// @flow

import * as React from "react";
import {useFormik} from "formik";
import UserService from "../services/user.service";
import {AxiosResponse} from "axios";
import CardWrapperFormTRUE from '../components/pricing-login-register/CardWrapperFormTRUE'
import {DEFAULT_REQUEST_ERROR, defaultStrings, URL_LOCAL, URL_PROD, URL_QA} from "../util/Constants";
import StandaloneFormPageTRUE from "../components/StandaloneFormPageTRUE"
import {useTranslation} from "react-i18next";
import "../css/ayg1pik.css";
import "../css/Login.css";
import {Alert, InputLabel, TextField} from "@mui/material";

type fieldTypes = {
    email?: string,
    active?: boolean,
};

type touchedTypes = {
    email?: boolean,
};

type Props = {
    strings?: any,
    action?: string,
    method?: string,
    values?: fieldTypes,
    errors?: fieldTypes,
    touched?: touchedTypes,
    onSubmit?: Function,
    isSubmitting?: boolean,
    onChange?: Function,
    onBlur?: Function,
};


const validate = (values: any) => {
    let errors: any = {};
    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'forms.invalidEmailValidation';
    }
    return errors;
};

/**
 * A forgot password page
 * Can be easily wrapped with form libraries like formik and redux-form
 */
function ForgotPassword() {

    const [alertState, setAlertState] = React.useState({visible: false, text: "", error: false});

    const userService = React.useMemo(() => new UserService(), []);

    const {t} = useTranslation();

    const handleSubmit = async (values: fieldTypes, setValues: any) => {
        if (values.active) {
            let errorMessage = t(DEFAULT_REQUEST_ERROR);
            let send = false;
            try {
                const {data: response}: AxiosResponse = await userService.forgotPasswordRequest(values.email)
                if (response.success) {
                    setValues({...values, active: false});
                    setAlertState({visible: true, text: t("changePassword.email_send"), error: false})
                    send = true;
                    return;
                }
                if (response?.internalError) {
                    errorMessage = response.message;
                }
            } catch (error) {
                console.info("Error #ForgotPasswordPage " + error)
            } finally {
                if (!send) {
                    // setValues({...values, active: false});
                    setAlertState({visible: true, text: errorMessage, error: true});
                }
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            active: true
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues}) => {
            handleSubmit(values, setValues).then(() => {
                setSubmitting(false);
            })
        },
    });

    return (
        <StandaloneFormPageTRUE imageURL={"./assets/images/new_logo.png"}
                                urlLogo={window.location.host.includes("tool.trueffort") ? URL_PROD : window.location.host.includes("qa.trueffort") ? URL_QA : URL_LOCAL }>
            <CardWrapperFormTRUE
                buttonText={t(defaultStrings.changePasswordButtonText)}
                title={t(defaultStrings.changePasswordTitle)}
                onSubmit={formik.handleSubmit}
                isSubmitting={formik.isSubmitting}
                showSubmitButton={formik.values.active}
            >
                {alertState.visible ?
                    <Alert variant="filled" severity={alertState.error ? "error" : "info"}>
                        <label>
                            {alertState.text}
                        </label>
                    </Alert> : <p className="text-muted">
                        {t(defaultStrings.changePasswordInstructions)}
                    </p>}
                <InputLabel sx={{ color: '#050536' }} >{t("email")}</InputLabel>
                <TextField
                    id="loginEmail"
                    name="email"
                    size="small"
                    margin="none"
                    fullWidth
                    //label={t("email")}
                    placeholder={ t("forms.emailPlaceholder") }
                    onChange={ formik.handleChange }
                    onBlur={ formik.handleBlur }
                    //defaultValue={userFromTrueDesktop != null && userFromTrueDesktop != "" ? userFromTrueDesktop : values && values.email}
                    value={formik.values && formik.values.email}
                    error={Boolean(formik.errors && formik.errors.email)}
                    disabled={formik.isSubmitting || !formik.values.active}
                    helperText={!formik.errors || !formik.errors.email ? " " : t(formik.errors.email)}
                />
            </CardWrapperFormTRUE>
        </StandaloneFormPageTRUE>
    );

    /*return (
        <StandaloneFormPageTRUE imageURL={"./assets/images/logo.png"}
        urlLogo={window.location.host.includes("tool.trueffort") ? URL_PROD : window.location.host.includes("qa.trueffort") ? URL_QA : URL_LOCAL }>
            <CardWrapperFormTRUE
                buttonText={t(defaultStrings.changePasswordButtonText)}
                title={t(defaultStrings.changePasswordTitle)}
                onSubmit={formik.handleSubmit}
                isSubmitting={formik.isSubmitting}
                showSubmitButton={formik.values.active}
            >
                {alertState.visible ?
                    <Alert type={alertState.error ? "danger" : "primary"}>
                        <label>
                            {alertState.text}
                        </label>
                    </Alert> : <p className="text-muted">
                        {t(defaultStrings.changePasswordInstructions)}
                    </p>}
                <Form.Label className={"size-form"}>{t(defaultStrings.emailLabel)}</Form.Label>
                <Form.Input
                    name="email"
                    disabled={formik.isSubmitting || !formik.values.active}
                    placeholder={t(defaultStrings.emailPlaceholder)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values && formik.values.email}
                    error={(formik.errors && formik.errors.email) ? t(formik.errors.email) : null}
                />
            </CardWrapperFormTRUE>
        </StandaloneFormPageTRUE>
    );*/
}

export default ForgotPassword;
