import {styled, useTheme} from "@mui/material/styles";
import {
  Box, Card, CardContent, Checkbox, Container, Divider, Drawer, Grid,
  IconButton, InputAdornment, Paper, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TablePagination,
  TableRow, TextField, Typography
} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import {useTranslation} from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from '@mui/icons-material/Search';
import StarRating from "../util/StarRating";
import React from "react";
import {TrueffortButton, TrueffortSearchInput, TrueffortTableHeadCell} from "../MUIstyle/MUICustom";
import {UserStatus} from "./projectDetail/ProjectDetailTaskRow";
import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomAvatar from "./custom/CustomAvatar";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#DCDCDC',//theme.palette.common.black,
        color: '#4A4A4A', //theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#EFEFEF' //theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#F5F5F5' //theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function UserTableRow(props) {

    return (
        <StyledTableRow >
            <StyledTableCell >
                <Stack direction="row" >
                    <Checkbox
                        checked={props.user.status === UserStatus.Assigned || props.user.status === UserStatus.Saved}
                        disabled={props.user.status === UserStatus.Saved}
                        onChange={(e) =>
                            props.handleCheckboxChange(e.target.checked, props.user.id, props.user.ranking !== undefined)
                            //props.handleCheckboxChange(e.target.checked, {...props.user})
                        }
                    />
                    <CustomAvatar 
                      url={props.user?.image_url}
                      text={props.user?.name + " " + (props.user.lastname === undefined ? props.user.lastName : props.user.lastname)}
                    />
                </Stack>
            </StyledTableCell>

            <StyledTableCell align="center" >
                <StarRating rating={props.user.ranking === undefined ? 0 : props.user.ranking} />
            </StyledTableCell>

            <StyledTableCell align="center" sx={{ paddingLeft: 1, paddingRight: 0 }} >
                <Paper
                    elevation={0}
                    sx={{ borderRadius: '3px 0px 0px 3px', bgcolor: '#F5F5F5', width: '100%', height: '50%' }}
                >
                    <Typography color='#553D74' >
                        {props.user.effort_interp_min === undefined ? "-" : props.user.effort_interp_min}
                    </Typography>
                </Paper>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0 }} >
                <Paper
                    elevation={0}
                    sx={{ borderRadius: '3px 0px 0px 3px', bgcolor: '#FFFFFF', width: '100%', height: '50%' }}
                >
                    <Typography color='#553D74' >
                        {props.user.effort_interp_avg}
                    </Typography>
                </Paper>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ paddingLeft: 0, paddingRight: 1 }} >
                <Paper
                    elevation={0}
                    sx={{ borderRadius: '3px 0px 0px 3px', bgcolor: '#F5F5F5', width: '100%', height: '50%' }}
                >
                    <Typography color='#553D74' >
                        {props.user.effort_interp_max === undefined ? "-" : props.user.effort_interp_max}
                    </Typography>
                </Paper>
            </StyledTableCell>
        </StyledTableRow>
    );

}

export default function AssignUsersDrawer(props/*: DrawerProps & { users: any, assign: () => void, taskName: string }*/) {
  //console.log("Assign");
  const {t} = useTranslation();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [assignedUsers, setAssignedUsers] = React.useState([]);
    const [rankedPage, setRankedPage] = React.useState(0);
    const [rowsPerPageRanked, setRowsPerPageRanked] = React.useState(5);
    const [notRankedPage, setNotRankedPage] = React.useState(0);
    const [rowsPerPageNotRanked, setRowsPerPageNotRanked] = React.useState(5);
    const [rankedCount, setRankedCount] = React.useState(props.users.rankedUsers.length);
    const [notRankedCount, setNotRankedCount] = React.useState(props.users.notRankedUsers.length);
    const [searchInput, setSearchInput] = React.useState('');

    /*const [rankedUsers, setRankedUsers] = React.useState(props.users.rankedUsers);
    const [notRankedUsers, setNotRankedUsers] = React.useState(props.users.notRankedUsers);*/

    const handleChangeSearchInput = (search) => {
        let newRankedCount = props.users.rankedUsers.reduce(
            (acc, user) =>
                acc +
                ((user.name.toLowerCase().includes(search) ||
                    (user.lastname === undefined ? user.lastName.toLowerCase().includes(search) :
                        user.lastname.toLowerCase().includes(search))) ? 1 : 0),
            0
        );

        let newNotRankedCount = props.users.notRankedUsers.reduce(
            (acc, user) =>
                acc +
                ((user.name.toLowerCase().includes(search) ||
                    (user.lastname === undefined ? user.lastName.toLowerCase().includes(search) :
                        user.lastname.toLowerCase().includes(search))) ? 1 : 0),
            0
        );

        setSearchInput(search);
        setRankedCount(newRankedCount);
        setNotRankedCount(newNotRankedCount);
        setRankedPage(0);
        setNotRankedPage(0);
    }
    
    const handleCheckboxChange = (checked, idUser, rankedUser) => {
        const usuarios = rankedUser ?
            props.users.rankedUsers.map(usr => ({ ...usr })) :
            props.users.notRankedUsers.map(usr => ({ ...usr }));
        const usuario = usuarios.find(usr => usr.id === idUser);
        const assignedUsersCopy = assignedUsers.map(usr => ({...usr}));

        if (checked) {
            usuario.status = UserStatus.Assigned;
            assignedUsersCopy.push(usuario);
        } else if (!checked && usuario.status !== UserStatus.Saved) {
            usuario.status = UserStatus.Unassigned;
            assignedUsersCopy.splice(assignedUsersCopy.findIndex(usr => usr.id === idUser));
        }

        if (rankedUser) {
            props.setUsers.setRankedUsers(usuarios);
        } else {
            props.setUsers.setNotRankedUsers(usuarios);
        }

        setAssignedUsers(assignedUsersCopy);
    }

    const handleAssigment = () => {
        props.assignUsers(assignedUsers);
        handleClose();
    }

    const handleChangeRankedPage = (event, newPage) => {
        setRankedPage(newPage);
    }

    const handleChangeRankedRowsPerPage = (event) => {
        setRowsPerPageRanked(parseInt(event.target.value, 10));
        setRankedPage(0);
    }

    const handleChangeNotRankedPage = (event, newPage) => {
        setNotRankedPage(newPage);
    }

    const handleChangeNotRankedRowsPerPage = (event) => {
        setRowsPerPageNotRanked(parseInt(event.target.value, 10));
        setNotRankedPage(0);
    }

    const handleClose = () => {
        setSearchInput('');
        setAssignedUsers([])
        props.onClose();
    }

    return (
        <Drawer
            open={props.open}
            anchor='right'
            onClose={handleClose}
            /*ModalProps={{
                keepMounted: true,
            }}*/
            PaperProps={{
                sx: { width: matches ? "100%" : "50%", paddingBottom: 5 },
            }}
        >
            {/*<Container sx={{paddingTop: '15px'}} >*/}
            <Box sx={{ margin: '19px' }} >
                <Grid container spacing={1} >
                    <Grid item xs={11} >
                        <Typography variant="caption" >
                            {t("project_page.task") + " - " + props.taskName}
                        </Typography>

                        <Typography variant="h4" >
                            {t("project_tasks_page.assign_users")}
                        </Typography>
                    </Grid>

                    <Grid item xs={1} sx={{ display: 'flex', alignItems: "flex-start", justifyContent: 'flex-end' }} >
                        <IconButton
                            aria-label="close"
                            size="small"
                            onClick={() => {
                                setSearchInput('');
                                props.onClose();
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            {/*</Container>*/}

            <Divider sx={{ borderBottomWidth: 1, bgcolor: '#989898' }} />

            <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
              <Stack direction={{ xs: "column", md: 'row'}} spacing={3} >
                <Grid container>
                  <Grid item style={{width:'75%', paddingRight:'0.2em'}}>
                    <TrueffortSearchInput
                      id="user-assigment-search"
                      size="small"
                      fullWidth
                      placeholder={t("user_page.search")}
                      onChange={(e) => handleChangeSearchInput(e.target.value.toLowerCase())}
                    />
                  </Grid>
                  {props.page === 'detail' &&
                    <Grid item style={{width:'25%'}}>
                      <TrueffortButton
                          size='small'
                          startIcon={<AddIcon />}
                          color='primary'
                          variant='contained'
                          disabled={!assignedUsers}
                          onClick={handleAssigment}
                      >
                        {t("project_tasks_page.assign_users")}
                      </TrueffortButton>
                    </Grid>
                  }
                </Grid>
              </Stack>
            </Container>

            <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
              {/*<FormControlLabel
                control={
                  <Switch
                    checked={props.automaticEstimatesState.changeValuesOnAssignedUsers}
                    onChange={props.automaticEstimatesState.handleEstimatesChangeOnAssignedUser}
                  />
                }
                label={t("users_modal.recalculate_estimates")}
              />*/}

              <Card elevation={1} >
                <CardContent>
                  <Typography >
                    {t("users_modal.ranked_users")}
                  </Typography>

                  <Typography variant="caption" >
                    {t('users_modal.participants_with_time')}
                  </Typography>

                  <Paper variant="outlined" >
                    <TableContainer sx={{ height: 385 }} >
                      <Table stickyHeader aria-label="ranked users table" >
                        <TableHead >
                          <StyledTableRow >
                            <TrueffortTableHeadCell background={'#DADADA'} align="center" >
                              {t("User")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center" >
                              Ranking
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} colSpan={3} align="center" >
                              {t("estimation_page.effort_man_hours")}
                            </TrueffortTableHeadCell>
                          </StyledTableRow>
                          <StyledTableRow >
                            <TrueffortTableHeadCell background={'#DADADA'} colSpan={2}></TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center">
                              Min
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center">
                              {t("estimation_page.avg")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center">
                              Max
                            </TrueffortTableHeadCell>
                          </StyledTableRow>
                        </TableHead>

                        <TableBody >
                          {props.users.rankedUsers
                            //.sort((usr1, usr2) => usr2.ranking - usr1.ranking)
                            .filter((user) =>
                              user?.name?.toLowerCase().includes(searchInput) ||
                              (user?.lastname === undefined ? user?.lastName?.toLowerCase().includes(searchInput) :
                              user?.lastname?.toLowerCase().includes(searchInput)))
                            .slice(
                              rankedPage * rowsPerPageRanked,
                              rankedPage * rowsPerPageRanked + rowsPerPageRanked
                            )
                            .map((user) => (
                                <UserTableRow
                                  key={user.id.toString()}
                                  user={user}
                                  //handleCheckboxChange={props.handleCheckboxChange}
                                  handleCheckboxChange={ props.page === 'estimation' ?
                                    props.assignUser : handleCheckboxChange
                                  }
                                />
                              ))
                            }
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      onRowsPerPageChange={handleChangeRankedRowsPerPage}
                      onPageChange={handleChangeRankedPage}
                      count={rankedCount}
                      rowsPerPage={rowsPerPageRanked}
                      page={rankedPage}
                    />
                  </Paper>
                </CardContent>
              </Card>
            </Container>

            <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
              <Card elevation={1} >
                <CardContent>
                  <Typography >
                    {t("users_modal.not_ranked_users")}
                  </Typography>

                  <Typography variant="caption" >
                    {t('users_modal.participants_without_time')}
                  </Typography>

                  <Paper variant="outlined" >
                    <TableContainer sx={{ height: 385 }} >
                      <Table stickyHeader aria-label="ranked users table" >
                        <TableHead >
                          <StyledTableRow >
                            <TrueffortTableHeadCell background={'#DADADA'} align="center" >
                              {t("User")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center" >
                              Ranking
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} colSpan={3} align="center" >
                              {t("estimation_page.effort_man_hours")}
                            </TrueffortTableHeadCell>
                          </StyledTableRow>
                          <StyledTableRow >
                            <TrueffortTableHeadCell background={'#DADADA'} colSpan={2}></TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center">Min</TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center">
                              {t("estimation_page.avg")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell background={'#DADADA'} align="center">Max</TrueffortTableHeadCell>
                          </StyledTableRow>
                        </TableHead>

                        <TableBody >
                          {props.users.notRankedUsers
                            .filter((user) =>
                              user?.name?.toLowerCase().includes(searchInput) ||
                              (user?.lastname === undefined ? user?.lastName?.toLowerCase().includes(searchInput) :
                              user?.lastname?.toLowerCase().includes(searchInput)))
                            .slice(
                              notRankedPage * rowsPerPageNotRanked,
                              notRankedPage * rowsPerPageNotRanked + rowsPerPageNotRanked
                            )
                            .map((user) => (
                              <UserTableRow
                                key={user.id.toString()}
                                user={user}
                                //handleCheckboxChange={props.handleCheckboxChange}
                                handleCheckboxChange={
                                  props.page === 'estimation' ? props.assignUser : handleCheckboxChange
                                }
                              />
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      onRowsPerPageChange={handleChangeNotRankedRowsPerPage}
                      onPageChange={handleChangeNotRankedPage}
                      count={notRankedCount}
                      rowsPerPage={rowsPerPageNotRanked}
                      page={notRankedPage}
                    />
                  </Paper>
                </CardContent>
              </Card>
            </Container>
            {props.page === 'detail' &&
              <Container maxWidth='xl' sx={{ marginTop: '10px', display:'flex', justifyContent:'end' }} >
                <Grid item style={{width:'25%'}}>
                  <TrueffortButton
                      size='small'
                      startIcon={<AddIcon />}
                      color='primary'
                      variant='contained'
                      disabled={!assignedUsers}
                      onClick={handleAssigment}
                  >
                    {t("project_tasks_page.assign_users")}
                  </TrueffortButton>
                </Grid>
              </Container>
            }
        </Drawer>
    );
}