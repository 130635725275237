import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as farStar} from "@fortawesome/free-regular-svg-icons";
import {faStar as fasStar, faStarHalfAlt as fasStarHalf} from "@fortawesome/free-solid-svg-icons";

function StarRating({ rating } : any) {

    const rate: number = Math.round(rating*2)/2;
    const halfStar: number = (+(rate % 1).toFixed(1));

    const emptyStars = () : number => halfStar === 0 ? 5 - Math.floor(rate) : 5 - Math.ceil(rate);

    return(
        <div >
            {(rating === 0) ?
                <>
                    <FontAwesomeIcon icon={farStar} color='#D4AF37' />
                    <FontAwesomeIcon icon={farStar} color='#D4AF37' />
                    <FontAwesomeIcon icon={farStar} color='#D4AF37' />
                    <FontAwesomeIcon icon={farStar} color='#D4AF37' />
                    <FontAwesomeIcon icon={farStar} color='#D4AF37' />
                </> :
                <>
                    {[...Array(Math.floor(rate))].map((star: any, index: number) =>
                        <FontAwesomeIcon key={index.toString()} icon={fasStar} color='#D4AF37' />
                    )}

                    {halfStar > 0 &&
                        <FontAwesomeIcon icon={fasStarHalf} color='#D4AF37' />
                    }

                    {[...Array(emptyStars())].map((emptyStar: any, index: number) =>
                        <FontAwesomeIcon key={index.toString()} icon={farStar} color='#D4AF37' />
                    )}
                </>
            }
        </div>
    );

}

export default StarRating;