// @flow
import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import {useTranslation} from "react-i18next";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CategoriesTabTRUE from "../components/configuration/CategoriesTabTRUE";
import ApplicationsTabTRUE from "../components/configuration/ApplicationsTabTRUE";
import SitesTabTRUE from "../components/configuration/SitesTabTRUE";
import CategoriesSitesTabTRUE from "../components/configuration/CategoriesSitesTabTRUE";
import { TrueffortButtonSmall } from "../MUIstyle/MUICustom";
import { useAuthContext } from "../contexts/AuthenticationContext";

function ConfigurationCategory() {

    const {user} = useAuthContext();
    const features = user?.instance?.license ? user?.instance?.license?.features : {};
    const {t} = useTranslation();
    const [tabName, setTabName] = React.useState(features.categorizeApplications ? "categories" : "applications");
    return (
        <SiteWrapper>
            <Container maxWidth="xl" sx={{marginTop:'20px'}}>
                <div className="title">
                    <div className="title-small">{t("config_page.apps_and_sites")}</div>
                    <h2 id="idTitle">{t("config_page.tools")}</h2>
                </div>
                <Grid container spacing={1} rowSpacing={1} >
                    {features.categorizeApplications && <Grid item>
                        <TrueffortButtonSmall
                            id="idTabConfigCategory"
                            color="secondary"
                            variant={tabName == "categories" ? "contained" : "outlined"}
                            onClick={() => setTabName("categories")}
                            style={{marginRight: "0.2em"}}>
                            {t("categories")}
                        </TrueffortButtonSmall>
                    </Grid>}
                    <Grid item>
                        <TrueffortButtonSmall
                            id="idTabApplications"
                            color="secondary"
                            variant={tabName == "applications" ? "contained" : "outlined"}
                            onClick={() => setTabName("applications")}
                            style={{marginRight: "0.2em"}}>
                            {t("Applications")}
                        </TrueffortButtonSmall>
                    </Grid>
                    {features.categorizeWebSites && <Grid item>
                        <TrueffortButtonSmall
                            id="idTabConfigCategorySites"
                            color="secondary"
                            variant={tabName == "categoriesSites" ? "contained" : "outlined"}
                            onClick={() => setTabName("categoriesSites")}
                            style={{marginRight: "0.2em"}}>
                            {t("site_page.categories")}
                        </TrueffortButtonSmall>
                    </Grid>}
                    {features.categorizeWebSites && <Grid item>
                        <TrueffortButtonSmall
                            id="idTabSites"
                            color="secondary"
                            variant={tabName == "sites" ? "contained" : "outlined"}
                            onClick={() => setTabName("sites")}
                            >
                            {t("Sites")}
                        </TrueffortButtonSmall>
                    </Grid>}
                </Grid>
                <Grid style={{marginTop:"1em"}}>
                    {tabName == "categories" && <CategoriesTabTRUE />}
                    {tabName == "applications" && <ApplicationsTabTRUE />}
                    {tabName == "categoriesSites" && <CategoriesSitesTabTRUE />}
                    {tabName == "sites" && <SitesTabTRUE />}
                </Grid>
            </Container>
        </SiteWrapper>
    );
}

export default ConfigurationCategory;
