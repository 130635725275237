import { TrueffortChip } from "../../MUIstyle/MUICustom";

export default function TrueffortChipStatus({status_id, status_name}:{status_id:number, status_name:string}) {

  return (
    <>
      {status_id == 4 ?
        <TrueffortChip
          color="green"
          variant="outlined"
          size="small"
          label={status_name?.toUpperCase()}
        /> :
        status_id == 2 ?
          <TrueffortChip
              color="secondary"
              variant="outlined"
              size="small"
              label={status_name?.toUpperCase()}
            /> :
          <TrueffortChip
              color="gray"
              variant="outlined"
              size="small"
              label={status_name?.toUpperCase()}
          />
      }
    </>
  );
}