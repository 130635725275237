import { Grid, Card, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { ResponsiveBar } from "@nivo/bar";
import { TrueffortNavButton } from "../../MUIstyle/MUICustom";
import { cutValueFormated } from "../../util/GlobalFunctions";
import CustomIconTrueffortScreen from "../custom/CustomIconTrueffortScreen";

function GraphicItemsCount({ dataCards, keys, indexByKey, status}) {
  //console.log(dataCards);
  const { t } = useTranslation();
  const [anchorElemFilterItem, setAnchorElemFilterItem] = React.useState(null);
  const openOptionsItem = Boolean(anchorElemFilterItem);
  const [menuNumberItem, setMenuNumberItem] = React.useState(0);
  const [itemSelected, setItemSelected] = React.useState({ id: 1, name: t("reports.projects.title"), field: "projects" });
  let sizesGraphic = ['300px', '450px', '500px'];

  const handleClickMenuItems = (event, value) => {
    setAnchorElemFilterItem(event.currentTarget);
    setMenuNumberItem(value);
  }

  const selectItem = (it) => {
    setItemSelected(it);
    setAnchorElemFilterItem(null);
  }

  return (
    <>
      {<Grid item xs={12} >
        <Card sx={{ height: dataCards[itemSelected.field].dataBar.length > 0 ? 
            dataCards[itemSelected.field].dataBar.length === 1 ? sizesGraphic[0] : 
            dataCards[itemSelected.field].dataBar.length === 2 ? sizesGraphic[1] : sizesGraphic[2] : '10em', width:'98%', paddingLeft:'1em'}}>
          <Grid xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1em 1em 0 1em'}}>
              <Grid item >
              <Typography>{t("reports.projects.graphic_projects").replace("{0}", itemSelected.name).replace("{1}", status)}</Typography>
              </Grid>
              <Grid item >
                <TrueffortNavButton
                  id="idSelectedItem"
                  size="small"
                  variant="contained"
                  onClick={(event) => handleClickMenuItems(event, 1)}
                  className={""}
                  fullWidth
                >
                  {cutValueFormated(itemSelected.name, 18)}
                  <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
                </TrueffortNavButton>
              </Grid>
            </div>
            <Menu id="idFilterMenuItem"
              anchorEl={anchorElemFilterItem}
              open={openOptionsItem}
              onClose={() => setAnchorElemFilterItem(null)}
            >
              {menuNumberItem === 1 && <div>
                <MenuItem id="projectsMenu" key={"projectsMenu"} onClick={() => selectItem({ id: 1, name: t("reports.projects.title"), field:"projects" })}>
                  {t("reports.projects.title")}
                </MenuItem>
                <MenuItem id="elementsMenu" key={"elementsMenu"} onClick={() => selectItem({ id: 2, name: t("reports.projects.elements"), field:"elements" })}>
                  {t("reports.projects.elements")}
                </MenuItem>
                <MenuItem id="phasesMenu" key={"phasesMenu"} onClick={() => selectItem({ id: 3, name: t("reports.projects.phases"), field:"phases" })}>
                  {t("reports.projects.phases")}
                </MenuItem>
                <MenuItem id="tasksMenu" key={"tasksMenu"} onClick={() => selectItem({ id: 4, name: t("reports.projects.tasks"), field:"tasks" })}>
                  {t("reports.projects.tasks")}
                </MenuItem>
              </div>}
            </Menu>
          </Grid>
          {dataCards[itemSelected.field].dataBar.length > 0 ?
            <div style={{ height: '90%'}}>
              <ResponsiveBar
                data={dataCards[itemSelected.field].dataBar}
                keys={keys}
                indexBy={indexByKey}
                layout="horizontal"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.4}
                valueScale={{ type: "linear" }}
                colors="#3182CE"
                animate={true}
                enableLabel={true}
                theme={{labels: {text:{fontSize:'16px'}}}}
                labelTextColor={'white'}
                legendLabel={'30px'}
                enableGridX={true}
                enableGridY={true}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5, tickPadding: 5,
                  tickRotation: 0, legend: t("reports.projects.quantity"),
                  legendPosition: "middle", legendOffset: 30
                }}
              />
            </div>: <div style={{ padding: "8px", width:'100%', height: '80%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {t("msg.info.empty_response")}
          </div>}
        </Card>
      </Grid>}
    </>
  );
}

export default GraphicItemsCount;