import { ApiProtectedWS as http } from "../api/axios";
import {baseURL} from "../util/Constants";

class infoJiraProjectsService {
  // Método getProjects que toma domain, email y token como parámetros
    getProjects(domain: string, email: string, token: string) {
       // Con los parametros de dominio, email y toekn hace la solicitud al back
       const response = http.get(`${baseURL.activitiesapi.jiraProjects}/projects?domain=${domain}&email=${email}&token=${token}`);
       // Devuelve la respuesta de la solicitud
       return response;
   }

   // Método getProjectDetails que toma domain, email, token y projectId como parámetros
    getProjectDetails(domain: string, email: string, token: string, projectId: string) {
       // Con los parametros de dominio, email y token hace la solicitud al back
       const response = http.get(`${baseURL.activitiesapi.jiraProjects}/project-details?domain=${domain}&email=${email}&token=${token}&projectId=${projectId}`);
      // Devuelve la respuesta de la solicitud
       return response;
   }

    getProjectBoards(domain: string, email: string, token: string, projectId: string) {
        // Con los parametros de dominio, email y toekn hace la solicitud al back
        const response = http.get(`${baseURL.activitiesapi.jiraProjects}/project-boards?domain=${domain}&email=${email}&token=${token}&projectId=${projectId}`);
        // Devuelve la respuesta de la solicitud
        return response;
    }

    getBoardSprints(domain: string, email: string, token: string, boardId: string) {
        // Con los parametros de dominio, email y toekn hace la solicitud al back
        const response = http.get(`${baseURL.activitiesapi.jiraProjects}/board-sprints?domain=${domain}&email=${email}&token=${token}&boardId=${boardId}`);
        // Devuelve la respuesta de la solicitud
        return response;
    }

    getIssueTransitions(domain: string, email: string, token: string, issueId: number) {
        return http?.get(
            `${baseURL.activitiesapi.jiraProjects}/transitions?domain=${domain}&email=${email}&token=${token}&issueId=${issueId}`
        );
    }

    updateIssueStatus(domain: string, email: string, token: string, data: any) {
        return http?.post(
            `${baseURL.activitiesapi.jiraProjects}/transition?domain=${domain}&email=${email}&token=${token}`,
            data
        );
    }

    getConnectionInfo() {
       return http?.get(`${baseURL.activitiesapi.jiraProjects}/connection-info`);
    }
}

export default new infoJiraProjectsService();