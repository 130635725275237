// @flow
import React from "react";
import { TableContainer, Paper, TableHead, TableBody, Table, Chip, Container, Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useFormik} from "formik";
import {useAuthContext} from "../contexts/AuthenticationContext";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { StyledTableRow, StyledTableCell, TrueffortCardTable, TrueffortTableHeadCell, 
  TrueffortTextField, TrueffortInputLabel } from "../MUIstyle/MUICustom";
import { styleReactSelect } from "../MUIstyle/GlobalStyles";
import { Edit, Save, Clear } from "@mui/icons-material";
import { useState, useMemo, useEffect } from "react";
import InstanceService from "../services/instance.service";
import SiteWrapper from "../SiteWrapper.react";

function  LicensePage() {
    const {user} = useAuthContext();
    const {t} = useTranslation();
    const [licenses, setLicenses] = useState([]);
    const [features, setFeatures] = useState([]);
    const [featuresValues, setFeaturesValues] = useState([]);
    const instanceService = useMemo(() => new InstanceService(), []);
    const [state, setState] = React.useState({
      loading: false,
      isEditing: false,
      isUpdating: false,
      errorMessageFile: ""
  });

  const initialValues = React.useMemo(() => {
    return {
        idLicenseType: null,
        name: '',
        isEditing: false,
        isLoading: false,
        licenseTypeFeatures: [],
    }
}, [licenses]);

  useEffect(() => {
    fillLicenses();
    fillFeatures();
  }, []);

  const fillLicenses = () => {
    // @ts-ignore
    instanceService.getAllLicenses().then(({data: response}) => {
      if (Array.isArray(response.data)) {
        setLicenses(response.data.map((lic) => {return {...lic, isEditing:false}}));
      }
    });
  }

  const fillFeatures = () => {
    // @ts-ignore
    instanceService.getAllFeatures().then(({data: response}) => {
      if (Array.isArray(response.data)) {
        setFeatures(response.data);
      }
    });
  }

  const validate = (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = 'Required';
    } else if (values.name.length > 25) {
        errors.name = 'forms.nameLengthValidation25';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validate: validate,
    onSubmit: (values, {setSubmitting, setValues, resetForm}) => {
      handleEdit(values, setValues).then(() => {
        console.log(values);
          resetForm();
          setSubmitting(false);
          setState({...state, isUpdating: false, isCreating: false});
      })
    }
  });

  function getArrIdFeatures() {
    let arr = [];
    console.log(featuresValues);
    featuresValues.map((feature, k) => {
        arr.push({idFeature: feature.value, idLicenseType: 0});
    });
    return arr;
  }

  const handleEdit = async (values, setValues) => {
    //console.log(values);
    const dataRequest = {
        idLicenseType: values.idLicenseType,
        name: values.name,
        licenseTypeFeatures: getArrIdFeatures(),
    };
    instanceService.saveLicense(values.idLicenseType, dataRequest).then(() => {
      toast.success(t("category_page.msg_success_update"));
    }).catch((error) =>{
      toast.error(t(error.message));
    }).finally(() => {
      fillLicenses();
    });
  }

  function prepareEdit(license) {
    setState({...state, isUpdating: true});
    formik.setValues({...initialValues, ...license, isEditing: true});
    setLicenses(licenses.map((item_) => item_.idLicenseType === license.idLicenseType ? { ...item_, isEditing: true} : item_));

    let arr = [];
    if (Array.isArray(license.licenseTypeFeatures)) {
      arr = license.licenseTypeFeatures.map((licType, k) => {
          return {value: licType.idFeature, label: licType.feature.description};
      });
    }
    setFeaturesValues(arr);
  }

  return (
    <SiteWrapper>
      <Container>
        <Typography variant='h4' >
            {t("Licenses")}
        </Typography>
        <Grid sx={{padding: "6px"}} >
          <TrueffortCardTable>
            <Grid container spacing={1} rowSpacing={3} style={{ margin: "0.4em 0.2em", width: '100%' }}>
              <TableContainer component={Paper}
                onSubmit={formik.handleSubmit}
              >
                <Table responsive={"true"}>
                  <TableHead>
                    <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                      <TrueffortTableHeadCell>{t("Name")}</TrueffortTableHeadCell>
                      <TrueffortTableHeadCell>{t("Applications")}</TrueffortTableHeadCell>
                      <TrueffortTableHeadCell>{t("actions")}</TrueffortTableHeadCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {licenses.map((lic, k) => (
                    !lic.isEditing ?
                      <StyledTableRow key={k}>
                        <StyledTableCell>
                          <div>{lic.name}</div>
                        </StyledTableCell>
                        <StyledTableCell style={{width: '650px'}}>
                          {lic.licenseTypeFeatures.map((app, j) => <li>{app.feature.description.length > 85 ? app.feature.description.substring(0,85) + '...' : app.feature.description}</li>)}
                        </StyledTableCell>
                        <StyledTableCell className="tbl-td-center">
                          <Chip size="small"
                            icon={<Edit fontSize="small" />}
                            variant="outlined"
                            color='primary'
                            disabled={lic.isEditing}
                            onClick={() => prepareEdit(lic)}
                            label={t("elements.buttons.edit")}
                          />
                        </StyledTableCell>
                      </StyledTableRow> :
                      <StyledTableRow key={k}>
                        <StyledTableCell colSpan={4}>
                          <Grid container spacing={1}>
                            <Grid item lg={4} md={6}>
                              <TrueffortInputLabel shrink labelstring={t("Name")} requiredlabel={true} />
                              <TrueffortTextField
                                id={"field-name-category-name"}
                                name="name"
                                size="small"
                                required={true}
                                value={formik.values.name}
                                placeholder={t("Name")}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                disabled={formik.isSubmitting}
                                fullWidth
                              />
                            </Grid>
                            <Grid item lg={8} md={6}>
                              <TrueffortInputLabel shrink labelstring={t("Features")}/>
                              <Select 
                                id={"select-applications-new"}
                                isMulti
                                value={featuresValues}
                                options={features.map((f) => {return {value: f.idFeature, label: f.description}})}
                                onChange={(option) => setFeaturesValues(option)}
                                styles={styleReactSelect}
                              />
                            </Grid>
                            <Grid item lg={8} md={6} style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center'}}>
                              <Chip size="small"
                                icon={<Save fontSize="small" />}
                                variant="outlined"
                                color='success'
                                disabled={state.isEditing}
                                onClick={formik.handleSubmit}
                                label={t("elements.buttons.save")}
                              />
                              <div style={{ marginLeft: "0.5em" }} />
                              <Chip size="small"
                                icon={<Clear fontSize="small" />}
                                onClick={() => {
                                    lic["isEditing"] = false;
                                    formik.resetForm();
                                    setState({...state, isUpdating: false});
                                }}
                                label={t("elements.buttons.cancel")}
                                variant="outlined"
                                color="error"
                              />
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>  
            </Grid>
          </TrueffortCardTable>
        </Grid>
      </Container>
    </SiteWrapper>
  );
};

export default LicensePage;
