import React, {createContext} from "react";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {User} from "../../util/Interfaces";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Container, Box} from "@mui/material";

function DragAndDropColumn({title, index, items, idStatus, _setSelectedIndexTask, _setSelectedTask, _setIdView}) {
    const draggableId = React.useId();

    return (
        <Draggable draggableId={idStatus.toString()} index={index}>
            {(provided) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    id={index}
                    sx={{backgroundColor: "#f8f8f8", padding: ".4rem"}}
                >
                    <Box className={"item-status-" + idStatus}><h5>{title}</h5></Box>
                    <Droppable
                        droppableId={index.toString()}
                        type="task"
                        ignoreContainerClipping={false}
                        isCombineEnabled={true}
                    >
                        {(provided, snapshot) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={"dnd-droppable-items " + (snapshot.isDraggingOver ? "isDraggingOver" : "")}
                            >
                                {items.map((item, k) => (
                                    <Box key={"t_" + item.idProjectTask} sx={{fontSize: ".8rem", backgroundColor: "white", marginBottom: ".4rem"}}>
                                        <Draggable draggableId={item.idProjectTask.toString()} index={item.idProjectTask}>
                                            {(provided, snapshot) => (
                                                <Box
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    className={"dnd-item " + (snapshot.isDragging ? "dragging" : "")}
                                                    onDoubleClick={() => {_setSelectedIndexTask(item.idProjectTask); _setSelectedTask(item); _setIdView(2);}}
                                                >
                                                    {item.elementName + " / " + item.phaseTypeName + " / " + item.taskTypeName}
                                                </Box>
                                            )}
                                        </Draggable>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Droppable>
                </Box>
             )}
        </Draggable>
    );
}

export default DragAndDropColumn;