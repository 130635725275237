import { Grid, Card, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDown as KeyboardArrowDownIcon, Speed as SpeedIcon } from "@mui/icons-material";
import { ResponsiveBar } from "@nivo/bar";
import { TrueffortNavButton } from "../../MUIstyle/MUICustom";
import { cutValueFormated } from "../../util/GlobalFunctions";
import CustomIconTrueffortScreen from "../custom/CustomIconTrueffortScreen";
import CardUnitStatistics from "../custom/CardUnitStatistics";

function CardsItemsStatistics({ statistics }) {
  const { t } = useTranslation();
  const [anchorElemFilterItem, setAnchorElemFilterItem] = React.useState(null);
  const openOptionsItem = Boolean(anchorElemFilterItem);
  const [menuNumberItem, setMenuNumberItem] = React.useState(0);
  const [itemSelected, setItemSelected] = React.useState({ id: 1, name: t("reports.projects.elements"), field: "element" });
  const [unitLabel, setUnitLabel] = React.useState({velocity: t("home.velocity_unit_elements"),
    productivity: t("home.productivity_description_elements"),
    PDR: t("home.PDR_description_elements") });


  const handleClickMenuItems = (event, value) => {
    setAnchorElemFilterItem(event.currentTarget);
    setMenuNumberItem(value);
  }

  const selectItem = (it) => {
    setItemSelected(it);
    let label = {velocity:'', productivity:'', PDR: ''};
    switch (it.id) {
      case 1:
        label = {velocity: t("home.velocity_unit_elements"),
          productivity: t("home.productivity_description_elements"),
          PDR: t("home.PDR_description_elements")};
        break;
      case 2:
        label = {velocity: t("home.velocity_unit_phases"),
          productivity: t("home.productivity_description_phases"),
          PDR: t("home.PDR_description_phases")};
        break;
      case 3:
        label = {velocity: t("home.velocity_unit_tasks"),
          productivity: t("home.productivity_description_tasks"),
          PDR: t("home.PDR_description_tasks")};
        break;
    }
    setUnitLabel(label);
    setAnchorElemFilterItem(null);
  }

  return (
    <Card>
      <Grid item xs={12} >
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1em 1em 0 1em', float:'right'}}>
          <Grid item >
            <TrueffortNavButton
              id="idSelectedItem"
              size="small"
              variant="contained"
              onClick={(event) => handleClickMenuItems(event, 1)}
              className={""}
              fullWidth
            >
              {cutValueFormated(itemSelected.name, 18)}
              <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
            </TrueffortNavButton>
          </Grid>
        </div>
        <Menu id="idFilterMenuItem"
          anchorEl={anchorElemFilterItem}
          open={openOptionsItem}
          onClose={() => setAnchorElemFilterItem(null)}
        >
          {menuNumberItem === 1 && <div>
            <MenuItem id="elementsMenu" key={"elementsMenu"} onClick={() => selectItem({ id: 1, name: t("reports.projects.elements"), field:"element" })}>
              {t("reports.projects.elements")}
            </MenuItem>
            <MenuItem id="phasesMenu" key={"phasesMenu"} onClick={() => selectItem({ id: 2, name: t("reports.projects.phases"), field:"phase" })}>
              {t("reports.projects.phases")}
            </MenuItem>
            <MenuItem id="tasksMenu" key={"tasksMenu"} onClick={() => selectItem({ id: 3, name: t("reports.projects.tasks"), field:"task" })}>
              {t("reports.projects.tasks")}
            </MenuItem>
          </div>}
        </Menu>
      </Grid>
      <Grid item xs={12} container spacing={1} style={{display:'flex', paddingLeft:'0.5em', paddingRight:'0.1em'}}>
        <Grid item xs={4}>
          <CardUnitStatistics 
            title={t("home.PDR")}
            value={statistics[itemSelected.field].PDR.toFixed(2)}
            subtitle={unitLabel.PDR}
            type={3}
            fontSizeTitle={'1rem'}
            fontSizeSubTitle={'0.8em'}
            alignValue={'center'}
            fontSizeValue={'2.125rem'}
          />
        </Grid>
        <Grid item xs={4}>
          <CardUnitStatistics 
            title={t("Productivity")}
            value={statistics[itemSelected.field].productivity.toFixed(2)}
            subtitle={unitLabel.productivity}
            type={2}
            fontSizeTitle={'1rem'}
            fontSizeSubTitle={'0.8em'}
            alignValue={'center'}
            fontSizeValue={'2.125rem'}
          />
        </Grid>
        <Grid item xs={4}>
        <CardUnitStatistics 
            title={t("home.velocity")}
            value={statistics[itemSelected.field].velocity.toFixed(2)}
            subtitle={unitLabel.velocity}
            type={1}
            fontSizeTitle={'1rem'}
            fontSizeSubTitle={'0.8em'}
            alignValue={'center'}
            fontSizeValue={'2.125rem'}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default CardsItemsStatistics;