import {createContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Grid, MenuItem, Modal, Stack, Typography} from "@mui/material";
import {TrueffortButton, TrueffortSelect, TrueffortTextField} from "../../MUIstyle/MUICustom";


export const UsersContext = createContext([]);

export const DurationTypeContext = createContext(false);

export const measurementStandards = [
    {
        id: 1,
        nombre: "COSMIC ISO 19761"
    },
    {
        id: 5,
        nombre: "FISMA ISO 29881"
    },
    {
        id: 2,
        nombre: "IFPUG ISO 20926"
    },
    {
        id: 3,
        nombre: "MKII ISO 20698"
    },
    {
        id: 4,
        nombre: "NESMA ISO 24570"
    }
];

export const sizeOptions = [
    {
        idSize: 1,
        size: "estimation_page.small_size",
    },
    {
        idSize: 2,
        size: "estimation_page.medium_size",
    },
    {
        idSize: 3,
        size: "estimation_page.large_size",
    },
    {
        idSize: 4,
        size: "estimation_page.very_large_size",
    },
];
export const complexityOptions = [
    {
        idComplexity: 1,
        complexity: "estimation_page.low_complexity",
    },
    {
        idComplexity: 2,
        complexity: "estimation_page.medium_complexity",
    },
    {
        idComplexity: 3,
        complexity: "estimation_page.large_complexity",
    },
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export function MeasurementsMenu({openState, size, complexity, functionalSize, handleMeasurementsChange}: any) {
    const {t} = useTranslation();

    const [newFunctionalSize, setNewFunctionalSize] = useState(functionalSize);
    const [newSize, setNewSize] = useState(size);
    const [newComplexity, setNewComplexity] = useState(complexity);
    const [invalidFunctionalSize, setInvalidFunctionalSize] = useState(false);
    const [invalidFunctionalSizeMessage, setInvalidFunctionalSizeMessage] = useState(" ");

    const moreThan2Decimals = (number: string) => {
        const numSplit: string[] = number.split(".");
        return (numSplit.length === 2 && numSplit[1].length > 2);
    }

    const handleFunctionalSizeChange = (event: any) => {
        let value = event.target.value;

        if (isNaN(Number(value)) || !/\d+(\.\d{1,2})?$/.test(value)) {
            setInvalidFunctionalSize(true);
            setInvalidFunctionalSizeMessage(t("estimation_page.functional_size_input_error1"));
        } else if (Number(value) < 1) {
            setInvalidFunctionalSize(true);
            setInvalidFunctionalSizeMessage(t("estimation_page.functional_size_input_error2"));
        } else if (value.split(".")[0].length > 5 || moreThan2Decimals(value)) {
            return;
        } else {
            invalidFunctionalSize && setInvalidFunctionalSize(false);
            invalidFunctionalSizeMessage !== " " && setInvalidFunctionalSizeMessage(" ");
        }

        setNewFunctionalSize(value);
    }

    const handleMeasurementsUpdate = () => {
        let newSizeNumber = Number(newSize);
        let newComplexityNumber = Number(newComplexity);
        let newFunctionalSizeNumber = Number(newFunctionalSize);

        if (newSizeNumber !== size || newComplexityNumber !== complexity || newFunctionalSizeNumber !== functionalSize) {
            handleMeasurementsChange(newSizeNumber, newComplexityNumber, newFunctionalSizeNumber);
        }

        openState.setOpenMenu(false);
    }

    const handleClose = () => {
        setNewSize(size);
        setNewComplexity(complexity);
        setNewFunctionalSize(functionalSize);
        setInvalidFunctionalSize(false);
        setInvalidFunctionalSizeMessage(" ");
        openState.setOpenMenu(false);
    }

    return(
        <Modal
            open={openState.openMenu}
            onClose={handleClose}
        >
            <Box sx={style} >
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Stack direction="column" spacing={1} >
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <Typography variant="h6" color='#050536' >
                                    {t("estimation_page.functional_size")}
                                </Typography>

                                {/*<Switch color="success" />*/}
                            </Stack>

                            <TrueffortTextField
                                id='functional-size'
                                type="number"
                                size="small"
                                fullWidth
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 99999.99,
                                        step: 0.01,
                                        style: { background:'#F5F5F5' }
                                    }
                                }}
                                //placeholder={props.t("estimation_page.number_elements")}
                                value={newFunctionalSize}
                                onChange={handleFunctionalSizeChange}
                                error={invalidFunctionalSize}
                                helperText={invalidFunctionalSizeMessage}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={6} >
                        <Stack direction="column" spacing={1} >
                            <Typography variant="h6" color='#050536' >
                                {t("estimation_page.element_size")}
                            </Typography>

                            <TrueffortSelect
                                id='base-elements-size'
                                size="small"
                                fullWidth
                                displayEmpty
                                value={newSize}
                                onChange={(event: any) => setNewSize(event.target.value)}
                            >
                                <MenuItem
                                    value=""
                                    disabled
                                >
                                    {t("Select")}
                                </MenuItem>
                                {sizeOptions.map((opcion: {idSize: number, size: string}) =>
                                    <MenuItem
                                        key={opcion.idSize}
                                        value={opcion.idSize}
                                    >
                                        {t(opcion.size)}
                                    </MenuItem>
                                )}

                            </TrueffortSelect>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} >
                        <Stack direction="column" spacing={1} >
                            <Typography variant="h6" color='#050536' >
                                {t("estimation_page.element_complexity")}
                            </Typography>

                            <TrueffortSelect
                                id='base-elements-complexity'
                                size="small"
                                fullWidth
                                displayEmpty
                                value={newComplexity}
                                onChange={(event: any) => setNewComplexity(event.target.value)}
                            >
                                <MenuItem
                                    value=""
                                    disabled
                                >
                                    {t("Select")}
                                </MenuItem>

                                {complexityOptions.map((opcion: { idComplexity: number, complexity: string }) =>
                                    <MenuItem
                                        key={opcion.idComplexity}
                                        value={opcion.idComplexity}
                                    >
                                        {t(opcion.complexity)}
                                    </MenuItem>
                                )}
                            </TrueffortSelect>
                        </Stack>
                    </Grid>

                    <Grid item xs >
                        <Stack direction="row" spacing={2} justifyContent="center" >
                            <TrueffortButton
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled={invalidFunctionalSize}
                                onClick={handleMeasurementsUpdate}
                            >
                                {t("estimation_page.update_measurements")}
                            </TrueffortButton>

                            <TrueffortButton
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </TrueffortButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}