import React from "react";
import {Card} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useTranslation} from "react-i18next";
import "../../css/Productivity.css";
import OnlyComparativeBar from "./OnlyComparativeBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailCategoriesAndApps from "./DetailCategoriesAndApps";
import { formatSeconds } from "../../util/GlobalFunctions";
import CustomAvatar from "../custom/CustomAvatar";

const ProductivityCard = ({id, user, idSelectedProject, startDate, endDate, 
    idProfile, users, range, colorsGraphic}) => {    
    const {t} = useTranslation();
    const [userAux, setUserAux] = React.useState(user);

    const [percentage, setPercentage] = React.useState(0)

    React.useEffect(() => {
        let perc = 0;
        if (user.totalTime > 0) {
            perc = (user.productiveTime / user.totalTime * 100).toFixed(1);
            setPercentage(perc);
        } else {
            perc = (user.productiveTime / (user.productiveTime + user.nonProductiveTime) * 100).toFixed(1);
            setPercentage(perc);
        }

        setUserAux({...user, showMoreProductive: userAux?.showMoreProductive, showMoreNotProductive: userAux?.showMoreNotProductive})
    }, [user]);

    const cardHead = () => {
      return (
        <Card id={id + "_grid_head"} sx={{padding:'0.1em', width:'100%', margin:'0.2em', height:'90px'}}>
            <Grid container>
              <Grid item xs={3}>
                <CustomAvatar url={user.imgUrl} code={user.fullNameShort} size={64} />
              </Grid>
              <Grid item xs={9} style={{textAlign:'start'}}>
                  <span title={user.fullNameShort} id={id + "_grid_headName"} style={{fontSize:'18px'}}>{user.fullNameShort.length > 22 ? user.fullNameShort.substring(0,20)+"..." : user.fullNameShort}</span>
                  <OnlyComparativeBar id={id + "_grid_headBar"} user={user}/>
                  <div style={{fontSize:'11px', display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                      <div><FontAwesomeIcon icon="clock" color={"#07074E"} className="mr-1"/>{t("dashboard_timeline.total_time")}</div>
                      <div>{formatSeconds(user.totalTime, true, false, false)}</div>
                  </div>
              </Grid>
            </Grid>
        </Card>
      );
    }

    const cardProductivity = () => {
        let val = Number(user.timeProdAux != -1 ? percentage : ((user.productiveTime / user.totalTime)*100).toFixed(1));
        return (<Card id={id + "_grid_productivity"} sx={{padding:'0.1em', width:'100%', margin:'0.2em', 
            textAlign:'center', fontWeight:'bold', background: val > range[1] ? colorsGraphic.workedOverload : val < range[0] ? colorsGraphic.nonProductive : colorsGraphic.productive}}>
            <span id={id + "_grid_productivityValue"} style={{fontSize:'20px', marginRight: '0.5em'}}>{val + "%"}</span>
            <span style={{fontSize:'16px'}}>{t("dashboard_productivity.productivity")}</span>
        </Card>);
    }

    return (
        <Card id={id} sx={{background: '#F5F5F5'}}>
            <Grid container id={id + "_grid"}>
                {cardHead()}
                {cardProductivity()}
                <div style={{width:'100%', cursor:'pointer'}} >
                    <DetailCategoriesAndApps
                        id={id+"_detailRelevant"}
                        isProductive={true}
                        user={userAux}
                        startDate={startDate}
                        endDate={endDate}
                        idProfile={idProfile}
                        idSelectedProject={idSelectedProject}
                        listProfiles={[]}
                        listCategories={[]}
                        users={users}
                        _showCatList={() => setUserAux({...userAux, showMoreProductive: !userAux.showMoreProductive})}
                    />
                </div>
                <div style={{width:'100%', cursor:'pointer'}}>
                <DetailCategoriesAndApps
                    id={id+"_detailIrrelevant"}
                    isProductive={false}
                    user={userAux}
                    startDate={startDate}
                    endDate={endDate}
                    idProfile={idProfile}
                    idSelectedProject={idSelectedProject}
                    listProfiles={[]}
                    listCategories={[]}
                    users={users}
                    _showCatList={() => setUserAux({...userAux, showMoreNotProductive: !userAux.showMoreNotProductive})}
                /></div>
            </Grid>
        </Card>
    );
};
export default ProductivityCard;