import React from "react";
import {useTranslation} from "react-i18next";
import {User} from "../../util/Interfaces";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {Container, Card, Box, Button, Chip, AvatarGroup, Avatar, Stack, Collapse, Paper, Typography, CardContent,
        Table, TableContainer, TableHead, TableBody, TableRow, TableCell, CircularProgress} from "@mui/material";
import {TrueffortButton, StyledTableCell, StyledTableRow, TrueffortTableHead, TrueffortTableHeadCell, TrueffortCardTable} from "../../MUIstyle/MUICustom";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Add, DeleteOutline, ExpandMore, Edit, Delete, Clear, Save, FileUpload, SaveAlt } from "@mui/icons-material";
import ProjectService from "../../services/project.service";

const FileDownload = require('js-file-download');

// @ts-ignore
export default function CreateFromXlsForm({_setShowXlsForm}) {
    const {t} = useTranslation();

    const uploadFile = React.useRef();
    const [uploadFileInfo, setUploadFileInfo] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [bulkUploadErrors, setBulkUploadErrors] = React.useState([]);
    const projectService = React.useMemo(() => new ProjectService(), []);

    const handleDownloadXlsx = () => {
        // @ts-ignore
        projectService.downloadEstimationXlsLayout().then((response) => {
            FileDownload(response.data, 'Layout-estimation.xls');
        }).catch((response: any) => {
            //setShowLoaderExport(false);
        });
    }

    function onChangeUploadFile() {
        setUploadFileInfo({
            name: uploadFile.current?.files[0].name,
            size: uploadFile.current?.files[0].size,
            type: uploadFile.current?.files[0].type
        });
    }

    const uploadFileElements = () => {
        setBulkUploadErrors([]);

        if (uploadFileInfo?.type !== "application/vnd.ms-excel" && uploadFileInfo?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            toast.error(t("user_page.msg_error_file_type"));
            return;
        }

        setLoading(true);
        let formData = new FormData();
        formData.append("file", uploadFile.current.files[0], uploadFile.current.files[0].name);
        try {
            // @ts-ignore
            projectService.bulkUploadProject(formData).then(({ data: response }) => {
                //console.log("RESP", response);
                if (response.errorMessage != null && response.errorMessage) {
                    toast.error(t(response.errorMessage));
                    setUploadFileInfo(null);
                    setLoading(false);
                } else {
                    if (Array.isArray(response.data.elementDtoList)) {
                        setBulkUploadErrors(response.data.elementDtoList);
                        if (response.data.elementDtoList.length > 0) {
                            setLoading(false);
                            toast.error(t("estimation_page.msg_error_bulk_upload_errors"));
                        } else {
                            toast.success(
                                t("estimation_page.msg_success_save"),
                                {
                                    onClose: () => window.location.replace("/projectDetail?idProject=" + response.data.idProject)
                                }
                            );
                            setLoading(false);
                        }
                    }
                }

                // reset file input
                let elTmp = document.getElementById("id-input-file")! as HTMLInputElement | null;
                if (elTmp != null) {
                    elTmp.value = "";
                }
            });
        } catch (err) {
            toast.error(t("user_page.msg_error_bulk_upload"));
            setLoading(false);
        }
    }

    return (
        <Container maxWidth='xl'>
            <Card elevation={2}>
                <CardContent>
                    <Typography variant="h5" color='#050536' sx={{marginBottom: "1rem"}}>
                        {t("estimation_page.generate_project")}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid xs={12} lg={4}>
                            <TrueffortButton
                                id="btnDownloadLayout"
                                size="small"
                                color="secondary"
                                variant="contained"
                                fullWidth
                                disabled={loading}
                                startIcon={<SaveAlt />}
                                onClick={() => handleDownloadXlsx()}
                            >
                                {t("estimation_page.download_layout_xls")}
                            </TrueffortButton>
                        </Grid>
                        <Grid lg={4}>
                            <Button
                                id="upload-file-button"
                                variant="outlined"
                                component="label"
                                fullWidth
                                size="small"
                            >
                                <input
                                    type="file"
                                    id="id-input-file"
                                    ref={uploadFile}
                                    name="image"
                                    onChange={onChangeUploadFile}
                                    accept="application/vnd.ms-excel"
                                    disabled={loading}
                                    readOnly={loading}
                                />
                            </Button>
                        </Grid>
                        <Grid sm={4} xs={12}>
                            <Stack
                                direction="row"
                                spacing={2}
                            >
                            <Box>
                                <TrueffortButton
                                    id="send-file-elements-button"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    onClick={() => uploadFileElements()}
                                >
                                    <FileUpload />
                                </TrueffortButton>
                                {loading && (
                                          <CircularProgress
                                            size={38}
                                            sx={{
                                              position: "absolute",
                                              marginLeft: '-52px',
                                            }}
                                          />
                                        )}
                            </Box>


                                <TrueffortButton
                                    color="error"
                                    size="small"
                                    variant={"outlined"}
                                    disabled={loading}
                                    onClick={() => {
                                        _setShowXlsForm(false);
                                        setUploadFileInfo(null);
                                        setBulkUploadErrors([]);
                                    }}
                                    style={{ fontSize: "11px" }}
                                >
                                    <Clear />
                                </TrueffortButton>
                            </Stack>
                        </Grid>
                    </Grid>

                    {bulkUploadErrors.length > 0 &&
                    <TrueffortCardTable sx={{marginTop: "1rem"}}>
                        <TableContainer component={Paper}>
                            < Table
                                responsive="true"
                                className="card-table table-vcenter text-nowrap"
                            >
                                <TrueffortTableHead>
                                    <TableRow>
                                        <TrueffortTableHeadCell>{""}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("project_page.elem_name_placeholder")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("project_page.elem_type")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("estimation_page.element_size")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("estimation_page.element_complexity")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("estimation_page.effort_man_hours")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("estimation_page.days_duration")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{""}</TrueffortTableHeadCell>
                                    </TableRow>
                                </TrueffortTableHead>
                                <TableBody>
                                    {bulkUploadErrors.map((err: any) => (
                                        <TableRow key={err.row}>
                                            <TableCell>{err.row}</TableCell>
                                            <TableCell><div className={(err.nameOk ? "" : "text-danger") + " mt-2"}>{err.name}</div></TableCell>
                                            <TableCell><div className={(err.elementTypeOk ? "" : "text-danger") + " mt-2"}>{err.elementType}</div></TableCell>
                                            <TableCell><div className={(err.sizeOk ? "" : "text-danger") + " mt-2"}>{err.size}</div></TableCell>
                                            <TableCell><div className={(err.complexityOk ? "" : "text-danger") + " mt-2"}>{err.complexity}</div></TableCell>
                                            <TableCell><div className={(err.effortOk ? "" : "text-danger") + " mt-2"}>{err.effort}</div></TableCell>
                                            <TableCell><div className={(err.durationOk ? "" : "text-danger") + " mt-2"}>{err.duration}</div></TableCell>
                                            <TableCell>
                                            {err.errorMessages.map((msg:string, i:number) => (
                                                <span key={i} className="text-danger">{t(msg)}</span>
                                            ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TrueffortCardTable>
                    }
                </CardContent>
            </Card>
        </Container>
    );
}