import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {
    TextField,
    MenuItem,
    Button,
    Stack,
    IconButton,
    Collapse,
    Typography,
    Menu,
    CircularProgress,
    Paper,
    Grid,
    ListItemIcon, ListItemText
} from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ProjectDetailTaskRow from "./ProjectDetailTaskRow";
import {TrueffortButton, TrueffortChip} from "../../MUIstyle/MUICustom";
import {Delete as DeleteIcon, Edit as EditIcon, Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import ProjectPhaseService from "../../services/projectPhase.service";
import PendingIcon from "@mui/icons-material/Pending";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { formatSeconds } from "../../util/GlobalFunctions";
import {MeasurementsContext, TaskTypesContext} from "./ProjectDetailContext";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {ID_ROLE_ADMIN, MIN_LEVEL_LEADER} from "../../util/Constants";
import { User } from "../../models/user";
import TrueffortChipStatus from "./TrueffortChipStatus";

export type PhaseType = {
    name: string
    duration: number
};

// @ts-ignore
function ProjectDetailPhaseRow ({el, phase, /*phaseType,*/ users, states, _fillProjectInfo, hidePhaseAndTask}) {
    //console.log(phaseType);
    const {t} = useTranslation();
    const {user} = useAuthContext() as { user: User };

    const taskTypes = useContext(TaskTypesContext);
    const meaurementsIDs = useContext(MeasurementsContext);

    const [open, setOpen] = React.useState(hidePhaseAndTask);
    const [isEditing, setIsEditing] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);
    const projectPhaseService = React.useMemo(() => new ProjectPhaseService(), []);

    const handleClickOptions = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorEl(null);
    };

    const validate = (values: PhaseType) => {
        let errors: any = {};
        if (!values.duration) {
            errors.duration = " ";
        }
        if (values.duration < 0) {
            errors.duration = t("project_tasks_page.msg_duration_negative");
        }
        return errors;
    }

    const handleSubmit = (values: any) => {
        setAnchorEl(null);
        let phAux = {
            name: values.name,
            estimatedDays: values.duration
        }

        // @ts-ignore
        projectPhaseService.update(phase.id, phAux).then(({data : response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                setIsEditing(false);
                toast.success(t("project_page.msg_success_phase_update"));
            }
            _fillProjectInfo();
        });
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            duration: 0
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues}) => {
            handleSubmit(values);
        }
    });

    const prepareEdit = (ph: any) => {
        setAnchorEl(null);
        setIsEditing(true);
        formik.setValues(ph);
    }

    const deleteProjectPhase = (phase: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body custom-ui'>
                        <h3 style={{marginBottom: "1em"}}>{t("project_page.msg_delete_phase_confirm2")} <span className="dark">{phase.type_name + "?"}</span></h3>
                        <p className="red"><strong>{t("project_page.msg_delete_phase_confirm3")}</strong></p>
                        <div className="react-confirm-alert-button-group">
                            <Button
                                onClick={() => {
                                    setShowLoading(true);
                                    try {
                                        // @ts-ignore
                                        projectPhaseService.delete(phase.id).then(({data : response}) => {
                                            toast.success(t("project_page.msg_success_phase_delete"));
                                            _fillProjectInfo();
                                        }).catch(function (error: any) {
                                            toast.error("Ocurrió un error al eliminar la fase.");
                                        });
                                    } catch (error) {
                                        console.log("Error #Users handleDelete" + JSON.stringify(error));
                                        toast.error("Ocurrió un error al eliminar la fase.");
                                    } finally {
                                        setShowLoading(false);
                                    }
                                    onClose();
                                }}>
                                {t("project_page.msg_delete_task_confirm_yes")}
                            </Button>
                            <Button onClick={onClose}>{t("project_page.msg_delete_task_confirm_no")}</Button>
                        </div>
                    </div>
                );
            }
        });
    }

    return (
        <>
          {!hidePhaseAndTask ? 
            !isEditing ?
                <Paper
                    variant="outlined"
                    square
                    sx={{ padding: '10px', backgroundColor: 'rgba(215, 235, 240, 0.30)' }}
                >
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={0.1} />

                        <Grid item xs={11.9} >
                            <Typography sx={{ fontSize: '0.75rem', fontWeight: '700' }} >
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                                </IconButton>
                                {phase.type_name}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} />
                        <Grid item xs={1.5} sx={{ display: "flex", justifyContent: 'center' }} >
                          <TrueffortChipStatus 
                            status_id={phase.status_id} 
                            status_name={phase.status_name} 
                          />
                        </Grid>

                        <Grid item xs={1} >
                          <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                            {phase.start_date === 0 ? '----------' : phase.start_date}
                          </Typography>
                        </Grid>

                        <Grid item xs={1} >
                          <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                            {phase.end_date === 0 ? '----------' : phase.end_date}
                          </Typography>
                        </Grid>

                        <Grid item xs={1} >
                          <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                            {phase.estimated_finish_date === 0 ? '----------' : phase.estimated_finish_date}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} >
                          <Typography align="center" color={(phase.registered_effort_hours * 3600 + phase.registered_effort_minutes * 60) == 0 ? '' : (phase.registered_effort_hours * 3600 + phase.registered_effort_minutes * 60) - (phase.effort * 3600) > 0 ? "error" : "#5eba00"} sx={{ fontSize: '0.75rem' }} >
                            {(phase.registered_effort_hours * 3600 + phase.registered_effort_minutes * 60) == 0 ? 0 : formatSeconds(Math.abs((phase.registered_effort_hours * 3600 + 
                              phase.registered_effort_minutes * 60) - (phase.effort * 3600)), false, false, false)}
                          </Typography>
                        </Grid>

                        <Grid item xs={1} >
                          <Typography align="center" color={phase.days_deviation < 0 ? "error" : "#5eba00"} sx={{ fontSize: '0.75rem' }} >
                            {Math.abs(phase.days_deviation)}
                          </Typography>
                        </Grid>

                        <Grid item xs={1} >
                          <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                            {phase.duration.toLocaleString()}
                          </Typography>
                        </Grid>

                        <Grid item xs={1} >
                          <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                            {formatSeconds(phase.effort * 3600, false, false, false)}
                          </Typography>
                        </Grid>

                        <Grid item xs={1} >
                          <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                            {phase.registered_duration.toLocaleString()}
                          </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {formatSeconds(phase.registered_effort_hours * 3600 + phase.registered_effort_minutes * 60, false, false, false)}
                            </Typography>
                        </Grid>

                        <Grid item xs={0.5} sx={{ display:'flex', justifyContent: 'center' }} >
                            {!showLoading ?
                                <>
                                    {user.role.accessLevel >= MIN_LEVEL_LEADER && 
                                    <TrueffortButton
                                        style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                        size="small"
                                        color="secondary"
                                        id="btnOptionsElement"
                                        aria-controls={openOptions ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openOptions ? 'true' : undefined}
                                        onClick={handleClickOptions}
                                    >
                                        <PendingIcon />
                                    </TrueffortButton>}

                                    <Menu
                                        id="basic-menu"
                                        sx={{
                                            '& .MuiPaper-root': {
                                                backgroundColor: '#050536'
                                            }
                                        }}
                                        anchorEl={anchorEl}
                                        open={openOptions}
                                        onClose={handleCloseOptions}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => prepareEdit(phase)}
                                        >
                                            <ListItemIcon>
                                                <EditIcon
                                                    size="small"
                                                    sx={{color: '#F5F5F5'}}
                                                />
                                            </ListItemIcon>
                                            <ListItemText sx={{ color: '#F5F5F5'}}>
                                                {t("elements.buttons.edit")}
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleCloseOptions();
                                                deleteProjectPhase(phase);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <DeleteIcon
                                                    size="small"
                                                    //className="color-red"
                                                    sx={{ color: '#989898' }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText sx={{ color: '#989898' }} >
                                                {t("delete")}
                                            </ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </>:

                                <CircularProgress size={15} />
                            }
                        </Grid>
                    </Grid>
                </Paper> :
                <Paper variant="outlined" square sx={{ padding: '10px', backgroundColor: '#DCDCDC' }} >
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={0.1} />

                        <Grid item xs={1.9} >
                            <Typography sx={{ fontSize: '0.75rem', fontWeight: '700' }} >
                                {/*<IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                                </IconButton>*/}
                                {phase.type_name}
                            </Typography>
                        </Grid>

                        <Grid item xs={1.5} sx={{ display: "flex", justifyContent: 'center' }} >
                            {phase.status_id == 4 ?
                                <TrueffortChip color="green" variant="outlined" size="small" label={phase.status_name?.toUpperCase()} /> :
                                phase.status_id == 2 ?
                                    <TrueffortChip color="secondary" variant="outlined" size="small" label={phase.status_name?.toUpperCase()} /> :
                                    <TrueffortChip color="gray" variant="outlined" size="small" label={phase.status_name?.toUpperCase()} />
                            }
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {phase.start_date === 0 ? '----------' : phase.start_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {phase.end_date === 0 ? '----------' : phase.end_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {phase.estimated_finish_date === 0 ? '----------' : phase.estimated_finish_date}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" color={phase.days_deviation < 0 ? "error" : "#5eba00"} sx={{ fontSize: '0.75rem' }} >
                                {Math.abs(phase.days_deviation) /*+ " " + t("project_tasks_page.days")*/}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <TextField
                                name="duration" type="number" min="1"
                                size="small"
                                variant="filled"
                                maxLength={6}
                                //label={t("estimation_page.effort_man_hours")}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.duration}
                                error={formik.errors?.duration && formik.touched?.duration ? t(formik.errors.duration) : null}
                            />
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {formatSeconds(phase.effort * 3600, false, false, false)
                                /*phase.effort.toLocaleString() /*+ " " + t("estimation_page.man_hours")*/}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {phase.registered_duration.toLocaleString() /*+ " " + t("project_tasks_page.days")*/}
                            </Typography>
                        </Grid>

                        <Grid item xs={1} >
                            <Typography align="center" sx={{ fontSize: '0.75rem' }} >
                                {formatSeconds(phase.registered_effort_hours * 3600 + phase.registered_effort_minutes * 60, false, false, false)
                                /*phase.registered_effort?.toFixed(0).toLocaleString()
                                    + ":" + (phase.registered_effort_minutes < 10 ? "0".concat(phase.registered_effort_minutes) : phase.registered_effort_minutes)
                                    /*+ " " + t("project_tasks_page.hp")*/
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={0.5} >
                            <Stack direction='column' alignItems='center' >
                                <TrueffortButton
                                    style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                    size="small"
                                    color="secondary"
                                    id="btnOptionsElement"
                                    aria-controls={openOptions ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openOptions ? 'true' : undefined}
                                    onClick={() => {formik.resetForm();setIsEditing(false);}}
                                >
                                    <CloseIcon />
                                </TrueffortButton>
                                <TrueffortButton
                                    style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                    size="small"
                                    color="primary"
                                    id="btnOptionsElement"
                                    aria-controls={openOptions ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openOptions ? 'true' : undefined}
                                    onClick={() => formik.handleSubmit()}
                                >
                                    <SaveIcon />
                                </TrueffortButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            : null}

            <Collapse in={open} timeout="auto" unmountOnExit>
                {!hidePhaseAndTask && <Paper variant="outlined" square sx={{ padding: '4px', backgroundColor: '#F2E9FF', borderBottomColor: '#F2E9FF', borderTopColor: '#F2E9FF' }} >
                    <Grid container spacing={1} >
                        <Grid item xs={0.5} />

                        <Grid item xs >
                            <Typography variant='caption' color='#553D74' sx={{ display: 'flex', alignItems: "center", fontWeight: '700' }} >
                                <AssignmentIcon sx={{ color: '#553D74', marginRight: '6px', width: "16px", height: "16px" }} />
                                {t("home.tasks")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>}

                {phase.tasks.map((tk: any, j: number) => (
                    <ProjectDetailTaskRow
                        key={tk.id}
                        element={el}
                        phase={phase}
                        task={tk}
                        users={users}
                        states={states}
                        _fillProjectInfo={_fillProjectInfo}
                        taskType={
                            taskTypes.find((taskT: any) =>
                                taskT.id === tk.type_id /*&&
                                (taskT.elementtype_size_id === meaurementsIDs.idSize &&
                                taskT.elementtype_complexity_id === meaurementsIDs.idComplexity ||
                                    taskT.elementtype_size_id === 0 &&
                                    taskT.elementtype_complexity_id === 0)*/
                            )
                        }
                        hidePhaseAndTask={hidePhaseAndTask}
                    />
                ))}
            </Collapse>
        </>
    );
}

export default ProjectDetailPhaseRow;