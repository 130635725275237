import React from "react";
import { Card, Paper, Table, TableBody, TableContainer, TableHead, 
  TablePagination, Box, Popover } from "@mui/material";
//import Grid from '@mui/material/Unstable_Grid2';
import '../../css/Dashboard.css'
import '../../css/hint.min.css'
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatSeconds} from "../../util/GlobalFunctions";
import { StyledTableCell, StyledTableRow, TrueffortButtonSmall } from "../../MUIstyle/MUICustom";
import OnlyComparativeBar from "./OnlyComparativeBar";
import { ArrowRight as ArrowRightIcon, Info as InfoIcon, AutoGraph as AutoGraphIcon } from "@mui/icons-material";
import DetailCategoriesAndApps from "./DetailCategoriesAndApps";
import { COLORS_RELEVANT } from "../../util/Constants";
import CustomAvatar from "../custom/CustomAvatar";
import { ResponsiveLine } from '@nivo/line';

function TableProductivityCard({id, recordsTimeline, showLoader, acceptClick, showAll, 
  startDate, endDate, idProfile, idSelectedProject, height, objDataLineMedias, getDataGraphicUser, configToolTip}) {
    //console.log(recordsTimeline);
  const {t} = useTranslation();
  const [recordsTimelineAux, setRecordsTimelineAux] = React.useState([]);
  let ROWS_PER_PAGE = 9;
  const [rowsPerPageUsers, setRowsPerPageUsers] = React.useState(ROWS_PER_PAGE);
  const [usersPage, setUsersPage] = React.useState(0);
  const [usersCount, setUsersCount] = React.useState(recordsTimeline.length);//Cantidad de categorias
  const [anchorsProductive, setAnchorsProductive] = React.useState(recordsTimeline.map(u => null));
  const openProductive = anchorsProductive.map(u => Boolean(u));
  const ids = openProductive.map(o => o ? 'simple-popper' : undefined);
  const [anchorsNotProductive, setAnchorsNotProductive] = React.useState(recordsTimeline.map(u => null));
  const openNotProductive = anchorsNotProductive.map(u => Boolean(u));
  const idsNotProductive = openProductive.map(o => o ? 'simple-popper' : undefined);
  const [anchorsProductiveLine, setAnchorsProductiveLine] = React.useState(recordsTimeline.map(u => null));
  const openProductiveLine = anchorsProductiveLine.map(u => Boolean(u));
  
  React.useEffect(() => {
    setRecordsTimelineAux(recordsTimeline.map(u => {return {...u, showMoreProductive: false, showMoreNotProductive: false}}));
  }, []);

  React.useEffect(()=>{
    setUsersCount(recordsTimeline.length);
    setRecordsTimelineAux(recordsTimeline.map(u => {return {...u, showMoreProductive: false, showMoreNotProductive: false}}));
  },[recordsTimeline]);

    //componente que forma la columna de Tiempo total
  const getComponentTotalTime = (scheduleRegister, timeRegistred, nonProductiveTime, productiveTime) => {
    return (
      <div style={{display:"flex", alignItems:"center"}}>
        <span style={{fontSize: '12px', minWidth:'50px'}}>{formatSeconds(timeRegistred, false, false, true)}</span>
        {scheduleRegister != null && timeRegistred > scheduleRegister &&
          <span className="hint--top hint--info hint--rounded" data-hint={t("dashboard_timeline.msg_2")} key={"msg_2"}>
            <InfoIcon htmlColor={"#ED9A55"} sx={{fontSize: showAll ? '16px' : '14px', '&:hover': {color: "#7C6924"}}} />
          </span>
        }
        {nonProductiveTime > productiveTime &&
          <span className="hint--top hint--warning hint--rounded" data-hint={t("dashboard_timeline.msg_3")} key={"msg_3"}>
            <InfoIcon htmlColor={"#ED9A55"} sx={{fontSize: showAll ? '16px' : '14px', '&:hover': {color: "#7C6924"}}} />
          </span>
        }
        {scheduleRegister != null && timeRegistred < scheduleRegister &&
          <span className="hint--top hint--warning hint--rounded" data-hint={t("dashboard_timeline.msg_4")} key={"msg_4"}>
            <InfoIcon htmlColor={"#ED9A55"} sx={{fontSize: showAll ? '16px' : '14px', '&:hover': {color: "#7C6924"}}} />
          </span>
        }
      </div>
    );
  }

  const handleChangeUsersRowsPerPage = (event) => {
    setRowsPerPageUsers(parseInt(event.target.value, 10));
    setUsersPage(0);
  }
    
  const handleChangeUsersPage = (event, newPage) => {
    setUsersPage(newPage);
  }

  const handleClickProductive = (event, i) => {
    if(acceptClick) {
      let a = anchorsProductive.map(aP => aP);
      a[i] = anchorsProductive[i] ? null : event.currentTarget;
      setAnchorsProductive(a);
    }
  };

  const handleClickProductiveLine = (event, i) => {
    let a = anchorsProductiveLine.map(aP => aP);
    a[i] = anchorsProductiveLine[i] ? null : event.currentTarget;
    setAnchorsProductiveLine(a);
  };

  const handleCloseProductive = () => {
    setAnchorsProductive(anchorsProductive.map(aP => null));
  };

  const handleClickNotProductive = (event, i) => {
    if(acceptClick) {
      let a = anchorsNotProductive.map(aP => aP);
      a[i] = anchorsNotProductive[i] ? null : event.currentTarget;
      setAnchorsNotProductive(a);
    }
  };

  const handleCloseNotProductive = () => {
    setAnchorsNotProductive(anchorsNotProductive.map(aP => null));
  };

  const getResponsiveLine = (data) => {
    return (<ResponsiveLine
      data={data}
      margin={{ top: 30, right: 30, bottom: 90, left: 50 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 0, max: 'auto',
        stacked: false, reverse: false
      }}
      colors={{ scheme: 'category10' }}
      yFormat=" >-.2f"
      axisBottom={{tickRotation: -90}}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel="data.yFormatted"
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      pointSize={4}
      useMesh={true}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('dashboard.productivity')+ ' (%)',
        legendPosition: 'middle',
        legendOffset: -32,
        truncateTickAt: 0
      }}
      legends={[{ anchor: 'top', direction: 'row', justify: false,
        translateX: 0, translateY: -20, itemsSpacing: 0, itemDirection: 'left-to-right',
        itemWidth: 80, itemHeight: 20, itemOpacity: 0.75, symbolSize: 12,
        symbolShape: 'circle', symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [ { on: 'hover', style: { 
          itemBackground: 'rgba(0, 0, 0, .03)', itemOpacity: 1 } } ]
      }]}
      tooltip={({ point }) => {
        return (
          <>
            {configToolTip(point, 100, 600)}
          </>
        )
      }}
    />);
  }

  return (
    <div style={{width:'100%'}}>
      {!showLoader && recordsTimelineAux.length !== 0 &&
        <Card className="card-project" key={"userMain"} >
          <TableContainer component={Paper} sx={{height: height ? height : 'none'}}>
            <Table sx={{minWidth:600}}>
              <TableHead>
                <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                  <StyledTableCell style={{ fontSize: '12px', padding: '0.2em', width: '40px' }} />
                  <StyledTableCell style={{ fontSize: '12px', padding: '0.2em', width:'80px'  }}>{t("dashboard_timeline.user")}</StyledTableCell>
                  <StyledTableCell style={{ fontSize: '12px', padding: '0', width: showAll ? '250px' : '150px'}}><FontAwesomeIcon icon="chart-area" color={"#9A64E2"} className="mr-1"/>{t("dashboard_timeline.productivity")}</StyledTableCell>
                  {showAll && <StyledTableCell style={{ fontSize: '12px', padding: 0  }}><FontAwesomeIcon icon="check-circle" color={"#78B737"} className="mr-1"/>
                    {t("dashboard_timeline.productive")}
                  </StyledTableCell>}
                  {showAll && <StyledTableCell style={{ fontSize: '12px', padding: 0  }}><FontAwesomeIcon icon="window-close" color={"#E4555A"} className="mr-1"/>{t("dashboard_timeline.non_productive")}</StyledTableCell>}
                  {showAll && <StyledTableCell style={{ fontSize: '12px', padding: 0  }}><FontAwesomeIcon icon="circle" color={"#BDBDBD"} className="mr-1"/>{t("dashboard_timeline.unregistered")}</StyledTableCell>}
                  <StyledTableCell style={{ fontSize: '12px', padding: 0, width: showAll ? '150px' : '100px' }}><FontAwesomeIcon icon="clock" color={"#07074E"} className="mr-1"/>{t("dashboard_timeline.total_time")}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {recordsTimelineAux.slice(usersPage * rowsPerPageUsers, usersPage * rowsPerPageUsers + rowsPerPageUsers).map((user, k) => (
                  <>
                    <StyledTableRow id={id+"_row_"+k} key={user.idUser}>
                      <StyledTableCell id={id+"_row_"+k+"_column_1"} style={{width:'40px'}}>
                        <CustomAvatar 
                          url={user.imgUrl}
                          code={user.name}
                          size={32}
                        />
                      </StyledTableCell>
                      <StyledTableCell id={id+"_row_"+k+"_column_2"} key={"name"} style={{fontSize: showAll ? '12px' : '11px', width: showAll ? '150px' : '80px'}}>
                          {showAll ? user.fullName : user.fullNameShort}
                      </StyledTableCell>
                      <StyledTableCell id={id+"_row_"+k+"_column_3"} key={"productivity"} 
                        style={{fontSize: showAll ? '12px': '11px', width: showAll ? '250px' : '150px'}}>
                          <div style={{display:'flex', alignContent:'center', flexDirection:'row', width:'100%'}}>
                              <div style={{width:'15%', marginTop:'5px'}}>{(((user.productiveTime) / (user.totalTime != 0 ? user.totalTime : user.timeRegistred)) * 100).toFixed(1) + "%"}</div>
                              <OnlyComparativeBar 
                                  user={user}
                              />
                          </div>
                      </StyledTableCell>
                      {showAll && <StyledTableCell id={id+"_row_"+k+"_column_4"} key={"productive"} style={{width:'200px', fontSize: showAll ? '12px' : '11px'}}>
                        <div aria-describedby={ids[k]} style={{display:'flex', justifyContent: acceptClick ? 'space-around' : '', cursor: acceptClick ? 'pointer' : ''}} 
                          onClick={(event) => handleClickProductive(event, k)}>
                          <span>{formatSeconds(user.productiveTime, false, false, true)}</span>
                          {acceptClick && <span><ArrowRightIcon /></span>}
                        </div>
                        {acceptClick && <div className="div-app-windows" style={{display:'flex', flexDirection:'row', width:'98%', padding: 0}}>
                          <span className={acceptClick ? "time-graphical-line-wrapper" : "time-graphical-line-wrapper-non-click"} style={{width:'100%'}}>
                            {user.categoriesRelevant != undefined && user.categoriesRelevant.map((c, i_) => 
                              <span key={"productive_"+i_} 
                                className={(user.nonProductiveTime==0 && (user.unrecordedTime==0 || user.unrecordedTime == null)?"time-extract-unique": i_ == 0 ? "time-extract-first" : i_ == (user.categoriesRelevant.length-1) ? "time-extract-last" : "")}
                              style={{width: (c.totalSeconds * 95 / user.productiveTime)+"%", 
                                background: COLORS_RELEVANT[i_], height:'5px'}}></span>
                            )}
                          </span>
                        </div>}
                        <Popover id={ids[k]} open={openProductive[k]} anchorEl={anchorsProductive[k]} onClose={handleCloseProductive}>
                          <Box sx={{ border: 0, p: '0.1em', bgcolor: 'background.paper' }}>
                            <DetailCategoriesAndApps
                              id={"detailCategoriesAndApps_" + k}
                              key={"detailCategoriesAndApps_" + k}
                              isProductive={true}
                              user={user}
                              startDate={startDate}
                              endDate={endDate}
                              idProfile={idProfile}
                              idSelectedProject={idSelectedProject}
                              listProfiles={[]}
                              listCategories={[]}
                              defaultShowMore={true}
                            />
                          </Box>
                        </Popover>
                      </StyledTableCell>}
                      {showAll && <StyledTableCell id={id+"_row_"+k+"_column_5"} key={"nonProductive"} style={{width:'200px', fontSize: showAll ? '12px' : '11px'}}>
                        <div style={{display:'flex', justifyContent: acceptClick ? 'space-around' : '', cursor:acceptClick ? 'pointer' : ''}} 
                            onClick={(event) => handleClickNotProductive(event, k)}
                        >
                          <span>{formatSeconds(user.nonProductiveTime, false, false, true)}</span>
                          {acceptClick && <span><ArrowRightIcon /></span>}
                        </div>
                        {acceptClick && <div className="div-app-windows" style={{display:'flex', flexDirection:'row', width:'98%', padding:0}}>
                          <span className={acceptClick?"time-graphical-line-wrapper":"time-graphical-line-wrapper-non-click"} style={{width:'100%'}}>
                              {user.categoriesNotRelevant != undefined && user.categoriesNotRelevant.map((c, i_) => 
                                <span key={"productive_"+i_} className={(user.nonProductiveTime==0 && (user.unrecordedTime==0 || user.unrecordedTime == null)?"time-extract-unique": i_ == 0 ? "time-extract-first" : i_ == (user.categoriesRelevant.length-1) ? "time-extract-last" : "")}
                                  style={{width: (c.totalSeconds*95/user.nonProductiveTime)+"%", background: COLORS_RELEVANT[i_], height:'5px'}}></span>
                              )}
                          </span>
                        </div>}
                        <Popover id={idsNotProductive[k]} open={openNotProductive[k]} anchorEl={anchorsNotProductive[k]} onClose={handleCloseNotProductive}>
                          <Box sx={{ border: 0, p: '0.1em', bgcolor: 'background.paper' }}>
                            <DetailCategoriesAndApps
                              id={"detailNotProductiveCategoriesAndApps_" + k}
                              key={"detailNotProductiveCategoriesAndApps" + k}
                              isProductive={false}
                              user={user}
                              startDate={startDate}
                              endDate={endDate}
                              idProfile={idProfile}
                              idSelectedProject={idSelectedProject}
                              listProfiles={[]}
                              listCategories={[]}
                              defaultShowMore={true}
                            />
                          </Box>
                        </Popover>
                      </StyledTableCell>}
                      {showAll && <StyledTableCell id={id+"_row_"+k+"_column_6"} key={"unregistred"} style={{fontSize: showAll ? '12px' : '11px', width:'150px'}}>
                        <div style={{display: "flex"}}>
                          {(user.unrecordedTime==0 || user.unrecordedTime==null)?'--':formatSeconds(user.unrecordedTime, false, false, true)}
                          {(user.unrecordedTime == 0 || user.unrecordedTime == null) &&
                            <span className="hint--top hint--info hint--rounded" 
                              data-hint={t("dashboard_timeline.msg_1")}>
                                <FontAwesomeIcon icon="info-circle" color={"#00428B"} className="mr-1"/>
                            </span>}
                        </div>
                      </StyledTableCell>}
                      <StyledTableCell id={id+"_row_"+k+"_column_7"} key={"totalTime"}
                        style={{fontSize: showAll ? '12px': '11px', width: showAll ? '150px' : '100px', display:'flex'}}>
                          {getComponentTotalTime(user.scheduleRegister, user.totalTime, user.nonProductiveTime, user.productiveTime)}
                          {!showAll && <TrueffortButtonSmall
                            startIcon={<AutoGraphIcon />}
                            color="secondary" 
                            variant='contained'
                            style={{marginLeft: "3em", fontSize:'11px'}}
                            onClick={(event) => handleClickProductiveLine(event, k)} />}
                      </StyledTableCell>
                    </StyledTableRow>
                    {openProductiveLine[k] && <StyledTableRow>
                      <StyledTableCell colSpan={4}>
                        <div style={{ border: 0, p: '0.1em', bgcolor: 'background.paper', height:'250px', width:'100%' }}>
                          {getResponsiveLine(getDataGraphicUser([user, objDataLineMedias.avg, objDataLineMedias.min, objDataLineMedias.max]))}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>}
                  </>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
            {(!showAll || acceptClick) && 
              <TablePagination
                id={id+"_pagination"}
                labelRowsPerPage={t("dashboard_productivity.pagination")}
                rowsPerPageOptions={[4, 8, 12]}
                component="div"
                onRowsPerPageChange={handleChangeUsersRowsPerPage}
                onPageChange={handleChangeUsersPage}
                count={usersCount}
                rowsPerPage={rowsPerPageUsers}
                page={usersPage}
              />
            }
        </Card>
        }
    </div>
  );
}

export default TableProductivityCard;