import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ProjectTaskService {

    getAll() {
        return http?.get(baseURL.readApi.projectTask + "/");
    }

    getByProjects(data : any) {
        return http?.post(baseURL.readApi.projectTask + "/findByProjects", data);
    }

    create(data : any) {
        return http?.post(baseURL.activitiesapi.projectTask + "/", data);
    }

    delete(id : number) {
        return http?.delete(baseURL.activitiesapi.projectTask + `/${id}`);
    }

    update(id : number, data : any) {
        return http?.put(baseURL.activitiesapi.projectTask + `/${id}`, data);
    }

    updateElementAsTask(id:number, data: any) {
      return http?.patch(baseURL.activitiesapi.projectTask + `/${id}`, data);
    }

    assignUser(idProjectTask: number, data: any) {
        return http?.post(baseURL.activitiesapi.projectTask + `/assignUser/${idProjectTask}`, data);
    }

    assignUsers(idProjectTask: number, data: any) {
        return http?.post(baseURL.activitiesapi.projectTask + "/assignUsersToTask", data);
    }

    removeUserFromTask(idProjectTask: number, data: any) {
        return http?.patch(baseURL.activitiesapi.projectTask + `/removeUserFromTask/${idProjectTask}`, data);
    }

    activate(id: number) {
        return http?.put(baseURL.activitiesapi.projectTask + "/active/" + id);
    }

    findUsers(id: number) {
        return http?.get(baseURL.readApi.projectTask + `/findUsers/${id}`);
    }

    changeStatus(idProjectTask: number, idStatus: number) {
        return http?.put(baseURL.activitiesapi.projectTask + "/" + idProjectTask + "/changeStatus/" + idStatus);
    }

}

export default ProjectTaskService;