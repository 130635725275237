import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class SiteService {

    findAll() {
        return http?.get(baseURL.activitiesapi.sites);
    }

    create(data: any) {
        const options = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }, transformRequest: () => data
        }
        //http?.options("/", args)
        return http?.post(baseURL.activitiesapi.sites + "/", data, options);
    }

    updateImage(id: string, data: any) {
        const options = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }, transformRequest: () => data
        }
        //http?.options("/", args)
        return http?.put(baseURL.activitiesapi.sites + `/${id}`, data, options);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.sites + `/${id}`);
    }

    setRelevant(idSite: number, idUser: number) {
        return http?.put(baseURL.activitiesapi.sites + "/setRelevant/" + idSite + "/user/" + idUser);
    }

    setNotRelevant(idSite: number, idUser: number) {
        return http?.put(baseURL.activitiesapi.sites + "/setNotRelevant/" + idSite + "/user/" + idUser);
    }

    setRelevantProfile(idSite: number, idProfile: number) {
        return http?.put(baseURL.activitiesapi.sites + "/setRelevant/" + idSite + "/profile/" + idProfile);
    }

    setNotRelevantProfile(idSite: number, idProfile: number) {
        return http?.put(baseURL.activitiesapi.sites + "/setNotRelevant/" + idSite + "/profile/" + idProfile);
    }
}
export default SiteService;