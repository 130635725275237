import { Badge } from "@mui/material";
import CustomAvatar from "./CustomAvatar";

// @ts-ignore
const CustomAvatarBadge = ({name, urlImage, isActive}) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isActive != null ?
          <Badge variant="dot" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{
              '& .MuiBadge-badge': { right: 5, top: 12, border: `2px solid #EFEFEF`,
                backgroundColor: isActive ? '#78B737' : '#989898', width: '15px',
                height: '15px', borderRadius: '64px'
              }
            }}
          >
            <CustomAvatar 
              url={urlImage}
            />
          </Badge> : 
          <CustomAvatar 
            url={urlImage}
          />
        }
        <div style={{ paddingLeft: '0.2em' }}>{name}</div>
      </div>
    );
};

export default CustomAvatarBadge;