import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Page } from 'tabler-react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Stack,
    Menu, MenuItem, Stepper, Step, StepLabel, CardHeader, CardActions, Collapse
} from '@mui/material';
import {TrueffortButton, TrueffortButtonSmall, TrueffortChip, TrueffortNavButton} from '../MUIstyle/MUICustom';
import { useHistory } from 'react-router-dom';
import infoJiraProjectsService from '../services/infoJiraProjects.service';
import InstanceService from "../services/instance.service";
import SiteWrapper from '../SiteWrapper.react';
import { toast } from 'react-toastify';
import {useAuthContext} from "../contexts/AuthenticationContext";
import {User} from "../util/Interfaces";
import {ExpandMore as ExpandMoreICon, ExpandLess as ExpandLessICon, KeyboardArrowDown} from '@mui/icons-material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import Grid from '@mui/material/Unstable_Grid2';
import ProjectService from "../services/project.service";
import CustomIconTrueffortScreen from "../components/custom/CustomIconTrueffortScreen";
import infoAzureProjectService from "../services/InfoAzureProject.service";
import ApiInfoForm from "../components/jira/ApiInfoForm";
import APISelect from "../components/jira/APISelect";

// Define una interfaz Project con propiedades id del proyecto a seleccionar y name del proyecto a seleccionar
interface Project {
    id: string;
    name: string;
}

const steps: string[] = ['select_tool', 'submit_info'];

function ProjectsExternalAPI() {
    const { user } = useAuthContext() as { user: User };
    const [organizationDomain, setOrganizationDomain] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [projects, setProjects] = useState([]);
    const [trueffortProjects, setTrueffortProjects] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false); // Nuevo estado para controlar la carga
    const [tasks, setTasks] = useState([]);
    const { t } = useTranslation();
    const history = useHistory();
    const instanceService = React.useMemo(() => new InstanceService());
    const projectService = React.useMemo(() => new ProjectService());
    const [showLoader, setShowLoader] = React.useState(true);
    const [showMore, setShowMore] = React.useState(true);
    const [instanceData, setInstanceData] = React.useState({});
    const [selectedAPI, setSelectedAPI] = React.useState("");
    const [activeStep, setActiveStep] = React.useState(0);

    const [errors, setErrors] = useState({
        organizationDomain: '',
        email: '',
        token: ''
    });

    React.useEffect(() => {
        // Una vez que se llenaron los proyectos de JIRA, consultar los proyectos de trueffort, para determinar cuáles ya están guardados
        getTrueffortProjects();
    }, [projects]);

    //console.log("TRUEFFORT PROJECT", trueffortProjects);

    const fillConnectionParams = () => {
        switch (selectedAPI) {
            case "Jira REST API":
                getJiraParams();
                break;

            case "Azure REST API":
                getAzureParams();
                break;
        }
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <APISelect handleToolSelect={handleToolSelection} />
                );

            case 1:
                return (
                    <ApiInfoForm
                        t={t}
                        selectedAPI={selectedAPI}
                        organizationDomainState={{
                            organizationDomain: organizationDomain,
                            setOrganizationDomain: setOrganizationDomain
                        }}
                        emailState={{
                            email: email,
                            setEmail: setEmail
                        }}
                        tokenState={{
                            token: token,
                            setToken: setToken
                        }}
                        errors={errors}
                    />
                );

            default:
                throw new Error('Unknown step');
        }
    }

    const getJiraParams = () => {
        infoJiraProjectsService.getConnectionInfo().then(({data: response} : any) => {
            if (response.success) {
                const connectionInfo = response.data;
                let allParams: boolean = true;

                if (typeof connectionInfo.email != "undefined" && connectionInfo.email != "" && connectionInfo.email != null) {
                    setEmail(connectionInfo.email);
                } else {
                    allParams = false;
                }
                setOrganizationDomain(connectionInfo.dominio);
                setToken(connectionInfo.token);
                //setShowMore(!allParams);

                if (allParams) {
                    setLoading(true);
                    try {
                        infoJiraProjectsService.getProjects(
                            connectionInfo.dominio,
                            connectionInfo.email,
                            connectionInfo.token
                        ).then(({data:response}: any) => {
                            if (response.length === 0) {
                                toast.warning(t('Herramienta.no_projects_found')); // Mostrar toast si no se encontraron proyectos
                            } else {
                                //console.log("JIRA P", response);
                                setProjects(response);
                                setLoaded(true); // Marcamos que los proyectos se han cargado
                                setShowMore(false);
                            }
                        });
                    } catch (error) {
                        toast.error(t('Herramienta.connection_error')); // Agregamos el toast de error
                    } finally {
                        setLoading(false); // Finaliza la carga, independientemente del resultado
                    }
                }
            } else {
                organizationDomain !== '' && setOrganizationDomain('');
                email !== '' && setEmail('');
                token !== '' && setToken('');
                projects.length > 0 && setProjects([]);
                setLoaded(false);
                //setShowMore(true);
            }
        }).catch((c:any) => {
            toast.error(c.response.data);
        }).finally(() => setShowLoader(false));;
    }

    const getAzureParams = () => {
        infoAzureProjectService.getConnectionInfo().then(({data: response} : any) => {
            if (response.success) {
                const connectionInfo = response.data;
                let allParams: boolean = true;

                setOrganizationDomain(connectionInfo.dominio);
                setToken(connectionInfo.token);
                //setShowMore(!allParams);

                if (allParams) {
                    setLoading(true);
                    try {
                        infoAzureProjectService.getProjects(
                            connectionInfo.dominio,
                            connectionInfo.token
                        ).then(({ data: response }: any) => {
                            console.log("AZURE P", response);
                            if (response.value.length > 0) {
                                setProjects(response.value);
                                setLoaded(true);
                                setShowMore(false);
                            } else {
                                toast.warning(t('Herramienta.no_projects_found'));
                            }
                        });
                    } catch (error) {
                        toast.error(t('Herramienta.connection_error'));
                    } finally {
                        setLoading(false);
                    }
                }
            } else {
                organizationDomain !== '' && setOrganizationDomain('');
                email !== '' && setEmail('');
                token !== '' && setToken('');
                projects.length > 0 && setProjects([]);
                setLoaded(false);
                //setShowMore(true);
            }
        }).catch((c: any) => {
            toast.error(c.response.data);
        }).finally(() => setShowLoader(false));
    }


    // Define una función asíncrona handleGetProjects que valida los campos, muestra un indicador de carga, intenta
    // obtener los proyectos de Jira usando el servicio infoJiraProjectsService, actualiza el estado de projects y loaded,
    // y finaliza la carga independientemente del resultado
    const handleGetProjects = async () => {
        const isValid = validateFields();
    
        if (isValid) {
            setLoading(true); // Indicar que se están cargando los proyectos
            try {
                let response;
                let projectData;
                if (selectedAPI === "Jira REST API") {
                    response = await infoJiraProjectsService.getProjects(organizationDomain, email, token);
                    projectData = response.data;
                    setProjects(projectData);
                } else if (selectedAPI === "Azure REST API") {
                    response = await infoAzureProjectService.getProjects(organizationDomain, token);
                    // Accede a los datos de la respuesta correctamente
                    projectData = response.data.value;
                    setProjects(projectData);
                }
                setLoaded(true); // Marcamos que los proyectos se han cargado
                if (projectData.length === 0) {
                    toast.error(t('Herramienta.no_projects_found')); // Mostrar toast si no se encontraron proyectos
                } else {
                    setShowMore(false);
                }
            } catch (error) {
                toast.error(t('Herramienta.connection_error')); // Agregamos el toast de error
            } finally {
                setLoading(false); // Finaliza la carga, independientemente del resultado
            }
        }
    };

    const getTrueffortProjects = () => {
        // @ts-ignore
        projectService.getProjectsInteroperabilityInfo().then(async ({data: response}) => {
            if (Array.isArray(response.data)) {
                //console.log("TRUEFFORT PROJECTS", response.data);
                setTrueffortProjects(response.data);
            }
        });
    }

    const isProjectInTrueffort = (idProjectJira: string) => {
        //console.log("isProjectInTrueffort: " + idProjectJira);
        if (trueffortProjects.length > 0 && typeof idProjectJira != "undefined") {
            let exists = trueffortProjects.find((e:any) => e.idProyectoHerramienta === idProjectJira);
            return typeof exists != "undefined";
        } else {
            return false;
        }
    }

    const getIdProjectInTrueffort = (idProjectJira: string) => {
        //console.log("isProjectInTrueffort: " + idProjectJira);
        if (trueffortProjects.length > 0 && typeof idProjectJira != "undefined") {
            let exists = trueffortProjects.find((e:any) => e.idProyectoHerramienta === idProjectJira);
            if (typeof exists != "undefined") {
                return exists.idProject;
            } else {
                return -1;
            }
        } else {
            return -1;
        }
    }

    // Define una función handleSelectProject que toma un projectId y un projectName, y navega a la 
    // ruta /jiraProjects pasando estos valores junto con organizationDomain, email y token como estado para usarlos en la siguiente petición al back
    const handleSelectProject = (projectId: any, projectName: string, trueffortId: number) => {
        // Navegar a la ruta /jiraProjects o /azureProjects, según sea el caso, y pasar el projectId como parámetro
        history.push('/apiProjectDetails', { selectedAPI, projectId, projectName, organizationDomain, email, token, trueffortId });
    };

    const validateFields = () => {
        let isValid = true;
        const newErrors = {
            organizationDomain: '',
            email: '',
            token: ''
        };

        if (!organizationDomain.trim()) {
            isValid = false;
            newErrors.organizationDomain = t("Herramienta.no_domain");
        }

        if (selectedAPI === "Jira REST API" && !email.trim()) {
            isValid = false;
            newErrors.email = t("Herramienta.no_email");
        } else if (selectedAPI === "Jira REST API" && !/\S+@\S+\.\S+/.test(email)) {
            isValid = false;
            newErrors.email = t("Herramienta.invalid_email");
        }

        if (!token.trim()) {
            isValid = false;
            newErrors.token = t("Herramienta.no_token");
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleToolSelection = (selectedTool: number) => {
        setActiveStep(activeStep + 1);

        if (selectedTool === 0) {
            setSelectedAPI("Jira REST API");
            getJiraParams();
        }

        if (selectedTool === 1) {
            setSelectedAPI("Azure REST API")
            getAzureParams();
        }
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        setProjects([]);
        setTrueffortProjects([]);
    }

    return (
        <SiteWrapper>
            <Box className="my-3 my-md-5">
                <Container>
                    <Stack spacing={1} direction='row' justifyContent="space-between" alignItems="center" >
                        <Page.Title className="m-4">
                            {t('Herramienta.interoperability')}
                        </Page.Title>
                    </Stack>

                    <Card elevation={2}>
                        <CardContent>
                            <Collapse
                                in={showMore}
                                timeout="auto"
                                unmountOnExit
                                sx={{width: "100%"}}
                            >
                                <Stack direction="column" spacing={5} >
                                    <Stepper activeStep={projects.length > 0 ? 2 : activeStep} alternativeLabel>
                                        {steps.map((label: string) =>
                                            <Step key={label} >
                                                <StepLabel>{t('Herramienta.' + label)}</StepLabel>
                                            </Step>
                                        )}
                                    </Stepper>

                                    {getStepContent(activeStep)}

                                    {activeStep !== 0 && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column-reverse', sm: 'row' },
                                                justifyContent: 'space-evenly'
                                            }}
                                        >
                                            <TrueffortButton
                                                //startIcon={<ChevronLeftRoundedIcon />}
                                                onClick={handleBack}
                                                variant="text"
                                                /*sx={{
                                                    display: { xs: 'none', sm: 'flex' },
                                                }}*/
                                            >
                                                {t('Herramienta.back')}
                                            </TrueffortButton>

                                            <TrueffortButton
                                                variant="contained"
                                                color="primary"
                                                //endIcon={<ChevronRightRoundedIcon />}
                                                onClick={handleGetProjects}
                                            >
                                                {t('Herramienta.get')}
                                            </TrueffortButton>
                                        </Box>
                                    )}
                                </Stack>
                            </Collapse>
                        </CardContent>

                        <CardActions sx={{display:'flex', justifyContent: 'center'}} >
                            {(activeStep !== 0 && projects.length > 0) &&
                                <TrueffortButtonSmall
                                    id="idBtnCollapse"
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    {showMore ?
                                        <ExpandLessICon sx={{fontSize: ".9rem"}} /> :
                                        <ExpandMoreICon sx={{fontSize: ".9rem"}} />
                                    }
                                    {t("Herramienta.edit_params")}
                                </TrueffortButtonSmall>
                            }
                        </CardActions>
                    </Card>
                </Container>
                <Box className="my-3 my-md-5">
                    <Container>
                        <Box mt={3} ml={2}>
                            <Page.Title className="m-4">
                                {t('Herramienta.projects')}
                            </Page.Title>
                            {loading ? ( /* Si loading es verdadero, renderiza lo siguiente */
                                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                                    <CircularProgress /> {/* Muestra un CircularProgress (indicador de carga) */}
                                </Grid>
                            ) : loaded ? (
                                projects.map((project: any) => (  /* Mapea el arreglo projects y renderiza lo siguiente por cada proyecto */
                                    <Card
                                        key={project.id} /* Utiliza el id del proyecto como clave única */
                                        sx={{
                                            border: "solid thin #64A8EC",
                                            borderRadius: "10px",
                                            marginTop: "12px",
                                            padding: "12px",
                                            borderLeft: " solid 8px #64A8EC",
                                        }}
                                    >
                                        <Grid container>
                                            <Grid xs={8}>
                                                <span className="home-project-detail-label">{t('project_tasks_page.title')}</span>
                                                <h5>{project.name}</h5> {/* Muestra el nombre del proyecto */}
                                            </Grid>
                                            <Grid xs={2} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSelectProject(project.id, project.name, getIdProjectInTrueffort(project.id))} /* Llama a handleSelectProject con el id y nombre del proyecto al hacer click */
                                                >
                                                    {t('Herramienta.select_project')}
                                                </Button>
                                            </Grid>

                                            <Grid xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                                {isProjectInTrueffort(project.id) &&
                                                    <TrueffortChip color="green" variant="outlined" size="small" label={"EN TRUEFFORT"} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ))
                            ) : ( /* Si loading y loaded son falsos, renderiza lo siguiente */
                                <Card sx={{ border: "solid thin #64A8EC", borderRadius: "10px", marginTop: "12px", padding: "12px", borderLeft: " solid 8px #64A8EC" }}>
                                    <Grid container>
                                        <Grid xs={12} sx={{ textAlign: "center" }}>
                                            <span className="home-project-detail-label">{t('Herramienta.no_projects')}...</span>
                                        </Grid>
                                    </Grid>
                                </Card>
                            )}
                        </Box>

                    </Container>
                </Box>
            </Box>
        </SiteWrapper>
    );
}

export default ProjectsExternalAPI;
