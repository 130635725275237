// @flow

import { Grid } from "@mui/material";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { TrueffortButton } from "../MUIstyle/MUICustom";

function Error403(){
  let history = useHistory();

  return <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
    <Grid style={{fontSize:'5em', color:'gray'}}>{"403"}</Grid>
    <Grid style={{fontSize:'2em', color:'black'}}>{t("error_403.message_title")}</Grid>
    <Grid style={{fontSize:'1.2em', color:'gray'}}>{t("error_403.message_body")}</Grid>
    <div>
      <TrueffortButton 
        id="idBtnGoBack"
        color='primary'
        variant='contained'
        fullWidth
        onClick={() => history.goBack()}>{"Volver"}
      </TrueffortButton>
    </div>
  </div>;
}

export default Error403;
