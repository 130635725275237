import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {UserStatus} from "../projectDetail/ProjectDetailTaskRow";
import {
    Chip, Collapse, FormControlLabel, Grid, IconButton, Paper, Stack,
    SvgIcon, Switch, TextField, Tooltip, Typography
} from "@mui/material";
import {Error as  ErrorIcon, ArrowDropDown as ArrowDropDownIcon, ArrowRight as ArrowRightIcon,
  Delete as DeleteIcon, Add as AddIcon, ReportProblem as ReportProblemIcon
} from "@mui/icons-material";
import {TrueffortButton, TrueffortChip, TrueffortConfirmAlert} from "../../MUIstyle/MUICustom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import ProjectDetailUserCard from "../projectDetail/ProjectDetailUserCard";
import AssignUsersDrawer from "../AssignUsersDrawer";
import {useProjectPlanDispatch} from "./ProjectPlanContext";
import {DurationTypeContext, UsersContext} from "./EstimationPageUtils";
import {confirmAlert} from "react-confirm-alert";

export default function CollapsibleActivities(props: any) {

    const {t} = useTranslation();

    const projectPlanDispatch = useProjectPlanDispatch();
    const users = useContext(UsersContext);
    const statisticalRange = useContext(DurationTypeContext);

    const [showUsers, setShowUsers] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [changeValuesOnAssignedUsers, setChangeValuesOnAssignedUsers] = React.useState(false);
    const [durationFocused, setDurationFocused] = React.useState(false);
    const [effortFocused, setEffortFocussed] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState(
        props.task.projectTask.assignedUsers.length
    );
    const [rankedUsers, setRankedUsers] = React.useState(
      props.task.taskType.users.map((user: any) => (
        {
          ...user,
          status: props.task.projectTask.assignedUsers.includes(user.id) ?
            UserStatus.Assigned :
            UserStatus.Unassigned
        }
      ))
    );
    const [notRankedUsers, setNotRankedUsers] = React.useState(() => {
      const taskUsers = props.task.taskType.users.map((user: any) => user.id);
      return users
        .filter((user: any) => !taskUsers.includes(user.idUser))
        .map((user: any) => (
          {
            id: user.idUser,
            name: user.name,
            lastname: user.lastName,
            email: user.email,
            effort_interp_avg: props.task.taskType.effort_avg,
            image_id: user.imageDto.idImage,
            image_url: user.imageDto.url,
            status: props.task.projectTask.assignedUsers.includes(user.idUser) ?
              UserStatus.Assigned :
              UserStatus.Unassigned
          }
        ))
    });
    const [activityEffort, setActivityEffort] = React.useState(
      Math.round(props.task.projectTask.estimatedTime)
    );
    const [activityDuration, setActivityDuration] = React.useState(
      Math.round(props.task.projectTask.estimatedDays)
    );

    if (activityEffort !== props.task.projectTask.estimatedTime && !effortFocused) {
      setActivityEffort(Math.round(props.task.projectTask.estimatedTime));
    }

    if (activityDuration !== props.task.projectTask.estimatedDays && !durationFocused) {
      setActivityDuration(Math.round(props.task.projectTask.estimatedDays));
    }

    const handleCheckboxChange = (checked: boolean, idUser: number, ranked: boolean) => {
      const usuarios = ranked ? JSON.parse(JSON.stringify(rankedUsers)) : JSON.parse(JSON.stringify(notRankedUsers));
      const usuario = usuarios.find((user: any) => user.id === idUser);
      let assignedUsers = selectedUsers;

      if (checked) {
        usuario.status = UserStatus.Assigned;
        assignedUsers++;
        projectPlanDispatch({
          type: 'assignUser',
          idUser: idUser,
          users: rankedUsers.map((rankedUser: any) => {
            if (rankedUser.id === idUser) {
              return { ...rankedUser, status: UserStatus.Assigned }
            }
            return { ...rankedUser }
          }).concat(
            notRankedUsers.map((notRankedUser: any) => {
              if (notRankedUser.id === idUser) {
                return  { ...notRankedUser, status: UserStatus.Assigned }
              }
              return { ...notRankedUser }
            })
          ),
          taskType: props.task.taskType,
          planIndexes: {
            elementTypeIndex: props.indexes.elementTypeIndex,
            projectElementIndex: props.indexes.elementIndex,
            phaseIndex: props.indexes.phaseIndex,
            taskIndex: props.indexes.taskIndex
          },
          adjustedDuration: !statisticalRange,
          recalculateEstimates: changeValuesOnAssignedUsers
        });

      } else if (selectedUsers > 0) {
        projectPlanDispatch({
          type: 'removeUser',
          user: { ...usuario },
          users: rankedUsers.map((rankedUser: any) => {
            if (rankedUser.id === idUser) {
              return { ...rankedUser, status: UserStatus.Unassigned }
            }
            return { ...rankedUser }
          }).concat(
            notRankedUsers.map((notRankedUser: any) => {
                if (notRankedUser.id === idUser) {
                    return  { ...notRankedUser, status: UserStatus.Unassigned }
                }
                return { ...notRankedUser }
            })
          ),
          taskType: props.task.taskType,
          planIndexes: {
            elementTypeIndex: props.indexes.elementTypeIndex,
            projectElementIndex: props.indexes.elementIndex,
            phaseIndex: props.indexes.phaseIndex,
            taskIndex: props.indexes.taskIndex
          },
          adjustedDuration: !statisticalRange,
          recalculateEstimates: changeValuesOnAssignedUsers
        });

        usuario.status = UserStatus.Unassigned;
        assignedUsers--;
      }

      if (assignedUsers === 0 && props.task.taskType.duration_avg > 0) {
        setActivityDuration(props.task.taskType.duration_avg);
      }
      setSelectedUsers(assignedUsers);
      if (ranked) {
        setRankedUsers(usuarios);
      } else {
        setNotRankedUsers(usuarios);
      }
    }

    const handleActivityEffortChange = (value: number) => {
      if (value < 0) {
        setActivityEffort(Math.round(props.task.projectTask.estimatedTime));
        return;
      }

      projectPlanDispatch({
        type: 'changeEffort',
        newEffortValue: value,
        planIndexes: {
          elementTypeIndex: props.indexes.elementTypeIndex,
          projectElementIndex: props.indexes.elementIndex,
          phaseIndex: props.indexes.phaseIndex,
          taskIndex: props.indexes.taskIndex
        }
      });
    }

    const handleActivityDurationChange = (value: number) => {
      if (value < 0) {
        setActivityDuration(Math.round(props.task.projectTask.estimatedDays));
        return;
      }
      setActivityDuration(value);
      projectPlanDispatch({
        type: 'changeDuration',
        newDurationValue: value,
        level: 3,
        planIndexes: {
          elementTypeIndex: props.indexes.elementTypeIndex,
          projectElementIndex: props.indexes.elementIndex,
          phaseIndex: props.indexes.phaseIndex,
          taskIndex: props.indexes.taskIndex
        },
        adjustedDuration: !statisticalRange
      });
    }

    const handleEffortBlur = () => {
      if (activityEffort === '') {
        setActivityEffort(Math.round(props.task.projectTask.estimatedTime));
      } else {
        handleActivityEffortChange(Math.round(Number(activityEffort)));
      }

      setEffortFocussed(false);
    }

    const handleDurationBlur = () => {
      if (activityDuration === '') {
        setActivityDuration(Math.round(props.task.projectTask.estimatedDays));
      } else {
        handleActivityDurationChange(Math.round(Number(activityDuration)));
      }

      setDurationFocused(false);
    }

    const handleEstimatesChangeOnAssignedUsers = () => {
      if (!changeValuesOnAssignedUsers) { // Si se activa el recalculo de estimaciones
        confirmAlert({
          customUI: ({onClose}) => {
            return (
              <TrueffortConfirmAlert
                title={
                  <Typography color='error' variant='h6' fontWeight={700} marginBottom='20px' >
                    <ReportProblemIcon color='error' />&nbsp;&nbsp;{t("Warning")}
                  </Typography>
                }
                alertColor='error'
                messages={[
                  t("estimation_page.msg_recalculate_estimates_1"),
                  t("estimation_page.msg_recalculate_estimates_2")
                ]}
                buttons={[
                  {
                    label: t("project_page.msg_delete_task_confirm_no"),
                    onClick: onClose
                  },
                  {
                    label: t("export_excel.yes"),
                    onClick: () => {
                      projectPlanDispatch({
                        type: 'updateTaskEstimates',
                        users: rankedUsers.map((rankedUser: any) => ({ ...rankedUser }))
                          .concat(
                            notRankedUsers.map((notRankedUser: any) => ({ ...notRankedUser }))
                          ),
                          taskType: props.task.taskType,
                          planIndexes: {
                            elementTypeIndex: props.indexes.elementTypeIndex,
                            projectElementIndex: props.indexes.elementIndex,
                            phaseIndex: props.indexes.phaseIndex,
                            taskIndex: props.indexes.taskIndex
                          },
                          adjustedDuration: !statisticalRange
                      });
                      setChangeValuesOnAssignedUsers(!changeValuesOnAssignedUsers);
                      onClose();
                    }
                  }
                ]}
              />
            );
          }
        });
      } else { // Si se desactiva el recalculo de estimaciones
        setChangeValuesOnAssignedUsers(!changeValuesOnAssignedUsers);
      }
    }

    return (
      <>
        {!props.hideTree &&
          <Paper
            variant="outlined"
            square
            sx={{ padding: '10px', backgroundColor: 'rgba(242, 233, 255, 0.30)' }}
          >
            <Grid container spacing={2} alignItems="center" >
              <Grid item xs={1} container alignItems='center' justifyContent='center' >
                {(Math.round(props.task.projectTask.estimatedTime) <= 0 ||
                    Math.round(props.task.projectTask.estimatedDays) <= 0) &&
                  <Tooltip title={t("estimation_page.cero_values_error")} placement='top'>
                      <ErrorIcon color='error' />
                  </Tooltip>
                }
              </Grid>
              <Grid item xs={3}  >
                <Typography >
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setShowUsers(!showUsers)}
                  >
                    {showUsers ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                  </IconButton>
                  {props.task.taskType.name}
                </Typography>
              </Grid>
              <Grid item xs={2} >
                <Stack direction='row' spacing={1} alignItems='center' justifyContent='center' >
                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >
                      {t("estimation_page.average")}
                    </Typography>

                    <TrueffortChip
                      //size='small'
                      color='purple'
                      variant='outlined'
                      label={props.task.taskType.team_sz_avg}
                    />
                  </Stack>

                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >
                      {t("estimation_page.selected_participants")}
                    </Typography>

                    <TrueffortChip
                      //size='small'
                      color='purple'
                      label={selectedUsers}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={2} >
                <Stack direction='row' spacing={1} >
                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >Min</Typography>
                      <TrueffortChip
                        icon={
                          <SvgIcon >
                            <svg
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                                fill={ Math.round(props.task.projectTask.estimatedTime) === props.task.taskType.effort_min 
                                  ? 'white' : '#6693CB' }
                              />
                            </svg>
                          </SvgIcon>
                        }
                        size='small'
                        color='secondary'
                        variant={ Math.round(props.task.projectTask.estimatedTime) === props.task.taskType.effort_min 
                          ? 'filled' : 'outlined'
                        }
                        label={props.task.taskType.effort_min}
                        onClick={() => handleActivityEffortChange(props.task.taskType.effort_min)}
                      />
                  </Stack>

                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >{t("estimation_page.avg")}</Typography>
                    <TextField
                      name="estimatedTime"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      size="small"
                      //disabled={!isModifiable || !elementType.active}
                      value={effortFocused ? activityEffort : props.task.projectTask.estimatedTime}
                      onFocus={() => setEffortFocussed(true)}
                      onBlur={handleEffortBlur}
                      onChange={(event: any) => effortFocused ? setActivityEffort(event.target.value) :
                        handleActivityEffortChange(Math.round(Number(event.target.value)))
                      }
                      onKeyDown={(event: any) => {
                        if (event.key === "Enter") handleActivityEffortChange(Math.round(Number(event.target.value)));
                      }}
                      error={Math.round(props.task.projectTask.estimatedTime) <= 0 || activityEffort <= 0}
                    />
                  </Stack>

                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >Max</Typography>
                    <TrueffortChip
                      icon={
                        <SvgIcon >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                              fill={ Math.round(props.task.projectTask.estimatedTime) === props.task.taskType.effort_max ?
                                'white' : '#6693CB' }
                            />
                          </svg>
                        </SvgIcon>
                      }
                      size='small'
                      color='secondary'
                      variant={ Math.round(props.task.projectTask.estimatedTime) === props.task.taskType.effort_max ?
                        'filled' : 'outlined' }
                      label={props.task.taskType.effort_max}
                      onClick={() => handleActivityEffortChange(props.task.taskType.effort_max)}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={2} >
                <Stack direction="row" spacing={1} >
                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >Min</Typography>
                    <TrueffortChip
                      icon={
                        <SvgIcon >
                          <svg
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                              fill={ Math.round(props.task.projectTask.estimatedDays) === props.task.taskType.duration_min ?
                                'white' : '#6693CB' }
                            />
                          </svg>
                        </SvgIcon>
                      }
                      size='small'
                      color='secondary'
                      variant={ Math.round(props.task.projectTask.estimatedDays) === props.task.taskType.duration_min ?
                        'filled' : 'outlined' }
                      label={props.task.taskType.duration_min}
                      onClick={() => handleActivityDurationChange(props.task.taskType.duration_min)}
                    />
                  </Stack>
                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >{t("estimation_page.avg")}</Typography>
                    <TextField
                      name="estimatedDays"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      size="small"
                      //disabled={!isModifiable || !elementType.active}
                      value={durationFocused ? activityDuration : props.task.projectTask.estimatedDays}
                      onFocus={() => setDurationFocused(true)}
                      onBlur={handleDurationBlur}
                      onChange={(event: any) =>
                        durationFocused ? setActivityDuration(event.target.value) :
                          handleActivityDurationChange(Math.round(Number(event.target.value)))
                      }
                      onKeyDown={(event: any) => {
                        if (event.key === "Enter") handleActivityDurationChange(Math.round(Number(event.target.value)));
                      }}
                      error={Math.round(props.task.projectTask.estimatedDays) <= 0 || activityDuration <= 0}
                    />
                  </Stack>
                  <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                    <Typography variant='caption' >Max</Typography>
                    <TrueffortChip
                      icon={
                        <SvgIcon >
                          <svg
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                              fill={ Math.round(props.task.projectTask.estimatedDays) === props.task.taskType.duration_max 
                                ? 'white' : '#6693CB' }
                            />
                          </svg>
                        </SvgIcon>
                      }
                      size='small'
                      color='secondary'
                      variant={ Math.round(props.task.projectTask.estimatedDays) === props.task.taskType.duration_max ?
                        'filled' : 'outlined' }
                      label={props.task.taskType.duration_max}
                      onClick={() => handleActivityDurationChange(props.task.taskType.duration_max)}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={1.5} container justifyContent='center' >
                <TrueffortChip
                  icon={<DeleteIcon fontSize="small" />}
                  size="small"
                  label={t("delete")}
                  variant="outlined"
                  color='redExtra'
                  //disabled={formikTask.isSubmitting}
                  onClick={() => props.handleTaskDelete(props.indexes.taskIndex, activityEffort)}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        <Collapse in={showUsers || props.hideTree} timeout="auto" unmountOnExit >
          <Paper
            variant="outlined"
            square
            sx={{ padding: '10px', backgroundColor: 'rgba(242, 233, 255, 0.30)' }}
          >
            <Grid container spacing={1} >
              <Grid item xs={1} ></Grid>
              <Grid item xs={1.5} >
                <Stack direction='column' spacing={1} >
                  <Typography variant="subtitle2" >
                    <SvgIcon fontSize="small" >
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.75 2.75V5.5C2.75 5.86467 2.89487 6.21441 3.15273 6.47227C3.41059 6.73013 3.76033 6.875 4.125 6.875H8.70833M8.70833 6.875L6.875 5.04167M8.70833 6.875L6.875 8.70833" 
                          stroke="#553D74" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </SvgIcon>
                    {t("estimation_page.participants")}
                  </Typography>
                  <Chip
                    sx={{ borderRadius: '3px' }}
                    icon={<FontAwesomeIcon icon={faUsers} color={'#9A64E2'} />}
                    label={"Total: " + selectedUsers}
                    variant='outlined'
                  />
                  <TrueffortButton
                    size="small"
                    variant='outlined'
                    color='secondary'
                    startIcon={<AddIcon />}
                    onClick={() => setShowModal(true)}
                  >
                    {t("project_tasks_page.add_user")}
                  </TrueffortButton>
                </Stack>
              </Grid>
              <Grid item xs={7}  >
                <Grid container spacing={1} padding={1} alignItems='center' >
                  {rankedUsers
                    .sort((usr1: any, usr2: any) => usr2.ranking - usr1.ranking)
                    .filter((user: any) => user.status === UserStatus.Assigned)
                    .map((user: any, k: number) => (
                      <Grid item key={k.toString()} >
                        <ProjectDetailUserCard
                          userInfo={user}
                          removeUser={handleCheckboxChange}
                          index={k}
                          detail={false}
                        />
                      </Grid>
                    ))
                  }

                  {notRankedUsers
                    .filter((user: any) => user.status === UserStatus.Assigned)
                    .map((user: any, k: number) => (
                      <Grid item key={k.toString()} >
                          <ProjectDetailUserCard
                            userInfo={user}
                            removeUser={handleCheckboxChange}
                            index={k}
                            detail={false}
                          />
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>

                <Grid item xs >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={changeValuesOnAssignedUsers}
                        onChange={handleEstimatesChangeOnAssignedUsers}
                      />
                    }
                    label={t("users_modal.recalculate_estimates")}
                  />
                </Grid>
            </Grid>
          </Paper>
        </Collapse>

        <AssignUsersDrawer
          open={showModal}
          anchor='right'
          onClose={() => setShowModal(false)}
          users={{
            notRankedUsers: notRankedUsers,
            rankedUsers: rankedUsers.sort(
              (usr1: any, usr2: any) => usr2.ranking - usr1.ranking
            )
          }}
          taskName={props.task.taskType.name}
          assignUser={handleCheckboxChange}
          /*automaticEstimatesState={{
              changeValuesOnAssignedUsers: changeValuesOnAssignedUsers,
              handleEstimatesChangeOnAssignedUser: handleEstimatesChangeOnAssignedUser
          }}*/
          page='estimation'
        />
      </>
    );
}