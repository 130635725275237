import {useTranslation} from "react-i18next";
import {User} from "../../util/Interfaces";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {useHistory} from "react-router-dom";
import {MIN_LEVEL_ADMIN} from "../../util/Constants";
import {Container, Card, Box} from "@mui/material";
import {TrueffortButton} from "../../MUIstyle/MUICustom";
import {Apps as AppsIcon, Add as AddIcon} from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery"; // Grid version 2

const HomeEmpty = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const { user } = useAuthContext() as { user: User };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="sm">
      {user.role.accessLevel >= MIN_LEVEL_ADMIN ?
        <div className="home-empty-card">
          {/*<Paper sx={{ borderRadius: '12px',  }} >*/}
          <Grid container sx={{paddingLeft: "1.4rem", paddingRight: "1.4rem"}}>
            <Grid xs={6} sx={{zIndex: 9}}>
              <Box sx={{width: "3.5rem", height: "3.5rem", background: "white", padding: ".1rem", borderRadius: "50%"}}>
                <img src='./assets/images/group.png' style={{margin: "0 auto", display: "block", paddingTop: "20%", width: "60%"}} alt="TruEffort" />
              </Box>
            </Grid>
            <Grid xs={6} sx={{textAlign: "right", fontSize: matches ? "8px" : "0.8rem"}}>
              {t("home.welcome")}
            </Grid>
          </Grid>
          <hr className="hr-white-line-logo" />
          <Box className="home-empty-card-2-title">
            <h3>{t("home.create_new_project")}</h3>
            <h5>{t("home.home_start_question")}</h5>
          </Box>
          <Box className="home-empty-card-2" sx={{alignItems: "center"}}>
            {/*<div className="home-empty-box-options">*/}
            <Grid container spacing={3} >
                <Grid xs={12} sm={6}  >
                  <div className="home-empty-box-1" style={{fontSize: ".8rem", marginLeft: '20px', marginRight: '20px' }}>
                    <TrueffortButton
                      color="primary"
                      size="medium"
                      variant="contained"
                      onClick={() => history.push("/estimation")}
                      sx={{fontSize: "1rem"}}
                    ><AddIcon sx={{fontSize: "1rem"}} />
                    </TrueffortButton>
                    <span style={{fontSize: "0.875rem", fontWeight: "bold", lineHeight: ".5"}}>{t("home.use_layout")}</span>
                    <span style={{fontSize: "0.875rem", lineHeight: ".6"}}>{t("home.use_layout2")}</span>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} >
                  <div className="home-empty-box-1" style={{ marginLeft: '20px', marginRight: '20px' }}>
                    <TrueffortButton
                        color="secondary"
                        size="medium"
                        variant="contained"
                        onClick={() => history.push("/projectTypes")}
                    >
                        <AppsIcon sx={{fontSize: "1rem"}}/>
                    </TrueffortButton>
                    <span style={{fontSize: "0.875rem", fontWeight: "bold", lineHeight: ".5"}}>{t("home.create_layout")}</span>
                    <span style={{fontSize: "0.875rem", lineHeight: ".6"}}>{t("home.create_layout2")}</span>
                  </div>
                </Grid>
              </Grid>
              {/*</div>*/}
          </Box>
          {/*</Paper>*/}
        </div> :
        <Card>
          <div style={{fontSize:"large", marginLeft:"0.5em", textAlign:"center", paddingBottom: "1em"}}>
            {t("home.not_exist_projects_to_show")}
          </div>
        </Card>
      }
    </Container>
  );
}

export default HomeEmpty;