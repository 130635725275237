import { Speed as SpeedIcon, Assignment as AssignmentIcon, TrendingUp as TrendingUpIcon} from '@mui/icons-material';

// @ts-ignore
function CardUnitStatistics ({id, title, unit, value, subtitle, type, fontSizeTitle, fontSizeSubTitle, alignValue, fontSizeValue}) {
  
  return(
    <div style={{background: "#F5F5F5", margin: "8px", color: "#050536", padding: "8px", width:'98%'}}>
      <div key={"_3.5.1.1"} style={{display:"flex", width:"100%"}}>
        <div style={{display: "flex", flexDirection:"column", width:'100%'}}>
          <div style={{minHeight: "1.4em"}}>
            <div style={{fontWeight: "bold", fontSize: fontSizeTitle ? fontSizeTitle : "0.8rem"}}>
              {type == 1 && <SpeedIcon fontSize="small" sx={{marginBottom: "-.2em", marginLeft: "-4px"}}/>}
              {type == 2 && <AssignmentIcon fontSize="small" sx={{marginBottom: "-.2em", marginLeft: "-4px"}}/>}
              {type == 3 && <TrendingUpIcon fontSize="small" sx={{marginBottom: "-.2em", marginLeft: "-4px"}}/>}
              {title}
            </div>
            <div style={{fontSize: fontSizeSubTitle ? fontSizeSubTitle : "0.6em"}} className="color-gray1">{subtitle}</div>
          </div>
          <div style={{width:'100%', textAlign: alignValue != null ? alignValue : 'left', fontSize: fontSizeValue ? fontSizeValue : "1.125rem"}}>{value}</div>
        </div>
      </div>
    </div>
  );
}

export default CardUnitStatistics;