import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ProjectTaskComment {

    getByProjectTask(idProjectTask : number) {
        return http?.get(baseURL.activitiesapi.projectTaskComment + `/project-task/${idProjectTask}`);
    }

    create(data : any) {
        return http?.post(baseURL.activitiesapi.projectTaskComment + "/", data);
    }

    uploadFile(id: string, data: any) {
        const options = {
            headers: {
                "Content-type": "multipart/form-data",
            }, transformRequest: () => data
        };

        return http?.post(baseURL.activitiesapi.projectTaskComment + `/uploadFile/${id}`, data, options);
    }

    downloadFile(filename: string) {
        return http?.get(baseURL.activitiesapi.projectTaskComment + `/download/${filename}`, {responseType: "blob"});
    }
}

export default ProjectTaskComment;