import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class PhaseService {

    getAll() {
        return http?.get(baseURL.readApi.phase + "/");
    }

    create(data : any) {
        return http?.post(baseURL.activitiesapi.phase + "/", data);
    }

    delete(id : number) {
        return http?.delete(baseURL.activitiesapi.phase + `/${id}`);
    }

    update(id : number, data : any) {
        return http?.put(baseURL.activitiesapi.phase + `/${id}`, data);
    }

    findAllByProjectType(id: number) {
        return http?.get(baseURL.readApi.phase + "/findAllByLifeCycle/" + id);
    }

    findAllByElementType(id: number) {
        return http?.get(baseURL.readApi.phase + "/findAllByElementType/" + id);
    }

    activate(id: number) {
        return http?.put(baseURL.activitiesapi.phase + "/active/" + id);
    }
}

export default PhaseService;