import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ReportsService {

    findGlobalUsers(startDate:string, endDate:string, idUser:number, idSelectedProject:number) {
      return http?.get(baseURL.readApi.reports + "/globalUsers?startDate="+startDate+"&endDate="+endDate+"&idUser="+idUser+"&idProject="+idSelectedProject);
    }

    /*findGlobalUsersByTasks(startDate:string, endDate:string, idUser:number, idSelectedProject:number) {
      return http?.get(baseURL.readApi.reports + "/globalUsersByTasks?startDate="+startDate+"&endDate="+endDate+"&idUser="+idUser+"&idProject="+idSelectedProject) 
    }*/

    findGlobalUsersWeekly(page:number,countPerPage:number, startDate:string, endDate:string, idUser:number, idSelectedProject:number, data:any) {
      return http?.post(baseURL.readApi.reports + "/weekly?page="+page+"&countPerPage="+countPerPage+"&startDate="+startDate+"&endDate="+endDate+"&idUser="+idUser+"&idProject="+idSelectedProject,data)
    }

    findGlobalBenchmark(startDate:string, endDate:string, idUser:number, idSelectedProject:number) {
      return http?.get(baseURL.readApi.reports + "/benchmark?startDate="+startDate+"&endDate="+endDate+"&idUser="+idUser+"&idProject="+idSelectedProject)
    }

    findGlobalProjects(idUser:number, idSelectedProject:number, startDate:string, endDate:string) {
      return http?.get(baseURL.readApi.reports + "/globalProjects?startDate="+startDate+"&endDate="+endDate+"&idUser="+idUser+"&idProject="+idSelectedProject);
    }

    findStatisticsGlobalProjects(startDate:string, endDate:string, idUser:number, idProject:number) {
      return http?.get(baseURL.readApi.reports + "/getStatisticsProject?startDate="+startDate+"&endDate="+endDate+"&idUser=" + idUser+"&idProject="+idProject);
    }

    globalProjectsStatus() {
      return http?.get(baseURL.readApi.reports + "/globalProjectsStatus");
    }
}

export default ReportsService;