import { ApiProtectedWS as http } from "../api/axios";
import { baseURL } from "../util/Constants";

class infoAzureProjectService {
  getProjects(organization: string, token: string) {
    const response = http.get(
      `${baseURL.activitiesapi.AzureProjects}/projects?organization=${organization}&token=${token}`
    );
    return response;
  }

  getProjectDetails(organization: string, token: string, projectId: string) {
    const response = http.get(
      `${baseURL.activitiesapi.AzureProjects}/project-details?organization=${organization}&token=${token}&projectId=${projectId}`
    );
    return response;
  }

  // getProjectBoards(organization: string, token: string, projectId: string) {
  //   const response = http.get(
  //     `${baseURL.activitiesapi.AzureProjects}/project-boards?organization=${organization}&token=${token}&projectId=${projectId}`
  //   );
  //   return response;
  // }

  getBoardSprints(organization: string, token: string, projectId: string) {
    const response = http.get(
      `${baseURL.activitiesapi.AzureProjects}/board-sprints?organization=${organization}&token=${token}&projectId=${projectId}`
    );
    return response;
  }

  getProjectTasks(organization: string, token: string, projectId: string) {
    const response = http.post(
      `${baseURL.activitiesapi.AzureProjects}/project-tasks?organization=${organization}&token=${token}&projectId=${projectId}`
    );
    return response;
  }
  
  getParents(organization: string, token: string, projectId: string) {
    const response = http.post(
      `${baseURL.activitiesapi.AzureProjects}/project-Parents?organization=${organization}&token=${token}&projectId=${projectId}`
    );
    return response;
  }
  
  async getWorkItemParents(organization: string, token: string, projectId: string) {
    try {
      // Paso 1: Obtener los IDs de los work items
      const response = await this.getProjectTasks(organization, token, projectId);
      const obj = JSON.parse(JSON.stringify(response.data));
      let ids: number[] = [];
      if (obj.workItems && obj.workItems.length > 0) {
        obj.workItems.forEach((item: any) => ids.push(item.id));
      } else {
        return { message: "AZURE T: No hay tareas en este proyecto.", details: [], parents: [] };
      }

    
      const relationfields = [
        "System.Id",
        "System.Title",
        "System.WorkItemType",
        "System.Parent"
      ];
      const parentsResponse = await http.post(
        `${baseURL.activitiesapi.AzureProjects}/workitems-parents?organization=${organization}&token=${token}`,
        {
          ids: ids,
          relationfields: relationfields
        }
      );

      const parents = parentsResponse.data;
      return { obj, parents };
    } catch (error) {
      console.error('Error al obtener las relaciones padre-hijo de los work items:', error);
      throw error;
    }
  }



  async getWorkItemDetails(organization: string, token: string, projectId: string) {
    try {
      // Paso 1: Obtener los IDs de los work items
      const response = await this.getProjectTasks(organization, token, projectId);
      // console.log(response);
      const obj = JSON.parse(JSON.stringify(response.data));
      // console.log(obj);
      let ids: number[] = [];
      if (obj.workItems && obj.workItems.length > 0) {
        obj.workItems.forEach((item: any) => ids.push(item.id));
      } else {
        return { message: "AZURE T: No hay tareas en este proyecto.", details: [] };
      }
      // Paso 2: Obtener los detalles de los work items usando los IDs obtenidos
      const detailsResponse = await http.post(
        `${baseURL.activitiesapi.AzureProjects}/workitems-batch?organization=${organization}&token=${token}`,

        {
          ids: ids,
          fields: ["System.Id",
                  "System.Title",
                  "System.IterationPath",
                  "System.WorkItemType",
                  "System.State",
                  "System.CreatedDate",
                  "System.TeamProject",
                  "System.Description",
                  "System.Parent",
                  "System.AssignedTo"

              
              ]
        }
      );

      const details = detailsResponse.data;
      // console.log(obj,details);
      console.log('Detalles de work items recibidos:', details);
       details.value.forEach((item: any) => {
            if (!item.fullTitle) {
                console.warn(`El elemento con ID ${item.id} no tiene fullTitle`);
            } else {
                console.log(`Elemento con ID ${item.id} tiene fullTitle: ${item.fullTitle}`);
            }
        });

    return { obj, details };
    } catch (error) {
      console.error('Error al obtener los detalles de los work items:', error);
      throw error;
    }
  }

  getConnectionInfo() {
    return http?.get(`${baseURL.activitiesapi.AzureProjects}/connection-info`);
  }

  getWorkitemsStates(organization: string, token: string, projectId: string, type: string) {
      return http?.get(
          `${baseURL.activitiesapi.AzureProjects}/workitem-states?organization=${organization}&token=${token}&projectId=${projectId}&type=${type}`
      );
  }

  updateWorkitemState(organization: string, token: string, projectId: string, data: any) {
      return http?.patch(
          `${baseURL.activitiesapi.AzureProjects}/update-workitem-state?organization=${organization}&token=${token}&projectId=${projectId}`,
          data
      );
  }
  
}
export default new infoAzureProjectService();
