import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class UserService {

    getAll() {
        return http?.get(baseURL.readApi.users + "/");
    }

    getAllComplete(){
        return http?.get(baseURL.readApi.users + "/info");
    }

    getAllWithoutRole(){
        return http?.get(baseURL.readApi.users + "/all");
    }

    get(id: number) {
        return http?.get(baseURL.activitiesapi.users + `/${id}`);
    }

    create(data: any) {
        return http?.post(baseURL.activitiesapi.users + "/register", data);
    }

    createInivitation(data: any) {
        return http?.post(baseURL.activitiesapi.users + "/registerInvitation", data);
    }

    bulkUploadInvitations(data: any) {
        const options = {
            headers: {
                "Content-type": "multipart/form-data",
            }, transformRequest: () => data
        };
        return http?.post(baseURL.activitiesapi.users + `/bulkUpload`, data, options);
    }

    updateUser(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.users + `/${id}`, data);
    }

    updateInfo(id: string, data: any) {
        const options = {
            headers: {
                "Content-type": "multipart/form-data",
            }, transformRequest: () => data
        };
        return http?.post(baseURL.activitiesapi.users + `/${id}`, data, options);
    }

    updateLangCode(userId: number, newLocale: string) {
        return http?.get(baseURL.activitiesapi.users + `/${userId}/language/${newLocale}`);
    }

    updateTimeFormat(newTimeFormat:string) {
        return http?.put(baseURL.activitiesapi.users + `/timeFormat/${newTimeFormat}`);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.users + `/${id}`);
    }

    deleteFromInstance(id: number, idInstance: number) {
        return http?.delete(baseURL.activitiesapi.users + `/${id}/instance/${idInstance}`);
    }

    updateUserPermissions(id: number, data: string) {
        return http?.put(baseURL.activitiesapi.users + `/${id}/modules`, data);
    }

    confirmToken(token: string, email: string) {
        return http?.get(baseURL.activitiesapi.users + `/confirm-account?token=${token}&email=${email}`);
    }

    resendCode(email: string) {
        return http?.get(baseURL.activitiesapi.users + `/resend-code?email=${email}`);
    }

    forgotPasswordConfirm(data: { newPassword: string | undefined; email: string | undefined; token: string | null }) {
        return http?.post(baseURL.activitiesapi.users + "/forgot-password/confirm", data);
    }

    forgotPasswordRequest(email: string | undefined) {
        return http?.get(baseURL.activitiesapi.users + `/forgot-password?email=${email}`);
    }

    forgotPasswordVerify(token: string | undefined) {
        return http?.get(baseURL.activitiesapi.users + `/forgot-password/verify?token=${token}`);
    }

    getLastActivity() {
        return http?.get(baseURL.readApi.users + `/getLastActivity`);
    }

    getRoles() {
        return http?.get(baseURL.readApi.users + `/roles`);
    }

    getLeaders() {
        return http?.get(baseURL.readApi.users + `/leaders`);
    }

    getLeadersByProject(idProject:number) {
        return http?.get(baseURL.readApi.users + `/leadersByProject/${idProject}`);
    }

    closeActiveSessions(idUser: number) {
        return http?.delete(baseURL.activitiesapi.users + `/closeActiveSessions/${idUser}`);
    }

    getRecentlyActiveUsers() {
        return http?.get(baseURL.readApi.users + `/recentlyActiveUsers`);
    }
}

export default UserService;
