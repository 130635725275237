import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class TaskStateService {

    getAll() {
        return http?.get(baseURL.readApi.taskState + "/");
    }

    getStatusInteroperability(idTool: number, statusInteroperability: string) {
        return http?.get(baseURL.readApi.taskState + `/interoperability/${idTool}/${statusInteroperability}`)
    }

}

export default TaskStateService;