// @flow

import * as React from "react";
import '../css/PricingCardET.css';
//import {Page, Grid} from "tabler-react";
import PricingCardET from "../components/pricing-login-register/PricingCardTRUE";
import {URL_LOCAL, URL_PROD, URL_QA} from "../util/Constants";
import {AppBar, Box, Container, MenuItem, Select, Stack, Toolbar, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Icon} from "tabler-react";

const cards = [
    //"free",
    // "perMonth",
    "company"];

function PricingCardsPage () {
    const {t, i18n} = useTranslation();
    const history = useHistory();

    return (
        <Box className="page" >
            <AppBar
                color="transparent"
                sx={{ boxShadow: '0px 0px 0px 0px', backgroundColor: 'white' }}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <a href={(window.location.host.includes("tool.trueffort") ? URL_PROD : window.location.host.includes("qa.trueffort") ? URL_QA : URL_LOCAL ) + 'login#/'}>
                            <img className="h-8 mt-0" src={"./assets/images/new_logo.png"} alt="TrueUEffort"/>
                        </a>
                    </Box>

                    <Typography>
                        {t("Dont_have_account?")} <a onClick={() => history.push("pricing")} href="#">{t("forms.go_signUp")}</a>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />

            <Box className="page-single" >
                <Stack direction='row' justifyContent='center' >
                    {cards.map((card) =>
                        <PricingCardET key={card} cardType={card}/>
                    )}
                </Stack>
            </Box>

            <Box
                component="footer"
                sx={{ py: 1, px: 2, mt: 'auto' }}
            >
                <Container maxWidth="sm" sx={{ textAlign: "center" }} >
                    <Select
                        size="small"
                        id="language-login-select"
                        value={i18n.language === "es" ? t("Spanish") : t("English")}
                        onChange={(e: any) => i18n.changeLanguage(e.target.value)}
                        renderValue={(value: any) => {
                            return (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    <FontAwesomeIcon size="lg" icon={faGlobe} />
                                    {value}
                                    {/*<FontAwesomeIcon icon={faChevronDown} />*/}
                                </Box>
                            )
                        }}
                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 }, color: '#07074E' }}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        <MenuItem value="es"><Icon flag name="mx" />  &nbsp;&nbsp; {t("Spanish")}</MenuItem>
                        <MenuItem value="en"><Icon flag name="us" />  &nbsp;&nbsp; {t("English")}</MenuItem>
                    </Select>
                </Container>
            </Box>
        </Box>
    );

    /*return (
        <div className="page justify-content-between">
            <Page.Header className="mb-0 mt-0 pt-0 pb-0">
                <a href={(window.location.host.includes("tool.trueffort") ? URL_PROD : window.location.host.includes("qa.trueffort") ? URL_QA : URL_LOCAL ) + 'login#/'}>
                    <img className="h-7 ml-5 mt-0" src={"./assets/images/logo.png"} alt="TrueUEffort"/>
                </a>
            </Page.Header>
            <Page.Content title="" className="mr-5 mb-0 mt-0 pt-0 pb-0">
                <Grid.Row cards deck className={"container-pricing-card"}>
                        {cards.map((card) => <Grid.Col key={card} lg={6} md={10}  sm={12} xs={12}><PricingCardET key={card} cardType={card}/></Grid.Col>)}
                </Grid.Row>
            </Page.Content>
            <div className="mr-5 mb-5">
                <a href={'https://spingere.com.mx/'}>
                    <img className="h-9 float-right" src={"./assets/images/logo_spingere.png"} alt="TrueUEffort"/>
                </a>
            </div>
        </div>
    )*/
}

export default PricingCardsPage;
