import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class LogbookService {

    find(idInstance:number, startDate: string, endDate: string) {
        return http?.get(baseURL.activitiesapi.logbook + "/find?idInstance=" + idInstance + "&startDate=" + startDate + "&endDate=" + endDate);
    }
}

export default LogbookService;