import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class CompanyBusinessSubtypeService {

    getAll() {
        return http?.get(baseURL.readApi.companyBusinessSubtype + "/");
    }

    getBusinessByCompany(company: String) {
        return http?.get(baseURL.readApi.companyBusinessSubtype + "/getBusinessByCompany?company=" + company);
    }
}

export default CompanyBusinessSubtypeService;