import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class EstimationsService {

    getAccumulatedEstimate(data: any) {
        return http?.post(baseURL.trueffortPyApi.estimates + "/project-type/accumulated/", data);
    }

    getRealEstimate(data: any) {
        return http?.post(baseURL.trueffortPyApi.estimates + "/project-type/real/", data);
    }

    create() {
        return http?.post(baseURL.trueffortPyApi.estimates + "/create/");
    }

    projectStructure(data:any) {
        return http?.post(baseURL.trueffortPyApi.estimates + "/project/followup/detail/", data);
    }

    getProjects (data:any) {
        return http?.post(baseURL.trueffortPyApi.estimates + "/project/followup/overview/", data);
    }

    getProjectEffort(data: any) {
        return http?.post(baseURL.trueffortPyApi.estimates + "/project/followup/effort/", data);
    }

    getSummaryTasks(data: any) {
        return http?.post(baseURL.trueffortPyApi.estimates + "/project/summary/tasks/", data);
    }

    getGlobalTrendTasks(data: any) {
        return http?.post(baseURL.trueffortPyApi.trend + "/instance/finished-tasks/weekly/", data);
    }

    getGlobalTrendEffort(data: any) {
        return http?.post(baseURL.trueffortPyApi.trend + "/instance/effort/weekly/", data);
    }

}

export default EstimationsService;