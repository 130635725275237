import SiteWrapper from "../SiteWrapper.react";
import {useTranslation} from "react-i18next";
import { Container, Typography, Stack, Box, Collapse, Card, Select, Menu, MenuItem, TableContainer, Paper, TableRow, TableBody, TableCell, CircularProgress } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {CalendarMonth, KeyboardArrowDown} from "@mui/icons-material";
import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../components/custom/CustomDatePickerInput";
import {TrueffortTable, TrueffortTableHead, TrueffortButton, TrueffortTableHeadCell,
    TrueffortNavButton, TrueffortSelect} from "../MUIstyle/MUICustom";
import CustomIconTrueffortScreen from "../components/custom/CustomIconTrueffortScreen";
import InstanceService from "../services/instance.service";
import LogbookService from "../services/logbook.service";
import {cutValueFormated, dateToDateLocale } from "../util/GlobalFunctions";
import "../css/LogbookPage.css";
import { CSVLink } from "react-csv";
import { formatDateString } from "../util/DateDataFormat";
import { useAuthContext } from "../contexts/AuthenticationContext";
import { ID_INSTANCE_SPINGERE } from "../util/Constants";
import { useState, useMemo, useEffect } from "react";

function LogbookPage() {
  const {t} = useTranslation();
  const {user} = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const [loading, setLoading] = useState(false);
  const [idSelectedInstance, setIdSelectedInstance] = useState(user.instance.idInstance == ID_INSTANCE_SPINGERE ? 2
    : user.instance.idInstance);
  const [instances, setInstances] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [logs, setLogs] = useState([]);
  const [nameSelectedDays, setNameSelectedDays] = useState(t("dashboard.today"));
  const [anchorElemFilter, setAnchorElemFilter] = useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const instanceService = useMemo(() => new InstanceService(), []);
  const logbookService = useMemo(() => new LogbookService(), []);
  const fileHeaders = [
    {label: t("logbook_page.user_name"), key: 'userName'},
    {label: t("logbook_page.user_lastname"), key: 'userLastName'},
    {label: t("user_page.email"), key: 'userEmail'},
    {label: t("logbook_page.action"), key: 'logbookType.name'},
    {label: 'URL', key: 'url'},
    {label: t("payment.date"), key: 'formattedDate'},
    {label: t("logbook_page.http_code"), key: 'httpCode'},
    {label: 'ERROR', key: 'isError'}
  ];

  useEffect(() => {
    fillInstances();
    handleSearch();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [idSelectedInstance, startDate, endDate]);

  const fillInstances = () => {
    if (user.instance.idInstance == ID_INSTANCE_SPINGERE) {
      instanceService.getAll().then((response) => {
        if (response.data.success) {
          setInstances(response.data.data);
        }
      }).catch((e) => console.log(e));
    } else {
      setInstances([user.instance]);
    }
  }

  const handleChangeDays = (id) => {
    setAnchorElemFilter(null);
    let start = new Date();
    switch (id) {
      case 1:
        setStartDate(new Date());
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.today"));
        break;
      case 2:
        start.setDate(start.getDate() - 5)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_5_days"));
        break;
      case 3:
        start.setDate(start.getDate() - 15)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_15_days"));
        break;
      case 4:
        start.setDate(start.getDate() - 30)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_30_days"));
        break;
    }
    handleSearch();
  }

  const handleSearch = () => {
    setLoading(true);
    setLogs([]);
    let start = dateToDateLocale(startDate).toISOString();
    let end = dateToDateLocale(endDate).toISOString();
    logbookService.find(idSelectedInstance, start.slice(0, 10), end.slice(0, 10)).then(({data : response}) => {
      if (response.success == true) {
        let data = response.data.map(row => ({...row, formattedDate: formatDateString(new Date(row.date), 'dd/MM/yyyy HH:mm:ss') }))
        setLogs(data);
      }
    })
    .catch((e) => console.log(e))
    .finally(() => setLoading(false));
  }

  return (
    <SiteWrapper>
      <Container>
        <Typography variant='h4' >
          {t("logbook_page.title")}
        </Typography>

        <Card elevation={2} sx={{padding: "6px"}} >
          <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end" >
              <Grid xs={4} lg={3}>
                {user.instance.idInstance == ID_INSTANCE_SPINGERE ?
                  <TrueffortSelect
                      id="idSlInstance"
                      name="Instance"
                      size="small"
                      sx={{ backgroundColor: '#F5F5F5', color: '#07074E' }}
                      IconComponent={KeyboardArrowDownIcon}
                      displayEmpty
                      fullWidth
                      value={idSelectedInstance}
                      labelstring={t("logbook_page.instance")}
                      onChange={(e) => setIdSelectedInstance(e.target.value)}
                  >
                      <MenuItem
                          value=""
                          disabled
                      >
                          {t("Select")}
                      </MenuItem>
                      {instances.map((pro) =>
                          {
                          if (pro.instanceType.idInstanceType == 2) {
                            return (
                              <MenuItem
                                  key={pro.idInstance}
                                  value={pro.idInstance}
                                  className={pro.active == 1 ? "" : "inactive"}
                              >
                                  {pro.name}
                              </MenuItem>
                          )
                          }}
                      )}
                  </TrueffortSelect> : <Typography>
                    {user.instance.name}
                  </Typography>}
              </Grid>
              <Grid xs={3} lg={2}>
                <TrueffortNavButton
                    id="idSelectedDays"
                    size="small"
                    variant="contained"
                    onClick={(event) => setAnchorElemFilter(event.currentTarget)}
                    className={"active"}
                    fullWidth
                >
                  <CustomIconTrueffortScreen icon={<CalendarMonth />} />
                  {cutValueFormated(nameSelectedDays, 11)}
                  <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                </TrueffortNavButton>
                <Menu id="idMenuDays"
                  anchorEl={anchorElemFilter}
                  open={openOptions}
                  onClose={() => setAnchorElemFilter(null)}
                >
                  <div>
                    <MenuItem id="idDays_0" key={"_1"} onClick={() => handleChangeDays(1)}>
                      {t("dashboard.today")}
                    </MenuItem>
                    <MenuItem id="idDays_1" key={"_5"} onClick={() => handleChangeDays(2)}>
                      {t("dashboard.last_5_days")}
                    </MenuItem>
                    <MenuItem id="idDays_2" key={"_15"} onClick={() => handleChangeDays(3)}>
                      {t("dashboard.last_15_days")}
                    </MenuItem>
                    <MenuItem id="idDays_3" key={"_30"} onClick={() => handleChangeDays(4)}>
                      {t("dashboard.last_30_days")}
                    </MenuItem>
                  </div>
                </Menu>
              </Grid>
              <Grid xs={3} lg={2}>
                <span style={{fontWeight: 700, fontSize: "0.75rem", color: "#4A4A4A"}}>{t("start_date")}</span>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  maxDate={endDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={<CustomDatePickerInput/>}
                />
              </Grid>
              <Grid xs={3} lg={2}>
                <span style={{fontWeight: 700, fontSize: "0.75rem", color: "#4A4A4A"}}>{t("end_date")}</span>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  minDate={startDate}
                  onChange={date => setEndDate(date)}
                  customInput={<CustomDatePickerInput/>}
                />
              </Grid>
              <Grid xs={3} lg={3}>
                <Box sx={{display: "flex"}}>
                    {loading &&
                      <CircularProgress
                        size={34}
                        color="secondary"
                        sx={{
                          position: "absolute",
                          marginLeft: '-62px',
                        }}
                      />
                    }

                    {features.exportData &&
                      <Box sx={{paddingBottom: "5px", paddingTop: "5px"}}>
                        <CSVLink
                          id="idBtnExport"
                          headers={fileHeaders}
                          data={logs}
                          filename="results.csv"
                          target="_blank"
                          className="link-export"
                          disabled={true}
                        >
                          {t("logbook_page.export")}
                        </CSVLink>
                      </Box>
                    }
                </Box>
              </Grid>
          </Grid>
        </Card>

        <Card elevation={2} sx={{padding: "6px", marginTop: "1rem"}}>
          {loading ?
            <CircularProgress
              size={34}
              color="primary"
              sx={{margin: "0 auto", position: "relative"}}
            /> : <span sx={{fontSize: ".75rem!important"}}>{logs.length + " registros"}</span>
          }
          <TableContainer component={Paper}>
            <TrueffortTable size="small">
              <TrueffortTableHead>
                <TableRow>
                  <TrueffortTableHeadCell>{t("logbook_page.user_name")}</TrueffortTableHeadCell>
                  <TrueffortTableHeadCell>{t("logbook_page.action")}</TrueffortTableHeadCell>
                  <TrueffortTableHeadCell>URL</TrueffortTableHeadCell>
                  <TrueffortTableHeadCell>{t("payment.date")}</TrueffortTableHeadCell>
                  <TrueffortTableHeadCell>{t("logbook_page.http_code")}</TrueffortTableHeadCell>
                </TableRow>
              </TrueffortTableHead>
              <TableBody>
                {logs.map((lo, k) => (
                  <TableRow key={k}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={ "row-log " + "row-" + (k%2 == 0 ? "even" : "odd") + (lo.isError ? " error" : "")}>
                    <TableCell sx={{fontSize: ".75rem"}}>{lo.userName + " " + lo.userLastName}</TableCell>
                    <TableCell sx={{fontSize: ".75rem"}}><strong>{lo.logbookType.name}</strong></TableCell>
                    <TableCell sx={{fontSize: ".75rem"}}>{lo.url}</TableCell>
                    <TableCell sx={{fontSize: ".75rem"}}>{lo.formattedDate}</TableCell>
                    <TableCell sx={{fontSize: ".75rem"}}>{lo.httpCode}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TrueffortTable>
          </TableContainer>
        </Card>
      </Container>
    </SiteWrapper>
  );
}

export default LogbookPage;