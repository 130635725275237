import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ImageService {

    get(id: number) {
        return http?.get(baseURL.readApi.image + `/get/${id}`);
    }

}

export default ImageService;
