import {
    Grid, Card, TablePagination, Paper, TableContainer, Table, Dialog,
    TableRow, TableCell, TableHead, TableBody, IconButton, Box, Checkbox,
} from "@mui/material";
import React from "react";
import {ArrowRight as ArrowRightIcon, ArrowDropDown as ArrowDropDownIcon,
  AddBox as AddBoxIcon
} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import CardEmpty from "../custom/CardEmpty";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import ReportsService from "../../services/reports.service";
import CustomLoader from "../custom/CustomLoader";
import { StyledTableCell, StyledTableRow, TrueffortButton, TrueffortChip, 
  TrueffortTableHeadCell } from "../../MUIstyle/MUICustom";
import { dateToDateLocale, formatSeconds } from "../../util/GlobalFunctions";
import { formatDateString } from "../../util/DateDataFormat";
import dayjs from "dayjs";
import { TimeField } from "@mui/x-date-pickers";
import CustomAvatarBadge from "../custom/CustomAvatarBadge";

function GlobalUserWeeklyReport({ startDate, endDate, idsSelectedUsers, idSelectedProject, 
  inputSearch }) {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = React.useState(false);
  const [dataWeeklyTable, setDataWeeklyTable] = React.useState([]);
  const [headWeeklyTable, setHeadWeeklyTable] = React.useState([]);
  let hourInitPeriod = new Date();
  hourInitPeriod.setHours(0, 0, 0, 0);
  const [usePeriods, setUsePeriods] = React.useState(false);
  const [openDialogAddPeriod, setOpenDialogAddPeriod] = React.useState(false);
  const [newPeriod, setNewPeriod] = React.useState({ startTime: hourInitPeriod, endTime: hourInitPeriod });
  const [errorAddPeriod, setErrorAddPeriod] = React.useState("");
  const [showMessageWarningDetailWeekly, setShowMessageWarningDetailWeekly] = React.useState("");
  const [weeklyPage, setWeeklyPage] = React.useState(0);
  const [weeklyCount, setWeeklyCount] = React.useState(0);
  const [countDatesWeekly, setCountDatesWeekly] = React.useState(1);
  const [numberLinesShowWeekly, setNumberLinesShowWeekly] = React.useState(countDatesWeekly);
  const [dataByWeekly, setDataByWeekly] = React.useState([]);
  const [dataByWeeklyFilter, setDataByWeeklyFilter] = React.useState([]);
  const [pageWeeklyTab, setPageWeeklyTab] = React.useState(0);
  const [countPerPageWeekly, setCountPerPageWeekly] = React.useState(10);
  const [periodsTab, setPeriodsTab] = React.useState([]);
  const reportsService = React.useMemo(() => new ReportsService(), []);

  const styleTableContent = { fontSize: '12px', padding: 0 };
  const alignText = { textAlign: 'center' };
  const rowDataWeekly = { width: '100px', minWidth: '100px', padding: 0 };
  const rowDataDateWeekly = { width: '120px', minWidth: '120px', padding: 0 };

  React.useEffect(() => {
    setDataByWeeklyFilter(filterResponse(dataByWeekly, inputSearch));
  }, [inputSearch])
  
  React.useEffect(() => {
    if (periodsTab.length > 0) {
      setUsePeriods(true);
    }
  }, []);

  React.useEffect(() => {
    setWeeklyCount(countDatesWeekly);
    setNumberLinesShowWeekly(countDatesWeekly);
  }, [countDatesWeekly]);

  React.useEffect(() => {
    setWeeklyPage(pageWeeklyTab);
  }, [pageWeeklyTab]);

  const handleCloseDialogAddPeriod = () => {
    setOpenDialogAddPeriod(false);
  };

  React.useEffect(() => {
    setShowLoader(true);
    fillDataByWeeklyService();
    setCountDatesWeekly(Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
  }, [startDate, endDate, idsSelectedUsers, periodsTab, idSelectedProject, pageWeeklyTab, countPerPageWeekly]);

  const fillDataByWeeklyService = async () => {
    reportsService.findGlobalUsersWeekly(pageWeeklyTab + 1, countPerPageWeekly, dateToDateLocale(startDate).toISOString().slice(0,10), 
      dateToDateLocale(endDate).toISOString().slice(0,10), idsSelectedUsers.length == 1 ? idsSelectedUsers[0].idUser : "", 
      idSelectedProject, periodsTab.map((p) => {return {
        startTime: dateToDateLocale(p.startTime).toISOString(), endTime: dateToDateLocale(p.endTime).toISOString()};}))
      .then(({data: response}) => {
      if (response.success) {
        let dataAux = response.data.flat();
        setDataByWeekly(dataAux);
        setDataByWeeklyFilter(filterResponse(dataAux, inputSearch));
        setShowLoader(false);
      } else {
        console.error(response);
      }
    }).catch((ex) => {
      console.error(ex);
    }).finally(() => setShowLoader(false));
  };

  const filterResponse = (list, text) => {
    if (text != "") {
      list = list.filter(d => d.name.toUpperCase().includes(text.toUpperCase()));
    }

    return list;
  }

  const DialogAddPeriod = (onClose, open) => {
    const handleClose = () => { 
      onClose(); 
    };

    const handleListItemClick = () => {
      if (newPeriod.endTime.getTime() > newPeriod.startTime.getTime()) {
        let hasError = false;
        for (let i = 0; i < periodsTab.length; i++) {
          hasError = newPeriod.startTime.getTime() < periodsTab[i].startTime.getTime() && newPeriod.endTime.getTime() > periodsTab[i].startTime.getTime();
          if (hasError) {
            setErrorAddPeriod(t("reports.time_summary.msg_error_1").replace("{0}", dateToDateLocale(periodsTab[i].startTime).toISOString().split('T')[1].substring(0, 5)));
            break;
          }
        }

        if (!hasError) {
          for (let i = 0; i < periodsTab.length; i++) {
            hasError = newPeriod.startTime.getTime() > periodsTab[i].startTime.getTime() && newPeriod.startTime.getTime() < periodsTab[i].endTime.getTime();
            if (hasError) {
              setErrorAddPeriod(t("reports.time_summary.msg_error_2").replace("{0}", dateToDateLocale(periodsTab[i].endTime).toISOString().split('T')[1].substring(0, 5)));
              break;
            }
          }
        }
        if (!hasError) {
          for (let i = 0; i < periodsTab.length; i++) {
            hasError = newPeriod.endTime.getTime() > periodsTab[i].startTime.getTime() && newPeriod.endTime.getTime() < periodsTab[i].endTime.getTime();
            if (hasError) {
              setErrorAddPeriod(t("reports.time_summary.msg_error_1").replace("{0}", dateToDateLocale(periodsTab[i].startTime).toISOString().split('T')[1].substring(0, 5)));
              break;
            }
          }
        }

        if (!hasError) {
          for (let i = 0; i < periodsTab.length; i++) {
            hasError = newPeriod.startTime.getTime() < periodsTab[i].startTime.getTime() && newPeriod.endTime.getTime() > periodsTab[i].endTime.getTime();
            if (hasError) {
              setErrorAddPeriod(t("reports.time_summary.msg_error_3").replace("{0}",
                dateToDateLocale(periodsTab[i].startTime).toISOString().split('T')[1].substring(0, 5)).replace("{1}",
                dateToDateLocale(periodsTab[i].endTime).toISOString().split('T')[1].substring(0, 5)));
              break;
            }
          }
        }

        if (!hasError) {
          setErrorAddPeriod("");
          let p = periodsTab.slice();
          p.push(newPeriod);
          setPeriodsTab(p);
          setNewPeriod({ startTime: hourInitPeriod, endTime: hourInitPeriod });
          handleClose();
        }
      } else {
        setErrorAddPeriod(t("reports.time_summary.msg_error_4"));
      }
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <div style={{ padding: '0.8em' }}>
          <h6>{t("reports.time_summary.title_add_period")}</h6>
          <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '0.2em', width: '100%' }}>
            <div style={{ width: '40%' }}>
              <p style={{ paddingRight: '0.2em' }}>{t("reports.time_summary.in_hour") + ':'}</p>
            </div>
            <div style={{ width: '60%' }}>
              <TimeField
                size="small"
                value={dayjs(newPeriod.startTime.toISOString())}
                onChange={val => {
                  setNewPeriod({ ...newPeriod, startTime: val.$d });
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '0.2em', paddingTop: '0.2em' }}>
            <div style={{ width: '40%' }}><p style={{ paddingRight: '0.2em' }}>{t("reports.time_summary.out_hour") + ':'}</p></div>
            <div style={{ width: '60%' }}>
              <TimeField
                size="small"
                value={dayjs(newPeriod.endTime.toISOString())}
                onChange={val => {
                  setNewPeriod({ ...newPeriod, endTime: val.$d });
                }}
              />
            </div>
          </div>
          {errorAddPeriod != "" && 
            <div style={{ fontSize: '12px', color: '#E4555A', paddingTop: '0.3em' }}>{errorAddPeriod}</div>}
          <div style={{ display: 'flex', paddingTop: '1em', justifyContent: 'space-around' }}>
            <TrueffortButton
              variant="contained"
              color="grayMedium"
              size="small"
              onClick={() => {
                setNewPeriod({ startTime: hourInitPeriod, endTime: hourInitPeriod });
                handleClose();
                setErrorAddPeriod("");
              }}
            >{t("elements.buttons.cancel")}</TrueffortButton>
            <TrueffortButton
              variant="contained"
              size="small"
              onClick={() => handleListItemClick()}
            >{t("elements.buttons.accept")}</TrueffortButton>
          </div>
        </div>
      </Dialog>
    );
  }

  const handleChangeWeeklyRowsPerPage = (event) => {
    setCountPerPageWeekly(parseInt(event.target.value, 10));
    setWeeklyPage(0);
  }

  const handleChangeWeeklyPage = (event, newPage) => {
    setWeeklyPage(newPage);
    setPageWeeklyTab(newPage);
  }

  React.useEffect(() => {
    let ids = idsSelectedUsers.map((u) => u.idUser);
    let auxData = [];
    auxData = ids.length > 0 ? dataByWeeklyFilter.filter((rec) => ids.includes(rec.idUser)) : dataByWeeklyFilter.map(a => a);
    let result = [];
    let periodsHead = [];
    if (auxData.length > 0) {
        auxData[0].dataPeriods.forEach((per, j) => { periodsHead.push(per.idPeriod); });
    }
    //console.log(auxData);
    let auxEndDate = new Date(endDate);
    auxEndDate = new Date(auxEndDate.setDate(auxEndDate.getDate() - (weeklyPage * countPerPageWeekly)));

    let auxStartDate = new Date(auxEndDate);
    auxStartDate = new Date(auxStartDate.setDate(auxStartDate.getDate() + (1 - countPerPageWeekly)));
    if (auxStartDate.getTime() < startDate.getTime()) { auxStartDate = new Date(startDate); }

    //console.log(auxStartDate + " " + auxEndDate);
    auxStartDate = dateToDateLocale(auxStartDate);
    auxEndDate = dateToDateLocale(auxEndDate);
    //console.log(periodsHead);
    let totalTimeTotal = 0;
    let totalTimeProductiveTotal = 0;
    //console.log(startDate);
    while (auxEndDate >= auxStartDate) {
        let dateA = formatDateString(auxEndDate, 'yyyy-MM-dd');
        //console.log(dateA);
        let record = { date: dateA, totalTime: 0, totalTimeProductive: 0, periods: [], showDetail: false };
        let totalTime = 0;
        let totalTimeProductive = 0;

        let checkInListGlobal = [], checkOutListGlobal = [];
        let detailByUser = [];
        auxData.forEach((r) => {
            let totalTimeUser = 0;
            let totalTimeProdUser = 0;
            let dataFilter = r.data.filter((rec) => rec.startDate == dateA);
            let checkInUserList = [];
            let checkOutUserList = [];
            dataFilter.forEach((act, ind) => {
                checkInListGlobal.push(act.startTime);
                checkOutListGlobal.push(act.endTime);
                checkInUserList.push(act.startTime);
                checkOutUserList.push(act.endTime);
                totalTimeUser += act.totalSecondsReal;
                if (r.idsApplicationRelevants.includes(act.idApplication)) {
                    totalTimeProdUser += act.totalSeconds;
                }
            });
            totalTime += totalTimeUser;
            totalTimeProductive += totalTimeProdUser;
            checkInUserList = checkInUserList.sort((a, b) => a.startTime - b.startTime);
            checkOutUserList = checkOutUserList.sort((a, b) => a.endTime - b.endTime);
            if (totalTimeUser > 0) {
                detailByUser.push({
                    idUser: r.idUser, name: r.name, image: r.image, checkIn: (checkInUserList.length > 0 ? checkInUserList[0] : null),
                    checkOut: (checkOutUserList.length > 0 ? checkOutUserList[checkOutUserList.length - 1] : null), periods: [],
                    totalTime: totalTimeUser, totalTimeProductive: totalTimeProdUser
                });
            }
        });

        checkInListGlobal = checkInListGlobal.sort((a, b) => a.startTime - b.startTime);
        checkOutListGlobal = checkOutListGlobal.sort((a, b) => a.endTime - b.endTime);
        let checkIn = checkInListGlobal.length > 0 ? checkInListGlobal[0] : null;
        let checkOut = checkOutListGlobal.length > 0 ? checkOutListGlobal[checkOutListGlobal.length - 1] : null;

        let periodos = [];
        periodsHead.forEach((p) => {
            let recordsByPeriodAndDate = [];
            let totalTimePeriod = 0;
            let totalTimePeriodProductive = 0;
            let checkInList = [], checkOutList = [];
            auxData.forEach((r) => {
                let checkInListByUserAndPeriod = [];
                let checkOutListByUserAndPeriod = [];
                let totalByPeriodAndUser = 0;
                let totalProdByPeriodAndUser = 0;

                let objDataPeriod = r.dataPeriods.find((per) => per.idPeriod == p);
                let objDataPeriodFilter = objDataPeriod.data.filter((rec) => rec.startDate == dateA);
                objDataPeriodFilter.forEach((act, ind) => {
                    if (ind == 0) { checkInList.push(act) }
                    if (ind == (objDataPeriodFilter.length - 1)) { checkOutList.push(act) }
                    checkInListByUserAndPeriod.push(act.startTime);
                    checkOutListByUserAndPeriod.push(act.endTime);
                    recordsByPeriodAndDate.push(act);

                    totalByPeriodAndUser += act.totalSecondsReal;
                    if (r.idsApplicationRelevants.includes(act.idApplication)) {
                        totalProdByPeriodAndUser += act.totalSeconds;
                    }
                });

                totalTimePeriod += totalByPeriodAndUser;
                totalTimePeriodProductive += totalProdByPeriodAndUser;

                checkInListByUserAndPeriod = checkInListByUserAndPeriod.sort((a, b) => a - b);
                checkOutListByUserAndPeriod = checkOutListByUserAndPeriod.sort((a, b) => a - b);
                let indexUser = detailByUser.findIndex((u) => u.idUser == r.idUser);
                if (indexUser != -1) {
                    detailByUser[indexUser].periods.push({
                        periodKey: p, data: objDataPeriodFilter,
                        checkIn: checkInListByUserAndPeriod.length > 0 ? checkInListByUserAndPeriod[0] : null,
                        checkOut: checkOutListByUserAndPeriod.length > 0 ? checkOutListByUserAndPeriod[checkOutListByUserAndPeriod.length - 1] : null,
                        totalTime: totalByPeriodAndUser, totalTimeProductive: totalProdByPeriodAndUser
                    });
                }
            });

            checkInList = checkInList.sort((a, b) => a.startTime - b.startTime);
            checkOutList = checkOutList.sort((a, b) => a.endTime - b.endTime);
            let checkIn_ = (checkInList.length > 0 ? checkInList[0].startTime : null);
            let checkOut_ = (checkOutList.length > 0 ? checkOutList[checkOutList.length - 1].endTime : null);
            periodos.push({
                periodKey: p, data: recordsByPeriodAndDate, checkIn: checkIn_, checkOut: checkOut_,
                totalTime: totalTimePeriod, totalTimeProductive: totalTimePeriodProductive
            });
        });

        let remainingTotalTime = totalTime;
        let remainingTotalTimeProductive = totalTimeProductive;
        periodos.forEach((per) => {
            remainingTotalTime -= per.totalTime;
            remainingTotalTimeProductive -= per.totalTimeProductive;
        });
        if (periodos.length > 0) {
            periodos.push({ periodKey: 'remaining', checkIn, checkOut, totalTime: remainingTotalTime, totalTimeProductive: remainingTotalTimeProductive });
            //console.log(detailByUser);
            for (let i = 0; i < detailByUser.length; i++) {
                let t = 0;
                let tp = 0;
                for (let j = 0; j < detailByUser[i].periods.length; j++) {
                    t += detailByUser[i].periods[j].totalTime;
                    tp += detailByUser[i].periods[j].totalTimeProductive;
                }
                detailByUser[i].periods.push({
                    periodKey: 'remaining',
                    checkIn: detailByUser[i].checkIn, checkOut: detailByUser[i].checkOut,
                    totalTime: detailByUser[i].totalTime - t,
                    totalTimeProductive: detailByUser[i].totalTimeProductive - tp
                });
            }
        }
        //console.log(periodos);
        record.totalTime = totalTime;
        totalTimeTotal += totalTime;
        record.totalTimeProductive = totalTimeProductive;
        totalTimeProductiveTotal += totalTimeProductive;
        record.periods = periodos;
        record.detailByUser = detailByUser;
        result.push(record);
        auxEndDate.setDate(auxEndDate.getDate() - 1);
    }
    //console.log(periodsHead);
    let periodsTotal = [];
    if (periodsHead.length > 0) {
        periodsHead.push('remaining');
        periodsHead.forEach((ph) => {
            let pT = { periodKey: ph, totalTime: 0, totalTimeProductive: 0 };
            let list = result.map((r) => r.periods.filter((p) => p.periodKey == ph)[0]);
            pT.totalTime = list.reduce((ac, cu) => ac + cu.totalTime, 0);
            pT.totalTimeProductive = list.reduce((ac, cu) => ac + cu.totalTimeProductive, 0);
            periodsTotal.push(pT);
        });
    }
    result.push({
        date: t('reports.total'), showDetail: true, checkIn: null, checkOut: null,
        periods: periodsTotal, totalTime: totalTimeTotal, totalTimeProductive: totalTimeProductiveTotal
    });

    if (weeklyPage == 0) {
        let totalH = 0, totalProductiveH = 0;
        auxData.forEach((u) => {
            totalH += u.total;
            totalProductiveH += u.totalProductive;
        });
        //console.log(periodsHead);
        let periodsTotales = [];
        let tpt = 0, tppt = 0;
        for (let i = 0; i < periodsHead.length - 1; i++) {
            let totalByPeriod = 0;
            let totalProductiveByPeriod = 0;
            auxData.forEach((u) => {
                let p_ = u.dataPeriods.find(per => per.idPeriod == periodsHead[i]);
                totalByPeriod += p_.total;
                totalProductiveByPeriod += p_.totalProductive;
            })
            tpt += totalByPeriod;
            tppt += totalProductiveByPeriod;
            periodsTotales.push({ periodKey: periodsHead[i], total: totalByPeriod, totalProductive: totalProductiveByPeriod });
        }
        if (periodsHead.length > 0) {
            periodsTotales.push({ periodKey: 'remaining', total: totalH - tpt, totalProductive: totalProductiveH - tppt });
        }
        //console.log(periodsTotales);
        setHeadWeeklyTable({ total: totalH, totalProductive: totalProductiveH, periods: periodsTotales });
    }
    //result.push({date:'Total', totalTime:0, totalTimeProductive:0 })
    //console.log(result);
    setDataWeeklyTable(result);
    let startDate_ = new Date(startDate);
    startDate_.setHours(0);
    startDate_.setMinutes(0);
    startDate_.setSeconds(0);
    startDate_.setMilliseconds(0);
    let current = new Date();
    current = new Date(current.setMonth(current.getMonth() - 6));
    current.setHours(0);
    current.setMinutes(0);
    current.setSeconds(0);
    current.setMilliseconds(0);
    if (startDate_.getTime() < current.getTime()) {
        setShowMessageWarningDetailWeekly(t("reports.time_summary.msg_warning_information_not_available").replace("{0}", formatDateString(current, 'yyyy-MM-dd')));
    } else {
        setShowMessageWarningDetailWeekly("");
    }
}, [dataByWeeklyFilter, countDatesWeekly, weeklyPage]);

  return (
    <div>
      {!showLoader ?
        <Grid container spacing={1} sx={{ marginTop: '1.2em', width: '100%' }} >
            {dataWeeklyTable.length == 0 ?
              <CardEmpty>
                <div>{t("msg.info.empty_response")}</div>
              </CardEmpty> :
              <Card sx={{ padding: "8px", width: '100%' }}>
                <Grid container spacing={1} rowSpacing={3} style={{ margin: '0.4em', width: '99%' }}>
                  <Grid item style={{ paddingTop: 0, paddingBottom: '8px' }}>
                    <Checkbox size="small" sx={{ padding: 0 }}
                      onChange={(event) => {
                        setUsePeriods(event.target.checked);
                        if (!event.target.checked) {
                          setPeriodsTab([]);
                        }
                      }}
                      checked={usePeriods}
                    />
                    <span style={{ fontSize: "12px" }}>{t("reports.time_summary.use_periods")}</span>
                    {usePeriods && <IconButton style={{ padding: 0, color: '#07074E' }}>
                      <AddBoxIcon sx={{ width: '0.9em' }}
                        onClick={() => { setOpenDialogAddPeriod(true); }}
                      />
                    </IconButton>}
                    {periodsTab.map((per, ind) => <TrueffortChip
                      key={ind + "_PeriodChip"}
                      label={dateToDateLocale(per.startTime).toISOString().split('T')[1].substring(0, 5) + " - "
                        + dateToDateLocale(per.endTime).toISOString().split('T')[1].substring(0, 5)}
                      size="small"
                      onDelete={() => {
                        let pers = periodsTab.map((p) => p);
                        pers.splice(ind, 1);
                        setPeriodsTab(pers);
                      }}
                    />)}
                  </Grid>
                  <TableContainer sx={{ overflow: "scroll", maxWidth: '1450px !important' }} component={Paper}>
                    <Table responsive="true">
                      <TableHead>
                        <TableRow sx={{ backgroundColor: '#DCDCDC !important' }}>
                          <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                            {t("reports.time_summary.date")}
                          </TrueffortTableHeadCell>
                          {headWeeklyTable.periods.map((per, ind) => (
                            <TableCell sx={{ paddingTop: 0, paddingBottom: 0, background: '#EFEFEF', width: '200px' }}>
                              <TableRow sx={{ width: '200px' }}>
                                <TableCell colSpan={4} style={{ ...styleTableContent, ...alignText }}>
                                  <div style={{ width: '100%', minHeight: '1.9em' }}>
                                    {ind < (headWeeklyTable.periods.length - 1) ? t("reports.time_summary.period") : ""}
                                  </div>
                                  <div style={{ width: '100%' }}>
                                    {ind == (headWeeklyTable.periods.length - 1) ? t("reports.remaining") : per.periodKey.substring(0, 5) + per.periodKey.substring(8, 16)}
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow sx={{ width: '200px' }}>
                                <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>{t("reports.time_summary.check_in")}</TableCell>
                                <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>{t("reports.time_summary.check_out")}</TableCell>
                                <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>
                                  <div>{t("reports.total")}</div>
                                  <div>{formatSeconds(per.total ? per.total : 0, false, true, false)}</div>
                                </TableCell>
                                <TableCell style={{ ...styleTableContent, ...alignText, width: '25%' }}>
                                  <div>{t("reports.time_summary.total_productive")}</div>
                                  <div>{formatSeconds(per.totalProductive ? per.totalProductive : 0, false, true, false)}</div>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                          ))}
                          {periodsTab.length == 0 && <>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div>{"Entrada"}</div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div>{"Salida"}</div>
                            </TrueffortTableHeadCell>
                          </>}
                          <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                            <div>{t("reports.total")}</div>
                            <div>{formatSeconds(headWeeklyTable.total ? headWeeklyTable.total : 0, false, true, false)}</div>
                          </TrueffortTableHeadCell>
                          <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                            <div>{t("reports.time_summary.total_productive")}</div>
                            <div>{formatSeconds(headWeeklyTable.totalProductive ? headWeeklyTable.totalProductive : 0, false, true, false)}</div>
                          </TrueffortTableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataWeeklyTable.map((day, i) => (
                          <>
                            <StyledTableRow style={{ height: '30px' }}>
                              <StyledTableCell style={{
                                ...rowDataDateWeekly,
                                paddingLeft: i < (dataWeeklyTable.length - 1) && day.totalTime > 0 ? 0 : '2.3em'
                              }}>
                                {i < (dataWeeklyTable.length - 1) && day.totalTime > 0 ? <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => {
                                    let days = dataWeeklyTable.map(d => d);
                                    days[i].showDetail = !days[i].showDetail;
                                    let count = numberLinesShowWeekly + (days[i].showDetail ? days[i].detailByUser.length : -days[i].detailByUser.length);
                                    setNumberLinesShowWeekly(count);
                                    setDataWeeklyTable(days);
                                  }}
                                >
                                  {day.showDetail ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                                </IconButton> : null}
                                {day.date}
                              </StyledTableCell>
                              {day.periods.map((per, ind) => (
                                <StyledTableCell sx={{ paddingTop: 0, paddingBottom: 0, width: '200px', ...alignText }}>
                                  <TableRow sx={{ width: '200px', backgroundColor: 'transparent !important' }}>
                                    <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{""}</StyledTableCell>
                                    <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{""}</StyledTableCell>
                                    <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTime, false, true, false)}</StyledTableCell>
                                    <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTimeProductive, false, true, false)}</StyledTableCell>
                                  </TableRow>
                                </StyledTableCell>
                              ))}
                              {periodsTab.length == 0 && <>
                                <StyledTableCell style={{ ...rowDataWeekly }}>{""}</StyledTableCell>
                                <StyledTableCell style={{ ...rowDataWeekly }}>{""}</StyledTableCell>
                              </>}
                              <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(day.totalTime, false, true, false)}</StyledTableCell>
                              <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(day.totalTimeProductive, false, true, false)}</StyledTableCell>
                            </StyledTableRow>
                            {i < (dataWeeklyTable.length - 1) && day.showDetail && day.detailByUser.map((us, ind) => (
                              <StyledTableRow style={{ height: '40px' }}>
                                <StyledTableCell style={{ ...rowDataWeekly, paddingLeft: '0.4em' }}>
                                  <CustomAvatarBadge 
                                    name={us.name.length > 12 ? us.name.substring(0, 12) + "..." : us.name}
                                    urlImage={us.image?.url}
                                  />
                                </StyledTableCell>
                                {us.periods.map((per, ind_) => (
                                  <StyledTableCell sx={{ paddingTop: 0, paddingBottom: 0, width: '200px', ...alignText }}>
                                    <StyledTableRow sx={{ width: '200px', backgroundColor: 'transparent !important' }}>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{per.checkIn ? formatDateString(per.checkIn, 'HH:mm:ss') : '-'}</StyledTableCell>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{per.checkOut ? formatDateString(per.checkOut, 'HH:mm:ss') : '-'}</StyledTableCell>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTime, false, true, false)}</StyledTableCell>
                                      <StyledTableCell sx={{ ...rowDataWeekly, ...alignText, backgroundColor: 'transparent' }}>{formatSeconds(per.totalTimeProductive, false, true, false)}</StyledTableCell>
                                    </StyledTableRow>
                                  </StyledTableCell>
                                ))}
                                {periodsTab.length == 0 && <>
                                  <StyledTableCell style={{ ...rowDataWeekly }}>{us.checkIn ? formatDateString(us.checkIn, 'HH:mm:ss') : '-'}</StyledTableCell>
                                  <StyledTableCell style={{ ...rowDataWeekly }}>{us.checkOut ? formatDateString(us.checkOut, 'HH:mm:ss') : '-'}</StyledTableCell>
                                </>}
                                <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(us.totalTime, false, true, false)}</StyledTableCell>
                                <StyledTableCell style={{ ...rowDataWeekly }}>{formatSeconds(us.totalTimeProductive, false, true, false)}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                    <Box sx={{
                      display: 'flex', justifyContent: "flex-end", alignItems: "center", marginTop: '1em'
                    }}>
                      <TablePagination
                        labelRowsPerPage={t("elements.pagination.label")}
                        rowsPerPageOptions={[10, 20, 30]}
                        component={"div"}
                        onRowsPerPageChange={handleChangeWeeklyRowsPerPage}
                        onPageChange={handleChangeWeeklyPage}
                        count={weeklyCount}
                        rowsPerPage={countPerPageWeekly}
                        page={weeklyPage}
                      />
                    </Box>
                  </div>
                  {showMessageWarningDetailWeekly != "" && periodsTab.length > 0 && idsSelectedUsers.length == 1 &&
                    <div style={{ fontSize: '12px', color: '#E4555A', paddingTop: '0.3em' }}>{showMessageWarningDetailWeekly}</div>}
                </Grid>
                {DialogAddPeriod(handleCloseDialogAddPeriod, openDialogAddPeriod)}
              </Card>
            }
        </Grid>
        : <CustomLoader />
      }
    </div>
  );
}

export default GlobalUserWeeklyReport;