import { CircularProgress, Stack } from "@mui/material";

// @ts-ignore
const CustomLoader = () => {
  return (
    <div style={{ padding: '8px', marginTop:'0.5em' }}>
      <Stack alignItems={"center"} justifyContent={"center"} style={{height:'300px'}}>
        <CircularProgress />
      </Stack>
    </div>
  )
};

export default CustomLoader;