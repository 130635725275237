import {
    Grid, Card, Slider, Menu, MenuItem, Paper, IconButton, Box
} from "@mui/material";
import React from "react";
import { Help as HelpIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import CardEmpty from "../custom/CardEmpty";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import ReportsService from "../../services/reports.service";
import CustomLoader from "../custom/CustomLoader";
import { HelperDialog, TrueffortNavButton } from "../../MUIstyle/MUICustom";
import { cutValueFormated, dateToDateLocale, getNumberBusinessDaysOnward } from "../../util/GlobalFunctions";
import { formatDateString } from "../../util/DateDataFormat";
import InstanceService from "../../services/instance.service";
import { COLORS_RELEVANT, MIN_LEVEL_ADMIN } from "../../util/Constants";
import CustomIconTrueffortScreen from "../custom/CustomIconTrueffortScreen";
import { Scatter } from "react-chartjs-2";
import SummaryActivityService from "../../services/summaryActivity.service";

function GlobalUserBenchmarkReport({ startDate, endDate, idsSelectedUsers, idSelectedProject, inputSearch }) {
  let appsWeb = JSON.parse(localStorage.getItem('INSTANCE_INFO'));
  const idsAppsWeb = appsWeb.applicationsWeb.map(app => app.idApplication);
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = React.useState(false);
  const [anchorElemFilterGraphBenchmark, setAnchorElemFilterGraphBenchmark] = React.useState(null);
  const openOptionsGraphBenchmark = Boolean(anchorElemFilterGraphBenchmark);
  const [menuNumberGraphBenchmark, setMenuNumberGraphBenchmark] = React.useState(0);
  const [axisB, setAxisB] = React.useState({ id: 1, name: "IED vs IP" });
  const [dataBenchmark, setDataBenchmark] = React.useState({ data: [] });
  const [valueEfficiency, setValueEfficiency] = React.useState(user.instance?.efficiency);
  const [valueRangeProductivity, setValueRangeProductivity] = React.useState([user.instance?.lowerProductivity, user.instance?.upperProductivity]);
  const [activeHelperDialogIndex, setActiveHelperDialogIndex] = React.useState(-1);
  const instanceService = React.useMemo(() => new InstanceService(), []);
  const [dataByBenchmark, setDataByBenchmark] = React.useState({data: [], userList: [], activities:[]});
  const [dataByBenchmarkFilter, setDataByBenchmarkFilter] = React.useState({data: [], userList: [], activities:[]});
  const reportsService = React.useMemo(() => new ReportsService(), []);
  const summaryActivityService = React.useMemo(() => new SummaryActivityService(), []);

  React.useEffect(() => {
    instanceService.get(user.instance?.idInstance).then(({ data: response }) => {
      if (response.success) {
        setValueEfficiency(response.data.efficiency);
        setValueRangeProductivity([response.data.lowerProductivity,
        response.data.upperProductivity]);
      }
    });
  }, []);

  React.useEffect(() => {
    setDataByBenchmarkFilter({...dataByBenchmark, userList: filterResponse(dataByBenchmark.userList, inputSearch)});
  }, [inputSearch]);

  React.useEffect(() => {
    setShowLoader(true);
    reportsService.findGlobalBenchmark(dateToDateLocale(startDate).toISOString().slice(0,10), 
      dateToDateLocale(endDate).toISOString().slice(0,10),'', idSelectedProject).then(({data:resBench}) => {
      let currentDate = dateToDateLocale(new Date()).toISOString();
      summaryActivityService.find(dateToDateLocale(startDate).toISOString().slice(0,10), 
        dateToDateLocale(endDate).toISOString().slice(0,10), currentDate.slice(0, 10), 
        '', '', idSelectedProject).then(({data: respData}) => {
        if (!respData.success || respData?.data.applications.length == 0) {
          console.error("No existen datos para realizar los calculos");
          setShowLoader(false);
          return;
        }

        if (!resBench.success) {
          console.error("Ocurrio un error al obtener la información");
          setShowLoader(false)
          return;
        }

        let itemsUserList = resBench.data.userList.map((us) => {return {...us, 
          relevantSites: us.relevantSites.filter((s) => s.isRelevant).map((s_) => s_.idSite),
          notRelevantSites: us.relevantSites.filter((s) => !s.isRelevant).map((s_) => s_.idSite)
         }});

        setDataByBenchmark({data: idSelectedProject == '' ? resBench.data.projectTaskList : 
        resBench.data.projectTaskList.filter((pt) => pt.projectPhase.projectElement.project.idProject == idSelectedProject),
          userList: itemsUserList, activities: respData.data.applications, sites: respData.data.sites});
        setDataByBenchmarkFilter({data: idSelectedProject == '' ? resBench.data.projectTaskList : 
          resBench.data.projectTaskList.filter((pt) => pt.projectPhase.projectElement.project.idProject == idSelectedProject),
          userList: filterResponse(itemsUserList, inputSearch), activities: respData.data.applications, sites: respData.data.sites});
      }).catch((err) => {
        console.error(err);
      }).finally(() => setShowLoader(false));
    }).catch((er) => {
      console.error(er);
      setShowLoader(false);
    });
  }, [startDate, endDate, idSelectedProject]);

  React.useEffect(() => {
    //console.log(dataByBenchmark);
    let finalDataBenchmark = [];
    let idsUser = idsSelectedUsers.map((u) => u.idUser);
    //console.log(idsUser);
    dataByBenchmarkFilter.userList.map((us) => {
      //console.log(us);
      //console.log(dataByBenchmarkFilter);
      let activitiesByUser = dataByBenchmarkFilter.activities.filter((act) => act.idUser == us.idUser);
      let sitesByUser = dataByBenchmarkFilter.sites.filter((site) => site.idUser == us.idUser);
      let resFilter = dataByBenchmarkFilter.data.filter((dat) => dat.users.map((u) => u.idUser).includes(us.idUser));
      let appsRelevants = us.relevantApplications.filter(ra => ra.isRelevant).map(ap => ap.idApplication);
      let datesWithActivity = activitiesByUser.map((act) => act.startDate);

      let startD = new Date(startDate);
      let SRj = 0;
      let DHT = 0;
      while (startD <= endDate) {
        if (startD.getDay() != 0 && startD.getDay() != 6) {
          DHT++;
          let dateA = formatDateString(startD, 'yyyy-MM-dd');
          if (!datesWithActivity.includes(dateA)) {
            SRj++;
          }
        }
        startD.setDate(startD.getDate() + 1);
      }

      let resTransform = [];
      resFilter.forEach((d) => {
        let upt = d.users.find((u) => u.idUser == us.idUser);
        if (upt.startDate != null) {
          let dedicatedTime = 0;
          let dedicatedTimeProd = 0;
          let acts = activitiesByUser.filter((act) => act.idProjectTask == d.idProjectTask);
          acts.forEach((act) => {
            dedicatedTime += act.totalSeconds;
            /*if (idsAppsWeb.includes(act.idApplication)) {
              if (appsRelevants.includes(act.idApplication)) {
                let sitesApp = sitesByUser.filter((site) => site.idApplication == act.idApplication && site.idProjectTask == act.idProjectTask && site.startDate == act.startDate && us.notRelevantSites.includes(site.idSite));
                let ts = sitesApp.reduce((act, cur) => act + cur.totalSeconds, 0);
                dedicatedTimeProd += act.totalSeconds - ts;
              } else {
                let sitesApp = sitesByUser.filter((site) => site.idApplication == act.idApplication && site.idProjectTask == act.idProjectTask && site.startDate == act.startDate && us.relevantSites.includes(site.idSite));
                let ts = sitesApp.reduce((act, cur) => act + cur.totalSeconds, 0);
                dedicatedTimeProd += act.totalSeconds - ts;
              }
            } else if (appsRelevants.includes(act.idApplication)) {
              dedicatedTimeProd += act.totalSeconds;
            }*/
            if (appsRelevants.includes(act.idApplication)) {
              dedicatedTimeProd += act.totalSeconds;
            }
          });

          let sD = new Date(upt.startDate);
          let eD = new Date(upt.finishDate);
          let estimatedDays = d.estimatedDays == 0 ? 1 : d.estimatedDays;
          let estimatedTime = d.estimatedTime == 0 ? 1 : d.estimatedTime;
          resTransform.push({
            idProjectTask: d.idProjectTask, idStatus: d.status.idStatus, idTaskType: d.taskType.idTaskType, 
            startDate: sD, endDate: eD, realDuration: getNumberBusinessDaysOnward(sD, eD), 
            dedicatedTimeTotal: d.dedicatedTime, dedicatedTime: dedicatedTime / 3600, 
            dedicatedTimeProd: dedicatedTimeProd / 3600, idProjectPhase: d.projectPhase.idProjectPhase, 
            estimatedDays: estimatedDays, estimatedTime: estimatedTime, 
            estimatedFinishDate: getEstimatedFinishDate(sD, estimatedDays),
            countUsers: d.users.length, estimatedTimeInd: estimatedTime / d.users.length,
            taskName: d.taskType.name, phaseName: d.projectPhase.phaseType.name,
            idProjectElement: d.projectPhase.projectElement.idProjectElement, elementName: d.projectPhase.projectElement.name,
            idProject: d.projectPhase.projectElement.project.idProject,
            projectName: d.projectPhase.projectElement.project.name
          });
        }
      })
      //console.log(resTransform);
      if (resTransform.length > 0) {
        let DRij = resTransform.reduce((ac, cu) => ac + cu.realDuration, 0);
        let TRij = resTransform.reduce((ac, cu) => ac + cu.dedicatedTime, 0);
        let DEij = resTransform.reduce((ac, cu) => ac + cu.estimatedDays, 0);
        let TEij = resTransform.reduce((ac, cu) => ac + cu.estimatedTimeInd, 0);
        /*let items = activitiesByUser.flat();
        let HPj = 0;
        for (let i = 0; i < items.length; i++) {
          if (idsAppsWeb.includes(items[i].idApplication)) {
            if (appsRelevants.includes(items[i].idApplication)) {
              let sitesApp = sitesByUser.filter((site) => site.idApplication == items[i].idApplication && site.idProjectTask == items[i].idProjectTask && site.startDate == items[i].startDate && us.notRelevantSites.includes(site.idSite));
              let ts = sitesApp.reduce((act, cur) => act + cur.totalSeconds, 0);
              HPj += items[i].totalSeconds - ts;
            } else {
              let sitesApp = sitesByUser.filter((site) => site.idApplication == items[i].idApplication && site.idProjectTask == items[i].idProjectTask && site.startDate == items[i].startDate && us.relevantSites.includes(site.idSite));
              let ts = sitesApp.reduce((act, cur) => act + cur.totalSeconds, 0);
              HPj += items[i].totalSeconds - ts;
            }
          } else if (appsRelevants.includes(items[i].idApplication)) {
            HPj += items[i].totalSeconds;
          }
        }
        HPj = HPj / 3600;*/
        let HPj = activitiesByUser.reduce((ac, cu) => ac + (appsRelevants.includes(cu.idApplication) ? cu.totalSeconds : 0), 0) / 3600;
        let HD = 8;
        let HL = HD * DHT;
        let HBT = HD * (DHT - SRj);

        let FED = DRij / DEij;
        //let intFed = 0.2;
        let PMFed = 1;
        let valueEfficiencyAux = valueEfficiency == 0 ? 1 : valueEfficiency;
        let lAdecFED = [PMFed - (valueEfficiencyAux / 100), PMFed + (valueEfficiencyAux / 100)];
        let LB = lAdecFED[1] + ((valueEfficiencyAux / 100) * 2);
        let LS = lAdecFED[0] - ((valueEfficiencyAux / 100) * 2);

        let interpretacionFed = FED >= lAdecFED[0] && FED <= lAdecFED[1] ? "Adecuado" : 
          FED > lAdecFED[1] ? "Bajo" : "Sobre saliente";
        const NLSFed = 9;
        let IED = NLSFed - (NLSFed / (LB - LS)) * (FED - LS);
        if (IED < 0) { IED = 0; }
        if (IED > 9) { IED = 9; }
        //let IED = FED > LB ? 0 : FED < LS ? 9 : NLSFed - (NLSFed / (LB - LS)) * (FED - LS);
        //console.log("FED", FED, "PMFed", PMFed, "LB", LB, "LS", LS, "IED", IED);

        let FEE = TRij / TEij;
        let interpretacionIee = FEE >= lAdecFED[0] && FEE <= lAdecFED[1] ? "Adecuado" :
            FEE > lAdecFED[1] ? "Bajo" : "Sobre saliente";
        const NLSIee = 9;
        let IEE = NLSIee - (NLSIee / (LB - LS)) * (FEE - LS);
        if (IEE < 0) { IEE = 0; }
        if (IEE > 9) { IEE = 9; }
        //let IEE = FEE > LB ? 0 : FEE < LS ? 9 : NLSIee - (NLSIee / (LB - LS)) * (FEE - LS);
        //console.log("FEE", FEE, "IEE", IEE);

        let FIP = HPj / HL;
        let valueRangeProductivityAux = valueRangeProductivity[1] == valueRangeProductivity[0] ? valueRangeProductivity[0] - 1 : valueRangeProductivity[0];
        let LAdecuado = [valueRangeProductivityAux / 100, valueRangeProductivity[1] / 100];
        let interpretacionFip = FIP >= LAdecuado[0] && FIP <= LAdecuado[1] ? "Adecuado" :
            FIP < LAdecuado[0] ? "Bajo" : "Sobre saliente";
        let intRangeProd = ((valueRangeProductivity[1] - valueRangeProductivityAux) / 100);
        let LBFip = (valueRangeProductivityAux - (valueRangeProductivity[1] - valueRangeProductivityAux)) / 100;
        let LSFip = 1 + (intRangeProd / 2);
        const NLSFip = 9;
        let IP = FIP < LBFip ? 0 : FIP > LSFip ? 9 : (NLSFip / (LSFip - LBFip)) * (FIP - LBFip);
        //console.log("FIP", FIP, "LSFip", LSFip, "LBFip", LBFip);

        //console.log("Drij", DRij, "TRij", TRij, "DEij", DEij, "TEij", TEij, "HPj", HPj, "HD", HD, 
        //    "HL", HL, "HBT", HBT, "SRj", SRj, "DHT", DHT, "FED", FED, "IED", IED, "FEE", FEE, "IEE", IEE, "interpretacionFed", interpretacionFed,  
        //    "interpretacionIee", interpretacionIee, "FIP", FIP, "IP", IP, "interpretacionFip", interpretacionFip);
        finalDataBenchmark.push({
          idUser: us.idUser, name: us.name + ' ' + (us.lastName ? us.lastName : ''), DRij, TRij, DEij, TEij, HD, SRj, HL, HBT, HPj, DHT, IED,
          interpretacionFed, IEE, interpretacionIee, IP, interpretacionFip
        })
      }
    });
    //console.log(finalDataBenchmark);
    let groups = [];
    let configs = [];
    let listRemaining = [];
    let remaining = { label: 'Sin grupo', data: [], backgroundColor: COLORS_RELEVANT[0] };
    let remainingArray_ = finalDataBenchmark.filter((d) => !idsUser.includes(d.idUser));
    remainingArray_.forEach((rem) => {
      remaining.data.push({ x: (rem.IP / 9) * 100, y: ((axisB.id == 1 ? rem.IED : rem.IEE) / 9) * 100 });
      listRemaining.push({
        idUser: rem.idUser, name: rem.name, intX: rem.interpretacionFip,
        intY: axisB.id == 1 ? rem.interpretacionFed : rem.interpretacionIee
      });
    });
    if (remaining.data.length > 0) { 
      groups.push(remaining);
      configs.push(listRemaining);
    }

    idsSelectedUsers.map((u_) => {
      let db = finalDataBenchmark.find((d) => d.idUser == u_.idUser);
      if (db) {
        let g = { label: u_.name + ' ' +(u_.lastName ? u_.lastName : ''), 
          data: [{ x: (db.IP / 9) * 100, y: ((axisB.id == 1 ? db.IED : db.IEE) / 9) * 100 }],
          backgroundColor: COLORS_RELEVANT[groups.length]
        };
        configs.push([{
          idUser: db.idUser, name: db.name, intX: db.interpretacionFip,
          intY: axisB.id == 1 ? db.interpretacionFed : db.interpretacionIee
        }])
        groups.push(g);
      }
    });
        
    //console.log(configs);
    //console.log(groups);
    setDataBenchmark({ data: groups, configs });
  }, [dataByBenchmarkFilter, axisB, idsSelectedUsers, valueEfficiency, valueRangeProductivity]);

  const filterResponse = (list, text) => {
    if (text != "") {
      list = list.filter(d => d.name.toUpperCase().includes(text.toUpperCase()));
    }
    return list;
  }

  const getEstimatedFinishDate = (start, count) => {
    start.setHours(start.getHours() + 6);
    let estimatedFinishDate = new Date(start);
    let c = 0;
    while (c < count) {
      let day = new Date(estimatedFinishDate).getDay();
      if (day == 5) {
        c++;
        estimatedFinishDate.setDate(estimatedFinishDate.getDate() + 3);
      } else if (day == 6) {
        c++;
        estimatedFinishDate.setDate(estimatedFinishDate.getDate() + 2);
      } else {
        c++
        estimatedFinishDate.setDate(estimatedFinishDate.getDate() + 1);
      }
    }
    return estimatedFinishDate;
  }

  const handleClickMenuBenchmark = (event, value) => {
    setAnchorElemFilterGraphBenchmark(event.currentTarget);
    setMenuNumberGraphBenchmark(value);
  }

  const handleActiveHelperChange = () => {
    setActiveHelperDialogIndex((prev) => prev + 1);
  }

  const image = new Image();
  image.src = './assets/images/int_benchmark.png';

  const options = {
    animation: false,
    responsive:true,
    elements: {
      point: {
        pointStyle: 'circle',
        radius: 10  
      }
    },
    scales: { 
      x: { title: { 
          text: t("reports.benchmark.axis_IP") + " (%)", 
          display: true
        }, type: 'linear', min: 0, max:100
      }, 
      y: { title: { 
          text: (axisB.id == 1 ? t("reports.benchmark.axis_IED") : t("reports.benchmark.axis_IEE")) + " (%)", 
          display: true
        }, type:'linear', min: 0, max:100
      } 
    },
    plugins: {
      legend: {
        display: true,
        position: 'right'
      },
      tooltip: {
        enabled: 'auto',
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = dataBenchmark.configs[context.datasetIndex][context.dataIndex].name 
              + ' (' + (axisB.id == 1 ? t("reports.benchmark.IED") : t("reports.benchmark.IEE")) 
              + ': ' + dataBenchmark.configs[context.datasetIndex][context.dataIndex].intY +' - ' 
              + t("reports.benchmark.IP") + ': ' + dataBenchmark.configs[context.datasetIndex][context.dataIndex].intX + ')';
            return (
              label
            );
          }
        }
      }
    }
  };

  const plugin = {
    id: "custom_canvas_background_image",
    beforeDraw: (chart) => {
      //console.log(chart);
      if (image.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        ctx.drawImage(image, left, top, width, height);
      } else {
        image.onload = () => chart.draw();
      }
    }
  }

  return (
    <div>
      {!showLoader ?
        <Paper elevation={0} sx={{ padding: '12px', bgcolor: '#F5F5F5', marginTop: '1em', width: '100%' }}>
        <div>
          {dataBenchmark.data.length > 0 ?
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <Card sx={{ padding: "8px", height: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '25px' }}>
                        <HelperDialog
                          title={t("reports.benchmark.config_efficiency")}
                          body={<div>
                            <span>{(axisB.id == 1 ? t("reports.benchmark.description_efficiency_IED") : t("reports.benchmark.description_efficiency_IEE")) + t("reports.benchmark.description_example")}</span><br />
                            <span>{"- "}<strong>{t("reports.benchmark.int_under")}</strong>{t("reports.benchmark.description_under").replace("{0}", valueEfficiency)}</span><br /><br />
                            <span>{"- "}<strong>{t("reports.benchmark.int_suitable")}</strong>{t("reports.benchmark.description_suitable").replace("{0}", valueEfficiency)}</span><br /><br />
                            <span>{"- "}<strong>{t("reports.benchmark.int_overhang")}</strong>{t("reports.benchmark.description_overhang").replace("{0}", valueEfficiency)}</span>
                          </div>}
                          dialogIndex={0}
                          totalDialogs={2}
                          handleNext={handleActiveHelperChange}
                          isOpen={activeHelperDialogIndex === 0}
                          handleClose={() => setActiveHelperDialogIndex(-1)}
                        >
                          <IconButton onClick={() => setActiveHelperDialogIndex(0)} >
                            <HelpIcon sx={{ color: '#6875C7' }} />
                          </IconButton>
                        </HelperDialog>
                        <span style={{ fontSize: "12px", paddingRight: '0.9em' }}>{t("reports.benchmark.config_efficiency")}</span>
                        <Box sx={{ width: 100, display:'flex', flexDirection:'row' }}>
                          <Slider
                            value={valueEfficiency}
                            onChange={(ev, nv) => setValueEfficiency(nv)}
                            onChangeCommitted={(event) => {
                              if (user.role.accessLevel >= MIN_LEVEL_ADMIN) {
                                instanceService.updateEfficiency(user.instance.idInstance, valueEfficiency).then(({ data: response }) => {
                                }).catch(error => {
                                  console.error("error", error);
                                });
                              }
                            }}
                            max={50}
                            valueLabelFormat={(e) => e + "%"}
                            sx={{ color: '#9A64E2', marginBottom: 0 }}
                            marks={[ { value: 0, label: '0%' },
                              { value: 50, label: '50%' }]}
                            disabled={(user.role?.accessLevel < MIN_LEVEL_ADMIN)}
                          />
                        </Box>
                        <span style={{ fontSize: "12px", paddingLeft: '0.9em' }}>{'[' + valueEfficiency + '%]'}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '25px', paddingLeft: '1.5em' }}>
                        <HelperDialog
                          title={t("reports.benchmark.config_IP")}
                          body={
                            <div>
                              <span>{t("reports.benchmark.description_IP")}</span><br />
                              <span>{"- "}<strong>{t("reports.benchmark.int_under")}</strong>{t("reports.benchmark.description_under_IP").replace("{0}", valueRangeProductivity[0])}</span><br />
                              <span>{"- "}<strong>{t("reports.benchmark.int_suitable")}</strong>{t("reports.benchmark.description_suitable_IP").replace("{0}", valueRangeProductivity[0]).replace("{1}", valueRangeProductivity[1])}</span><br />
                              <span>{"- "}<strong>{t("reports.benchmark.int_overhang")}</strong>{t("reports.benchmark.description_overhang_IP").replace("{0}", valueRangeProductivity[1])}</span>
                            </div>
                          }
                          dialogIndex={1}
                          totalDialogs={2}
                          isOpen={activeHelperDialogIndex == 1}
                          handleClose={() => setActiveHelperDialogIndex(-1)}
                        >
                          <span style={{ fontSize: "12px", paddingRight: '0.9em' }}>{t("reports.benchmark.config_IP")}</span>
                        </HelperDialog>
                        <Box sx={{ width: 150 }}>
                          <Slider
                            value={valueRangeProductivity}
                            onChange={(ev, nv) => setValueRangeProductivity(nv)}
                            onChangeCommitted={(event) => {
                              if (user.role.accessLevel >= MIN_LEVEL_ADMIN) {
                                instanceService.updateProductivityRange(user.instance.idInstance, {
                                  lowerProductivity: valueRangeProductivity[0], 
                                  upperProductivity: valueRangeProductivity[1]
                                }).then(({ data: response }) => {
                                }).catch(error => {
                                  console.error("error", error);
                                });
                              }
                            }}
                            max={120}
                            min={50}
                            valueLabelFormat={(e) => e + "%"}
                            sx={{ color: '#9A64E2', marginBottom: 0 }}
                            marks={[ { value: 100, label: '' }, { value: 50, label: '50%' },
                              { value: 120, label: '120%' }]}
                            disabled={user.role?.accessLevel < MIN_LEVEL_ADMIN}
                          />
                        </Box>
                        <span style={{ fontSize: "12px", paddingLeft: '0.9em' }}>
                          {'[' + valueRangeProductivity[0] + '% - ' + valueRangeProductivity[1] + '%]'}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Grid item >
                          <TrueffortNavButton
                            id="idSelectedGraphBenchmark"
                            size="small"
                            variant="contained"
                            onClick={(event) => handleClickMenuBenchmark(event, 1)}
                            className={""}
                            fullWidth
                          >
                            {cutValueFormated(axisB.name, 18)}
                            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
                          </TrueffortNavButton>
                        </Grid>
                      </div>
                      <Menu id="idTasksFilterGraphBenchmark"
                        anchorEl={anchorElemFilterGraphBenchmark}
                        open={openOptionsGraphBenchmark}
                        onClose={() => setAnchorElemFilterGraphBenchmark(null)}
                      >
                        {menuNumberGraphBenchmark == 1 && <div>
                          <MenuItem id="axisB_0" key={"0_B"} onClick={() => setAxisB({ id: 1, name: "IED vs IP" })}>
                            {"IED vs IP"}
                          </MenuItem>
                          <MenuItem id="axisB_1" key={"1_B"} onClick={() => setAxisB({ id: 2, name: "IEE vs IP" })}>
                            {"IEE vs IP"}
                          </MenuItem>
                        </div>}
                      </Menu>
                    </Grid>
                    <div style={{ width: '100%', height: '90%', paddingTop: '2em' }}>
                      <Grid item xs={10}>
                        <Scatter data={{ datasets: dataBenchmark.data}} options={options} plugins={[plugin]} />
                      </Grid>
                    </div>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card sx={{ padding: "8px", textAlign: 'justify'}}>
                  <div>
                    <span><strong>{t("reports.benchmark.config_efficiency") + ': '}</strong><br/>
                    {'- ' + t("reports.benchmark.axis_IED") + ': ' + t("reports.benchmark.description_efficiency_IED")}</span><br /><br />
                    <span>{'- ' + t("reports.benchmark.axis_IEE") + ': ' + t("reports.benchmark.description_efficiency_IEE")}</span><br /><br />
                    <span><strong>{t("reports.benchmark.config_IP") + ': '}</strong>{t("reports.benchmark.description_IP")}</span>
                  </div>
                </Card>
              </Grid>
            </Grid> :
            <CardEmpty> <div>{t("reports.benchmark.empty_response")}</div></CardEmpty>
          }
        </div>
      </Paper>
        : <CustomLoader />
      }
    </div>
  );
}

export default GlobalUserBenchmarkReport;