// @flow

import * as React from "react";
import RegisterCardFormTRUE from "../components/pricing-login-register/RegisterCardFormTRUE";
import UserService from "../services/user.service";
import {AxiosResponse} from "axios";
import {DEFAULT_REQUEST_ERROR, URL_LOCAL, URL_PROD, URL_QA} from "../util/Constants";
import StandaloneFormPageTRUE from "../components/StandaloneFormPageTRUE"
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

/**
 * A register page
 * Can be easily wrapped with form libraries like formik and redux-form
 */
function RegisterPage() {

    //const [alertState, setAlertState] = React.useState({visible: false, text: "", error: false});
    const userService = React.useMemo(() => new UserService(), []);

    const location = useLocation();
    const {t} = useTranslation();
    // console.log("card tipe " + location.state.cardType);

    const handleSubmit = async (values: any, setValues: any) => {
        const formAccount = {
            name: values.name,
            lastName: values.lastname,
            email: values.email,
            company: values.company,
            password: values.password,
            idInstance: values.idInstance,
            phone: values.phone,
            idUserCompanyRoleType: values.idUserCompanyRoleType,
            idUserCompanyPositionType: values.idUserCompanyPositionType,
            idCompanyBusinessSubtype: values.idCompanyBusinessSubtype
        }
        let errorMessage = t(DEFAULT_REQUEST_ERROR);
        let send = false;
        try {
            const {data: response}: AxiosResponse = await userService.create(formAccount);
            if (response.success) {
                setValues({...values, active: false, token: "", passed: false, showConfirmForm: true})
                //setAlertState({visible: true, text: t("register.write_token"), error: false})
                toast.info(t("register.write_token"));
                send = true;
                return;
            }
            if (response?.internalError) {
                errorMessage = response.message;
            }
        } catch (error) {
            console.info("Error #RegisterPage " + error);
        } finally {
            if (!send) {
                //setAlertState({visible: true, text: errorMessage, error: true});
                toast.error(errorMessage)
            }
        }
    };
    return (
        <div id="register-page" className="h-100">
            <StandaloneFormPageTRUE
                imageURL={"./assets/images/new_logo.png"}
                showMensura={false}
                urlLogo={window.location.host.includes("tool.trueffort") ? URL_PROD : window.location.host.includes("qa.trueffort") ? URL_QA : URL_LOCAL}
            >

                {/*alertState.visible ?
                    <Alert type={alertState.error ? "danger" : "primary"}>
                        {/*<Header.H4>Completed</Header.H4>*/}{/*
                        <p>
                            {alertState.text}
                        </p>
                    </Alert> : null
                */}
                <RegisterCardFormTRUE /*setAlertState={setAlertState} */strings handleSubmit={handleSubmit}/>
            </StandaloneFormPageTRUE>
            <ToastContainer theme="colored" />
        </div>
    );
}

export default RegisterPage;
