// @flow
import React from "react";
import {
  Card, TableContainer, Paper, TableHead, TableBody, Table,
  Chip, CardContent, TablePagination, Box, Stack
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useFormik} from "formik";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import ApiRequest from "../../helpers/ApiRequest";
import {useTranslation} from "react-i18next";
import CategoryService from "../../services/category.service";
import '../../css/Category.css';
import '../../css/FormCrud.css';
import Select from "react-select";
import {confirmAlert} from "react-confirm-alert";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import {WHITOUT_CATEGORY_NAME, USER_LANGUAGE_KEY} from "../../util/Constants";
import { StyledTableRow, StyledTableCell, TrueffortButton, TrueffortCardTable, TrueffortSearchInput, 
    TrueffortTableHeadCell, TrueffortChip, TrueffortTextField, TrueffortInputLabel } from "../../MUIstyle/MUICustom";
import { styleReactSelect } from "../../MUIstyle/GlobalStyles";
import { Add, Edit, Delete, DeleteOutline, Save, Clear } from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomAvatar from "../custom/CustomAvatar";

function  CategoriesTabTRUE() {
    const {user} = useAuthContext();
    const {t} = useTranslation();

    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.only('xs'));

    const [categories, setCategories] = React.useState([]);
    const [categoriesFiltered, setCategoriesFiltered] = React.useState([]);
    const [applicationsBySelect, setApplicationsBySelect] = React.useState([]);
    //const [errorMessage, setErrorMessage] = React.useState("");
    //const [infoMessage, setInfoMessage] = React.useState("");
    const [applicationsValues, setApplicationsValues] = React.useState([]);
    const [applicationWithoutCategory, setApplicationWithoutCategory] = React.useState();
    const imageFile = React.useRef();
    const [imageInfo, setImageInfo] = React.useState();
    const [showFormAdd, setShowFormAdd] = React.useState(false);
    //let NUMBER_ITEMS_BY_PAGE = 10;
    let ROWS_PER_PAGE = 5;
    const [rowsPerPageCategories, setRowsPerPageCategories] = React.useState(ROWS_PER_PAGE);
    const [categoriesPage, setCategoriesPage] = React.useState(0);
    const [categoriesCount, setCategoriesCount] = React.useState(0);//Cantidad de categorias

    const [state, setState] = React.useState({
        //showFormAdd: false,
        loading: false,
        isEditing: false,
        isUpdating: false,
        isCreating: false,
        errorMessageFile: ""
    });
    const categoryService = React.useMemo(() => new CategoryService(), []);

    const initialValues = React.useMemo(() => {
        return {
            idCategory: null,
            name: '',
            isEditing: false,
            isCreating: false,
            isLoading: false,
            applications: [],
            image: null
        }
    }, [categories]);

    //const [pageActive, setPageActive] = React.useState({activePage: 1});
    //const [indexPage, setIndexPage] = React.useState(0);
    //const [categoriesPage, setCategoriesPage] = React.useState([]);

    React.useEffect(() => {
        //fillApplications();
        fillCategories();
    }, []);

    /*React.useEffect(() => {
        let page = categories.filter((user, k) => k >= indexPage && k < indexPage + NUMBER_ITEMS_BY_PAGE);
        //console.log("page", page);
        setCategoriesPage(page);
    }, [categories, indexPage]);*/

    const fillCategories = () => {
      // @ts-ignore
      categoryService.find(user.instance.idInstance).then(({data: response}) => {
        //console.log(response);
        if (Array.isArray(response.data)) {
          setCategories(response.data);
          setCategoriesFiltered(response.data);
          let objWithoutCategory = response.data.find((c) => c.name.toUpperCase() == WHITOUT_CATEGORY_NAME.toUpperCase());
          objWithoutCategory.applications = objWithoutCategory.applications.sort((a, b) => a.application.name > b.application.name ? 1 : -1);
          setApplicationWithoutCategory(objWithoutCategory);
          setCategoriesCount(response.data.length);
        }
      });
    }

    const handleChangeSearchInput = (searchFilter) => {
        let items = categories.filter((data) => {
            if (searchFilter == "") {
                return categories;
            } else {
                if (data.name.toLowerCase().includes(searchFilter.toLowerCase())) {
                    return data;
                }
            }
        });

        setCategoriesFiltered(items);
        setCategoriesCount(items.length);
    }

    const handleSubmit = async (values, setValues) => {
        //console.log("handleSubmit");
        //console.log(applicationsValues);

        const dataRequest = {
            name: values.name,
            idInstance: user.instance.idInstance,
            applications: getArrIdApplications(),
            imageDto: null
        };
        //console.log("data sent new category" + JSON.stringify(dataRequest));

        try {
            const data = await ApiRequest(categoryService.create(getFormData(dataRequest)));
            //console.log(data);
            //setInfoMessage(t("category_page.msg_success_add"));
            toast.success(t("category_page.msg_success_add"));
            formik.setSubmitting(false);
            //setState({...state, showFormAdd: false, isCreating: false});
            //setImageInfo(null);
            //setShowFormAdd(false);
            handleCancel();
            //console.log("bforcancl");
            fillCategories();
        } catch (error) {
            //setAlertState({visible: true, text: t(error.message), error: true, withActions: false});
            //setErrorMessage(error.message);
            toast.error(t(error.message));
            //console.log("Error #Category handleSubmit" + JSON.stringify(error));
        }
    };
    //console.log(state);

    const handleEdit = async (values, setValues) => {
        //console.log("submit");
        const dataRequest = {
            idCategory: values.idCategory,
            name: values.name,
            idInstance: values.idInstance,
            applications: getArrIdApplications(),
            imageDto: null
        };
        //console.log("data sent edited category" + JSON.stringify(dataRequest));
        try {
            const data = await ApiRequest(categoryService.update(getFormData(dataRequest)));
            //setInfoMessage(t("category_page.msg_success_update"));
            toast.success(t("category_page.msg_success_update"));
            //setState({...state, showFormAdd: false});
            setShowFormAdd(false);
            fillCategories();
        } catch (error) {
            //setAlertState({visible: true, text: t(error.message), error: true, withActions: false});
            toast.error(t(error.message));
            fillCategories();
        }
    }

    const getFormData = (dataRequest) => {
        var formData = new FormData();
        if (imageInfo) {
            formData.append("image", imageFile.current.files[0], imageFile.current.files[0].name);
            dataRequest.imageDto = imageFile.current.files[0];
        } else {
            formData.append("image", new Blob([JSON.stringify(null)], {
                type: "application/json"
            }));
        }
        formData.append('categoryDto', new Blob([JSON.stringify(dataRequest)], {
            type: "application/json"
        }));

        return formData;
    }

    function getArrIdApplications() {
        //let arr: { idApplication: any; idCategory: number; }[] = [];
        let arr = [];
        applicationsValues.map((app, k) => {
            arr.push({idApplication: app.value, idCategory: 0});
        });
        return arr;
    }

    function prepareEdit(category) {
        setState({...state, isUpdating: true});
        formik.setValues({...initialValues, ...category, isEditing: true});
        setCategoriesFiltered(categories.map((item_) => item_.idCategory === category.idCategory ? {
            ...item_,
            isEditing: true
        } : item_));

        //console.log(category);
        //let arr: { value: any; label: any; }[] = [];
        let arr = [];
        if (Array.isArray(category.applications)) {
            category.applications.map((app, k) => {
                arr.push({value: app.idApplication, label: app.application.name});
            });
        }
        //console.log(arr);
        setApplicationsValues(arr);
        let apps = arr.map(app => app);
        //console.log(apps);
        let withoutCategory = categories.find((item) => item.name.toUpperCase() == WHITOUT_CATEGORY_NAME.toUpperCase());
        //console.log(withoutCategory);
        withoutCategory.applications.forEach((item) => {apps.push({value: item.idApplication, label: item.application.name })});
        setApplicationsBySelect(apps);
    }

    const validate = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = 'Required';
        } else if (values.name.length > 25) {
            errors.name = 'forms.nameLengthValidation25';
        }

        if (imageInfo) {
            if (imageInfo?.type !== "image/png" && imageInfo?.type !== "image/jpeg") {
                errors.image = 'forms.photoTypeValidation';
            } else if (imageInfo.size > 1000000) {
                errors.image = 'forms.photoSizeValidation';
            }
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: initialValues,
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues, resetForm}) => {
            //console.log(values);
            if (!state.isUpdating) {
                handleSubmit(values, setValues).then(() => {
                    resetForm();
                    setSubmitting(false);
                    setState({...state, isUpdating: false, isCreating: false});
                })
            } else {
                handleEdit(values, setValues).then(() => {
                    resetForm();
                    setSubmitting(false);
                    setState({...state, isUpdating: false, isCreating: false});
                })
            }
        },
    });

    const handleDelete = (category) => {
        confirmAlert({
            title: t("category_page.msg_delete_confirm1"),
            message: t("category_page.msg_delete_confirm2") + category.name + "?",
            buttons: [
                {
                    label: t("accept"),
                    onClick: () => {
                        try {
                            // @ts-ignore
                            categoryService.delete(category.idCategory).then(({data: response}) => {
                                if (response.internalError != null && response.internalError && response.message != null) {
                                    toast.error(t(response.message));
                                } else {
                                    //setInfoMessage(t("category_page.msg_success_delete"));
                                    toast.success(t("category_page.msg_success_delete"));
                                    fillCategories();
                                }
                            });
                        } catch (error) {
                            //console.log("Error #Users handleDelete" + JSON.stringify(error));
                            toast.error(t(error.message));
                            //setErrorMessage("Ocurrió un error al eliminar usuario.")
                            toast.error("Ocurrió un error al eliminar usuario.");
                        }
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const handleCancel = () => {
        formik.setValues({...initialValues, isLoading: false, isCreating: false, isEditing: false});
        setCategories(categories.map((category_) => {
            return {
                ...category_,
                isEditing: false
            }
        }));
        formik.resetForm();
        setState({...state, isCreating: false});
        setImageInfo(null);
        //console.log("handlcancl");
        setShowFormAdd(false);
    };
    
    function onChangeImage() {
        setImageInfo({
            name: imageFile.current?.files[0].name,
            size: imageFile.current?.files[0].size,
            type: imageFile.current?.files[0].type
        });
    }

    const handleChangeCategoriesRowsPerPage = (event) => {
        setRowsPerPageCategories(parseInt(event.target.value, 10));
        setCategoriesPage(0);
    }
    
    const handleChangeCategoriesPage = (event, newPage) => {
        setCategoriesPage(newPage);
    }

    return (
        <Card sx={{padding:"8px", background:"#EFEFEF", overflow:"unset"}}>
            <Grid container spacing={1} rowSpacing={3} style={{marginBottom:"0.4em"}}>
                <Grid item xs={12} >
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' spacing={1} >
                        {/*<FormControl fullWidth>*/}
                            <TrueffortSearchInput
                                id="idSearchInput"
                                //label={t("category_page.search")}
                                placeholder={t("category_page.search")}
                                variant="outlined"
                                size="small"
                                fullWidth={matchesXS}
                                onChange={(e) => handleChangeSearchInput(e.target.value)}
                            />
                        {/*</FormControl>*/}

                        <TrueffortButton
                            id="idBtnAddCategory"
                            variant="contained"
                            color="secondary"
                            fullWidth={matchesXS}
                            startIcon={<Add />}
                            onClick={() => {
                                setState({...state, isUpdating: false, isCreating: true});
                                setShowFormAdd(true);
                                setApplicationsValues([]);
                            }}
                            disabled={state.isUpdating}
                            maxHeight="25px"
                        >{t("add_new")}</TrueffortButton>
                    </Stack>
                </Grid>

            {showFormAdd &&
            <Paper style={{ marginBottom: "0.4em" }}>
                <CardContent>{t("category_page.create_title")}
                    <Grid container spacing={1}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <TrueffortInputLabel shrink labelstring={t("Name")} requiredlabel={true} />
                            <TrueffortTextField
                                id="field-name-category-new"
                                name="name"
                                size="small"
                                fullWidth
                                required
                                disabled={formik.isSubmitting}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                helperText={formik.errors?.name && formik.touched?.name ? t(formik.errors?.name): null}
                            />
                        </Grid>
                        <Grid item lg={8} md={6} sm={12} xs={12}>
                            <TrueffortInputLabel shrink labelstring={t("Applications")}/>
                            <Select
                                isMulti
                                value={applicationsValues}
                                options={
                                        applicationWithoutCategory.applications.map((app, k) => {
                                        return ({
                                            value: app.application.idApplication,
                                            label: app.application.name
                                        })
                                    })
                                }
                                onChange={(option) => {
                                    setApplicationsValues(option);
                                }}
                                styles={styleReactSelect}
                            />
                        </Grid>
                        <Grid item lg={4} md={6}>
                            <TrueffortInputLabel shrink labelstring={t("applications.image")} />
                            <div className="custom-file" style={{ zIndex: 0 }} >
                                <input
                                    type="file"
                                    ref={imageFile}
                                    className="custom-file-input"
                                    lang={localStorage.getItem(USER_LANGUAGE_KEY)}
                                    name="image"
                                    disabled={formik.isSubmitting}
                                    readOnly={formik.isSubmitting}
                                    onChange={onChangeImage}
                                    onBlur={formik.handleBlur}
                                    accept="image/png, image/jpeg"
                                />
                                <label
                                    className="custom-file-label"
                                    style={{
                                        whiteSpace: "nowrap",
                                        display: "block",
                                        overflow: "hidden",
                                        background: 'rgb(245, 245, 245)'
                                    }}
                                >
                                    {imageInfo?.name || t("forms.choose_file")}
                                </label>
                            </div>
                            {formik.errors?.image && imageInfo && !formik.isSubmitting ?
                                <div className="text-danger mt-2 text-validation">{t(formik.errors.image)} </div> : null}
                        </Grid>
                        <Grid item lg={8} md={6} sm={12} xs={12}>
                            <div style={{"float": "right", display:"flex", alignItems:"flex-end"}}>
                                <TrueffortButton 
                                    id="new-category-button"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={formik.handleSubmit}><Save size="small" /></TrueffortButton>
                                <div style={{paddingRight:'0.2em'}}></div>
                                <TrueffortButton
                                    id="cancel-new-category-button" 
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    disabled={formik.isSubmitting} 
                                    onClick={handleCancel}
                                ><Clear size="small" /></TrueffortButton>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>}

            <TrueffortCardTable>
                <Grid container spacing={1} rowSpacing={3} style={{ margin: "0.4em 0.2em", width: '100%' }}>
                    <TableContainer component={Paper}
                        onSubmit={formik.handleSubmit}
                        /*sx={{overflow:"unset"}}*/
                    >
                        <Table responsive={"true"}>
                            <TableHead>
                                <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                                    <TrueffortTableHeadCell></TrueffortTableHeadCell>
                                    <TrueffortTableHeadCell>{t("Name")}</TrueffortTableHeadCell>
                                    <TrueffortTableHeadCell>{t("Applications")}</TrueffortTableHeadCell>
                                    <TrueffortTableHeadCell>{t("actions")}</TrueffortTableHeadCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {categoriesFiltered.slice(categoriesPage * rowsPerPageCategories,
                                    categoriesPage * rowsPerPageCategories + rowsPerPageCategories).map((cat, k) => (
                                !cat.isEditing ?
                                    <StyledTableRow key={k}>
                                        <StyledTableCell>
                                          <CustomAvatar 
                                            url={cat.image?.url}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {cat.name}</StyledTableCell>
                                        <StyledTableCell style={{width: '650px'}}>
                                            {cat.applications.map((app, j) => j < 10 ?
                                                <TrueffortChip label={app.application.name.length > 50 ? app.application.name.substring(0,50) + '...' : app.application.name}
                                                    size="small"
                                                    avatar={
                                                      <CustomAvatar 
                                                        size={20}
                                                        url={app.application?.image?.url}
                                                        code={app.application.name}
                                                        sizeCode={12}
                                                      />}
                                                /> : null
                                            )}
                                            {cat.applications.length > 10 ? 
                                                <TrueffortChip label={"+"+(cat.applications.length - 10)} 
                                                size="small" />:null}
                                        </StyledTableCell>
                                        <StyledTableCell className="tbl-td-center">
                                            {cat.name.toUpperCase() != WHITOUT_CATEGORY_NAME.toUpperCase() ? 
                                            <div style={{ display: "flex" }}>
                                                <Chip size="small"
                                                    icon={<Edit fontSize="small" />}
                                                    variant="outlined"
                                                    color='primary'
                                                    disabled={cat.isEditing}
                                                    onClick={() => prepareEdit(cat)}
                                                    label={t("elements.buttons.edit")}
                                                />
                                                <div style={{ marginLeft: "0.5em" }} />
                                                <Chip size="small"
                                                    icon={<Delete fontSize="small" />}
                                                    onClick={() => handleDelete(cat)}
                                                    label={t("elements.buttons.delete")}
                                                    variant="outlined"
                                                    color="error"
                                                    disabled={cat.isEditing}
                                                >
                                                    <DeleteOutline fontSize="small" />
                                                </Chip>
                                            </div> : null}
                                        </StyledTableCell>
                                    </StyledTableRow> :
                                    <StyledTableRow key={k}>
                                        <StyledTableCell colSpan={4}>
                                            <Grid container spacing={1}>
                                                <Grid item lg={4} md={6}>
                                                    <TrueffortInputLabel shrink labelstring={t("Name")} requiredlabel={true} />
                                                    <TrueffortTextField
                                                        id={"field-name-category-name"}
                                                        name="name"
                                                        size="small"
                                                        required={true}
                                                        value={formik.values.name}
                                                        placeholder={t("Name")}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                                        disabled={formik.isSubmitting}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item lg={8} md={6}>
                                                    <TrueffortInputLabel shrink labelstring={t("Applications")}/>
                                                    <Select 
                                                        id={"select-applications-new"}
                                                        isMulti
                                                        value={applicationsValues}
                                                        options={applicationsBySelect}
                                                        onChange={(option) => setApplicationsValues(option)}
                                                        styles={styleReactSelect}
                                                    />
                                                </Grid>
                                                <Grid item lg={4} md={6}>
                                                    <TrueffortInputLabel shrink labelstring={t("applications.image")} />
                                                    <div className="custom-file" style={{ zIndex: 0 }}>
                                                        <input
                                                            type="file"
                                                            ref={imageFile}
                                                            className="custom-file-input"
                                                            lang={localStorage.getItem(USER_LANGUAGE_KEY)}
                                                            name="image"
                                                            disabled={formik.isSubmitting}
                                                            readOnly={formik.isSubmitting}
                                                            onChange={onChangeImage}
                                                            onBlur={formik.handleBlur}
                                                            accept="image/png, image/jpeg"
                                                        />    
                                                        <label
                                                            className="custom-file-label"
                                                            style={{
                                                                whiteSpace: "nowrap",
                                                                display: "block",
                                                                overflow: "hidden",
                                                                background: 'rgb(245, 245, 245)'
                                                            }}
                                                        >
                                                            {imageInfo?.name || t("forms.choose_file")}
                                                        </label>
                                                    </div>
                                                    {formik.errors?.image && imageInfo && !formik.isSubmitting ?
                                                        <div className="text-danger mt-2 text-validation">{t(formik.errors.image)} </div> : null}
                                                </Grid>
                                                <Grid item lg={8} md={6} style={{display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center'}}>
                                                    <Chip size="small"
                                                        icon={<Save fontSize="small" />}
                                                        variant="outlined"
                                                        color='success'
                                                        disabled={state.isEditing}
                                                        onClick={formik.handleSubmit}
                                                        label={t("elements.buttons.save")}
                                                    />
                                                    <div style={{ marginLeft: "0.5em" }} />
                                                    <Chip size="small"
                                                        icon={<Clear fontSize="small" />}
                                                        onClick={() => {
                                                            cat["isEditing"] = false;
                                                            formik.resetForm();
                                                            setState({...state, isUpdating: false});
                                                        }}
                                                        label={t("elements.buttons.cancel")}
                                                        variant="outlined"
                                                        color="error"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </StyledTableCell>
                                    </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                        <Box sx={{ display: 'flex', justifyContent: "flex-end", 
                            alignItems: "center", marginTop: '1em' }}>
                            <TablePagination 
                                labelRowsPerPage={t("elements.pagination.label")}
                                rowsPerPageOptions={[5,10,15]}
                                component={"div"}
                                onRowsPerPageChange={handleChangeCategoriesRowsPerPage}
                                onPageChange={handleChangeCategoriesPage}
                                count={categoriesCount}
                                rowsPerPage={rowsPerPageCategories}
                                page={categoriesPage}
                            />
                        </Box>
                    </div>
                </Grid>
            </TrueffortCardTable>
            </Grid>
        </Card>
    );
};

export default CategoriesTabTRUE;
