import { TIME_FORMAT } from "./Constants";

/**
 * Convierte la fecha y hora a la fecha y hora local
 * */
export const dateToDateLocale = (date:any) => {
    let d = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    return d;
}

/**
 * Transforma segundos al formato 00 h 00 m 00 s
 * @param seconds:number
 */
export const secondsToHMSWithoutParents = (seconds:number) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds - (h * 3600)) / 60);
    let s = (seconds - (h * 3600) - (m * 60)).toFixed();
    return h + "h " + (m < 10 ? "0" + m : m) + " m " + s + " s";
}

/**
 * Transforma segundos al formato 00 h 00 m 00 s
 * @param seconds:number
 */
export const secondsToHMSWithParents = (seconds:number) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds - (h * 3600)) / 60);
    let s = (seconds - (h * 3600) - (m * 60));
    let sF = s.toFixed();
    return "(" + h + "h " + (m < 10 ? "0" + m : m) + " m " + (s < 10 ? "0" + sF : sF) + " s)";
}

/**
 * Transforma segundos al formato seleccionado
 * @param seconds:number
 * @param withParents:boolean
 * @param complete:boolean
 * @param withUnits
 */
export const formatSeconds = (seconds:number, withParents:boolean, complete:boolean, withUnits:boolean) => {
    let format = localStorage.getItem(TIME_FORMAT);
    let result = "";
    let h;
    if (isNaN(seconds) || !isFinite(seconds)) return "--";

    switch (format) {
        case 'hrs':
            h = Math.floor(seconds / 3600);
            let m = Math.floor((seconds - (h * 3600)) / 60);
            let s = (seconds - (h * 3600) - (m * 60));
            let sF = s.toFixed();
            if (withUnits) {
                result = h + "h " + (m < 10 ? "0" + m : m) + "m" + (complete ? (s < 10 ? " 0" + sF : " " + sF) + "s" : "");
            } else {
                result = h + ":" + (m < 10 ? "0" + m : m) + (complete ? (s < 10 ? ":0" + sF : ":" + sF) : "");
            }
            break;
        case 'dec':
            h = seconds / 3600;
            result = h.toFixed(2) + (withUnits ? " h" : "");
            break;
    }

    return withParents ? "(" + result + ")" : result;
}

/**
 * Transforma segundos al formato 00:00:00
 * @param seconds:number
 */
export const secondsToHHMMSSWithoutParents = (seconds:number) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds - (h * 3600)) / 60);
    let s = (seconds - (h * 3600) - (m * 60));
    let sF = s.toFixed();
    return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + sF : sF);
}

/**
 * Transforma segundos al formato 00:00:00
 * @param seconds:number
 */
export const secondsToHHMMSSWithParents = (seconds:number) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds - (h * 3600)) / 60);
    let s = (seconds - (h * 3600) - (m * 60));
    let sF = s.toFixed();
    return "(" + h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + sF : sF) + ")";
}

/**
 * Transforma segundos al formato HH:MM
 * @param seconds:number
 */
export const secondsToHHMM = (seconds:number) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds - (h * 3600)) / 60);
    return h + ":" + (m < 10 ? "0" + m : m);
}

export const cutValueFormated = (value:string, index:number) => {
    return value.length > index ? value.substring(0,index-1) + "..." : value;
}

export const getNumberBusinessDaysOnward = (start:any, end:any) => {
  let sD_ = new Date(start);
  let eD_ = new Date(end);
  sD_.setHours(sD_.getHours() - 6, 0, 0,0);
  eD_.setHours(eD_.getHours() - 6, 0, 0, 0);
  if (sD_.getTime() === eD_.getTime()) {
      return 1;
  } else if (sD_.getTime() > eD_.getTime()) {
      return 0;
  } else {
    let c = 0;
    while (sD_.getTime() <= eD_.getTime()) {
      let day = new Date(sD_).getDay();
      if (day !== 0 && day !== 6) { c++; }
      sD_.setDate(sD_.getDate() + 1);
    }
    return c === 0 ? 1 : c;
  }
}

export const getNumberBusinessDaysBackward = (start:any, end:any) => {
  let sD_ = new Date(start);
  let eD_ = new Date(end);
  sD_.setHours(sD_.getHours() - 6, 0, 0, 0);
  eD_.setHours(eD_.getHours() - 6, 0, 0, 0);
  if (sD_.getTime() === eD_.getTime()) {
      return 1;
  } else if (sD_.getTime() < eD_.getTime()) {
      return 0;
  } else {
    let c = 0;
    while (sD_.getTime() >= eD_.getTime()) {
      let day = new Date(sD_).getDay();
      if (day !== 0 && day !== 6) { c++; }
      sD_.setDate(sD_.getDate() - 1);
    }
    return c === 0 ? 1 : c;
  }
}

// Determinar los estados permitidos a partir del estado actual
export const statusAllowedSinceStatus = ({status_id, status_name}:{status_id:number, status_name:string}): any[] => {
  let status:any = [];
  switch (status_id) {
    case 1:
      // CREADA
      status = [
        {idStatus: status_id, name: status_name},
        {idStatus: 2, name: "En Proceso"},
        {idStatus: 3, name: "Cancelada"}
      ];
      break;
    case 2:
      // EN PROCESO
      status = [
        {idStatus: status_id, name: status_name},
        {idStatus: 4, name: "Terminada"},
        {idStatus: 3, name: "Cancelada"}
      ];
      break;
    case 3:
      // CANCELADA
      status = [
        {idStatus: status_id, name: status_name},
        {idStatus: 2, name: "En Proceso"}
      ];
      break;
    case 4:
      // TERMINADA
      status = [
        {idStatus: status_id, name: status_name},
        {idStatus: 2, name: "En Proceso"}
      ];
      break;
  }

  return status;
}