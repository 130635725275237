import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class TaskTypeService {

    create(data : any) {
        return http?.post(baseURL.activitiesapi.taskType + "/", data);
    }

    update(id : number, data : any) {
        return http?.put(baseURL.activitiesapi.taskType + `/${id}`, data);
    }

    delete(id : number) {
        return http?.delete(baseURL.activitiesapi.taskType + `/${id}`);
    }

    activate(id: number) {
        return http?.put(baseURL.activitiesapi.taskType + "/active/" + id);
    }

    getNotSavedTasks(idProjectElement: number) {
        return http?.get(baseURL.readApi.taskType + "/findAllNotSaved/" + idProjectElement);
    }
}

export default TaskTypeService;