import {
    Box,
    Button,
    ButtonProps,
    Chip,
    ChipProps,
    createTheme,
    Paper,
    Stack,
    ThemeProvider,
    TextField,
    TextFieldProps,
    Typography,
    TypographyProps,
    Table, TableProps,
    TableHead, TableHeadProps,
    InputLabelProps,
    InputLabel,
    SelectProps,
    Select,
    MenuItem,
    IconButtonProps,
    IconButton,
    FabProps,
    Fab,
    InputAdornment,
    LinearProgress, LinearProgressProps,
    TableCell, TableCellProps, Card, CardProps, styled, TableRow, Tooltip, TooltipProps, tooltipClasses
} from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//import '../css/Global.scss'
import {Search as SearchIcon} from '@mui/icons-material';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from '@mui/icons-material/Close';


// Aumentar la paleta para agregar más colores
declare module '@mui/material/styles' {
    interface Palette {
        indigo: Palette['primary'];
        purple: Palette['primary'];
        darkT: Palette['primary'];
        darkG?: PaletteOptions['primary'];
        grayMedium: Palette['primary'];
        gray: Palette['primary'];
        pink: Palette['primary'];
        teal: Palette['primary'];
        green: Palette['primary'];
        blanco: Palette['primary'];
        lightY: Palette['primary'];
        lime: Palette['primary'];
        cinnabar: Palette['primary'];
        redExtra: Palette['primary'];
    }

    interface PaletteOptions {
        indigo?: PaletteOptions['primary'];
        purple?: PaletteOptions['primary'];
        darkT?: PaletteOptions['primary'];
        darkG?: PaletteOptions['primary'];
        grayMedium?: PaletteOptions['primary'];
        gray?: PaletteOptions['primary'];
        pink?: PaletteOptions['primary'];
        teal?: PaletteOptions['primary'];
        green?: PaletteOptions['primary'];
        blanco?: PaletteOptions['primary'];
        lightY?: PaletteOptions['primary'];
        lime?: PaletteOptions['primary'];
        cinnabar?: PaletteOptions['primary'];
        redExtra?: PaletteOptions['primary'];
    }
}

// Actualiza las opciones de color del componente Button para incluír los colores agregados
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        indigo: true;
        purple: true;
        darkT: true;
        darkG: true;
        gray: true;
        lime: true;
        cinnabar: true;
    }
}

// Actualiza las opciones de color del componente Chip para incluír los colores agregados
declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        indigo: true;
        purple: true;
        darkT: true;
        blanco: true;
        lightY: true;
        lime: true;
        green: true;
        gray: true;
        teal: true;
        cinnabar: true;
        redExtra: true;
    }
}

declare module '@mui/material/LinearProgress' {
    interface LinearProgressPropsColorOverrides {
        indigo: true;
        purple: true;
        darkT: true;
        pink: true;
        teal: true;
        green: true;
    }
}

/*declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        indigo: true;
        purple: true;
        darkT: true;
        blanco: true;
        lightY: true;
        lime: true;
        green: true;
        gray: true;
        teal: true;
        cinnabar: true;
        redExtra: true;
    }
}*/
///////////////////////////////////// Tema Global
export let globalTheme = createTheme({
    palette: {
        primary: {
            main: '#07074E',
            dark: '#19149E'
        },
        secondary: {
            main: '#6693CB',
            dark: '#527BBD'
        },
        cinnabar: {
            main: '#6693CB',
            dark: '#527BBD'
        }
    },
    typography: {
        fontFamily: 'Inter',
        h1: {
            fontFamily: 'ofelia-display',
            fontWeight: 500,
        },
        h2: {
            fontWeight: 500,
            fontFamily: 'ofelia-display',
        },
        h3: {
            fontWeight: 500,
            fontFamily: 'ofelia-display',
        },
        h4: {
            fontFamily: 'ofelia-display',
            fontWeight: 500,
        },
        h5: {
            fontWeight: 500,
            fontFamily: 'ofelia-display',
        },
        h6: {
            fontFamily: 'ofelia-display',
            fontWeight: 500,
        },
        body1: {
            fontFamily: 'Inter',
        },
        body2: {
            fontFamily: 'Inter',
        },
        button: {
            fontFamily: 'Inter',
            fontWeight: 500,
        },
        caption: {
            fontFamily: 'Inter',
            fontWeight: 500,
        },
        overline: {
            fontFamily: 'Inter',
        },
        subtitle1: {
            fontFamily: 'Inter',
        },
        subtitle2: {
            fontFamily: 'Inter',
        },
    },
});

globalTheme = createTheme(globalTheme, {
    palette: {
        indigo: globalTheme.palette.augmentColor({
            color: {
                main: '#6875C7',
            },
            name: 'indigo',
        }),
        purple: globalTheme.palette.augmentColor({
            color: {
                main: '#9A64E2',
            },
            name: 'purple',
        }),
        darkT: globalTheme.palette.augmentColor({
            color: {
                main: '#27535E',
            },
            name: 'darkT',
        }),
        darkG: globalTheme.palette.augmentColor({
            color: {
                main: '#474A4E',
            },
            name: 'darkG',
        }),
        grayMedium: globalTheme.palette.augmentColor({
            color: {
                main:"#6A6A6A"
            },
            name: "grayMedium"
        }),
        gray: globalTheme.palette.augmentColor({
            color: {
                main:"#474A4E"
            },
            name: "gray"
        }),
        pink: globalTheme.palette.augmentColor({
            color: {
                main:"#E3759A"
            },
            name: "pink"
        }),
        teal: globalTheme.palette.augmentColor({
            color: {
                main:"#68C8BA"
            },
            name: "teal"
        }),
        green: globalTheme.palette.augmentColor({
            color: {
                main:"#78B737"
            },
            name: "green"
        }),
        blanco: globalTheme.palette.augmentColor({
            color: {
                main: '#FFFFFF',
            },
            name:'blanco',
        }),
        lightY: globalTheme.palette.augmentColor({
            color: {
                main: '#D7EBF0',
            },
            name: 'lightY',
        }),
        lime: globalTheme.palette.augmentColor({
            color: {
                main: '#92CF52',
            },
            name: 'lime',
        }),
        cinnabar: globalTheme.palette.augmentColor({
            color: {
                main: '#EF3E2D',
                contrastText: '#FFFFFF'
            },
            name: 'cinnabar',
        }),
        redExtra: globalTheme.palette.augmentColor({
            color: {
                main: '#E4555A',
                contrastText: '#FFFFFF'
            },
            name: 'redExtra',
        }),
    },
});

export function TrueffortButtonSmall(props: ButtonProps) {
    return(
        //<ThemeProvider theme={buttonTheme} >
            <Button {...props} sx={{ borderRadius: '100px', textTransform: 'none', fontSize:"0.7em", padding:"8px 22px !important",
                maxHeight:'25px' }} />
        //</ThemeProvider>
    );
}

export function TrueffortButton(props: ButtonProps & {maxHeight?:string}) {
    return(
        //<ThemeProvider theme={buttonTheme} >
            <Button {...props} sx={{ borderRadius: '100px', textTransform: 'none', fontSize:"0.7rem", padding:"8px 22px !important",
                maxHeight:props.maxHeight ? props.maxHeight:'none' }} />
        //</ThemeProvider>
    );
}

export function TrueffortFABButton(props: FabProps) {
    return (
        //<ThemeProvider theme={buttonTheme} >
            <Fab {...props} />
        //</ThemeProvider>
    );
}

export function TrueffortNavButton(props: ButtonProps & {noneHeight?:boolean}) {
    return(
        //<ThemeProvider theme={buttonTheme} >
            <Button {...props}
                sx={{borderRadius: '100px', textTransform: 'none', boxShadow: "none",
                    background: "#EFEFEF", color: "#6A6A6A", border: "solid 1px #EFEFEF",
                    height: props.noneHeight ? 'none' : '2em', maxHeight:'12em', fontSize:'0.8em',
                    /*maxWidth: '18em',*/
                '&:hover': {
                    color: "#6875C7",
                    background: "#EFEFEF",
                    border: "solid 1px #6875C7"
                },
                '&.active': {
                    color: "white",
                    backgroundColor: "#6875C7",
                    border: "solid 1px #1D3863"
                }
            }} />
        //</ThemeProvider>
    );
}

export function TrueffortChip(props: ChipProps) {
    return(
        /*<ThemeProvider theme={buttonTheme} >*/
            <Chip sx={{fontSize: "0.75rem", padding:'0.2em', marginBottom: '0.4em', marginRight: '0.4em', position: 'relative'}} {...props} />
        /*</ThemeProvider>*/
    );
}

export function TrueffortConfirmAlert({ title, subtitle, alertColor, message, messages, buttons }: any) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ThemeProvider theme={globalTheme} >
            <Paper elevation={1} sx={{ padding: '20px', width: !matches ? '450px' : '340px' }}>
                {/*<Typography color={alertColor} fontSize='18px' fontWeight={700} marginBottom='20px' >
                    {titleIcon}&nbsp;&nbsp;{title}
                </Typography>*/}

                <Box sx={{ marginBottom: '20px' }} >
                    {title}
                </Box>

                <Box sx={{ marginBottom: '20px' }} >
                    {subtitle}
                </Box>

                {message &&
                    <Typography marginBottom='20px' >
                        {message}
                    </Typography>
                }

                {messages &&
                    messages.map((message: string, k: number) =>
                        <Typography key={message + "-" + k} marginBottom='20px' >
                            {message}
                        </Typography>
                    )
                }

                <Stack direction='row' justifyContent='space-evenly'>
                    {buttons.map((button: any, k: number) =>
                        <TrueffortButton
                            key={k + "-" + button.label}
                            size='small'
                            variant={k === 0 ? "outlined" : "contained"}
                            color={k > 0 ? alertColor : undefined}
                            onClick={button.onClick}
                        >
                            {button.label}
                        </TrueffortButton>
                    )}
                </Stack>
            </Paper>
        </ThemeProvider>
    );
}

export function TrueffortSearchInput(props: TextFieldProps & {size?:string}) {
    return (
        <TextField {...props}
            InputProps={{
                sx: { borderRadius: "100px", maxHeight: "36px", background: "#efefef", height:props.size ? "2.6em" : "", fontSize:props.size?"0.8em":"" },
                endAdornment: <InputAdornment position="end">
                    <SearchIcon />
                </InputAdornment>,
                }}
        />
    );
}

export function TrueffortTable(props: TableProps) {
    return (
        //<ThemeProvider theme={buttonTheme}>
            <Table {...props} sx={{border: "solid thin lightgray", backgroundColor: "white"}}/>
        //</ThemeProvider>
    );
}

export function TrueffortTableCell(props: TableCellProps) {
    return(
        //<ThemeProvider theme={buttonTheme}>
            <TableCell {...props} sx={{paddingLeft: 0, paddingBottom: ".3rem", paddingTop: ".3rem"}} />
        //</ThemeProvider>
    );
}

export function TrueffortTableHeadCell(props: TableCellProps & { background?: string}) {
    return(
        //<ThemeProvider theme={buttonTheme}>
            <TableCell {...props} sx={{paddingBottom: ".3rem", paddingTop: ".3rem", 
                fontSize: "0.75rem", color: "#4A4A4A", background: props.background ? props.background : ''}} />
        //</ThemeProvider>
    );
}

export function TrueffortTableHead(props: TableHeadProps) {
    return (
        //<ThemeProvider theme={buttonTheme}>
            <TableHead {...props} sx={{border: "solid thin lightgray"}} className="background-gray"/>
        //</ThemeProvider>
    );
}

export function TrueffortLinearProgress(props: LinearProgressProps) {
    return (
        //<ThemeProvider theme={buttonTheme}>
            <LinearProgress {...props} />
        //</ThemeProvider>
    );
}

export function TrueffortInputLabel(props: TextFieldProps & { requiredlabel?: boolean, labelstring?: string }) {
    return (
        <>
            {props.labelstring !== undefined &&
                <InputLabel
                    required={props.requiredlabel}
                    sx={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 700,
                        color: '#4A4A4A',
                        '& .MuiInputLabel-asterisk': {
                            color: "#E4555A"
                        },
                        fontSize: '0.75rem'
                    }}
                >
                    {props.labelstring}
                </InputLabel>
            }
        </>
    );
}

export function TrueffortTextField(props: TextFieldProps & { requiredlabel?: boolean, labelstring?: string }) {
    return (
        <>
            {props.labelstring !== undefined &&
                <InputLabel
                    required={props.requiredlabel}
                    sx={{
                        fontWeight: 700,
                        color: '#4A4A4A',
                        '& .MuiInputLabel-asterisk': {
                            color: "#E4555A"
                        },
                        fontSize: '0.75rem'
                    }}
                >
                    {props.labelstring}
                </InputLabel>
            }
            <TextField
                inputProps={{ maxLength: 200, style: { background:'#F5F5F5' } }}
                {...props}
            />
        </>
    );
}

export function TrueffortSelect(props: SelectProps & { requiredlabel?: boolean, labelstring?: string }) {
    return (
        <>
            {props.labelstring !== undefined &&
                <InputLabel
                    required={props.requiredlabel}
                    sx={{
                        fontWeight: 700,
                        color: '#4A4A4A',
                        '& .MuiInputLabel-asterisk': {
                            color: "#E4555A"
                        },
                        fontSize: '0.75rem'
                    }}
                >
                    {props.labelstring}
                </InputLabel>
            }
            <Select
                sx={{ backgroundColor: '#F5F5F5' }}
                IconComponent={KeyboardArrowDownIcon}
                {...props}
            >
                {props.children}
            </Select>
        </>
    );
}

export function TrueffortCardTable(props: CardProps) {
    return(
        <Card elevation={2} style={{ display: 'flex', width: '100%', padding: '11px 6px', background: 'white' }} {...props} />
    );
}

export const StyledTableCell:any = styled(TableCell)(({ theme } : any) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#DCDCDC',
        color: '#4A4A4A',
        fontSize: '12px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '14px',
        color: '#553D74',
        padding: '0.4em'
    },
}));

export const StyledTableRow:any = styled(TableRow)(({ theme } : any) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const HelperTooltip: any = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#D7EBF0',
        fontSize: 'large'
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#D7EBF0',
        maxWidth: '400px'
    },
}));

export function HelperDialog(
    props: {
        title: string | undefined,
        body: string | undefined,
        dialogIndex: number,
        totalDialogs: number,
        children: any,
        handleNext: () => void,
        isOpen: boolean,
        handleClose: () => void,
        position?: string
    }
) {
    return(
        <HelperTooltip
            open={props.isOpen}
            arrow
            placement={props.position}
            title={
                <React.Fragment >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <IconButton
                            size='small'
                            onClick={props.handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>

                    <Box sx={{ marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem', marginTop: '-1rem' }} >
                        <Typography variant='h6' color='#27535E' fontWeight={700} >
                            {props.title}
                        </Typography>

                        <Typography variant='body2' color='#27535E' >
                            {props.body}
                        </Typography>

                        <Stack
                            direction='row'
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography color='#68C8BA' >
                                {props.dialogIndex + 1} de {props.totalDialogs}
                            </Typography>

                            <TrueffortButton
                                size="small"
                                color="primary"
                                variant='contained'
                                onClick={
                                    props.dialogIndex + 1 < props.totalDialogs ?
                                        props.handleNext :
                                        props.handleClose
                                }
                            >
                                {props.dialogIndex + 1 < props.totalDialogs ? 'Siguiente' : 'Cerrar'}
                            </TrueffortButton>
                        </Stack>
                    </Box>
                </React.Fragment>
            }
        >
            {props.children}
        </HelperTooltip>
    );
}