import * as React from "react";
import {Container, Card, Avatar, InputLabel, Alert} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {TrueffortButton, TrueffortTextField, TrueffortSelect} from "../../MUIstyle/MUICustom";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {useFormik} from "formik";
import UserService from "../../services/user.service";
import {DEFAULT_REQUEST_ERROR, MIN_LEVEL_LEADER, MIN_LEVEL_ADMIN} from "../../util/Constants";
import ApiRequest from "../../helpers/ApiRequest";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import UserCompanyRoleTypeService from "../../services/userCompanyRoleType.service";
import UserCompanyPositionTypeService from "../../services/userCompanyPositionType.service";
import CompanyBusinessSubtypeService from "../../services/companyBusinessSubtype.service";
import {Groups as GroupsIcon, PendingRounded as PendingRoundedIcon, Delete as DeleteIcon, Edit as EditIcon, Close as CloseIcon, Save as SaveIcon} from '@mui/icons-material';

function ProfileCardTRUE() {
    const {user, updateUserInfo} = useAuthContext();
    const userService = React.useMemo(() => new UserService(), []);
    const imageFile = React.useRef();
    const [imageInfo, setImageInfo] = React.useState();
    const [userCompanyRoleTypes, setUserCompanyRoleTypes] = React.useState([]);
    const userCompanyRoleTypeService = React.useMemo(() => new UserCompanyRoleTypeService(), []);
    const [userCompanyPositionTypes, setUserCompanyPositionTypes] = React.useState([]);
    const userCompanyPositionTypeService = React.useMemo(() => new UserCompanyPositionTypeService(), []);
    const [companyBusinessSubtypes, setCompanyBusinessSubtypes] = React.useState([]);
    const companyBusinessSubtypeService = React.useMemo(() => new CompanyBusinessSubtypeService(), []);
    const {t} = useTranslation();

    const [alertState, setAlertState] = React.useState({
        visible: false,
        text: "",
        error: false
    });

    React.useEffect(() => {
        fillRoleTypes();
        fillPositionTypes();
        fillCompanyBusinessSubtypes();
    }, []);

    const fillRoleTypes = () => {
        userCompanyRoleTypeService.getAll().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                setUserCompanyRoleTypes(response.data);
            }
        });
    }

    const fillPositionTypes = () => {
        userCompanyPositionTypeService.getAll().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                setUserCompanyPositionTypes(response.data);
            }
        });
    }

    const fillCompanyBusinessSubtypes = () => {
        companyBusinessSubtypeService.getAll().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                setCompanyBusinessSubtypes(response.data);
            }
        });
    }

    const validate = (values) => {
        let errors = {};
        if (values.company) {
            if (!values.name) {
                errors.name = 'Required';
            } else if (values.name.length > 35) {
                errors.name = 'forms.nameLengthValidation';
            }
            if (!values.lastName) {
                errors.lastName = t("Required");
            } else if (values.lastName.length > 40) {
                errors.lastName = t("forms.nameLengthValidation");
            }
    
            if (user.role.accessLevel >= MIN_LEVEL_LEADER && !values.phone) {
                errors.phone = t('Required');
            }
    
            if (values.company) {
                if (values.company.toString().length > 100) {
                    errors.company = 'forms.nameLengthValidation';
                }
            }
            if (values.idUserCompanyRoleType == "" || !values.idUserCompanyRoleType) {
                errors.idUserCompanyRoleType = t("Required");
            }
            if (values.idUserCompanyPositionType == "" || !values.idUserCompanyPositionType) {
                errors.idUserCompanyPositionType = t("Required");
            }
            if (values.idCompanyBusinessSubtype == "" || !values.idCompanyBusinessSubtype) {
                errors.idCompanyBusinessSubtype = t("Required");
            }
    
            if (imageInfo) {
                /*if (imageInfo?.type !== "image/png") {
                    errors.image = 'forms.photoTypeValidation';
                } else*/ if (imageInfo.size > 1000000) {
                    errors.image = 'forms.photoSizeValidation';
                }
            }
        }

        return errors;
    };

    const onSubmitChanges = React.useCallback(async (values) => {
        let errorMessage = t(DEFAULT_REQUEST_ERROR);

        const dataRequest = {
            name: values.name,
            lastName: values.lastName,
            phone: values.phone,
            company: values.company,
            idUserCompanyRoleType: values.idUserCompanyRoleType,
            idUserCompanyPositionType: values.idUserCompanyPositionType,
            idCompanyBusinessSubtype: values.idCompanyBusinessSubtype
        };

        var formData = new FormData();
        if (imageInfo) {
            formData.append("image", imageFile.current.files[0], imageFile.current.files[0].name);
        } else {
            formData.append("image", new Blob([JSON.stringify(null)], {
                type: "application/json"
            }));
        }
        formData.append('userDto', new Blob([JSON.stringify(dataRequest)], {type: "application/json"}));
        try {
            const response = await ApiRequest(userService.updateInfo(values.idUser, formData));
            updateUserInfo(response);
        } catch (err) {
            setAlertState({
                visible: true,
                text: t(errorMessage),
                error: true
            })
            toast.error(errorMessage);
        }
    }, [t, imageInfo, userService, updateUserInfo]);

    const formik = useFormik({
        initialValues: {
            idUser: user.idUser,
            name: user.name,
            lastName: user.lastName,
            phone: user.phone,
            company: user.company,
            isEditing: false,
            image: "",
            idUserCompanyRoleType: user.userCompanyRoleType?.idUserCompanyRoleType,
            idUserCompanyPositionType: user.userCompanyPositionType?.idUserCompanyPositionType,
            idCompanyBusinessSubtype: user.instance.companyBusinessSubtype?.idCompanyBusinessSubtype
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues, resetForm}) => {
            if (values.isEditing) {
                onSubmitChanges(values, setValues).then(() => {
                    setSubmitting(false);
                    resetForm();
                });
            } else {
                setValues({...values, isEditing: true});
                setSubmitting(false);
            }
        },
    });

    function handleCancel() {
        formik.resetForm();
    }

    function onChangeImage() {
        setImageInfo({
            name: imageFile.current?.files[0].name,
            size: imageFile.current?.files[0].size,
            type: imageFile.current?.files[0].type
        });
    }

    return (
        <Grid container maxWidth="md" sx={{margin: "0 auto"}}>
        <Grid xs={12}>
            <Card sx={{padding: "1.5rem 2.125rem"}}>
                {alertState.visible ?
                    <Alert isDismissible type={alertState.error ? "danger" : "primary"}>
                        <label>
                            {alertState.text}
                        </label>
                    </Alert> : null
                }
                <Grid container spacing={2}>
                    <Grid sx={4}>
                        <Avatar src={user.image?.url} sx={{ width: 70, height: 70, marginLeft: ".6rem" }} />
                    </Grid>
                    <Grid sx={8}>
                        <h3 className="color-primary">{user.name + " " + user.lastName}</h3>
                        {t("role."+user.role.code)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Grid container>
                        {
                            !formik.values.isEditing ?
                                <Grid xs={2} xsOffset="auto">
                                    <TrueffortButton
                                        color="secondary"
                                        onClick={() => formik.handleSubmit()}
                                    >
                                        <EditIcon />
                                    </TrueffortButton>
                                </Grid> :
                                <Grid xs={4} xsOffset="auto">
                                    <TrueffortButton
                                        color="primary"
                                        onClick={() => formik.handleSubmit()}
                                    >
                                        <SaveIcon />
                                    </TrueffortButton>
                                    <TrueffortButton
                                        color="secondary"
                                        onClick={() => handleCancel()}
                                    >
                                        <CloseIcon />
                                    </TrueffortButton>
                                </Grid>
                        }
                        </Grid>
                    </Grid>


                    <Grid xs={12} sm={6}>
                        <InputLabel className={formik.values.isEditing ? "color-primary" : ""}>{t("Name")}
                            <span className={"form-required"}>*</span>
                        </InputLabel>
                        <TrueffortTextField
                            disabled={!formik.values.isEditing || formik.isSubmitting}
                            name="name"
                            fullWidth
                            size="small"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <InputLabel className="">
                            {t("Lastname")}<span className={"form-required"}>*</span>
                        </InputLabel>
                        <TrueffortTextField
                            disabled={!formik.values.isEditing || formik.isSubmitting}
                            name="lastName"
                            fullWidth
                            size="small"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            error={formik.errors?.lastName && formik.touched?.lastName ? t(formik.errors.lastName) : null}
                        />
                    </Grid>

                    <Grid xs={12} sm={6}>
                        <InputLabel className="">{t("Phone")}
                            {user.role.accessLevel >= MIN_LEVEL_LEADER && <span
                            className={"form-required"}>*</span>}</InputLabel>
                        <TrueffortTextField
                            disabled={!formik.values.isEditing || formik.isSubmitting}
                            name="phone"
                            fullWidth
                            size="small"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            error={formik.errors?.phone && formik.touched?.phone ? t(formik.errors.phone) : null}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <InputLabel className="">{t("email")}</InputLabel>
                        <TrueffortTextField fullWidth size="small" disabled placeholder={user.email} value={user.email}/>
                    </Grid>

                    <Grid xs={12} sm={6}>
                        <InputLabel className={""}>{t("position")} <span
                            className={"form-required"}>*</span></InputLabel>
                        <TrueffortSelect
                            disabled={!formik.values.isEditing || formik.isSubmitting}
                            name="idUserCompanyPositionType"
                            fullWidth
                            size="small"
                            onChange={formik.handleChange}
                            value={formik.values.idUserCompanyPositionType}
                        >
                            <option value="">{t("Select")}</option>
                            {userCompanyPositionTypes.map((o, k) =>
                                <option key={o.idUserCompanyPositionType} value={o.idUserCompanyPositionType}>{o.name}</option>
                            )}
                        </TrueffortSelect>
                        {formik.errors?.idUserCompanyPositionType && formik.touched?.idUserCompanyPositionType && !formik.isSubmitting ?
                            <div className="text-danger">{t(formik.errors.idUserCompanyPositionType + '')} </div> : null}
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <InputLabel className={""}>
                            {t("profile")} <span className={"form-required"}>*</span>
                        </InputLabel>
                        <TrueffortSelect
                            disabled={!formik.values.isEditing || formik.isSubmitting}
                            name="idUserCompanyRoleType"
                            fullWidth
                            size="small"
                            onChange={formik.handleChange}
                            value={formik.values.idUserCompanyRoleType}
                        >
                            <option value="">{t("Select")}</option>
                            {userCompanyRoleTypes.map((o, k) =>
                                <option key={o.idUserCompanyRoleType} value={o.idUserCompanyRoleType}>{o.name}</option>
                            )}
                        </TrueffortSelect>
                        {formik.errors?.idUserCompanyRoleType && formik.touched?.idUserCompanyRoleType && !formik.isSubmitting ?
                            <div className="text-danger">{t(formik.errors.idUserCompanyRoleType + '')} </div> : null}
                    </Grid>

                    <Grid xs={12} sm={6}>
                        <InputLabel className="">{t("Company")}
                            {user.role.accessLevel >= MIN_LEVEL_ADMIN && <span
                            className={"form-required"}>*</span>}</InputLabel>
                        <TrueffortTextField
                            disabled={!formik.values.isEditing || formik.isSubmitting || user.role.accessLevel != MIN_LEVEL_ADMIN}
                            name="company"
                            fullWidth
                            size="small"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.company}
                            error={formik.errors?.company && formik.touched?.company ? t(formik.errors.company) : null}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <InputLabel className={""}>
                            {t("company_line_business")} <span className={"form-required"}>*</span>
                        </InputLabel>
                        <TrueffortSelect
                            disabled={!formik.values.isEditing || formik.isSubmitting || user.role.accessLevel != MIN_LEVEL_ADMIN}
                            name="idCompanyBusinessSubtype"
                            fullWidth
                            size="small"
                            onChange={formik.handleChange}
                            value={formik.values.idCompanyBusinessSubtype}
                        >
                            <option value="">{t("Select")}</option>
                            {companyBusinessSubtypes.map((o, k) =>
                                <option key={o.idCompanyBusinessSubtype} value={o.idCompanyBusinessSubtype}>{o.name}</option>
                            )}
                        </TrueffortSelect>
                        {formik.errors?.idCompanyBusinessSubtype && formik.touched?.idCompanyBusinessSubtype && !formik.isSubmitting ?
                            <div className="text-danger">{t(formik.errors.idCompanyBusinessSubtype + '')} </div> : null}
                    </Grid>

                    <Grid xs={12}>
                        <InputLabel className="">{t("Role")}</InputLabel>
                        <TrueffortTextField disabled fullWidth size="small" placeholder={user.role.role} value={user.role.role}/>
                    </Grid>


                    {formik.values.isEditing &&
                        <Grid xs={12}>
                            <InputLabel className="">{t("forms.change_image")}</InputLabel>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    ref={imageFile}
                                    className="custom-file-input"
                                    name="image"
                                    disabled={!formik.values.isEditing || formik.isSubmitting}
                                    readOnly={!formik.values.isEditing || formik.isSubmitting}
                                    onChange={onChangeImage}
                                    onBlur={formik.handleBlur}
                                    accept="image/png, image/jpeg"
                                />
                                <label
                                    className="custom-file-label"
                                    style={{
                                        whiteSpace: "nowrap",
                                        display: "block",
                                        overflow: "hidden",
                                    }}
                                >
                                    {imageInfo?.name || t("forms.choose_file")}
                                </label>
                            </div>
                            {formik.errors?.image && formik.touched?.image && !formik.isSubmitting ?
                                <div className="text-danger mt-2">{t(formik.errors.image)} </div> : null}
                        </Grid>
                    }

                </Grid>
            </Card>
        </Grid>
        </Grid>
    );
}

export default ProfileCardTRUE;
