import React from "react";
import SiteWrapper from "../SiteWrapper.react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import "../css/EstimationPage.css";
import {
  Container, Typography, Box, Collapse, IconButton
} from "@mui/material";
import {ProjectPlanProvider} from "../components/estimation/ProjectPlanContext";
import EstimationPageManager from "../components/estimation/EstimationPageManager";
import {TrueffortButton, TrueffortSearchInput, TrueffortLinearProgress, HelperDialog} from "../MUIstyle/MUICustom";
import CreateFromXlsForm from "../components/estimation/CreateFromXlsForm";
import HelpIcon from "@mui/icons-material/Help";
import {helperDialogs} from "../util/HelperDialogs";
import { User } from "../util/Interfaces";
import { useAuthContext } from "../contexts/AuthenticationContext";

export default function EstimationPage() {
  const {t} = useTranslation();
  const history = useHistory();
  const { user } = useAuthContext() as { user: User};
  const features = user?.instance?.license ? user?.instance?.license?.features : {};

  const [showXlsForm, setShowXlsForm] = React.useState(false);
  const [activeHelperDialogIndex, setActiveHelperDialogIndex] = React.useState(-1);

  const handleOpenHelp = () => {
    setActiveHelperDialogIndex(0);
  }

  const handleClose = () => {
    setActiveHelperDialogIndex(-1);
  };

  const handleActiveHelperChange = () => {
    if (activeHelperDialogIndex === helperDialogs['estimation'].length) {
      setActiveHelperDialogIndex(-1)
    } else {
      setActiveHelperDialogIndex((prev: number) => prev + 1)
    }
  }

  return (
    <SiteWrapper >
      <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
        <Typography variant='caption' >
            {t("home.new_project")}
        </Typography>

        <Box sx={{display: "flex"}}>
          <Box sx={{ flexGrow: 1 }}>
              <Typography variant='h4' >{t("Estimation")}&nbsp;
                <HelperDialog
                  title={t(helperDialogs["estimation"][0].title)}
                  body={t(helperDialogs["estimation"][0].body)}
                  dialogIndex={0}
                  totalDialogs={helperDialogs["estimation"].length}
                  handleNext={handleActiveHelperChange}
                  isOpen={activeHelperDialogIndex === 0}
                  handleClose={handleClose}
                >
                  <IconButton size='small' onClick={handleOpenHelp} >
                    <HelpIcon sx={{ color: '#6875C7' }} />
                  </IconButton>
                </HelperDialog>
              </Typography>
          </Box>
          <Box>
            {/*<TrueffortButton
                id="btnCreateFromJira"
                color="primary" variant="contained"
                onClick={() => history.push("/jira")}
                sx={{marginRight: "4px"}}
            >
                {t("Jira.btn_create_from_jira")}
            </TrueffortButton>
            */}
            {features.createProjectsExcel && !showXlsForm &&
              <TrueffortButton
                id="btnDownloadLayout"
                color="primary" variant="contained"
                onClick={() => setShowXlsForm(true)}
              >
                {t("estimation_page.create_from_xls")}
              </TrueffortButton>
            }
          </Box>
        </Box>

        <Typography variant='caption' >
          {t("estimation_page.page_description")}
        </Typography>
      </Container>

      <Collapse in={showXlsForm} orientation="vertical" sx={{marginTop:"1rem"}}>
        <CreateFromXlsForm _setShowXlsForm={setShowXlsForm} />
      </Collapse>

      {!showXlsForm &&
        <ProjectPlanProvider >
          <EstimationPageManager
            formHelperDialogsConfig={{
              activeHelperDialogIndex: activeHelperDialogIndex,
              handleActiveHelperChange: handleActiveHelperChange,
              handleClose: handleClose
            }}
          />
        </ProjectPlanProvider>
      }
    </SiteWrapper>
  );
}