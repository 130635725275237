import { Container, Grid, Paper, Table, TableBody, TableContainer, 
  TableHead, TablePagination, Typography,
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import SubscriptionService from "../../services/subscription.service";
import InstanceRow from "./InstanceRow";
import {useFormik} from "formik";
import InstanceService from "../../services/instance.service";
import {toast} from "react-toastify";
import {DEFAULT_REQUEST_ERROR} from "../../util/Constants";
import {confirmAlert} from "react-confirm-alert";
import { StyledTableRow, TrueffortConfirmAlert, TrueffortSearchInput, 
  TrueffortTableHeadCell } from "../../MUIstyle/MUICustom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function InstancesTable() {
    const {t} = useTranslation();

    const [isEditing, setIsEditing] = React.useState(false);
    const [licenses, setLicenses] = React.useState([]);
    const [filteredLicenses, setFilteredLicenses] = React.useState([]);
    const [searchBarValue, setSearchBarValue] = React.useState("");
    /*const [statusFilterValue, setStatusFilterValue] = React.useState(1);
    const [paymentFilterValue, setFilterPaymentValue] = React.useState(1);
    const [startDateFilterValue, setStartDateFilterValue] = React.useState(null);
    const [expirationDateFilterValue, setExpirationDateFilterValue] = React.useState(null);*/
    const [tablePage, setTablePage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    /*const [anchorStatusFilter, setAnchorStatusFilter] = React.useState(null);
    const openStatusFilterOptions = Boolean(anchorStatusFilter);*/

    const subscriptionsService = React.useMemo(() => new SubscriptionService(), []);
    const instancesServices = React.useMemo(() => new InstanceService(), []);

    React.useEffect(() => {
        fillLicenses();
    }, [])


    const fillLicenses = () => {
        // @ts-ignore
        subscriptionsService.getLicenses().then(({data : response}) => {
            if (Array.isArray(response.data)) {
                setLicenses(response.data);
                setFilteredLicenses(response.data);
            }
        });
    }

    const handleSubmit = (values: any) => {
        instancesServices.updateInstanceByPay(
            values.idInstance,
            { totalUsers: values.totalUsers, newExpirationDate: values.newExpirationDate.getTime() }
        ).then((response: any) => {
            response = response.data;
            if (response.success) {
                formik.setSubmitting(false);
                formik.resetForm();
                fillLicenses();
                setIsEditing(false);
                toast.success("Instancia actualizada");
            } else {
                toast.error(response.message);
            }
        }).catch((err: any) => {
            toast.error(t(DEFAULT_REQUEST_ERROR));
        });
    }

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.totalUsers) {
            errors.totalUsers = t('Required');
        } else if (values.totalUsers <= 0) {
            errors.totalUsers = t("forms.numberValidationPositive");
        }

        if (!values.newExpirationDate) {
            errors.newExpirationDate = t('Required');
        }

        if (values.newExpirationDate < new Date()) {
            errors.newExpirationDate = t('instances_page.invalid_date')
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            idInstance: null,
            totalUsers: 0,
            newExpirationDate: new Date()
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues, resetForm}) => {
            handleSubmit(values);
        }
    });

    const handleDeleteInstance = (idInstance: number) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return(
                    <TrueffortConfirmAlert
                        title={
                            <Typography color='error' variant='h6' fontWeight={700} marginBottom='20px' >
                                <ReportProblemIcon color='error' />&nbsp;&nbsp;{t("Warning")}
                            </Typography>
                        }
                        alertColor='error'
                        messages={[
                            t("instances_page.msg_delete_instance_confirm"),
                            t("instances_page.msg_delete_instance_warning")
                        ]}
                        buttons={[
                            {
                                label: t("project_page.msg_delete_task_confirm_no"),
                                onClick: onClose
                            },
                            {
                                label: t("project_page.msg_delete_task_confirm_yes"),
                                onClick: () => {
                                    try {
                                        // @ts-ignore
                                        instancesServices.deleteInstance(idInstance).then((response: any) => {
                                            console.log(response.success);
                                            if (response.data.success) {
                                                toast.success(t("instances_page.msg_delete_instance_success"));
                                                fillLicenses();
                                            } else {
                                                toast.error(t("instances_page.msg_delete_instance_error"));
                                            }
                                        }).catch(function (error: any) {
                                            console.log(error);
                                            toast.error("Ocurrió un error al eliminar la instancia.");
                                        });
                                    } catch (error) {
                                        console.log("Error #Users handleDelete" + JSON.stringify(error));
                                        toast.error("Ocurrió un error al eliminar la instancia.");
                                    }
                                    onClose();
                                }
                            }
                        ]}
                    />
                )
            }
        })
    }

    const filterLicenses = (searchFilter: string) => {
        const licensesCopy = JSON.parse(JSON.stringify(licenses));
        return licensesCopy.filter((license: any) => {
            if (searchFilter === "") {
                return licensesCopy;
            } else {
                if (license.name.toLowerCase().includes(searchFilter.toLowerCase())) {
                    return license;
                }
            }
        });
    }

    const handleChangeSearchInput = (newString: string) => {
        const filteredItems = filterLicenses(newString);

        setSearchBarValue(newString);
        setFilteredLicenses(filteredItems);
        setTablePage(0);
    }

    /*const handleChangeStatusFilter = (status: number) => {
        if (status === statusFilterValue) {
            return;
        }

        switch (status) {
            case 1:
                setFilteredLicenses(filterLicenses(searchBarValue));
                setStatusFilterValue(status);

                break;
            case 2:
                const filteredLicensesCopy1 = JSON.parse(JSON.stringify(filteredLicenses));
                setFilteredLicenses(filteredLicensesCopy1.filter((license: any) => license.activeByPayment));
                setStatusFilterValue(status);

                break;
            case 3:
                const filteredLicensesCopy2 = JSON.parse(JSON.stringify(filteredLicenses));
                setFilteredLicenses(filteredLicensesCopy2.filter((license: any) => !license.activeByPayment));
                setStatusFilterValue(status);

                break;
        }
    }*/

    const handleChangeTablePage = (event: any, newPage: number) => {
        if (isEditing) {
            return;
        }

        setTablePage(newPage);
    }

    const handleChangeRowsPerPage = (event: any) => {
        if (isEditing) {
            return;
        }

        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    }

  return (
    <>
      <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={6} >
            <TrueffortSearchInput 
              id="instances-search-name"
              placeholder={t("search")}
              variant="outlined"
              fullWidth
              size="small"
              value={searchBarValue}
              onChange={(e: any) => handleChangeSearchInput(e.target.value)}
            />
          </Grid>
          {/*<Grid item xs={6} >
              <Stack spacing={1} direction='row' >
                  <Menu
                      id='license-status-filter'
                      anchorEl={anchorStatusFilter}
                      open={openStatusFilterOptions}
                      onClose={() => setAnchorStatusFilter(null)}
                  >
                      <MenuItem id='status-filter-option1' onClick={() => handleChangeStatusFilter(1)} >

                      </MenuItem>
                  </Menu>
              </Stack>
          </Grid>*/}
        </Grid>
      </Container>

      <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
        <Paper elevation={0} sx={{ padding: '12px', bgcolor: '#F5F5F5' }} >
          <TableContainer component={Paper}
            onSubmit={formik.handleSubmit}
            sx={{overflow:'scroll'}}
          >
            <Table responsive="true">
              <TableHead >
                <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                  <TrueffortTableHeadCell >
                      {t("instances_page.company")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                      {t("instances_page.admin_name")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                      {t("instances_page.last_payment")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                      {t("instances_page.last_payment_date")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                      {t("Phone")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                      {t("user_page.email")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                      {t("instances_page.subscription_plan")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                      {t("instances_page.users")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                    {t("instances_page.start_date")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                    {t("instances_page.end_date")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                    {t("instances_page.license_status")}
                  </TrueffortTableHeadCell>
                  <TrueffortTableHeadCell >
                    {t("actions")}
                  </TrueffortTableHeadCell>
                </StyledTableRow>
              </TableHead>

              <TableBody >
                {filteredLicenses
                  .slice( tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                  .map((license: any) =>
                    <InstanceRow
                      key={license.idInstance}
                      license={license}
                      isEditingState={{
                          isEditing: isEditing,
                          setIsEditing: setIsEditing
                      }}
                      formik={formik}
                      deleteInstance={handleDeleteInstance}
                    />
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangeTablePage}
              count={filteredLicenses.length}
              rowsPerPage={rowsPerPage}
              page={tablePage}
          />
        </Paper>
      </Container>
    </>
  );
}