import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class UserCompanyRoleTypeService {

    getAll() {
        return http?.get(baseURL.readApi.userCompanyRoleType + "/");
    }
}

export default UserCompanyRoleTypeService;