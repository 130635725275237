import React from "react";
import {useTranslation} from "react-i18next";
import {User} from "../../util/Interfaces";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import ImageUser from "./ImageUser";
import {Box, Rating, Collapse, Chip, Stack, Paper, Divider, Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Delete as DeleteIcon, RemoveRedEye as RemoveRedEyeIcon, ExpandMore as ExpandMoreICon, 
  KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import {TrueffortButton, TrueffortChip} from "../../MUIstyle/MUICustom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons/faFloppyDisk";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons/faTrashCan";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import { MIN_LEVEL_LEADER } from "../../util/Constants";

interface ProjectDetailUserCardProps {
    userInfo: any;
    _saveAssignedUser?: (idUser: number, userEffortAvg: number) => void;
    _deleteUser?: (usr: any) => void;
    removeUser?: (selected: boolean, idUser: number, ranked: boolean) => void;
    index: number;
    detail: boolean;
    pendingSave?: boolean,
    showRating?: boolean,
    showDelete?: boolean,
    children?: any
}

// @ts-ignore
function ProjectDetailUserCard({ userInfo, _saveAssignedUser, _deleteUser, removeUser, index, detail, pendingSave, showRating,
  showDelete, children
 }: ProjectDetailUserCardProps) {
    let showDeleteCard = showDelete != undefined && showDelete != null ? showDelete : true;
    let showRatingCard = showRating != undefined && showRating != null ? showRating : true;
    const {t} = useTranslation();
    const {user} = useAuthContext() as { user: User };
    const [open, setOpen] = React.useState(false);

    const userName = userInfo.name + " " + (userInfo.lastname === undefined ? userInfo.lastName : userInfo.lastname);

    return (
        <Stack direction='column' spacing={0.25} >
            <Paper
                variant='outlined'
                sx={{ borderColor: '#553D74', backgroundColor: '#EFEFEF', /*padding: '1em'*/ }}
            >
                <Box sx={{ margin: '1em' }} >
                    <Grid container spacing={1} >
                        <Grid item >
                            <ImageUser user={userInfo} />
                        </Grid>

                        <Grid item xs={12} sm container >
                            <Stack direction='column' spacing={0.5} >
                                <Stack direction='row' justifyContent='space-between' spacing={2} >
                                    <Typography color='#553D74' >
                                        {userName.length <= 22 ? userName : (userName.substring(0, 22) + "...")}
                                    </Typography>

                                    <Stack direction='row' spacing={0.5} >
                                        {detail && pendingSave && _saveAssignedUser !== undefined &&
                                            <Chip
                                                aria-label="save"
                                                size='small'
                                                color='success'
                                                variant='outlined'
                                                label={<FontAwesomeIcon icon={faFloppyDisk} />}
                                                onClick={() => _saveAssignedUser(userInfo.id, userInfo.effort_interp_avg)}
                                            />
                                            /*<IconButton aria-label="save" sx={{float: "right", marginTop: "-1.4rem", marginRight: "-1.5rem", color: "#6875C7"}}
                                                        onClick={() => _saveAssignedUser(userInfo.id, userInfo.effort_avg)}>
                                                <SaveIcon />
                                            </IconButton>*/
                                        }

                                        {showDeleteCard && user.role.accessLevel >= MIN_LEVEL_LEADER && <TrueffortChip
                                            aria-label="remove"
                                            size='small'
                                            variant='outlined'
                                            color='redExtra'
                                            label={
                                                pendingSave ?
                                                    <FontAwesomeIcon icon={faXmark} /> :
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                            }
                                            onClick={ () =>
                                                removeUser !== undefined ?
                                                    removeUser(false, userInfo.id, !(userInfo.ranking === undefined)) :
                                                     _deleteUser !== undefined && _deleteUser(userInfo)
                                            }
                                        />}
                                    </Stack>
                                </Stack>
                                {user.role.accessLevel >= MIN_LEVEL_LEADER && showRatingCard &&
                                <Stack direction='row' justifyContent='space-between' alignItems="center" spacing={1} >
                                    <Rating name="read-only" value={userInfo.ranking === undefined ? 0 : userInfo.ranking} readOnly size="small" />
                                    <Typography variant='caption' >
                                        {t("estimation_page.effort")}:&nbsp;&nbsp;

                                        <TrueffortChip
                                            size="small"
                                            color='lightY'
                                            label={userInfo.effort_interp_avg}
                                        />
                                    </Typography>
                                    {/*<TrueffortChip
                                        size="small"
                                        color='secondary'
                                        label={userInfo.effort_avg}
                                    />*/}
                                </Stack>}
                                {children}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

                {detail && !open && false && 
                    <Box sx={{ width: '100%', background: "#553D74", color: "white", textAlign: "center" }}
                         className="box-show-more"
                         onClick={() => setOpen(!open)}>
                        <ExpandMoreICon sx={{marginBottom: "-0.4rem"}} fontSize="small" /> {t("show_more")}
                    </Box>
                }

                {detail && open &&
                    <Box sx={{ width: '100%', background: "#F2E9FF", color: "#553D74", textAlign: "center" }}
                         className="box-show-more"
                         onClick={() => setOpen(!open)}>
                        <KeyboardArrowUpIcon sx={{marginBottom: "-0.4rem"}} fontSize="small" /> {t("show_less")}
                    </Box>
                }
            </Paper>

            {detail &&
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {/*<Box sx={{borderRadius: "0.25rem", border: "1px solid #553D74", background: "#F2E9FF", padding: "0.25rem 0.375rem", color: "#553D74"}}>*/}
                    <Paper
                        variant='outlined'
                        sx={{ borderColor: '#553D74', backgroundColor: '#F2E9FF', padding: '0.5em' }}
                    >
                        <Stack direction='column' spacing={0.5} >
                            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                                <Typography variant='subtitle2' color='#553D74' fontWeight='700' >
                                    {t("project_tasks_page.mid_effort")}
                                </Typography>

                                <TrueffortChip
                                    size='small'
                                    color='blanco'
                                    label={userInfo.effort_interp_avg}
                                />
                            </Stack>

                            <Typography variant='caption' color='#553D74' >
                                {t("project_tasks_page.mid_effort_desc")}
                            </Typography>

                            <Divider color='#553D74' />

                            <Typography variant='subtitle2' color='#553D74' fontWeight='700' >
                                {t("home.status")}
                            </Typography>

                            <Typography variant='caption' color='#553D74' >
                                {t("project_tasks_page.last_connection")}:
                            </Typography>

                            <Divider color='#553D74' />

                            <Typography variant='subtitle2' color='#553D74' fontWeight='700' >
                                {t("project_tasks_page.actions")}
                            </Typography>

                            <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
                                <TrueffortButton
                                    startIcon={<DeleteIcon />}
                                    variant="outlined"
                                    size="small"
                                    className="btn-remove"
                                    onClick={() =>
                                        _deleteUser !== undefined ?
                                            _deleteUser(userInfo) :
                                            removeUser !== undefined &&
                                            removeUser(false, userInfo.id, !(userInfo.ranking === undefined))
                                    }
                                >
                                    {t("project_tasks_page.remove")}
                                </TrueffortButton>

                                <TrueffortButton
                                    startIcon={<RemoveRedEyeIcon />}
                                    variant="outlined"
                                    size="small"
                                    className="btn-show-all"
                                >
                                    {t("project_tasks_page.show_all")}
                                </TrueffortButton>
                            </Stack>
                        </Stack>
                    </Paper>
                    {/*</Box>*/}
                </Collapse>
            }
        </Stack>
    );

    /*return (
        <Grid xs={3} md={3}>
            <Box sx={{borderRadius: "0.25rem", border: "1px solid #553D74", background: "#EFEFEF", padding: "0.75rem", paddingLeft: "0.4rem"}}>
                {typeof userInfo.pendingSave != "undefined" && userInfo.pendingSave && _saveAssignedUser!== undefined &&
                    <IconButton aria-label="save" sx={{float: "right", marginTop: "-1.4rem", marginRight: "-1.5rem", color: "#6875C7"}}
                        onClick={() => _saveAssignedUser(userInfo.id, userInfo.effort_avg)}>
                      <SaveIcon />
                    </IconButton>
                }
                <Grid container>
                    <Grid xs={2}>
                        <ImageUser user={userInfo} />
                    </Grid>
                    <Grid xs={10}>
                        <Box sx={{color: "#553D74"}}>
                            {userInfo.name + " " + (userInfo.lastname === undefined ? userInfo.lastName : userInfo.lastname)}
                        </Box>
                        <Box sx={{display: "flex"}}>
                            <Rating name="read-only" value={userInfo.ranking === undefined ? 0 : userInfo.ranking} readOnly size="small" />
                            <Box sx={{ ml: 2 }}>{t("estimation_page.effort")}: {userInfo.effort_avg}</Box>
                        </Box>
                    </Grid>
                </Grid>
                {detail && !open &&
                <Box sx={{background: "#553D74", marginBottom: "-0.75rem", marginLeft: "-0.4rem", marginRight: "-0.75rem", color: "white", textAlign: "center"}}
                    className="box-show-more"
                    onClick={() => setOpen(!open)}>
                    <ExpandMoreICon sx={{marginBottom: "-0.4rem"}} fontSize="small" /> {t("show_more")}
                </Box>
                }

                {detail && open &&
                <Box sx={{background: "#F2E9FF", marginBottom: "-0.75rem", marginLeft: "-0.4rem", marginRight: "-0.75rem", color: "#553D74", textAlign: "center"}}
                    className="box-show-more"
                    onClick={() => setOpen(!open)}>
                    <KeyboardArrowUpIcon sx={{marginBottom: "-0.4rem"}} fontSize="small" /> {t("show_less")}
                </Box>
                }
            </Box>
            {detail && _deleteUser !== undefined &&
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{borderRadius: "0.25rem", border: "1px solid #553D74", background: "#F2E9FF", padding: "0.25rem 0.375rem", color: "#553D74"}}>
                    <strong>{t("project_tasks_page.mid_effort")}</strong>
                    <Box sx={{float: "right"}}>{userInfo.effort_avg}</Box>
                    <Box sx={{fontSize: "0.625rem"}}>{t("project_tasks_page.mid_effort_desc")}</Box>
                    <Box sx={{borderTop: "solid thin #553D74", borderBottom: "solid thin #553D74"}}>
                        <strong>{t("home.status")}</strong>
                        <Box sx={{fontSize: "0.625rem"}}>{t("project_tasks_page.last_connection")}: </Box>
                    </Box>
                    <Box>
                        <strong>{t("project_tasks_page.actions")}</strong>
                        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
                            <TrueffortButton startIcon={<DeleteIcon />} variant="outlined" size="small" className="btn-remove"
                                onClick={() => _deleteUser(userInfo, index)}>
                                {t("project_tasks_page.remove")}
                            </TrueffortButton>
                            <TrueffortButton startIcon={<RemoveRedEyeIcon />} variant="outlined" size="small" className="btn-show-all">
                                {t("project_tasks_page.show_all")}
                            </TrueffortButton>
                        </Stack>
                    </Box>
                </Box>
            </Collapse>
            }
        </Grid>
    );*/
}

export default ProjectDetailUserCard;