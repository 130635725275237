import React from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Card, Box, Stack, Radio, Checkbox, FormControlLabel, MenuItem, CircularProgress} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {useFormik} from "formik";
import PaymentService from "../../services/payment.service";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {defaultStrings, countries, exchangeapi, price_constants} from "../../util/Constants";
import {faCcVisa, faCcMastercard, faCcAmex} from "@fortawesome/free-brands-svg-icons";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {useHistory} from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {TrueffortButton, TrueffortTextField, TrueffortSelect, TrueffortInputLabel} from "../../MUIstyle/MUICustom";
import PaymentPlanService from "../../services/paymentPlan.service";
import {KeyboardBackspace as KeyboardBackspaceIcon} from '@mui/icons-material';

/*export type FormType = {
    holder: string,
    address: string,
    zipcode: string,
    country: string,
    city: string,
    state: string,
    acceptTerms: boolean,
    taxid: string,
    email: string,
    validCard: boolean
}*/

function PaymentTabTRUE ({_setShowPaymentPage}) {
    const {t} = useTranslation();
    const {user, updateUserInfo} = useAuthContext();

    const history = useHistory();
    const paymentService = React.useMemo(() => new PaymentService(), []);
    const stripe = useStripe();
    const elements = useElements();
    const [people, setPeople] = React.useState(1);
    const [zipcode, setZipcode] = React.useState("");
    const [valutaType, setValutaType] = React.useState({value: 1, label: "MXN"});
    const [idValutaTypeSelected, setIdValutaTypeSelected] = React.useState(1);
    const [payMethod, setPayMethod] = React.useState(0);
    const [frequency, setFrequency] = React.useState(1);
    const [showLoader, setShowLoader] = React.useState(false);
    const [card, setCard] = React.useState({last4: null, monthExp: null, yearExp: null, brand: null});
    const [fill, setFill] = React.useState(false);
    const [exchangeRate, setExchangeRate] = React.useState(1.0);
    //const [errorMessage, setErrorMessage] = React.useState(null);
    const [canceled, setCanceled] = React.useState(null);
    const [taxidTypesOptions, setTaxidTypesOptions] = React.useState(["mx_rfc"]);
    const paymentPlanService = React.useMemo(() => new PaymentPlanService(), []);
    const [paymentPlans, setPaymentPlans] = React.useState([]);
    const [idPlanSelected, setIdPlanSelected] = React.useState(1);
    const [planSelected, setPlanSelected] = React.useState(user.instance.paymentPlan);

    // @ts-ignore
    const calculateRenewalDate = (freq) => {
        let currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + freq);
        return currentDate;
    }

    const [renewalDate, setRenewalDate] = React.useState(calculateRenewalDate(1));
    const divisas = [
        {value: 0, label: "USD"}, {value: 1, label: "MXN"}, {value: 2, label: "EUR"}
    ];

    const CARD_OPTIONS = {
        iconStyle: "solid",
        hidePostalCode: true,
        style: {
            base: {
                iconColor: "black",
                color: "black",
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                    color: "#fce883"
                },
                "::placeholder": {
                    color: "rgb(207, 209, 223)"
                }
            },
            invalid: {
                iconColor: "#EE0202",
                color: "#EE0202"
            }
        }
    };

    // @ts-ignore
    const validate = (values) => {

        let errors = {} ;
        if (people === 300) {
            return errors;
        }
        if (!values.holder) {
            errors.holder = t("Required");
        }
        if (!values.address) {
            errors.address = t("Required");
        }
        if (!zipcode) {
            errors.zipcode = t("Required");
        }
        if (!values.country) {
            errors.country = t("Required");
        }
        if (!values.city) {
            errors.city = t("Required");
        }
        if (!values.state) {
            errors.state = t("Required");
        }
        if (!values.acceptTerms) {
            errors.acceptTerms = t("Required");
        }

        if (!values.taxid) {
            errors.taxid = t("Required");
        }

        if (!values.email) {
            errors.email = t("Required");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t("forms.invalidEmailValidation");
        }

        if (!values.validCard && !fill) {
            errors.validCard = t("invalid_data");
        }

        return errors;
    };
    //llamar al servicio de pago para obtener los datos de pago si es que existen
    const formik = useFormik({
        initialValues: {
            people: 1,
            country: 'Mexico',
            acceptTerms: false,
            holder: '',
            address: '',
            zipcode: '',
            email: '',
            state: '',
            city: '',
            taxid: '',
            taxid_type: 'mx_rfc',
            validCard: true
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues}) => {
            console.log("submiting")
            handleSubmit(values).then(() => {
                console.log("finish submiting")
            })
        },
    });

    // @ts-ignore
    function truncateTwoDecimals (num) {
        if (typeof num == "undefined") {
            return "";
        }
        var numS = num.toString(),
            decPos = numS.indexOf('.'),
            substrLength = decPos == -1 ? numS.length : 1 + decPos + 2,
            trimmedResult = numS.substr(0, substrLength),
            finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

        return parseFloat(finalResult);
    }

    const contactUs = () => {
        const newWindow = window.open("https://trueffort.com/#contactanos", '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    // @ts-ignore
    const convertValue = (number) => {
        console.log("convertValue: " + number, "");
        if (valutaType.label === "EUR") {
            return number / exchangeRate.USD;
        } else if (valutaType.label === "MXN") {
            return ((number / exchangeRate.USD) * exchangeRate.MXN);
        }

        return number;
    }

    const calculatePrice = (mod = 0) => {
        /*let unitU = 0.0;
        let comp = (price_constants.unit_price_user.m * people) + price_constants.unit_price_user.b;
        if (comp < price_constants.unit_price_user.y2) {
            unitU = price_constants.unit_price_user.y2;
        } else {
            unitU = comp;
        }
        let m1 = (price_constants.unit_price_month.y2 - unitU) / (price_constants.unit_price_month.x2 - price_constants.unit_price_month.x1);
        let b1 = unitU - m1;
        let unitM = 0.0;
        let comp1 = m1 * frequency + b1;
        if (comp1 < price_constants.unit_price_month.y2) {
            unitM = price_constants.unit_price_month.y2;
        } else {
            unitM = comp1;
        }

        let sin = frequency * unitM * people;

        switch (frequency) {
            case 6:
                unitM *= price_constants.discount_complement_semiannual;
                break;
            case 12:
                unitM *= price_constants.discount_complement_annual;
        }

        let res = frequency * unitM * people;
        if (mod === 1) {
            return truncateTwoDecimals(convertValue(sin));
        } else if (mod === 2) {
            return truncateTwoDecimals(convertValue(unitM));
        } else if (mod === 3) {
            return truncateTwoDecimals(convertValue(sin - res));
        }

        return truncateTwoDecimals(convertValue(res));*/
        return truncateTwoDecimals(convertValue(planSelected.price))
    };

    // @ts-ignore
    const handleSubmit = async (values) => {
        //ev.preventDefault();
        //setProcessing(true);
        if (people === 300) {
            //redireccionar a la cita
            contactUs();
            return;
        }
        setShowLoader(true);
        let obj = {} ;
        obj.taxid = values.taxid;
        obj.email = values.email;
        obj.people = `${people}`;
        obj.holder = values.holder;
        obj.address = values.address;
        obj.zipcode = zipcode;
        obj.city = values.city;
        obj.state = values.state;
        obj.country = values.country;
        obj.frequency = `${frequency}`;
        obj.valuta = valutaType.label.toLowerCase();
        obj.payment_method = `${payMethod}`;
        obj.price = calculatePrice(1);
        obj.taxid_type = values.taxid_type;
        obj.idPaymentPlan = idPlanSelected;

        let country = countries.find(x => x.name === obj.country);
        obj.country_code = country?.code;

        let ownerInfo = {
            name: obj.holder,
            email: obj.email,
            address: {
                line1: obj.address,
                postal_code: obj.zipcode,
                city: obj.city,
                state: obj.state,
                country: country?.code
            }
        };

        // SE QUITÓ LA PARTE DE ACTUALIZAR SUBSCRIPCIÓN PARA QUE SIEMPRE SE CREE UN NUEVO REGISTRO

        // @ts-ignore
        paymentService.createSubscription(obj).then((response) => {
            //setClientSecret(response.data.data);
            let jsonResponse = response.data.data;
            //console.log("RESP", jsonResponse);
            if (jsonResponse.error === "1") {
                //setErrorMessage({message: "payment.error_msg_5", type: "danger"});
                toast.error(t("payment.error_msg_5"));
                setShowLoader(false);
                return;
            } else if (jsonResponse.error === "2") {
                //setErrorMessage({message: "payment.error_msg_6", type: "warning"});
                toast.warning(t("payment.error_msg_6"));
                setShowLoader(false);
                return;
            }
            stripe?.confirmCardPayment(jsonResponse.clientSecret, {
                receipt_email: obj.email,
                payment_method: {
                    card: elements?.getElement(CardElement),
                    billing_details: ownerInfo
                },
                setup_future_usage: 'off_session'
            }).then((respo) => {
                let req = {};
                req.idStripePaymentMethod = respo?.paymentIntent?.payment_method;
                req.idPaymentMethodServer = jsonResponse.idPaymentMethodServer;

                // @ts-ignore
                paymentService.saveStripePaymentMethod(req).then(ans => {
                    console.log("exito")
                    let inst = user.instance;
                    inst.activeByPayment = true;
                    inst.paymentPlan.admins = planSelected.admins;
                    inst.paymentPlan.users = planSelected.users;
                    if (inst.trial !== null && typeof inst.trial !== "undefined") {
                        inst.trial.hasBought = true;
                    }
                    console.log(inst)
                    updateUserInfo({instance: inst});
                    //history.push("dashboard", {success: true, date: formatDate()});
                    _setShowPaymentPage(false);
                }).catch((er) => {
                    //setErrorMessage({message: "payment.error_msg_3", type: "warning"});
                    toast.warning(t("payment.error_msg_3"));
                    setShowLoader(false);
                    _setShowPaymentPage(false);
                    console.log("error: " + er);
                });

            }).catch((error) => {
                // @ts-ignore
                paymentService.eraseIncompleteSubscription(parseInt(jsonResponse.idPaymentMethodServer)).then(r => {
                    console.log("subcription erased!");
                });
                //setErrorMessage({message: "payment.error_msg_2", type: "danger"});
                toast.error(t("payment.error_msg_2"));
                console.log("error: " + error);
                setShowLoader(false);
            });
        }).catch((error) => {
            //setErrorMessage({message: "payment.error_msg_2", type: "danger"});
            toast.error(t("payment.error_msg_2"));
            console.log("error: " + error);
            setShowLoader(false);
        });
    };

    // @ts-ignore
    const formatNumberDate = (number) => {
        if (number >= 10) {
            return `${number}`;
        }
        return `0${number}`;
    }

    const formatDate = () => {
        let d = new Date(renewalDate);
        return `${formatNumberDate(d.getDate())}/${formatNumberDate(d.getMonth() + 1)}/${d.getFullYear()}`;
    }

    // @ts-ignore
    const handleChangeCountry = (e) => {
        formik.setFieldValue("country", e.value);
        let country = countries.find(x => x.name === e.value);
        setTaxidTypesOptions(country?.taxid_types);
    }

    React.useEffect(() => {
        setExchangeRate({"USD": 1, "MXN": 18.2});
        // TODO: CUENTA DE EXCHANGE API
        // @ts-ignore
        /*fetch(exchangeapi + "?access_key=" + localStorage.getItem("EXCHANGEAPI_KEY") + "&symbols=USD,MXN").then(response => {
            response.text().then(data => {
                let resp = JSON.parse(data);

                setExchangeRate({"USD": resp.rates.USD, "MXN": resp.rates.MXN});
            });

        }).catch(error => {
            //setErrorMessage("payment.error_msg_1");
            toast.error("payment.error_msg_1");
            console.log("error: " + error);
        });*/

        // @ts-ignore
        paymentService.get(user.instance.idInstance).then((response) => {
            if (typeof response.data.data !== "undefined" && response.data.data !== null) {
                let pmSe = response.data.data.paymentMethod;
                let pm = response.data.data;
                let cardInfo = response.data.data.cardInfo;
                formik.setFieldValue("holder", pmSe.holder);
                formik.setFieldValue("email", pmSe.email);
                formik.setFieldValue("address", pmSe.address);
                formik.setFieldValue("country", pmSe.country);
                formik.setFieldValue("state", pmSe.state);
                formik.setFieldValue("city", pmSe.city);
                formik.setFieldValue("taxid", pmSe.taxid);
                formik.setFieldValue("taxid_type", response.data.data.taxid_type);
                setPeople(pm.userQuantity > 299 ? 299 : pm.userQuantity);
                setZipcode(pmSe.zipcode);
                setFrequency(pm.frequency);
                setCanceled(pm.canceled);

                if (pm.idCurrency === 1) {
                    setValutaType(divisas[0]);
                } else if (pm.idCurrency === 2) {
                    setValutaType(divisas[1]);
                } else {
                    setValutaType(divisas[2]);
                }
                if (pmSe.idPaymentMethodType === 1) {
                    setPayMethod(0);
                } else {
                    setPayMethod(1);
                }
                let obj = {};
                obj.last = cardInfo.last4;
                obj.monthExp = cardInfo.monthExp;
                obj.yearExp = cardInfo.yearExp;
                obj.brand = cardInfo.brand;
                setCard(obj);
                // Comenté esta línea porque todos los campos aparecían deshabilitados
                //setFill(true);
            }
        }).catch((error) => {
            //setErrorMessage("payment.error_msg_4");
            //toast.error(t("payment.error_msg_4"));
        });

        fillPaymentPlans();
    }, []);

    React.useEffect(() => {
        if (!fill) {
            formik.setFieldValue("validCard", false);
        } else {
            formik.setFieldValue("validCard", true);
        }
    }, [fill]);

    React.useEffect(() => {
        // @ts-ignore
        elements?.getElement(CardElement).on('ready', (event) => {
            // @ts-ignore
            elements.getElement(CardElement).on('change', (evento) => {
                if (fill) {
                } else if (evento.error) {
                    formik.setFieldValue("validCard", false);
                } else if (!evento.complete || evento.empty) {
                    formik.setFieldValue("validCard", false);
                } else {
                    formik.setFieldValue("validCard", true);
                }

            })
        });
    }, [elements]);

    React.useEffect(() => {
        setIdPlanSelected(3);
    }, [paymentPlans]);

    const fillPaymentPlans = () => {
        paymentPlanService.getAll().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                setPaymentPlans(response.data);
            }
        });
    }

    // @ts-ignore
    const OptionsTaxId = ({options, formik}) => {
        return (<select name="taxid_type" value={formik.values.taxid_type} onChange={e => {
            formik.setFieldValue("taxid_type", e.target.value);
        }} className={"mini-select"}>
            {options.map((x, k) => {
                return (<option value={x} key={`opt-${k}`}>{x.substring(3).toUpperCase()}</option>);
            })}
        </select>)
    };

    const handleValutaTypeChange = (id) => {
        setIdValutaTypeSelected(id);
        for (let i=0; i < divisas.length; i++) {
            if (id == divisas[i].value) {
                setValutaType(divisas[i]);
            }
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={12} sx={{marginBottom: "-.6rem!important"}}>
                <TrueffortButton size="small" onClick={() => _setShowPaymentPage(false)}>
                    <KeyboardBackspaceIcon sx={{fontSize: "1rem", marginRight: ".4rem"}} />
                    Volver atrás
                </TrueffortButton>
            </Grid>
            <Grid xs={12} sm={8}>
                <Card sx={{padding: "1rem 0.75rem"}}>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <h4 className="color-primary">{t("payment.payment")}</h4>
                        </Grid>

                        <Grid xs={12}>
                            <h6>{t("payment.payment_method")}</h6>
                            <Stack direction="row" spacing={2}>
                                <Box sx={{border: "1px solid #DCDCDC", padding: ".75rem 0.625rem"}}>
                                    <Radio label={"."}
                                                onChange={(e) => {
                                                    setPayMethod(0)
                                                }}
                                                checked={payMethod === 0}
                                                disabled={fill}
                                                className={fill ? "radio-disabled" : ""}
                                    />
                                    <span className="tc">{t("payment.debit_card")}</span>
                                </Box>
                                <Box sx={{border: "1px solid #DCDCDC", padding: ".75rem 0.625rem"}}>
                                    <Radio label={"."}
                                                onChange={(e) => {
                                                    setPayMethod(1)
                                                }}
                                                checked={payMethod === 1}
                                                disabled={fill}
                                                className={fill ? "radio-disabled" : ""}
                                    />
                                    <span className="tc">{t("payment.credit_card")}</span>
                                </Box>
                            </Stack>
                        </Grid>

                            <Grid xs={12} md={9} lg={6}>
                                <h6>{t("payment.payment_data")}</h6>
                                <TrueffortInputLabel labelstring={t("payment.holder_name")} />
                                <TrueffortTextField name="holder"
                                            size="small"
                                            fullWidth
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.holder}
                                            readOnly={fill}
                                />
                            </Grid>

                            <Grid xs={12}>
                                <Box>
                                    <TrueffortInputLabel labelstring={t("payment.number_card")} />
                                        {!fill ?
                                            <CardElement options={CARD_OPTIONS}
                                                         className={"payment-card"}/> :
                                            <div>
                                                {card.brand === "visa" ?
                                                    <FontAwesomeIcon icon={faCcVisa}
                                                                     size={"lg"}/> : card.brand === "mastercard" ?
                                                        <FontAwesomeIcon icon={faCcMastercard}
                                                                         size={"lg"}/> :
                                                        <FontAwesomeIcon
                                                            icon={faCcAmex} size={"lg"}/>}<b> **** ****
                                                **** {card.last4}</b> /<b> {card.monthExp < 10 ? `0${card.monthExp}` : card.monthExp}/{card.yearExp}</b> /<b> ***</b>
                                            </div>
                                        }
                                        {formik.errors?.validCard && <div>
                                        <span style={{
                                            color: "#EE0202",
                                            fontSize: "13.12px"
                                            }}>{formik.errors.validCard}</span>
                                            </div>
                                        }

                                </Box>
                            </Grid>

                            <Grid xs={12}>
                                <h6>{t("payment.billing_info")}</h6>
                                <Box>
                                    <TrueffortInputLabel labelstring={t("payment.taxid")} requiredlabel={true}>
                                        ({fill ? formik.values.taxid_type.substring(3).toUpperCase() : taxidTypesOptions.length === 1 ? taxidTypesOptions[0].substring(3).toUpperCase() :
                                        <OptionsTaxId options={taxidTypesOptions} formik={formik}/>})
                                    </TrueffortInputLabel>
                                    <TrueffortTextField
                                        name="taxid"
                                        size="small"
                                        fullWidth
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.taxid}
                                        readOnly={fill}
                                        error={formik.errors?.taxid && formik.touched?.taxid ? t(formik.errors.taxid) : null}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs={12}>
                                <TrueffortInputLabel labelstring={t("payment.address")} requiredlabel={true} />
                                <TrueffortTextField
                                    name="address"
                                    size="small"
                                    fullWidth
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                            readOnly={fill}
                                    error={formik.errors?.address && formik.touched?.address ? t(formik.errors.address) : null}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TrueffortInputLabel labelstring={t("payment.city")} requiredlabel={true} />
                                <TrueffortTextField
                                    name="city"
                                    size="small"
                                    fullWidth
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.city}
                                                readOnly={fill}
                                    error={formik.errors?.city && formik.touched?.city ? t(formik.errors.city) : null}
                                    />
                            </Grid>
                            <Grid xs={12}>
                                <TrueffortInputLabel labelstring={t("payment.state_province")} requiredlabel={true} />
                                <TrueffortTextField
                                    name="state"
                                    size="small"
                                    fullWidth
                                            onChange={formik.handleChange}
                                            value={formik.values.state}
                                            onBlur={formik.handleBlur}
                                            readOnly={fill}
                                    error={formik.errors?.state && formik.touched?.state ? t(formik.errors.state) : null}
                                />
                            </Grid>

                            <Grid xs={12}>
                                <TrueffortInputLabel labelstring={t("payment.zipcode")} requiredlabel={true} />
                                <TrueffortTextField
                                    name="zipcode"
                                    size="small"
                                    fullWidth
                                                maxLength={10}
                                                onBlur={formik.handleBlur}
                                                onChange={(e) => {
                                                    //en este caso llamar la handleChange aqui y dejar el codigo de abajp
                                                    formik.handleChange(e);
                                                    let isnum = /^\d+$/.test(e.target.value);
                                                    let cad = e.target.value;
                                                    if (!isnum) {
                                                        cad = cad.substring(0, e.target.value.length - 1);
                                                    }
                                                    setZipcode(cad);
                                                }} value={zipcode}
                                                error={formik.errors?.zipcode && formik.touched?.zipcode ? t(formik.errors.zipcode) : null}
                                                readOnly={fill}
                                    />
                            </Grid>
                            <Grid xs={12}>
                                <TrueffortInputLabel labelstring={t("payment.country")} requiredlabel={false} />
                                <TrueffortSelect
                                    name="country"
                                    size="small"
                                    fullWidth
                                        isDisabled={fill}
                                        value={formik.values.country}
                                        onChange={handleChangeCountry}
                                    >
                                        {countries.map((x, k) =>
                                            <MenuItem value={x.name}>
                                                {t(`countries.${x.name}`)}
                                            </MenuItem>

                                        )}
                                </TrueffortSelect>
                            </Grid>
                            <Grid xs={12}>
                                <TrueffortInputLabel labelstring={t(defaultStrings.emailLabel)} requiredlabel={true} />
                                <TrueffortTextField formTarget={"checkout-form"}
                                    size="small"
                                    name="email"
                                    type="email"
                                    fullWidth
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            error={formik.errors?.email && formik.touched?.email ? t(formik.errors.email) : null}
                                            readOnly={fill}
                                />
                            </Grid>
                        </Grid>
                    </Card>

                    <Card sx={{marginTop: "1.25rem", padding: "1rem 0.75rem"}}>
                        <h4 className="color-primary">{t("payment.cancel_subscription")}</h4>
                        <Box sx={{marginBottom: "1rem"}}>
                            <span>{t("payment.cancel_msg_0")}</span>
                        </Box>
                        <TrueffortButton
                            color={"cinnabar"}
                            variant="outlined"
                            fullWidth
                            onClick={(e) => {
                                history.push("/cancel", {from: true});
                            }}
                            disabled={canceled === null || canceled === true}
                        >
                            {t("payment.cancel_subscription")}
                        </TrueffortButton>
                    </Card>

            </Grid>

            <Grid xs={12} sm={4} md={4} lg={4} xl={4} >
                <Card sx={{padding: "1rem 0.75rem"}}>
                    <h4 className="color-primary">{t("payment.amount_to_pay")}</h4>
                        <Box>
                            {/*<Grid xs={12}>
                                <TrueffortInputLabel labelstring={t("payment.exchange_rate")} requiredlabel={true} />
                                <TrueffortSelect
                                    name="country"
                                    size="small"
                                    fullWidth
                                    value={idValutaTypeSelected}
                                    onChange={(e) => {
                                        handleValutaTypeChange(e.target.value);
                                    }}
                                    >
                                        {divisas.map((d, k) =>
                                            <MenuItem key={k} value={d.value}>
                                                {d.label}
                                            </MenuItem>
                                        )}
                                </TrueffortSelect>
                            </Grid>
                            <hr/>*/}
                            <Box className="months">
                                {paymentPlans.map((p, k) =>
                                    <TrueffortButton key={k}
                                        color={idPlanSelected == p.idPaymentPlan ? "primary" : "secondary"}
                                        onClick={() => {
                                            setPlanSelected(p);
                                            setIdPlanSelected(p.idPaymentPlan);
                                            setRenewalDate(calculateRenewalDate(1));
                                        }}
                                    >
                                        {p.name}
                                    </TrueffortButton>
                                )}
                            </Box>
                            <hr/>
                            <Box className="checkout-section">
                                <h5 className="color-sand600">{t("payment.amount_to_pay")}</h5>
                                <div>
                                    <span style={{fontSize: "1.5rem"}}>
                                        {valutaType.label !== "EUR" ? "$" : "€"}
                                        {people !== 300 ? calculatePrice(2).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2}) : "?"}
                                        {" " + valutaType.label}
                                    </span>
                                    <span>{" " + t("payment.for") + " " + planSelected.users + " " + t("payment.users")}</span>
                                </div>
                                <p>{frequency === 1 ? t("payment.monthly_payment") : frequency === 6 ? t("payment.six_months_payment") : t("payment.twelve_months_payment")}</p>
                            </Box>
                            <hr/>
                            <Box>
                                {showLoader ?
                                    <CircularProgress /> :

                                    <TrueffortButton
                                            type={"submit"}
                                            variant="contained"
                                            fullWidth
                                            disabled={showLoader}
                                            onClick={formik.handleSubmit}
                                        >
                                        {people === 300 ? t("payment.contact_us") : (fill ? t("payment.update") : t("payment.pay_now"))}
                                    </TrueffortButton>
                                }
                            </Box>
                            <Box>
                                <FormControlLabel
                                    required control={<Checkbox />}
                                    name={"acceptTerms"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={formik.values.acceptTerms}
                                    label={t("payment.accept") + " " + t("payment.terms_and_conditions")}/>
                                {formik.errors?.acceptTerms && formik.touched?.acceptTerms && !formik.isSubmitting ?
                                    <div
                                        className="text-danger" style={{
                                        marginTop: "-1em",
                                        marginBottom: "-0.5em"
                                    }}>{t(formik.errors.acceptTerms)} </div> : null}
                            </Box>
                                </Box>
                                <hr/>
                                <Box>
                                    <p>{frequency === 1 ? t("payment.renewal_info_1") : frequency === 6 ? t("payment.renewal_info_2") : t("payment.renewal_info_3")}.
                                        {" " + t("payment.renewal_info_4")} {formatDate()} {t("payment.for")} {valutaType.label !== "EUR" ? "$" : "€"}{people !== 300 ? calculatePrice() : "?"}.</p>
                                    <p>{t("payment.renewal_info_5")}</p>
                                </Box>
                                <hr/>

                                    <ul style={{listStyleType: "none"}}>
                                        <li><FontAwesomeIcon icon="check-circle"
                                                             className="benefit-icon"/> {t("payment.user_invitation")}
                                        </li>
                                        <li><FontAwesomeIcon icon="check-circle"
                                                             className="benefit-icon"/> {t("payment.workteams_creation")}
                                        </li>
                                        <li><FontAwesomeIcon icon="check-circle"
                                                             className="benefit-icon"/> {t("payment.schedule_configuration")}

                                        </li>
                                        <li><FontAwesomeIcon icon="check-circle"
                                                             className="benefit-icon"/> {t("payment.projects_creation")}
                                        </li>
                                        <li><FontAwesomeIcon icon="check-circle"
                                                             className="benefit-icon"/> {t("payment.tool_categories")}
                                        </li>
                                        <li><FontAwesomeIcon icon="check-circle"
                                                             className="benefit-icon"/> {t("payment.productivity_dashboards")}
                                        </li>
                                        <li><FontAwesomeIcon icon="check-circle"
                                                             className="benefit-icon"/> {t("payment.logs")}
                                        </li>
                                    </ul>
                                    <div style={{textAlign: "center"}}>
                                <span style={{color: "#006BA0"}}><FontAwesomeIcon icon="headset"
                                                                                  className="benefit-icon"/> {t("payment.premium_support")}</span>
                                    </div>
                        </Card>
                    </Grid>
        </Grid>
    );
}

export default PaymentTabTRUE;