// @flow

import {AppBar, Box, Container,
  MenuItem, Select, Toolbar, Typography} from "@mui/material";
  import {} from "@mui/material"
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
//import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Flag from "react-world-flags";

type Props = {
    children?: any,
    imageURL?: string,
    showMensura?: boolean,
    urlLogo?: string
};

function StandaloneFormPageTRUE(props: Props): any {
    const {t, i18n} = useTranslation();
    const history = useHistory();

    return (
        <Box className="page" >
            <AppBar
                color="transparent"
                sx={{ boxShadow: '0px 0px 0px 0px', backgroundColor: 'white' }}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <a href={props.urlLogo + 'login#/'}>
                            <img src={props.imageURL} className="h-8 mt-0"  alt="logo"/>
                        </a>
                    </Box>

                    <Typography >
                        {t("Dont_have_account?")} <a onClick={() => history.push("pricing")} href="#">{t("forms.go_signUp")}</a>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />

            <Box className="page-single" >
                {/*<Container
                    sx={{ width: '500px'}}
                    //className="col col-login mx-auto"
                >*/}
                {props.children}
                {/*</Container>*/}
            </Box>

            <Box
                component="footer"
                sx={{ py: 1, px: 2, mt: 'auto' }}
            >
                <Container maxWidth="sm" sx={{ textAlign: "center" }} >
                    <Select
                        size="small"
                        id="language-login-select"
                        value={i18n.language === "es" ? t("Spanish") : t("English")}
                        onChange={(e: any) => i18n.changeLanguage(e.target.value)}
                        renderValue={(value: any) => {
                            return (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    <FontAwesomeIcon size="lg" icon={faGlobe} />
                                    {value}
                                    {/*<FontAwesomeIcon icon={faChevronDown} />*/}
                                </Box>
                            )
                        }}
                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 }, color: '#07074E' }}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        <MenuItem value="es"><Flag code={"mx"} height="16" />&nbsp;&nbsp;{t("Spanish")}</MenuItem>
                        <MenuItem value="en"><Flag code={"us"} height="16" />&nbsp;&nbsp; {t("English")}</MenuItem>
                    </Select>
                </Container>
            </Box>
        </Box>
    );
}

export default StandaloneFormPageTRUE;
