import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ApplicationService {

    findAll() {
        return http?.get(baseURL.activitiesapi.applications);
    }

    setRelevant(idApplication: number, idUser: number) {
        return http?.put(baseURL.activitiesapi.applications + "/setRelevant/" + idApplication + "/user/" + idUser);
    }

    setRelevantsApplicationByUsers(idApplication: number, idsUsers: any) {
        return http?.put(baseURL.activitiesapi.applications + `/setRelevant/${idApplication}`, {ids: idsUsers});
    }

    setNotRelevant(idApplication: number, idUser: number) {
        return http?.put(baseURL.activitiesapi.applications + "/setNotRelevant/" + idApplication + "/user/" + idUser);
    }

    setNotRelevantsApplicationByUsers(idApplication: number, idUsers: any) {
      return http?.put(baseURL.activitiesapi.applications + "/setNotRelevant/" + idApplication, {ids: idUsers});
  }

    setRelevantProfile(idApplication: number, idProfile: number) {
        return http?.put(baseURL.activitiesapi.applications + "/setRelevant/" + idApplication + "/profile/" + idProfile);
    }

    setNotRelevantProfile(idApplication: number, idProfile: number) {
        return http?.put(baseURL.activitiesapi.applications + "/setNotRelevant/" + idApplication + "/profile/" + idProfile);
    }

    findAllByIdInstance(idInstance: number) {
        return http?.get(baseURL.readApi.applications + "/findByInstance/" + idInstance);
    }

    findAllByIdInstanceAndFilters(idInstance: number, idProfile: number, idUser: number, inputSearch: string) {
        return http?.get(baseURL.readApi.applications + "/findByInstanceAndFilters/" + idInstance + "?idProfile=" + idProfile + "&idUser=" + idUser + "&name=" + inputSearch);
    }

    findAllByIdInstanceLicense(idInstance: number) {
        return http?.get(baseURL.activitiesapi.applications + "/findByInstanceLicense/" + idInstance);
    }

    categorize(idApplication: number, idCategory: number) {
        return http?.put(baseURL.activitiesapi.applications + "/categorize/" + idApplication + "/category/" + idCategory);
    }

    updateImage(data: any) {
      const options = {
        headers: {
          "Content-type": "multipart/form-data"
        }, transformRequest: () => data
      };

      return http?.post(baseURL.activitiesapi.applications + '/', data, options);
    }

    findByInstanceWithWorkstationsAndUsers(idInstance: number, arrIds: [], idProfile: number) {
        return http?.post(baseURL.readApi.applications + "/findByInstanceWithWorkstationsAndUsers/"
            + idInstance + "?idProfile=" + idProfile, arrIds);
    }
}

export default ApplicationService;