import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class SubscriptionService {

    getAll() {
        return http?.get(baseURL.readApi.subscriptions + "/");
    }

    getLicenses() {
        return http?.get(baseURL.readApi.subscriptions + '/findLicenses');
    }

}

export default SubscriptionService;