import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ProjectElementService {

    getAll() {
        return http?.get(baseURL.readApi.projectElement + "/");
    }

    getByProject(idProject : number) {
        return http?.get(baseURL.readApi.projectElement + `/findByProject/${idProject}`);
    }

    create(data : any) {
        return http?.post(baseURL.activitiesapi.projectElement + "/", data);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.projectElement + `/${id}`);
    }

    /*update(id: number, data: any) {
      return http?.put(baseURL.activitiesapi.projectElement + `/${id}`, data);
    }*/

    activate(id: number) {
        return http?.put(baseURL.activitiesapi.projectElement + "/active/" + id);
    }

    updateName(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.projectElement + `/${id}`, data);
    }

    updateMeasurements(id: number, data: any) {
        return http?.patch(baseURL.activitiesapi.projectElement + "/measurements/" + id, data);
    }

    getByIdJiraProject(idJiraProject : number) {
        return http?.get(baseURL.readApi.projectElement + "/findByIdJiraProject/" + idJiraProject);
    }
    getByIdAzureProject(idAzureProject : number) {
        return http?.get(baseURL.readApi.projectElement + "/findByIdAzureProject/" + idAzureProject);
    }
}

export default ProjectElementService;