import {
  Grid, Card, Paper, Box, Collapse } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  AccountTree as AccountTreeIcon, ImportantDevices as ImportantDevicesIcon,
  Groups as GroupsIcon, Schema as SchemaIcon, List as ListIcon, ExpandMore as ExpandMoreICon,
  ExpandLess as ExpandLessIcon} from "@mui/icons-material";
import { ID_STATUS_CANCELED, ID_STATUS_COMPLETED, ID_STATUS_CREATED, ID_STATUS_INPROCESS } from "../../util/Constants";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import GraphicItemsCount from "./GraphicItemsCount";
import { dateToDateLocale } from "../../util/GlobalFunctions";
import { useState } from "react";
import ReportsService from "../../services/reports.service";
import CustomLoader from "../custom/CustomLoader";

function ReportProjectsTab({startDate, endDate, inputSearch,
   valuesSelectedUsers, valuesSelectedProjects }) {
    //console.log(valuesSelectedUsers);
  const { user } = useAuthContext();
  const [showLoader, setShowLoader] = useState(false);
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const { t } = useTranslation();
  const [projectsFiltered, setProjectsFiltered] = React.useState([]);
  const [projectsReport, setProjectsReport] = React.useState([]);
  const [showMore, setShowMore] = React.useState([false, false, false, false, false]);
  let fields = ['all','created', 'in_process', 'canceled', 'finished'];
  let statusEmpty = {projects: {items: [], dataBar: []}, elements: {items: [], dataBar: []}, users: [], 
    phases: {items: [], dataBar: []}, tasks: {items: [], dataBar: []}};
  const [dataCards, setDataCards] = React.useState({all: {...statusEmpty}, created: {...statusEmpty}, in_process: {...statusEmpty},
    canceled: {...statusEmpty}, finished: {...statusEmpty}});
  const reportsService = React.useMemo(() => new ReportsService(), []);
  
    React.useEffect(() => {
      setShowLoader(true);
      fillProjectsReportsService(startDate, endDate);
    }, [startDate, endDate]);
    
    React.useEffect(() => {
      let items = projectsReport.flat();
      if (valuesSelectedProjects.length > 0) {
        items = items.filter((p) => valuesSelectedProjects.map(p => p.value).includes(p.idProject));
      }
      items = applyFilterUser(items);

      setProjectsFiltered(items);
    }, [valuesSelectedUsers, valuesSelectedProjects]);

    const fillProjectsReportsService = async (sd, ed) => {
      reportsService.findGlobalProjects("", "", 
        sd ? sd.toISOString().substring(0, 10) : "", ed ? ed.toISOString().substring(0,10) : "").then(({data: response}) => {
        //console.log(response);
        if (response.success) {
          setProjectsReport(response.data);
          setShowLoader(false);
        } else {
          console.error(response);
        }
      }).catch((ex) => {
        console.error(ex);
      }).finally(() => setShowLoader(false));
    };

  const applyFilterUser = (items) => {
      if (valuesSelectedUsers.length > 0) {
        let ids = valuesSelectedUsers.map((u) => u.value);
        let itemsAux = [];
        for (let i = 0; i < items.length; i++) {
          let p = items[i];
          let ban = false;
          for (let j = 0; j < ids.length; j++) {
            ban = p.users.map(u => u.idUser).includes(ids[j]);
            if (!ban) break;
          }
          if (ban) itemsAux.push(items[i]);
        }
        items = itemsAux.flat();
      }

    return items;
  }

  React.useEffect(() => {
    if (inputSearch == "") { setProjectsFiltered(projectsReport); }
    else {
      let items = projectsReport.filter((p) => p.name.toLowerCase().includes(inputSearch.toLowerCase()));
      setProjectsFiltered(items);
    }
  }, [inputSearch]);

  React.useEffect(() => {
    //console.log('projectedFiltered');
      let resultCards = {};
      let status = [null, ID_STATUS_CREATED, ID_STATUS_INPROCESS, ID_STATUS_CANCELED, ID_STATUS_COMPLETED];
      status.forEach((s, i) => {
        let projAux = [];
        let projects_ = [], elements_ = [], phases_ = [], tasks_ = [], users = [];
        let field = "created";
        if (!s) {
          projAux = projectsFiltered;
          projects_ = projAux.flat();
          elements_ = projectsFiltered.map(p => p.elements).flat();
          phases_ = projectsFiltered.map(p => p.elements.map(e => e.phases)).flat(2);
          tasks_ = projectsFiltered.map(p => p.elements.map(e => e.phases.map(ph => ph.tasks))).flat(3);
        } else {
          projAux = projectsFiltered.filter(p => p.idStatus == s);
          projects_ = projAux.flat();
          elements_ = projectsFiltered.map(p => p.elements.filter(e => e.idStatus == s)).flat();
          phases_ = projectsFiltered.map(p => p.elements.map(e => e.phases.filter(ph => ph.idStatus == s))).flat(2);
          tasks_ = projectsFiltered.map(p => p.elements.map(e => e.phases.map(ph => ph.tasks.filter(t => t.idStatus == s)))).flat(3);
          field = s == ID_STATUS_CREATED ? 'created' : s == ID_STATUS_INPROCESS ? 'startDate' : 'finishDate';
        }
        
        projAux.forEach(p => p.users.forEach(u => { 
          if (u.user.isEnabled && !users.includes(u.idUser)) { 
            users.push(u.idUser);
          }
        }));

        projects_.sort((a,b) => new Date(a[field]).getTime() - new Date(b[field]).getTime());
        let projectsBar = getItemsByMonth(s && projects_.length > 0 ? projects_[0][field] : null, 
          projects_.length > 0 ? projects_[projects_.length - 1][field] : null, projects_, field, 'projects');
        elements_.sort((a,b) => new Date(a[field]).getTime() - new Date(b[field]).getTime());
        let elementsBar = getItemsByMonth(s && elements_.length > 0 ? elements_[0][field] : null, 
          elements_.length > 0 ? elements_[elements_.length - 1][field] : null, elements_, field, 'elements');
        phases_.sort((a,b) => new Date(a[field]).getTime() - new Date(b[field]).getTime());
        let phasesBar = getItemsByMonth(s && phases_.length > 0 ? phases_[0][field] : null, 
          phases_.length > 0 ? phases_[phases_.length - 1][field] : null, phases_, field, 'phases');
        tasks_.sort((a,b) => new Date(a[field]).getTime() - new Date(b[field]).getTime());
        let tasksBar = getItemsByMonth(s && tasks_.length > 0 ? tasks_[0][field] : null, 
          tasks_.length > 0 ? tasks_[tasks_.length - 1][field] : null, tasks_, field, 'tasks');

        resultCards[fields[i]] = { projects: {items: projects_, dataBar: projectsBar},
          elements: {items: elements_, dataBar: elementsBar}, phases: {items: phases_, dataBar: phasesBar}, 
          tasks: {items: tasks_, dataBar: tasksBar}, users };
      });
      //console.log(resultCards);
      setDataCards(resultCards);
  }, [projectsFiltered]);

  React.useEffect(() => {
    let items = applyFilterUser(projectsReport.flat());
    setProjectsFiltered(items);
  }, [projectsReport]);

  const getItemsByMonth = (start, end, items, field) => {
    let datas = [];
    if (start != null) {
      let newStart = new Date(start);
      newStart.setMonth(newStart.getMonth() - 1);
      let newEnd = new Date(end);
      while (newStart <= newEnd) {
        let count = items.filter(it => dateToDateLocale(new Date(it[field])).getMonth() == newStart.getMonth() 
          && dateToDateLocale(new Date(it[field])).getFullYear() == newStart.getFullYear());
        let data = {key: (t('months_reduced.' + (newStart.getMonth() + 1))) + '-' + newStart.getFullYear(), cantidad:count.length};
        if (count.length > 0) { datas.push(data); };
        newStart.setMonth(newStart.getMonth() + 1);
      }
    }
    
    return datas;
  }

  const getSmallCard = (value, title, background, icon, backgroundIcon, footCard) => {
    return (
    <Card style={{background:background, padding:'8px', maxWidth:'210px'}}>
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <div style={{fontSize:'1em'}}>{title}</div>
        <div style={{fontSize:'0.5em', background: backgroundIcon, borderRadius: '20px'}}>{icon}</div>
      </div>
      <div style={{fontSize:'2em', display:'flex', justifyContent:'center'}}>{value}</div>
      {footCard}
    </Card>)
  }

  const getMiniCard = (value, background, icon, backgroundIcon, footCard) => {
    return (
    <Card style={{background:background, padding:'8px', maxWidth:'210px'}}>
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <div style={{fontSize:'0.4em', background: backgroundIcon, borderRadius: '20px'}}>{icon}</div>
      </div>
      <div style={{fontSize:'1.3em', display:'flex', justifyContent:'center'}}>{value}</div>
      {footCard}
    </Card>)
  }

  const styleIcon = {fontSize:'16px', margin: '2px'};

  return (
    <Paper elevation={0} sx={{ padding: '12px', bgcolor: showLoader ? 'transparent' : '#F5F5F5', marginTop: '1em', width: '98%' }} >
      {!showLoader ?
        <Grid container spacing={2} 
        sx={{border: "solid thin #64A8EC", borderRadius: "10px", marginLeft:'6px', 
          marginTop: "6px", padding: "12px", borderLeft: " solid 8px #64A8EC", width: '99%'}}
      >
        <Grid xs={12}>
          <h4 style={{padding: 0, margin: 0}}>{t("reports.projects.totals")}</h4>
        </Grid>
        <Grid container spacing={2} sx={{marginLeft:'6px', marginTop: "6px", width: '99%'}}>
          <Grid item xs={2}>
            {getSmallCard(dataCards[fields[0]].projects.items.length, t("reports.projects.title"),'#e7e8ea', 
              <ImportantDevicesIcon style={{...styleIcon}} />, '#bdbdbd')}
          </Grid>
          <Grid item xs={2}>
            {getSmallCard(dataCards[fields[0]].users.length, t("reports.projects.users"), '#faf3d3', 
              <GroupsIcon style={{...styleIcon}} />, '#edd685')}
          </Grid>
          <Grid item xs={2}>
            {getSmallCard(dataCards[fields[0]].elements.items.length, t("reports.projects.elements"), '#cff1ab', 
              <AccountTreeIcon style={{...styleIcon}} />, '#a6da71')}
          </Grid>
          <Grid item xs={2}>
            {getSmallCard(dataCards[fields[0]].phases.items.length, t("reports.projects.phases"), '#d7ebf0', 
              <SchemaIcon style={{...styleIcon}} />, '#9addd3')}
          </Grid>
          <Grid item xs={2}>
            {getSmallCard(dataCards[fields[0]].tasks.items.length, t("reports.projects.tasks"), '#f2e9ff', 
              <ListIcon style={{...styleIcon}} />, '#cfb2f6')}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{paddingTop:'10px'}} />
        <Collapse
          in={showMore[0]}
          timeout="auto"
          unmountOnExit
          sx={{width: "100%"}}
        >
          {fields.map((f, ind) => ind == 0 ? null : 
            <Grid container spacing={2} sx={{border: "solid thin #64A8EC", borderRadius: "10px", marginLeft:'6px', 
              marginTop: "6px", paddingBottom: "12px", borderLeft: " solid 8px #64A8EC", width: '99%'}}>
              <Grid xs={12}>
                <h5 style={{paddingTop: '6px', paddingLeft:'6px', margin: 0}}>{t("reports.projects."+f)}</h5>
              </Grid>
              <Grid item xs={2}>
                {getMiniCard(dataCards[f].projects.items.length, '#e7e8ea', 
                  <ImportantDevicesIcon style={{...styleIcon}} />, '#bdbdbd')}
              </Grid>
              <Grid item xs={2}>
                {getMiniCard(dataCards[f].users.length,  '#faf3d3', 
                  <GroupsIcon style={{...styleIcon}} />, '#edd685')}
              </Grid>
              <Grid item xs={2}>
                {getMiniCard(dataCards[f].elements.items.length,  '#cff1ab', 
                  <AccountTreeIcon style={{...styleIcon}} />, '#a6da71')}
              </Grid>
              <Grid item xs={2}>
                {getMiniCard(dataCards[f].phases.items.length,  '#d7ebf0', 
                  <SchemaIcon style={{...styleIcon}} />, '#9addd3')}
              </Grid>
              <Grid item xs={2}>
                {getMiniCard(dataCards[f].tasks.items.length,  '#f2e9ff', 
                  <ListIcon style={{...styleIcon}} />, '#cfb2f6')}
              </Grid>
              {showMore[ind] &&
                <GraphicItemsCount 
                  dataCards={dataCards[f]}
                  keys={["cantidad"]}
                  indexByKey={"key"}
                  status={t("reports.projects."+f)}
                />
              }
              <Grid item xs={10} style={{paddingLeft:'10px', paddingTop:0}}>
                <Box className="show-more-div sand600-color" sx={{fontSize: "0.75rem", marginTop: "0.75rem"}} 
                  onClick={() => {
                    let showMore_ = showMore.map(s => s);
                    showMore_[ind] = !showMore_[ind];
                    setShowMore(showMore_);
                  }}>
                  {!showMore[ind] && <><ExpandMoreICon sx={{fontSize: ".9rem"}} /> {t("show_more")}</>}
                  {showMore[ind] && <><ExpandLessIcon sx={{fontSize: ".9rem"}} /> {t("show_less")}</>}
                </Box>
              </Grid>
            </Grid>
          )}
        </Collapse>
        <Grid xs={12}>
          <Box className="show-more-div sand600-color" sx={{fontSize: "0.75rem"}} 
            onClick={() => {
              let showMore_ = showMore.map(s => s);
              showMore_[0] = !showMore_[0];
              setShowMore(showMore_);
            }}>
            {!showMore[0] && <><ExpandMoreICon sx={{fontSize: ".9rem"}} /> {t("show_more")}</>}
            {showMore[0] && <><ExpandLessIcon sx={{fontSize: ".9rem"}} /> {t("show_less")}</>}
          </Box>
        </Grid>
        </Grid> : <CustomLoader />
      }
    </Paper>
  );
}

export default ReportProjectsTab;