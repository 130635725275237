import {AxiosResponse} from "axios";
import {DEFAULT_REQUEST_ERROR} from "../util/Constants";


const ApiRequest = async (callbackRequest: Promise<AxiosResponse>) => new Promise(async (resolve, reject) => {
    let errorMessage = DEFAULT_REQUEST_ERROR;
    let send = false;
    try {
        const {data: response} = await callbackRequest;
        if (response.success === "true" && response.data) {
            resolve(response.data);
            send = true;
        } else if (response?.internalError === "true") {
            errorMessage = response.message;
        }
    } catch (err) {
        console.log("#Error on Request " + err);
    } finally {
        if (!send) {
            reject({message: errorMessage})
        }
    }
});

export default ApiRequest;