import {
    Grid, Badge, Card, TablePagination, Paper, TableContainer, Table,
    TableRow, TableCell, TableHead, TableBody, Radio, FormControlLabel,
    RadioGroup, Box
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { dateToDateLocale } from "../../util/GlobalFunctions";
import CardEmpty from "../custom/CardEmpty";
import { COLORS_RELEVANT } from "../../util/Constants";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import CustomAvatar from "../custom/CustomAvatar";
import ReportsService from "../../services/reports.service";
import CustomLoader from "../custom/CustomLoader";
import { StyledTableRow, TrueffortTableHeadCell } from "../../MUIstyle/MUICustom";

function GlobalUserViewReport({ startDate, endDate, idSelectedUser, idSelectedProject, 
  inputSearch }) {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = React.useState(false);
  const [dataUsers, setDataUsers] = React.useState([]);
  const [dataUsersFilter, setDataUsersFilter] = React.useState([]);
  const [configMagnitud, setConfigMagnitud] = React.useState(1);
  let ROWS_PER_PAGE = 4;
  const [rowsPerPageUsers, setRowsPerPageUsers] = React.useState(ROWS_PER_PAGE);
  const [usersPage, setUsersPage] = React.useState(0);
  const [usersCount, setUsersCount] = React.useState(0);
  const reportsService = React.useMemo(() => new ReportsService(), []);

  React.useEffect(() => {
    setShowLoader(true);
    fillSummaryUsersService();
    let today = new Date();
    let end = new Date(endDate != null ? endDate : new Date());
    today.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    if (today.getTime() == end.getTime()) {
      const id = setInterval(() => fillSummaryUsersService(), 30000);
      return () => clearInterval(id);
    }
  }, [startDate, endDate, idSelectedProject, idSelectedUser]);

  const fillSummaryUsersService = async () => {
    //console.log("fillSummaryUsersService")
    let start = dateToDateLocale(startDate != null ? startDate : new Date()).toISOString();
    let end = dateToDateLocale(endDate != null ? endDate : new Date()).toISOString();
    reportsService.findGlobalUsers(start.slice(0, 10), end.slice(0, 10), idSelectedUser, idSelectedProject)
    .then(({data: response}) => {
      if (response.success) {
        setDataUsers(response.data);
      }
    }).catch(({data: response}) => console.error(response))
    .finally(() => setShowLoader(false));
  };

  const getFormatShedule = (time) => {
    let init = new Date(time);
    init = new Date(init.getTime() + init.getTimezoneOffset() * 60000);
    return (init.getHours() < 10 ? "0" + init.getHours() : init.getHours()) + ":" +
      (init.getMinutes() < 10 ? "0" + init.getMinutes() : init.getMinutes());
  }

  React.useEffect(() => {
    let dataAux = dataUsers.map(d => d);
    if (inputSearch != "") {
      dataAux = dataAux.filter(d => d.name.toUpperCase().includes(inputSearch.toUpperCase()));
    }

    let data = dataAux.map(us => {
      let projs_ = [];
      let elems_ = [];
      let phases_ = [];
      let tasks_ = [];
      let projsFinished_ = [];
      let elemsFinished_ = [];
      let phasesFinished_ = [];
      let tasksFinished_ = [];
      us.projects.forEach(p => {
        projs_.push(p);
        if (p.idStatus == 4) { projsFinished_.push(p); }
        p.elements.forEach(e => {
          elems_.push(e);
          if (e.idStatus == 4) { elemsFinished_.push(e); }
          e.phases.forEach(ph => {
            phases_.push(ph);
            if (ph.idStatus == 4) { phasesFinished_.push(ph); }
            ph.tasks.forEach(t => {
              tasks_.push(t);
              if (t.idStatus == 4) { tasksFinished_.push(t); }
            });
          });
        });
      });
      let finished = { projects: projsFinished_.length, elements: elemsFinished_.length, phases: phasesFinished_.length, tasks: tasksFinished_.length };

      projs_ = projs_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityProjects = {
        min: (projs_[0].effortRelevant / projs_[0].effort),
        max: (projs_[projs_.length - 1].effortRelevant / projs_[projs_.length - 1].effort), avg: 0
      };
      let avgProj = 0;
      projs_.forEach(p => avgProj += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgProj = avgProj / projs_.length;
      productivityProjects.avg = avgProj;

      elems_ = elems_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityElements = {
        min: (elems_[0].effortRelevant / elems_[0].effort),
        max: (elems_[elems_.length - 1].effortRelevant / elems_[elems_.length - 1].effort), avg: 0
      };
      let avgElem = 0;
      elems_.forEach(p => avgElem += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgElem = avgElem / elems_.length;
      productivityElements.avg = avgElem;

      phases_ = phases_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityPhases = {
        min: (phases_[0].effortRelevant / phases_[0].effort),
        max: (phases_[phases_.length - 1].effortRelevant / phases_[phases_.length - 1].effort), avg: 0
      };
      let avgPhases = 0;
      phases_.forEach(p => avgPhases += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgPhases = avgPhases / phases_.length;
      productivityPhases.avg = avgPhases;

      tasks_ = tasks_.sort((a, b) => (a.effortRelevant / a.effort) - (b.effortRelevant / b.effort));
      let productivityTasks = {
        min: (tasks_[0].effortRelevant / tasks_[0].effort),
        max: (tasks_[tasks_.length - 1].effortRelevant / tasks_[tasks_.length - 1].effort), avg: 0
      };
      let avgTasks = 0;
      tasks_.forEach(p => avgTasks += (p.effort == 0 ? 0 : (p.effortRelevant / p.effort)));
      avgTasks = avgTasks / tasks_.length;
      productivityTasks.avg = avgTasks;

      let projEffortFinished = projsFinished_.reduce((acc, cur) => acc + cur.effort, 0);
      let elemEfortFinished = elemsFinished_.reduce((a, c) => a + c.effort, 0);
      let phaseEffortFinished = phasesFinished_.reduce((ac, cu) => ac + cu.effort, 0);
      let taskEffortFinished = tasksFinished_.reduce((accu, curr) => accu + curr.effort, 0);
      let effortFinished = {
        projects: projEffortFinished, elements: elemEfortFinished,
        phases: phaseEffortFinished, tasks: taskEffortFinished
      };

      //productivity = cantidad de items finalizados / suma del esfuerzo registrado de los items finalizados
      //PDR = suma del esfuerzo registrado de los items finalizados / cantidad de items finalizados
      //velocity = cantidad de items finalizados / cantidad de dias que contienen esfuerzo del item
      productivityProjects = { min: 0, avg: 0, max: 0 };
      productivityElements = { min: 0, avg: 0, max: 0 };
      productivityPhases = { min: 0, avg: 0, max: 0 };
      productivityTasks = { min: 0, avg: 0, max: 0 };

      if (projsFinished_.length > 0) {
        productivityProjects.avg = projsFinished_.length / (effortFinished.projects / 3600);
        projsFinished_ = projsFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityProjects.min = 1 / (projsFinished_[0].effort / 3600);
        productivityProjects.max = 1 / (projsFinished_[projsFinished_.length - 1].effort / 3600);
      }

      if (elemsFinished_.length > 0) {
        productivityElements.avg = elemsFinished_.length / (effortFinished.elements / 3600);
        elemsFinished_ = elemsFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityElements.min = 1 / (elemsFinished_[0].effort / 3600);
        productivityElements.max = 1 / (elemsFinished_[elemsFinished_.length - 1].effort / 3600);
      }

      if (phasesFinished_.length > 0) {
        productivityPhases.avg = phasesFinished_.length / (effortFinished.phases / 3600);
        phasesFinished_ = phasesFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityPhases.min = 1 / (phasesFinished_[0].effort / 3600);
        productivityPhases.max = 1 / (phasesFinished_[phasesFinished_.length - 1].effort / 3600);
      }

      if (tasksFinished_.length > 0) {
        productivityTasks.avg = tasksFinished_.length / (effortFinished.tasks / 3600);
        tasksFinished_ = tasksFinished_.sort((a, b) => (1 / a.effort) - (1 / b.effort));
        productivityTasks.min = 1 / (tasksFinished_[0].effort / 3600);
        productivityTasks.max = 1 / (tasksFinished_[tasksFinished_.length - 1].effort / 3600);
      }

      let productivityEffort = {
        projects: productivityProjects, elements: productivityElements,
        phases: productivityPhases, tasks: productivityTasks
      };

      let projDurationFinished = projsFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let elemDurationFinished = elemsFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let phaseDurationFinished = phasesFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let taskDurationFinished = tasksFinished_.reduce((ac, cur) => ac + cur.duration, 0);
      let durationFinished = {
        projects: projDurationFinished, elements: elemDurationFinished,
        phases: phaseDurationFinished, tasks: taskDurationFinished
      };

      let prodDurationProj = { min: 0, avg: 0, max: 0 };
      let prodDurationElem = { min: 0, avg: 0, max: 0 };
      let prodDurationPha = { min: 0, avg: 0, max: 0 };
      let prodDurationTasks = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        prodDurationProj.avg = projsFinished_.length / projsFinished_.reduce((a, c) => a + c.duration, 0);
        projsFinished_ = projsFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationProj.min = 1 / projsFinished_[0].duration;
        prodDurationProj.max = 1 / projsFinished_[projsFinished_.length - 1].duration;
      }

      if (elemsFinished_.length > 0) {
        prodDurationElem.avg = elemsFinished_.length / elemsFinished_.reduce((a, c) => a + c.duration, 0);
        elemsFinished_ = elemsFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationElem.min = 1 / elemsFinished_[0].duration;
        prodDurationElem.max = 1 / elemsFinished_[elemsFinished_.length - 1].duration;
      }

      if (phasesFinished_.length > 0) {
        prodDurationPha.avg = phasesFinished_.length / phasesFinished_.reduce((a, c) => a + c.duration, 0);
        phasesFinished_ = phasesFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationPha.min = 1 / phasesFinished_[0].duration;
        prodDurationPha.max = 1 / phasesFinished_[phasesFinished_.length - 1].duration;
      }

      if (tasksFinished_.length > 0) {
        prodDurationTasks.avg = tasksFinished_.length / tasksFinished_.reduce((a, c) => a + c.duration, 0);
        tasksFinished_ = tasksFinished_.sort((a, b) => (1 / a.duration) - (1 / b.duration));
        prodDurationTasks.min = 1 / tasksFinished_[0].duration;
        prodDurationTasks.max = 1 / tasksFinished_[tasksFinished_.length - 1].duration;
      }

      let productivityDuration = {
        projects: prodDurationProj, elements: prodDurationElem,
        phases: prodDurationPha, tasks: prodDurationTasks
      };

      let projDurationDaysWorkedFinished = projsFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let elemDurationDaysWorkedFinished = elemsFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let phaseDurationDaysWorkedFinished = phasesFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let taskDurationDaysWorkedFinished = tasksFinished_.reduce((ac, cur) => ac + cur.daysWithEffort, 0);
      let durationDaysWorkedFinished = { projects: projDurationDaysWorkedFinished, elements:
        elemDurationDaysWorkedFinished, phases: phaseDurationDaysWorkedFinished,
        tasks: taskDurationDaysWorkedFinished
      };

      let prodDurationDaysWorkedProj = { min: 0, avg: 0, max: 0 };
      let prodDurationDaysWorkedElem = { min: 0, avg: 0, max: 0 };
      let prodDurationDaysWorkedPha = { min: 0, avg: 0, max: 0 };
      let prodDurationDaysWorkedTasks = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        prodDurationDaysWorkedProj.avg = projsFinished_.length / projsFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        projsFinished_ = projsFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedProj.min = 1 / projsFinished_[0].daysWithEffort;
        prodDurationDaysWorkedProj.max = 1 / projsFinished_[projsFinished_.length - 1].daysWithEffort;
      }

      if (elemsFinished_.length > 0) {
        prodDurationDaysWorkedElem.avg = elemsFinished_.length / elemsFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        elemsFinished_ = elemsFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedElem.min = 1 / elemsFinished_[0].daysWithEffort;
        prodDurationDaysWorkedElem.max = 1 / elemsFinished_[elemsFinished_.length - 1].daysWithEffort;
      }

      if (phasesFinished_.length > 0) {
        prodDurationDaysWorkedPha.avg = phasesFinished_.length / phasesFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        phasesFinished_ = phasesFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedPha.min = 1 / phasesFinished_[0].daysWithEffort;
        prodDurationDaysWorkedPha.max = 1 / phasesFinished_[phasesFinished_.length - 1].daysWithEffort;
      }

      if (tasksFinished_.length > 0) {
        prodDurationDaysWorkedTasks.avg = tasksFinished_.length / tasksFinished_.reduce((a, c) => a + c.daysWithEffort, 0);
        tasksFinished_ = tasksFinished_.sort((a, b) => (1 / a.daysWithEffort) - (1 / b.daysWithEffort));
        prodDurationDaysWorkedTasks.min = 1 / tasksFinished_[0].daysWithEffort;
        prodDurationDaysWorkedTasks.max = 1 / tasksFinished_[tasksFinished_.length - 1].daysWithEffort;
      }

      let productivityDurationDaysWorked = {
        projects: prodDurationDaysWorkedProj, elements: prodDurationDaysWorkedElem,
        phases: prodDurationDaysWorkedPha, tasks: prodDurationDaysWorkedTasks
      };

      let avgPDRProject = 0;
      let PDRProject = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        projsFinished_ = projsFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRProject = (projsFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / projsFinished_.length;
        PDRProject.min = (projsFinished_[0].effort / 3600) / 1;
        PDRProject.max = (projsFinished_[projsFinished_.length - 1].effort / 3600) / 1;
        PDRProject.avg = avgPDRProject;
      }

      let avgPDRElements = 0;
      let PDRElements = { min: 0, avg: 0, max: 0 };
      if (elemsFinished_.length > 0) {
        elemsFinished_ = elemsFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRElements = (elemsFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / elemsFinished_.length;
        PDRElements.min = (elemsFinished_[0].effort / 3600) / 1;
        PDRElements.max = (elemsFinished_[elemsFinished_.length - 1].effort / 3600) / 1;
        PDRElements.avg = avgPDRElements;
      }

      let avgPDRPhases = 0;
      let PDRPhases = { min: 0, avg: 0, max: 0 };
      if (phasesFinished_.length > 0) {
        phasesFinished_ = phasesFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRPhases = (phasesFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / phasesFinished_.length;
        PDRPhases.min = (phasesFinished_[0].effort / 3600) / 1;
        PDRPhases.max = (phasesFinished_[phasesFinished_.length - 1].effort / 3600) / 1;
        PDRPhases.avg = avgPDRPhases;
      }

      let avgPDRTasks = 0;
      let PDRTasks = { min: 0, avg: 0, max: 0 };
      if (tasksFinished_.length > 0) {
        tasksFinished_ = tasksFinished_.sort((a, b) => (a.effort / 1) - (b.effort / 1));
        avgPDRTasks = (tasksFinished_.reduce((a, c) => a + c.effort, 0) / 3600) / tasksFinished_.length;
        PDRTasks.min = (tasksFinished_[0].effort / 3600) / 1;
        PDRTasks.max = (tasksFinished_[tasksFinished_.length - 1].effort / 3600) / 1;
        PDRTasks.avg = avgPDRTasks;
      }
      let PDREffort = { projects: PDRProject, elements: PDRElements, phases: PDRPhases, tasks: PDRTasks };

      let avgPDRDurationProject = 0;
      let PDRDurationProj = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        projsFinished_ = projsFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationProject = projsFinished_.reduce((a, c) => a + c.duration, 0) / projsFinished_.length;
        PDRDurationProj.min = projsFinished_[0].duration / 1;
        PDRDurationProj.max = projsFinished_[projsFinished_.length - 1].duration / 1;
        PDRDurationProj.avg = avgPDRDurationProject;
      }

      let avgPDRDurationElements = 0;
      let PDRDurationElem = { min: 0, avg: 0, max: 0 };
      if (elemsFinished_.length > 0) {
        elemsFinished_ = elemsFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationElements = elemsFinished_.reduce((a, c) => a + c.duration, 0) / elemsFinished_.length;
        PDRDurationElem.min = elemsFinished_[0].duration / 1;
        PDRDurationElem.max = elemsFinished_[elemsFinished_.length - 1].duration / 1;
        PDRDurationElem.avg = avgPDRDurationElements;
      }

      let avgPDRDurationPhases = 0;
      let PDRDurationPhases = { min: 0, avg: 0, max: 0 };
      if (phasesFinished_.length > 0) {
        phasesFinished_ = phasesFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationPhases = phasesFinished_.reduce((a, c) => a + c.duration, 0) / phasesFinished_.length;
        PDRDurationPhases.min = phasesFinished_[0].duration / 1;
        PDRDurationPhases.max = phasesFinished_[phasesFinished_.length - 1].duration / 1;
        PDRDurationPhases.avg = avgPDRDurationPhases;
      }

      let avgPDRDurationTasks = 0;
      let PDRDurationTasks = { min: 0, avg: 0, max: 0 };
      if (tasksFinished_.length > 0) {
        tasksFinished_ = tasksFinished_.sort((a, b) => (a.duration / 1) - (b.duration / 1));
        avgPDRDurationTasks = tasksFinished_.reduce((a, c) => a + c.duration, 0) / tasksFinished_.length;
        PDRDurationTasks.min = tasksFinished_[0].duration / 1;
        PDRDurationTasks.max = tasksFinished_[tasksFinished_.length - 1].duration / 1;
        PDRDurationTasks.avg = avgPDRDurationTasks;
      }
      let PDRDuration = { projects: PDRDurationProj, elements: PDRDurationElem,
        phases: PDRDurationPhases, tasks: PDRDurationTasks
      };

      let avgPDRDurDaysWorkedProj = 0;
      let PDRDurDaysWorkedProj = { min: 0, avg: 0, max: 0 };
      if (projsFinished_.length > 0) {
        projsFinished_ = projsFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedProj = projsFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / projsFinished_.length;
        PDRDurDaysWorkedProj.min = projsFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedProj.max = projsFinished_[projsFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedProj.avg = avgPDRDurDaysWorkedProj;
      }

      let avgPDRDurDaysWorkedElem = 0;
      let PDRDurDaysWorkedElem = { min: 0, avg: 0, max: 0 };
      if (elemsFinished_.length > 0) {
        elemsFinished_ = elemsFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedElem = elemsFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / elemsFinished_.length;
        PDRDurDaysWorkedElem.min = elemsFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedElem.max = elemsFinished_[elemsFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedElem.avg = avgPDRDurDaysWorkedElem;
      }

      let avgPDRDurDaysWorkedPha = 0;
      let PDRDurDaysWorkedPha = { min: 0, avg: 0, max: 0 };
      if (phasesFinished_.length > 0) {
        phasesFinished_ = phasesFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedPha = phasesFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / phasesFinished_.length;
        PDRDurDaysWorkedPha.min = phasesFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedPha.max = phasesFinished_[phasesFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedPha.avg = avgPDRDurDaysWorkedPha;
      }

      let avgPDRDurDaysWorkedTasks = 0;
      let PDRDurDaysWorkedTasks = { min: 0, avg: 0, max: 0 };
      if (tasksFinished_.length > 0) {
        tasksFinished_ = tasksFinished_.sort((a, b) => (a.daysWithEffort / 1) - (b.daysWithEffort / 1));
        avgPDRDurDaysWorkedTasks = tasksFinished_.reduce((a, c) => a + c.daysWithEffort, 0) / tasksFinished_.length;
        PDRDurDaysWorkedTasks.min = tasksFinished_[0].daysWithEffort / 1;
        PDRDurDaysWorkedTasks.max = tasksFinished_[tasksFinished_.length - 1].daysWithEffort / 1;
        PDRDurDaysWorkedTasks.avg = avgPDRDurDaysWorkedTasks;
      }
      let PDRDurationDaysWorked = { projects: PDRDurDaysWorkedProj, elements: PDRDurDaysWorkedElem,
        phases: PDRDurDaysWorkedPha, tasks: PDRDurDaysWorkedTasks
      };

      let vProject = projsFinished_.length > 0 ? 
        projsFinished_.length / projsFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let vElements = elemsFinished_.length > 0 ? 
        elemsFinished_.length / elemsFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let vPhases = phasesFinished_.length > 0 ? 
        phasesFinished_.length / phasesFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let vTasks = tasksFinished_.length > 0 ? 
        tasksFinished_.length / tasksFinished_.reduce((ac, cu) => ac + cu.daysWithEffort, 0) : 0;
      
      let velocity = { projects: vProject, elements: vElements, phases: vPhases, tasks: vTasks };

      let groupsIdsElementsType = {};
      let groupsElementsType = [];
      for (let elementType of elems_) {
        if (groupsIdsElementsType[elementType.idElementType] == undefined) {
          groupsIdsElementsType[elementType.idElementType] = {
            idElementType: elementType.idElementType, name: elementType.nameElementType,
            elementsByType: elems_.filter(e => e.idElementType == elementType.idElementType)
          };
          groupsElementsType.push(groupsIdsElementsType[elementType.idElementType]);
        }
      }

      let groupsIdsPhasesType = {};
      let groupsPhasesType = [];
      for (let pt of phases_) {
        if (groupsIdsPhasesType[pt.idPhaseType] == undefined) {
          groupsIdsPhasesType[pt.idPhaseType] = {
            idPhaseType: pt.idPhaseType, name: pt.name,
            phasesByType: phases_.filter(e => e.idPhaseType == pt.idPhaseType)
          };
          groupsPhasesType.push(groupsIdsPhasesType[pt.idPhaseType]);
        }
      }
      let groupsIdsTasksType = {};
      let groupsTasksType = [];
      for (let tt of tasks_) {
        if (groupsIdsTasksType[tt.idTaskType] == undefined) {
          groupsIdsTasksType[tt.idTaskType] = {
            idTaskType: tt.idTaskType, name: tt.name,
            tasksByType: tasks_.filter(e => e.idTaskType == tt.idTaskType)
          };
          groupsTasksType.push(groupsIdsTasksType[tt.idTaskType]);
        }
      }

      let totalDaysWorkedProject = us.projects.reduce((a, c) => a + c.daysWithEffort, 0);
      let totalDaysWorkedElement = groupsElementsType.map(e => e.elementsByType.reduce((a, c) => a + c.daysWithEffort, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDaysWorkedPhases = groupsPhasesType.map(p => p.phasesByType.reduce((a, c) => a + c.daysWithEffort, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDaysWorkedTasks = groupsTasksType.map(t => t.tasksByType.reduce((a, c) => a + c.daysWithEffort, 0)).reduce((ac, cu) => ac + cu, 0);
      let durationDaysWorked = { projects: totalDaysWorkedProject, elements: totalDaysWorkedElement,
        phases: totalDaysWorkedPhases, tasks: totalDaysWorkedTasks
      };

      let totalDurationProject = us.projects.reduce((a, c) => a + c.duration, 0);
      let totalDurationElement = groupsElementsType.map(e => e.elementsByType.reduce((a, c) => a + c.duration, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDurationPhases = groupsPhasesType.map(p => p.phasesByType.reduce((a, c) => a + c.duration, 0)).reduce((accu, curr) => accu + curr, 0);
      let totalDurationTasks = groupsTasksType.map(t => t.tasksByType.reduce((a, c) => a + c.duration, 0)).reduce((ac, cu) => ac + cu, 0);
      let duration = { projects: totalDurationProject, elements: totalDurationElement, phases: totalDurationPhases, tasks: totalDurationTasks };

      let prevUs = dataUsersFilter.find(u => u.idUser == us.idUser);

      return {
        ...us, timeEntryFormat: getFormatShedule(us.checkInHour), timeExitFormat: getFormatShedule(us.checkOutHour),
        productivityEffort, productivityDuration, productivityDurationDaysWorked, finished, effortFinished,
        durationFinished, durationDaysWorkedFinished, PDREffort,
        PDRDuration, PDRDurationDaysWorked, velocity, groupsElementsType, groupsPhasesType, groupsTasksType, duration, durationDaysWorked,
        showListProjectDetail: prevUs ? prevUs.showListProjectDetail : false
      };
    });
    //console.log(data);
    setDataUsersFilter(data);
    setUsersCount(data.length);
  }, [dataUsers, inputSearch]);

  const getExtractBar = (name, index, isFirst, isLast, totalTime, extractTime) => {
    //console.log(totalTime, extractTime);
      return (<span title={name} style={{
          background: COLORS_RELEVANT[index % COLORS_RELEVANT.length], width: ((extractTime / totalTime) * 100) + '%', height: '20px',
          borderTopLeftRadius: isFirst ? '5px' : '', borderBottomLeftRadius: isFirst ? '5px' : '',
          borderTopRightRadius: isLast ? '5px' : '', borderBottomRightRadius: isLast ? '5px' : ''
      }}>
      </span>)
  }

  const styleTableContent = { fontSize: '12px', padding: 0 };
  const alignText = { textAlign: 'center' };
  const columnData = { textAlign: "center", width: '33%', height: '20px', marginBottom: '0.2em' };
  const rowData = { width: '100%', display: 'flex', flexDirection: 'row' };
  const styleWorkingOn = { width: '220px', display: 'flex' };
  const styleVelocity = { textAlign: "center", height: '20px', marginBottom: '0.2em' };

  const handleChangeMagnitud = (event) => {
    setConfigMagnitud(event.target.value);
  };

  const AvatarAndUser = (name, urlImage, isActive) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isActive != null ?
          <Badge variant="dot" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{
              '& .MuiBadge-badge': { right: 5, top: 12, border: `2px solid #EFEFEF`,
                backgroundColor: isActive ? '#78B737' : '#989898', width: '15px',
                height: '15px', borderRadius: '64px'
              }
            }}
          >
            <CustomAvatar 
              url={urlImage}
            />
          </Badge> : 
          <CustomAvatar 
            url={urlImage}
          />
        }
        <div style={{ paddingLeft: '0.2em' }}>{name}</div>
      </div>
    );
  }

  const divColumnData = (d1, d2, d3) => {
    return (
      <div style={{ ...styleTableContent, ...columnData }}>
        {(configMagnitud == 1 ? d1 : configMagnitud == 2 ? d2 : d3).toFixed(2)}
      </div>
    );
  }

  const handleChangeUsersRowsPerPage = (event) => {
    setRowsPerPageUsers(parseInt(event.target.value, 10));
    setUsersPage(0);
  }

  const handleChangeUsersPage = (event, newPage) => {
    setUsersPage(newPage);
  }

  const getRadioButton = (handle, value, title, subtitle, width) => {
    return (<Box style={{display:'flex', flexDirection:'column', width:width}}>
    <FormControlLabel
      sx={{marginBottom:'0'}}
      onChange={handle} value={value}
      control={<Radio size="small" />} label={title} />
      <span style={{fontSize:'10px', color:'gray', paddingLeft:'1em'}}>{subtitle}</span>
    </Box>);
  }

  return (
    <div>
      {!showLoader ?
        <Grid container spacing={1} sx={{ marginTop: '1.2em', width: '100%' }} >
          {dataUsersFilter.length == 0 ?
            <CardEmpty> <div>{t("msg.info.empty_response")}</div></CardEmpty> :
            <Card sx={{ padding: "8px", width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'unset', marginBottom: '0.5em' }}>
                <div style={{ paddingRight: '0.4em', fontSize: '12px', fontWeight: 'bold', paddingRight: '1em' }}>{t("reports.show")}</div>
                <RadioGroup row
                  defaultValue={1}
                  name="row-radio-buttons-group"
                  sx={{
                      '& .MuiTypography-root': { fontSize: '12px' },
                      '& .MuiRadio-root': { padding: '0 0 0 1px' }
                  }}
                >
                  {getRadioButton(handleChangeMagnitud, 1, t("reports.effort"), t("reports.hours"), '80px')}
                  {getRadioButton(handleChangeMagnitud, 2, t("reports.duration"), t("reports.working_days"), '100px')}
                  {getRadioButton(handleChangeMagnitud, 3, t("reports.days_worked"), t("reports.days_with_activity"), '180px')}
                </RadioGroup>
              </div>
              <Grid container spacing={1} rowSpacing={3} style={{ margin: '0.4em', width: '99%' }}>
                <TableContainer sx={{ overflow: "scroll" }} component={Paper}>
                  <Table responsive={true}>
                    <TableHead>
                      <StyledTableRow sx={{ backgroundColor: '#DADADA !important'}}>
                        <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                            {t("User")}
                        </TrueffortTableHeadCell>
                        <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                            {t("reports.working_in")}
                        </TrueffortTableHeadCell>
                        <TrueffortTableHeadCell style={{ ...styleTableContent, width: '80px' }}>
                            {t("reports.in_process")}
                        </TrueffortTableHeadCell>
                        <TrueffortTableHeadCell style={{ ...styleTableContent, width: '80px' }}>
                            {t("reports.finished")}
                        </TrueffortTableHeadCell>
                        <TrueffortTableHeadCell style={{ ...styleTableContent, width: '80px'}}>
                            {configMagnitud == 1 ? t('reports.effort') :
                                configMagnitud == 2 ? t('reports.duration') : t('reports.days_worked')}
                        </TrueffortTableHeadCell>
                        <TrueffortTableHeadCell background="#BDBDBD" sx={{ paddingTop: 0, paddingBottom: 0, width: '210px' }}>
                          <StyledTableRow style={{background:'transparent'}}>
                            <TrueffortTableHeadCell colSpan={3} style={{ ...styleTableContent, ...alignText, width: '33%' }}>
                              <div style={{ padding: 0, fontWeight: 'bold' }}>{t("Productivity")}</div>
                              <div style={{ fontSize: '9px' }}>{configMagnitud == 1 ?
                                t('reports.productivity_description_effort') :
                                t('reports.productivity_description_duration')}</div>
                            </TrueffortTableHeadCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, ...alignText }}>
                              {t("reports.minimum")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, ...alignText }}>
                              {t("reports.average")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, ...alignText }}>
                              {t("reports.maximum")}
                            </TrueffortTableHeadCell>
                          </StyledTableRow>
                        </TrueffortTableHeadCell>
                        <TrueffortTableHeadCell background="#BDBDBD" sx={{ paddingTop: 0, paddingBottom: 0, background: '#EFEFEF', width: '210px' }}>
                          <StyledTableRow style={{background:'transparent'}}>
                            <TrueffortTableHeadCell colSpan={3} style={{ ...styleTableContent, ...alignText, width: '33%' }}>
                              <div style={{ padding: 0, fontWeight: 'bold' }}>{"PDR"}</div>
                              <div style={{ fontSize: '9px' }}>{configMagnitud == 1 ?
                                t('reports.PDR_description_effort') :
                                t('reports.PDR_description_duration')}</div>
                            </TrueffortTableHeadCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, ...alignText }}>
                              {t("reports.minimum")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, ...alignText }}>
                              {t("reports.average")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, ...alignText }}>
                              {t("reports.maximum")}
                            </TrueffortTableHeadCell>
                          </StyledTableRow>
                        </TrueffortTableHeadCell>
                        <TrueffortTableHeadCell background="#BDBDBD" style={{ ...styleTableContent, textAlign: 'center' }}>
                          <div style={{ fontWeight: 'bold' }}>{t("reports.velocity")}</div>
                          <div style={{ fontSize: '9px' }}>{t("reports.productivity_description_duration_1")}</div>
                          <div style={{ fontSize: '9px' }}>{t("reports.productivity_description_duration_2")}</div>
                        </TrueffortTableHeadCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {dataUsersFilter.slice(usersPage * rowsPerPageUsers,
                        usersPage * rowsPerPageUsers + rowsPerPageUsers).map((us, ind) => (
                          <StyledTableRow>
                            <TableCell style={{ width: '230px', padding: '0.2em' }}>{AvatarAndUser(us.name, us?.urlImage, us.isRecentlyActivity)}</TableCell>
                            <TableCell style={{ width: '250px', ...styleTableContent }}>
                              <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                  {us.projects.map((p, ind) => getExtractBar(p.name, ind, ind == 0, (us.countProjects - 1) == ind, us.totalEffort, p.effort))}
                                </div>}
                                {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                  {us.projects.map((p, ind) => getExtractBar(p.name, ind, ind == 0, (us.countProjects - 1) == ind, us.duration.projects, p.duration))}
                                </div>}
                                {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                  {us.projects.map((p, ind) => getExtractBar(p.name, ind, ind == 0, (us.countProjects - 1) == ind, us.durationDaysWorked.projects, p.duration))}
                                </div>}
                                <div style={{ paddingLeft: '0.2em' }}>{t("reports.projects.title")}</div>
                              </TableRow>
                              <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsElementsType.map((g, ind) => getExtractBar(g.name, ind, ind == 0, ind == (us.groupsElementsType.length - 1), us.totalEffort, g.elementsByType.reduce((ac, cu) => ac + cu.effort, 0)))}
                                </div>}
                                {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsElementsType.map((g, ind) => getExtractBar(g.name, ind, ind == 0, ind == (us.groupsElementsType.length - 1), us.duration.elements, g.elementsByType.reduce((ac, cu) => ac + cu.duration, 0)))}
                                </div>}
                                {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsElementsType.map((g, ind) => getExtractBar(g.name, ind, ind == 0, ind == (us.groupsElementsType.length - 1), us.durationDaysWorked.elements, g.elementsByType.reduce((ac, cu) => ac + cu.daysWithEffort, 0)))}
                                </div>}
                                <div style={{ paddingLeft: '0.2em' }}>{t("reports.elements")}</div>
                              </TableRow>
                              <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsPhasesType.map((ph, ind) => getExtractBar(ph.name, ind, ind == 0, ind == (us.groupsPhasesType.length - 1), us.totalEffort, ph.phasesByType.reduce((ac, cu) => ac + cu.effort, 0)))}
                                </div>}
                                {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsPhasesType.map((ph, ind) => getExtractBar(ph.name, ind, ind == 0, ind == (us.groupsPhasesType.length - 1), us.duration.phases, ph.phasesByType.reduce((ac, cu) => ac + cu.duration, 0)))}
                                </div>}
                                {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsPhasesType.map((ph, ind) => getExtractBar(ph.name, ind, ind == 0, ind == (us.groupsPhasesType.length - 1), us.durationDaysWorked.phases, ph.phasesByType.reduce((ac, cu) => ac + cu.daysWithEffort, 0)))}
                                </div>}
                                <div style={{ paddingLeft: '0.2em' }}>{t("reports.phases")}</div>
                              </TableRow>
                              <TableRow style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2em' }}>
                                {configMagnitud == 1 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsTasksType.map((t, ind) => getExtractBar(t.name, ind, ind == 0, ind == (us.groupsTasksType.length - 1), us.totalEffort, t.tasksByType.reduce((ac, cu) => ac + cu.effort, 0)))}
                                </div>}
                                {configMagnitud == 2 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsTasksType.map((t, ind) => getExtractBar(t.name, ind, ind == 0, ind == (us.groupsTasksType.length - 1), us.duration.tasks, t.tasksByType.reduce((ac, cu) => ac + cu.duration, 0)))}
                                </div>}
                                {configMagnitud == 3 && <div style={{ ...styleWorkingOn }}>
                                  {us.groupsTasksType.map((t, ind) => getExtractBar(t.name, ind, ind == 0, ind == (us.groupsTasksType.length - 1), us.durationDaysWorked.tasks, t.tasksByType.reduce((ac, cu) => ac + cu.daysWithEffort, 0)))}
                                </div>}
                                <div style={{ paddingLeft: '0.2em' }}>{t("reports.tasks")}</div>
                              </TableRow>
                            </TableCell>
                            <TableCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.projects.filter(p => p.idStatus == 2).length}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.projects.map(p => p.elements.filter(e => e.idStatus == 2).length)
                                    .reduce((acc, cur) => acc + cur, 0)}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.projects.map(p => p.elements.map(e => e.phases.filter(ph => ph.idStatus == 2).length)
                                    .reduce((acc, cur) => acc + cur, 0)).reduce((ac, cu) => ac + cu, 0)}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.projects.map(p => p.elements.map(e => e.phases.map(ph => ph.tasks.filter(t => t.idStatus == 2).length)
                                    .reduce((acc, cur) => acc + cur, 0)).reduce((ac, cu) => ac + cu, 0)).reduce((a, c) => a + c, 0)}
                                </TableRow>
                              </div>
                            </TableCell>
                            <TableCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.finished.projects}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.finished.elements}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.finished.phases}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {us.finished.tasks}
                                </TableRow>
                              </div>
                            </TableCell>
                            <TableCell style={{ ...styleTableContent, width: '70px' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {configMagnitud == 1 ? (us.effortFinished.projects / 3600).toFixed(2) :
                                    configMagnitud == 2 ? us.durationFinished.projects : us.durationDaysWorkedFinished.projects}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {configMagnitud == 1 ? (us.effortFinished.elements / 3600).toFixed(2) :
                                    configMagnitud == 2 ? us.durationFinished.elements : us.durationDaysWorkedFinished.elements}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {configMagnitud == 1 ? (us.effortFinished.phases / 3600).toFixed(2) :
                                    configMagnitud == 2 ? us.durationFinished.phases : us.durationDaysWorkedFinished.phases}
                                </TableRow>
                                <TableRow style={{ height: '20px', marginBottom: '0.2em' }}>
                                  {configMagnitud == 1 ? (us.effortFinished.tasks / 3600).toFixed(2) :
                                    configMagnitud == 2 ? us.durationFinished.tasks : us.durationDaysWorkedFinished.tasks}
                                </TableRow>
                              </div>
                            </TableCell>
                            <TableCell sx={{ width: '210px' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.productivityEffort.projects.min, us.productivityDuration.projects.min,
                                    us.productivityDurationDaysWorked.projects.min)}
                                  {divColumnData(us.productivityEffort.projects.avg, us.productivityDuration.projects.avg,
                                    us.productivityDurationDaysWorked.projects.avg)}
                                  {divColumnData(us.productivityEffort.projects.max, us.productivityDuration.projects.max,
                                    us.productivityDurationDaysWorked.projects.max)}
                                </div>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.productivityEffort.elements.min, us.productivityDuration.elements.min,
                                    us.productivityDurationDaysWorked.elements.min)}
                                  {divColumnData(us.productivityEffort.elements.avg, us.productivityDuration.elements.avg,
                                    us.productivityDurationDaysWorked.elements.avg)}
                                  {divColumnData(us.productivityEffort.elements.max, us.productivityDuration.elements.max,
                                    us.productivityDurationDaysWorked.elements.max)}
                                </div>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.productivityEffort.phases.min, us.productivityDuration.phases.min,
                                    us.productivityDurationDaysWorked.phases.min)}
                                  {divColumnData(us.productivityEffort.phases.avg, us.productivityDuration.phases.avg, us.productivityDurationDaysWorked.phases.avg)}
                                  {divColumnData(us.productivityEffort.phases.max, us.productivityDuration.phases.max,
                                    us.productivityDurationDaysWorked.phases.max)}
                                </div>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.productivityEffort.tasks.min, us.productivityDuration.tasks.min,
                                    us.productivityDurationDaysWorked.tasks.min)}
                                  {divColumnData(us.productivityEffort.tasks.avg, us.productivityDuration.tasks.avg,
                                    us.productivityDurationDaysWorked.tasks.avg)}
                                  {divColumnData(us.productivityEffort.tasks.max, us.productivityDuration.tasks.max,
                                    us.productivityDurationDaysWorked.tasks.max)}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell sx={{ width: '210px', textAlign: 'center' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.PDREffort.projects.min, us.PDRDuration.projects.min,
                                    us.PDRDurationDaysWorked.projects.min)}
                                  {divColumnData(us.PDREffort.projects.avg, us.PDRDuration.projects.avg,
                                    us.PDRDurationDaysWorked.projects.avg)}
                                  {divColumnData(us.PDREffort.projects.max, us.PDRDuration.projects.max,
                                    us.PDRDurationDaysWorked.projects.max)}
                                </div>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.PDREffort.elements.min, us.PDRDuration.elements.min,
                                    us.PDRDurationDaysWorked.elements.min)}
                                  {divColumnData(us.PDREffort.elements.avg, us.PDRDuration.elements.avg,
                                    us.PDRDurationDaysWorked.elements.avg)}
                                  {divColumnData(us.PDREffort.elements.max, us.PDRDuration.elements.max,
                                    us.PDRDurationDaysWorked.elements.max)}
                                </div>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.PDREffort.phases.min, us.PDRDuration.phases.min,
                                    us.PDRDurationDaysWorked.phases.min)}
                                  {divColumnData(us.PDREffort.phases.avg, us.PDRDuration.phases.avg,
                                    us.PDRDurationDaysWorked.phases.avg)}
                                  {divColumnData(us.PDREffort.phases.max, us.PDRDuration.phases.max,
                                    us.PDRDurationDaysWorked.phases.max)}
                                </div>
                                <div style={{ ...rowData }}>
                                  {divColumnData(us.PDREffort.tasks.min, us.PDRDuration.tasks.min,
                                    us.PDRDurationDaysWorked.tasks.min)}
                                  {divColumnData(us.PDREffort.tasks.avg, us.PDRDuration.tasks.avg,
                                    us.PDRDurationDaysWorked.tasks.avg)}
                                  {divColumnData(us.PDREffort.tasks.max, us.PDRDuration.tasks.max,
                                    us.PDRDurationDaysWorked.tasks.max)}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell style={{ ...styleTableContent, width: '120px' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.projects).toFixed(2)}</div>
                                <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.elements).toFixed(2)}</div>
                                <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.phases).toFixed(2)}</div>
                                <div style={{ ...styleTableContent, ...styleVelocity }}>{(us.velocity.tasks).toFixed(2)}</div>
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                  <Box sx={{
                    display: 'flex', justifyContent: "flex-end",
                    alignItems: "center", marginTop: '1em'
                  }}>
                    <TablePagination
                      labelRowsPerPage={t("elements.pagination.label")}
                      rowsPerPageOptions={[4, 8, 12]}
                      component={"div"}
                      onRowsPerPageChange={handleChangeUsersRowsPerPage}
                      onPageChange={handleChangeUsersPage}
                      count={usersCount}
                      rowsPerPage={rowsPerPageUsers}
                      page={usersPage}
                    />
                  </Box>
                </div>
              </Grid>
            </Card>
          }
        </Grid>
        : <CustomLoader />
      }
    </div>
  );
}

export default GlobalUserViewReport;