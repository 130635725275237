// @flow

import React from "react";

import SiteWrapper from "../SiteWrapper.react";
import {useTranslation} from "react-i18next";
import {Box, Card, CardContent, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import InstancesTable from "../components/instances/InstancesTable";
import InstanceService from "../services/instance.service";
import UserService from "../services/user.service";

function GlobalInstances () {
    const {t} = useTranslation();

    const [numInstances, setNumInstances] = React.useState(0);
    const [activeUsers, setActiveUsers] = React.useState(0);

    const userServices = React.useMemo(() => new UserService(), []);
    const instancesServices = React.useMemo(() => new InstanceService(), []);

    React.useEffect(() => {
        fillNumInstances();
        fillRecentlyActiveUsers();
    },[]);

    const fillRecentlyActiveUsers = () => {
        // @ts-ignore
        userServices.getRecentlyActiveUsers().then(({data: response}) => {
            if (typeof response.data == "number") {
                setActiveUsers(response.data);
            }
        });
    }

    const fillNumInstances = () => {
        // @ts-ignore
        instancesServices.getCount().then(({data: response}) => {
            if (typeof response.data == "number") {
                setNumInstances(response.data)
            }
        });
    }

    return (
        <SiteWrapper>
            <Container maxWidth='xl' sx={{ marginTop: '20px' }} >
                <Box sx={{display: "flex"}}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant='h4' >
                            {t("Instances")}
                        </Typography>
                    </Box>
                </Box>
            </Container>

            <Container maxWidth='xl' >
                <Paper elevation={0} sx={{ padding: '12px', bgcolor: '#F5F5F5' }} >
                    <Grid container direction="row" alignItems="stretch" spacing={1} >
                        <Grid item xs={12} sm={6} >
                            <Card elevation={1} sx={{ height: '100%' }} >
                                <CardContent >
                                    <Grid container spacing={2} direction='column' justifyContent="center" alignItems="center" >
                                        <Grid item xs >
                                            <Typography color='#050536' textAlign='center' >
                                                {t("instances_page.total_customers")}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs >
                                            <Typography variant='h4' color='#050536' textAlign='center' >
                                                {numInstances}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Card elevation={1} sx={{ height: '100%' }} >
                                <CardContent >
                                    <Grid container spacing={2} direction='column' justifyContent="center" alignItems="center" >
                                        <Grid item xs >
                                            <Typography color='#050536' textAlign='center' >
                                                {t("instances_page.online_users")}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs >
                                            <Typography variant='h4' color='#050536' textAlign='center' >
                                                {activeUsers}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>

            <InstancesTable />
        </SiteWrapper>
    );
}

export default GlobalInstances;
