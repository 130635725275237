import { Grid, Paper, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  List as ListIcon,
  AutoAwesomeMosaic as AutoAwesomeMosaicIcon} from "@mui/icons-material";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import ProjectsReportByStatusTab from "./ProjectsReportByStatusTab";
import ReportProjectsTab from "./ReportProjectsTab";

function GlobalReportProjects({startDate, endDate, valuesSelectedUsers, valuesSelectedProjects, 
  inputSearch, showLoader, indexTab, handleChangeIndexTab, valuesStatusSelect}) {
    //console.log(valuesSelectedUsers);
  const { user } = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const { t } = useTranslation();

  return (
    <div>
      <Paper elevation={0} sx={{ padding: '12px', bgcolor: showLoader ? 'transparent' : '#F5F5F5', marginTop: '1em', width: '98%' }} >
      {!showLoader &&
            <Grid container spacing={1} xs={12}>
              <Grid id={"idTabsUsers"} item xl={10} md={10} sm xs={12}>
                <Tabs
                  variant='scrollable'
                  scrollButtons='auto'
                  value={indexTab}
                  onChange={handleChangeIndexTab}
                >
                  {features.globalReportProjectsStatus && <Tab id="idTabGlobal" sx={{ minHeight: '0px' }}
                    icon={<ListIcon />} iconPosition="start"
                    label={t("reports.global_view")} value={0} />}
                  {features.reportProjectsEvaluation && <Tab id="idTabInProgress" sx={{ minHeight: '0px' }}
                    icon={<AutoAwesomeMosaicIcon />} iconPosition="start"
                    label={"Proyectos"} value={1} />}
                </Tabs>
              </Grid>
            </Grid>
          }
          {indexTab == 0 && 
            <ReportProjectsTab 
              startDate={startDate}
              endDate={endDate}
              inputSearch={inputSearch}
              valuesSelectedUsers={valuesSelectedUsers}
              valuesSelectedProjects={valuesSelectedProjects}
            />
          }
          {indexTab == 1 && 
            <ProjectsReportByStatusTab 
              valuesSelectedUsers={valuesSelectedUsers}
              valuesSelectedProjects={valuesSelectedProjects}
              valuesStatusSelect={valuesStatusSelect}
              inputSearch={inputSearch}
            />
          }
      </Paper>
    </div>
  );
}

export default GlobalReportProjects;