// @flow
import React from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {Tabs, Tab, Container, Box} from "@mui/material";
import SiteWrapper from "../SiteWrapper.react";
import ProfileCardTRUE from "../components/profile/ProfileCardTRUE";
//import WorkstationCardTRUE from "../components/profile/WorkstationCardTRUE";
import {useTranslation} from "react-i18next";
//import LicencesCardTRUE from "../components/profile/LicencesCardTRUE";
//import {useAuthContext} from "../contexts/AuthenticationContext";
import PaymentHistoryTabTRUE from "../components/subscription/PaymentHistoryTabTRUE";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import { MIN_LEVEL_ADMIN } from "../util/Constants";
import { useAuthContext } from "../contexts/AuthenticationContext";

const stripePromise = loadStripe(localStorage.getItem("PK_STRIPE"));

function Profile() {
    const {user} = useAuthContext();
    const {t} = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <SiteWrapper>
            <Container className="background-gray" sx={{paddingBottom: "1rem"}}>
                <Tabs key="Profile" value={value} onChange={handleChange}>
                        <Tab label={t("Profile")} />
                        {user.role.accessLevel >= MIN_LEVEL_ADMIN && 
                            <Tab label={t("payment.plans_payment")} />
                        }
                </Tabs>
                <Box>
                    {value == 0 &&
                        <ProfileCardTRUE/>
                    }
                    {value == 1 &&
                        <Elements stripe={stripePromise}>
                            <PaymentHistoryTabTRUE />
                        </Elements>
                    }
                </Box>
            </Container>
        </SiteWrapper>
    );
}

export default Profile;
