import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'tabler-react';
import {
  Box, Paper, Select, MenuItem, Typography, Grid, Stack, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Card, CardContent, Menu, SvgIcon, IconButton, Tooltip
} from '@mui/material';
import SiteWrapper from '../SiteWrapper.react';
import { useLocation } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ProjectTypeService from '../services/projectType.service';
import ElementTypeService from '../services/elementType.service';
import useMediaQuery from "@mui/material/useMediaQuery";
import infoJiraProjectsService from '../services/infoJiraProjects.service';
import ProjectService from '../services/project.service';
import { toast } from 'react-toastify';
import { TrueffortButton, TrueffortChip, TrueffortNavButton } from '../MUIstyle/MUICustom';
import { Dashboard } from '@mui/icons-material';
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { CircularProgress } from '@mui/material';
import CustomIconTrueffortScreen from "../components/custom/CustomIconTrueffortScreen";
import {KeyboardArrowDown, CheckCircle as CheckCircleIcon} from '@mui/icons-material';
import ProjectElementService from "../services/projectElement.service";
import infoAzureProjectsService from "../services/InfoAzureProject.service";
import APITaskTableRow from "../components/jira/APITaskTableRow";
import EstimationsService from "../services/estimations.service";
import UserService from "../services/user.service";
import ProjectDetailUserCard from "../components/projectDetail/ProjectDetailUserCard";
import CachedIcon from '@mui/icons-material/Cached';
import TaskStateService from "../services/taskState.service";
import { useAuthContext } from '../contexts/AuthenticationContext';

const APIProjectsInfo = () => {
  const location = useLocation();
  const { selectedAPI, projectId, projectName, organizationDomain, email, token, trueffortId } = location.state || {};
  const {user} = useAuthContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [projectTypes, setProjectTypes] = React.useState([]);
  const [idSelectedProjectType, setIdSelectedProjectType] = React.useState("");
  const [selectedProjectType, setSelectedProjectType] = React.useState({});
  const [elementTypes, setElementTypes] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [estimationUpdateNeeded, setEstimationUpdateNeeded] = useState(true);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [projectElements, setProjectElements] = useState([]);
  const [syncedElements, setSyncedElements] = useState([]);
  const [estimation, setEstimation] = useState({});
  const [selectedStatuses, setSelectedStatuses] = React.useState([]);
  const [statusFromTasks, setStatusFromTasks] = React.useState([]);
  const [selectedSprints, setSelectedSprints] = React.useState([]);
  const [sprints, setSprints] = React.useState([]);
  const [sprintsFromTasks, setSprintsFromTasks] = React.useState([]);
  const [durationInputErrorMessage, setDurationInputErrorMessage] = useState("");
  const [totalDuration, setTotalDuration] = useState(0);
  const [isUsersLoaded, setIsUsersLoaded] = useState(false);

  const [rankedUsers, setRankedUsers] = React.useState([]);
  const [notrankedUsers, setNotRankedUsers] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const [menuNumber, setMenuNumber] = React.useState(0);
  const [anchorElemFilter, setAnchorElemFilter] = React.useState(null);
  const openOptions = Boolean(anchorElemFilter);

  const projectTypeService = React.useMemo(() => new ProjectTypeService(), []);
  const elementTypeService = React.useMemo(() => new ElementTypeService(), []);
  const projectElementService = React.useMemo(() => new ProjectElementService(), []);
  const estimationsService = React.useMemo(() => new EstimationsService(), []);
  const userService = React.useMemo(() => new UserService(), []);
  const taskStateService = React.useMemo(() => new TaskStateService(), []);

  const minEstimatedDays = projectElements.length === 0 ? 1 :
      projectElements.reduce(
          (acc: number, element: any): boolean => acc < element.estimatedDays ? element.estimatedDays : acc,
          0
      );
  const maxEstimatedDays = projectElements.length === 0 ? 1 :
      projectElements.reduce((acc: number, element: any): number => acc + element.estimatedDays, 0);
  const totalHours = projectElements.reduce((acc: number, element: any): number => acc + element.estimatedTime, 0);

  const fillUsers = () => {
    // @ts-ignore
    userService.getAll().then(({ data: response }) => {
      if (Array.isArray(response)) {
        const arrUsersEnabled = response.filter((data) => data.isEnabled);
        setUsers(arrUsersEnabled);
        setIsUsersLoaded(true);
      }
    }).catch((error: any) => {
      console.error("Error fetching users: ", error);
      setIsUsersLoaded(false);
    });
  };


  React.useEffect(() => {
    fillUsers();
  }, []);

  useEffect(() => {
    /*console.log("SELECTED API", selectedAPI);
    console.log("PROJECT ID", projectId);
    console.log("PROJECT NAME", projectName);
    console.log("ORGANIZATION DOMAIN", organizationDomain);
    console.log("EMAIL", email);
    console.log("TOKEN", token);
    console.log("TRUEFFORT ID", trueffortId);*/

    if (isUsersLoaded && projectId && projectName) {
      setSelectedProjectName(projectName);
      fillProjectTypes();
      fetchProjectTasks(organizationDomain, email, token, projectId);
      getProjectSprints();
      updateUserLists();
    }

  }, [isUsersLoaded]);


  const updateUserLists = () => {
    const assignedUserIds = projectElements.map((elem: any) => elem.assignedUser);
    setRankedUsers(users.filter((elem: any) => assignedUserIds.includes(users.idUser)));
    setNotRankedUsers(users.filter((elem: any) => !assignedUserIds.includes(users.idUser)));
  };

  const deleteUser = (usr: any, elemId: any) => {
    const updatedProjectElements = projectElements.map((elem: any) => {
      if (elem.apiID === elemId && elem.assignedUser === usr.idUser) {
        return { ...elem, assignedUser: "" };
      }
      return elem;
    });
    setProjectElements(updatedProjectElements);
    updateUserLists();
  };

  // Utiliza el hook useEffect para llamar a la función fillElementTypes cada vez que cambia el estado idSelectedProjectType
  React.useEffect(() => {
    fillElementTypes();
  }, [idSelectedProjectType]);

  useEffect(() => {
    setProjectElements(projectElements.map((pe:any) => {return {...pe, idElementType: elementTypes.length == 1 ? elementTypes[0].idElementType : ''}}));
  }, [elementTypes]);

  // Función para obtener todos los tipos de proyecto utilizando el servicio projectTypeService y actualizar el estado projectTypes
  const fillProjectTypes = () => {
    projectTypeService.getAll().then(({ data: response }: any) => {
      if (Array.isArray(response.data)) {
        setProjectTypes(response.data);

        if (response.data.length > 0) {
          let projectType = response.data.find((p:any) => p.name == user.baseTemplateName);
          if (projectType) {
            setIdSelectedProjectType(projectType.idProjectType);
            setSelectedProjectType(projectType);
          } else {
            setIdSelectedProjectType(response.data[0].idProjectType);
            setSelectedProjectType(response.data[0]);
          }
        }
      }
    });
  }

  const fetchProjectTasks = async (organizationDomain: any, email: any, token: any, projectId: any) => {
    try {
      setIsLoading(true); // Indicar que se están cargando los datos

      let response;
      if (selectedAPI === "Jira REST API") {
        response = await infoJiraProjectsService.getProjectDetails(organizationDomain, email, token, projectId);
        //console.log("JIRA T", response.data.issues);
        manageJiraIssues(response);
      } else if (selectedAPI === "Azure REST API") {
        response = await infoAzureProjectsService.getWorkItemDetails(organizationDomain, token, projectId);
        //console.log("AZURE T", response.details.value);

        manajeAzureWorkItems(response);
      }
    } catch (error) {
      // Manejar el error aquí
      console.error("Error fetching project tasks", error);
    } finally {
      setIsLoading(false); // Finalizar la carga, independientemente del resultado
    }
  };

  const manageJiraIssues = async (response: any) => {
    let filteredIssues = [];
    if (trueffortId !== -1) { // Si el proyecto ya está registrado en Trueffort, filtrar los elementos ya guardados
      const trueffortElems = await projectElementService.getByIdJiraProject(projectId);
      filteredIssues = response.data.issues.filter((issue: any) => !isElmentInTrueffort(issue.id, trueffortElems.data.data));
      setSyncedElements(
          response.data.issues
              .filter((issue: any) => isElmentInTrueffort(issue.id, trueffortElems.data.data))
              .map((issue: any) => ({
                apiID: issue.id,
                name: issue.fullTitle,
                trueffortStatus: trueffortElems.data.data.find((elem: any) => elem.idToolTask === issue.id).projectElement.status,
                status: issue.fields.status.name,
                sprints: issue.fields.customfield_10020 ? issue.fields.customfield_10020.map((sprint:any) => sprint.name) : []
              }))
      );
    } else {
      filteredIssues = response.data.issues;
    }
    // Obtener los estatus
    // @ts-ignore
    let statusFromTasksAux = [];
    // @ts-ignore
    let sprintsFromTasksAux = [];
    filteredIssues.map((issue: any) => {
      // @ts-ignore
      var findItem = statusFromTasksAux.find((x: any) => x === issue.fields.status.name);
      // @ts-ignore
      if (!findItem) {
        statusFromTasksAux.push(issue.fields.status.name);
      }
      const sprintField = issue.fields.customfield_10020;
      if (sprintField && Array.isArray(sprintField)) {
        sprintField.forEach((sprint: any) => {
          // @ts-ignore
          if (!sprintsFromTasksAux.includes(sprint.name)) {
            sprintsFromTasksAux.push(sprint.name);
          }
        });
      }
    });
    // @ts-ignore
    setStatusFromTasks(statusFromTasksAux);
    // @ts-ignore
    setSprintsFromTasks(sprintsFromTasksAux);
    //console.log(elementTypes);
    const elements = filteredIssues.map((issue: any) => (
      {
        apiID: issue.id,
        name: issue.fullTitle,
        status: issue.fields.status.name,
        creationDate: issue.fields.created,
        idElementType: "",
        estimatedDays: 1,
        estimatedTime: 1,
        durationMax: 0,
        durationMin: 0,
        effortMin: 0,
        effortMax: 0,
        idSize: 2,
        idComplexity: 2,
        sprints: issue.fields.customfield_10020 ? issue.fields.customfield_10020.map((sprint:any) => sprint.name) : [],
        assignedUser: issue.fields.assignee && issue.fields.assignee.emailAddress
            ? (users.find((u: any) => u.email === issue.fields.assignee.emailAddress)?.idUser || '')
            : ''
      }
    ));
    setProjectElements(elements);
    //setTotalHours(elements.reduce((acc: number, elem: any) => acc + elem.estimatedTime, 0));

    let maxDuration = elements.reduce((acc: number, elem: any) => acc + elem.estimatedDays, 0);
    let minDuration = elements.reduce(
        (acc: number, elem: any) => acc < elem.estimatedDays ? elem.estimatedDays : acc, 0
    );
    /*setMaxEstimatedDays(maxDuration);
    setMinEstimatedDays(minDuration);*/
    setTotalDuration(Math.round((maxDuration + minDuration) / 2));
  }

  const manajeAzureWorkItems = async (response: any) => {
    if (response.message) {
      // Si no hay tareas, muestra el mensaje y limpia las listas de tareas
      //console.log(response.message);
      setStatusFromTasks([]);
      setProjectElements([]);
    } else {
      const { details } = response;
      let filteredTasks: any = [];

      if (trueffortId !== -1) {
        const trueffortElems = await projectElementService.getByIdJiraProject(projectId);
        //console.log("TRUEFFORT ELEMS AZURE", trueffortElems);
        filteredTasks = details.value.filter((item: any) => !isElmentInTrueffort(item.id + "", trueffortElems.data.data));
        setSyncedElements(
          details.value
            .filter((item: any) => isElmentInTrueffort(item.id + "", trueffortElems.data.data))
            .map((workItem: any) => ({
              apiID: workItem.id,
              name: workItem.fullTitle,
              trueffortStatus: trueffortElems.data.data.find((elem: any) => elem.idToolTask === (workItem.id + "")).projectElement.status,
              status: workItem.fields["System.State"],
              type: workItem.fields["System.WorkItemType"],
              sprints: workItem.fields["System.IterationPath"].split('\\').pop()
            }))
        );
      } else {
        filteredTasks = details.value;
      }

      // @ts-ignore
      let statusFromTasksAux = [];
      // @ts-ignore
      let sprintsFromTasksAux = [];
      filteredTasks.forEach((item: any) => {
        const state = item.fields["System.State"];
        // @ts-ignore
        if (!statusFromTasksAux.find((x) => x === state)) {
          statusFromTasksAux.push(state);
        }
        const sprintField = item.fields["System.IterationPath"];
        const sprintName = sprintField.split('\\').pop();
        // @ts-ignore
        if (!sprintsFromTasksAux.find((x) => x === sprintName)) {
          sprintsFromTasksAux.push(sprintName);
        }

      });
      // @ts-ignore
      setStatusFromTasks(statusFromTasksAux);
      // @ts-ignore
      setSprintsFromTasks(sprintsFromTasksAux);
      //console.log(filteredTasks);
      //console.log(elementTypes);
      const elements = filteredTasks.map((workItem: any) => (
        {
          apiID: workItem.id,
          name: workItem.fullTitle,
          status: workItem.fields["System.State"],
          creationDate: workItem.fields["System.CreatedDate"],
          idElementType: "",
          estimatedDays: 1,
          estimatedTime: 1,
          durationMax: 0,
          durationMin: 0,
          effortMin: 0,
          effortMax: 0,
          idSize: 2,
          idComplexity: 2,
          sprints: workItem.fields["System.IterationPath"].split('\\').pop(),
          assignedUser: workItem.fields["System.AssignedTo"] && workItem.fields["System.AssignedTo"].uniqueName
              ? (users.find((u: any) => u.email === workItem.fields["System.AssignedTo"].uniqueName)?.idUser || '')
              : ''
        }
      ));
      setProjectElements(elements);
      //setTotalHours(elements.reduce((acc: number, elem: any) => acc + elem.estimatedTime, 0));

      let maxDuration = elements.reduce((acc: number, elem: any) => acc + elem.estimatedDays, 0);
      let minDuration = elements.reduce(
        (acc: number, elem: any) => acc < elem.estimatedDays ? elem.estimatedDays : acc, 0
      );
      /*setMaxEstimatedDays(maxDuration);
      setMinEstimatedDays(minDuration);*/
      setTotalDuration(Math.round((maxDuration + minDuration) / 2));
    }
  }

  //console.log("ELEMENTOS", projectElements);
  //console.log(elementTypes);
  const fillElementTypes = () => {
    // Verifica si no se ha seleccionado ningún tipo de proyecto
    if (idSelectedProjectType === "") {
      // Si no se ha seleccionado, establece los tipos de elementos como una matriz vacía y devuelve
      setElementTypes([]);
      return;
    }
    //console.log("elementTypes");
    // Llama al servicio para obtener todos los tipos de elemento asociados al tipo de proyecto seleccionado
    elementTypeService.getAllByProjectType(idSelectedProjectType).then(({ data: response }: any) => {
      // Verifica si la respuesta es una matriz
      if (Array.isArray(response.data)) {
        // Si es una matriz, establece los tipos de elementos con la respuesta obtenida
        setElementTypes(response.data);
      }
    }).finally(() => {
      //fetchProjectTasks(organizationDomain, email, token, projectId);
      //getProjectSprints();
    });
  }

  const getProjectSprints = () => {
    switch (selectedAPI) {
      case "Jira REST API":
        getJiraProjectSprints();
        break;

      case "Azure REST API":
        getAzureProjectSprints();
        break;
    }
  }

  const getJiraProjectSprints = () => {
    // PARA OBTENER LOS SPRINTS PRIMERO SE TIENEN QUE OBTENER LOS BOARDS
    infoJiraProjectsService.getProjectBoards(organizationDomain, email, token, projectId).then(({ data: response }: any) => {
      //console.log("BOARDS", response);
      if (typeof response.values != "undefined" && Array.isArray(response.values)) {
        response.values.map((b: any) => {
          // Consultar sprints del board
          infoJiraProjectsService.getBoardSprints(organizationDomain, email, token, b.id).then(({data: response}: any) => {
            //console.log("SP", response);
            if (Array.isArray(response.values)) {
              setSprints(response.values);
            }
          }).catch(error => {
            if (error.response.status === 400) {
              console.log("El tablero no soporta sprints");
            }
          });
        });
      }
    });
  }

  const getAzureProjectSprints = () => {
    infoAzureProjectsService.getBoardSprints(organizationDomain, token, projectId).then(({ data: response }) => {
      //console.log("SP", response);
      if (Array.isArray(response.value)) {
        const mappedSprints = response.value.map((sprint: any) => ({
          id: sprint.id,
          name: sprint.name
        }));
        //console.log("SPRINTS", mappedSprints);
        setSprints(mappedSprints);  // Actualizar el estado con los sprints mapeados
      } else {
        console.log("No sprints found");
      }
    }).catch(error => {
      console.error("Error fetching sprints", error);
    });
  }

  const isElmentInTrueffort = (keyTaskJira: string, trueffortElems: any) => {
    if (trueffortElems.length > 0 && typeof keyTaskJira != "undefined") {
      let exists = trueffortElems.find((e: any) => e.idToolTask === keyTaskJira);
      return typeof exists != "undefined";
    } else {
      return false;
    }
  }

  const handleChangeProjectTypeSelect = (id: any) => {
    // Establece el tipo de proyecto seleccionado
    setIdSelectedProjectType(id);
    // Busca el tipo de proyecto seleccionado en la lista de tipos de proyecto y lo establece como seleccionado
    projectTypes.forEach((p: any) => {
      if (p.idProjectType == id) {
        setSelectedProjectType(p);
        setEstimationUpdateNeeded(true);
      }
    });

    for (let i: number = 0; i < projectElements.length; i++) {
      projectElements[i].idElementType = "";
    }
  }
  const getWorkItemParents = () => {
    switch (selectedAPI) {
      case "Jira REST API":
        break;

      case "Azure REST API":
        getItemParentsAzure();
        break;
    }
  }

  const getItemParentsAzure = () => {
    try {
      const response = infoAzureProjectsService.getWorkItemParents(organizationDomain, token, projectId);
      //console.log("WorkItemsParents", response);
    } catch (error) {
      console.error("Error al obtener los WorkItemParents:", error);
    }
  }

  const getParents = () => {
    switch (selectedAPI) {
      case "Jira REST API":
        break;

      case "Azure REST API":
        getParentsAzure();
        break;
    }
  }

  const getParentsAzure = () => {
    infoAzureProjectsService.getParents(organizationDomain, token, projectId).then(({ data: response }) => {
    //console.log("Parents", response);

    });
  }

  const adjustDurationEstimates = (projectElement: any, estimationElement: any) => {
    let durationMin: number = 0;
    let durationMax: number = 0;
    for (let i: number = 0; i < estimationElement.phasetypes.length; i++) {
      let phaseDurationMax: number = estimationElement.phasetypes[i].tasktypes.reduce(
          (acc: number, tasktype: any) => acc + tasktype.duration_avg,
          0
      );
      let phaseDurationMin: number = estimationElement.phasetypes[i].tasktypes.reduce(
          (acc: number, tasktype: any) => acc < tasktype.duration_avg ? tasktype.duration_avg : acc,
          0
      );
      let phaseDurationAverage: number = Math.round((phaseDurationMax + phaseDurationMin) / 2);
      durationMax += phaseDurationAverage;
      durationMin = durationMin < phaseDurationAverage ? phaseDurationAverage : durationMin;
    }

    projectElement.estimatedDays = Math.round((durationMin + durationMax) / 2);
    projectElement.durationMax = durationMax;
    projectElement.durationMin = durationMin;
  }

  const getEstimate = () => {
    let missingElementTypes: boolean = projectElements.filter((elem: any): boolean => elem.idElementType === "").length > 0;
    if (missingElementTypes) {
      toast.error("Debes seleccionar un tipo de elemento para todos los elementos");
      return;
    }

    const projectElementsTypes = projectElements.map((elem: any) => (
      {
        "id": elem.idElementType,
        "id_size": elem.idSize,
        "id_complexity": elem.idComplexity
      }
    ));
    const elementTypesSet = new Set(projectElementsTypes.map(JSON.stringify));

    const datos = {
      "project_types": [idSelectedProjectType],
      "element_types": Array.from(elementTypesSet).map((elem: any) => JSON.parse(elem))
    }

    //console.log("DATOS ESTIMACION", datos);

    // @ts-ignore
    estimationsService.getAccumulatedEstimate(datos).then(({data: response}) => {
      if (response.status === "warning") {
        toast.error(t(response.code));
      } else {
        const estimationResponse = response.projecttypes[0];
        const projectElementsCopy = JSON.parse(JSON.stringify(projectElements));

        //console.log("ESTIMATION RESPONSE", estimationResponse);

        for (let i: number = 0; i < projectElementsCopy.length; i++) {
          const elementTypeInfo = estimationResponse.elementtypes
              .find((elemType: any) =>
                  elemType.id === projectElementsCopy[i].idElementType
              );
          const elemTypeDifficulty = elementTypeInfo.dificulties
              .find((difficulty: any) =>
                  difficulty.size_id === projectElementsCopy[i].idSize
                  && difficulty.complexity_id === projectElementsCopy[i].idComplexity
              );
          projectElementsCopy[i].estimatedTime = elemTypeDifficulty.effort_avg;
          projectElementsCopy[i].effortMin = elemTypeDifficulty.effort_min;
          projectElementsCopy[i].effortMax = elemTypeDifficulty.effort_max;
          adjustDurationEstimates(projectElementsCopy[i], elemTypeDifficulty);
          //projectElementsCopy[i].estimatedDays = adjustDurationEstimates(elemTypeDifficulty);
          //projectElementsCopy[i].estimatedDays = elemTypeDifficulty.duration_avg;
        }

        let minDuration = projectElementsCopy.reduce(
            (acc: number, element: any): boolean => acc < element.estimatedDays ? element.estimatedDays : acc,
            0
        );
        let maxDuration = projectElementsCopy.reduce((acc: number, element: any): number => acc + element.estimatedDays, 0);
        setTotalDuration(Math.round((maxDuration + minDuration) / 2));
        setProjectElements(projectElementsCopy);
        setEstimation(estimationResponse);
        setEstimationUpdateNeeded(false);
      }
    }).catch(function (error: any) {
      console.log("ERROR ESTIMACION", error);
      toast.error("Ocurrió un problema al ejecutar la estimación (" + error + ")");
    });
  }

  const handleSaveButtonClick = () => {
    setIsSaving(true); // Actualizar el estado isSaving a true
    // Agrupar las filas por tipo de elemento
    const rowsByElementType = projectElements.reduce((acc: any, row: any) => {
      const elementTypeId = row.idElementType;
      if (!acc[elementTypeId]) {
        acc[elementTypeId] = [];
      }
      acc[elementTypeId].push(row);
      return acc;
    }, {});

    // Crear un objeto elementTypes para cada grupo
    const elementTypesData = Object.entries(rowsByElementType).map(([elementTypeId, rows]: any[]) => ({
      idElementType: parseInt(elementTypeId),
      projectElements: rows,
    }));

    for (let i: number = 0; i < elementTypesData.length; i++) {
      for (let j: number = 0; j < elementTypesData[i].projectElements.length; j++) {
        // elementTypesData[i].projectElements[j].projectPhases = elementTypes
        //     .find((elemType: any) => elemType.idElementType === elementTypesData[i].idElementType)
        //     .phaseTypes.map((phase: any) => (
        //const foundElementType = elementTypes.find((elemType: any) => elemType.idElementType === elementTypesData[i].idElementType);
        const foundElementType = estimation.elementtypes.find((elemType: any) => elemType.id === elementTypesData[i].idElementType);
        const foundElementTypeDifficulty = foundElementType.dificulties.find(
            (elemInfo: any) =>
                elemInfo.size_id === elementTypesData[i].projectElements[j].idSize
                && elemInfo.complexity_id === elementTypesData[i].projectElements[j].idComplexity
        );
        if (foundElementTypeDifficulty && foundElementTypeDifficulty.phasetypes) {
          elementTypesData[i].projectElements[j].projectPhases = foundElementTypeDifficulty.phasetypes.map((phase: any, index: number) => {
            let durationMax: number = phase.tasktypes.reduce(
                (acc: number, tasktype: any) => acc + tasktype.duration_avg,
                0
            );
            let durationMin: number = phase.tasktypes.reduce(
                (acc: number, tasktype: any) => acc < tasktype.duration_avg ? tasktype.duration_avg : acc,
                0
            );
            let effort: number = Math.floor(
                elementTypesData[i].projectElements[j].estimatedTime
                / foundElementTypeDifficulty.phasetypes.length
            );
            if (index === 0) {
              effort += elementTypesData[i].projectElements[j].estimatedTime
                  % foundElementTypeDifficulty.phasetypes.length;
            }

            return ({
              idPhaseType: phase.id,
              estimatedDays: Math.round((durationMin + durationMax) / 2),
              estimatedTime: effort,
              projectTasks: phase.tasktypes.map((taskType: any, taskIndex: number) => ({
                idTaskType: taskType.id,
                estimatedTime: taskIndex === 0 ?
                    Math.floor(effort / phase.tasktypes.length) + (effort % phase.tasktypes.length) :
                    Math.floor(effort / phase.tasktypes.length),
                estimatedDays: taskType.duration_avg,
                assignedUsers: projectElements.find((element: any) => element.apiID === elementTypesData[i].projectElements[j].apiID)?.assignedUser
                    ? [projectElements.find((element: any) => element.apiID === elementTypesData[i].projectElements[j].apiID).assignedUser]
                    : []
              }))
            })
          });
        } else {
          console.error(`Element type with id ${elementTypesData[i].idElementType} not found or has no phaseTypes`);
        }
      }
    }

    // Asignación de los datos para guardar en el proyecto General
    const dataToSave = {
      idProjectType: idSelectedProjectType,
      name: selectedProjectName,
      //jiraKey: projectKey,
      //toolName: "JIRA",
      toolId: selectedAPI.split(" ")[0] === "Jira" ? 1 : 2,
      toolDomain: organizationDomain,
      toolProjectId: projectId,
      idsLeader: [],
      idMeasurementStandard: null,
      estimatedDays: totalDuration,
      estimatedTime: totalHours,
      elementTypes: elementTypesData,
    };

    //console.log(dataToSave);
    let bandera = false;
    bandera = dataToSave.elementTypes.filter((e:any)=> e.idElementType == undefined || e.idElementType==null || isNaN(e.idElementType)).length>0;
    if(bandera){
      toast.error("Debes seleccionar un tipo de elemento para todos los elementos");
      setIsSaving(false);
    
      return;
    }
    // Crear una instancia del servicio de proyecto
    const projectService = new ProjectService();

    // Guardar los datos a través de la API
    if (trueffortId !== -1) {
      projectService.updatePlan(trueffortId, dataToSave).then(({ data: response }) => {
        // Manejar la respuesta de la API
        if (response.internalError != null && response.internalError && response.message != null) {
          toast.error(response.message);
        } else {
          // Si no hay errores, mostrar un mensaje de éxito y redirigir a la página de detalles del proyecto
          toast.success(t("estimation_page.msg_success_save"), {
            onClose: () => window.location.replace("/projectDetail?idProject=" + response.data)
          });
        }
      }).catch((error) => {
        // Manejar errores de la API
        toast.error('Ocurrió un error al enviar los datos al back-end.');

      }).finally(()=>{
        setIsSaving(false);
      });
    } else {
      projectService.createPlan(dataToSave).then(({ data: response }) => {
        // Manejar la respuesta de la API
        if (response.internalError != null && response.internalError && response.message != null) {
          toast.error(response.message);
        } else {
          // Si no hay errores, mostrar un mensaje de éxito y redirigir a la página de detalles del proyecto
          toast.success(t("estimation_page.msg_success_save"), {
            onClose: () => window.location.replace("/projectDetail?idProject=" + response.data)
          });
        }
      }).catch((error) => {
        // Manejar errores de la API
        toast.error('Ocurrió un error al enviar los datos al back-end.');

      }).finally(()=>{
        setIsSaving(false);
      });
    }
  };
  
  const updateJiraState = (id: number, transition: any) => {
    const datos = {
      issueId: id,
      transition: transition
    };

    infoJiraProjectsService.updateIssueStatus(organizationDomain, email, token, datos).then(({ data: response }) => {
      if (response.success) {
        fetchProjectTasks(organizationDomain, email, token, projectId);
        getProjectSprints();
        updateUserLists();

        toast.success(t("Herramienta.jira_state_updated"));
      } else {
        toast.error(t("Herramienta.jira_state_update_error"));
      }
    }).catch((error: any) => {
      toast.error(t("Herramienta.jira_state_update_error"));
    });
  }

  const updateAzureState = (id: number, newState: string) => {
    const datos = {
      workitemId: id,
      stateName: newState
    };
    infoAzureProjectsService.updateWorkitemState(organizationDomain, token, projectId, datos).then(({ data: response }) => {
      if (response.success) {
        fetchProjectTasks(organizationDomain, email, token, projectId);
        getProjectSprints();
        updateUserLists();

        toast.success(t("Herramienta.azure_state_updated"));
      } else {
        toast.error(t("Herramienta.azure_state_update_error"));
      }
    }).catch((error: any) => {
      toast.error(t("Herramienta.azure_state_update_error"));
    });
  }

  const syncJiraState = async (id: number, trueffortStatus: any, state: string) => {
    try {
      const responseJira = await infoJiraProjectsService.getIssueTransitions(organizationDomain, email, token, id);
      const responseStatusInt = await taskStateService.getStatusInteroperability(1, trueffortStatus.idStatus);

      if (responseJira.data.success && responseStatusInt.data.success) {
        if (responseStatusInt.data.data === null) {
          toast.error(t("Herramienta.no_compatible_jira_state"));
          setIsSaving(false);

          return;
        }

        let statusCategoryName = responseStatusInt.data.data.statusInteroperability;
        const transitions = responseJira.data.data.transitions;
        const transition = transitions.find(
            (trans: any) => trans.to.statusCategory.name === statusCategoryName
        );

        if (transition === undefined) {
          toast.error(t("Herramienta.no_compatible_jira_state"));
        } else if (transition.name === state) {
          toast.warning(t("Herramienta.state_already_synced"));
        } else {
          updateJiraState(id, transition);
        }
      } else {
        toast.error(t("Herramienta.jira_state_update_error"));
      }
    } catch (error) {
      toast.error(t("Herramienta.jira_state_update_error"));
    }

    setIsSaving(false);
  }

  const syncAzureState = async (id: number, type: string, trueffortStatus: any, state: string) => {
    try {
      const response = await infoAzureProjectsService.getWorkitemsStates(organizationDomain, token, projectId, type);

      if (response.data.success) {
          const states = response.data.data.value;

          switch (trueffortStatus.name) {
            case 'Creada':
              const proposedState = states.filter((estado: any) => estado.category === "Proposed");

              if (proposedState.length === 0) {
                toast.error(t("Herramienta.no_compatible_azure_state"));
              } else if (proposedState.findIndex((estado: any) => estado.name === state) === -1) {
                updateAzureState(id, proposedState[0].name);
              } else {
                toast.warning(t("Herramienta.state_already_synced"));
              }

              break;

            case 'En Proceso':
              const inProgressState = states.filter((estado: any) => estado.category === "InProgress");

              if (inProgressState.length === 0) {
                toast.error(t("Herramienta.no_compatible_azure_state"));
              } else if (inProgressState.findIndex((estado: any) => estado.name === state) === -1) {
                updateAzureState(id, inProgressState[0].name);
              } else {
                toast.warning(t("Herramienta.state_already_synced"));
              }

              break;

            case 'Terminada':
              const completedState = states.filter((estado: any) => estado.category === "Completed");

              if (completedState.length === 0) {
                toast.error(t("Herramienta.no_compatible_azure_state"));
              } else if (completedState.findIndex((estado: any) => estado.name === state) === -1) {
                updateAzureState(id, completedState[0].name);
              } else {
                toast.warning(t("Herramienta.state_already_synced"));
              }

              break;

            case 'Cancelada':
              const removedState = states.filter((estado: any) => estado.category === "Removed");

              if (removedState.length === 0) {
                toast.error(t("Herramienta.no_compatible_azure_state"));
              } else if (removedState.findIndex((estado: any) => estado.name === state) === -1) {
                updateAzureState(id, removedState[0].name);
              } else {
                toast.warning(t("Herramienta.state_already_synced"));
              }

              break;

            default:
              toast.error(t("Herramienta.no_compatible_azure_state"));
          }
      } else {
        toast.error(t("Herramienta.azure_state_update_error"));
      }
    } catch (error) {
      toast.error(t("Herramienta.azure_state_update_error"));
    }

    setIsSaving(false);
  }

  const handleStateUpdate = (id: number, type: string, trueffortStatus: any, state: string) => {
    setIsSaving(true);
    if (selectedAPI === "Azure REST API") {
      syncAzureState(id, type, trueffortStatus, state);
    } else {
      syncJiraState(id, trueffortStatus, state);
    }
  }

  const handleDeleteElement = (elemId: string) => {
    const updatedProjectElements = projectElements.filter((elem: any) => elem.apiID !== elemId);
    setProjectElements(updatedProjectElements);
    //setTotalHours(updatedProjectElements.reduce((acc: number, elem: any) => acc + elem.estimatedTime, 0));

    let maxDuration = updatedProjectElements.reduce((acc: number, elem: any) => acc + elem.estimatedDays, 0);
    let minDuration = updatedProjectElements.reduce(
        (acc: number, elem: any) => acc < elem.estimatedDays ? elem.estimatedDays : acc, 0
    );
    /*setMaxEstimatedDays(maxDuration);
    setMinEstimatedDays(minDuration);*/
    setTotalDuration(Math.round((maxDuration + minDuration) / 2));
  }

  const handleElementTypeChange = (elemID: string, idElementType: number) => {
    const projectElementsCopy = projectElements.map((elem: any) =>
        (elem.apiID === elemID ? {...elem, idElementType: idElementType} : {...elem})
    );

    setProjectElements(projectElementsCopy);
    setEstimationUpdateNeeded(true);
  }

  const handleElementSizeChange = (elemID: string, idSize: number) => {
    const projectElementsCopy = projectElements.map((elem: any) =>
        (elem.apiID === elemID ? {...elem, idSize: idSize} : {...elem})
    );

    setProjectElements(projectElementsCopy);
    setEstimationUpdateNeeded(true);
  }

  const handleElementComplexityChange = (elemID: string, idComplexity: number) => {
    const projectElementsCopy = projectElements.map((elem: any) =>
        (elem.apiID === elemID ? {...elem, idComplexity: idComplexity} : {...elem})
    );

    setProjectElements(projectElementsCopy);
    setEstimationUpdateNeeded(true);
  }

  const handleElementEffortChange = (elemID: string, effort: number, previousValue: number) => {
    const projectElementsCopy = projectElements.map((elem: any) =>
        (elem.apiID === elemID ? {...elem, estimatedTime: effort} : {...elem})
    );

    setProjectElements(projectElementsCopy);
  }

  const handleElementDurationChange = (elemID: number, duration: number) => {
    const projectElementsCopy = projectElements.map((elem: any) =>
        (elem.apiID === elemID ? {...elem, estimatedDays: duration} : {...elem})
    );

    setProjectElements(projectElementsCopy);
    let minDuration=projectElementsCopy.reduce(
      (acc: number, elem: any) => acc < elem.estimatedDays ? elem.estimatedDays : acc,0);
    let maxDuration = projectElementsCopy.reduce((acc: number, elem: any) => acc + elem.estimatedDays, 0);

    if (duration > totalDuration) {
      setTotalDuration(duration > maxDuration ? maxDuration : duration);
    }
    setTotalDuration(Math.round((maxDuration + minDuration) / 2));
  }

  const handleTotalDurationInputChange = (newTotalDuration: number) => {
    /*if (newTotalDuration < minEstimatedDays || newTotalDuration < 0 || newTotalDuration > maxEstimatedDays) {
      return;
    }*/
      if (newTotalDuration > maxEstimatedDays) {
        setTotalDuration((maxEstimatedDays + minEstimatedDays) / 2);
      } else {
        validateDurationInput(newTotalDuration);
        setTotalDuration(newTotalDuration);
      }

  }

  const validateDurationInput = (durationInput: number) => {
    if (isNaN(durationInput) || !Number.isInteger(durationInput)) {
      setDurationInputErrorMessage("Sólo valores númericos enteros");
    } else if (durationInput < minEstimatedDays) {
      setDurationInputErrorMessage("Valor menor que el mínimo");
    } else if (durationInput < 0) {
      setDurationInputErrorMessage("Valores negativos inválidos");
    } else if (durationInput > maxEstimatedDays) {
      setDurationInputErrorMessage("Valor mayor que el máximo");
    } else if (durationInputErrorMessage !== "") {
      setDurationInputErrorMessage("");
    }
  }

  const handleClickMenu = (event: any, value: any) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  // Prepara el Filtro por status
  const handleChangeStatus = (status: string) => {
    setAnchorElemFilter(null);
    if (status === "all") {
      setSelectedStatuses([]);
      //setProjectTasksFiltered(projectTasks);
      return;
    }
    let selectedStatusesCopy = [...selectedStatuses];

    let alreadySelected = selectedStatuses.find((s: any) => s === status);
    if (typeof alreadySelected == "undefined") {
      selectedStatusesCopy.push(statusFromTasks.find((s: any) => s === status));
    } else {    // Si ya estaba seleccionarlo, deseleccionarlo
      selectedStatusesCopy = selectedStatusesCopy.filter((item: any) => item !== status);
    }
    setSelectedStatuses(selectedStatusesCopy);
    //console.log("Status Filter Applied:", selectedStatusesCopy);
  }

  const handleChangeSprint = (sprint: string) => {
    setAnchorElemFilter(null);
    if (sprint === "all") {
      setSelectedSprints([]);
      return;
    }
    let selectedSprintsCopy = [...selectedSprints];
    let alreadySelected = selectedSprints.find((s: any) => s === sprint);

    if (typeof alreadySelected === "undefined") {
      selectedSprintsCopy.push(sprintsFromTasks.find((s: any) => s === sprint));

    } else {
      selectedSprintsCopy = selectedSprintsCopy.filter((item: any) => item !== sprint);

    }
    setSelectedSprints(selectedSprintsCopy);
    //console.log("Sprint Filter Applied:", selectedSprintsCopy);
  };

  //console.log(projectElements);

  // Número de columnas
  const numberOfColumns = 8;
  return (
    <SiteWrapper>
      <Container maxWidth='xl' sx={{ marginTop: '20px', minWidth: "1280px" }}>
        <Typography color='#4A4A4A' variant="h4">
          {selectedProjectName}
        </Typography>
      </Container>

      {/* Contenedor para el selector de tipo de proyecto */}
      <Container maxWidth='xl' sx={{ marginTop: '20px', marginLeft: '-20px' }}>
        <Paper
          elevation={0}
          square
          sx={{ padding: '6px', bgcolor: '#F5F5F5', minWidth: "1280px", marginLeft: '-20px' }} // Ajustar marginLeft aquí
        >
          <Card elevation={2}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <Typography variant="h5" color='#050536' >
                    {t("Herramienta.type_project")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {/* Selector de tipo de proyecto */}
                  <Select
                    id="project-type-select"
                    name="Project"
                    size="small"
                    sx={{ backgroundColor: '#F5F5F5', color: '#07074E' }}
                    IconComponent={KeyboardArrowDownIcon}
                    displayEmpty
                    fullWidth
                    value={idSelectedProjectType}
                    onChange={(e) => handleChangeProjectTypeSelect(e.target.value)}
                  >
                    <MenuItem value="" disabled >
                      {t("Select")}
                    </MenuItem>
                    {/* Mapeo de tipos de proyecto */}
                    {projectTypes.map((pro: any, k: any) => (
                      <MenuItem
                        key={pro.idProjectType}
                        value={pro.idProjectType}
                        className={pro.active == 1 ? "" : "inactive"}
                      >
                        {pro.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Container>

      {/* Contenedor para la tabla y los detalles del proyecto seleccionado */}
      <Container maxWidth='xl' sx={{ marginTop: '20px', marginLeft: '-20px' }}>
        <Paper
          elevation={0}
          sx={{ padding: '6px', bgcolor: '#F5F5F5', minWidth: "1280px", marginLeft: '-20px' }} // Ajustar marginLeft aquí
        >
          <Card elevation={1}>
            <CardContent>
              {isLoading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                  <CircularProgress />
                </Grid>
              ) : ( /* Verifica si hay un proyecto seleccionado */
                selectedProjectType?.active && (
                  <>
                    <TableContainer component={Paper}>
                      <Grid item xs={12} >
                        <Paper variant="outlined" square sx={{ padding: '10px', backgroundColor: '#F5F5F5' }} >
                          <Stack direction="row" spacing={2} >
                            <Typography sx={{ display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                              <Dashboard fontSize="small" sx={{ marginRight: '6px' }} />
                              {t("project_tasks_page.title") + ": " + selectedProjectName}
                            </Typography>

                            <Typography sx={{ display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                              <AppRegistrationIcon fontSize="small" sx={{ marginRight: '6px' }} />
                              {t("project_page.template") + ": " + selectedProjectType.name}
                            </Typography>

                          </Stack>
                        </Paper>
                        <Grid container direction="row" alignItems="stretch" spacing={1}>
                          <Grid item xs>
                            <Card elevation={1} sx={{ height: '100%' }}>
                              <CardContent>
                                <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                                  <Grid item xs>
                                    <Typography color='#050536'>
                                      {t("estimation_page.estimated_total_effort")}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs>
                                    <Stack direction='row' spacing={1} justifyContent='center' alignContent='center'>
                                      <Typography variant="h5" color='#000000'>
                                        {/* {rowData && rowData.length > 0
                                          ? rowData.reduce((total: any, row: any) => total + (row.estimatedTime ? parseFloat(row.estimatedTime) : 0), 0)
                                          : 0
                                        } */}
                                        {totalHours}
                                      </Typography>

                                      <Typography variant="h6" color='#000000'>
                                        {t("estimation_page.man_hours")}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>

                          <Grid item xs>
                            <Card elevation={1} sx={{ height: '100%' }}>
                              <CardContent>
                                <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                                  <Grid item xs>
                                    <Typography color='#050536'>
                                      {t("estimation_page.estimated_total_time")}
                                    </Typography>

                                  </Grid>

                                  <Grid item xs>
                                    <Stack direction='row' spacing={1} justifyContent='center' alignContent='center'>
                                      <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                        <Typography variant='caption' >
                                          Min
                                        </Typography>

                                        <TrueffortChip
                                            icon={
                                              <SvgIcon >
                                                <svg
                                                    viewBox="0 0 13 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                      d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                                                      fill={
                                                        totalDuration === minEstimatedDays ?
                                                            'white' :
                                                            '#6693CB'
                                                      }
                                                  />
                                                </svg>
                                              </SvgIcon>
                                            }
                                            size='small'
                                            color='secondary'
                                            variant={
                                              totalDuration === minEstimatedDays ?
                                                  'filled' :
                                                  'outlined'
                                            }
                                            label={estimation.id === undefined ? '--' : minEstimatedDays}
                                            disabled={estimationUpdateNeeded}
                                            onClick={() => handleTotalDurationInputChange(minEstimatedDays)}
                                        />
                                      </Stack>

                                      <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="center" >
                                        <TextField
                                          type="number"
                                          size="small"
                                          InputProps={{
                                            inputProps: {
                                              min: minEstimatedDays,
                                              style: {
                                                height: '1.15rem',
                                                width: '100px',
                                                fontFamily: 'ofelia-display, sans-serif',
                                                fontSize: '1.500rem'
                                              }
                                            }
                                          }}
                                          value={totalDuration}
                                          onChange={(e: any) => handleTotalDurationInputChange(Number(e.target.value))}
                                          error={durationInputErrorMessage !== ""}
                                          helperText={durationInputErrorMessage}
                                          disabled={estimationUpdateNeeded}
                                        />
                                        <Typography variant="h6" color='#000000'>
                                          {t("estimation_page.days")}
                                        </Typography>
                                      </Stack>

                                      <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                        <Typography variant='caption' >
                                          Max
                                        </Typography>

                                        <TrueffortChip
                                            icon={
                                              <SvgIcon >
                                                <svg
                                                    viewBox="0 0 13 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                      d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                                                      fill={
                                                        totalDuration === maxEstimatedDays ?
                                                            'white' :
                                                            '#6693CB'
                                                      }
                                                  />
                                                </svg>
                                              </SvgIcon>
                                            }
                                            size='small'
                                            color='secondary'
                                            variant={
                                              totalDuration === maxEstimatedDays ?
                                                  'filled' :
                                                  'outlined'
                                            }
                                            label={estimation.id === undefined ? '--' : maxEstimatedDays}
                                            disabled={estimationUpdateNeeded}
                                            onClick={() => handleTotalDurationInputChange(maxEstimatedDays)}
                                        />
                                      </Stack>
                                    </Stack>

                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>

                      </Grid>

                      <Grid container>
                        <Grid item xs={2} sx={{ padding: 2, paddingRight: 1}}>
                          <TrueffortNavButton
                            id="idSelectedStatuses"
                            size="small"
                            fullWidth
                            color={"primary"}
                            variant="contained"
                            onClick={(event: any) => handleClickMenu(event, 1)}
                          >
                            <CustomIconTrueffortScreen icon={<CheckCircleIcon />} />
                            {selectedStatuses.length == 0 ?
                              t("reports_page.general.estatus") :
                              selectedStatuses.map((u: any, i: number) =>
                                u + (i < (selectedStatuses.length - 1) ? ", " : "")
                              )
                            }
                            <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                          </TrueffortNavButton>
                        </Grid>
                        {sprints.length > 0 &&
                          <Grid item xs={2} sx={{ padding: 2, paddingLeft: 1}}>
                            <TrueffortNavButton
                              id="idSelectedSprints"
                              size="small"
                              fullWidth
                              color={"primary"}
                              variant="contained"
                              onClick={(event: any) => handleClickMenu(event, 2)}
                            >
                              <CustomIconTrueffortScreen icon={<CheckCircleIcon />} />
                              {selectedSprints.length === 0 ? "Sprints" : selectedSprints.map((u:any, i:number) => u + (i < (selectedSprints.length-1) ? ", " : ""))}
                              <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                            </TrueffortNavButton>
                          </Grid>
                        }
                      </Grid>

                      <Menu id="idElementsFilterDashboards"
                            anchorEl={anchorElemFilter}
                            open={openOptions}
                            onClose={() => setAnchorElemFilter(null)}
                      >
                        {menuNumber == 1 &&
                            <div>
                              <MenuItem
                                  id="idStatus_all"
                                  key="all"
                                  sx={{ fontSize: ".8rem!important", color: selectedStatuses.length === 0 ? "#777777" : "" }}
                                  onClick={() => handleChangeStatus("all")}
                              >
                                {t("Mostrar todos")}
                              </MenuItem>

                              {statusFromTasks.map((s:any, i:number) => (
                                  <MenuItem
                                      id={"idStatus_"+(i+1)}
                                      key={s}
                                      sx={{
                                        fontSize: ".8rem!important",
                                        color: selectedStatuses.findIndex((st:any) => st === s) != -1 ? "#777777" : ""
                                      }}
                                      onClick={() => handleChangeStatus(s)}
                                  >
                                    {s}
                                  </MenuItem>
                              ))}
                            </div>
                        }
                        {menuNumber === 2 &&
                          <div>
                            <MenuItem
                              id="idSprint_all"
                              key="all"
                              sx={{ fontSize: ".8rem!important", color: selectedSprints.length === 0 ? "#777777" : "" }}
                              onClick={() => handleChangeSprint("all")}
                            >
                              {t("Mostrar todos")}
                            </MenuItem>
                            {sprintsFromTasks.map((s: any, i: number) => (
                              <MenuItem
                                id={"idSprint_" + (i + 1)}
                                key={s}
                                sx={{
                                  fontSize: ".8rem!important",
                                  color: selectedSprints.findIndex((st: any) => st === s) != -1 ? "#777777" : ""
                                }}
                                onClick={() => handleChangeSprint(s)}
                              >
                                {s}
                              </MenuItem>
                            ))}
                          </div>}
                      </Menu>

                      <Table>

                        <TableHead>
                        
                          <TableRow>

                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                              <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                {t("estimation_page.element")}
                              </Typography>
                            </TableCell>
                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                              <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                {t("project_page.elem_type")}
                              </Typography>
                            </TableCell>
                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                              <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                {t("estimation_page.element_size")}
                              </Typography>
                            </TableCell>
                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                              <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                {t("estimation_page.element_complexity")}
                              </Typography>
                            </TableCell>
                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                              <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                {t("estimation_page.total_element_effort")}
                              </Typography>
                            </TableCell>
                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                              <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                {t("estimation_page.days_duration")}
                              </Typography>
                            </TableCell>
                            {/*<TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                               Sprint
                             </TableCell>*/}
                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                              <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                {t("reports_page.general.estatus")}
                              </Typography>
                            </TableCell>
                            {/*<TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                               <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                 {t("start_date")}
                               </Typography>
                            </TableCell>*/}
                            <TableCell item xs={2.5} square sx={{ backgroundColor: '#F5F5F5' }}>
                            </TableCell>
                          </TableRow>
                        </TableHead>


                        <TableBody>
                          {projectElements
                            .filter((elem: any) =>{
                              const statusMatch = selectedStatuses.length === 0 || selectedStatuses.includes(elem.status);
                              const sprintMatch = selectedSprints.length === 0 || selectedSprints.includes(typeof elem.sprints === 'string' ? elem.sprints : elem.sprints[0]);                           
                               return statusMatch && sprintMatch;
                            })
                            .map((elem: any, index: number) => (
                              <React.Fragment >
                                {/* Componente para cada fila de tarea */}
                                <APITaskTableRow
                                  key={elem.apiID}
                                  task={elem}
                                  t={t}
                                  elementTypes={elementTypes}
                                  matches={matches}
                                  onRowDataChange={{
                                    onElementTypeChange: handleElementTypeChange,
                                    onDurationChange: handleElementDurationChange,
                                    onEffortChange: handleElementEffortChange,
                                    onSizeChange: handleElementSizeChange,
                                    onComplexityChange: handleElementComplexityChange
                                  }}
                                  onDeleteRow={() => handleDeleteElement(elem.apiID)}
                                  estimated={!estimationUpdateNeeded}
                                />

                                {elem.assignedUser && (
                                  <TableRow>
                                    <TableCell colSpan={1}>
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                      <ProjectDetailUserCard
                                        userInfo={users.find((user: any) => user.idUser === elem.assignedUser)}
                                        _deleteUser={(usr) => deleteUser(usr, elem.apiID)}
                                        index={index}
                                        detail={true}
                                        pendingSave={false}
                                        showRating={false}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}


                                {index !== projectElements.length - 1 && ( // Asegura que no agregues la línea después de la última fila
                                    <TableRow>
                                      <TableCell colSpan={numberOfColumns} style={{ padding: 0 }}> {/* Ajusta numberOfColumns con el número de columnas en tu tabla */}
                                        <Box borderBottom={1} borderColor="grey.300" />
                                      </TableCell>
                                    </TableRow>
                                )}

                              </React.Fragment>
                            ))
                          }

                          {/*{syncedElements.length > 0 &&
                              <TableRow>
                                <TableCell colSpan={numberOfColumns} style={{ padding: 0 }}>  Ajusta numberOfColumns con el número de columnas en tu tabla
                                  <Box borderBottom={1} borderColor="grey.300" />
                                </TableCell>
                              </TableRow>
                          }*/}


                        </TableBody>
                      </Table>

                      {syncedElements.length > 0 &&
                        <Table >
                          <TableHead>
                            <TableRow>
                              <TableCell square sx={{ backgroundColor: '#F5F5F5' }}>
                                <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                  {t("estimation_page.element")}
                                </Typography>
                              </TableCell>

                              <TableCell square sx={{ backgroundColor: '#F5F5F5' }} ></TableCell>

                              <TableCell square sx={{ backgroundColor: '#F5F5F5' }}>
                                <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                  {t("reports_page.general.estatus") + " (Trueffort)"}
                                </Typography>
                              </TableCell>
                              <TableCell square sx={{ backgroundColor: '#F5F5F5' }}>
                                <Typography variant='subtitle2' color='#4A4A4A' align='center' >
                                  {t("reports_page.general.estatus") + " (" + selectedAPI.split(" ")[0] + ")" }
                                </Typography>
                              </TableCell>
                              <TableCell square sx={{ backgroundColor: '#F5F5F5' }}>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody >
                            {syncedElements
                                .filter((elem: any) =>{
                                  const statusMatch = selectedStatuses.length === 0 || selectedStatuses.includes(elem.status);
                                  const sprintMatch = selectedSprints.length === 0 || selectedSprints.includes(typeof elem.sprints === 'string' ? elem.sprints : elem.sprints[0]);                            return statusMatch && sprintMatch;
                                })
                                .map((elem: any, index: number) => (
                                    <>
                                      <TableRow style={{ backgroundColor: 'white' }}>
                                        <TableCell style={{ color: /*isDeleted ? '#888' :*/ '#000' }}>
                                          <Typography variant='subtitle2' align='center' >
                                            {elem.name}
                                          </Typography>
                                        </TableCell>

                                        <TableCell align="center" >
                                          <TrueffortChip color="green" variant="outlined" size="small" label={"EN TRUEFFORT"} />
                                        </TableCell>

                                        <TableCell >
                                          <Typography variant='subtitle2' align='center' >
                                            {elem.trueffortStatus.name}
                                          </Typography>
                                        </TableCell>

                                        <TableCell >
                                          <Typography variant='subtitle2' align='center' >
                                            {elem.status}
                                          </Typography>
                                        </TableCell>

                                        <TableCell >
                                          <Tooltip title="Sincronizar estado" >
                                            <IconButton
                                                color="primary"
                                                disabled={isSaving}
                                                onClick={() => handleStateUpdate(elem.apiID, elem.type, elem.trueffortStatus, elem.status)}
                                            >
                                              <CachedIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>

                                      {index !== syncedElements.length - 1 && ( // Asegura que no agregues la línea después de la última fila
                                          <TableRow>
                                            <TableCell colSpan={numberOfColumns} style={{ padding: 0 }}> {/* Ajusta numberOfColumns con el número de columnas en tu tabla */}
                                              <Box borderBottom={1} borderColor="grey.300" />
                                            </TableCell>
                                          </TableRow>
                                      )}
                                    </>
                                ))
                            }
                          </TableBody>
                        </Table>
                      }

                    </TableContainer>

                      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        {/* Botón de guardado */}
                        <TrueffortButton
                          variant="contained"
                          color="primary"
                          onClick={estimationUpdateNeeded ? getEstimate : handleSaveButtonClick}
                          disabled={
                            isSaving
                              || projectElements.length === 0
                              || durationInputErrorMessage !== ""
                          }
                        >
                          {estimationUpdateNeeded ? t("Herramienta.estimate") : t('Herramienta.save')}
                        </TrueffortButton>
                      </Box>
                  </>
                )
              )}

            </CardContent>
          </Card>
        </Paper>

      </Container>

    </SiteWrapper>
  );
}

export default APIProjectsInfo;