import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class InstanceService {
    getAll() {
        return http?.get(baseURL.activitiesapi.instances + `/getAll`);
    }

    getCount() {
        return http?.get(baseURL.activitiesapi.instances + `/getCount`);
    }

    get(id: number) {
        return http?.get(baseURL.readApi.instances + `/get/${id}`);
    }

    updateEfficiency(idInstance: number, efficiency: number) {
        return http?.get(baseURL.activitiesapi.instances + `/updateEfficiency?idInstance=${idInstance}&efficiency=${efficiency}`);
    }

    updateProductivityRange(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.instances + `/updateProductivityRange/${id}`, data);
    }

    getLicensesActiveRange(id: number) {
        return http?.get(baseURL.activitiesapi.instances + `/getLicensesActiveRange/${id}`);
    }

    updateLicensesActiveRange(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.instances + `/updateLicensesActiveRange/${id}`, data);
    }

    updateInstanceByPay(id:number, data: any) {
        return http?.put(baseURL.activitiesapi.instances + `/updateInstanceByPay/${id}`, data)
    }

    getLastBought() {
        return http?.get(baseURL.activitiesapi.instances + `/lastBought`);
    }

    deleteInstance(id: number) {
        return http?.delete(baseURL.activitiesapi.instances + `/${id}`);
    }

    getAllLicenses() {
      return http?.get(baseURL.readApi.licenses + "/");
    }

    getAllFeatures() {
      return http?.get(baseURL.readApi.licenses + "/features");
    }

    saveLicense(id:number, data:any) {
      return http?.put(baseURL.activitiesapi.license + `/${id}`, data)
    }
}

export default InstanceService;