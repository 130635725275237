import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ElementTypeService {

    getAll() {
        return http?.get(baseURL.readApi.elementType + "/");
    }

    getAllByProjectType(idProjectType: number) {
        return http?.get(baseURL.readApi.elementType + "/" + idProjectType);
    }

    getAllWithTasksByProjectType(idProjectType: number) {
        return http?.get(baseURL.readApi.elementType + "/findAllWithTasks/" + idProjectType);
    }

    create(data: any) {
        return http?.post(baseURL.activitiesapi.elementType + "/", data);
    }

    saveConfiguration(data: any) {
        return http?.post(baseURL.activitiesapi.elementType + "/saveConfiguration", data);
    }

    update(id : number, data : any) {
        return http?.put(baseURL.activitiesapi.elementType + `/${id}`, data);
    }

    delete(id : number) {
        return http?.delete(baseURL.activitiesapi.elementType + `/${id}`);
    }

    reactivate(id : number) {
        return http?.patch(baseURL.activitiesapi.elementType + `/reactivate/${id}`);
    }
}

export default ElementTypeService;