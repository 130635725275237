/*CAPTCHA V2*/
export const RECAPTCHA_SITE_KEY = "6Ld6wIsaAAAAAMpk8Gc6onPN3k9pz17Uz1zhgx8l";
export const RECAPTCHA_SECRET_KEY = "6Ld6wIsaAAAAAKLTLOE-5elanUEuIkVOU85vAlJ8";


//CAPTCHA V3
// export const RECAPTCHA_SITE_KEY = "6LfdF1saAAAAABIiHCQIQJDqM2TsH0jOuJrdiIas";
// export const RECAPTCHA_SECRET_KEY = "6LfdF1saAAAAAFiVuYL1t5MLVjcjW3UiFDJqpwo9";

//export const server_address = "http://localhost:8765/api";
// PROD
export const server_address = "https://tool.trueffort.com/api";
// QA
//export const server_address = "https://qa.trueffort.com/api";

export const DEFAULT_REQUEST_ERROR = "default_request_error"
export const ID_INSTANCE_SPINGERE = 2;
export const WHITOUT_CATEGORY_NAME = "Sin Categoria";
export const defaultStrings = {
  nameLabel: "Name",
  namePlaceholder: "forms.namePlaceholder",
  emailLabel: "email",
  emailPlaceholder: "forms.emailPlaceholder",
  passwordLabel: "Password",
  passwordPlaceholder: "forms.passwordPlaceholder",
  tokenPlaceholder: "forms.tokenPlaceholder",
  createAccountTitle: "register.title",
  createAccountButtonTextBefore: "register.buttonTextBefore",
  createAccountButtonTextAfter: "register.buttonTextAfter",
  createAccountTermsLabel: "forms.terms",
  buttonTextDuring: "forms.loading",
  lastname: "Lastname",
  company: "Company",
  confirmPassword: "forms.confirmPassword",
  terms: "forms.terms",
  lastnamePlaceholder: "forms.lastnamePlaceholder",
  companyPlaceholder: "forms.companyPlaceholder",
  confirmPasswordPlaceholder: "forms.confirmPasswordPlaceholder",
  changePasswordTitle: "changePassword.title",
  changePasswordButtonText: "changePassword.buttonText",
  changePasswordInstructions: "changePassword.instructions",
  newPasswordPlaceholder: "forms.newPasswordPlaceholder",
  newPasswordConfirmLabel: "forms.newPasswordConfirmLabel",
};

const activitiesapi = server_address + "/activitiesapi";
const trueffortReadApi = server_address + "/trueffort-read-api";
const trueffortPyApi = server_address + "/trueffort-py-api/services";
export const baseURL = {
  activitiesapi: {
    activity: activitiesapi + "/activities",
    applications: activitiesapi + "/applications",
    applicationsGroup: activitiesapi + "/applications-group",
    profiles: activitiesapi + "/profiles",
    teams: activitiesapi + "/teams",
    users: activitiesapi + "/users",
    workstations: activitiesapi + "/workstations",
    category: activitiesapi + "/category",
    projects: activitiesapi + "/projects",
    schedules: activitiesapi + "/schedules",
    sites: activitiesapi + "/sites",
    categorysite: activitiesapi + "/categorysites",
    instances: activitiesapi + "/instances",
    notifications: activitiesapi + "/notifications",
    payments: activitiesapi + "/payments",
    summaryLicences: activitiesapi + "/summary-licences",
    projectElement: activitiesapi + "/project-element",
    projectTask: activitiesapi + "/project-task",
    phase: activitiesapi + "/phase",
    taskType: activitiesapi + "/task-type",
    projectType: activitiesapi + "/project-type",
    elementType: activitiesapi + "/element-type",
    projectPhase: activitiesapi + "/project-phase",
    version: activitiesapi + "/version",
    paymentPlan: activitiesapi + "/payment-plan",
    logbook: activitiesapi + "/logbook",
    projectTaskComment: activitiesapi + "/project-task-comment",
    jiraProjects: activitiesapi + "/jira",
    AzureProjects:activitiesapi + "/azuredevops",
    license: activitiesapi + "/license"
  },
  readApi: {
    activity: trueffortReadApi + "/activities",
    applications: trueffortReadApi + "/applications",
    instances: trueffortReadApi + "/instances",
    image: trueffortReadApi + "/image",
    summaryActivities: trueffortReadApi + "/summary-activities",
    users: trueffortReadApi + "/users",
    teams: trueffortReadApi + "/teams",
    lifeCycleType: trueffortReadApi + "/life-cycle-type",
    elementType: trueffortReadApi + "/element-type",
    phase: trueffortReadApi + "/phases",
    projects: trueffortReadApi + "/projects",
    profiles: trueffortReadApi + "/profiles",
    reports: trueffortReadApi + "/reports",
    projectElement: trueffortReadApi + "/project-element",
    projectTask: trueffortReadApi + "/project-task",
    projectType: trueffortReadApi + "/project-type",
    taskState: trueffortReadApi + "/task-state",
    taskType: trueffortReadApi + "/task-type",
    userCompanyRoleType: trueffortReadApi + "/userCompanyRoleType",
    userCompanyPositionType: trueffortReadApi + "/userCompanyPositionType",
    companyBusinessSubtype: trueffortReadApi + "/companyBusinessSubtype",
    subscriptions: trueffortReadApi + "/subscriptions",
    licenses: trueffortReadApi + '/licenses'
  },
  trueffortPyApi: {
    estimates: trueffortPyApi + "/estimates",
    trend: trueffortPyApi + "/trend"
  }
}

export const download_options = (t: Function, type = "warning") => [{
    title: t("dashboard.download_for") + " Windows_x86_64",
    onClick: () => window.location.href = "./trueffort-3.15.0-prod-windows-x86_64-setup.exe",
    to: "./trueffort-3.15.0-prod-windows-x86_64-setup.exe",
    type,
    value: "Windows_x86_64",
    icon: 'windows.png'
},
{
  title: t("dashboard.download_for") + " MacOS (ARM)",
  onClick: () => window.location.href = "./trueffort-client-arm64-3.15.0.dmg",
  to: "./trueffort-client-arm64-3.15.0.dmg",
  type,
  value: "MacOS (ARM)",
  icon: 'macos.png'
},
{
    title: t("dashboard.download_for") + " MacOS (Intel)",
    onClick: () => window.location.href = "./trueffort-client-x86_64-3.15.0.dmg",
    to: "./trueffort-client-x86_64-3.15.0.dmg",
    type,
    value: "MacOS (Intel)",
    icon: 'macos.png'
}, {
    title: t("dashboard.download_for") + " Linux_x86_64 (deb)",
    onClick: () => window.location.href = "./trueffort-client-3.15.0-prod.deb",
    type,
    to: "./trueffort-client-3.15.0-prod.deb",
    value: "Linux_x86_64 (deb)",
    icon: 'linux.png'
}, {
    title: t("dashboard.download_for") + " Linux_x86_64 (pacman)",
    onClick: () => window.location.href = "./trueffort-client-3.15.0-prod.pacman",
    type,
    to: "./trueffort-client-3.15.0-prod.pacman",
    value: "Linux_x86_64 (pacman)",
    icon: 'linux.png'
}, {
    title: t("dashboard.download_for") + " Linux_x86_64 (rpm)",
    onClick: () => window.location.href = "./trueffort-client-3.15.0-prod.rpm",
    type,
    to: "./trueffort-client-3.15.0-prod.rpm",
    value: "Linux_x86_64 (rpm)",
    icon: 'linux.png'
}
];

export const arrMeetingApps = [
  "zoom",
  "zoom.exe"
];

export const arrMeetingWindows = [
  "Google Hangouts - (.*)",
  "Meet - [a-z]{3}-[a-z]{4}-[a-z]{3}",
  "(.*) Jitsi Meet -", "Skype", "ooVoo", "Zoom", "Uberconference",
  "Discord", "8x8Meet", "Slack", "Microsoft Teams", "GoToMeeting", "BlueJeans", "Cisco Webex Meetings",
  "Whereby", "Zoho Meetings", "Clickmeeting", "LifeSize", "Join.me", "Amazon Chime", "Meeting Burner",
  "Huddle", "Meeting.gs", "Fuze Meeting", "Tinychat", "Anymeeting", "LiveWebinar", "Classroom Meeting"
];

export const countries = [
  {name: "Australia", code: "AU", taxid_types: ["au_abn", "au_arn"]},
  {name: "Austria", code: "AT", taxid_types: ["eu_vat"]},
  {name: "Belgium", code: "BE", taxid_types: ["eu_vat"]},
  {name: "Brazil", code: "BR", taxid_types: ["br_cnpj", "br_cpf"]},
  {name: "Bulgaria", code: "BG", taxid_types: ["eu_vat"]},
  {name: "Canada", code: "CA", taxid_types: ["ca_bn", "ca_gst_hst", "ca_pst_bc", "ca_pst_mb", "ca_pst_sk", "ca_qst"]},
  {name: "Chile", code: "CL", taxid_types: ["cl_tin"]},
  {name: "Croatia", code: "HR", taxid_types: ["eu_vat"]},
  {name: "Cyprus", code: "CY", taxid_types: ["eu_vat"]},
  {name: "Czech", code: "CZ", taxid_types: ["eu_vat"]},
  {name: "Denmark", code: "DK", taxid_types: ["eu_vat"]},
  {name: "Estonia", code: "EE", taxid_types: ["eu_vat"]},
  {name: "Finland", code: "FI", taxid_types: ["eu_vat"]},
  {name: "France", code: "FR", taxid_types: ["eu_vat"]},
  {name: "Germany", code: "DE", taxid_types: ["eu_vat"]},
  {name: "Greece", code: "GR", taxid_types: ["eu_vat"]},
  {name: "Hong_Kong", code: "", taxid_types: ["hk_br"]},
  {name: "Hungary", code: "HU", taxid_types: ["eu_vat"]},
  {name: "India", code: "IN", taxid_types: ["in_gst"]},
  {name: "Indonesia", code: "ID", taxid_types: ["id_inpwp"]},
  {name: "Ireland", code: "IE", taxid_types: ["eu_vat"]},
  {name: "Israel", code: "IL", taxid_types: ["il_vat"]},
  {name: "Italy", code: "IT", taxid_types: ["eu_vat"]},
  {name: "Japan", code: "JP", taxid_types: ["jp_cn", "jp_rn"]},
  {name: "Korea", code: "KR", taxid_types: ["kr_brn"]},
  {name: "Latvia", code: "LV", taxid_types: ["eu_vat"]},
  {name: "Liechtenstein", code: "LI", taxid_types: ["li_uid"]},
  {name: "Lithuania", code: "LT", taxid_types: ["eu_vat"]},
  {name: "Luxembourg", code: "LU", taxid_types: ["eu_vat"]},
  {name: "Malaysia", code: "MY", taxid_types: ["my_frp", "my_itn", "my_sst"]},
  {name: "Malta", code: "MT", taxid_types: ["eu_vat"]},
  {name: "Mexico", code: "MX", taxid_types: ["mx_rfc"]},
  {name: "Netherlands", code: "NL", taxid_types: ["eu_vat"]},
  {name: "New_Zealand", code: "NZ", taxid_types: ["nz_gst"]},
  {name: "Norway", code: "NO", taxid_types: ["no_vat"]},
  {name: "Poland", code: "PL", taxid_types: ["eu_vat"]},
  {name: "Portugal", code: "PT", taxid_types: ["eu_vat"]},
  {name: "Romania", code: "RO", taxid_types: ["eu_vat"]},
  {name: "Russia", code: "RU", taxid_types: ["ru_inn", "ru_kpp"]},
  {name: "Saudi_Arabia", code: "SA", taxid_types: ["sa_vat"]},
  {name: "Singapore", code: "SG", taxid_types: ["sg_gst", "sg_uen"]},
  {name: "Slovakia", code: "SK", taxid_types: ["eu_vat"]},
  {name: "Slovenia", code: "SI", taxid_types: ["eu_vat"]},
  {name: "South_Africa", code: "ZA", taxid_types: ["za_vat"]},
  {name: "Spain", code: "ES", taxid_types: ["es_cif", "eu_vat"]},
  {name: "Sweden", code: "SE", taxid_types: ["eu_vat"]},
  {name: "Switzerland", code: "CH", taxid_types: ["ch_vat"]},
  {name: "Taiwan", code: "TW", taxid_types: ["tw_vat"]},
  {name: "Thailand", code: "TH", taxid_types: ["th_vat"]},
  {name: "UAE", code: "AE", taxid_types: ["ae_trn"]},
  {name: "United_Kingdom", code: "GB", taxid_types: ["gb_vat", "eu_vat"]},
  {name: "United_States", code: "US", taxid_types: ["us_ein"]}
];

export const USER_INFO_KEY = 'USER_INFO';
export const USER_LANGUAGE_KEY = 'USER_LANGUAGE';
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
export const PROJECT_KEY = 'PROJECT';
export const PROJECT_ID_KEY = 'PROJECT_ID';
export const PROJECT_OBJ_KEY = 'PROJECT_OBJ';
export const MONTHS = ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"];
export const DAYS_WEEK = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6
};
export const ID_INSTANCE_TYPE_INDIVIDUAL = 1;
export const ID_INSTANCE_TYPE_CORPORATE = 2;
export const ID_ROLE_ADMIN = 1;
export const ID_ROLE_USER = 2;
export const NUMBER_TRIAL_DAYS = 30;
export const MIN_LEVEL_USER = 1;
export const MIN_LEVEL_LEADER = 2;
export const MIN_LEVEL_ADMIN = 3;
export const MIN_LEVEL_SUSER = 4;
export const NUMBER_ITEMS_BY_PAGE = 5;
export const URL_PROD = "https://tool.trueffort.com/";
export const URL_QA = "https://qa.trueffort.com/";
export const URL_LOCAL = "http://localhost:3000/";
export const SHOW_MORE_DASHBOARD_ITEM = 5;
export const TIME_FORMAT = "TIME_FORMAT";
export const ID_STATUS_CREATED = 1;
export const ID_STATUS_INPROCESS = 2;
export const ID_STATUS_CANCELED = 3;
export const ID_STATUS_COMPLETED = 4;
// QA
//export const STRIPE_KEY = "pk_test_51Jf5dyEThJbgPPgiff5lwkp6wYKdzAd3MP5P7cXOCeiIkCXBGemnw0XB0daBHcRaGzbG03eSCiigCaGVrsI91vTE00iZA9lF4E";
// PROD
export const STRIPE_KEY = "pk_live_51Jf5dyEThJbgPPgi09TMc6lISUAEhlWlx7tDsCfWQC6YGEPCM3gNEAFYbJVLlNRtRhuuVB5Kdcdnu9zd1Uy8J2MV00QBuYoLwL";
export const EXCHANGE_KEY = "b32e0439c0be50a24aa2cf7331c1e51b";
export const exchangeapi = "http://api.exchangeratesapi.io/v1/latest"

export const price_constants = {
  discount_complement_semiannual: 0.89,
  discount_complement_annual: 0.75,
  unit_price_user: {
    x1: 1,
    x2: 299,
    y2: 2.99,
    y1: 4.99,
    m: -0.01,
    b: 4.98
  },
  unit_price_month: {
    x1: 1,
    x2: 12,
    y2: 3.74
  }
};

export const COLORS_RELEVANT = ['#68C8BA', '#E3759A', '#9A64E2', '#ED9A55', '#E4555A', 
        '#FFCDCF', '#E9C545', '#92CF52', '#64A8EC', '#6875C7', '78B737'];
export const COLORS_NOT_RELEVANT = ['#989898', '#BDBDBD', '#4A4A4A', '#6A6A6A', '#DCDCDC', '#474A4E'];