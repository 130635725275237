import { useState } from 'react';
import {
  Select, MenuItem, Typography, Stack, SvgIcon, TableCell, TableRow, IconButton, Tooltip
} from '@mui/material';
import { TrueffortTextField, TrueffortChip } from '../../MUIstyle/MUICustom';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from 'react';

const sizeOptions = [
  { idSize: 1, size: "estimation_page.small_size" },
  { idSize: 2, size: "estimation_page.medium_size" },
  { idSize: 3, size: "estimation_page.large_size" },
  { idSize: 4, size: "estimation_page.very_large_size" }
];

const complexityOptions = [
  { idComplexity: 1, complexity: "estimation_page.low_complexity" },
  { idComplexity: 2, complexity: "estimation_page.medium_complexity" },
  { idComplexity: 3, complexity: "estimation_page.large_complexity" }
];

function APITaskTableRow({ task, t, elementTypes, matches, onRowDataChange, onDeleteRow, estimated }: any) {
  //console.log(task);
  
  const [effortFocused, setEffortFocused] = useState(false);
  const [durationFocused, setDurationFocused] = useState(false);

  // Estado para almacenar y actualizar el esfuerzo, inicializado con rowData.estimatedTime o 1
  const [elementEffort, setElementEffort] = useState(task.estimatedTime);

  // Estado para almacenar y actualizar la duración, inicializada con rowData.estimatedDays o 1
  const [elementDuration, setElementDuration] = useState(task.estimatedDays);

  const handleEffortChange = (value: number) => {
    if (value < 0 || (task.effortMax != 0 && task.effortMin != 0 && (value > task.effortMax || value < task.effortMin))) {
      setElementEffort(task.estimatedTime);
      return;
    }

    setElementEffort(value);
    onRowDataChange.onEffortChange(task.apiID, value, task.estimatedTime);
  };
  
  const handleDurationChange = (value: number) => {
    if (value < 0 || (task.durationMax != 0 && task.durationMax != 0 && (value > task.durationMax || value < task.durationMax))) {
      setElementDuration(Math.round(task.estimatedDays));
      return;
    }

    setElementDuration(value);
    onRowDataChange.onDurationChange(task.apiID, value);
  };
  
  // Función para manejar el cambio del tipo de elemento seleccionado
  const handleChangeElementType = (idElementType: number) => {
    //setIdSelectedElementType(id);
    onRowDataChange.onElementTypeChange(task.apiID, idElementType); // Llama a la función onElementTypeChange recibida como prop
  }


  useEffect(() => {
    if (task.idElementType == '' && elementTypes.length == 1) {
      handleChangeElementType(elementTypes[0].idElementType);
    }
  });

  const handleChangeSize = (idSize: number) => {
    onRowDataChange.onSizeChange(task.apiID, idSize);
  }

  const handleChangeComplexity = (idComplexity: number) => {
    onRowDataChange.onComplexityChange(task.apiID, idComplexity);
  }

  // Define una función handleDeleteRow que establece isDeleted en true y llama a la función onDeleteRow
  const handleDeleteRow = () => {
    // Establece isDeleted en true
    //setIsDeleted(true);
    // Llama a la función onDeleteRow pasando la clave de la tarea o elemento que se va a eliminar
    onDeleteRow(task.id);
  };

  const handleEffortBlur = () => {
    if (elementEffort === '') {
      setElementEffort(Math.round(task.estimatedTime));
    } else {
      handleEffortChange(Math.round(Number(elementEffort)));
    }

    setEffortFocused(false);
  }

  const handleDurationBlur = () => {
    if (elementDuration === '') {
      setElementDuration(Math.round(task.estimatedDays));
    } else {
      handleDurationChange(Math.round(Number(elementDuration)));
    }

    setDurationFocused(false);
  }

  return (
    <>
    <TableRow style={{ backgroundColor: 'white' }}>
    <TableCell colSpan={8} style={{ color: /*isDeleted ? '#888' :*/ '#000' }}>
          <Typography variant='subtitle2' align='left' >
            {/*{currentAPI === "Jira REST API" ? task.fields.summary: task.fields["System.Title"]}*/}
            {task.name}
          </Typography>
        </TableCell>
    </TableRow>
      <TableRow style={{ backgroundColor: 'white' }}>
       <TableCell colSpan={1}>

       </TableCell>
        <TableCell >
          {/*Seleccionar el tipo de elemento */}
          <Select
            id="element-type-select"
            name="ElementType"
            size="small"
            sx={{ backgroundColor: '#F5F5F5', color: '#07074E' }}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
            value={task.idElementType } //Valor del tipo de elemento seleccionado
            onChange={(e) => handleChangeElementType(e.target.value)} //Llama a la función para hacer el cambio del tipo de elemento seleccionado
            //disabled={isDeleted} //Este select se deshabilitará si la fila fue eliminada
          >
            <MenuItem value="" disabled>
              {t("Select")}
            </MenuItem>
            {/* Muestra la lista de los elementos de la plantilla seleccionada */}
            {elementTypes.map((elemType: any) => (
              <MenuItem key={elemType.idElementType} value={elemType.idElementType}>
                {/* Muestra el nombre del elemento*/}
                {elemType.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell>
          <Stack direction="column" spacing={1}>
            {/*Seleccionar el tamaño */}
            <Select
              id="base-elements-size"
              size="small"
              sx={{ backgroundColor: '#F5F5F5', color: '#07074E' }}
              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              value={task.idSize}
              onChange={(event) => handleChangeSize(event.target.value)} //Cambia el tipo de valor seleccionado
              //disabled={isDeleted} //Este select se deshabilitará si la fila fue eliminada
            >
              <MenuItem value="" disabled>
                {t("Select")}
              </MenuItem>
              {/* Muestra la lista de los tamaños */}
              {sizeOptions.map((option) => (
                <MenuItem key={option.idSize} value={option.idSize}>
                  {/*Muestra el nombre del tamaño seleccionado */}
                  {t(option.size)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="column" spacing={1}>
            {/*Seleccionar la complejidad */}
            <Select
              id="base-elements-complexity"
              size="small"
              sx={{ backgroundColor: '#F5F5F5', color: '#07074E' }}
              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              value={task.idComplexity} //Valor de la complejidad seleccionada
              onChange={(event) => handleChangeComplexity(event.target.value)} //Cambia el tipo de valor seleccionado
              //disabled={isDeleted} //Este select se deshabilitará si la fila fue eliminada
            >
              <MenuItem value="" disabled>
                {t("Select")}
              </MenuItem>
              {/*Mostrar la lista de las complejidades */}
              {complexityOptions.map((option) => (
                <MenuItem key={option.idComplexity} value={option.idComplexity}>
                  {/*Muestra el nombre de la complejidad */}
                  {t(option.complexity)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </TableCell>

        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
              <Typography variant='caption' >
                Min
              </Typography>
              <TrueffortChip
                  icon={
                    <SvgIcon >
                      <svg
                          /*width="30"
                          height="30"*/
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                            fill={
                              Math.round(task.estimatedTime) === task.effortMin ?
                                  'white' :
                                  '#6693CB'
                            }
                        />
                      </svg>
                    </SvgIcon>
                  }
                  size='small'
                  color='secondary'
                  variant={
                    Math.round(task.estimatedTime) === task.effortMin ?
                        'filled' :
                        'outlined'
                  }
                  label={task.effortMin}
                  onClick={() => handleEffortChange(task.effortMin)}
                  disabled={!estimated}
              />
            </Stack>
            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
              <Typography variant='caption' >
                {t("estimation_page.avg")}
              </Typography>
              {/*Input para ingresar el esfuerzo */}
              <TrueffortTextField
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      /*max: 99999.99,
                      step: 1,*/
                      style: {
                        background: '#F5F5F5',
                        height: '7px'
                      }
                    }
                  }}
                  //value={initialEffort} //Valor del esfuerzo
                  value={effortFocused ? elementEffort : task.estimatedTime}
                  // onChange={(e: any) => setInitialEffort(e.target.value)} //Cambio del valor del esfuerzo
                  onFocus={() => setEffortFocused(true)}
                  onBlur={handleEffortBlur}
                  onChange={(event: any) =>
                      effortFocused ? setElementEffort(event.target.value) :
                          handleEffortChange(Math.round(Number(event.target.value)))
                  }
                  onKeyDown={(event: any) => {
                    if (event.key === "Enter") {
                      handleEffortChange(Math.round(Number(event.target.value)))
                    }
                  }}
                  fullWidth
                  sx={{ width: matches ? '100%' : '80px' }}
                  error={Math.round(task.estimatedTime) <= 0 || elementEffort <= 0}
                  disabled={!estimated}
              />
            </Stack>
            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
              <Typography variant='caption' >
                Max
              </Typography>

              <TrueffortChip
                  icon={
                    <SvgIcon >
                      <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                            fill={
                              Math.round(task.estimatedTime) === task.effortMax ?
                                  'white' :
                                  '#6693CB'
                            }
                        />
                      </svg>
                    </SvgIcon>
                  }
                  size='small'
                  color='secondary'
                  variant={
                    Math.round(task.estimatedTime) === task.effortMax ?
                        'filled' :
                        'outlined'
                  }
                  label={task.effortMax}
                  onClick={() => handleEffortChange(task.effortMax)}
                  disabled={!estimated}
              />
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
              <Typography variant='caption' >
                Min
              </Typography>

              <TrueffortChip
                  icon={
                    <SvgIcon >
                      <svg
                          /*width="30"
                          height="30"*/
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                            fill={
                              Math.round(task.estimatedDays) === task.durationMin ?
                                  'white' :
                                  '#6693CB'
                            }
                        />
                      </svg>
                    </SvgIcon>
                  }
                  size='small'
                  color='secondary'
                  variant={
                    Math.round(task.estimatedDays) === task.durationMin ?
                        'filled' :
                        'outlined'
                  }
                  label={task.durationMin}
                  onClick={() => handleDurationChange(task.durationMin)}
                  disabled={!estimated}
              />
            </Stack>

            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
              <Typography variant='caption' >
                {t("estimation_page.avg")}
              </Typography>

              {/*Input para ingresar la duración */}
              <TrueffortTextField
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                    /*max: 99999.99,
                    step: 1,*/
                    style: {
                      background: '#F5F5F5',
                      height: '7px'
                    }
                  }
                }}
                value={durationFocused ? elementDuration : task.estimatedDays}
                onFocus={() => setDurationFocused(true)}
                onBlur={handleDurationBlur}
                onChange={(event: any) =>
                    durationFocused ? setElementDuration(event.target.value) :
                        handleDurationChange(Math.round(Number(event.target.value)))
                }
                onKeyDown={(event: any) => {
                  if (event.key === "Enter")
                    handleDurationChange(Math.round(Number(event.target.value)));
                }}
                error={Math.round(task.estimatedDays) <= 0 || elementDuration <= 0}
                fullWidth
                sx={{ width: matches ? '100%' : '80px' }}
                disabled={!estimated}
              />
            </Stack>

            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
              <Typography variant='caption' >
                Max
              </Typography>

              <TrueffortChip
                  icon={
                    <SvgIcon >
                      <svg
                          /*width="13"
                          height="13"*/
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                            fill={
                              Math.round(task.estimatedDays) === task.durationMax ?
                                  'white' :
                                  '#6693CB'
                            }
                        />
                      </svg>
                    </SvgIcon>
                  }
                  size='small'
                  color='secondary'
                  variant={
                    Math.round(task.estimatedDays) === task.durationMax ?
                        'filled' :
                        'outlined'
                  }
                  label={task.durationMax}
                  onClick={() => handleDurationChange(task.durationMax)}
                  disabled={!estimated}
              />
            </Stack>
          </Stack>
        </TableCell>
        {/*<TableCell>

        </TableCell>*/}
        <TableCell>
          <Typography variant='subtitle2' align='center' >
            {/*{currentAPI === "Jira REST API" ? task.fields.status.name : task.fields["System.State"]}*/}
            {task.status}
          </Typography>
        </TableCell>
        {/*<TableCell>
          <Typography variant='subtitle2' align='center' >
            {currentAPI === "Jira REST API" ? task.fields.created: task.fields["System.CreatedDate"]}
          </Typography>
        </TableCell>*/}
        <TableCell>
          <Tooltip title="Quitar" >
            <IconButton
                color="error"
                onClick={handleDeleteRow}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}

export default APITaskTableRow;