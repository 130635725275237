import React, {createContext} from "react";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {User} from "../../util/Interfaces";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragAndDropColumn from "./DragAndDropColumn";
import {Container, Stack, Box} from "@mui/material";
import {toast} from "react-toastify";
import ProjectTaskService from "../../services/projectTask.service";

const ITEM_TYPES = {
  CARD: "card",
  TASK: "task"
};

function DragAndDropView({tasks, taskStates, selectedProjects, _setSelectedProjects, projects, _setSelectedIndexTask, _setSelectedTask, _setIdView}) {
    const {t} = useTranslation();
    const [tasksAux, setTasksAux] = React.useState([]);
    const [columns, setColumns] = React.useState(taskStates);
    const projectTaskService = React.useMemo(() => new ProjectTaskService(), []);

    React.useEffect(() => {
        //console.log("TASKS", tasks);
        setTasksAux(tasks);
    }, [tasks]);

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;

        if (!destination || (destination.droppableId === source.droppableId &&destination.index === source.index)) {
          return;
        }

        if (type === ITEM_TYPES.CARD) {
          reorderCards(source, destination, draggableId);
        } else {
            console.log("source.droppableId: " + source.droppableId);
            console.log("destination.droppableId: " + destination.droppableId);
          const start = columns[source.droppableId];
          const finish = columns[destination.droppableId];
          if (start.idStatus === finish.idStatus) {
//            reorderTasksWithinCard(
//              start,
//              source.index,
//              destination.index,
//              draggableId
//            );
          } else {
            moveTask(start, finish, source.index, destination.index, draggableId);
          }
        }
    }

    const reorderCards = (source, destination, draggableId) => {
        const newCardOrder = Array.from(columns);
        newCardOrder.splice(source.index, 1);
        newCardOrder.splice(destination.index, 0, draggableId);
        setColumns(newCardOrder);
      };

    const moveTask = (start, finish, sourceIdx, destinationIdx, draggableId) => {
        // SÓLO CAMBIAR EL STATUS DE LA TAREA
        projectTaskService.changeStatus(draggableId, finish.idStatus).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                let tasksCopy = tasksAux.slice();
                let taskUpdateIndex = tasksAux.findIndex((t) => t.idProjectTask == draggableId);
                tasksCopy[taskUpdateIndex]["idStatus"] = finish.idStatus;
                setTasksAux(tasksCopy);
                toast.error(response.message);
                toast.success(t("daily_page.msg_status_change_success"));
            }
        });
    }

    return (
            <Box sx={{backgroundColor: "#d4e3ff", padding: ".5rem"}}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="all-cards" direction="horizontal" type="card">
                {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                        <Stack direction="row" spacing={2}>
                        {columns.map((col, index) => (
                            <DragAndDropColumn
                                key={index}
                                index={index}
                                title={col.name}
                                idStatus={col.idStatus}
                                items={tasksAux?.filter((t) => t.idStatus == col.idStatus)}
                                _setSelectedIndexTask={_setSelectedIndexTask}
                                _setSelectedTask={_setSelectedTask}
                                _setIdView={_setIdView}
                            />
                        ))}
                        </Stack>
                    </Box>
                  )}
                 </Droppable>
            </DragDropContext>
            </Box>
    );
}

export default DragAndDropView;