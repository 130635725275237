import SiteWrapper from "../../SiteWrapper.react";
import React from "react";
import {User} from "../../util/Interfaces";
// @ts-ignore
import DOMPurify from 'dompurify';
// @ts-ignore
import renderHTML from 'react-render-html';
import {
  Avatar, Container, Card, Box, TextField, FormControl, MenuItem, Button, Stack, CircularProgress, Menu,
    Select, Paper, Typography, TableContainer, TableRow, TableBody, List, ListItemButton, ListItemText, Divider, CardHeader
} from "@mui/material";
import { formatDateString } from "../../util/DateDataFormat";
import {dateToDateLocale, formatSeconds} from "../../util/GlobalFunctions";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {TrueffortChip} from "../../MUIstyle/MUICustom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ProjectTaskCommentService from "../../services/projectTaskComment.service";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const FileDownload = require('js-file-download');

const ImageUser = ({user, date}: any) => {
    return(
        <Box sx={{display: "flex"}}>
            {typeof user.image_id != "undefined" && user.image_id != null && user.image_id != "" &&
                <Avatar src={user.image_url} style={{marginRight: ".4em"}} />
            }
            {typeof user.imageDto != "undefined" && user.imageDto != null && user.imageDto != "" &&
                <Avatar src={user.imageDto.url} style={{marginRight: ".4em"}} />
            }
            {typeof user.image != "undefined" && user.image != null && user.image != "" &&
                <Avatar src={user.image.url} style={{marginRight: ".4em"}} />
            }
            <Box>
                <Box><strong>{user.name + " " + (user.lastname === undefined ? user.lastName : user.lastname)}</strong></Box>
                <Box sx={{fontSize: ".8rem"}}>{formatDateString(dateToDateLocale(new Date(date)), "dd/MM/yyyy HH:mm:ss")}</Box>
            </Box>
        </Box>
    );
}

function UploadedFile({ fileName }: {fileName: string}) {
    const {t} = useTranslation();

    const projectTaskCommentService = React.useMemo(() => new ProjectTaskCommentService(), []);

    const handleFileDownload = () => {
        // @ts-ignore
        projectTaskCommentService.downloadFile(fileName).then((response) => {
            FileDownload(response.data, fileName);
        }).catch((error: any) => {
            toast.error(t("daily_page.msg_file_download_error"));
        });
    }

    return (
        <Stack direction="row" spacing={1} >
            <InsertDriveFileIcon fontSize='large' />

            <Stack direction="column" spacing={1} alignItems='flex-start' >
                {fileName}

                <TrueffortChip
                    size='small'
                    label={t("daily_page.file_download")}
                    icon={<FileDownloadIcon/>}
                    onClick={handleFileDownload}
                />
            </Stack>
        </Stack>
    );
}

// @ts-ignore
function Comment({comment, user}) {
    return (
        <Card sx={{padding: "8px", backgroundColor: "#f9f9f9"}}>
            <Stack direction='column' spacing={2} sx={{ marginBottom: 2 }}>
                <ImageUser user={user} date={comment.created} />

                {renderHTML(DOMPurify.sanitize(comment.comment, {USE_PROFILES: {html: true}}))}

                {comment.file !== null &&
                    <UploadedFile fileName={comment.file.name} />
                }
            </Stack>
        </Card>
    );
}

export default Comment;