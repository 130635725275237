import React from "react";
import {
    Container,
    Card,
    Box,
    Button,
    Stack,
    TableRow,
    TableBody,
    Link,
    Typography, TableContainer
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {TrueffortButton, TrueffortTable, TrueffortTableHead, TrueffortTableHeadCell, TrueffortTableCell} from "../../MUIstyle/MUICustom";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import PaymentTabTRUE from "./PaymentTabTRUE";
import InstanceService from "../../services/instance.service";
import PaymentService from "../../services/payment.service";
import { formatDateString } from "../../util/DateDataFormat";
import {confirmAlert} from "react-confirm-alert";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningIcon from '@mui/icons-material/Warning';
import {dateToDateLocale} from "../../util/GlobalFunctions";


function PaymentHistoryTabTRUE() {
    const {user, updateUserInfo} = useAuthContext();
    const {t} = useTranslation();

    const [showPaymentPage, setShowPaymentPage] = React.useState(false);
    const instanceService = React.useMemo(() => new InstanceService(), []);
    const paymentService = React.useMemo(() => new PaymentService(), []);
    const [boughtInfo, setBoughtInfo] = React.useState(null);
    const [paymentHistory, setPaymentHistory] = React.useState([]);
    const [instanceInfo, setInstanceInfo] = React.useState(null);
    const [showLoading, setShowLoading] = React.useState(false);

    const divisas = ["", "USD", "MXN", "EUR"];

    React.useEffect(() => {
        fillBoughtInfo();
        fillPaymentHistory();
        fillInstanceInfo();
    }, []);

    function fillBoughtInfo() {
        instanceService.getLastBought().then((response) => {
            setBoughtInfo(response.data.data);
        });
    }

    function fillPaymentHistory() {
        paymentService.getSubscriptionsHistory().then((response) => {
            if (response.data.success) {
                setPaymentHistory(response.data.data);
            }
        });
    }

    function fillInstanceInfo() {
        instanceService.get(user.instance.idInstance).then((response) => {
            setInstanceInfo(response.data.data);
        });
    }

    React.useEffect(() => {
        if (!showPaymentPage) {
            fillBoughtInfo();
            fillPaymentHistory();
            fillInstanceInfo();
        }
    }, [showPaymentPage]);

    function cancelSubscription() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body custom-ui'>
                        <h3 style={{marginBottom: "1em"}}>{t("payment.cancel_msg_0")} <span className="dark"> </span></h3>
                        <p className="red"><strong>{t("payment.cancel_msg_1")}</strong></p>
                        <div className="react-confirm-alert-button-group">
                            <Button
                                onClick={() => {
                                    setShowLoading(true);
                                    try {
                                        // @ts-ignore
                                        paymentService.cancelSubscription(1).then(({data : response}) => {
                                            toast.success(t("payment.cancel_success"));
                                            setShowPaymentPage(false);
                                            setShowLoading(false);
                                        }).catch(function (error) {
                                            toast.error(t("payment.cancel_error"));
                                            setShowLoading(false);
                                        });
                                    } catch (error) {
                                        console.log("Error cancal subscription" + JSON.stringify(error));
                                        toast.error(t("payment.cancel_error"));
                                        setShowLoading(false);
                                    }
                                    onClose();
                                }}>
                                {t("payment.cancel_subscription")}
                            </Button>
                            <Button onClick={onClose}>{t("project_page.msg_delete_task_confirm_no")}</Button>
                        </div>
                    </div>
                );
            }
        });
    }

    return (
        <Container>
        {!showPaymentPage ?
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ background: "#050536", padding: '0.75rem' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                            <Box sx={{ background: "white", padding: "0.75rem", width: '100%', textAlign: "center" }}>
                                <h6 className="color-primary">{t("payment.next_payment_date")}</h6>
                                <h4>{typeof boughtInfo != "undefined" && boughtInfo != null ? formatDateString(boughtInfo?.endTime, "dd/MM/yyyy") : ""}</h4>
                            </Box>

                            <Box sx={{background: "white", padding: "0.75rem", width: '100%', textAlign: "center"}} >
                                <h6 className="color-primary">{t("total")}</h6>
                                <h4>{"$" + user.instance.paymentPlan.price.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})}</h4>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ background: "#050536", padding: '0.75rem' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                            <Box sx={{background: "white", padding: "0.75rem", width: '100%', textAlign: "center"}} >
                                <h6 className="color-primary">{t("payment.total_admins")}</h6>
                                <h4>{user.instance.paymentPlan.admins}</h4>
                            </Box>

                            <Box sx={{background: "white", padding: "0.75rem", width: '100%', textAlign: "center"}} >
                                <h6 className="color-primary">{t("payment.total_users")}</h6>
                                <h4>{user.instance.paymentPlan.users}</h4>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>

                <Grid xs={12}>
                    <Card sx={{padding:"0.375rem", color: "#4A4A4A"}}>
                        <Grid container spacing={1}>
                            <Grid xs={12} sm={8} md={10} >
                                <Typography variant='h6' color='#050536' >
                                    {instanceInfo?.paymentPlan?.name}
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={4} md={2}>
                                <TrueffortButton
                                    variant="contained"
                                    size="small"
                                    fullWidth
                                    sx={{marginTop: "-1rem!important"}}
                                    onClick={() => setShowPaymentPage(true)}
                                >
                                    Aumentar plan
                                </TrueffortButton>
                            </Grid>
                        </Grid>
                        <p style={{fontSize: "0.875rem", marginTop: ".5rem"}}>
                            {t("payment.plan_msg1", {
                                plan_start: typeof boughtInfo != "undefined" && boughtInfo != null ? formatDateString(boughtInfo?.startTime, "dd/MM/yyyy") : "-",
                                plan_end: typeof boughtInfo != "undefined" && boughtInfo != null ? formatDateString(boughtInfo?.endTime, "dd/MM/yyyy") : "-",
                                num_users: instanceInfo?.paymentPlan?.users,
                                num_admins: instanceInfo?.paymentPlan?.admins
                            })}
                        </p>
                    </Card>
                </Grid>

                <Grid xs={12}>
                    <Card sx={{padding: "0.6875rem 0.375rem"}}>
                    <TableContainer>
                    <TrueffortTable >
                        <TrueffortTableHead>
                            <TableRow>
                                <TrueffortTableHeadCell>
                                    {t("payment.payment_date")}
                                </TrueffortTableHeadCell>
                                <TrueffortTableHeadCell>
                                    {t("Description")}
                                </TrueffortTableHeadCell>
                                <TrueffortTableHeadCell>
                                    {t("payment.total_admins")}
                                </TrueffortTableHeadCell>
                                <TrueffortTableHeadCell>
                                    {t("payment.total_users")}
                                </TrueffortTableHeadCell>
                                <TrueffortTableHeadCell>
                                    {t("total")}
                                </TrueffortTableHeadCell>
                            </TableRow>
                        </TrueffortTableHead>
                        <TableBody>
                            {paymentHistory.map((p, k) =>
                                <TableRow>
                                    <TrueffortTableCell>
                                        {formatDateString(dateToDateLocale(new Date(p.updated)), "dd/MM/yyyy HH:mm:ss")}
                                    </TrueffortTableCell>
                                    <TrueffortTableCell>
                                        {p.description}
                                    </TrueffortTableCell>
                                    <TrueffortTableCell>
                                        {p.adminQuantity}
                                    </TrueffortTableCell>
                                    <TrueffortTableCell>
                                        {p.userQuantity}
                                    </TrueffortTableCell>
                                    <TrueffortTableCell>
                                        {"$" + p.price.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2}) + " " + divisas[p.idCurrency]}<br/>
                                        <Link href="#">{t("payment.request_invoice")}</Link>
                                    </TrueffortTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </TrueffortTable>
                    </TableContainer>
                    </Card>
                </Grid>

                <Grid xs={12}>
                    <Card sx={{padding:"0.375rem", color: "#4A4A4A"}}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid xs={12} sm={8} md={10} >
                                <Stack direction='row' spacing={1} alignItems='center' >
                                    <WarningIcon fontSize='small' sx={{ color: '#EF3E2D' }} />

                                    <Typography variant='h6' color='#050536' >
                                        {t("payment.cancel_subscription")}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12} sm={4} md={2} >
                                <TrueffortButton
                                    variant="contained"
                                    color='cinnabar'
                                    size="small"
                                    fullWidth
                                    onClick={cancelSubscription}
                                >
                                    {t("payment.cancel_subscription")}
                                </TrueffortButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid> :


            <PaymentTabTRUE _setShowPaymentPage={setShowPaymentPage}/>
        }
        </Container>
    );
}

export default PaymentHistoryTabTRUE;