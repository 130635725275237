import { Grid, Card, Box, Typography, IconButton, Avatar } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CardByList from "../custom/CardByList";
import CustomAvatar from "../custom/CustomAvatar";
import { formatDateString } from "../../util/DateDataFormat";
import CardsItemsStatistics from "./CardsItemsStatistics";
import { ResponsiveBar } from "@nivo/bar";
import {
  ExpandMore as ExpandMoreICon, ExpandLess as ExpandLessIcon, ArrowDropDown as ArrowDropDownIcon,
ArrowRight as ArrowRightIcon} from "@mui/icons-material";
import { ID_STATUS_COMPLETED, ID_STATUS_CREATED } from "../../util/Constants";
import CardTaskDetail from "./CardTaskDelay";
import ReportsService from "../../services/reports.service";
import CustomLoader from "../custom/CustomLoader";

function ProjectsReportByStatusTab ({ valuesSelectedUsers, valuesSelectedProjects, 
  valuesStatusSelect, inputSearch }) {
  
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showMore, setShowMore] = React.useState([]);
  const [showMoreListDelay, setShowMoreListDelay] = React.useState(false);
  const [showMoreListCreated, setShowMoreListCreated] = React.useState(false);
  const [projectsFiltered, setProjectsFiltered] = React.useState([]); 
  const reportsService = React.useMemo(() => new ReportsService(), []);
  const [projectsReport, setProjectsReport] = React.useState([]);

  React.useEffect(() => {
    setShowLoader(true);
    fillProjectsInProgressReport();
  }, []);

  React.useEffect(() => {
    if (inputSearch == "") { setProjectsFiltered(projectsReport); }
    else {
      let items = projectsReport.filter((p) => p.name.toLowerCase().includes(inputSearch.toLowerCase()));
      setProjectsFiltered(items);
    }
  }, [inputSearch]);

  React.useEffect(() => {    
    let items = projectsReport.flat();
    setProjectsFiltered(valuesStatusSelect.length > 0 ?
      items.filter((p) => valuesStatusSelect.map(p => p.value).includes(p.status.idStatus))
      : items);
  }, [projectsReport]);

  const fillProjectsInProgressReport = async () => {
    //console.log("fillProjectsInProgressReport");
    reportsService.globalProjectsStatus().then(({data : response}) => {
      if (response.success) {
        let data = response.data.map((p) => {
          let missingDuration = p.unfinishedTask.reduce((acc, cur) => acc + cur.estimatedDays, 0);
          let missingEffort = p.unfinishedTask.reduce((acc, cur) => acc + cur.estimatedTime, 0);
          let estFinishDate = new Date(p.estimatedFinishDate);
          estFinishDate.setDate(estFinishDate.getDate() + 1);
          estFinishDate.setHours(0);
          let currentDate = new Date();
          currentDate.setHours(0);
          let statusByDuration = true;
          let itemsTaskOrderByProjectedDate = p.unfinishedTask.sort((a,b) => new Date(b.projectedDate).getTime() - new Date(a.projectedDate).getTime());
          let projectedDate = null;
          if (itemsTaskOrderByProjectedDate.length > 0) {
            let pd = new Date(itemsTaskOrderByProjectedDate[0].projectedDate);
            pd.setDate(pd.getDate() + 1);
            projectedDate = getOnlyDateWithoutTime(pd);
          } else {
            projectedDate = getOnlyDateWithoutTime(currentDate);
          }
          if (p.status.idStatus === ID_STATUS_COMPLETED) {
            let finishDate = new Date(p.finishDate);
            finishDate.setDate(finishDate.getDate() + 1);
            statusByDuration = finishDate.getTime() <= estFinishDate.getTime();
            projectedDate = getOnlyDateWithoutTime(finishDate);
          } else {
            if (currentDate.getTime() >= estFinishDate.getTime()) {
              statusByDuration = false;
            } else {
              statusByDuration = projectedDate.getTime() <= estFinishDate.getTime();
            }
          }

          let statusByEffort = true;
          if (p.estimatedTime > p.dedicatedTime) {
            statusByEffort = false;
          } else {
            let ED = (p.estimatedTime - p.dedicatedTime) - missingEffort;
            statusByEffort = ED >= 0;
          }
          
          return {...p, missingDuration, missingEffort, statusByDuration, projectedDate, statusByEffort, showMore: false};
        });
        setProjectsReport(data);
      } else {
        console.error(response);
      }
    }).catch((ex) => console.error(ex)).finally(() => setShowLoader(false));
  }

  const getOnlyDateWithoutTime = (date) => {
    let date_ = new Date(date);
    date_.setHours(0);
    date_.setMinutes(0);
    date_.setSeconds(0);
    date_.setMilliseconds(0);

    return date_;
  }

  React.useEffect(() => {
    let items = projectsReport.flat();
    if (valuesSelectedProjects.length > 0) {
      items = items.filter((p) => valuesSelectedProjects.map(p => p.value).includes(p.idProject));
    }
    
    if (valuesStatusSelect.length > 0) {
      items = items.filter((p) => valuesStatusSelect.map(p => p.value).includes(p.status.idStatus));
    }

    if (inputSearch == "") { setProjectsFiltered(items); }
    else {
      items = items.filter((p) => p.name.toLowerCase().includes(inputSearch.toLowerCase()));
      setProjectsFiltered(items);
    }

    //setProjectsFiltered(items);
  }, [valuesSelectedUsers, valuesSelectedProjects, valuesStatusSelect, projectsFiltered]);

  const getGraphicComparative = (title, axisTitle, keys, data) => {
    return (
      <div style={{ height: '200px', width:'50%', paddingTop:'0.5em'}}>
        <div style={{ width:'100%', textAlign:'center', margin: '0 2rem', fontSize: '1rem', fontWeight:'bold'}}>
          {title}
        </div>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={'key'}
          layout="horizontal"
          margin={{ top: 5, right: 130, bottom: 50, left: 60 }}
          padding={0.1}
          valueScale={{ type: "linear" }}
          colors={["#E56033","#054279"]}
          colorBy="index"
          animate={true}
          enableLabel={true}
          theme={{labels: {text:{fontSize:'16px'}}}}
          labelTextColor={'white'}
          enableGridX={true}
          enableGridY={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5, tickPadding: 5,
            tickRotation: 0, legend: axisTitle,
            legendPosition: "middle", legendOffset: 30
          }}
          
        />
      </div>
    );
  }

  const getStatus = (status, type, text) => {
    return (
      <div style={{display:'flex'}}>
        <Avatar sx={{ bgcolor: status ? '#48be25' : '#be2525', width: '30px', height:'30px'}} >{""}</Avatar>
        <span style={{marginLeft: '0.5em'}}><span style={{fontWeight:'bold'}}>{type + ": "}</span> {text}</span>
      </div>)
  }

  const getCardTaskList = (title, showMore_, setShowMore_, listTask) => {
    return (
      <Card style={{padding:'0.5em'}}>
        <div style={{paddingBottom:'0.5em'}}>
          <Typography sx={{ fontSize: '0.9rem', fontWeight: '700' }} >
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowMore_(!showMore_)}
            >
                {showMore_ ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
            {title}
          </Typography>
        </div>
        {showMore_ && listTask.map((t) => (<CardTaskDetail task={t} />))}
      </Card>
    );
  }

  return (
    <>
    {!showLoader ?
      projectsFiltered.map((project) => (
        <CardByList key={project.idProject}>
          <Grid xs={12} container spacing={1}>
            <Grid item xs={12} style={{display:'flex', alignItems:'center', paddingBottom:'1em'}}>
                <CustomAvatar code={project.code}></CustomAvatar>
                <h5 style={{paddingLeft: '1em', margin: 0}}>{project.name}</h5>
            </Grid>
            <Grid item xs={2}>
              <div style={{paddingBottom:'1em'}}>{t("reports.projects.created_date")}</div>
              <span>{formatDateString(project.created, 'yyyy-MM-dd')}</span>
            </Grid>
            <Grid item xs={2}>
              <div style={{paddingBottom:'1em'}}>{t("reports.projects.start_date")}</div>
              <span>{project.startDate ? formatDateString(project.startDate, 'yyyy-MM-dd') : "-"}</span>
            </Grid>
            <Grid item xs={2}>
              <div style={{paddingBottom:'1em'}}>{t("reports.projects.estimated_finish_date")}</div>
              <span>{project.estimatedFinishDate ? formatDateString(project.estimatedFinishDate, 'yyyy-MM-dd') : "-"}</span>
            </Grid>
            <Grid item xs={6}>
              {project.projectedDate && getStatus(project.statusByDuration, t("reports.projects.type_duration"), project.statusByDuration ? 
                t("reports.projects.healthy_project_status_per_duration").replace("{0}", t("days." + project.projectedDate.getDay()))
                  .replace("{1}", project.projectedDate.getDate()).replace("{2}", t("months." + (project.projectedDate.getMonth() + 1)))
                  .replace("{3}", project.projectedDate.getFullYear()) 
                : t("reports.projects.risk_project_status_per_duration").replace("{0}", t("days." + project.projectedDate.getDay()))
                  .replace("{1}", project.projectedDate.getDate()).replace("{2}", t("months." + (project.projectedDate.getMonth() + 1)))
                    .replace("{3}", project.projectedDate.getFullYear()) 
                )}
              {getStatus(project.statusByEffort, t("reports.projects.type_effort"), project.statusByEffort ? t("reports.projects.healthy_project_status_per_effort") 
                : t("reports.projects.risk_project_status_per_effort"))}
            </Grid>
            {project?.status?.idStatus === ID_STATUS_COMPLETED &&
            <Grid item xs={2}>
              <div style={{paddingBottom:'1em'}}>{t("reports.projects.end_date")}</div>
              <span>{project.finishDate ? formatDateString(project.finishDate, 'yyyy-MM-dd') : "-"}</span>
            </Grid>}
          </Grid>
          {project.showMore && <>
            <Grid item xs={12} style={{padding:'0.3em'}}>
              <CardsItemsStatistics
                statistics={project.statistics} />
            </Grid>
            <Grid item xs={12} style={{height: '260px', padding:'0.3em'}}>
              <Card style={{height:'16em', display:'flex', flexDirection:'row'}}>
                  {getGraphicComparative(t("reports.duration"), t("estimation_page.days_duration"), ["duration"], [{duration: project.estimatedDuration, key:"Estimada"}, {duration: project.realDuration, key:"Real"}])}
                  {getGraphicComparative(t("reports.effort"), t("estimation_page.effort_man_hours"), ["hours"], [{hours: project.estimatedTime ? project.estimatedTime.toFixed(2) : 0, key:"Estimada"}, {hours: project.dedicatedTime ? project.dedicatedTime.toFixed(2) : 0, key:"Real"}])}
              </Card>
            </Grid>
            <Grid item xs={12} style={{padding: '0.3em'}}>
              {project.taskWithDelay.length > 0 && 
                getCardTaskList(t("reports.projects.task_delay_title").replace("{0}", project.taskWithDelay.length), 
                showMoreListDelay, setShowMoreListDelay, project.taskWithDelay)}
            </Grid>
            <Grid item xs={12} style={{padding: '0.3em'}}>
              {project.unfinishedTask.filter((t) => t.status.idStatus == ID_STATUS_CREATED).length > 0 && 
                getCardTaskList(t("reports.projects.task_in_creation_title").replace("{0}", project.unfinishedTask.filter((t) => t.status.idStatus == ID_STATUS_CREATED).length), 
                  showMoreListCreated, setShowMoreListCreated, project.unfinishedTask.filter((t) => t.status.idStatus == ID_STATUS_CREATED)
              )}
            </Grid>
          </>}
          <Grid item xs={12} style={{paddingLeft:'10px', paddingTop:0}}>
            <Box className="show-more-div sand600-color" sx={{fontSize: "0.75rem", marginTop: "0.75rem"}} 
              onClick={() => {
                let items = projectsFiltered.flat();
                let ind = projectsFiltered.findIndex((p) => p.idProject == project.idProject);
                items[ind].showMore = !items[ind].showMore;
                setProjectsFiltered(items);
                //setShowMore(!showMore);
                }}>
              {!project.showMore && <><ExpandMoreICon sx={{fontSize: ".9rem"}} /> {t("show_more")}</>}
              {project.showMore && <><ExpandLessIcon sx={{fontSize: ".9rem"}} /> {t("show_less")}</>}
            </Box>
          </Grid>
      </CardByList>
      )) : 
      <CustomLoader />
    }
    </>
  );
}

export default ProjectsReportByStatusTab;