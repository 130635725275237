export const formatDateString = (date:any, format:string, t?:any) => {
  let dt = new Date(date);
  dt = new Date(dt.getTime() + dt.getTimezoneOffset() * 60000);
  let result = "";
  let day = dt.getDate();
  let month = dt.getMonth() + 1;
  let year = dt.getFullYear();
  let hour  = dt.getHours();
  let minutes = dt.getMinutes();
  let seconds = dt.getSeconds();

  switch (format) {
    case "dd/MM/yyyy HH:mm:ss":
      result = (day <= 9 ? ("0" + day) : day) + "/" + (month <= 9? ("0" + month): month) + "/" + year + " " +
        (hour <= 9? ("0" + hour): hour) + ":" + (minutes <= 9?("0" + minutes): minutes) + ":"+ (seconds <= 9? ("0" + seconds):seconds);
      break;
    case "dd/MM/yyyy":
      result = (day <= 9 ? ("0" + day) : day) + "/" + (month <= 9? ("0" + month): month) + "/" + year;
      break;
    case 'yyyy-MM-dd':
      result = year + "-"+(month <= 9? ("0" + month): month)+"-"+(day <= 9 ? ("0" + day) : day);
      break;
    case 'MM-dd':
      let month_T = t ? t("months_reduced." + month) : null;
      result = t ? result = (month_T) + "-" + (day <= 9 ? ("0" + day) : day) :
        (month <= 9? ("0" + month): month) + "-" + (day <= 9 ? ("0" + day) : day);
      break;
    case 'HH:mm':
      result = (hour <= 9? ("0" + hour): hour) + ":" + (minutes <= 9?("0" + minutes): minutes);
      break;
    case 'HH:mm:ss':
      result = (hour <= 9? ("0" + hour): hour) + ":" + (minutes <= 9?("0" + minutes): minutes) + ":"+ (seconds <= 9? ("0" + seconds):seconds);
      break;
  }

  return result;
}
