import {
    Chip, FormHelperText,
    ListItemIcon,
    ListItemText, Menu,
    MenuItem,
    Stack, TextField,
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import PendingIcon from "@mui/icons-material/Pending";
import {StyledTableCell, StyledTableRow, TrueffortButton} from "../../MUIstyle/MUICustom";
import EditIcon from "@mui/icons-material/Edit";
import {Close as CloseIcon, Save as SaveIcon} from "@mui/icons-material";
import DatePicker from "react-datepicker";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomAvatar from "../custom/CustomAvatar";


export default function InstanceRow({ license, isEditingState, formik, deleteInstance }: any) {
    const {t} = useTranslation();

    const [isEditingInstance, setIsEditingInstance] = React.useState(false);
    if (isEditingInstance && !isEditingState.isEditing) {
        setIsEditingInstance(false);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);

    /*const getLastPaymentDate = ()  => {
        const lastPaymentDay = new Date();
        if (license.instanceSubscription !== null && license.bought !== null) {
            const subcriptionUdate: Date = new Date(license.instanceSubscription.updated);
            const boughtStartTime: Date = new Date(license.bought.startTime);

            if (subcriptionUdate > boughtStartTime) {
                lastPaymentDay.setTime(license.instanceSubscription.updated);
            } else {
                lastPaymentDay.setTime(license.bought.startTime);
            }
        } else if (license.instanceSubscription !== null && license.bought === null) {
            lastPaymentDay.setTime(license.instanceSubscription.updated);
        } else if (license.instanceSubscription === null && license.bought !== null) {
            lastPaymentDay.setTime(license.bought.startTime);
        }

        return lastPaymentDay.getDate() + "/" + (lastPaymentDay.getMonth() + 1) + "/" + lastPaymentDay.getFullYear();
    }*/

    const prepareEdit = () => {
        const values = {
            idInstance: license.idInstance,
            totalUsers: license.totalUsers,
            newExpirationDate: new Date(license.expirationDate)
        }
        formik.setValues(values);
        isEditingState.setIsEditing(true);
        setIsEditingInstance(true);
    }

    const getDateString = (timestamp: number) => {
        const date: Date = new Date(timestamp);
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    }

    const handleClickOptions = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorEl(null);
    };

  return (
    <StyledTableRow >
      <StyledTableCell >
        {license.company}
      </StyledTableCell>

      <StyledTableCell >
        <CustomAvatar url={license.instanceSuperUser?.imageDto?.image_id}
          text={license.instanceSuperUser !== null ?
          license.instanceSuperUser.name + " " + (license.instanceSuperUser.lastname === undefined ? license.instanceSuperUser.lastName : license.instanceSuperUser.lastname) : ""}
        />
      </StyledTableCell>

      <StyledTableCell align='center' >
        <Chip
          size='small'
          variant='outlined'
          color={ license.lastPaymentDate !== null ? 'success' : 'secondary' }
          label={
            license.lastPaymentDate !== null ?
              t("instances_page.last_payment_made") :
              t("instances_page.last_payment_not_paid")
          }
        />
      </StyledTableCell>

      <StyledTableCell >
        {license.lastPaymentDate === null ? '-' : getDateString(license.lastPaymentDate)}
      </StyledTableCell>

      <StyledTableCell >
        {license.instanceSuperUser !== null ? license.instanceSuperUser.phone : '-'}
      </StyledTableCell>

      <StyledTableCell >
        {license.instanceSuperUser !== null ? license.instanceSuperUser.email : '-'}
      </StyledTableCell>

      <StyledTableCell align='center' >
        <Chip
          size='small'
          label={license.paymentPlan.name}
        />
      </StyledTableCell>

      <StyledTableCell >
        {isEditingInstance && isEditingState.isEditing ?
          <TextField
              name="totalUsers"
              type="number"
              min="1"
              size="small"
              variant="filled"
              maxLength={6}
              //value={license.totalUsers}
              //disabled={el.status_id == 4}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.totalUsers}
              error={formik.errors?.totalUsers && formik.touched?.totalUsers ? formik.errors.totalUsers : null}
              helperText={formik.errors?.totalUsers && formik.touched?.totalUsers ? formik.errors.totalUsers : null}
          /> :
          license.registeredUsers + "/" + license.totalUsers
        }
      </StyledTableCell>

      <StyledTableCell >
        {getDateString(license.startDate)}
      </StyledTableCell>

      <StyledTableCell >
        {isEditingInstance && isEditingState.isEditing ?
          <Stack direction='column' spacing={0} >
            <DatePicker
                name="newExpirationDate"
                showIcon
                selected={formik.values.newExpirationDate}
                onChange={(date: Date) => formik.setFieldValue("newExpirationDate", date)}
            />
            {(formik.errors?.newExpirationDate && formik.touched?.newExpirationDate) ?
              <FormHelperText error >
                  {t(formik.errors.newExpirationDate)}
              </FormHelperText> :
              null
            }
          </Stack> :
          license.expirationDate === null ? '-' : getDateString(license.expirationDate)
        }
      </StyledTableCell>

      <StyledTableCell align='center' >
        <Chip
          size='small'
          variant='outlined'
          color={ license.activeByPayment || license.onTrial ? 'success' : 'error'}
          label={ license.activeByPayment || license.onTrial ? t("instances_page.license_active") :
            t("instances_page.license_expired")
          }
        />
      </StyledTableCell>
      <StyledTableCell >
        {isEditingInstance && isEditingState.isEditing ?
          <Stack direction="column" alignItems='center' >
            <TrueffortButton style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
              size="small"
              color="primary"
              id="btnSaveEdit"
              aria-controls={openOptions ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openOptions ? 'true' : undefined}
              onClick={() => formik.handleSubmit()}
            >
              <SaveIcon />
            </TrueffortButton>
            <TrueffortButton style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
              size="small"
              color="secondary"
              id="btnCancelEdit"
              aria-controls={openOptions ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openOptions ? 'true' : undefined}
              onClick={() => {
                //formik.resetForm();
                setIsEditingInstance(false);
                isEditingState.setIsEditing(false);
              }}
            >
              <CloseIcon />
            </TrueffortButton>
          </Stack> :

          <>
            <TrueffortButton
              style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
              size="small"
              color="secondary"
              id="btnOptionsElement"
              aria-controls={openOptions ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openOptions ? 'true' : undefined}
              disabled={isEditingState.isEditing && !isEditingInstance}
              onClick={handleClickOptions}
            >
              <PendingIcon />
            </TrueffortButton>

            <Menu
              id="basic-menu"
              sx={{'& .MuiPaper-root': { backgroundColor: '#050536' } }}
              anchorEl={anchorEl}
              open={openOptions}
              onClose={handleCloseOptions}
              MenuListProps={{'aria-labelledby': 'basic-button'}}
            >
              <MenuItem
                onClick={() => {
                  handleCloseOptions();
                  prepareEdit();
                }}
              >
                <ListItemIcon>
                  <ListItemIcon>
                    <EditIcon
                      size="small"
                      sx={{color: '#F5F5F5'}}
                    />
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText sx={{ color: '#F5F5F5'}}>
                    {t("Edit")}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseOptions();
                  deleteInstance(license.idInstance);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon
                    size="small"
                    //className="color-red"
                    sx={{ color: '#989898' }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ color: '#989898' }} >
                  {t("delete")}
                </ListItemText>
              </MenuItem>
            </Menu>
          </>
        }
      </StyledTableCell>
    </StyledTableRow>
  );
}