import {InputLabel, TextField} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

export default function ApiInfoForm({t, selectedAPI, organizationDomainState, emailState, tokenState, errors}: any) {

    return(
        <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid xs={12} md={6} lg={5}>
                <InputLabel
                    sx={{
                        color: '#050536',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '1em',
                    }}
                >
                    {selectedAPI === "Jira REST API" ? t('Herramienta.domain_users') : t('Herramienta.organization_label')}
                </InputLabel>
                <Tooltip
                    title={
                    selectedAPI === "Jira REST API" ?
                        t('Herramienta.domainj_help_text') :
                        t('Herramienta.domaina_help_text')
                    }
                >
                    <TextField
                        id="organizationDomain"
                        name="organizationDomain"
                        size="small"
                        margin="none"
                        required
                        fullWidth
                        inputProps={{ style: { fontFamily: 'Inter, sans-serif' } }}
                        placeholder={selectedAPI === "Jira REST API" ? t('Herramienta.domain_placeholder') : t('Herramienta.organization_placeholder')}
                        value={organizationDomainState.organizationDomain} //Valor del campo de entrada
                        onChange={(event: any) => organizationDomainState.setOrganizationDomain(event.target.value)} //Función para actualizar el estado organizationDomain al cambiar el valor
                        error={errors.organizationDomain} //Muestra un error si errors.organizationDomain es verdadero
                        helperText={errors.organizationDomain} //Muestra el texto de error en errors.organizationDomain
                    />
                </Tooltip>
            </Grid>

            {selectedAPI === "Jira REST API" &&
                <Grid xs={12} md={6} lg={5}>
                    <InputLabel
                        sx={{
                            color: '#050536',
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '1em',
                        }}
                    >
                        {t('Herramienta.email_users')}
                    </InputLabel>
                    <Tooltip title={t('Herramienta.emailj_help_text')}>
                        <TextField
                            id="email"
                            name="email"
                            size="small"
                            margin="none"
                            required
                            fullWidth
                            inputProps={{ style: { fontFamily: 'Inter, sans-serif' } }}
                            placeholder={t('Herramienta.email_placeholder')}
                            value={emailState.email}
                            onChange={(event: any) => emailState.setEmail(event.target.value)}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </Tooltip>
                </Grid>
            }

            <Grid xs={12} md={6} lg={5}>
                <InputLabel
                    sx={{
                        color: '#050536',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '1em',
                    }}
                >
                    {t('Herramienta.token_users')}
                </InputLabel>
                <Tooltip
                    title={
                    selectedAPI === "Jira REST API" ?
                        t('Herramienta.tokenj_help_text') :
                        t('Herramienta.tokena_help_text')
                    }
                >
                    <TextField
                        id="token"
                        name="token"
                        size="small"
                        margin="none"
                        type="password"
                        required
                        fullWidth
                        inputProps={{ style: { fontFamily: 'Inter, sans-serif' } }}
                        placeholder={t('Herramienta.token_placeholder')}
                        value={tokenState.token}
                        onChange={(event: any) => tokenState.setToken(event.target.value)}
                        error={!!errors.token}
                        helperText={errors.token}
                    />
                </Tooltip>
            </Grid>

            {/*<Grid xs={12} md={6} lg={5}>
                 Se llama a la función para obtener los proyectos
               <TrueffortButton variant="contained" color="primary" onClick={getProjects}>
                     {t('Herramienta.get')}
               </TrueffortButton>
            </Grid>*/}
        </Grid>
    )
}