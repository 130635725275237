import { TrueffortNavButton } from "../../MUIstyle/MUICustom";
import { CalendarToday, KeyboardArrowDown } from "@mui/icons-material";
import CustomIconTrueffortScreen from "./CustomIconTrueffortScreen";

/**
 * DatePicker
 */
// @ts-ignore
const CustomDatePickerInput = ({value, onClick, label}) => (
  <TrueffortNavButton RootComponent="a"
    icon="calendar" className="btn-datepicker"
    onClick={onClick}>
      <CustomIconTrueffortScreen icon={<CalendarToday />} />
        {value ? value : label}
      <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
  </TrueffortNavButton>
);

export default CustomDatePickerInput;