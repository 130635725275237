import {ImageList, ImageListItem, ImageListItemBar} from "@mui/material";

const imageData = [
    {
        img: 'atlassian_jira_logo_icon_170511.png',
        title: 'Jira'
    },
    {
        img: 'azure_devops_logo_icon_145466.png',
        title: 'Azure Devops'
    }
]

export default function APISelect({ handleToolSelect }: any) {

    return (
        <ImageList cols={imageData.length}  sx={{display: 'flex', justifyContent: 'space-evenly'}} >
            {imageData.map((item: { img: string, title: string }, index: number) =>
                <ImageListItem
                    key={item.img}
                    sx={{ width: 200, height: 200 }}
                    component='button'
                    onClick={() => handleToolSelect(index)}
                >
                    <img src={"./assets/images/" + item.img} alt={item.title} />
                    <ImageListItemBar title={item.title} position="bottom" />
                </ImageListItem>
            )}
        </ImageList>
    );
}