// @flow

import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./contexts/AuthenticationContext";
import {I18nextProvider} from "react-i18next";
import i18n from "./internationalization/i18n";

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <I18nextProvider i18n={i18n}>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </I18nextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
