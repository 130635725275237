import React, {createContext} from "react";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {User} from "../../util/Interfaces";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {
    TextField, MenuItem, Button, Stack, Collapse, Typography, Menu, Select, Paper, ListItemIcon, ListItemText,
    SvgIcon, Chip, Box, Avatar, AvatarGroup, Card, TableRow, TableBody, TableCell, Table, Icon, CardActions
} from "@mui/material";
import {useHistory, Link} from "react-router-dom";
import {East as EastIcon, KeyboardArrowDown, ExpandMore as ExpandMoreICon, Add as AddIcon, Cancel as CancelIcon,
        RemoveRedEye as RemoveRedEyeIcon} from '@mui/icons-material';
import { formatDateString } from "../../util/DateDataFormat";
import {TrueffortButton, TrueffortTable, TrueffortTableHead, TrueffortTableHeadCell, TrueffortTableCell,
        TrueffortSearchInput, globalTheme, TrueffortNavButton, TrueffortButtonSmall} from "../../MUIstyle/MUICustom";
import CustomIconTrueffortScreen from "../../components/custom/CustomIconTrueffortScreen";
import {ID_ROLE_ADMIN, MIN_LEVEL_LEADER} from "../../util/Constants";
import {toast} from "react-toastify";
import ProjectTaskCommentService from "../../services/projectTaskComment.service";
import JoditEditor from 'jodit-react';
import Comment from "./Comment";
import AssignUsersDrawer from "../AssignUsersDrawer";
import ProjectTaskService from "../../services/projectTask.service";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {styled} from "@mui/material/styles";


export enum UserStatus {
    Unassigned = 0,
    Assigned = 1,
    Saved = 3
}

const VisuallyHiddenInput: any = styled('input')({
    //clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

// @ts-ignore
function TaskCard({task, usersAll, taskType, statusList, _updateTaskStatus, _handleChangeTaskStatus}) {
    const history = useHistory();
    const {t} = useTranslation();
    const { user } = useAuthContext() as { user: User };

    const projectTaskService = React.useMemo(() => new ProjectTaskService(), []);
    const projectTaskCommentService = React.useMemo(() => new ProjectTaskCommentService(), []);
    const [selectedIndexTaskForChangeStatus, setSelectedIndexTaskForChangeStatus] = React.useState("");
    const [anchorElemFilter, setAnchorElemFilter] = React.useState(null);
    const [menuNumber, setMenuNumber] = React.useState(0);
    const openOptions = Boolean(anchorElemFilter);
    const [showMore, setShowMore] = React.useState(false);
    const [showAddComment, setShowAddComment] = React.useState(false);
    const [showAddFile, setShowAddFile] = React.useState(false);
    const [content, setContent] = React.useState('');
    const [ptComments, setPtComments] = React.useState([]);
    const [fileToUpload, setFileToUpload] = React.useState(null);
    const editor = React.useRef(null);
    const [showModal, setShowModal] = React.useState(false);
    const [rankedUsers, setRankedUsers] = React.useState(
        taskType && Array.isArray(taskType?.users) ? taskType?.users.map((user: any) => (
            {
                ...user,
                status: task.userList.findIndex((usr: any) => usr.idUser === user.id) !== -1 ?
                    UserStatus.Saved :
                    UserStatus.Unassigned
            }
        )) : []
    );
    const [notRankedUsers, setNotRankedUsers] = React.useState(() => {
        const ttUsers = taskType && Array.isArray(taskType?.users) ? taskType?.users.map((user: any) => user.id) : [];
        return usersAll.filter((user: any) => !ttUsers.includes(user.idUser)).map(
            (user: any) => ({
                id: user.idUser,
                name: user.name,
                lastname: user.lastName,
                email: user.email,
                effort_interp_avg: taskType ? taskType.effort_avg : 0,
                image_id: user.imageDto?.idImage,
                image_url: user.imageDto?.url,
                status: task.userList.findIndex((usr: any) => usr.idUser == user.idUser) !== -1 ?
                    UserStatus.Saved :
                    UserStatus.Unassigned
            })
        );
    });
    const [usersTask, setUsersTask] = React.useState(task.userList.map((usr: any) => ({
        ...usr,
        effort_interp_avg: !usr.effort_interp_avg ? (taskType ? taskType.effort_avg : 0) : usr.effort_interp_avg
    })));

    const config = {
        placeholder: '...',
        buttons: ["bold","italic","underline","strikethrough","eraser","ul","ol","font","fontsize","paragraph","lineHeight","superscript","subscript","classSpan","speechRecognize","spellcheck","cut","copy","paste"],
        toolbarAdaptive: false
    }

    const handleClickMenu = (event:any, value:any) => {
        setAnchorElemFilter(event.currentTarget);
        setMenuNumber(value);
    }

    function getProjectTaskComments() {
        // @ts-ignore
        projectTaskCommentService.getByProjectTask(task.idProjectTask).then(({data: response}) => {
            if (Array.isArray(response.data)) {
                setPtComments(response.data);
            }
        });
    }

    const handleCreateComment = () => {
        const newComment = {
            idProjectTask: task.idProjectTask,
            comment: content
        }
        // @ts-ignore
        projectTaskCommentService.create(newComment).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                setShowAddComment(false);
                setContent("");
                toast.success(t("daily_page.msg_success_comment_add"));
            }
            getProjectTaskComments();
        }).catch(function (error: any) {
            console.log(error);
            toast.error(t("daily_page.msg_comment_add_error"));
        });
    }

    const handleUploadFile = () => {
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);

        // @ts-ignore
        projectTaskCommentService.uploadFile(task.idProjectTask, formData).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                setShowAddFile(false);
                setFileToUpload(null);
                toast.success(t("daily_page.msg_success_file_upload"));
            }
            getProjectTaskComments();
        }).catch(function (error: any) {
            console.log(error);
            toast.error(t("daily_page.msg_file_upload_error"));
        });
    }

    const saveAssignedUsers = (assignedUsers: any) => {
        const usersProjectTask = {
            idProjectTask: task.idProjectTask,
            updatedTaskEffort: 0,
            updatedTaskDuration: 0,
            users: assignedUsers.map((usr: any) => usr.id),
            changeValuesOnAssignedUsers: false
        };

        // NO SE ACTUALIZA LA ESTIMACIÓN

        // @ts-ignore
        projectTaskService.assignUsers(task.idProjectTask, usersProjectTask).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                toast.success(t("project_tasks_page.user_assigned"));
                _updateTaskStatus();
            }
        });
    }

    const handleFileChange = (e: any) => {
        setFileToUpload(e.target.files[0]);
        setShowAddFile(true);
    }

    const dummyFunction = () => {}

    return (
        <Grid
            container
            spacing={1}
            sx={{border: "solid thin #64A8EC", borderRadius: "10px", marginBottom: "12px", padding: "4px 12px", borderLeft: " solid 8px #64A8EC"}}
        >
            {/* PROYECTO / FASE / TAREA */}
            <Grid xs={12} sm md={5} >
                <Table size="small" className="table-card-element-phase-task">
                <TableBody>
                    <TableRow>
                        <TableCell sx={{maxWidth: "40px!imporant", width: "40px!important"}}><span className="home-project-detail-label">{t("Project")}: </span></TableCell>
                        <TableCell><span className="title-element-card">{task.projectName}</span></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><span className="home-project-detail-label">{t("reports.element")}: </span></TableCell>
                        <TableCell><span className="title-element-card">{task.elementName}</span></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><span className="home-project-detail-label">{t("reports.phase")}: </span></TableCell>
                        <TableCell><span className="title-element-card">{task.phaseTypeName}</span></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><span className="home-project-detail-label">{t("daily_page.task")}</span></TableCell>
                        <TableCell><span className="title-element-card">{task.taskTypeName}</span></TableCell>
                    </TableRow>
                </TableBody>
                </Table>
            </Grid>
            {/* FECHAS */}
            <Grid xs={12} md={3} sx={{ marginBottom: '0.5rem' }}>
                <span className="home-project-detail-label">{t("start_date")} <EastIcon sx={{fontSize: 10, marginLeft: ".2rem", marginRight: ".2rem"}} /> {t("end_date")}</span>
                <Box sx={{display: "block", fontSize: ".87rem"}} className="color-primary">
                    {task.startDate == null ? "--" : formatDateString(task.startDate, "dd/MM/yyyy")}
                    <EastIcon sx={{fontSize: 10, marginLeft: ".2rem", marginRight: ".2rem"}} />
                    {task.endDate == null ? "--" : formatDateString(task.endDate, "dd/MM/yyyy")}
                </Box>
            </Grid>

            {/* TODO: AGREGAR TIPO */}

            {/* ESTADO */}
            <Grid xs={12} md={2} sm={3} sx={{ marginBottom: '0.5rem' }}>
                <span className="home-project-detail-label"><Box sx={{display: "block"}}>{t("home.status")}</Box></span>
                {user.role.accessLevel >= MIN_LEVEL_LEADER ?
                    <TrueffortNavButton
                        id="idSelectedStatusesTask"
                        size="small"
                        variant="contained"
                        onClick={(event:any) => {setSelectedIndexTaskForChangeStatus(task.idProjectTask);handleClickMenu(event, 1);}}
                        noneHeight={true}
                        className={"item-status-" + task.idStatus}
                    >
                        {task.statusName}
                        <CustomIconTrueffortScreen icon={<KeyboardArrowDown />} />
                    </TrueffortNavButton> :
                    <TrueffortNavButton
                        id="idSelectedStatusTaskRoleUser"
                        size="small"
                        variant="contained"
                        noneHeight={true}
                        className={"item-list-status-" + task.idStatus}
                    >
                        {task.statusName}
                    </TrueffortNavButton>
                }
                <Menu id="idElementsFilterDashboards"
                                    anchorEl={anchorElemFilter}
                                    open={openOptions}
                                    onClose={() => setAnchorElemFilter(null)}
                                >
                    {menuNumber == 1 && <div>
                        {statusList.map((s:any, i:number) => (
                            <MenuItem id={"idStatus_"+(i+1)} key={i}
                                className={"item-status-" + s.idStatus}
                                sx={{fontSize: ".8rem!important"}}
                                onClick={() => _handleChangeTaskStatus(selectedIndexTaskForChangeStatus, s.idStatus)}>
                                {s.name}
                            </MenuItem>
                    ))}
                    </div>}
                </Menu>
            </Grid>
            {/* TRABAJANDO AQUÍ */}
            <Grid xs={12} sm md={2} sx={{ marginBottom: '0.5rem' }}>
                <Box sx={{display: "block"}}><span className="home-project-detail-label">{t("home.working_here")}</span></Box>
                <a data-tooltip-id="tooltip-assigned_users">
                <AvatarGroup max={4} sx={{float: "left", marginBottom: ".5rem"}}>
                    {task.userList.map((us:any, ind:number) =>
                        <Avatar
                            key={us.idUser}
                            alt={us.name}
                            src={us.imageDto?.url}
                            sx={{ width: 28, height: 28 }} />
                    )}
                </AvatarGroup>
                </a>
                <ReactTooltip id="tooltip-assigned_users" place="top" variant="info">
                    {
                        task.userList.map((us:any, ind:number) => us.name).join(", ")
                    }
                </ReactTooltip>

                {/* BOTÓN PARA ASIGNAR USUARIOS */}
                <TrueffortButton
                    id="idBtnAssignUser"
                    color="primary"
                    variant="contained"
                    size="small"
                    className="btn-assign-usr"
                    onClick={() => setShowModal(true)}
                    >
                    <AddIcon sx={{fontSize: ".8rem"}} />
                    {t("daily_page.assign_user")}
                </TrueffortButton>
            </Grid>
            {/* BARRA (BOTÓN) VER MÁS */}
            {!showMore &&
                <Grid xs={12}>
                    <Box className="show-more-div sand600-color" sx={{fontSize: "0.75rem", marginTop: "-8px"}}
                        onClick={() => {setShowMore(true); getProjectTaskComments();}}>
                        <ExpandMoreICon sx={{fontSize: ".9rem"}} /> {t("show_more")}
                    </Box>
                </Grid>
            }

            {/* CONTENIDO DE DETALLE */}
            <Collapse
                in={showMore}
                timeout="auto"
                unmountOnExit
                sx={{width: "100%"}}
            >
                {/* COMENTARIOS */}
                <Box sx={{display: "flex", marginTop: "2rem"}}>
                    <Box sx={{flexGrow: 1}}>
                        <h5>Comentarios:</h5>
                    </Box>
                    <Box>
                        <Stack spacing={1} direction='row' >
                            <TrueffortButton
                                component="label"
                                role={undefined}
                                tabIndex={-1}
                                variant="outlined"
                                startIcon={<CloudUploadIcon />}
                                disabled={showAddComment || showAddFile}
                            >
                                {t("daily_page.upload_file")}
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={(e: any) => handleFileChange(e)}
                                />
                            </TrueffortButton>

                            <TrueffortButton
                                id="idBtnAddComment"
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={() => setShowAddComment(true)}
                                startIcon={<AddIcon />}
                                disabled={showAddComment || showAddFile}
                            >
                                {t("daily_page.add_comment")}
                            </TrueffortButton>
                        </Stack>
                    </Box>
                </Box>

                <Stack spacing={2}>
                    {showAddComment &&
                        <Card sx={{padding: "8px", backgroundColor: "#f9f9f9"}}>
                            {t("daily_page.add_comment")}
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={config}
                                buttons="bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,superscript,subscript,classSpan,speechRecognize,spellcheck,cut,copy,paste"
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent:string) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                onChange={(newContent:string) => {}}
                            />

                            <CardActions>
                                <TrueffortButton
                                    id="idBtnAddCommentSave"
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleCreateComment()}
                                >
                                    {t("elements.buttons.save")}
                                </TrueffortButton>
                                <TrueffortButton
                                    id="idBtnAddCommentCancel"
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => setShowAddComment(false)}
                                >
                                    {t("cancel")}
                                </TrueffortButton>
                            </CardActions>
                        </Card>
                    }
                    {showAddFile &&
                        <Card sx={{padding: "8px", backgroundColor: "#f9f9f9"}}>
                            {t("daily_page.upload_file")}:
                            <Stack direction='column' spacing={0} >
                                <InsertDriveFileIcon fontSize='large' />
                                {fileToUpload.name}
                            </Stack>

                            <CardActions>
                                <TrueffortButton
                                    id="idBtnAddCommentSave"
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleUploadFile()}
                                >
                                    {t("elements.buttons.save")}
                                </TrueffortButton>
                                <TrueffortButton
                                    id="idBtnAddCommentCancel"
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setFileToUpload(null);
                                        setShowAddFile(false);
                                    }}
                                >
                                    {t("cancel")}
                                </TrueffortButton>
                            </CardActions>
                        </Card>
                    }
                    {ptComments.map((c:any, k:number) =>
                        <Comment
                            comment={c}
                            user={(usersAll.find((usr:any) => {return usr.idUser == c.idUser}))}
                        />
                    )}
                </Stack>
            </Collapse>

            {/* BARRA (BOTÓN) VER MENOS */}
            {showMore &&
                <Grid xs={12} className="background-gray1 show-more-div">
                    <Grid container>
                        <Grid xs={6}>
                            <Box className="show-more-div color-sand500" onClick={() => setShowMore(false)} sx={{fontSize: "0.75rem", justifyContent: "right"}}>
                                <CancelIcon fontSize="small" sx={{fontSize: ".9rem"}} /> {t("show_less")}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <AssignUsersDrawer
                open={showModal}
                anchor='right'
                onClose={() => setShowModal(false)}
                users={{
                    notRankedUsers: notRankedUsers,
                    rankedUsers: rankedUsers.sort((usr1: any, usr2: any) => usr2.ranking - usr1.ranking)
                }}
                setUsers={{
                    setNotRankedUsers: setNotRankedUsers,
                    setRankedUsers: setRankedUsers
                }}
                taskName={task.taskTypeName}
                assignUsers={saveAssignedUsers}
                automaticEstimatesState={{
                    changeValuesOnAssignedUsers: dummyFunction,
                    handleEstimatesChangeOnAssignedUser: dummyFunction
                }}
                page='detail'
            />
        </Grid>
    );
}

export default TaskCard;