import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ScheduleService {

    getAll() {
        return http?.get(baseURL.activitiesapi.schedules + `/`);
    }

    findByBetweenDays(startDate: any, endDate: any) {
        return http?.get(baseURL.activitiesapi.schedules + "/findByBetweenDays?startDate="+startDate+"&endDate="+endDate);
    }

    create(data: any) {
        return http?.post(baseURL.activitiesapi.schedules + "/", data);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.schedules + `/${id}`);
    }

    update(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.schedules + `/${id}`, data);
    }
}

export default ScheduleService;