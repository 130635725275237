import * as React from "react";
import {Card, Grid} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import "../css/Trial.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
const cardButtonAction = () => {
    openInNewTab("https://trueffort.com/#contactanos");
};

const BuyComplete = ({trialPeriod, remainingDays = -1}) => {
    const {t} = useTranslation();
    const [display, setDisplay] = React.useState(true);

    return (
      <Grid className={`trial-row ${(display)?"":"trial-hide"}`} >
        <Card style={{paddingBottom: '1em', width:'100%'}}>
          <div style={{display:'flex', width:'100%', justifyContent:'end', paddingRight:'0.5em'}}>
            <CloseIcon onClick={() => { setDisplay(!display)}} />
          </div>
          <div style={{textAlign: "center"}}>
              <FontAwesomeIcon className="alert-left" icon={['fas', 'exclamation-triangle']}/>
              <FontAwesomeIcon className="alert-right"  icon={['fas', 'exclamation-triangle']}/>
              <h4 style={{color: "#2f66b3"}}>{(!trialPeriod) ? t("pricing.trial_expired") :
                  t("pricing.days_left_head") + remainingDays + t("pricing.days_left_tail")}</h4>
              {(!trialPeriod) ? t("pricing.complete_access") : t("pricing.definitive_access")}
              <b style={{color: "#2f66b3", "cursor": "pointer"}}
                onClick={() => cardButtonAction()} color={"primary"}> {t("Contact")}</b>.
          </div>
        </Card>
      </Grid>
    );
};

export default BuyComplete;