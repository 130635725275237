import React from "react";
import {useTranslation} from "react-i18next";
import {User} from "../../util/Interfaces";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import {useHistory, Link} from "react-router-dom";
import {MONTHS} from "../../util/Constants";
import {Box, Chip, AvatarGroup, Avatar, Grid, Stack, Collapse} from "@mui/material";
import {Cancel as CancelIcon, ExpandMore as ExpandMoreICon, Speed as SpeedIcon,
  Assignment as AssignmentIcon, TrendingUp as TrendingUpIcon, East as EastIcon, 
  RemoveRedEye as RemoveRedEyeIcon} from '@mui/icons-material';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const TYPE_PHASES = 1;
const TYPE_TASKS = 2;
const TYPE_ELEMENTS = 3;

// @ts-ignore
function MiniCard ({id, title, unit, value, subtitle, type}) {
  return(
    <div style={{background: "#F5F5F5", margin: "8px", color: "#050536", padding: "8px"}}>
      <div key={"_3.5.1.1"} style={{display:"flex", width:"100%"}}>
        <div style={{display: "flex", flexDirection:"column"}}>
          <div style={{minHeight: "1.4em"}}>
            <div style={{fontWeight: "bold", fontSize: "0.8rem"}}>
              {type == 1 && <SpeedIcon fontSize="small" sx={{marginBottom: "-.2em", marginLeft: "-4px"}}/>}
              {type == 2 && <AssignmentIcon fontSize="small" sx={{marginBottom: "-.2em", marginLeft: "-4px"}}/>}
              {type == 3 && <TrendingUpIcon fontSize="small" sx={{marginBottom: "-.2em", marginLeft: "-4px"}}/>}
              {title}
            </div>
            <div style={{fontSize: "0.6em"}} className="color-gray1">{subtitle}</div>
          </div>
          <div style={{fontSize: "1.125rem"}}>{value}</div>
        </div>
      </div>
    </div>
  );
}

// @ts-ignore
function ProjectCard ({project, activeHelperDialogIndex}: {project: any, activeHelperDialogIndex?: number}) {
    const history = useHistory();
    const {t} = useTranslation();
    const { user } = useAuthContext() as { user: User };
    const {features} = user.instance.license;
    const theme = useTheme();
    const bellowSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [showMore, setShowMore] = React.useState(false);
    const [typeSelected, setTypeSelected] = React.useState(2); // 1=Fases, 2=Tareas, 3=Elementos
    const [speed, setSpeed] = React.useState(project.velocityTaskProject);
    const [pdr, setPdr] = React.useState(project.PDR_task);
    const [productivity, setProductivity] = React.useState(project.productivity_task);
    const [dataTrend, setDataTrend] = React.useState(project.dataTrendsTasks);
    const [dataTrendEffort, setDataTrendEffort] = React.useState(project.dataTrends);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [velocityUnitLabel, setVelocityUnitLabel] = React.useState(t("home.velocity_unit_tasks"));
    const [productivityUnitLabel, setProductivityUnitLabel] = React.useState(t("home.productivity_description_tasks"));
    const [pdrDescriptionLabel, setPdrDescriptionLabel] = React.useState(t("home.PDR_description_tasks"));

    React.useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const getDoughnut = (dataset:any, percent:string) => {
      return (
        <div>
          <Doughnut
            data={dataset}
            options={{
              plugins: {
                legend: {display: true},
                tooltip: {enabled: false}
              },
              rotation: 0,
              circumference: 360,
              cutout: "70%",
              maintainAspectRatio: true,
              responsive: true,
              aspectRatio: 1
            }}
          />
          <div
            style={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -200%)",
              textAlign: "center",
              marginBottom: "-1.5em"
            }}
          >
            <div>{percent+"%"}</div>
          </div>
        </div>
      );
    }

    const handleChangeType = (idType: number) => {
        setTypeSelected(idType);
        switch (idType) {
            case TYPE_PHASES:
                setSpeed(project.velocityPhasesProject);
                setPdr(project.PDR_phases);
                setProductivity(project.productivity_phases);
                setDataTrend(project.dataTrendsPhases);
                setVelocityUnitLabel(t("home.velocity_unit_phases"));
                setProductivityUnitLabel(t("home.productivity_description_phases"));
                setPdrDescriptionLabel(t("home.PDR_description_phases"));
                break;
            case TYPE_TASKS:
                setSpeed(project.velocityTaskProject);
                setPdr(project.PDR_task);
                setProductivity(project.productivity_task);
                setDataTrend(project.dataTrendsTasks);
                setVelocityUnitLabel(t("home.velocity_unit_tasks"));
                setProductivityUnitLabel(t("home.productivity_description_tasks"));
                setPdrDescriptionLabel(t("home.PDR_description_tasks"));
                break;
            case TYPE_ELEMENTS:
                setSpeed(project.velocityElementsProject);
                setPdr(project.PDR_elements);
                setProductivity(project.productivity_elements);
                setDataTrend(project.dataTrendsElements);
                setVelocityUnitLabel(t("home.velocity_unit_elements"));
                setProductivityUnitLabel(t("home.productivity_description_elements"));
                setPdrDescriptionLabel(t("home.PDR_description_elements"));
                break;
        }
    }

    return (
        <Grid
            container
            spacing={1}
            sx={{border: "solid thin #64A8EC", borderRadius: "10px", marginTop: "12px", padding: "12px", borderLeft: " solid 8px #64A8EC"}}
        >
            <Grid xs={12} sm md={4} >
                <span className="home-project-detail-label">{t("Project")}</span>
                {features.detailProject ?
                <Link to={"/projectDetail?idProject=" + project.id} style={{textDecoration: "none", color: "#4A4A4A"}}>
                    <h5>{project?.name}</h5>
                    {/*<Link to={"/projectDetail?idProject=" + project.id}>{t("home.see_detail")}</Link>*/}
                </Link> : <h5>{project?.name}</h5>}
            </Grid>
            <Grid xs={12} sm md={3} sx={{ marginBottom: '0.5rem' }}>
                <span className="home-project-detail-label">{t("home.estimation_date")}</span>
                <Box sx={{display: "block", fontSize: ".87rem"}} className="color-primary">
                    {project.start_date && project.start_date != null ? MONTHS[parseInt(project.start_date.split('-')[1])-1] + ' ' + project.start_date.split('-')[2] + ', ' + project.start_date.split('-')[0] : ''}
                    <EastIcon sx={{fontSize: 10, marginLeft: ".2rem", marginRight: ".2rem"}} />
                    {project.end_date && project.end_date!= null ? MONTHS[parseInt(project.end_date.split('-')[1])-1] + ' ' + project.end_date.split('-')[2] + ', ' + project.end_date.split('-')[0] : ''}
                </Box>
            </Grid>
            <Grid xs={12} sm md={3} sx={{ marginBottom: '0.5rem' }}>
                <span className="home-project-detail-label"><Box sx={{display: "block"}}>{t("home.status")}</Box></span>
                <Chip color="primary" variant="outlined" size="small" label={project.status_id == 1 ? t("home.states.created") : project.status_id == 2 ? t("home.states.inProcess") : t("home.states.finished")} />
            </Grid>
            <Grid xs={12} sm md={2} sx={{ marginBottom: '0.5rem' }}>
                <Box sx={{display: "block"}}><span className="home-project-detail-label">{t("home.working_here")}</span></Box>
                <AvatarGroup max={4} sx={{float: "left"}}>
                    {project.users.map((us:any, ind:number) =>
                        <Avatar key={us.idUser} alt={us.name} src={us.image_url} sx={{ width: 28, height: 28 }} />
                    )}
                </AvatarGroup>
            </Grid>

            {(activeHelperDialogIndex === 1 || (!showMore && activeHelperDialogIndex !== 2)) &&
                <Grid xs={12}>
                    <Box className="show-more-div sand600-color" sx={{fontSize: "0.75rem", marginTop: "0.75rem"}} onClick={() => setShowMore(true)}>
                        <ExpandMoreICon sx={{fontSize: ".9rem"}} /> {t("show_more")}
                    </Box>
                </Grid>
            }

            <Collapse
                in={!(showMore && activeHelperDialogIndex === 1) && (showMore || activeHelperDialogIndex === 2)}
                timeout="auto"
                unmountOnExit
                sx={{width: "100%"}}
            >
                <Box sx={{ border: "solid thin #64A8EC", borderRadius: "10px", marginTop: "12px", marginBottom: '12px', padding: "12px" }} >
                <Grid container rowSpacing={1} /*sx={{border: "solid thin #64A8EC", borderRadius: "10px", marginTop: "12px", padding: "12px" }}*/ >
                    <Grid xs={windowWidth > 450 ? 6 : 12} sm={4} md={2}>
                        {/* SELECCIONAR FASES, TAREAS O ELEMENTOS */}
                        <Stack spacing={1}>
                            {/* FASES */}
                            <Grid container
                                sx={typeSelected == 1 ? {background: "#050536", color: "white", padding: "6px"} : {background: "#F5F5F5", color: "#07074E", padding: "6px"}}
                                className="div-select-type-info"
                                onClick={() => handleChangeType(TYPE_PHASES)}
                            >
                                <Grid xs={6}>
                                    <div>{t("home.phases")}</div>
                                    <div>{project.phases.finished + "/" + project.phases.total}</div>
                                </Grid>
                                <Grid xs={6}>{getDoughnut(project.dataSetPhases, ((project.phases.finished / project.phases.total) * 100).toFixed(0))}</Grid>
                            </Grid>
                            {/* TAREAS */}
                            <Grid container
                                sx={typeSelected == 2 ? {background: "#050536", color: "white", padding: "6px"} : {background: "#F5F5F5", color: "#07074E", padding: "6px"}}
                                className="div-select-type-info"
                                onClick={() => handleChangeType(TYPE_TASKS)}
                            >
                                <Grid xs={6}>{t("home.tasks")} {project.tasks.finished + "/" + project.tasks.total}</Grid>
                                <Grid xs={6}>{getDoughnut(project.dataSetTasks, ((project.tasks.finished / project.tasks.total) * 100).toFixed(0))}</Grid>
                            </Grid>
                            {/* ELEMENTOS */}
                            <Grid container
                                sx={typeSelected == 3 ? {background: "#050536", color: "white", padding: "6px"} : {background: "#F5F5F5", color: "#07074E", padding: "6px"}}
                                className="div-select-type-info"
                                onClick={() => handleChangeType(TYPE_ELEMENTS)}
                            >
                                <Grid xs={6}><span>{t("home.elements")} {project.elements.finished + "/" + project.elements.total}</span></Grid>
                                <Grid xs={6}>{getDoughnut(project.dataSetElements, ((project.elements.finished / project.elements.total) * 100).toFixed(0))}</Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    {windowWidth > 450 &&
                    <Grid /*xs={12}*/ xs={6} sm={8} md={10}>
                        <Grid container sx={{border: "solid thin #222222", borderRadius: "4px", maxHeight: "400px"}}>
                            {!bellowSmall &&
                            <Grid sm={6} md={2}>
                                <MiniCard
                                    id={project.id}
                                    title={t("home.velocity")}
                                    subtitle={velocityUnitLabel}
                                    unit=""
                                    value={speed}
                                    type={1} />
                                <MiniCard
                                    id={project.id}
                                    title={t("home.PDR")}
                                    subtitle={pdrDescriptionLabel}
                                    unit={t("home.PDR_unit")}
                                    value={pdr}
                                    type={2} />
                                <MiniCard
                                    id={project.id}
                                    title={t("Productivity")}
                                    subtitle={productivityUnitLabel}
                                    unit={t("home.productivity_unit")}
                                    value={productivity}
                                    type={3} />
                            </Grid>
                            }
                            {!matches &&
                                <>
                                    <Grid xs={4}>
                                        <h5 style={{marginBottom: "0", marginTop: ".5em"}} className="color-primary">
                                            {(typeSelected == 1 ?
                                                t("home.phases_trend") :
                                                typeSelected == 2 ? t("home.tasks_trend") :
                                                t("home.elements_trend")
                                            )}
                                        </h5>
                                        <div style={{height: "270px"}}>
                                            <Line data={dataTrend} options={project.optionsDataTrendsTasks} />
                                            <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                              <span style={{fontSize:'12px', color:'#6a6a6a'}}>{t("home.last_five_weeks")}</span>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={4}>
                                        <h5 style={{marginBottom: "0", marginTop: ".5em"}}>&nbsp;</h5>
                                        <div style={{height: "270px"}}>
                                            <Bar data={dataTrendEffort} options={project.optionsDataTrends} />
                                            <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                              <span style={{fontSize:'12px', color:'#6a6a6a'}}>{t("home.last_five_weeks")}</span>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            }
                            <Grid xs={12} sm={6} md={2}>
                                <Box sx={{background: "#f5f5f5", height: "130px", margin: "8px", textAlign: "center", fontWeight: "bold", paddingTop: "34px"}} className="color-primary">
                                    {typeSelected == TYPE_TASKS ?
                                        (project.tasks.inprogress > 0 ? project.tasks.inprogress : "-") :
                                        typeSelected == TYPE_PHASES ?
                                            (project.phases.inprogress > 0 ? project.phases.inprogress : "-") :
                                        (project.elements.inprogress > 0 ? project.elements.inprogress : "-")
                                    }
                                    <Box sx={{display: "block"}}>{t("home.inProcess")}</Box>
                                </Box>
                                <Box sx={{background: "#f5f5f5", height: "130px", margin: "8px", textAlign: "center", fontWeight: "bold", paddingTop: "34px"}} className="color-primary">
                                    {typeSelected == TYPE_TASKS ?
                                        (project.tasks.delayed > 0 ? project.tasks.delayed : "-") :
                                         typeSelected == TYPE_PHASES ?
                                            (project.phases.delayed > 0 ? project.phases.delayed : "-") :
                                        (project.elements.delayed > 0 ? project.elements.delayed : "-")
                                    }
                                    <Box sx={{display: "block"}}>{t("home.late")}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </Grid>
                </Box>
            </Collapse>

            {(activeHelperDialogIndex !== 1 && (showMore || activeHelperDialogIndex === 2)) &&
                <Grid xs={12} className="background-gray1 show-more-div">
                    <Grid container>
                        <Grid xs={6}>
                            <Box className="show-more-div color-sand500" onClick={() => setShowMore(false)} sx={{fontSize: "0.75rem", justifyContent: "right"}}>
                                <CancelIcon fontSize="small" sx={{fontSize: ".9rem"}} /> {t("show_less")}
                            </Box>
                        </Grid>
                        <Grid xs={6}>
                            <Box className="show-more-div color-primary" onClick={() => history.push("/projectDetail?idProject=" + project.id)}
                                    sx={{fontSize: "0.75rem", justifyContent: "left"}}> <span className="color-sand500">| </span>
                                <RemoveRedEyeIcon fontSize="small" sx={{fontSize: ".9rem"}} /> {t("project_tasks_page.show_all")}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}

export default ProjectCard;