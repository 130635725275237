function OnlyComparativeBar({id, user, acceptClick}) {
    //console.log(user);
    return (
        <div style={{width: "100%"}}>
            <div id={id} className="div-app-windows"
                    style={{display: 'flex', flexDirection:'row', width:'98%'}}>
                    <span className={acceptClick?"time-graphical-line-wrapper":"time-graphical-line-wrapper-non-click"} style={{width:"100%"}}>
                        {user.productiveTime != 0 &&
                            <span key={"productive"} className={(user.nonProductiveTime==0 && (user.unrecordedTime==0 || user.unrecordedTime == null)?"time-extract-unique":"time-extract-first")}
                                style={{width: (user.productiveTime*95/user.totalTimeRegistred)+"%", background:"#78B737"}}></span>
                        }
                        {user.nonProductiveTime != 0 &&
                            <span key={"nonProductive"} className={(user.productiveTime==0 && (user.unrecordedTime == 0 || user.unrecordedTime == null)?"time-extract-unique":user.productiveTime == 0?"time-extract-first": (user.unrecordedTime==0 || user.unrecordedTime == null?"time-extract-last":""))}
                                style={{width:(user.nonProductiveTime*95/user.totalTimeRegistred)+"%", background:"#E4555A"}}></span>
                        }
                        {user.unrecordedTime != 0 && user.unrecordedTime != null &&
                            <span key={"unregistred"} className={(user.productiveTime==0 && user.nonProductiveTime == 0?"time-extract-unique":"time-extract-last")}
                                style={{width: (user.unrecordedTime*95/user.totalTimeRegistred)+"%", background:"#BDBDBD"}}></span>
                        }
                    </span>
            </div>
        </div>
    );
}

export default OnlyComparativeBar;
