import { Card } from "@mui/material";

// @ts-ignore
const CardEmpty = ({children}) => {
    return (
        <Card sx={{ padding: "8px", width:'100%', height: '10em', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {children}
        </Card>
    )
};

export default CardEmpty;