import * as React from 'react'
//import {Button, Form, Dropdown} from "tabler-react";
import {AxiosResponse} from "axios";
import UserService from "../../services/user.service";
import {DEFAULT_REQUEST_ERROR} from "../../util/Constants";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Box, Grid, Stack, TextField} from "@mui/material";
import {TrueffortButton} from "../../MUIstyle/MUICustom";

const ConfirmationTokenFormTRUE = ({
                                       handleBlur,
                                       handleChange,
                                       token,
                                       error,
                                       touched,
                                       //setAlertState,
                                       email,
                                       values,
                                       setValues
                                   }: any) => {

    const [state, setState] = React.useState({isSubmitting: false, resendTimeOut: 0});

    const userService = React.useMemo(() => new UserService(), []);

    const {t} = useTranslation();

    React.useEffect(() => {
        let interval: any = null;
        if (state.resendTimeOut !== 0) {
            interval = setInterval(() => {
                setState({...state, resendTimeOut: (state.resendTimeOut - 1)});
            }, 1000);
        } else if (state.resendTimeOut === 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [state.resendTimeOut]);

    const handleTokenSubmit = () => {
        let errorMessage = t(DEFAULT_REQUEST_ERROR);
        let send = false;
        setState({...state, isSubmitting: true});
        userService.confirmToken(values.token, email).then(({data: response}: AxiosResponse) => {
            if (response.success && response.data === true) {
                setValues({...values, active: false, showConfirmForm: false, passed: true});
                /*setAlertState({
                    withActions: false,
                    visible: true,
                    text: t("register.confirmedMessage"),
                    error: false
                })*/
                toast.success(t("register.confirmedMessage"));
                send = true;
                return;
            }
            if (response?.internalError) {
                errorMessage = response.message;
            }
        }).catch((error: any) => {
            console.log("Error #ConfirmationTokenFormTRUE " + error);
        }).finally(() => {
            if (!send) {
                /*setAlertState({
                    withActions: false,
                    visible: true,
                    text: errorMessage,
                    error: true
                })*/
                toast.error(errorMessage);
                setValues({...values, active: false, showConfirmForm: true, passed: false});
            }
            setState({...state, isSubmitting: false});
        });
    };

    const handleResendCode = () => {
        if (state.resendTimeOut === 0) {
            let errorMessage = t(DEFAULT_REQUEST_ERROR);
            let send = false;
            setState({...state, isSubmitting: true});
            userService.resendCode(email).then(({data: response}: AxiosResponse) => {
                if (response.success) {
                    setState({...state, resendTimeOut: 60});
                    setValues({...values, passed: false});
                    /*setAlertState({
                        withActions: false,
                        visible: true,
                        text: t("register.confirmationEmail"),
                        error: false
                    })*/
                    toast.info(t("register.confirmationEmail"));
                    send = true;
                    return;
                }
                if (response.internalError === "true") {
                    errorMessage = response.message;
                }
            }).catch((error: any) => {
                console.log("Error #ConfirmationTokenFormTRUE " + error)
            }).finally(() => {
                if (!send) {
                    setValues({...values, active: false, showConfirmForm: true, passed: false});
                    /*setAlertState({
                        withActions: false,
                        visible: true,
                        text: errorMessage,
                        error: true
                    })*/
                    toast.error(errorMessage);
                }
                setState({...state, isSubmitting: false});
            });
        }
    }

    return(
        <Box >
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} >
                    <TextField
                        id="token"
                        name="token"
                        size="small"
                        fullWidth
                        placeholder={t("register.tokenPlaceholder")}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={token}
                        error={error && touched}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <Stack direction="row" spacing={1} >
                        <TrueffortButton
                            id="token-confirm"
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={handleTokenSubmit}
                            disabled={state.isSubmitting}
                        >
                            {state.isSubmitting ? t("forms.loading") : t("register.buttonTextConfirm")}
                        </TrueffortButton>

                        <TrueffortButton
                            id="token-resend"
                            variant="contained"
                            color="primary"
                            size="medium"
                            disabled={state.resendTimeOut > 0}
                            onClick={handleResendCode}
                        >
                            {state.resendTimeOut === 0 ? t("register.resendTokenText") : t("Wait")}
                        </TrueffortButton>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );

    /*return (
        <div className="mt-5">
            <Form.Group>
                <Form.InputGroup>
                    <Form.Input
                        placeholder={t("register.tokenPlaceholder")}
                        name="token"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={token}
                        error={error && touched}
                    />
                    <Form.InputGroupAppend>
                        <Button
                            RootComponent="a"
                            color="primary"
                            href="#"
                            onClick={handleTokenSubmit}
                            loading={state.isSubmitting}
                        >
                            {state.isSubmitting ? t("forms.loading") : t("register.buttonTextConfirm")}
                        </Button>
                        <Button.Dropdown disabled={state.isSubmitting} color="primary">
                            {state.resendTimeOut === 0 ?
                                <Dropdown.Item onClick={handleResendCode}>
                                    {t("register.resendTokenText")}
                                </Dropdown.Item> :
                                <Dropdown.Item>
                                    {t("Wait") + state.resendTimeOut + " s"}
                                </Dropdown.Item>
                            }
                        </Button.Dropdown>
                    </Form.InputGroupAppend>
                </Form.InputGroup>
            </Form.Group>
        </div>
    );*/


}


export default ConfirmationTokenFormTRUE;