import * as React from "react";

//import {StandaloneFormPage, Button, FormTextInput, Alert, Form} from "tabler-react";

import CardWrapperFormTRUE from "./CardWrapperFormTRUE";
import ConfirmationTokenFormTRUE from "./ConfirmationTokenFormTRUE";
import StandaloneFormPageTRUE from "../StandaloneFormPageTRUE"
import {useTranslation} from "react-i18next";
import "../../css/ayg1pik.css";
import "../../css/FormCrud.css"
import {Alert, Button, IconButton, InputAdornment, InputLabel, TextField} from "@mui/material";
import {TrueffortButton} from "../../MUIstyle/MUICustom";
import {Visibility, VisibilityOff} from "@mui/icons-material";

type fieldTypes = {
    email?: string,
    password?: string,
    showConfirmForm?: boolean | string,
    token?: string,
    active?: boolean | string,
    passed?: boolean | string,
};

type touchedTypes = {
    email?: boolean,
    password?: boolean,
    showConfirmForm?: boolean,
    token?: boolean,
};

type Props = {
    strings?: any,
    action?: string,
    method?: string,
    values?: fieldTypes,
    errors?: fieldTypes,
    touched?: touchedTypes,
    onSubmit?: Function,
    onChange?: Function,
    onBlur?: Function,
    isSubmitting?: boolean,
    setAlertState?: Function,
    setValues?: Function,
    alertState?: any,
    urlLogo?: string
};

/**
 * A login page
 * Can be easily wrapped with form libraries like formik and redux-form
 */
function LoginCardFormTRUE(props: Props): any {
    const {
        action,
        method,
        onSubmit,
        onChange,
        onBlur,
        values,
        strings = {},
        isSubmitting,
        errors,
        setValues,
        alertState,
        setAlertState = () => {
        },
        touched,
        urlLogo
    } = props;
    const {t} = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);
    const [userFromTrueDesktop, setUserFromTrueDesktop] = React.useState("");

    React.useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const u = query.get('u');
        setUserFromTrueDesktop(u);
    }, []);

    return (
        <>
            <StandaloneFormPageTRUE imageURL={"./assets/images/new_logo.png"} urlLogo={urlLogo} >
                {(alertState.visible && alertState.withActions) &&
                    <Alert
                        variant="filled"
                        severity={alertState.error && alertState.withActions && "warning"}
                        action={
                            <>
                                <Button
                                    onClick={alertState.confirmAction}
                                    //color="success"
                                    color="inherit"
                                >
                                    Resend code
                                </Button>
                                <TrueffortButton
                                    onClick={() => setAlertState({...alertState, visible: false, withActions: false})}
                                    //color="primary"
                                    color="inherit"
                                >
                                    No, thanks
                                </TrueffortButton>
                            </>
                        }
                    >
                        <label>
                            {alertState.text}
                        </label>
                    </Alert>
                }

                <CardWrapperFormTRUE
                    buttonText={t("Login")}
                    title={t("Login")}
                    onSubmit={onSubmit}
                    action={action}
                    method={method}
                    isSubmitting={isSubmitting}
                    showSubmitButton={values?.active || values?.passed}
                >
                    <InputLabel
                        sx={{
                            color: '#050536',
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '1em',
                        }}
                    >
                        {t("email")}
                    </InputLabel>
                    <TextField
                        id="loginEmail"
                        name="email"
                        size="small"
                        margin="none"
                        required
                        fullWidth
                        inputProps={{ style: { fontFamily: 'Inter, sans-serif' } }}
                        //label={t("email")}
                        placeholder={ t("forms.emailPlaceholder") }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        //defaultValue={userFromTrueDesktop != null && userFromTrueDesktop != "" ? userFromTrueDesktop : values && values.email}
                        value={userFromTrueDesktop !== null && userFromTrueDesktop !== "" ? userFromTrueDesktop : values && values.email}
                        error={ Boolean(errors && touched?.email && errors.email) }
                        disabled={isSubmitting || (!values?.active && !values?.passed)}
                        helperText={!errors?.email || !touched?.email ? " " : errors?.email}
                    />

                    <InputLabel
                        sx={{
                            color: '#050536',
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '1em',
                        }}
                    >
                        {t("Password")}
                    </InputLabel>
                    <TextField
                        id="loginPassword"
                        name="password"
                        size="small"
                        margin="none"
                        type={showPassword ? 'text' : 'password'}
                        required
                        fullWidth
                        inputProps={{ style: { fontFamily: 'Inter, sans-serif' } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        size='small'
                                    >
                                        {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        //label={t("Password")}
                        placeholder={t("forms.passwordPlaceholder")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={values && values.password}
                        error={ Boolean(errors && touched?.password && errors.password) }
                        disabled={isSubmitting || (!values?.active && !values?.passed)}
                        helperText={!touched?.password || !errors?.password ? " " : errors?.password}
                    />

                    {values?.showConfirmForm ?
                        <ConfirmationTokenFormTRUE setValues={setValues} values={values}
                                                   token={values.token} email={values.email}
                                                   error={errors && errors.token && touched?.token}
                                                   handleBlur={onBlur} handleChange={onChange}
                                                   setAlertState={setAlertState} touched={touched?.token}/>
                        : null}
                </CardWrapperFormTRUE>
            </StandaloneFormPageTRUE>
        </>
    );

    /*return (
        <StandaloneFormPageTRUE imageURL={"./assets/images/new_logo.png"}
            urlLogo={props.urlLogo}>
                {(alertState.visible && alertState.withActions) ?
                    <Alert type={alertState.error ? alertState.withActions ? "warning" : "danger" : "primary"}>
                        {/!*<Header.H4>Some Message</Header.H4>*!/}
                        <label>
                            {alertState.text}
                        </label>
                        {/!*alertState.withActions ?*!/}
                            <Button.List>
                                <Button onClick={alertState.confirmAction} color="success" RootComponent="button">
                                    Resend code
                                </Button>
                                <Button
                                    onClick={() => setAlertState({...alertState, visible: false, withActions: false})}
                                    color="secondary"
                                    RootComponent="button">
                                    No, thanks
                                </Button>
                            </Button.List> {/!*: null
                        *!/}
                    </Alert> : null
                }
                <CardWrapperFormTRUE
                    buttonText={t("Login")}
                    title={t("Login")}
                    onSubmit={onSubmit}
                    action={action}
                    method={method}
                    isSubmitting={isSubmitting}
                    showSubmitButton={values?.active || values?.passed}
                >
                    <Form.Label className={"size-form"}>{t("email")}</Form.Label>
                    <Form.Input
                        disabled={isSubmitting || (!values?.active && !values?.passed)}
                        name="email"
                        placeholder={ t("forms.emailPlaceholder") }
                        onChange={onChange}
                        onBlur={onBlur}
                        value={userFromTrueDesktop != null && userFromTrueDesktop != "" ? userFromTrueDesktop : values && values.email}
                        error={errors && touched?.email && errors.email}
                    />
                    <Form.Label className={"size-form"}>{t("Password")}</Form.Label>
                    <Form.Input
                        disabled={isSubmitting || (!values?.active && !values?.passed)}
                        name="password"
                        type="password"
                        placeholder={
                            t("forms.passwordPlaceholder")
                        }
                        onChange={onChange}
                        onBlur={onBlur}
                        value={values && values.password}
                        error={errors && touched?.password && errors.password}
                    />
                    {values?.showConfirmForm ?
                        <ConfirmationTokenFormTRUE setValues={setValues} values={values}
                                                   token={values.token} email={values.email}
                                                   error={errors && errors.token && touched?.token}
                                                   handleBlur={onBlur} handleChange={onChange}
                                                   setAlertState={setAlertState} touched={touched?.token}/>
                        : null}
                </CardWrapperFormTRUE>
            </StandaloneFormPageTRUE>
    );*/
}

export default LoginCardFormTRUE;
