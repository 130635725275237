// @flow
import * as React from "react";
import {Container, Stack} from "@mui/material";
import SiteWrapper from "../SiteWrapper.react";
import '../css/Dashboard.css'
import {useAuthContext} from "../contexts/AuthenticationContext";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import UserService from "../services/user.service";
import ProjectService from "../services/project.service";
import { useHistory} from "react-router-dom";
import { TrueffortButtonSmall } from "../MUIstyle/MUICustom";
import {PieChart} from "@mui/icons-material";
import GlobalReportTabs from "../components/globalReports/GlobalReportTabs";

function GlobalReport() {
  const {user} = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const {t} = useTranslation();
  const history = useHistory();
  const userService = React.useMemo(() => new UserService(), []);
  const [users, setUsers] = React.useState([]);
  const projectService = React.useMemo(() => new ProjectService(), []);
  const [projects, setProjects] = React.useState([]);
  const [lastUserActivity, setLastUserActivity] = React.useState(null);
  const [tabName, setTabName] = React.useState((features.userProductivityReportItemsPerformed || 
    features.worksheetsUsers || features.reportBenchmark) ? "users" : 
    (features.globalReportProjectsStatus || features.reportProjectsEvaluation) ? "projects" : "");

  React.useEffect(() => {
    if ((!user.instance.activeByPayment && user.instance.trial === null)
      || (!user.instance.activeByPayment && user.instance.trial !== null
      && user.instance.trial.isExpired && !user.instance.trial.hasBought)) {
      history.push("/payment");
    }
    //fillTeams();
    fillProjects();
    fillUsers();
    document.title = "TruEffort <" + user.email + ">";
  }, []);

  const fillUsers = () => {
    userService.getAllComplete().then(({data: response}) => {
      if (response != null && Array.isArray(response)) {
        setUsers(response);
      }
    });
  }

  const fillProjects = () => {
    projectService.getAll().then(({data: response}) => {
      if (response.data != null && Array.isArray(response.data)) {
        setProjects(response.data);
      }
    });
  }

  return (
    <SiteWrapper>
      <Container maxWidth='xl' sx={{ marginTop: '20px' }}>
        <div className="title">
          <div className="title-small">{tabName == "users" ? t("Productivity") : t("reports.projects.total_all_time")}</div>
          <h2 id="idTitle">{tabName == "users" ? t("Reports") : t("reports.global_analysis")}</h2>
        </div>
        <Stack direction={{ xs: 'column', sm:'row', md: 'row'}} spacing={1} >
          {(features.userProductivityReportItemsPerformed  || features.worksheetsUsers || 
            features.reportBenchmark) && <TrueffortButtonSmall 
            id="idTabUserReport"
            color="secondary"
            variant={tabName == "users" ? "contained" : "outlined"}
            onClick={() => setTabName("users")}
            startIcon={<PieChart sx={{width: '0.9em'}}/>}
            style={{marginRight: "0.2em"}}>
            {t("Users.title")}
          </TrueffortButtonSmall>}
          {(features.globalReportProjectsStatus || features.reportProjectsEvaluation) && <TrueffortButtonSmall 
            id="idTabProjectReport"
            color="secondary"
            variant={tabName == "projects" ? "contained" : "outlined"}
            onClick={() => setTabName("projects")}
            startIcon={<PieChart sx={{width: '0.9em'}}/>}
            style={{marginRight: "0.2em"}}>
            {t("Projects.title")}
          </TrueffortButtonSmall>}
        </Stack>
        <GlobalReportTabs
          user={user}
          allProjects={projects}
          allUsers={users}
          setLastUserActivity={setLastUserActivity}
          report={tabName}
        />
      </Container>
    </SiteWrapper>
  );
}

export default GlobalReport;
