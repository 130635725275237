import React from "react";
import {
    Box,
    Card,
    CardContent,
    Chip,
    FormControl,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import CategorysiteService from "../../services/categorysite.service";
import {confirmAlert} from "react-confirm-alert";
import SiteService from "../../services/site.service";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {WHITOUT_CATEGORY_NAME, USER_LANGUAGE_KEY} from "../../util/Constants";
import {useAuthContext} from "../../contexts/AuthenticationContext";
import ApiRequest from "../../helpers/ApiRequest";
import { StyledTableCell, StyledTableRow, TrueffortButton, TrueffortCardTable, TrueffortChip, TrueffortInputLabel, TrueffortSearchInput, TrueffortTableHeadCell, TrueffortTextField } from "../../MUIstyle/MUICustom";
import { Add, Clear, Delete, Edit, Save } from "@mui/icons-material";
import { styleReactSelect } from "../../MUIstyle/GlobalStyles";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomAvatar from "../custom/CustomAvatar";

// @ts-ignore
function CategoriesSitesTabTRUE() {
    const {t} = useTranslation();
    const {user} = useAuthContext();

    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.only('xs'));

    const [categories, setCategories] = React.useState([]);
    const [categoriesFiltered, setCategoriesFiltered] = React.useState([]);
    const [sites, setSites] = React.useState([]);
    const [sitesValues, setSitesValues] = React.useState([]);
    const [sitesWithoutCategory, setSitesWithoutCategory] = React.useState();
    //const [arrMessages, setArrMessages] = React.useState([]);
    const [state, setState] = React.useState({isCreating: false, isEditing: false, showFormAdd: false});
    const categorySiteService = React.useMemo(() => new CategorysiteService(), []);
    const siteService = React.useMemo(() => new SiteService(), []);
    const imageFile = React.useRef();
    const [imageInfo, setImageInfo] = React.useState();
    const [pageActive, setPageActive] = React.useState({activePage: 1});
    const [indexPage, setIndexPage] = React.useState(0);
    let NUMBER_ITEMS_BY_PAGE = 10;
    let ROWS_PER_PAGE = 5;
    const [rowsPerPageCategoriesSites, setRowsPerPageCategoriesSites] = React.useState(ROWS_PER_PAGE);
    const [categoriesSitesPage, setCategoriesSitesPage] = React.useState(0);
    const [categoriesSitesCount, setCategoriesSitesCount] = React.useState(0);//Cantidad de categorias

    React.useEffect(() => {
        fillCategories();
        fillSites();
    }, []);

    /*React.useEffect(() => {
        let page = categories.filter((user, k) => k >= indexPage && k < indexPage + NUMBER_ITEMS_BY_PAGE);
        // console.log("page",page, indexPage)
        setCategoriesSitesPage(page);
    }, [categories, indexPage]);*/

    const fillCategories = () => {
        // @ts-ignore
        categorySiteService.findAll().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                setCategories(response.data);
                setCategoriesFiltered(response.data);
                let objWithoutCategory = response.data.find((c) => c.name.toUpperCase() == WHITOUT_CATEGORY_NAME.toUpperCase());
                setSitesWithoutCategory(objWithoutCategory);
                setCategoriesSitesCount(response.data.length);
            }
        });
    }

    const fillSites = () => {
        // @ts-ignore
        siteService.findAll().then(({data: response}) => {
            if (Array.isArray(response.data)) {
                let arr = response.data;
                arr.sort((a, b) => a.name > b.name ? 1 : -1);
                setSites(response.data);
            }
        });
    }

    const handleChangeSearchInput = (searchFilter) => {
        let items = categories.filter((data) => {
            if (searchFilter == "") {
                return categories;
            } else {
                if (data.name.toLowerCase().includes(searchFilter.toLowerCase())) {
                    return data;
                }
            }
        });

        setCategoriesFiltered(items);
        setCategoriesSitesCount(items.length);
    }

    function getArrIdSites() {
        let arr = [];
        sitesValues.map((site) => {
            arr.push({idSite: site.value, idCategorysite: 0});
        });
        return arr;
    }

    const validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name= "Required";
        } else if (values.name.length > 25) {
            errors.name = 'forms.nameLengthValidation25';
        }

        if (imageInfo) {
            if (imageInfo?.type !== "image/png" && imageInfo?.type !== "image/jpeg") {
                errors.image = 'forms.photoTypeValidation';
            } else if (imageInfo.size > 1000000) {
                errors.image = 'forms.photoSizeValidation';
            }
        }

        return errors;
    }

    const handleSubmit = async (values) => {
        let dataRequest = {
            name: values.name,
            idInstance: user.instance.idInstance,
            sites: getArrIdSites(),
            imageDto: null
        }
        // @ts-ignore
        try {
            const data = await ApiRequest(categorySiteService.create(getFormData(dataRequest)));
            formik.setSubmitting(false);
            toast.success(t("category_page.msg_success_add"));
            setState({...state, showFormAdd: false, isCreating: false});
            fillCategories();
        } catch (error) {
            toast.error(t(error.message));
            fillCategories();
        }
    }

    const handleEdit = async (values) => {
        let dataRequest = {
            idCategorysite: values.idCategorysite,
            idInstance: user.instance.idInstance,
            name: values.name,
            sites: getArrIdSites(),
            imageDto: null
        }

        // @ts-ignore
        try {
            const data = await ApiRequest(categorySiteService.update(getFormData(dataRequest)));
            formik.setSubmitting(false);
            setState({...state, showFormAdd: false, isEditing: false});
            //setArrMessages([...arrMessages, {type: "success", message: t("category_page.msg_success_add")} ]);
            toast.success(t("category_page.msg_success_add"));
            fillCategories();
        } catch (error) {
            toast.error(t(error.message));
            fillCategories();
        }
    }

    const formik = useFormik({
        initialValues: {
            name: ''
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues}) => {
            if (!state.isEditing) {
                handleSubmit(values);
            } else {
                handleEdit(values);
                setState({...state, isEditing: false});
            }
        }
    });

    const prepareEdit = (obj) => {
        obj["isEditing"] = true;
        formik.setValues(obj);
        setState({...state, isEditing: true});

        let arr = [];
        if (Array.isArray(obj.sites)) {
            obj.sites.map((site) => {
                arr.push({value: site.idSite, label: site.site.name});
            });
        }
        setSitesValues(arr);
        setImageInfo(null);
    }

    const handleCancel = () => {
        formik.resetForm();
        setState({...state, isCreating: false, isEditing: false, showFormAdd: false});
    };

    const getFormData = (dataRequest) => {
        var formData = new FormData();
        if (imageInfo) {
            formData.append("image", imageFile.current.files[0], imageFile.current.files[0].name);
            dataRequest.imageDto = imageFile.current.files[0];
        } else {
            formData.append("image", new Blob([JSON.stringify(null)], {
                type: "application/json"
            }));
        }
        formData.append('categorySiteDto', new Blob([JSON.stringify(dataRequest)], {
            type: "application/json"
        }));
        //console.log(formData);

        return formData;
    }

    const handleDelete = (obj) => {
        confirmAlert({
            title: t("category_page.msg_delete_confirm1"),
            message: t("category_page.msg_delete_confirm2") + "" + obj.name + "?",
            buttons: [
                {
                    label: t("accept"),
                    onClick: () => {
                        try {
                            // @ts-ignore
                            categorySiteService.delete(obj.idCategorysite).then(({data: response}) => {
                                //console.log(response);
                                if (response.internalError != null && response.internalError && response.message != null) {
                                    //setArrMessages([...arrMessages, {type: "danger", message: response.message} ]);
                                    toast.error(t(response.message));
                                } else {
                                    //setArrMessages([...arrMessages, {type: "success", message: t("category_page.msg_success_delete")} ]);
                                    toast.success(t("category_page.msg_success_delete"));
                                    fillCategories();
                                }
                            });
                        } catch (error) {
                            console.log("Error #Sites handleDelete" + JSON.stringify(error));
                            //setArrMessages([...arrMessages, {type: "danger", message: "Ocurrió un error al eliminar categoría."} ]);
                            toast.error("Ocurrió un error al eliminar categoría.");
                        }
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => {return;}
                }
            ]
        });
    }

    const handlePageChange = (pageNumber) => {
        //console.log(`active page is ${pageNumber}`);
        let nextpage = (pageNumber == 1) ? 0 : (pageNumber - 1) * NUMBER_ITEMS_BY_PAGE;
        setIndexPage(nextpage);
        setPageActive({activePage: pageNumber});
    }

    function onChangeImage() {
        setImageInfo({
            name: imageFile.current?.files[0].name,
            size: imageFile.current?.files[0].size,
            type: imageFile.current?.files[0].type
        });
    }

    const handleChangeCategoriesSitesRowsPerPage = (event) => {
        setRowsPerPageCategoriesSites(parseInt(event.target.value, 10));
        setCategoriesSitesPage(0);
    }
    
    const handleChangeCategoriesSitesPage = (event, newPage) => {
        setCategoriesSitesPage(newPage);
    }

    return(
        <Card sx={{padding:"8px", background:"#EFEFEF", overflow:'unset'}}>
            <Grid container spacing={1} style={{marginBottom:"0.4em"}}>
                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' spacing={1} >
                        {/*<FormControl fullWidth>*/}
                            <TrueffortSearchInput
                                id="idSearchInputCategorySite"
                                placeholder={t("category_page.search")}
                                variant="outlined"
                                size="small"
                                fullWidth={matchesXS}
                                onChange={(e) => handleChangeSearchInput(e.target.value)}
                            />
                        {/*</FormControl>*/}

                        <TrueffortButton
                            id="idBtnAddCategorySite"
                            variant="contained"
                            color="secondary"
                            fullWidth={matchesXS}
                            startIcon={<Add />}
                            onClick={() => {
                                formik.resetForm();
                                setState({...state, isCreating: true, showFormAdd: true});
                                setSitesValues([]);
                                setImageInfo(null);
                            }}
                            disabled={state.isEditing}
                            maxHeight="25px"
                        >
                            {t("add_new")}
                        </TrueffortButton>
                    </Stack>
                </Grid>

                {/*arrMessages.map((msg: any, k: number) => (
                    <div key={k}>
                        <Alert className="mt-3" type={msg.type} isDismissible>
                            {msg.message}
                        </Alert>
                    </div>
                ))*/}

                {state.showFormAdd &&
                <Paper  style={{ marginBottom: "0.4em", width:'100%' }}>
                    <CardContent className={"overflow"}>{t("category_page.create_title_site")}
                        <Grid container spacing={1}>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <TrueffortInputLabel labelstring={t("Name")} requiredlabel={true} />
                                <TrueffortTextField
                                    id="idFieldNameCategorySiteNew"
                                    name="name"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    maxLength={200}
                                    placeholder={t("category_page.name_placeholder_site")}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                    helperText={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                />
                            </Grid>
                            <Grid item lg={8} md={6} sm={12} xs={12}>
                                <TrueffortInputLabel shrink labelstring={t("Sites")}/>
                                <Select
                                    isMulti
                                    value={sitesValues}
                                    options={
                                        sitesWithoutCategory.sites.map((site) => {
                                            return ({
                                                value: site.site.idSite,
                                                label: site.site.name})
                                        })
                                    }
                                    onChange={(option) => {
                                        setSitesValues(option);
                                    }}
                                    styles={styleReactSelect}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <TrueffortInputLabel shrink labelstring={t("applications.image")} />
                                <div className="custom-file" style={{ zIndex: 0 }} >
                                    <input
                                        type="file"
                                        ref={imageFile}
                                        className="custom-file-input"
                                        lang={localStorage.getItem(USER_LANGUAGE_KEY)}
                                        name="image"
                                        disabled={formik.isSubmitting}
                                        readOnly={formik.isSubmitting}
                                        onChange={onChangeImage}
                                        onBlur={formik.handleBlur}
                                        accept="image/png, image/jpeg"
                                    />
                                    <label
                                        className="custom-file-label"
                                        style={{
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            overflow: "hidden",
                                            background: 'rgb(245, 245, 245)'
                                        }}
                                    >
                                        {imageInfo?.name || t("forms.choose_file")}
                                    </label>
                                </div>
                                {formik.errors?.image && imageInfo && !formik.isSubmitting ?
                                    <div className="text-danger mt-2 text-validation">{t(formik.errors.image)} </div> : null}
                            </Grid>
                            <Grid item lg={8} md={6} sm={12} xs={12}>
                                <div style={{"float":'right', display:'flex'}}>
                                    <TrueffortButton
                                        id="idBtnNewCategorySite"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={formik.handleSubmit}>
                                            <Save size="small" />
                                    </TrueffortButton>
                                    <div style={{paddingRight:'0.2em'}}></div>
                                    <TrueffortButton
                                        id="idBtnCancelNewCategorySite"
                                        size="small"
                                        color="secondary"
                                        variant="outlined"
                                        disabled={formik.isSubmitting}
                                        onClick={handleCancel}>
                                            <Clear size="small" />
                                    </TrueffortButton>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Paper>
                }

                <TrueffortCardTable>
                    <Grid container spacing={1} style={{ margin: "0.4em 0.2em", width: '100%' }}>
                        <TableContainer component={Paper}
                            onSubmit={formik.handleSubmit}
                        >
                            <Table responsive={"true"}>
                                <TableHead>
                                    <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                                        <TrueffortTableHeadCell></TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("Name")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("Sites")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell>{t("actions")}</TrueffortTableHeadCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {categoriesFiltered.slice(categoriesSitesPage * rowsPerPageCategoriesSites,
                                        categoriesSitesPage * rowsPerPageCategoriesSites + rowsPerPageCategoriesSites).map((obj) => (
                                    !obj.isEditing ?
                                        <StyledTableRow key={obj.idCategorysite}>
                                            <StyledTableCell>
                                                <CustomAvatar url={obj.image?.url} />
                                            </StyledTableCell>
                                            <StyledTableCell style={{width:'200px'}}>
                                                {obj.name}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {obj.sites.map((site, j) => j < 10 ?
                                                    <TrueffortChip label={site.site.name}
                                                        size="small"
                                                        avatar={<CustomAvatar size={20} src={site.site?.image?.url} 
                                                        code={site.site?.name}
                                                        sizeCode={12} />}
                                                    /> : null
                                                )}
                                                {obj.sites.length > 10 ?
                                                    <TrueffortChip label={"+"+(obj.sites.length - 10)}
                                                    size="small" />:null}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {obj.name.toUpperCase() != WHITOUT_CATEGORY_NAME.toUpperCase() ?
                                                    <div style={{display:'flex'}}>
                                                        <Chip size="small"
                                                            icon={<Edit fontSize="small" />}
                                                            variant="outlined"
                                                            color='primary'
                                                            disabled={state.isEditing || state.isCreating}
                                                            onClick={() => prepareEdit(obj)}
                                                            label={t("elements.buttons.edit")}
                                                        />
                                                        <div style={{ marginLeft: "0.5em" }} />
                                                        <Chip size="small"
                                                            icon={<Delete fontSize="small" />}
                                                            onClick={() => handleDelete(obj)}
                                                            label={t("elements.buttons.delete")}
                                                            variant="outlined"
                                                            color="error"
                                                            disabled={state.isEditing || state.isCreating}
                                                        />
                                                    </div>:null
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow> :
                                        <StyledTableRow key={obj.idCategorysite}>
                                            <StyledTableCell colSpan={4}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <TrueffortInputLabel labelstring={t("Name")} requiredlabel={true} />
                                                        <TrueffortTextField
                                                            id="idInputNameEdit"
                                                            name="name"
                                                            size="small"
                                                            fullWidth
                                                            required={true}
                                                            maxLength={200}
                                                            placeholder={t("project_page.name_placeholder")}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.name}
                                                            error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                                            helperText={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TrueffortInputLabel labelstring={t("Sites")} />
                                                        <Select
                                                            isMulti
                                                            value={sitesValues}
                                                            options={
                                                                sites.map((site) => {
                                                                    return ({
                                                                        value: site.idSite,
                                                                        label: site.name})
                                                                })
                                                            }
                                                            onChange={(option) => {
                                                                setSitesValues(option);
                                                            }}
                                                            styles={styleReactSelect}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <TrueffortInputLabel shrink labelstring={t("applications.image")} />
                                                        <div className="custom-file" style={{ zIndex: 0 }}>
                                                            <input
                                                                type="file"
                                                                ref={imageFile}
                                                                className="custom-file-input"
                                                                lang={localStorage.getItem(USER_LANGUAGE_KEY)}
                                                                name="image"
                                                                disabled={formik.isSubmitting}
                                                                readOnly={formik.isSubmitting}
                                                                onChange={onChangeImage}
                                                                onBlur={formik.handleBlur}
                                                                accept="image/png, image/jpeg"
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    display: "block",
                                                                    overflow: "hidden",
                                                                    background: 'rgb(245, 245, 245)'
                                                                }}
                                                            >
                                                                {imageInfo?.name || t("forms.choose_file")}
                                                            </label>
                                                        </div>
                                                        {formik.errors?.image && imageInfo && !formik.isSubmitting ?
                                                            <div className="text-danger mt-2 text-validation">{t(formik.errors.image)} </div> : null}
                                                    </Grid>
                                                    <Grid item xs={6} sm={8} style={{"float": "right", display:'flex', flexDirection:'row', justifyContent:'end', alignItems:'center'}}>
                                                        <Chip size="small"
                                                            icon={<Save fontSize="small" />}
                                                            variant="outlined"
                                                            color='success'
                                                            onClick={formik.handleSubmit}
                                                            label={t("elements.buttons.save")}
                                                        />
                                                        <div style={{ marginLeft: "0.5em" }} />
                                                        <Chip size="small"
                                                            icon={<Clear fontSize="small" />}
                                                            onClick={() => {
                                                                obj["isEditing"] = false;
                                                                formik.resetForm();
                                                                setState({...state, isEditing: false});
                                                            }}
                                                            label={t("elements.buttons.cancel")}
                                                            variant="outlined"
                                                            color="error"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                            <Box sx={{ display: 'flex', justifyContent: "flex-end",
                                alignItems: "center", marginTop: '1em' }}>
                                <TablePagination
                                    labelRowsPerPage={t("elements.pagination.label")}
                                    rowsPerPageOptions={[5,10,15]}
                                    component={"div"}
                                    onRowsPerPageChange={handleChangeCategoriesSitesRowsPerPage}
                                    onPageChange={handleChangeCategoriesSitesPage}
                                    count={categoriesSitesCount}
                                    rowsPerPage={rowsPerPageCategoriesSites}
                                    page={categoriesSitesPage}
                                />
                            </Box>
                        </div>
                    </Grid>
                </TrueffortCardTable>
            </Grid>
        </Card>
    );
}

export default CategoriesSitesTabTRUE;