import * as React from "react";
import {useTranslation} from "react-i18next";
import {getWindowsBySite} from "../../util/ActivitiesProcessorUtil";
import {formatSeconds} from "../../util/GlobalFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import ImageService from "../../services/image.service";
import { TrueffortButton } from "../../MUIstyle/MUICustom";
import { Pending } from "@mui/icons-material";
import CustomAvatar from "../custom/CustomAvatar";

const DashboardAppSitesTRUE = ({id, activity, idUser, idProfile, totalSeconds, listAllActivities, 
    handleDecideRelevantSite, className}) => {
  //console.log('DashboardAppSitesTRUE');
  //console.log(activity);
  const {t} = useTranslation();
  const [showWindowsList, setShowWindowsList] = React.useState(false);
  const [windowsList, setWindowsList] = React.useState([]);
  const [showMore, setShowMore] = React.useState(false);
  //const [imageB64, setImageB64] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState("");
  const imageService = React.useMemo(() => new ImageService(), []);
  const [anchorElemFilter, setAnchorElemFilter] = React.useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const [menuNumber, setMenuNumber] = React.useState(0);

  React.useEffect(() => {
    if (typeof activity.application.idImage != "undefined" && activity.application.idImage != null) {
      imageService.get(activity.application.idImage).then(({data: response}) => {
        setImageUrl(response.url);
      });
    }
  }, []);

  const getWindows = () => {
    let windows = [];
    if (!showWindowsList) {
      windows = getWindowsBySite(listAllActivities, idUser, activity.application.name);
      setWindowsList(windows);
    }
    setShowWindowsList(!showWindowsList);
  }

  const handleClickMenu = (event, value) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  return(
    <div className={className} 
      style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%'}}>
        <div style={{display:'flex', width:'100%'}}>
          <div id={id} className="div-app-window-clean-new" style={{paddingLeft: className?.includes("div-app-windows-clean") ? "3em" : "", display:'flex', alignItems:'center', height:'3em', cursor:'pointer'}} onClick={getWindows}>
            <CustomAvatar url={imageUrl} />
            <span className="name-app">{activity.application.name}</span>
            <span className="time-app-wrapper" style={{background: '#F3F3F3'}}>
                <span className="time-app" style={{width: (activity.totalSeconds * 100 /totalSeconds) + "%"}}>
                  <span> {formatSeconds(activity.totalSeconds, false, false, true)}</span></span>
            </span>
            <FontAwesomeIcon className="caret-icon" icon={['fas', showWindowsList ? 'caret-down' : 'caret-right']}/>
          </div>
          {(idProfile != "" || idUser != "") && <TrueffortButton
              onClick={(event) => handleClickMenu(event, 1)}>
              <Pending sx={{color: anchorElemFilter == null ? "#7C6924" : "#ED9A55"}}/>
          </TrueffortButton>}
          <Menu id={id + "_idElementsAssignSites"}
            anchorEl={anchorElemFilter}
            open={openOptions}
            onClose={() => setAnchorElemFilter(null)}
            sx={{
              '& .MuiPaper-root': {
                background: '#EFEFEF',
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
              }
            }}
          >
            {(menuNumber == 1) && <div>
              {idUser != "" ? 
              <MenuItem id={id + "_idElementsAssignSites_0"} key={0} sx={{fontSize: ".8rem!important", background:'#EFEFEF'}} 
                onClick={() => handleDecideRelevantSite(activity.idSite)}>
                {activity.isRelevant ? t("dashboard.assign_to_user_not_relevant") : t("dashboard.assign_to_user_relevant")}
              </MenuItem>:null}
              {idProfile != "" && idUser != "" ?
              <MenuItem id={id + "_idElementsAssignSites_1"} key={1} sx={{fontSize: ".8rem!important", background:'#EFEFEF'}} 
                onClick={() => handleDecideRelevantSite(activity.idSite)}>
                {activity.isRelevant ? t("dashboard.assign_to_profile_relevant") : t("dashboard.assign_to_profile_not_relevant")}
              </MenuItem> : null
              }
            </div>}
          </Menu>
        </div>

        {showWindowsList &&
          <div id={id + "_WindowsNotes"} className="div-windows-parent">
            {windowsList.map((w, k) => (
              showMore || k < 5 ?
                <div id={id + "_Notes_" + k} key={k} className="item-window" style={{display:'flex', flexDirection:'row', alignItems:'center', width:'100%', height:'2.5em'}}>
                  <div style={{width:'10em', fontWeight:'bold'}}>{formatSeconds(w.totalSeconds, false, false, true)}</div>
                  <div><li>{w.notes.length > 60 ? w.notes.substring(0,60)+"..." : w.notes}</li></div>
                </div> : null
            ))}

            {!showMore && windowsList.length > 5 &&
              <div id={id+"_idBtnShowMoreNotes"} className="item-window show-more" onClick={() => setShowMore(true)}>
                <FontAwesomeIcon icon={['fas', 'chevron-down']}/> {t("show_more")}
              </div>
            }
            {showMore &&
              <div id={id+"_idBtnShowLessNotes"} className="item-window show-more" onClick={() => setShowMore(false)}>
                <FontAwesomeIcon icon={['fas', 'chevron-up']}/> {t("show_less")}
              </div>
            }
          </div>
          }
      </div>
  );
}

export default DashboardAppSitesTRUE;
