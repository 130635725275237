// @flow

import * as React from "react";
//import {Form, Card, Button, List} from "tabler-react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
//import "../../css/ayg1pik.css";
//import "../../css/Login.css";
import "../../MUIstyle/MUICustom.css";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader, Checkbox, FormControlLabel,
    Grid, Link, Typography
} from "@mui/material";
import {TrueffortButton} from "../../MUIstyle/MUICustom";

type Props = {
    action?: string,
    children?: any,
    method?: string,
    title: string,
    buttonText: string,
    onSubmit?: Function,
    isSubmitting?: boolean,
    showSubmitButton?: boolean | string,
};

const normalFont = {
    //fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
}

function CardWrapperFormTRUE({
                                 children,
                                 action,
                                 method,
                                 onSubmit,
                                 title,
                                 buttonText,
                                 isSubmitting,
                                 showSubmitButton
                             }: Props): any {
    const location = useLocation();
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <Box component="form" onSubmit={onSubmit} sx={{ width: '410px'}} >
            <Card sx={{ padding: '24px 34px' }} >
                <CardHeader
                    sx={{ color: '#050536' }}
                    title={
                        <Typography variant='h4' >
                            {title}
                        </Typography>
                    }
                />

                <CardContent >
                    {children}
                </CardContent>

                {showSubmitButton &&
                    <CardActions>
                        <TrueffortButton
                            id="login-button"
                            color="primary"
                            size="medium"
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            {buttonText}
                        </TrueffortButton>
                    </CardActions>
                }

                <Grid container >
                    <Grid item xs >
                        {/*<FormControlLabel
                        control={
                            <Checkbox color="primary" size="small" sx={{...normalFont, '&.Mui-checked': { color: "#EF3E2D" } }} />
                        }
                        label="Remember me"
                    />*/}
                    </Grid>

                    <Grid item >
                        <Link
                            component="button"
                            underline="hover"
                            color="#EF3E2D"
                            variant="body2"
                            onClick={() => history.push(location.pathname !== "/login" ? "login" : "forgot-password")}
                            sx={{...normalFont, padding: "9px", fontFamily: 'Inter', fontSize: "16px" }}
                        >
                            {t(location.pathname !== "/login" ? "Login" : "change_password")}
                        </Link>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );

    /*return (
        <Form className="card card-body-lg" onSubmit={onSubmit} action={action} method={method}>
            <Card.Body className="p-6">
                {/!*<Card.Title RootComponent="div">{title}</Card.Title>*!/}
                <h1 className="text-primary">
                    {t(title)}</h1>
                {children}
                {showSubmitButton ?
                    <Form.Footer>
                        <Button pill type="submit" color="primary" block={true} loading={isSubmitting} className={"size-form"}>
                            {buttonText}
                        </Button>
                    </Form.Footer> : null
                }
                <List className="list-inline mt-5 justify-content-around d-flex">
                    {location.pathname !== "/forgot-password" && location.pathname !== "/change-password" ?
                        <List.Item className="list-inline-item size-form-sm">
                            <a href="#" onClick={() => history.push("forgot-password")}>{t("forms.reset_password")}</a>
                        </List.Item> : null}
                    {location.pathname !== "/login" ?
                        <List.Item className="list-inline-item size-form-sm">
                            <a onClick={() => history.push("login")} href="#">{t("forms.go_signIn")}</a>
                        </List.Item> : null}
                    {location.pathname !== "/register" ?
                        <List.Item className="list-inline-item size-form-sm">
                            <a onClick={() => history.push("pricing")} href="#">{t("forms.go_signUp")}</a>
                        </List.Item> : null}
                </List>
            </Card.Body>
        </Form>
    );*/
}

export default CardWrapperFormTRUE;
