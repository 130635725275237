import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ProjectService {

    getAll() {
        return http?.get(baseURL.readApi.projects + `/`);
    }

    create(data: any) {
        return http?.post(baseURL.activitiesapi.projects + "/", data);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.projects + `/${id}`);
    }

    update(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.projects + `/${id}`, data);
    }

    createPlan(data: any) {
        return http?.post(baseURL.activitiesapi.projects + "/plan", data);
    }

    updatePlan(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.projects + `/updatePlan/${id}`, data);
    }

    assignLeaderes(data: any) {
        return http?.post(baseURL.activitiesapi.projects + "/assignLeaders", data);
    }

    downloadEstimationXlsLayout() {
        return http?.get(baseURL.readApi.projects + `/downloadLayout`, {responseType: "blob"});
    }

    bulkUploadProject(data: any) {
        const options = {
            headers: {
                "Content-type": "multipart/form-data",
            }, transformRequest: () => data
        };
        return http?.post(baseURL.activitiesapi.projects + `/creareFromXls`, data, options);
    }

    getProjectsInteroperabilityInfo() {
        return http?.get(baseURL.readApi.projects + `/interoperability`);
    }
}

export default ProjectService;