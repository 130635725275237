export const helperDialogs = {
    siteWrapper: [
        // Binvenida
        {
            title: "helper_dialogs.trueffort_welcome_title",
            body: "helper_dialogs.trueffort_welcome_body"
        },
        // cabecera
        {
            title: 'helper_dialogs.tools_menu_title',
            body: 'helper_dialogs.tools_menu_body'
        },
        {
            title: 'helper_dialogs.configuration_title',
            body: 'helper_dialogs.configuration_body'
        },
        {
            title: 'helper_dialogs.trueffort_download_title',
            body: 'helper_dialogs.trueffort_download_body'
        },
        {
            title: 'helper_dialogs.language_change_title',
            body: 'helper_dialogs.language_change_body'
        },
        {
            title: 'helper_dialogs.notifications_center_title',
            body: 'helper_dialogs.notifications_center_body'
        },
        {
            title: 'helper_dialogs.profile_title',
            body: 'helper_dialogs.profile_body'
        },
        // Menú lateral
        {
            title: 'helper_dialogs.drawer_menu_title',
            body: 'helper_dialogs.drawer_menu_body'
        },
        {
            title: 'helper_dialogs.global_view_title',
            body: 'helper_dialogs.global_view_body'
        },
        {
            title: 'helper_dialogs.overview_report_title',
            body: 'helper_dialogs.overview_report_body'
        },
        {
            title: 'helper_dialogs.templates_title',
            body: 'helper_dialogs.templates_body'
        },
        {
            title: 'helper_dialogs.users_title',
            body: 'helper_dialogs.users_body'
        },
        {
            title: "helper_dialogs.daily_title",
            body: "helper_dialogs.daily_body"
        }
    ],
    home: [
        {
            title: 'helper_dialogs.home_last_tool_title',
            body: 'helper_dialogs.home_last_tool_body'
        },
        {
            title: 'helper_dialogs.home_closed_project_card_title',
            body: 'helper_dialogs.home_closed_project_card_body'
        },
        {
            title: 'helper_dialogs.home_open_project_card_title',
            body: 'helper_dialogs.home_open_project_card_body'
        },
        {
            title: 'helper_dialogs.home_finished_tasks_title',
            body: 'helper_dialogs.home_finished_tasks_body'
        },
        {
            title: 'helper_dialogs.home_global_trend_title',
            body: 'helper_dialogs.home_global_trend_body'
        }
    ],
    projectDetail: [
        {
            title: 'helper_dialogs.project_detail_estimation_title',
            body: 'helper_dialogs.project_detail_estimation_body'
        },
        {
            title: 'helper_dialogs.project_detail_registered_title',
            body: 'helper_dialogs.project_detail_registered_body'
        },
        {
            title: 'helper_dialogs.project_detail_elements_title',
            body: 'helper_dialogs.project_detail_elements_body'
        },
        {
            title: 'helper_dialogs.project_detail_phases_title',
            body: 'helper_dialogs.project_detail_phases_body'
        },
        {
            title: 'helper_dialogs.project_detail_tasks_title',
            body: 'helper_dialogs.project_detail_tasks_body'
        },
        {
            title: 'helper_dialogs.project_detail_elements_list_title',
            body: 'helper_dialogs.project_detail_elements_list_body'
        },
        {
            title: 'helper_dialogs.project_detail_element_name',
            body: ''
        },
        {
            title: 'helper_dialogs.project_detail_element_status_title',
            body: 'helper_dialogs.project_detail_element_status_body'
        },
        {
            title: 'helper_dialogs.project_detail_initial_date_title',
            body: 'helper_dialogs.project_detail_initial_date_body'
        },
        {
            title: 'helper_dialogs.project_detail_final_date_title',
            body: 'helper_dialogs.project_detail_final_date_body'
        },
        {
            title: 'helper_dialogs.project_detail_estimated_date_title',
            body: 'helper_dialogs.project_detail_estimated_date_body'
        },
        {
            title: 'helper_dialogs.project_detail_estimates_title',
            body: 'helper_dialogs.project_detail_estimates_body'
        },
        {
            title: 'helper_dialogs.project_detail_reality_title',
            body: 'helper_dialogs.project_detail_reality_body'
        }
    ],
    estimation: [
        {
            title: 'helper_dialogs.estimation_welcome_title',
            body: 'helper_dialogs.estimation_welcome_body'
        },
        {
            title: 'helper_dialogs.estimation_leader_assignment_title',
            body: 'helper_dialogs.estimation_leader_assignment_body'
        },
        {
            title: 'helper_dialogs.estimation_project_name_title',
            body: 'helper_dialogs.estimation_project_name_body'
        },
        {
            title: 'helper_dialogs.estimation_project_type_title',
            body: 'helper_dialogs.estimation_project_type_body'
        },
        {
            title: 'helper_dialogs.estimation_create_template_title',
            body: 'helper_dialogs.estimation_create_template_body'
        },
        {
            title: 'helper_dialogs.estimation_measurement_standard_title',
            body: 'helper_dialogs.estimation_measurement_standard_body'
        },
        {
            title: 'helper_dialogs.estimation_add_element_type_title',
            body: 'helper_dialogs.estimation_add_element_type_body'
        },
        {
            title: 'helper_dialogs.estimation_element_size_title',
            body: 'helper_dialogs.estimation_element_size_body'
        },
        {
            title: 'helper_dialogs.estimation_element_complexity_title',
            body: 'helper_dialogs.estimation_element_complexity_body'
        },
        {
            title: 'helper_dialogs.estimation_add_new_element_title',
            body: 'helper_dialogs.estimation_add_new_element_body'
        },
        {
            title: 'helper_dialogs.estimation_total_effort_title',
            body: 'helper_dialogs.estimation_total_effort_body'
        },
        {
            title: 'helper_dialogs.estimation_total_duration_title',
            body: 'helper_dialogs.estimation_total_duration_body'
        },
        {
            title: 'helper_dialogs.estimation_estimate_project_title',
            title_alt: 'helper_dialogs.estimation_new_estimate_title',
            body: 'helper_dialogs.estimation_estimate_project_body',
            body_alt: 'helper_dialogs.estimation_new_estimate_body'
        }
    ],
    estimationDetail: [
        {
            title: 'helper_dialogs.estimation_detail_estimation_ready_title',
            body: 'helper_dialogs.estimation_detail_estimation_ready_body'
        },
        {
            title: 'helper_dialogs.estimation_detail_total_duration_min_title',
            body: 'helper_dialogs.estimation_detail_total_duration_min_body'
        },
        {
            title: 'helper_dialogs.estimation_detail_total_duration_max_title',
            body: 'helper_dialogs.estimation_detail_total_duration_max_body'
        },
        {
            title: 'helper_dialogs.estimation_detail_avg_total_duration_title',
            body: 'helper_dialogs.estimation_detail_avg_total_duration_body'
        },
        {
            title: 'helper_dialogs.estimation_detail_statistical_range_title',
            body: 'helper_dialogs.estimation_detail_statistical_range_body'
        },
        {
            title: 'helper_dialogs.estimation_detail_added_elements_title',
            body: 'helper_dialogs.estimation_detail_added_elements_body'
        },
        {
            title: 'helper_dialogs.estimation_detail_add_element_title',
            body: 'helper_dialogs.estimation_detail_add_element_body',
            body_alt: 'helper_dialogs.estimation_detail_add_element_body2'
        },
        {
            title: 'helper_dialogs.estimation_detail_phases_title',
            body: 'helper_dialogs.estimation_detail_phases_body'
        },
        {
            title: 'helper_dialogs.estimation_detail_element_name_title',
            body: 'helper_dialogs.estimation_detail_element_name_body'
        }
    ]
}