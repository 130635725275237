import * as React from "react";
import { Grid, Menu, MenuItem, Divider} from "@mui/material";
import {KeyboardArrowDown as KeyboardArrowDownIcon, CalendarMonth as CalendarMonthIcon, 
    Dashboard as DashboardIcon, Groups as GroupsIcon} from "@mui/icons-material";
import '../../css/Dashboard.css'
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { cutValueFormated } from "../../util/GlobalFunctions";
import CustomDatePickerInput from "../custom/CustomDatePickerInput";
import { TrueffortNavButton, TrueffortSearchInput } from "../../MUIstyle/MUICustom";
import CustomIconTrueffortScreen from "../custom/CustomIconTrueffortScreen";
import GlobalReportUsers from "./GlobalReportUsers";
import GlobalReportProjects from "./GlobalReportProjects";
import SelectMulti from 'react-select';
import TaskStateService from "../../services/taskState.service";
import { ID_STATUS_INPROCESS } from "../../util/Constants";

const GlobalReportTabs = ({user, setLastUserActivity, allProjects, allUsers, report}) => {
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const {t} = useTranslation();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [inputSearch, setInputSearch] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState({});
  const [dataUsers, setDataUsers] = React.useState([]);
  const [nameSelectedDays, setNameSelectedDays] = React.useState(t("dashboard.today"));
  const [valuesSelectedProjects, setValuesSelectedProjects] = React.useState([]);
  const [valuesSelectedUsers, setValuesSelectedUsers] = React.useState( allUsers.length == 1 ? 
    allUsers.map((us) => ({ value: us.idUser, label: us.name + ' ' + us.lastName } )) : []);
  const [idSelectedProject, setIdSelectedProject] = React.useState("");
  const [selectedProject, setSelectedProject] = React.useState({});
  const [idSelectedUser, setIdSelectedUser] = React.useState("");
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [idsSelectedUsers, setIdsSelectedUsers] = React.useState([]);
  const [users, setUsers] = React.useState(allUsers);
  const [indexTab, setIndexTab] = React.useState(0);
  const [indexTabProject, setIndexTabProject] = React.useState(0);
  const [anchorElemFilter, setAnchorElemFilter] = React.useState(null);
  const openOptions = Boolean(anchorElemFilter);
  const [menuNumber, setMenuNumber] = React.useState(0);
  const taskStateService = React.useMemo(() => new TaskStateService(), []);
  const [taskStates, setTaskStates] = React.useState([]);
  const [valuesStatusSelectFilter, setValuesStatusSelectFilter] = React.useState([{value: ID_STATUS_INPROCESS, label: "En Proceso"}]);

  React.useEffect(() => {
    setUsers(allUsers);
    setValuesSelectedUsers(allUsers.length == 1 ? 
      allUsers.map((us) => ({ value: us.idUser, label: us.name + ' ' + us.lastName } )) : []);
  }, [allUsers]);

  React.useEffect(() => {
    let ed = new Date();
    setEndDate(ed);
    if (report == "projects") {
      let d = new Date();
      d.setMonth(d.getMonth() - 6);
      d.setDate(1);
      setStartDate(d);
      setIndexTabProject(0);
      fillTaskStates();
    }
  }, [report])

  const fillTaskStates = () => {
    taskStateService.getAll().then(({data: response}) => {
      if (Array.isArray(response.data)) {
        setTaskStates(response.data);
      }
    });
  }

  const handleChangeUser = (id) => {
    setAnchorElemFilter(null);
    setIdSelectedUser(id);
    setSelectedUser(users.find(us => us.idUser == id));
  }
  
  const handleChangeUsers = (id) => {
    //setIdSelectedUser(id);
    setAnchorElemFilter(null);
    let us = users.find(us => us.idUser == id);
    let aux = selectedUsers.map(u => u);
    aux.push(us);
    setSelectedUsers(aux);
  }

  const handleChangeProject = (id) => {
    //console.log("handleChangeProject");
    setAnchorElemFilter(null);
    setIdSelectedProject(id);
    setSelectedProject(id == "" || id == "-1" ? {} : allProjects.find(p => p.idProject == id));
  }

  const handleChangeDays = (id) => {
    setAnchorElemFilter(null);
    let start = new Date();
    switch (id) {
      case 1:
        setStartDate(new Date());
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.today"));
        break;
      case 2:
        start.setDate(start.getDate() - 5)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_5_days"));
        break;
      case 3:
        start.setDate(start.getDate() - 15)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_15_days"));
        break;
      case 4:
        start.setDate(start.getDate() - 30)
        setStartDate(start);
        setEndDate(new Date());
        setNameSelectedDays(t("dashboard.last_30_days"));
        break;
    }
    //if (report == "users" && indexTab == 3) {setPageWeeklyTab(0);}
  }

  const handleClickMenu = (event, value) => {
    setAnchorElemFilter(event.currentTarget);
    setMenuNumber(value);
  }

  const handleChangeIndexTab = (event, val) => {
    setIndexTab(val);
    setIdSelectedUser("");
    setIdsSelectedUsers([]);
  };

  const handleChangeIndexTabProject = (event, val) => {
    setIndexTabProject(val);
  };

  const handleChangeUsersGraph = (id) => {
    let ids = idsSelectedUsers.map(i => i);
    let index = ids.findIndex(u => u.idUser == id);
    setAnchorElemFilter(null);
    if (index == -1) {
      let us = users.find(us => us.idUser == id);
      let aux = idsSelectedUsers.map(u => u);
      aux.push(us);
      setIdsSelectedUsers(aux);
    } else {
      ids.splice(index, 1);
      setIdsSelectedUsers(ids);
    }
  }

  return (
    <Grid>
      <Grid container spacing={0.5} sx={{paddingTop: '0.5em'}}  >
        {(report == "users" || indexTabProject == 1) && <Grid item xl={4} md={3} xs={12} >
          <TrueffortSearchInput
            id="idInputSearchReports"
            placeholder={t("search")}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setInputSearch(e.target.value)}
          />
        </Grid>}
        {report == "users" && <Grid item >
          <TrueffortNavButton
            id="idSelectedDays"
            size="small"
            variant="contained"
            onClick={(event) => handleClickMenu(event, 1)}
            className={nameSelectedDays == t("dashboard.customized") ? "" : "active"}
            fullWidth
            //noneHeight={nameSelectedDays.length > 5}
          >
            <CustomIconTrueffortScreen icon={<CalendarMonthIcon />} />
              {cutValueFormated(nameSelectedDays, 11)}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {(report == "users" || indexTabProject == 0) && <Grid item>
          <div id="idStartDate">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              maxDate={endDate}
              onChange={date => {
                //if (report == "users" && indexTab == 3) {setPageWeeklyTab(0);}
                setStartDate(date);
              }}
              customInput={<CustomDatePickerInput label={t("start_date")} />}
            />
          </div>
        </Grid>}
        {(report == "users" || indexTabProject == 0) && <Grid item>
          <div id="idEndDate">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              minDate={startDate}
              onChange={date => {
                //if (report == "users" && indexTab == 3) {setPageWeeklyTab(0);}
                setEndDate(date);
              }}
              customInput={<CustomDatePickerInput label={t("end_date")} />}
            />
          </div>
        </Grid>}
        {[0,1].includes(indexTab) && report == "users" && <Grid item >
          <TrueffortNavButton
            id="idSelectedUser"
            size="small"
            variant="contained"
            onClick={(event) => handleClickMenu(event, 3)}
            className={idSelectedUser != "" ? "active" : ""}
            noneHeight={false}
            fullWidth
          >
            <CustomIconTrueffortScreen icon={<GroupsIcon />} />
            {idSelectedUser != "" ? cutValueFormated(selectedUser.name + " " + selectedUser.lastName, 16) : t("dashboard.all_users")}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {[2,3,4].includes(indexTab) && <Grid item >
          <TrueffortNavButton
            id="idsSelectedUsers"
            size="small"
            variant="contained"
            onClick={(event) => {
              //if (report == "users" && indexTab == 3) { setPageWeeklyTab(0); }
              handleClickMenu(event, 2);
            }}
            className={idsSelectedUsers.length != 0 ? "active" : ""}
            noneHeight={false}
          >
            <CustomIconTrueffortScreen icon={<GroupsIcon />} />
              {idsSelectedUsers.length == 0 ? t("dashboard.all_users") : idsSelectedUsers.map((u, i) => u.name + (i < (idsSelectedUsers.length-1) ? ", " : ""))}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {report == "projects" && indexTabProject == 0 && <Grid item xs={2} sm={2}>
          <SelectMulti
            name="selectUsers"
            isMulti
            placeholder={t("User")}
            value={valuesSelectedUsers}
            onChange={(option) => setValuesSelectedUsers(option)}
            options={
              users.map((us) => (
                {
                  value: us.idUser,
                  label: us.name + ' ' + us.lastName
                }
              ))
            }
          />
        </Grid>}
        {report == "users" && <Grid item >
          <TrueffortNavButton
            id="idSelectedProject"
            size="small"
            variant="contained"
            onClick={(event) => {
              handleClickMenu(event, 4);
            }}
            className={idSelectedProject != "" ?"active" : ""}
            fullWidth
          >
            <CustomIconTrueffortScreen icon={<DashboardIcon />} />
              {idSelectedProject != "" ? idSelectedProject != "-1" ? cutValueFormated(selectedProject.name, 18): t("project_page.without_project") : t("dashboard.all_projects")}
            <CustomIconTrueffortScreen icon={<KeyboardArrowDownIcon />} />
          </TrueffortNavButton>
        </Grid>}
        {report == "projects" && indexTabProject == 1 && 
          <Grid item xs={2} md={2}>
            <SelectMulti
              isMulti
              label={t("home.status")}
              placeholder={t("project_page.status_activity")}
              value={valuesStatusSelectFilter}
              options={
                  taskStates.map((s, k) => {
                  return ({
                    value: s.idStatus,
                    label: s.name
                  })
                })
              }
              onChange={(option) => {
                setValuesStatusSelectFilter(option);
              }}
            />
        </Grid>}
        {report == "projects" && <Grid item xs={2} sm={2}>
          <SelectMulti
            name="selectProjects"
            isMulti
            placeholder={t("reports.projects.title")}
            value={valuesSelectedProjects}
            onChange={(option) => setValuesSelectedProjects(option)}
            options={
              allProjects.map((pro) => (
                { value: pro.idProject, label: pro.name }
              ))
            }
          />
        </Grid>}
      </Grid>
      <Menu id="idElementsFilterDashboards"
        anchorEl={anchorElemFilter}
        open={openOptions}
        onClose={() => setAnchorElemFilter(null)}
      >
        {menuNumber == 1 && <div>
          <MenuItem id="idDays_0" key={"_1"} onClick={() => handleChangeDays(1)}>
            {t("dashboard.today")}
          </MenuItem>
          <MenuItem id="idDays_1" key={"_5"} onClick={() => handleChangeDays(2)}>
            {t("dashboard.last_5_days")}
          </MenuItem>
          <MenuItem id="idDays_2" key={"_15"} onClick={() => handleChangeDays(3)}>
            {t("dashboard.last_15_days")}
          </MenuItem>
          <MenuItem id="idDays_3" key={"_30"} onClick={() => handleChangeDays(4)}>
            {t("dashboard.last_30_days")}
          </MenuItem>
        </div>}
        {menuNumber == 2 && <div>
          <MenuItem id="idUsersGraph_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => setIdsSelectedUsers([])}>
            {t("dashboard.all_users")}
          </MenuItem>
          <Divider />
          {users.map((u, i) => (
            <MenuItem id={"idUsersGraph_"+(i+1)} key={i} 
              //disabled={idsSelectedUsers.findIndex(us => us.idUser == u.idUser) != -1} 
              sx={{fontSize: ".8rem!important", background: idsSelectedUsers.findIndex(us => us.idUser == u.idUser) != -1 ? '#F5F5F5' : '#FFFFFF'}} 
              onClick={() => handleChangeUsersGraph(u.idUser)}>
              {u.name + " " + u.lastName}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 3 && <div>
          <MenuItem id="idUser_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUser("")}>
            {t("dashboard.all_users")}
          </MenuItem>
          <Divider />
          {users.map((u, i) => (
            <MenuItem id={"idUser_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUser(u.idUser)}>
              {u.name + " " + u.lastName}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 4 && <div>
          <MenuItem id="idProject_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject("")}>
            {t("dashboard.all_projects")}
          </MenuItem>
          <Divider />
          {allProjects.map((p, i) => (
            <MenuItem id={"idProject_"+(i+1)} key={i} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeProject(p.idProject)}>
              {p.name}
            </MenuItem>
          ))}
        </div>}
        {menuNumber == 5 && <div>
          <MenuItem id="idUsers_0" key={-1} sx={{fontSize: ".8rem!important"}} onClick={() => setSelectedUsers([])}>
            {t("dashboard.all_users")}
          </MenuItem>
          {users.map((u, i) => (
            <MenuItem id={"idUsers_"+(i+1)} key={i} disabled={selectedUsers.findIndex(us => us.idUser == u.idUser) != -1} sx={{fontSize: ".8rem!important"}} onClick={() => handleChangeUsers(u.idUser)}>
              {u.name + " " + u.lastName}
            </MenuItem>
          ))}
        </div>}
      </Menu>
      {(features.userProductivityReportItemsPerformed || features.worksheetsUsers || 
        features.reportBenchmark) && report == "users" && 
        <GlobalReportUsers 
          startDate={startDate}
          endDate={endDate}
          idSelectedUser={idSelectedUser}
          idsSelectedUsers={idsSelectedUsers}
          idSelectedProject={idSelectedProject}
          dataUsers={dataUsers}
          inputSearch={inputSearch}
          indexTab={indexTab}
          handleChangeIndexTab={handleChangeIndexTab}
          users={allUsers}
        />
      }
      {(features.globalReportProjectsStatus || features.reportProjectsEvaluation) && 
        report == "projects" && 
        <GlobalReportProjects 
          startDate={startDate}
          endDate={endDate}
          inputSearch={inputSearch}
          idSelectedUser={idSelectedUser}
          idSelectedProject={idSelectedProject}
          valuesSelectedUsers={valuesSelectedUsers}
          valuesSelectedProjects={valuesSelectedProjects}
          valuesStatusSelect={valuesStatusSelectFilter}
          indexTab={indexTabProject}
          handleChangeIndexTab={handleChangeIndexTabProject}
        />
      }
    </Grid>
  );
}

export default GlobalReportTabs;