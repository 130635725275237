import { Grid } from "@mui/material";

// @ts-ignore
const CardByList = ({borderColor, children}) => {
  return (
    <Grid container spacing={2} 
      sx={{border: "solid thin " + (borderColor ? borderColor : "#64A8EC"), borderRadius: "10px", marginLeft:'6px', 
        marginTop: "6px", padding: "12px", borderLeft: " solid 8px " + (borderColor ? borderColor : "#64A8EC"), width: '99%'}}
    >
      {children}
    </Grid>
  )
};

export default CardByList;