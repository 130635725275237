// @flow

import * as React from "react";
import {Formik, FormikErrors} from "formik";
import {useAuthContext} from "../contexts/AuthenticationContext";
import LoginCardFormTRUE from "../components/pricing-login-register/LoginCardFormTRUE";
import UserService from "../services/user.service";
import {AxiosResponse} from "axios";
import {DEFAULT_REQUEST_ERROR, URL_LOCAL, URL_PROD, URL_QA} from "../util/Constants";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function LoginPage(props: any) {

    const {user, loginUser} = useAuthContext();
    const userService = React.useMemo(() => new UserService(), [])
    const {t} = useTranslation();
    const location = useLocation();
    React.useEffect(() => {
        if (user?.token) {
            props.history.push(user?.projectsInfo.length === 0 ? "/dashboard" : "/home");
        }
    }, [props.history, user?.token]);

    React.useEffect(() => {
        document.title = "TruEffort";
    }, []);

    const [alertState, setAlertState] = React.useState({
        visible: false,
        text: "",
        error: false,
        withActions: false,
        confirmAction: () => {
        }
    });

    const handleLogin = async (values: { email: any; password: any; showConfirmForm?: boolean; token?: string; active?: boolean; }, {
        setValues,
        setSubmitting
    }: { setValues: (values: any, shouldValidate?: boolean | undefined) => void; setSubmitting: (isSubmitting: boolean) => void; }) => {

        try {
            const response: any = await loginUser({
                username: values.email,
                password: values.password,
            });
            if (response.internalError) {
                /*if (response.userNotConfirmed) {
                    setAlertState({
                        ...alertState,
                        visible: true,
                        text: response.message,
                        error: true,
                        withActions: true,
                        confirmAction: () => {
                            setSubmitting(true);
                            let errorMessage = t(DEFAULT_REQUEST_ERROR);
                            let send = false;
                            userService.resendCode(values.email).then(({data: response}: AxiosResponse) => {
                                if (response.success) {
                                    toast.info(t("register.resendCodeMessage"));
                                    setValues({...values, active: false, showConfirmForm: true, token: ""})
                                    send = true;
                                } else if (response.internalError === "true") {
                                    errorMessage = response.message;
                                }
                            }).catch((error: any) => {
                                console.log("Error #LoginPage " + error)
                            }).finally(() => {
                                if (!send) {
                                    toast.error(errorMessage);
                                    setValues({...values, active: false, showConfirmForm: true});
                                }
                                setSubmitting(false)
                            });
                        }
                    });
                } else {*/
                    toast.warning(response.message);
                //}
            }
        } catch (error) {
            //console.log(error);
            toast.error(t(DEFAULT_REQUEST_ERROR));
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    showConfirmForm: false,
                    token: "",
                    active: true,
                    passed: false
                }}
                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors: any = {};
                    if (!values.email) {
                        errors.email = t("Required");
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(values.email)) {
                        errors.email = t("forms.invalidEmailValidation");
                    }
                    if (!values.password) {
                        errors.password = t("Required");
                    }
                    return errors;
                }}
                onSubmit={(
                    values,
                    {setSubmitting, setValues /* setValues and other goodies */}
                ) => {
                    handleLogin(values,
                        {setValues, setSubmitting}).then(() => setSubmitting(false))
                }}
                render={({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                             setValues
                         }) => (
                    <LoginCardFormTRUE
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isSubmitting={isSubmitting}
                        setValues={setValues}
                        alertState={alertState}
                        setAlertState={setAlertState}
                        urlLogo={window.location.host.includes("localhost") ? URL_LOCAL : window.location.host.includes("qa.trueffort") ? URL_QA : URL_PROD }
                    />
                )}
            />
            <ToastContainer theme="colored" />
        </>
    );
}

export default LoginPage;
