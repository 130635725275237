import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ProjectPhaseService {

    create(data: any) {
        return http?.post(baseURL.activitiesapi.projectPhase + "/", data);
    }

    delete(idProjectPhase: number) {
        return http?.delete(baseURL.activitiesapi.projectPhase + `/${idProjectPhase}`);
    }

    update(id: number, data: any) {
        return http?.put(baseURL.activitiesapi.projectPhase + `/${id}`, data);
    }
    
}

export default ProjectPhaseService;