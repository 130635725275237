import axios from "axios";
import {
    ACCESS_TOKEN_KEY,
    PROJECT_ID_KEY,
    PROJECT_KEY, PROJECT_OBJ_KEY,
    server_address, TIME_FORMAT,
    USER_INFO_KEY,
    USER_LANGUAGE_KEY
} from "../util/Constants";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
    (config) => {
        let localDate = new Date((new Date()).getTime() - (new Date()).getTimezoneOffset() * 60000);
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        const currentLanguage = localStorage.getItem(USER_LANGUAGE_KEY);
        if (accessToken) {
            config.headers["Authorization"] = "Bearer " + accessToken;
        }
        if (currentLanguage) {
            config.headers["Accept-Language"] = currentLanguage;
        }
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
        config.headers["Content-type"] = "application/json";
        config.headers["localDatetime"] = localDate.toISOString();

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


axios.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        console.log("#Error " + JSON.stringify(error.message));
        //console.log("ERRORRRRRRR " + JSON.stringify(error.response));
        const originalRequest = error.config;
        // let refreshToken = localStorage.getItem("refreshToken");

        if (
            // refreshToken &&
            error.response.status === 401
            || error.response.status === 403
            // && error.response.message.includes("TOKEN_EXPIRED")
        ) {
            if (
                localStorage.getItem(ACCESS_TOKEN_KEY) !==
                originalRequest.headers["Authorization"].replace("Bearer ", '')
            ) {
                return axios(originalRequest);
            } else if (!originalRequest._retry /*|| originalRequest._retry < 3*/) {
                originalRequest._retry = true;
                const args = {
                    headers: {
                        "isRefreshToken": "true",
                    }
                };
                axios?.options("/", args);
                return axios
                    .get(`${server_address}/token/refresh-token`)
                    .then((res) => {
                        if (res.status === 200 && res.data.success === "true") {
                            localStorage.setItem(ACCESS_TOKEN_KEY, res.data.accessToken);
                            console.log("Access token refreshed!");
                            return axios(originalRequest);
                        } else if (res.data?.internalError === "true") {
                            if (res.data?.message === "unauthorized") {
                                return axios(originalRequest);
                            } else {
                                //alert(res.data?.message);
                                localStorage.removeItem(ACCESS_TOKEN_KEY);
                                localStorage.removeItem(USER_INFO_KEY);
                                window.location.reload();
                            }
                        }
                    });
            } else {
                localStorage.removeItem(ACCESS_TOKEN_KEY);
                localStorage.removeItem(USER_INFO_KEY);
                window.location.reload();
            }
        }
        return Promise.reject(error);
    }
);

export {axios as ApiProtectedWS};
