import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class UserCompanyPositionTypeService {

    getAll() {
        return http?.get(baseURL.readApi.userCompanyPositionType + "/");
    }
}

export default UserCompanyPositionTypeService;