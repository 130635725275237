import SiteWrapper from "../../SiteWrapper.react";
import React from "react";
import '../../css/ProjectDetail.css';
import {useFormik} from "formik";
import {Container, Card, Box, TextField, FormControl, InputLabel, Button, Stack, Select, MenuItem} from "@mui/material";
import {TrueffortButton} from "../../MUIstyle/MUICustom";
import {Save as SaveIcon} from '@mui/icons-material';
import ProjectElementService from "../../services/projectElement.service";
import {toast} from "react-toastify";


export type NewTaskForm = {
    idTaskType: number,
    estimatedDays: number,
    estimatedTime: number
};

export type NewElementForm = {
    idElementType: number,
    name: string,
};

// @ts-ignore
function AddElementForm({t, formState, setFormState, elementTypes, project, _fillProjectInfo}) {
    const projectElementService = React.useMemo(() => new ProjectElementService(), []);

    const validate = (values: NewElementForm) => {
        let errors: any = {};
        if (!values.idElementType) {
            errors.idElementType = "Required";
        }
        if (!values.name) {
            errors.name = "Required";
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            idElementType: 0,
            name: ""
        },
        validate: validate,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values: any) => {
        let newProjectElement = {
            name: values.name,
            elementType: {
                idElementType: values.idElementType,
                projectType: {
                    idProjectType: project.idProjectType
                }
            },
            project: {
                idProject: project.idProject
            }
        }
        // @ts-ignore
        projectElementService.create(newProjectElement).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else {
                setFormState({...formState, showFormAddElement: false, isCreating: false});
                toast.success(t("project_page.msg_success_pe_add"));
            }
            _fillProjectInfo();
        });
    }

    const handleCancel = () => {
        formik.resetForm();
        setFormState({...formState, isCreating: false, isEditing: false, showFormAddElement: false})
    }


    return (
        <Card sx={{padding: "12px", marginTop: "1rem", overflow: "unset", marginBottom: "1rem"}} className="background-gray">
            <h6>{t("project_tasks_page.new_element")}</h6>

            <Stack direction={{ xs: 'column', sm: "row" }} spacing={2}>
                <TextField
                    id="idTxNewElementName"
                    size="small"
                    fullWidth
                    sx={{background: "white"}}
                    placeholder={t("project_page.elem_name_placeholder")}
                    label={t("Name")}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors?.name && formik.touched?.name ? t(formik.errors.name) : null}
                 />

                <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <Select
                        id="idSlNewElementType"
                        sx={{background: "white"}}
                        size="small"
                        label={t("project_tasks_page.select_element")}
                        name="idElementType"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.idElementType}
                        error={formik.errors?.idElementType && formik.touched?.idElementType ? t(formik.errors.idElementType) : null}
                    >
                        {elementTypes.map((elemType: any) =>
                            <MenuItem key={elemType.idElementType} value={elemType.idElementType}>
                                {elemType.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>

                <TrueffortButton id="idBtnSaveElement" color="primary" variant="outlined" size="small" onClick={() => formik.handleSubmit()}>
                    <SaveIcon />
                </TrueffortButton>
                <TrueffortButton id="idBtnSaveElement" color="secondary" variant="outlined" size="small" onClick={handleCancel}>
                    X
                </TrueffortButton>
            </Stack>
        </Card>
    );
}

export default AddElementForm;