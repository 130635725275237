import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {confirmAlert} from "react-confirm-alert";
import {TrueffortChip, TrueffortConfirmAlert} from "../../MUIstyle/MUICustom";
import {
    Chip,
    Collapse,
    Grid,
    IconButton, MenuItem,
    Paper,
    Select as MaterialSelect,
    Stack,
    SvgIcon,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ErrorIcon from "@mui/icons-material/Error";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderTree} from "@fortawesome/free-solid-svg-icons/faFolderTree";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {toast} from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CollapsibleActivities from "./CollapsibleActivities";
import {useProjectPlan, useProjectPlanDispatch} from "./ProjectPlanContext";
import { formatSeconds } from "../../util/GlobalFunctions";
import { TIME_FORMAT } from "../../util/Constants";
import {DurationTypeContext} from "./EstimationPageUtils";

export default function CollapsiblePhase(props: any) {

    const {t} = useTranslation();

    const projectPlanDispatch = useProjectPlanDispatch();
    const statisticalRange = useContext(DurationTypeContext);

    const [showTasks, setShowTasks] = React.useState(false);
    const [durationFocused, setDurationFocused] = React.useState(false);
    const [createTask, setCreateTask] = React.useState(false);
    const [taskTypes, setTaskTypes] = React.useState(() => {
        const tasks = JSON.parse(JSON.stringify(props.phase.phaseType.tasktypes));
        return props.phase.projectPhase.projectTasks.map(
            (projectTask: any) =>
                tasks.find((taskType: any) => taskType.id === projectTask.idTaskType)
        );
    });
    const [notAddedTasks, setNotAddedTasks] = React.useState(() => {
        const tasks = JSON.parse(JSON.stringify(props.phase.phaseType.tasktypes));
        const projectTasksIds = props.phase.projectPhase.projectTasks.map(
            (prokectTask: any) => prokectTask.idTaskType
        );
        return tasks.filter((taskType: any) => !projectTasksIds.includes(taskType.id));
    });
    const [duration, setDuration] = React.useState(
        Math.round(props.phase.projectPhase.estimatedDays)
    );

    if (duration !== props.phase.projectPhase.estimatedDays && !durationFocused) {
        setDuration(Math.round(props.phase.projectPhase.estimatedDays));
    }

    const adjustedDurationMin = statisticalRange ? props.phase.phaseType.duration_min :
        props.phase.projectPhase.projectTasks.reduce((acc: number, taskType: any) =>
            acc < taskType.estimatedDays ? taskType.estimatedDays : acc,
            0
        );
    const adjustedDurationMax = statisticalRange ? props.phase.phaseType.duration_max :
        props.phase.projectPhase.projectTasks.reduce(
            (acc: number, taskType: any) => acc + taskType.estimatedDays,
            0
        );
    const phaseTimeMin = props.phase.projectPhase.projectTasks.reduce(
        (acc: number, task: any) =>
            acc < task.estimatedDays ? task.estimatedDays : acc,
        0
    );

    const deleteActivity = (taskIndex: number, taskEffort: number) => {
        //const plan = JSON.parse(JSON.stringify(props.projectPlan));
        const tasks = JSON.parse(JSON.stringify(taskTypes));
        const deletedTasks = JSON.parse(JSON.stringify(notAddedTasks));

        deletedTasks.push(
            taskTypes[taskIndex]
        );
        tasks.splice(taskIndex, 1);
        projectPlanDispatch({
            type: 'deleteTask',
            taskEffort: taskEffort,
            planIndexes: {
                elementTypeIndex: props.indexes.elementTypeIndex,
                projectElementIndex: props.indexes.elementIndex,
                phaseIndex: props.indexes.phaseIndex,
                taskIndex: taskIndex
            },
            adjustedDuration: !statisticalRange
        });
        setTaskTypes(tasks);
        setNotAddedTasks(deletedTasks);
    }

    const handleTaskDelete = (taskIndex: number, taskEffort: number) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <TrueffortConfirmAlert
                        title={
                            <Typography color='error' variant='h6' fontWeight={700} marginBottom='20px' >
                                <ReportProblemIcon color='error' />&nbsp;&nbsp;{t("Warning")}
                            </Typography>
                        }
                        alertColor='error'
                        messages={[
                            t("project_page.msg_delete_task_confirm2") + taskTypes[taskIndex].name + "?",
                            t("project_page.msg_delete_task_confirm3")
                        ]}
                        buttons={[
                            {
                                label: t("project_page.msg_delete_task_confirm_no"),
                                onClick: onClose
                            },
                            {
                                label: t("project_page.msg_delete_task_confirm_yes"),
                                onClick: () => {
                                    deleteActivity(taskIndex, taskEffort);
                                    onClose();
                                }
                            }
                        ]}
                    />
                );
            }
        });
    }

    const addTask = (taskType: any) => {
        const tasks = JSON.parse(JSON.stringify(taskTypes));
        tasks.unshift(taskType);
        projectPlanDispatch({
            type: 'addTask',
            newTaskType: taskType,
            planIndexes: {
                elementTypeIndex:  props.indexes.elementTypeIndex,
                projectElementIndex: props.indexes.elementIndex,
                phaseIndex: props.indexes.phaseIndex
            },
            adjustedDuration: !statisticalRange
        });

        setTaskTypes(tasks);
        setNotAddedTasks(notAddedTasks.filter((task: any) => task.id !== taskType.id));
    }

    const handlePhaseDurationChange = (timeValue: number) => {
        if (timeValue < 0 || timeValue < phaseTimeMin || (!statisticalRange && timeValue > adjustedDurationMax)) {
            setDuration(
                Math.round(props.phase.projectPhase.estimatedDays)
            );
            return;
        }

        setDuration(timeValue);
        projectPlanDispatch({
            type: 'changeDuration',
            newDurationValue: timeValue,
            level: 2,
            planIndexes: {
                elementTypeIndex: props.indexes.elementTypeIndex,
                projectElementIndex: props.indexes.elementIndex,
                phaseIndex: props.indexes.phaseIndex
            },
            adjustedDuration: !statisticalRange
        });
    }

    const handleDurationBlur = () => {
        if (duration === '') {
            setDuration(Math.round(props.phase.projectPhase.estimatedDays));
        } else {
            handlePhaseDurationChange(Math.round(Number(duration)));
        }

        setDurationFocused(false);
    }

    return (
        <>
            {!props.hideTree && 
            <Paper
                variant="outlined"
                square
                sx={{ padding: '10px', backgroundColor: 'rgba(215, 235, 240, 0.30)' }}
            >
                <Grid container spacing={1} alignItems="center" >
                    <Grid item xs={.5} container alignItems='center' justifyContent='flex-end' >
                        {(Math.round(props.phase.projectPhase.estimatedTime) <= 0 ||
                                Math.round(props.phase.projectPhase.estimatedDays) <= 0) &&
                            <Tooltip title={t("estimation_page.cero_values_error")} placement='top'>
                                <ErrorIcon color='error' />
                            </Tooltip>
                        }
                    </Grid>
                    <Grid item xs={3} >
                        <Stack direction="row" spacing={5} alignItems="center" justifyContent='space-between' >
                            <Typography >
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setShowTasks(!showTasks)}
                                >
                                    {showTasks ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                                </IconButton>

                                {props.phase.phaseType.name}
                            </Typography>

                            <Chip
                                sx={{ borderRadius: '3px' }}
                                icon={<FontAwesomeIcon icon={faFolderTree} color={'#9A64E2'} />}
                                label={props.phase.projectPhase.projectTasks.length + " " + t("home.tasks")}
                                variant='outlined'
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={2} >
                        <Typography textAlign='center' >
                            {formatSeconds(Math.round(props.phase.projectPhase.estimatedTime) * 3600, false, false, false)  + (localStorage.getItem(TIME_FORMAT) == 'dec' ? " " + t("estimation_page.man_hours") : "")}
                            {/*Math.round(props.phase.projectPhase.estimatedTime).toLocaleString() + " " + t("estimation_page.man_hours")*/}
                        </Typography>
                    </Grid>

                    <Grid item xs={2} >
                        <Stack direction='row' spacing={1} alignItems='center' justifyContent='center' >
                            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                <Typography variant='caption' >
                                    Min
                                </Typography>

                                <TrueffortChip
                                    icon={
                                        <SvgIcon >
                                            <svg
                                                /*width="30"
                                                height="30"*/
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                                                    fill={
                                                        Math.round(props.phase.projectPhase.estimatedDays) ===
                                                        /*props.phase.phaseType.duration_min*/
                                                        adjustedDurationMin ?
                                                            'white' :
                                                            '#6693CB'
                                                    }
                                                />
                                            </svg>
                                        </SvgIcon>
                                    }
                                    size='small'
                                    color='secondary'
                                    variant={
                                        Math.round(props.phase.projectPhase.estimatedDays) ===
                                        /*props.phase.phaseType.duration_min*/
                                        adjustedDurationMin ?
                                            'filled' :
                                            'outlined'
                                    }
                                    label={
                                        /*props.phase.phaseType.duration_min*/
                                        adjustedDurationMin
                                    }
                                    onClick={() => handlePhaseDurationChange(
                                        /*props.phase.phaseType.duration_min*/
                                        adjustedDurationMin
                                    )}
                                />
                            </Stack>

                            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                <Typography variant='caption' >
                                    {t("estimation_page.avg")}
                                </Typography>

                                <TextField
                                    name="estimatedDays"
                                    //sx={{ width: '89px' }}
                                    type="number"
                                    InputProps={{ inputProps: { min: phaseTimeMin } }}
                                    size="small"
                                    //disabled={!isModifiable || !elementType.active}
                                    onFocus={() => setDurationFocused(true)}
                                    onBlur={handleDurationBlur}
                                    value={durationFocused ? duration : props.phase.projectPhase.estimatedDays}
                                    onChange={(event: any) =>
                                        durationFocused ? setDuration(event.target.value) :
                                            handlePhaseDurationChange(Math.round(Number(event.target.value)))
                                    }
                                    onKeyDown={(event: any) => {
                                        if (event.key === "Enter")
                                            handlePhaseDurationChange(Math.round(Number(event.target.value)));
                                    }}
                                    error={Math.round(props.phase.projectPhase.estimatedDays) <= 0 || duration <= 0}
                                />
                            </Stack>

                            <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                                <Typography variant='caption' >
                                    Max
                                </Typography>

                                <TrueffortChip
                                    icon={
                                        <SvgIcon >
                                            <svg
                                                width="13"
                                                height="13"
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                                                    fill={
                                                        Math.round(props.phase.projectPhase.estimatedDays) ===
                                                        /*props.phase.phaseType.duration_max*/
                                                        adjustedDurationMax ?
                                                            'white' :
                                                            '#6693CB'
                                                    }
                                                />
                                            </svg>
                                        </SvgIcon>
                                    }
                                    size='small'
                                    color='secondary'
                                    variant={
                                        Math.round(props.phase.projectPhase.estimatedDays) ===
                                        /*props.phase.phaseType.duration_max*/
                                        adjustedDurationMax ?
                                            'filled' :
                                            'outlined'
                                    }
                                    label={
                                        /*props.phase.phaseType.duration_max*/
                                        adjustedDurationMax
                                    }
                                    onClick={() => handlePhaseDurationChange(
                                        /*props.phase.phaseType.duration_max*/
                                        adjustedDurationMax
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item xs container justifyContent="center" >
                        <Stack direction="row" spacing={3} >
                            <TrueffortChip
                                icon={<AddIcon fontSize="small" />}
                                size="small"
                                label={t("project_page.add_task")}
                                variant="outlined"
                                color='secondary'
                                //disabled={formikTask.isSubmitting}
                                onClick={() => setCreateTask(true)}
                            />
                            <TrueffortChip
                                icon={<DeleteIcon fontSize="small" />}
                                size="small"
                                label={t("delete")}
                                variant="outlined"
                                color='redExtra'
                                //disabled={formikTask.isSubmitting}
                                onClick={() =>
                                    props.handlePhaseDelete(props.indexes.phaseIndex)
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>}

            <Collapse in={showTasks || props.hideTree} timeout="auto" unmountOnExit >
                {!props.hideTree && <Paper variant="outlined" square sx={{ padding: '10px', backgroundColor: '#F2E9FF' }} >
                    <Grid container spacing={1} >
                        <Grid item xs={1} ></Grid>
                        <Grid item xs={3} >
                            <Typography color='#553D74' sx={{ display: 'flex', alignItems: "center" }} >
                                <AssignmentIcon fontSize="small" sx={{ color: '#553D74', marginRight: '6px' }} />
                                {t("home.tasks")}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} >
                            <Typography textAlign='center' variant='subtitle2' >
                                {t("estimation_page.participants")}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} >
                            <Typography textAlign='center' variant='subtitle2' >
                                {t("estimation_page.effort_man_hours")}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <Typography textAlign='center' variant='subtitle2' >
                                {t("estimation_page.days_duration")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>}

                {createTask &&
                    <AddTaskForm
                        t={t}
                        notAddedTasks={JSON.parse(JSON.stringify(notAddedTasks))}
                        setCreateTask={setCreateTask}
                        addTask={addTask}
                    />
                }

                {props.phase.projectPhase.projectTasks.map((projectTask: any, index: number) => (
                    <CollapsibleActivities
                        key={projectTask.idTaskType.toString()}
                        task={ { projectTask: projectTask, taskType: taskTypes[index] } }
                        handleTaskDelete={handleTaskDelete}
                        //planDurationChange={props.planDurationChange}
                        indexes={({...props.indexes, taskIndex: index})}
                        //users={props.users}
                        hideTree={props.hideTree}
                    />
                ))}
            </Collapse>
        </>
    );

}

function AddTaskForm({ t, notAddedTasks, setCreateTask, addTask } : any) {
    const [idSelectedTaskType, setIdSelectedTaskType] = React.useState('');
    const selectedTaskType = (
        idSelectedTaskType !== '' ?
            notAddedTasks.find((task: any) => task.id == idSelectedTaskType) :
            {
                id: '',
                name: '',
                effort_min: '',
                effort_max: '',
                effort_avg: '',
                duration_avg: '',
                duration_min: '',
                duration_max: '',
                team_sz_avg: '',
            }
    );

    const handleTaskAdd = () => {
        if (selectedTaskType.id === '') {
            return;
        }

        addTask(selectedTaskType);

        toast.success(t("estimation_page.task_added"));
        reset();
    }

    const reset = () => {
        setIdSelectedTaskType('');
        setCreateTask(false);
    }

    return (
        <Paper
            variant="outlined"
            square
            sx={{ padding: '10px', backgroundColor: 'rgba(242, 233, 255, 0.30)' }}
        >
            <Grid container spacing={2} alignItems="center" >
                <Grid item xs={1} ></Grid>
                <Grid item xs={3} >
                    <MaterialSelect
                        size='small'
                        fullWidth
                        displayEmpty
                        disabled={!notAddedTasks.length}
                        onChange={(event: any) => setIdSelectedTaskType(event.target.value)}
                        value={idSelectedTaskType}
                    >
                        <MenuItem
                            value=""
                            disabled
                        >
                            {notAddedTasks.length ? t("Select") : t("estimation_page.no_tasks")}
                        </MenuItem>
                        {notAddedTasks.map((taskType: any) =>
                            <MenuItem
                                key={taskType.id}
                                value={taskType.id}
                            >
                                {taskType.name}
                            </MenuItem>
                        )}
                    </MaterialSelect>
                </Grid>

                <Grid item xs={2} >
                    <Stack direction='row' spacing={1} alignItems='center' justifyContent='center' >
                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                {t("estimation_page.average")}
                            </Typography>

                            <TrueffortChip
                                //size='small'
                                color='purple'
                                variant='outlined'
                                label={selectedTaskType.id ? selectedTaskType.team_sz_avg : '-'}
                            />
                        </Stack>

                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                {t("estimation_page.selected_participants")}
                            </Typography>

                            <TrueffortChip
                                //size='small'
                                color='purple'
                                label={0}
                            />
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={2} >
                    <Stack direction='row' spacing={1} >
                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                Min
                            </Typography>
                            <TrueffortChip
                                icon={
                                    <SvgIcon >
                                        <svg
                                            /*width="30"
                                            height="30"*/
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                                                fill="#6693CB"
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                                size='small'
                                color='secondary'
                                variant='outlined'
                                label={selectedTaskType.effort_min}
                            />
                        </Stack>

                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                {t("estimation_page.avg")}
                            </Typography>
                            <TextField
                                name="estimatedDays"
                                //sx={{ width: '89px' }}
                                size="small"
                                disabled
                                value={selectedTaskType.effort_avg}
                            />
                        </Stack>

                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                Max
                            </Typography>

                            <TrueffortChip
                                icon={
                                    <SvgIcon >
                                        <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                                                fill="#6693CB"
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                                size='small'
                                color='secondary'
                                variant='outlined'
                                label={selectedTaskType.effort_max}
                            />
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={2} >
                    <Stack direction="row" spacing={1} >
                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                Min
                            </Typography>
                            <TrueffortChip
                                icon={
                                    <SvgIcon >
                                        <svg
                                            /*width="30"
                                            height="30"*/
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.5 10.5L5.5 8.5H4V2.5H3V8.5H1.5L3.5 10.5ZM6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5Z"
                                                fill="#6693CB"
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                                size='small'
                                color='secondary'
                                variant='outlined'
                                label={selectedTaskType.duration_min}
                            />
                        </Stack>

                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                {t("estimation_page.avg")}
                            </Typography>
                            <TextField
                                name="estimatedDays"
                                //sx={{ width: '89px' }}
                                size="small"
                                disabled
                                value={selectedTaskType.duration_avg}
                            />
                        </Stack>

                        <Stack direction="column" spacing={0} alignItems="center" justifyContent="center" >
                            <Typography variant='caption' >
                                Max
                            </Typography>

                            <TrueffortChip
                                icon={
                                    <SvgIcon >
                                        <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M6 4.5H10.5V5.5H6V4.5ZM6 6.5H9.5V7.5H6V6.5ZM6 2.5H11.5V3.5H6V2.5ZM6 8.5H8.5V9.5H6V8.5ZM3 10H4V4H5.5L3.5 2L1.5 4H3V10Z"
                                                fill="#6693CB"
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                                size='small'
                                color='secondary'
                                variant='outlined'
                                label={selectedTaskType.duration_max}
                            />
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs container justifyContent="center" >
                    <Stack direction="row" spacing={1} >
                        <Chip
                            size="small"
                            icon={<CheckIcon fontSize="small" />}
                            label={t("project_page.add_task")}
                            variant="outlined"
                            color="success"
                            onClick={handleTaskAdd}
                        />

                        <Chip
                            size="small"
                            icon={<CancelIcon fontSize="small" />}
                            label={t("Cancel")}
                            variant="outlined"
                            color='error'
                            onClick={reset}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    );

}
