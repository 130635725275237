import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";


class ProfileService {

    find() {
      return http?.get(baseURL.readApi.profiles);
    }
    getAll() {
        return http?.get(baseURL.activitiesapi.profiles + "/");
    }

    create(data: any) {
        return http?.post(baseURL.activitiesapi.profiles + "/", data);
    }

    update(id: number, data: any) {
        return http?.patch(baseURL.activitiesapi.profiles + `/${id}`, data);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.profiles + `/${id}`);
    }
}

export default ProfileService;