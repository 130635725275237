import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CardByList from "../custom/CardByList";
import { formatDateString } from "../../util/DateDataFormat";
import ProjectDetailUserCard from "../projectDetail/ProjectDetailUserCard";
import { ID_STATUS_INPROCESS } from "../../util/Constants";

function CardTaskDetail ({ task }) {
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = React.useState(false);

  return (
    <CardByList borderColor={"#063873"}>
      <div onClick={() => setOpenDetail(!openDetail)} 
        style={{cursor:'pointer', paddingBottom:'0.2em'}}>
          <u>{task.projectPhase.projectElement.name + " -> " + task.projectPhase.phaseType.name + " -> " + task.taskType.name}</u>
      </div>
      <Grid xs={12} container spacing={1}>
          {openDetail && <>
            <Grid item xs={4}>
              {t("reports.element") + ": " + task.projectPhase.projectElement.name}
            </Grid>
            <Grid item xs={4}>
              {t("reports.phase") + ": " + task.projectPhase.phaseType.name}
            </Grid>
            <Grid item xs={4}>
              {t("reports.task") + ": " + task.taskType.name}
            </Grid>
            <Grid item xs={4}>
              {t("reports.projects.start_date") +": " + (task.startDate ? formatDateString(task.startDate, 'yyyy-MM-dd') : '-')}
            </Grid>
            <Grid item xs={4}>
              {t("reports.projects.estimated_finish_date") + ": " + (task.estimatedFinishDate ? formatDateString(task.estimatedFinishDate, 'yyyy-MM-dd') : '-')}
            </Grid>
            {task.status.idStatus == ID_STATUS_INPROCESS && <Grid item xs={4}>
                {t("reports.projects.delay_days") + ": " + task.daysDelay}
              </Grid>
            }
            <Grid item xs={4}>
              {t("reports.projects.estimated_effort") + ": " + task.estimatedTime.toFixed(2) + " hrs"}
            </Grid>
            <Grid item xs={4}>
              {t("reports.projects.dedicated_effort") + ": " + (task.dedicatedTime ? task.dedicatedTime.toFixed(2) : 0) + " hrs"}
            </Grid>
            <Grid item xs={12}>
              <span>{t("reports.projects.assigned_users")}</span>
            </Grid>
            <Grid container xs={12} spacing={1}>
              {task.users.map((us, index) => (
                <Grid item xs={3}>
                  <ProjectDetailUserCard
                    userInfo={us.user}
                    showDelete={false}
                    index={index}
                    detail={true}
                    pendingSave={false}
                    showRating={false}
                  >
                    {t("reports.projects.dedicated_effort") +": " + (us.dedicatedTime ? us.dedicatedTime.toFixed(2) : 0) + " hrs"}
                  </ProjectDetailUserCard>
                </Grid>
              ))}
            </Grid>
            </>}
      </Grid>
    </CardByList>
  );
}

export default CardTaskDetail;