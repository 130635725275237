import SiteWrapper from "../SiteWrapper.react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, Link} from "react-router-dom";
import EstimationsService from "../services/estimations.service";
import {useAuthContext} from "../contexts/AuthenticationContext";
import {User} from "../util/Interfaces";
import '../css/HomePage.css';
import {MIN_LEVEL_ADMIN, MIN_LEVEL_LEADER, MONTHS} from "../util/Constants";
import { Doughnut, Bar, Pie, Line } from "react-chartjs-2";
import {toast} from "react-toastify";
import { Chart, ArcElement, BarElement, PointElement, LineElement, Legend, Tooltip} from "chart.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import UserService from "../services/user.service";
import { formatDateString } from "../util/DateDataFormat";
import HomeEmpty from "../components/home/HomeEmpty"
import { Container, Card, Box, FormControl, ButtonProps, createTheme,
    ThemeProvider, Button, Stack, CircularProgress, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {TrueffortButton, TrueffortSearchInput, TrueffortLinearProgress, HelperDialog} from "../MUIstyle/MUICustom";
import ProjectCard from "../components/home/ProjectCard";
import {ImportExport as ImportExportIcon, InsertChart as InsertChartIcon} from '@mui/icons-material';
import {helperDialogs} from "../util/HelperDialogs";
import HelpIcon from "@mui/icons-material/Help";
import { useState, useEffect } from "react";

const statusButtonTheme = createTheme({
    palette: {
        primary: {
            main: '#07074E',
            dark: '#19149E',
            contrastText: '#fff'
        },
        secondary: {
            main: '#F5F5F5',
            dark: '#527BBD',
            contrastText: '#07074E'
        }
    }
});

export function StatusButton(props: ButtonProps) {
    return(
        <ThemeProvider theme={statusButtonTheme} >
            <Button {...props} sx={{ /*fontSize: '8.5px',*/ borderRadius: '4px', textTransform: 'none', '&:hover': {backgroundColor: "#eeeeee"} }} />
        </ThemeProvider>
    );
}

export const initPieChartProjects = {
      labels: ["", "", ""],
      datasets: [{label: 'Projects', data: [0, 0, 0], borderWidth: 1}],
}

export const optionsPie:any = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          padding: 6,
          font: {
              size: 10
          }
        }
      }
    }
}

export const arrayColorsSummaryTasks = ["purple", "teal", "green", "pink"] as const;

function HomePage() {
    Chart.register(ArcElement, BarElement, PointElement, LineElement, Legend, Tooltip);

    const history = useHistory();
    const {t} = useTranslation();
    const { user } = useAuthContext() as { user: User };
    const { features } = user.instance.license;
    const [projects, setProjects] = React.useState([]);
    const [projectsFiltered, setProjectsFiltered] = React.useState([]);
    const [inputSearch, setInputSearch] = useState("");
    const [order,setOrder] = React.useState("ASC");
    const [showLoader, setShowLoader] = React.useState(true);
    let states = [{ id: 0, name: t("home.states.all") }, { id:1, name:t("home.states.created") },
        { id: 2, name: t("home.states.inProcess") }, { id: 4, name:t("home.states.finished") }];
    const [selectedState, setSelectecState] = React.useState(states[2]);
    const [lastUserActivity, setLastUserActivity] = React.useState(null);
    const estimationService = React.useMemo(() => new EstimationsService());
    const userService = React.useMemo(() => new UserService(), []);
    const [dataChartPieProjects, setDataChartPieProjects] = React.useState(initPieChartProjects);
    const [topSummaryTasks, setTopSummaryTasks] = React.useState([]);
    const [dataChartTrendGlobal, setDataChartTrendGlobal] = React.useState(null);
    const [dataChartTrendGlobalEffort, setDataChartTrendGlobalEffort] = React.useState(null);
    const [optionsChartGlobalEffort, setOptionsChartGlobalEffort] = React.useState(null);
    const [optionsChartGlobalTasks, setOptionsChartGlobalTasks] = React.useState(null);
    const [activeHelperDialogIndex, setActiveHelperDialogIndex] = React.useState(-1);


    React.useEffect(() => {
        setShowLoader(true);
        fillProjectTypes();
        fillSummaryTasks();
        fillDataTrendGlobal();
        userService.getLastActivity().then(({data: response}:any) => {
            setLastUserActivity(response);
        }).catch(({data: response}:any) => {
            console.error("Error getting last activity");
        });
        const id = setInterval(() => fillProjectTypes(), 20000);
        return () => {
          clearInterval(id);
        }
    }, []);

    React.useEffect(() => {
        // Calcular terminados, en proceso y sin iniciar
        let numProjectsCreated = projects.filter((p: any) => p.status_id == 1).length;
        let numProjectsProcess = projects.filter((p: any) => p.status_id == 2).length;
        let numProjectsFinished = projects.filter((p: any) => p.status_id == 4).length;
        //console.log("projects", projects);

        setDataChartPieProjects({
              labels: [t("project_tasks_page.status_finished"), t("project_tasks_page.status_process"), t("project_tasks_page.status_created")],
              datasets: [
                {
                  label: '# of Votes',
                  data: [numProjectsFinished, numProjectsProcess, numProjectsCreated],
                  backgroundColor: [
                      'rgba(146, 207, 82, 1)',
                      'rgba(100, 168, 236, 1)',
                      'rgba(152, 152, 152, 1)',
                    ],
                    borderColor: [
                      'rgba(146, 207, 82, 1)',
                      'rgba(100, 168, 236, 1)',
                      'rgba(152, 152, 152, 1)',
                    ],
                  borderWidth: 1,
                },
              ],
        });

        // OBTENER LOS ÚLTIMOS PROYECTOS CON TAREAS TERMINADAS

    }, [projects]);

    const getOptionsDataTrend = (list:any) => {
        let maxy = Math.max(...(list.map((x:any) => x.finished_tasks)));
        let maxy1 = Math.max(...(list.map((x:any) => x.effort)));
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            barThickness: 30,
            plugins: {
                legend: {
                    display: true
                }
            },
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false
                    },
                    title: {
                        display: true,
                        text: t("home.effort_unit")
                    },
                    max:  maxy1 == 0 ? 10 : Math.round(maxy1) + (Math.round(maxy1) % 2 == 0 ? 2 : 1),
                    ticks: { stepSize: 2 }
                },
                x: {
                    display: false
                }
            }
        }
        return options;
    }

    const getOptionsDataTrendTasks = (list:any) => {
        let maxy = Math.max(...(list.map((x:any) => x.finished_tasks)));
        const options = {
            responsive: true,
            tension: 0.2,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true
                }
            },
            scales: {
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    title: {
                        display: true,
                        text: t("home.number_task_finished")
                    },
                    max: maxy == 0 ? 5 : Math.round(maxy) + (Math.round(maxy) % 2 == 0 ? 2 : 1),
                    min: 0,
                    ticks: { stepSize: 1 }
                },
                x: {
                  display: false
                }
            }
        }
        return options;
    }

    const getDataSet = (obj:any, color: string) => {
        let data = {
            datasets: [
                {
                    data: [obj.finished, obj.total - obj.finished],
                    backgroundColor: [color, "#F2F2F2"],
                    display: true,
                    borderWidth: 0
                }
            ]
        };
        return data;
    }

    const getWeeks = (list:any) => {
        return list.map((x:any) => x.bin_start);
    }

    const fillDataTrendGlobal = () => {
        // TENDENCIA DE TAREAS
        estimationService.getGlobalTrendTasks({instances: [user.instance.idInstance], trend_weeks: 5}).then(({data: response} : any) => {
            if (response.status == "ok") {
                let trendArr = response.instances[0].trend;

                let data = {
                    labels: getWeeks(trendArr),
                    datasets: [
                        {
                            label: t("home.finished_tasks"),
                            type: 'line' as const,
                            data: trendArr.map((x:any) => x.finished_tasks),
                            borderColor: "#F97C70",
                            borderWidth: 1,
                            yAxisID: 'y',
                            backgroundColor: "rgba(228, 85, 90, 0.3)",
                            fill: "origin",
                            pointStyle: "false",
                            pointRadius: 0
                        }
                    ],
                };
                setDataChartTrendGlobal(data);

                let maxy = Math.max(...(trendArr.map((x:any) => x.finished_tasks)));
                const options = {
                    responsive: true,
                    tension: 0.4,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                            boxHeight: 1,
                            labels: {
                                font: {
                                    size: 9
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            title: {
                                display: false,
                                text: t("home.number_task_finished")
                            },
                            max: maxy == 0 ? 5 : Math.round(maxy) + (Math.round(maxy) % 2 == 0 ? 2 : 1),
                            ticks: { stepSize: 1, font: { size: 9 } }
                        },
                        x: {
                            display: false
                        }
                    }
                }
                setOptionsChartGlobalTasks(options);
            }
        });

        // TENDENCIA DE ESFUERZO
        estimationService.getGlobalTrendEffort({instances: [user.instance.idInstance], weeks: 5}).then(({data: response} : any) => {
            if (response.status == "ok") {
                let trendArr = response.instances[0].trend;
                let data = {
                    labels: getWeeks(trendArr),
                    datasets: [
                        {
                            label: t("home.effort_unit"),
                            type: 'bar' as const,
                            data: trendArr.map((x: any) => x.effort),
                            borderColor: "#ED9A55",
                            borderWidth: 2,
                            yAxisID: 'y',
                            backgroundColor: "#ED9A55",
                            borderRadius: 16,
                        }
                    ]
                };
                setDataChartTrendGlobalEffort(data);

                let maxy1 = Math.max(...(trendArr.map((x:any) => x.effort)));
                let options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    barThickness: 16,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                            boxHeight: 1,
                            labels: {
                                font: {
                                    size: 9
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            grid: {
                                drawOnChartArea: true
                            },
                            title: {
                                display: false,
                                text: t("home.effort_unit")
                            },
                            max:  maxy1 == 0 ? 10 : Math.round(maxy1) + (Math.round(maxy1) % 2 == 0 ? 2 : 1),
                            ticks: { stepSize: 2, font: { size: 9 } }
                        },
                        x: {
                            display: false
                        }
                    }
                }
                setOptionsChartGlobalEffort(options);
            }
        });
    }

    const getDataTrends = (list:any, labels:any) => {
        let sum = 0;
        list.forEach((x:any) => sum += x.finished_tasks);
        //let color = sum == 0 ? 'rgb(255, 255, 255)' : 'rgb(255, 99, 132)';
        //let border = sum == 0 ? 0 : 2;
        let data = {
            labels,
            datasets: [
                {
                    label: t("home.effort_unit"),
                    type: 'bar' as const,
                    backgroundColor: 'rgb(75, 192, 192)',
                    data: list.map((x:any) => x.effort),
                    borderColor: 'white',
                    borderWidth: 2,
                    yAxisID: 'y1',
                }
            ],
        };
        return data;
    }

    const getDataTrendsTasks = (list:any, labels:any) => {
        let sum = 0;
        list.forEach((x:any) => sum += x.finished_tasks);
        let color = sum == 0 ? 'rgb(255, 255, 255)' : '#9A64E2';
        let border = sum == 0 ? 0 : 2;
        let data = {
           labels,
           datasets: [
               {
                   label: t("home.number_task_finished"),
                   type: 'line' as const,
                   borderColor: color,
                   borderWidth: border,
                   data: list.map((x:any) => x.finished_tasks),
                   yAxisID: 'y',
                   backgroundColor: "rgb(154,100,226, 0.3)",
                   fill: "origin"
               }
           ]
        };
        return data;
    }

    const getDataTrendsPhases = (list:any, labels:any) => {
            let sum = 0;
            list.forEach((x:any) => sum += x.finished_phases);
            let color = sum == 0 ? 'rgb(255, 255, 255)' : '#9A64E2';
            let border = sum == 0 ? 0 : 2;
            let data = {
               labels,
               datasets: [
                   {
                       label: t("home.number_phases_finished"),
                       type: 'line' as const,
                       borderColor: color,
                       borderWidth: border,
                       data: list.map((x:any) => x.finished_phases),
                       yAxisID: 'y',
                       backgroundColor: "rgb(154,100,226, 0.3)",
                       fill: "origin"
                   }
               ],
            };
            return data;
        }

    const getDataTrendsElements = (list:any, labels:any) => {
        let sum = 0;
        list.forEach((x:any) => sum += x.finished_elements);
        let color = sum == 0 ? 'rgb(255, 255, 255)' : '#9A64E2';
        let border = sum == 0 ? 0 : 2;
        let data = {
           labels,
           datasets: [
               {
                   label: t("home.number_elements_finished"),
                   type: 'line' as const,
                   borderColor: color,
                   borderWidth: border,
                   data: list.map((x:any) => x.finished_elements),
                   yAxisID: 'y',
                   backgroundColor: "rgb(154,100,226, 0.3)",
                   fill: "origin"
               }
           ],
        };
        return data;
    }

    const getDataVelocity = (list:any, property:string) => {
        //console.log(list);
        let total_finished = 0;
        let count = 0;
        list.forEach((x:any) => {
            if (x.effort != 0 || x[property] != 0) {
                total_finished += x[property];
                count++;
            }
        });
        let totalDays = count * 5;
        return (totalDays != 0 ? total_finished / totalDays : 0).toFixed(2);
    }

    const getDataPDRProductivity = (list:any, property:string) => {
        let totalEffort = 0;
        let total_finished = 0;
        list.forEach((x:any) => {
            totalEffort += x.effort;
            total_finished += x[property];
        });
        return {PDR: total_finished != 0 ? totalEffort / total_finished : 0,
            productivity: totalEffort != 0 ? total_finished / totalEffort : 0};
    }

    useEffect(() => {
      //console.log("inputSearch -> projects -> ",inputSearch);
      //(obj.id == 0 ? true : p.status_id == obj.id)
      let items = projects.filter((p:any) => selectedState.id == 0 ? true : p.status_id == selectedState.id);
      setProjectsFiltered(applyFilterInputSearch(items));
    }, [projects, inputSearch]);

    /*useEffect(() => {
      //console.log("inputSearch -> ", inputSearch);
      let items = projects.filter((p:any) => p.status_id == selectedState.id);
      setProjectsFiltered(applyFilterInputSearch(items));
    }, [inputSearch]);*/

    const fillProjectTypes = () => {
        // @ts-ignore
        estimationService.getProjects({instances: [user.instance.idInstance]}).then(({data: response} : any) => {
            if (Array.isArray(response.projects)) {
              //console.log("response.projects", response.projects);
              for (let i = 0; i < response.projects.length; i++) {
                  if (response.projects[i].end_date) {
                    let ind = response.projects[i].end_date.indexOf('T');
                    response.projects[i].end_date = response.projects[i].end_date.substring(0, ind);
                  }

                  response.projects[i]["dataSetElements"] = getDataSet(response.projects[i].elements, "#ED9A55");
                  response.projects[i]["dataSetPhases"] = getDataSet(response.projects[i].phases, "#E4555A");
                  response.projects[i]["dataSetTasks"] = getDataSet(response.projects[i].tasks, "#92CF52");
                  let trendList = [];
                  for(let j = 0; j < response.projects[i].trend.length; j++) {
                    trendList.push(response.projects[i].trend[j]);
                  }
                  response.projects[i]["dataTrends"] = getDataTrends(trendList, getWeeks(trendList));
                  response.projects[i]["dataTrendsTasks"] = getDataTrendsTasks(trendList, getWeeks(trendList));
                  response.projects[i]["dataTrendsPhases"] = getDataTrendsPhases(trendList, getWeeks(trendList));
                  response.projects[i]["dataTrendsElements"] = getDataTrendsElements(trendList, getWeeks(trendList));
                  response.projects[i]["optionsDataTrends"] = getOptionsDataTrend(trendList);
                  response.projects[i]["optionsDataTrendsTasks"] = getOptionsDataTrendTasks(trendList);
                  response.projects[i]["velocityTaskProject"] = getDataVelocity(trendList,'finished_tasks');
                  const tasks = getDataPDRProductivity(trendList, 'finished_tasks');
                  response.projects[i]["PDR_task"] = tasks.PDR.toFixed(2);
                  response.projects[i]["productivity_task"] = tasks.productivity.toFixed(tasks.productivity < 0.005 && tasks.productivity != 0 ? 4 : 2);
                  response.projects[i]["velocityElementsProject"] = getDataVelocity(trendList,'finished_elements');
                  const elements = getDataPDRProductivity(trendList, 'finished_elements');
                  response.projects[i]["PDR_elements"] = elements.PDR.toFixed(2);
                  response.projects[i]["productivity_elements"] = elements.productivity.toFixed(elements.productivity < 0.005 && elements.productivity != 0 ? 4 : 2);
                  response.projects[i]["velocityPhasesProject"] = getDataVelocity(trendList,'finished_phases');
                  const phases = getDataPDRProductivity(trendList, 'finished_phases');
                  response.projects[i]["PDR_phases"] = phases.PDR.toFixed(2);
                  response.projects[i]["productivity_phases"] = phases.productivity.toFixed(phases.productivity < 0.005 && phases.productivity != 0 ? 4 : 2);
              }
              setProjects(response.projects);
              //setProjectsFiltered(applyFilterInputSearch(response.projects.filter((p:any) => p.status_id == selectedState.id)));
            }
        }).catch((c:any) => {
            toast.error(c.response.data);
        }).finally(() => setShowLoader(false));
    }

    const fillSummaryTasks = () => {
        estimationService.getSummaryTasks({instances: [user.instance.idInstance], top: 4}).then(({data: response} : any) => {
            if (Array.isArray(response.projects)) {
                setTopSummaryTasks(response.projects);
            }
        }).catch((c:any) => {
            toast.error(c.response.data);
        });
    }

    /*const handleChangeSearchInput = (searchFilter: string, projects_:any) => {

        let items = projects_.filter((data : any) => {
            if (searchFilter == "" && (selectedState.id == 0 ? true : data.status_id == selectedState.id)) {
                return projects_;
            } else {
                if ((data.name.toLowerCase().includes(searchFilter.toLowerCase()) && (selectedState.id == 0 ? true : data.status_id == selectedState.id))) {
                    return data;
                }
            }
        });

        setProjectsFiltered(items);
    }*/

    const applyFilterInputSearch = (items_:any) => {
      /*console.log("applyFilterInputSearch");
      console.log(items_);
      console.log(inputSearch);*/
      let items = items_.filter((data : any) => {
        if (inputSearch == "" && (selectedState.id == 0 ? true : data.status_id == selectedState.id)) {
            return items_;
        } else {
            if ((data.name.toLowerCase().includes(inputSearch.toLowerCase()) && (selectedState.id == 0 ? true : data.status_id == selectedState.id))) {
                return data;
            }
        }
      });
      return items;
    }

    const drop = React.useRef(null);
    const [toogle, setToogle] = React.useState(false);

    function handleClick(e: any) {
        if (e.target.closest(`.${drop?.current?.className}`) && toogle) {
            setToogle(false);
        } else if(e.target.className != "btn form-filter-page form-control dropdown-toggle shadow dropdown-home" && e.target.className != drop?.current?.className) {
            setToogle(false);
        }
    }

    React.useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });

    const handleOpenHelp = () => {
        setActiveHelperDialogIndex(0);
    }

    const handleClose = () => {
        setActiveHelperDialogIndex(-1);
    };

    const handleActiveHelperChange = () => {
        if (activeHelperDialogIndex === helperDialogs['home'].length) {
            setActiveHelperDialogIndex(-1)
        } else {
            setActiveHelperDialogIndex((prev: number) => prev + 1)
        }
    }

    return (
        <SiteWrapper>
            <div className="my-3 my-md-5">
                <Container>
                    <Grid container spacing={2}>

                        <Grid xs={12}>
                            <HelperDialog
                                title={t(helperDialogs["home"][0].title)}
                                body={t(helperDialogs["home"][0].body)}
                                dialogIndex={0}
                                totalDialogs={projects.length === 0 ? 1 :
                                    helperDialogs["home"].length
                                }
                                handleNext={handleActiveHelperChange}
                                isOpen={activeHelperDialogIndex === 0}
                                handleClose={handleClose}
                            >
                            <div key={"tooltip"} style={{float: "right", backgroundColor: "#f9f9f9", fontSize: "14px", marginTop: ".4em"}}>
                                <a data-tooltip-id="tooltip-last-user-activity" >
                                    {t("dashboard.last_activity")}:
                                    {lastUserActivity != "" ?
                                        " " + (typeof lastUserActivity?.applicationName != "undefined" ?
                                            lastUserActivity?.applicationName > 20 ? lastUserActivity?.applicationName.substring(0, 20) + "..." : lastUserActivity?.applicationName :
                                            "")
                                        + " | " +
                                        (typeof lastUserActivity?.notes != "undefined" ?
                                            lastUserActivity?.notes?.length > 20 ? lastUserActivity?.notes.substring(0, 20) + "..." : lastUserActivity?.notes :
                                            "")
                                        + " | " + (typeof lastUserActivity?.endTime == "number" ? formatDateString(new Date(lastUserActivity?.endTime), 'dd/MM/yyyy HH:mm:ss') : "") :
                                        " "
                                    }
                                </a>
                                <ReactTooltip id="tooltip-last-user-activity" place="bottom" variant="info">
                                    {lastUserActivity != "" ?
                                        " " + (typeof lastUserActivity?.applicationName != "undefined" ? lastUserActivity?.applicationName : "")
                                        + " | " + (typeof lastUserActivity?.notes != "undefined" ? lastUserActivity?.notes : "")
                                        + " | " + (typeof lastUserActivity?.endTime == "number" ? formatDateString(new Date(lastUserActivity?.endTime), 'dd/MM/yyyy HH:mm:ss') : "") :
                                        " "
                                    }
                                </ReactTooltip>
                            </div>
                            </HelperDialog>
                            <h2>
                                {t("Projects.global_view")} &nbsp;
                                <IconButton size='small' onClick={handleOpenHelp} >
                                    <HelpIcon sx={{ color: '#6875C7' }} />
                                </IconButton>
                            </h2>
                        </Grid>

                        {showLoader &&
                          <Grid xs={12}>
                            <div style={{margin: "0 auto", width: "100px", marginTop: "10%"}}><CircularProgress /></div>
                          </Grid>
                        }
                        {!showLoader &&  projects.length == 0 &&
                            <HomeEmpty />
                        }
                        <Grid xs={12} md={10}>
                            {features.homePage && !showLoader && projects.length > 0 &&
                                <div key="data">
                                    <Card sx={{padding: "8px"}}>
                                        <Grid container spacing={1} rowSpacing={3}>
                                            <Grid xs={12} md={10} >
                                                <Stack spacing={1} direction="row">
                                                {states.map((obj: any, i: number) => (
                                                  <StatusButton
                                                    id={"idStatusButton_" + obj.id}
                                                    key={i}
                                                    color={selectedState.id == obj.id ? "primary" : "secondary"}
                                                    variant="contained"
                                                    onClick={() => {
                                                        /*console.log("buttons");
                                                        console.log(projects);
                                                        console.log(inputSearch);
                                                        console.log(obj);*/
                                                        setSelectecState(obj);
                                                        setProjects(projects.flat());
                                                        //setProjectsFiltered(applyFilterInputSearch(projects.filter((p:any) => (obj.id == 0 ? true : p.status_id == obj.id))));
                                                      }
                                                    }>
                                                      {obj.name}
                                                  </StatusButton>
                                                ))}
                                                </Stack>
                                            </Grid>

                                            {user.role.accessLevel >= MIN_LEVEL_ADMIN &&
                                                <Grid xs={12} md={2}>
                                                    <TrueffortButton
                                                        id="idButtonNewProject"
                                                        fullWidth color="primary" variant="contained"
                                                        onClick={() => history.push("/estimation")}>
                                                    + {t("project_page.add_new")}
                                                    </TrueffortButton>
                                                </Grid>
                                            }

                                            <Grid xs={8} md={10}>
                                                <FormControl fullWidth size="small">
                                                    <TrueffortSearchInput
                                                        id="idSearchInput"
                                                        value={inputSearch}
                                                        placeholder={t("project_page.search")} variant="outlined" size="small"
                                                        onChange={(e: any) => setInputSearch(e.target.value)} />
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={4} md={2}>
                                                <TrueffortButton
                                                    id="idSortButton"
                                                    fullWidth
                                                    size="small"
                                                    color="secondary"
                                                    variant="contained"
                                                    startIcon={<ImportExportIcon />}
                                                    style={{backgroundColor: "#DCDCDC", color: "#07074E"}}
                                                    onClick={() => {
                                                        setProjectsFiltered(projectsFiltered.sort((a:any,b:any) => a.name < b.name ? order == "ASC" ? 1 : -1 : a.name > b.name ? order == "ASC" ? -1 : 1 : 0));
                                                        setOrder(order == "ASC" ? "DESC": "ASC");
                                                    }}>
                                                    A-Z
                                                </TrueffortButton>
                                            </Grid>
                                        </Grid>
                                    </Card>

                                    <Card sx={{padding: "8px", marginTop: "15px"}}>
                                        {projectsFiltered.map((pro:any, ind:number) => (
                                            ind === 0 ?
                                                <HelperDialog
                                                    title={activeHelperDialogIndex === 2 ?
                                                        t(helperDialogs["home"][2].title) :
                                                        t(helperDialogs["home"][1].title)
                                                    }
                                                    body={activeHelperDialogIndex === 2 ?
                                                        t(helperDialogs['home'][2].body) :
                                                        t(helperDialogs["home"][1].body)
                                                    }
                                                    dialogIndex={activeHelperDialogIndex === 2 ? 2 : 1}
                                                    totalDialogs={helperDialogs["home"].length}
                                                    handleNext={handleActiveHelperChange}
                                                    isOpen={activeHelperDialogIndex === 1 || activeHelperDialogIndex === 2}
                                                    handleClose={handleClose}
                                                    position='top'
                                                >
                                                    <div>
                                                        <ProjectCard key={pro.id} project={pro} activeHelperDialogIndex={activeHelperDialogIndex} />
                                                    </div>
                                                </HelperDialog> :
                                                <ProjectCard key={pro.id} project={pro} />
                                        ))}
                                    </Card>
                                </div>
                            }
                        </Grid>

                        {!showLoader && projects.length > 0 &&
                        <Grid xs={12} md={2} sx={{background: "#F5F5F5", borderRadius: "0.625rem", padding: "0.375rem"}}>
                            <Box sx={{padding: "0rem 0.375rem", display: "block"}}>
                                <h5 className="color-primary"> <InsertChartIcon /> {t("home.real_time")}</h5>
                            </Box>

                            <Stack spacing={2}>
                                <Box sx={{borderRadius: "0.625rem", background: "white", padding: "0.3125rem"}}>
                                    <Box sx={{borderRadius: "0.25rem", background: "#F5F5F5", padding: "0.75rem", border: "solid thin #DCDCDC"}}>
                                        <span className="color-primary">{t("home.current_status")}</span>
                                        <Pie data={dataChartPieProjects} options={optionsPie} />
                                        <h5 className="color-primary" style={{textAlign: "center", marginTop: ".4rem", marginBottom: 0}}>{projects.length} {t("Projects.title")}</h5>
                                    </Box>
                                </Box>

                                <HelperDialog
                                    title={t(helperDialogs["home"][3].title)}
                                    body={t(helperDialogs["home"][3].body)}
                                    dialogIndex={3}
                                    totalDialogs={helperDialogs["home"].length}
                                    handleNext={handleActiveHelperChange}
                                    isOpen={activeHelperDialogIndex === 3}
                                    handleClose={handleClose}
                                    position='left'
                                >
                                    <Box sx={{borderRadius: "0.625rem", background: "white", padding: "0.3125rem"}}>
                                        <Box sx={{borderRadius: "0.25rem", background: "#F5F5F5", padding: "0.75rem", border: "solid thin #DCDCDC"}}>
                                            <Box sx={{borderBottom: "1px solid #DCDCDC", paddingBottom: ".2rem", marginBottom: ".4rem"}}>
                                                <h5 className="color-primary" style={{textAlign: "center", marginBottom: 0}}>{t("home.finished_tasks")}</h5>
                                                <Box sx={{fontSize: "0.7rem", textAlign: "center"}}>{t("home.text_real_time_reg")}</Box>
                                            </Box>
                                            <Stack spacing={1}>
                                            {topSummaryTasks.map((pr:any, k:number) => (
                                                <Box sx={{background: "white", borderRadius: "0.25rem", border: "solid thin #DCDCDC", padding: "0.625rem"}}>
                                                    <span style={{fontSize: "0.68rem"}}>{pr.name}</span>
                                                    <TrueffortLinearProgress variant="determinate" value={((pr.finished_tasks/pr.total_tasks)*100)} color={arrayColorsSummaryTasks[k]} />
                                                    <span style={{fontSize: "0.5rem"}}>{pr.finished_tasks} {t("home.finished_tasks")}</span>
                                                </Box>
                                            ))}
                                            </Stack>
                                        </Box>
                                    </Box>
                                </HelperDialog>

                                <HelperDialog
                                    title={t(helperDialogs["home"][4].title)}
                                    body={t(helperDialogs["home"][4].body)}
                                    dialogIndex={4}
                                    totalDialogs={helperDialogs["home"].length}
                                    handleNext={handleActiveHelperChange}
                                    isOpen={activeHelperDialogIndex === 4}
                                    handleClose={handleClose}
                                    position='left'
                                >
                                    <Box sx={{borderRadius: "0.625rem", background: "white", padding: "0.3125rem"}}>
                                        <Box sx={{borderRadius: "0.25rem", background: "#F5F5F5", padding: "0.75rem", border: "solid thin #DCDCDC"}}>
                                            <h5 className="color-primary" style={{textAlign: "center"}}>{t("home.global_trend")}</h5>
                                            <div style={{height: "120px"}}>
                                                <Line data={dataChartTrendGlobal} options={optionsChartGlobalTasks} />
                                            </div>
                                            <div style={{height: "120px"}}>
                                                <Line data={dataChartTrendGlobalEffort} options={optionsChartGlobalEffort} />
                                            </div>
                                        </Box>
                                    </Box>
                                </HelperDialog>
                            </Stack>
                        </Grid>
                        }
                    </Grid>

                </Container>
            </div>
        </SiteWrapper>
    );
}

export default HomePage;