import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class ProjectTypeService {

    getAll() {
        return http?.get(baseURL.readApi.projectType + "/");
    }

    createFromLayout(data : any) {
        return http?.post(baseURL.activitiesapi.projectType + "/createFromLayout/", data);
    }

    update(id : number, data : any) {
        return http?.put(baseURL.activitiesapi.projectType + `/${id}`, data);
    }

    hasElementsWithoutActivities(id: number) {
        return http?.get(baseURL.readApi.projectType + "/hasElementsWithoutActivities/" + id);
    }

    delete(id : number) {
        return http?.delete(baseURL.activitiesapi.projectType + `/${id}`);
    }

    reactivate(id : number) {
        return http?.put(baseURL.activitiesapi.projectType + `/active/${id}`);
    }
}

export default ProjectTypeService;