import {
    Box,
    Card,
    CardActions,
    CardContent, Chip,
    Collapse,
    Grid,
    MenuItem,
    Pagination,
    Paper,
    Stack, Tooltip,
    Typography
} from "@mui/material";
import {
    HelperDialog,
    TrueffortButton,
    TrueffortConfirmAlert,
    TrueffortSelect,
    TrueffortTextField
} from "../../MUIstyle/MUICustom";
import AddIcon from "@mui/icons-material/Add";
import {Dashboard} from "@mui/icons-material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import EditIcon from "@mui/icons-material/Edit";
import CollapsibleElement from "./CollapsibleElement";
import React, {useContext} from "react";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import {toast} from "react-toastify";
import {useProjectPlan, useProjectPlanDispatch} from "./ProjectPlanContext";
import EstimationsService from "../../services/estimations.service";
import {useTranslation} from "react-i18next";
import {complexityOptions, DurationTypeContext, sizeOptions} from "./EstimationPageUtils";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {confirmAlert} from "react-confirm-alert";
import InfoIcon from "@mui/icons-material/Info";
import {helperDialogs} from "../../util/HelperDialogs";

export default function EstimationDetailTable({ idProjectType, projectTypeName, elementTypes, savingState, estimateState, warningMessage, searchForCeroValues, submitProjectPlan, handleProjectEstimationCancel, detailHelperDialogsConfig }: any) {
    const {t} = useTranslation();

    const projectPlan = useProjectPlan();
    const projectPlanDispatch = useProjectPlanDispatch();

    const statisticalRange = useContext(DurationTypeContext);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [activePage, setActivePage] = React.useState(1);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [createNewElement, setCreateNewElement] = React.useState(false);

    const estimationsService = React.useMemo(() => new EstimationsService(), []);

    const elementsIndexes: { elementTypeIndex: number, elementIndex: number }[] =
        [...Array(projectPlan.elementTypes.length)].map((elem: any, i: number) =>
            [...Array(projectPlan.elementTypes[i].projectElements.length)].map(
                (projectElem: any, j: number): { elementTypeIndex: number, elementIndex: number } =>
                    ({ elementTypeIndex: i, elementIndex: j })
            )
        ).flat();

    const addElementTypeToEstimation = (elementType: any) => {
      const estimationCopy = JSON.parse(JSON.stringify(estimateState.detailedEstimate));

      let existingElemType = estimationCopy.elementtypes.find((elem: any) =>
        elem.id === elementType.id
      );

      if (existingElemType === undefined) {
        estimationCopy.elementtypes.push(elementType);
        estimateState.setDetailedEstimate(estimationCopy);
      } else {
        const elemDificultyExist: boolean = existingElemType.dificulties.some((dif: any) =>
            dif.size_id === elementType.dificulties[0].size_id &&
            dif.complexity_id === elementType.dificulties[0].complexity_id
        );
        !elemDificultyExist && existingElemType.dificulties.push(elementType.dificulties[0]);
        estimateState.setDetailedEstimate(estimationCopy);
      }
    }

    const createNewProjectElement = (elemInfo: any) => {
      let tiposElementosArr = [{
        id: elemInfo.idElementType,
        id_size: elemInfo.idSize,
        id_complexity: elemInfo.idComplexity}] as any;

      const datos = {
        "project_types": [parseInt(idProjectType)],
        "element_types": tiposElementosArr
      }


      //console.log("datos", datos);
      // @ts-ignore
      estimationsService.getAccumulatedEstimate(datos).then(({data: response}) => {
        if (response.status === "warning") {
          toast.error(t(response.code));
        } else {
          const elemType = response.projecttypes[0].elementtypes[0];
          addElementTypeToEstimation(elemType);
          projectPlanDispatch({
            type: 'addElement',
            newEstimatedElementType: elemType,
            newElementInfo: elemInfo,
            adjustedDuration: !statisticalRange
          });
          setCreateNewElement(false);
        }
      }).catch(function (error: any) {
          //console.log(error);
          toast.error("Ocurrió un problema al estimar el tipo de elemento (" + error + ")");
      });
    }

    const handleProjectElementDelete = (elementTypeIndex: number, projectElementIndex: number) => {
      projectPlanDispatch({
        type: 'deleteElement',
        planIndexes: { elementTypeIndex: elementTypeIndex, projectElementIndex: projectElementIndex },
        adjustedDuration: !statisticalRange
      });
    }

    const elementNameExists = (elementName: string, idElementType: number): boolean => {
      let elementTypeIndex = projectPlan.elementTypes.findIndex(
        (elemType: any) => elemType.idElementType === idElementType
      );

      if (elementTypeIndex !== -1) {
        for (let i = 0; i < projectPlan.elementTypes[elementTypeIndex].projectElements.length; i++) {
          let nombre = projectPlan.elementTypes[elementTypeIndex].projectElements[i].name;
          if (nombre.trim() === elementName.trim()) {
            return true;
          }
        }
      }

      return false;
    }

    const goToErrorPage = (elementTypeIndex: number, projectElementIndex: number) => {
        let elementIndex: number = elementsIndexes.findIndex(
            (elemIndexes: { elementTypeIndex: number, elementIndex: number }) =>
                elemIndexes.elementTypeIndex === elementTypeIndex
                && elemIndexes.elementIndex === projectElementIndex
        );

        elementIndex = elementIndex == -1 ? 0 : elementIndex;
        setActivePage(Math.ceil((elementIndex+1)/5));
    }

    const ceroValuesNotification = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return(
                    <TrueffortConfirmAlert
                        title={
                            <Typography color='#6A6A6A' variant='h6' fontWeight={700} marginBottom='20px' >
                                <InfoIcon sx={{ color: '#6A6A6A' }} />&nbsp;&nbsp;{t("Warning")}
                            </Typography>
                        }
                        alertColor='darkG'
                        message={ t("estimation_page.cero_estimates") }
                        buttons={[
                            {
                                label: t("Cancel"),
                                onClick: () => {
                                    savingState.setIsSaving(false);
                                    onClose();
                                }
                            },
                            {
                                label: t("accept"),
                                onClick: () => {
                                    onClose();
                                    submitProjectPlan();
                                }
                            }
                        ]}
                    />
                );
            }
        });
    }

    const handleProjectSubmitting = () => {
        if (!projectPlan.name) {
            warningMessage([t("estimation_page.name_required")]);
            savingState.setIsSaving(false);
            return;
        }

        const ceroValuesIndexes: number[] = searchForCeroValues(projectPlan, true);
        if (ceroValuesIndexes.length > 0) {
            goToErrorPage(ceroValuesIndexes[0], ceroValuesIndexes[1]);
            ceroValuesNotification();
        } else {
            submitProjectPlan();
        }
    }

    const handlePageChange = (event: any, value: number) => {
        setActivePage(value);
    }

    return (
        <Paper elevation={0} sx={{ padding: '12px', bgcolor: '#F5F5F5' }} >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: "flex-end",
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    flexDirection: matches ? 'column' : 'row'
                }}
            >
                <HelperDialog
                    title={t(helperDialogs["estimationDetail"][0].title)}
                    body={t(helperDialogs["estimationDetail"][0].body)}
                    dialogIndex={0}
                    totalDialogs={!projectPlan.elementTypes.length ?
                        helperDialogs["estimationDetail"].length - 2 :
                        helperDialogs["estimationDetail"].length
                    }
                    handleNext={detailHelperDialogsConfig.handleActiveDetailHelperChange}
                    isOpen={detailHelperDialogsConfig.activeDetailDialogIndex === 0}
                    handleClose={detailHelperDialogsConfig.handleDetailHelperClose}
                    position='right'
                >
                    <Typography variant="h6" color='#050536' >
                        {t("estimation_page.estimate_detail_title") + projectPlan.name}
                    </Typography>
                </HelperDialog>

                <HelperDialog
                    title={t(helperDialogs["estimationDetail"][6].title)}
                    body={!projectPlan.elementTypes.length ?
                        t(helperDialogs["estimationDetail"][6].body_alt === undefined ?
                            '' : helperDialogs["estimationDetail"][6].body_alt) :
                        t(helperDialogs["estimationDetail"][6].body)
                    }
                    dialogIndex={6}
                    totalDialogs={!projectPlan.elementTypes.length ?
                        helperDialogs["estimationDetail"].length - 2 :
                        helperDialogs["estimationDetail"].length
                    }
                    handleNext={detailHelperDialogsConfig.handleActiveDetailHelperChange}
                    isOpen={detailHelperDialogsConfig.activeDetailDialogIndex === 6}
                    handleClose={detailHelperDialogsConfig.handleDetailHelperClose}
                    position='left'
                >
                    <div>
                        <TrueffortButton
                            id="new-element-type-button"
                            size="small"
                            variant="contained"
                            color="secondary"
                            fullWidth={matches}
                            startIcon={<AddIcon />}
                            onClick={() => setCreateNewElement(true)}
                        >
                            {t("project_page.new_element_type")}
                        </TrueffortButton>
                    </div>
                </HelperDialog>
            </Box>

            <Card elevation={1} >
                <CardContent sx={{ overflowX: 'scroll' }} >
                    <HelperDialog
                        title={t(helperDialogs["estimationDetail"][5].title)}
                        body={t(helperDialogs["estimationDetail"][5].body)}
                        dialogIndex={5}
                        totalDialogs={!projectPlan.elementTypes.length ?
                            helperDialogs["estimationDetail"].length - 2 :
                            helperDialogs["estimationDetail"].length
                        }
                        handleNext={detailHelperDialogsConfig.handleActiveDetailHelperChange}
                        isOpen={detailHelperDialogsConfig.activeDetailDialogIndex === 5}
                        handleClose={detailHelperDialogsConfig.handleDetailHelperClose}
                        position='top'
                    >
                    <Box sx={{ minWidth: "1280px" }} >
                        <Paper variant="outlined" square sx={{ padding: '10px', backgroundColor: '#F5F5F5' }} >
                            <Grid container spacing={1} >
                                <Grid item xs={12} >
                                    <Stack direction="row" spacing={2} >
                                        <Typography sx={{ display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                                            <Dashboard fontSize="small" sx={{ marginRight: '6px' }} />
                                            {t("project_tasks_page.title") + ": " + projectPlan.name}
                                        </Typography>

                                        <Typography sx={{ display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                                            <AppRegistrationIcon fontSize="small" sx={{ marginRight: '6px' }} />
                                            {t("project_page.template") + ": " + projectTypeName}
                                        </Typography>

                                        {/*<Typography sx={{ display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                                                        <AssignmentIcon fontSize="small" sx={{ marginRight: '6px' }} />
                                                        {t("home.tasks") + ": " + totalTasks}
                                                    </Typography>*/}
                                    </Stack>
                                </Grid>

                                <Grid item xs={0.5} sx={{ textAlign: 'center' }} >
                                    <Typography variant='subtitle2' color='#4A4A4A' >
                                        Nº
                                    </Typography>
                                </Grid>
                                <Grid item xs={2.5} >
                                    <Typography variant='subtitle2' color='#4A4A4A' >
                                        {t("estimation_page.element")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} >
                                    <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: "center" }} color='#4A4A4A' >
                                        <EditIcon fontSize="small" sx={{ marginRight: '0.5em' }} />
                                        {t("project_page.change_name")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} >
                                    <Typography variant="subtitle2" textAlign='center' color='#4A4A4A' >
                                        {t("estimation_page.effort_man_hours")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} >
                                    <Typography variant="subtitle2" textAlign='center' color='#4A4A4A' >
                                        {t("estimation_page.days_duration")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Collapse in={createNewElement} timeout="auto" unmountOnExit >
                            <AddElementForm
                                elementTypes={elementTypes}
                                setCreateNewElement={setCreateNewElement}
                                saveElement={createNewProjectElement}
                                elementNameExists={elementNameExists}
                            />
                        </Collapse>

                        {elementsIndexes
                            .slice((activePage*5) - 5, activePage*5)
                            .map((indexes: {elementTypeIndex: number, elementIndex: number}, k: number) =>
                                k === 0 ?
                                    <CollapsibleElement
                                        key={
                                            projectPlan
                                                .elementTypes[indexes.elementTypeIndex]
                                                .projectElements[indexes.elementIndex]
                                                .artifitialKey
                                        }
                                        indexes={indexes}
                                        numElement={k+1}
                                        elements={{
                                            elementType: estimateState
                                                .detailedEstimate
                                                .elementtypes[indexes.elementTypeIndex],
                                            projectElement: projectPlan
                                                .elementTypes[indexes.elementTypeIndex]
                                                .projectElements[indexes.elementIndex]
                                        }}
                                        deleteElement={handleProjectElementDelete}
                                        setSaveDisabled={() => setSaveDisabled}
                                        detailHelperDialogsConfig={detailHelperDialogsConfig}
                                    /> :
                                    <CollapsibleElement
                                        key={
                                            projectPlan
                                                .elementTypes[indexes.elementTypeIndex]
                                                .projectElements[indexes.elementIndex]
                                                .artifitialKey
                                        }
                                        indexes={indexes}
                                        numElement={k+1}
                                        elements={{
                                            elementType: estimateState
                                                .detailedEstimate
                                                .elementtypes[indexes.elementTypeIndex],
                                            projectElement: projectPlan
                                                .elementTypes[indexes.elementTypeIndex]
                                                .projectElements[indexes.elementIndex]
                                        }}
                                        deleteElement={handleProjectElementDelete}
                                        setSaveDisabled={() => setSaveDisabled}
                                    />
                            )
                        }
                    </Box>
                    </HelperDialog>
                </CardContent>

                <CardContent >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: matches ? 'center' : "flex-end",
                            alignItems: "center",
                            marginTop: '1em'
                        }}
                    >
                        <Pagination
                            variant='outlined'
                            count={!elementsIndexes ? 0 : Math.ceil(elementsIndexes.length/5)}
                            page={activePage}
                            onChange={handlePageChange}
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                </CardContent>

                <CardActions sx={{ display: 'flex', justifyContent: matches ? 'center' : "flex-end", alignItems: "center" }} >
                    <Stack direction='row' spacing={2} >
                        <TrueffortButton
                            id='cancel-estimate-button'
                            variant='outlined'
                            disabled={savingState.isSaving}
                            onClick={handleProjectEstimationCancel}
                        >
                            {t("cancel")}
                        </TrueffortButton>

                        <TrueffortButton
                            id='save-estimate-button'
                            color='primary'
                            variant='contained'
                            disabled={saveDisabled || savingState.isSaving}
                            onClick={() => {
                                savingState.setIsSaving(true);
                                //submitProjectPlan();
                                handleProjectSubmitting();
                            }}
                        >
                            {t("estimation_page.save")}
                        </TrueffortButton>
                    </Stack>
                </CardActions>
            </Card>
        </Paper>
    );
}

function AddElementForm({ elementTypes, setCreateNewElement, saveElement, elementNameExists}: any) {

    const {t} = useTranslation();

    const [idSelectedElementType, setIdSelectedElementType] = React.useState("");
    const [elementName, setElementName] = React.useState("");
    const [idSelectedSize, setIdSelectedSize] = React.useState("");
    const [idSelectedComplexity, setIdSelectedComplexity] = React.useState("");
    const [required, setRequired] = React.useState(false);
    const [nameExists, setNameExists] = React.useState(false);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [tooltipMessage, setTooltipMessage]= React.useState("");

    const handleAddNewElement = () => {
        if (!elementName || !idSelectedElementType || !idSelectedElementType || !idSelectedComplexity) {
            setRequired(true);
            setTooltipMessage("Campos Requeridos");
            setShowTooltip(true);
            return;
        }

        if (elementNameExists(elementName, Number(idSelectedElementType))) {
            setTooltipMessage("Nombre para tipo de elemento ya existe");
            setNameExists(true);
            setShowTooltip(true);
            return;
        }

        required && setRequired(false);
        saveElement({
            idElementType: Number(idSelectedElementType),
            elementName: elementName,
            idSize: Number(idSelectedSize),
            idComplexity: Number(idSelectedComplexity)
        });
    }

    return (
        <Paper variant="outlined" square sx={{ padding: '10px' }} >
            <Grid container spacing={2} alignItems="center" >
                <Grid item xs={2.5} >
                    <TrueffortSelect
                        size='small'
                        fullWidth
                        displayEmpty
                        onChange={(event: any) => setIdSelectedElementType(event.target.value)}
                        value={idSelectedElementType}
                        labelstring={t("project_page.elem_type")}
                        error={!idSelectedElementType && required}
                    >
                        <MenuItem
                            value=""
                            disabled
                        >
                            {t("Select")}
                        </MenuItem>
                        {elementTypes.map((elementType: any) =>
                            <MenuItem
                                key={elementType.idElementType}
                                value={elementType.idElementType}
                            >
                                {elementType.name}
                            </MenuItem>
                        )}
                    </TrueffortSelect>
                </Grid>

                <Grid item xs={3} >
                    <TrueffortTextField
                        id="create-new-element"
                        name="new-element-name"
                        size="small"
                        fullWidth
                        required
                        //value={props.elements.projectElement.name}
                        onChange={(event: any) => setElementName(event.target.value)}
                        error={(elementName === "" && required) || nameExists}
                        placeholder={t("project_tasks_page.new_element") + "..."}
                        //helperText={!formikPhase.errors?.name || !formikPhase.touched?.name ? undefined : t(formikPhase.errors.name)}
                        labelstring={t("Name")}
                    />
                </Grid>
                <Grid item xs={2} >
                    <TrueffortSelect
                        id='base-elements-size'
                        size="small"
                        fullWidth
                        displayEmpty
                        value={idSelectedSize}
                        placeholder={t("estimation_page.element_size")}
                        onChange={(event: any) => setIdSelectedSize(event.target.value)}
                        labelstring={t("estimation_page.element_size")}
                        error={!idSelectedSize && required}
                    >
                        <MenuItem
                            value=""
                            disabled
                        >
                            {t("Select")}
                        </MenuItem>
                        {sizeOptions.map((opcion: {idSize: number, size: string}) =>
                            <MenuItem
                                key={opcion.idSize}
                                value={opcion.idSize}
                            >
                                {t(opcion.size)}
                            </MenuItem>
                        )}

                    </TrueffortSelect>
                </Grid>

                <Grid item xs={2} >
                    <TrueffortSelect
                        id='base-elements-complexity'
                        size="small"
                        fullWidth
                        displayEmpty
                        value={idSelectedComplexity}
                        placeholder={t("estimation_page.element_complexity")}
                        onChange={(event: any) => setIdSelectedComplexity(event.target.value)}
                        labelstring={t("estimation_page.element_complexity")}
                        error={!idSelectedComplexity && required}
                    >
                        <MenuItem
                            value=""
                            disabled
                        >
                            {t("Select")}
                        </MenuItem>

                        {complexityOptions.map((opcion: {idComplexity: number, complexity: string}) =>
                            <MenuItem
                                key={opcion.idComplexity}
                                value={opcion.idComplexity}
                                //disabled={opcion.disabled}
                            >
                                {t(opcion.complexity)}
                            </MenuItem>
                        )}
                    </TrueffortSelect>
                </Grid>

                <Grid item xs container justifyContent="center" >
                    <Stack direction="row" spacing={1} >
                        <Tooltip open={showTooltip} title={tooltipMessage} >
                            <Chip
                                size="small"
                                icon={<CheckIcon fontSize="small" />}
                                label={t("project_page.new_element_type")}
                                variant="outlined"
                                color="success"
                                onClick={handleAddNewElement}
                            />
                        </Tooltip>


                        <Chip
                            size="small"
                            icon={<CancelIcon fontSize="small" />}
                            label={t("Cancel")}
                            variant="outlined"
                            color='error'
                            onClick={() => setCreateNewElement(false)}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    );
}