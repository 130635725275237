import React from "react";
//import {Button, PricingCard} from "tabler-react";
import '../../css/PricingCardET.css';
import building from '../../assets/images/building.png';
import unlimited from '../../assets/images/unlimited.png';
import individual from '../../assets/images/unlimited.png';
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../css/Login.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NUMBER_TRIAL_DAYS} from "../../util/Constants";
import {Avatar, Box, Divider, Paper, Typography} from "@mui/material";
import {TrueffortButton} from "../../MUIstyle/MUICustom";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const itemsInfo = (cardType: string) => {
    const available = {available: true, hasIcon: true};
    const nonAvailable = {available: false, hasIcon: true};
    switch (cardType) {
        case "free":
            return [
                {...available, text: "pricing.free_item_1"},
                {...available, text: "pricing.free_item_2"},
                {...available, text: "pricing.free_item_3"},
                {...available, text: "pricing.free_item_4"},
                {...available, text: "pricing.free_item_5"},
                // {available: true, hasIcon: true, text: "Sin costo"}
                // {...nonAvailable, text: "Tiempo limitado (15 dias)"}
            ];
        case "perMonth":
            return [
                {...available, text: "Todas las funcionalidades disponibles"},
                {available: true, hasIcon: true, text: "Facturacion Mensual"}
            ];
        case "company":
            return [
                {...available, text: "pricing.company_item_1"},
                {...available, text: "pricing.company_item_2"},
                {...available, text: "pricing.company_item_3"},
                {...available, text: "pricing.company_item_4"},
                {...available, text: "pricing.company_item_5"},
                {...available, text: "pricing.company_item_6"},
                {...available, text: "pricing.company_item_7"},
                {...available, text: "pricing.company_item_8"}
                // {...available, text: "Tiempo ilimitado"},
                // {available: true, hasIcon: false, text: "Contactenos para solicitar una cotizacion"}
            ];
        default:
            return [
                {...available, text: "Todas las funcionalidades disponibles"},
                {...nonAvailable, text: "Tiempo ilimitado"}
            ];
    }
}

const cardButton = (cardType: string) => {
    switch (cardType) {
        case "free":
            return "Get";
        case "perMonth":
            return "Buy";
        case "company":
            return "Contact";
        default: return "Get";
    }
    return "Contact"
};

const openInNewTab = (url: string | undefined) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const cardButtonAction = (cardType: string, history: any) => {
    switch (cardType) {
        case "company":
            return () => openInNewTab("https://trueffort.com/#contactanos");
        default:
            return () => history.push({pathname: "/register", state: {cardType}});
    }
};

const cardTitle = (cardType: string) => {
    switch (cardType) {
        case "free":
            return "pricing.Individual";
        case "perMonth":
            return "Mensual";
        case "company":
            return "pricing.Corporate";
    }
    return "Individual";
};

const cardPrice = (cardType: any) => {
    switch (cardType) {
        case "free":
            return "$0";
        case "perMonth":
            return "$160/usuario";
        case "company":
            return "Corporativo";
    }
};

const cardIcon = (cardType: any) => {
    switch (cardType) {
        case "free":
            return individual;
        case "perMonth":
            return unlimited;
        case "company":
            return building;
    }
};


const PricingCardTRUE = ({cardType, history}: { cardType: string, history: any }) => {
    const {t} = useTranslation();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Paper sx={{ width: !matches ? '518px' : '320px', padding: '19px' }} >
            <div style={{ height: 0 }} >
                <hr style={{ visibility: "hidden", marginTop: 0, marginBottom: '30px' }} />
                <Divider color='#EFEFEF' sx={{ borderBottomWidth: 2, marginTop: '30px', marginBottom: '28px' }} />
            </div>

            <Box sx={{ height: '60px', display: 'flex', alignItems: "flex-start", justifyContent: 'space-between' }}>
                <Avatar sx={{ bgcolor: '#EFEFEF', width: '60px', height: '60px' }} >
                    <FontAwesomeIcon icon={"users"} size="lg" color='#07074E' />
                </Avatar>
                <Typography color='#6A6A6A' variant='subtitle2' >
                    {t("register.welcome")}
                </Typography>
            </Box>

            <Box sx={{ marginTop: '2em', marginBottom: '2em', marginRight: '0.5em', marginLeft: '0.5em' }} >
                <Typography variant="h4" color='#4A4A4A' gutterBottom >
                    {t(cardTitle(cardType))}
                </Typography>

                <Typography color='#6A6A6A' gutterBottom >
                    {t("register.company_description")}
                </Typography>

                <ul style={{ marginTop: '1em', marginBottom: '2em', marginRight: 0, marginLeft:-20 }}>
                    {itemsInfo(cardType)
                        .filter((item) => item.available)
                        .map((item) =>
                            <li style={{ color: '#6A6A6A', marginBottom: '0.75em'}}>
                                <Typography >
                                    {t(item.text)}
                                </Typography>
                            </li>
                        )
                    }
                </ul>

                {cardType === "company" &&
                    <TrueffortButton
                        color={"primary"}
                        variant="contained"
                        fullWidth={matches}
                        onClick={cardButtonAction("free", history)}
                    >
                        {t("pricing.start_trial").replace("[0]", NUMBER_TRIAL_DAYS+"")}
                    </TrueffortButton>
                }
            </Box>
        </Paper>
    );

    /*return (
        <div className={cardType !== "company" ? "head-pricing-first" : "head-pricing-second"}>
            <PricingCard
                // ={cardType === "perMonth"}
            >
                <h1 className={cardType !== "company" ? "text-secondary" : "text-primary"}>
                    {t(cardTitle(cardType))}</h1>
                {/!*   cardType !== "company" ? <PricingCard.Price>{cardPrice(cardType)} </PricingCard.Price> :
                    <div style={{height: 28}}/>*!/
                }
                <FontAwesomeIcon icon={cardType !== "company" ? "user" : "users"}
                                 size={'10x'}
                                 className={cardType !== "company" ? "color-secondary height-icon" : "color-primary height-icon"}/>
                {/!*<img style={{margin: 20, opacity: 0.8}} height={cardType !== "company" ? 80 : 130} src={cardIcon(cardType)}
                     alt={cardType}/>*!/}
                {
                    <Button pill color={cardType !== "company" ? "secondary" : "primary"} block={true}
                            onClick={cardButtonAction(cardType, history)}
                            className="price-buttons">
                        {t(cardButton(cardType))}
                    </Button>
                    /!*
                    <PricingCard.Button active
                                        onClick={cardButtonAction(cardType, history)}
                                        className="color-secondary"
                    > {t(cardButton(cardType))} </PricingCard.Button>
                    *!/
                }
                <div id={"attribute-list"}>
                    <PricingCard.AttributeList className={"mt-3"}>
                        {
                            itemsInfo(cardType).map(((item) =>
                                <PricingCard.AttributeItem available={item.available}>
                                    {t(item.text)}
                                </PricingCard.AttributeItem>)
                            )
                        }
                        {cardType === "company" &&
                        <PricingCard.AttributeItem available>
                            <Button pill color={"primary"}
                                    onClick={cardButtonAction("free", history)}>{t("pricing.start_trial").replace("[0]", NUMBER_TRIAL_DAYS+"")}</Button>
                        </PricingCard.AttributeItem>
                        }
                    </PricingCard.AttributeList>
                </div>
                <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                    <div style={{bottom: 0, width: "80%", fontWeight: "bold", paddingTop:"10px"}}>
                        <p className={cardType !== "company" ? "color-secondary" : "color-primary"}> {cardType === "company" ? t("pricing.footer_corporate") : t("pricing.footer_individual")} </p>
                    </div>
                </div>
            </PricingCard>
        </div>)*/
}

export default withRouter(PricingCardTRUE);