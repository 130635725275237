// @flow

import * as React from "react";
//import {StandaloneFormPage, FormCard, FormTextInput, withTouchedErrors, Alert, Button} from "tabler-react";
import {useFormik} from "formik";
import {useLocation} from "react-router-dom";
import UserService from "../services/user.service";
import CardWrapperFormTRUE from '../components/pricing-login-register/CardWrapperFormTRUE'
import {AxiosResponse} from "axios";
import {DEFAULT_REQUEST_ERROR} from "../util/Constants";
import StandaloneFormPageTRUE from "../components/StandaloneFormPageTRUE"
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IconButton, InputAdornment, InputLabel, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";


type fieldTypes = {
    password?: string,
    confirmedPassword?: string,
    active?: boolean,
    email?: string,
};

type touchedTypes = {
    password?: string,
    confirmedPassword?: string,
};


const validate = (values: any) => {
    let errors: any = {};
    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = "Required";
    }
    if (!values.confirmedPassword) {
        errors.confirmedPassword = "Required";
    } else if (values.password !== values.confirmedPassword) {
        errors.password = "forms.passwordDontMatchValidation";
        errors.confirmedPassword = "forms.passwordDontMatchValidation";
    }
    return errors;
};

/**
 * A forgot password page
 * Can be easily wrapped with form libraries like formik and redux-form
 */
function ChangePasswordPage(): any {

    //const [alertState, setAlertState] = React.useState({visible: false, text: "", error: false});
    const location = useLocation();
    const {t} = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false)
    const userToken = React.useMemo(() => new URLSearchParams(location.search).get("token"), [location])

    const userService = React.useMemo(() => new UserService(), [])

    const handleSubmit = async (values: fieldTypes, setValues: Function) => {
        const dataRequest = {email: values.email, token: userToken, newPassword: values.confirmedPassword};
        let errorMessage = t(DEFAULT_REQUEST_ERROR);
        let sent = false;
        try {
            const {data: response}: AxiosResponse = await userService.forgotPasswordConfirm(dataRequest);
            if (response.success && response.confirmed === "true") {
                setValues({...values, active: false});
                //setAlertState({visible: true, text: t("changePassword.successMessage"), error: false})
                toast.success(t("changePassword.successMessage"));
                sent = true;
                return;
            }
            if (response?.internalError) {
                errorMessage = response.message;
            }
        } catch (error) {
            console.log("Error #ChangePassword " + error);
        } finally {
            if (!sent) {
                setValues({...values, active: false});
                //setAlertState({visible: true, text: errorMessage, error: true});
                toast.error(errorMessage);
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmedPassword: '',
            active: false
        },
        validate: validate,
        onSubmit: (values, {setSubmitting, setValues}) => {
            handleSubmit(values, setValues).then(() => {
                setSubmitting(false);
            })
        },
    });

    React.useEffect(() => {
            const verifyLink = async () => {
                let send = false;
                let errorMessage = t(DEFAULT_REQUEST_ERROR);
                try {
                    if (userToken) {
                        errorMessage = t("changePassword.wrongLink");
                        const {data: response}: AxiosResponse = await userService.forgotPasswordVerify(userToken);
                        if (response.success && response.username) {
                            await formik.setValues({...formik.values, active: true, email: response.username});
                            send = true
                            return;
                        }
                        if (response?.internalError) {
                            errorMessage = response.message;
                        }
                        await formik.setValues({...formik.values, active: false});
                        //setAlertState({visible: true, text: errorMessage, error: true});
                        toast.error(errorMessage);
                    }
                } catch (error: any) {
                    console.log("Error #ChangePassword2 " + error);
                } finally {
                    if (!send) {
                        await formik.setValues({...formik.values, active: false});
                        //setAlertState({visible: true, text: errorMessage, error: true});
                        toast.error(errorMessage);
                    }
                }
            }
            verifyLink()
        }, [ t, userService, userToken]
    )

    return (
        <StandaloneFormPageTRUE imageURL={"./assets/images/new_logo.png"}>
            <CardWrapperFormTRUE
                buttonText={t("changePassword.title")}
                title={t("changePassword.title")}
                onSubmit={formik.handleSubmit}
                isSubmitting={formik.isSubmitting}
                showSubmitButton={formik.values.active}
            >
                {/*alertState.visible ?
                    <Alert type={alertState.error ? "danger" : "primary"}>
                        {/*<Header.H4>Some Message</Header.H4>*/}{/*
                        <label>
                            {alertState.text}
                        </label>
                    </Alert> : <p className="text-muted">
                        {t("changePassword.enter_email_message")}
                    </p>*/}
                {/*<FormTextInput
                    name="password"
                    disabled={formik.isSubmitting || !formik.values.active}
                    label={t("forms.newPasswordLabel")}
                    type="password"
                    placeholder={t("forms.newPasswordPlaceholder")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values && formik.values.password}
                    error={formik.errors && formik.touched?.password && formik.errors.password ? t(formik.errors.password) : null}
                />
                <FormTextInput
                    name="confirmedPassword"
                    type="password"
                    label={t("forms.confirmPassword")}
                    disabled={formik.isSubmitting || !formik.values.active}
                    placeholder={t("forms.newPasswordConfirmPlaceholder")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values && formik.values.confirmedPassword}
                    error={formik.errors && formik.touched?.confirmedPassword && formik.errors.confirmedPassword ? t(formik.errors.confirmedPassword) : null}
                />*/}

                <InputLabel sx={{ color: '#050536' }} >{t("forms.newPasswordLabel")}</InputLabel>
                <TextField
                    id="newPassword"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                sx={{
                                    backgroundColor: '#F5F5F5'
                                }}
                            >
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size='small'
                                >
                                    {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    size="small"
                    margin="none"
                    fullWidth
                    placeholder={ t("forms.newPasswordPlaceholder") }
                    onChange={ formik.handleChange }
                    onBlur={ formik.handleBlur }
                    value={formik.values && formik.values.password}
                    error={Boolean(formik.errors && formik.touched?.password && formik.errors.password)}
                    disabled={formik.isSubmitting || !formik.values.active}
                    helperText={!formik.touched?.password || !formik.errors?.password ? " " : t(formik.errors.password)}
                />

                <InputLabel sx={{ color: '#050536' }} >{t("forms.confirmPassword")}</InputLabel>
                <TextField
                    id="confirmed-new-password"
                    name="confirmedPassword"
                    type={showPasswordConfirm ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                    edge="end"
                                    size='small'
                                >
                                    {showPasswordConfirm ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    size="small"
                    margin="none"
                    fullWidth
                    placeholder={ t("forms.newPasswordConfirmPlaceholder") }
                    onChange={ formik.handleChange }
                    onBlur={ formik.handleBlur }
                    value={formik.values && formik.values.confirmedPassword}
                    error={Boolean(formik.errors && formik.touched?.confirmedPassword && formik.errors.confirmedPassword)}
                    disabled={formik.isSubmitting || !formik.values.active}
                    helperText={!formik.touched?.confirmedPassword || !formik.errors?.confirmedPassword ? " " : t(formik.errors.confirmedPassword)}
                />
            </CardWrapperFormTRUE>
            <ToastContainer theme="colored" />
        </StandaloneFormPageTRUE>
    );
}

export default ChangePasswordPage;
