import React from "react";
import { useTranslation } from "react-i18next";
import {
    Card, Chip, Table, TableContainer, TableHead, FormControlLabel, 
    Checkbox, Paper, Box, TablePagination, Stack, CircularProgress
} from '@mui/material';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from "formik";
import Select from "react-select";
import "../css/Schedule.css";
import ScheduleService from "../services/schedule.service";
import UserService from "../services/user.service";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { NUMBER_ITEMS_BY_PAGE } from "../util/Constants";
import { Add, Delete, Edit, ArrowRight, ArrowDropDown, Save, Clear } from "@mui/icons-material";
import { TrueffortSearchInput, TrueffortButton, TrueffortCardTable, TrueffortTableHeadCell, 
  TrueffortTextField, TrueffortInputLabel, TrueffortButtonSmall, StyledTableRow, StyledTableCell 
} from "../MUIstyle/MUICustom";
import { TimeField } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { styleReactSelect } from "../MUIstyle/GlobalStyles";
import CardEmpty from "../components/custom/CardEmpty";
import CustomAvatar from "../components/custom/CustomAvatar";
import { useAuthContext } from "../contexts/AuthenticationContext";

function SchedulesPage() {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [valuesUsersSelect, setValuesUsersSelect] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [schedulesAll, setSchedulesAll] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);
  const days = [0, 1, 2, 3, 4, 5, 6, 7];
  const userService = React.useMemo(() => new UserService(), []);
  const scheduleService = React.useMemo(() => new ScheduleService(), []);
  const [hideRowList, setHideRowList] = React.useState([]);
  const [state, setState] = React.useState({ isCreating: false, isEditing: false, showFormAdd: false, openDetailDay: false });
  const [daysValues, setDaysValues] = React.useState([]);
  const [selectButtonsWeek, setSelectButtonsWeek] = React.useState({buttonWorkWeek: false, buttonCompleteWeek: false});
  let dayClean = { breakDuration: null,
      end: null, existDay: false, hasBreakTime: false,
      hourEnd: null, hourStart: null,
      hourStartBreak: null, minEnd: null,
      minStart: null, minStartBreak: null,
      obligatoryHours: null, start: null,
      startBreak: null
  }
  const [rowsPerPageSchedules, setRowsPerPageSchedules] = React.useState(NUMBER_ITEMS_BY_PAGE);
  const [schedulesPage, setSchedulesPage] = React.useState(0);
  const [schedulesCount, setSchedulesCount] = React.useState(0);
  const [showLoader, setShowLoader] = React.useState(false);

  React.useEffect(() => {
      setShowLoader(true);
      fillSchedules();
      fillUsers();
  }, []);

  const fillSchedules = () => {
    // @ts-ignore
    scheduleService.getAll().then(({data: response}) => {
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          setHideRowList(Array(response.data.length).fill(true));
        }
        setSchedulesAll(response.data);
        setSchedules(response.data);
        setSchedulesCount(response.data.length);
      }
    }).catch(({data:response}) => {
      console.error(response);
    }).finally(() => setShowLoader(false));
  }

  const fillUsers = () => {
    // @ts-ignore
    userService.getAll().then(({data: response}) => {
      if (Array.isArray(response)) {
        response.unshift({idUser: 0, name: t("schedule_page.select_all"), email: t("schedule_page.select_all")});
        setUsers(response);
      }
    });
  }

  const handleChangeSearchInput = (searchFilter) => {
    let items = schedulesAll.filter((data) => {
      if (searchFilter == "") {
        return schedulesAll;
      } else if (data.name.toLowerCase().includes(searchFilter.toLowerCase())) {
        return data;
      }
    });
    setSchedules(items);
    if (items?.data?.length > 0) {
      setHideRowList(Array(items.data.length).fill(true));
    }
  }

  function getArrUsersFromValues() {
    let arr = [];
    valuesUsersSelect.map((usr, k) => {
      arr.push({ idProject: 0, idUser: usr.value });
    });
    return arr;
  }

  /**
   * Genera el json adecuado para enviar al servicio y guardar los registros ScheduleDay
   */
  function getArrDaysFromPeriods() {
    let arr = [];
    //console.log(daysValues);
    daysValues.filter(d => d.existDay).map((d, k) => {
      if (d.idDay != 0) {
        let endBreak = null;
        if (d.breakDuration != null && d.startBreak != null) {
          let date = new Date();
          let startBreakString = d.startBreak.split(':');
          let breakDurationString = d.breakDuration.split(':');
          date.setHours(Number(startBreakString[0]) + Number(breakDurationString[0]));
          date.setMinutes(Number(startBreakString[1]) + Number(breakDurationString[1]));
          endBreak = date.getHours() + ':' + date.getMinutes();
        }
        arr.push({
          dayOfWeek: d.idDay,
          start: d.start,
          end: d.end,
          isBreakTime: false,
          obligatoryHours: d.obligatoryHours,
          hasBreakTime: d.hasBreakTime,
          startBreak: d.startBreak,
          breakDuration: d.breakDuration,
          endBreak
        });
      }
    });
    return arr;
  }

  /**
   * Obtiene, de los usuarios que se van a agregar, los que ya existen en otros horarios.
   * (Un usuario sólo puede pertenecer a un horario)
   */
  function getRepeatedUsers() {
    let arrResult = [];
    let arrUsers = getArrUsersFromValues();
    schedules.map((schedule, k) => {
      schedule.users.map((su, j) => {
        arrUsers.map((u, n) => {
          if (u.idUser == su.idUser) {
            arrResult.push(su.user);
          }
        });
      })
    });
    return arrResult;
  }

  const handleSubmit = async (values) => {
    let dataRequest = {
      name: values.name,
      users: getArrUsersFromValues(),
      days: getArrDaysFromPeriods()
    };

    if (dataRequest.days.length == 0) {
      toast.error(t("schedule_page.msg_add_without_hours"));
      return;
    }

    // @ts-ignore
    scheduleService.create(dataRequest).then(({ data: response }) => {
      if (response.internalError != null && response.internalError && response.message != null) {
        toast.error(response.message);
      } else {
        setState({ ...state, showFormAdd: false, isCreating: false });
        toast.success(t("schedule_page.msg_success_add"));
      }
      fillSchedules();
    });
  }

  const handleEdit = async (values) => {
    let dataRequest = {
      idSchedule: values.idSchedule,
      name: values.name,
      users: getArrUsersFromValues(),
      days: getArrDaysFromPeriods()
    };

    if (dataRequest.days.length == 0) {
      toast.error(t("schedule_page.msg_add_without_hours"));
      return;
    }

    // @ts-ignore
    scheduleService.update(values.idSchedule, dataRequest).then(({ data: response }) => {
      if (response.internalError != null && response.internalError && response.message != null) {
        toast.error(response.message);
      } else {
        setState({ ...state, showFormAdd: false, isCreating: false });
        toast.success(t("schedule_page.msg_success_update"));
      }
      fillSchedules();
      setState({ ...state, isCreating: false, isEditing: false, showFormAdd: false });
    });
  }

  const validate = async (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = t('Required');
    } else if (values.name.length > 25) {
      errors.name = t('forms.nameLengthValidation25');
    }
    for (let i = 0; i < daysValues.length; i++) {
      if (daysValues[i].existDay) {
        if (daysValues[i].start == null || daysValues[i].end == null) {
          errors.days = t("schedule_page.msg_error_days_1").replaceAll("{0}", t("days."+daysValues[i].idDay));
          break;
        }

        if (daysValues[i].hasBreakTime && daysValues[i].breakDuration == null) {
          errors.days = t("schedule_page.msg_error_days_2").replaceAll("{0}", t("days."+daysValues[i].idDay));
          break;
        }
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      users: [],
      days: []
    },
    validate: validate,
    onSubmit: (values, { setSubmitting, setValues }) => {
      //console.log(values);
      let repeatedUsers = getRepeatedUsers();
      if (repeatedUsers.length > 0) {
        let str = "";
        let sep = "";
        repeatedUsers.map((u, k) => {
          str += sep + (u.isEnabled ? u.name + " " + u.lastName : u.email);
          sep = ", ";
        });
        confirmAlert({
          title: t("schedule_page.msg_repeted_users1"),
          message: t("schedule_page.msg_repeted_users2") + str + t("schedule_page.msg_repeted_users3"),
          buttons: [
            { label: t("accept"),
            onClick: () => {
              if (!state.isEditing) {
                  handleSubmit(values);
              } else {
                        handleEdit(values);
                        setState({ ...state, isEditing: false });
                    }
                }
            },
            {
              label: t("cancel"),
              onClick: () => { return; }
            }
          ]
        });
      } else {
        if (!state.isEditing) {
          handleSubmit(values);
        } else {
          handleEdit(values);
          setState({ ...state, isEditing: false });
        }
      }
    }
  });

  const getFormatNameExtendForUser = (user) => {
    return (user.name != null ? user.name : "") + " "
      + (user.lastName != null ? user.lastName : "")
      + " (" + user.email + ")";
  }

  const handleCancel = (ind) => {
    formik.resetForm();
    if (ind != -1) {
      let sAux = schedules.map(s1 => s1);
      sAux[ind].isEditing = false;
      setSchedules(sAux);
    }
    setState({ ...state, isCreating: false, isEditing: false, showFormAdd: false });
    setDaysValues([]);
  };

  const constructDataForDaysValues = (daysRecords) => {
    let data = [];
    days.forEach(day => {
      let dayObj = daysRecords.find(d => d.dayOfWeek == day);
      let existDay = dayObj != undefined;
      let dayDescription = t("days."+day);
      data.push({idDay: day, existDay, dayDescription, start: existDay ? dayObj.start : null,
        end: existDay ? dayObj.end : null, obligatoryHours: existDay ? dayObj.obligatoryHours : null,
        hasBreakTime: existDay ? dayObj.hasBreakTime : false, 
        startBreak: existDay && dayObj.hasBreakTime ? dayObj.startBreak : null,
        breakDuration: existDay && dayObj.hasBreakTime ? dayObj.breakDuration : null
      });
    });
    return data;
  }

  const prepareEdit = (obj) => {
    obj["isEditing"] = true;
    formik.setValues(obj);
    setDaysValues(constructDataForDaysValues(obj.days));
    setState({ ...state, isEditing: true });

    let arr = [];
    if (Array.isArray(obj.users)) {
      arr = obj.users.map(usr => ({value: usr.idUser, label: getFormatNameExtendForUser(usr.user)}));
    }
    setValuesUsersSelect(arr);
  }

  const deleteSchedule = (sch) => {
    confirmAlert({
      title: t("schedule_page.msg_delete_confirm1"),
      message: t("schedule_page.msg_delete_confirm2") + "" + sch.name + "?",
      buttons: [
        { label: t("accept"),
          onClick: () => {
            try {
              // @ts-ignore
              scheduleService.delete(sch.idSchedule).then(({ data: response }) => {
                toast.success(t("schedule_page.msg_success_delete"));
                fillSchedules();
              }).catch(function (error) {
                toast.error("Ocurrió un error al eliminar horario.");
              });
            } catch (error) {
              toast.error(error.message);
            }
          }
        }, {
          label: t("cancel"),
          onClick: () => { return; }
        }
      ]
    });
  }

  const handleOnChangeUsers = (option) => {
    //@ts-ignore
    let index = option.findIndex(x => x.value === 0);
    if (index !== -1) {
      option = [];
      for (let j = 0; j < users.length; j++) {
        if (users[j].idUser !== 0) {
          option.push({
              value: users[j].idUser, label: (users[j].name != null ? users[j].name : "") + " "
                  + (users[j].lastName != null ? users[j].lastName : "")
                  + (users[j].email != null ? " (" + users[j].email + ")" : "")
          });
        }
      }
    }
    setValuesUsersSelect(option);
  }

  const getScheduleDescription = (s) => {
    let auxS = s.days.map(s_ => s_);
    let cadena = '';
    days.forEach(day => {
      let start = null;
      let end = null;
      s.days.forEach((d) => {
        if (auxS.find(x => x.idScheduleDay == d.idScheduleDay) != null) {
          if (day == d.dayOfWeek && !d.isBreakTime) {
            if (start == null && end == null) {
              start = d.start;
              end = d.end;
              auxS.splice(auxS.findIndex(x => x.idScheduleDay == d.idScheduleDay), 1);
              cadena += t("days_reduced." + d.dayOfWeek);
            }
          } else if (d.start == start && d.end == end) {
            auxS.splice(auxS.findIndex(x => x.idScheduleDay == d.idScheduleDay), 1);
            cadena += ', ' + t("days_reduced." + d.dayOfWeek);
          }
        }
      });
      if (start != null && end != null) {
        let startA = start.split(':');
        let numberStart = Number(startA[0]);
        let endA = end.split(':');
        let numberEnd = Number([endA[0]]);
        cadena += ' ' + (numberStart < 12 ? start + ' am' : (numberStart - 12) + ':' + startA[1] + ' pm') + ' a ' + (numberEnd < 12 ? end + ' am' : (numberEnd - 12) + ':' + endA[1] + ' pm') + ' | ';
      }
    });

    return (<div style={{ fontWeight: 'bold', fontSize: '12px' }}>{cadena.length > 0 ? cadena.substring(0, cadena.length - 3) : cadena}</div>);
  }

  const getScheduleDescriptionDetail = (s) => {
    let data = [];
    for (let i = 1; i < days.length; i++) {
      let day = days[i];
      let dayDescription = t("days_reduced." + day);
      let obligatoryHours = null;
      let hasDay = true;
      let workingHours = '';
      let breakDuration = '';
      let d = s.days.find(d => d.dayOfWeek == day);
      if (d == null) { 
        hasDay = false;
        obligatoryHours = 0; 
      } else {
        let d1 = new Date();
        let s_ = d.start.split(':');
        d1.setHours(Number(s_[0]));
        d1.setMinutes(Number(s_[1]));
        d1.setSeconds(0);
        let d2 = new Date();
        let s2_ = d.end.split(':');
        d2.setHours(Number(s2_[0]));
        d2.setMinutes(Number(s2_[1]));
        d2.setSeconds(0);

        if (d.obligatoryHours != null) {
          obligatoryHours = d.obligatoryHours;
        } else {
          let milis = d2.getTime() - d1.getTime();
          let h = Math.floor(milis/3600000);
          let m = Math.floor((milis - (h*3600000)) / 60000);
          obligatoryHours = h + (m != 0 ? ':' + m : '');
        }
        workingHours = d.start + ' ' + (d1.getHours() <= 12 ? 'AM' : 'PM') + ' - ' + d.end + ' ' + (d2.getHours() <= 12 ? 'AM' : 'PM');
        if (d.hasBreakTime) {
          let durationString = d.breakDuration.split(':');
          let hours = Number(durationString[0]);
          breakDuration = ((hours > 0) ? d.breakDuration +' hr' : durationString[1] + ' m') + ' de descanso. ' + 
              (d.startBreak ? d.startBreak + ' ' + (Number(d.startBreak.split(':')[0]) <= 12 ? 'AM' : 'PM') : '');
        }
      }
      obligatoryHours += 'hrs.';
      data.push({hasDay, dayDescription, obligatoryHours, workingHours, breakDuration })
    }

    return (<div>
      {data.map(d => <div style={{display: 'flex', fontSize: '12px'}}>
        <div style={{width: '12%', fontWeight: 'bold', paddingLeft: '1em', color: !d.hasDay ? '#989898':''}}>{d.dayDescription}:</div>
        <div style={{width: '15%', color: !d.hasDay ? '#989898':''}}>{d.obligatoryHours}</div>
        <div style={{width: '35%', paddingLeft: '1em'}}>{d.workingHours}</div>
        <div style={{width: '38%'}}>{d.breakDuration}</div>
      </div>)}
    </div>)
  }

  const setValueForDataTableDays = (ind, fields, values) => {
    let daysAux = daysValues.map(d1 => d1);
    fields.forEach((f, i) => {
      daysAux[ind][f] = values[i];
    });
    setDaysValues(daysAux);
  }

  const configureSchedule = () => {
    let daysAux = daysValues.map(d => d);
    for (let i = 1; i < daysAux.length; i++) {
      daysAux[i].breakDuration = null;
      daysAux[i].end = null;
      daysAux[i].existDay = false;
      daysAux[i].hasBreakTime = false;
      daysAux[i].hourEnd = null;
      daysAux[i].hourStart = null;
      daysAux[i].hourStartBreak = null;
      daysAux[i].minEnd = null;
      daysAux[i].minStart = null;
      daysAux[i].minStartBreak = null;
      daysAux[i].obligatoryHours = null;
      daysAux[i].start = null;
      daysAux[i].startBreak = null;
    }
    for (let i = 1; i < (selectButtonsWeek.buttonWorkWeek ? daysAux.length - 2 : daysAux.length); i++) {
      daysAux[i].breakDuration = daysAux[0].breakDuration;
      daysAux[i].end = daysAux[0].end;
      daysAux[i].existDay = daysAux[0].existDay;
      daysAux[i].hasBreakTime = daysAux[0].hasBreakTime;
      daysAux[i].hourEnd = daysAux[0].hourEnd;
      daysAux[i].hourStart = daysAux[0].hourStart;
      daysAux[i].hourStartBreak = daysAux[0].hourStartBreak;
      daysAux[i].minEnd = daysAux[0].minEnd;
      daysAux[i].minStart = daysAux[0].minStart;
      daysAux[i].minStartBreak = daysAux[0].minStartBreak;
      daysAux[i].obligatoryHours = daysAux[0].obligatoryHours;
      daysAux[i].start = daysAux[0].start;
      daysAux[i].startBreak = daysAux[0].startBreak;
    }
    setDaysValues(daysAux);
    cancelConfigureSchedule();
  }

  const cancelConfigureSchedule = () => {
    let daysAux = daysValues.map(d => d);
    daysAux[0] = {dayDescription: daysAux[0].dayDescription, ...dayClean};
    setDaysValues(daysAux);
    setSelectButtonsWeek({buttonWorkWeek: false, buttonCompleteWeek: false})
  }

  const getTableEditDays = () => {
    return (daysValues.map((d, ind) => (ind == 0 && (selectButtonsWeek.buttonWorkWeek || selectButtonsWeek.buttonCompleteWeek)) || ind > 0 ? 
      <div style={{flexDirection: "column", borderBottom: '0.75px solid var(--sand-300, #BDBDBD)'}}>
        <div style={{display: 'flex', flexDirection: 'row', fontSize: '0.8em',
          paddingTop: '0.5em', alignItems:'center'}}>
          <div style={{width: '12%', paddingRight:'0.2em'}}>
            <FormControlLabel control={
              <Checkbox checked={d.existDay}
                onChange={(event) => setValueForDataTableDays(ind, ['existDay'], [event.target.checked])}
              />} 
              label={<div style={{fontSize:'3em !important'}}
              >{ind > 0 ? d.dayDescription : selectButtonsWeek.buttonWorkWeek ? t("schedule_page.workweek") : t("schedule_page.complete_week") }</div>}
            />
          </div>
          <div style={{width: '13%', display:'flex', flexDirection: 'row', paddingRight:'0.2em'}}>
            <p style={{paddingRight: '0.2em'}}>{t("schedule_page.entrance") + ':'}</p>
            <div>
              <TimeField
                disabled={!d.existDay}
                value={d.existDay && d.start != null ? 
                  dayjs('2023-01-01T' + d.start) : null}
                onChange={val => setValueForDataTableDays(ind, ['start', 'hourStart', 'minStart'], [(val.$H < 10 ? '0' : '') + val.$H + ':'
                  + (val.$m < 10 ? '0' : '') + val.$m, val.$H, val.$m])}
              />
            </div>
          </div>
          <div style={{width: '13%', display:'flex', flexDirection: 'row', paddingRight:'0.2em'}}>
            <p style={{paddingRight: '0.2em'}}>{t("schedule_page.exit") + ":"}</p>
            <TimeField 
              disabled={!d.existDay}
              value={d.existDay && d.end != null ? dayjs('2023-01-01T' + d.end) : null}
              onChange={val => setValueForDataTableDays(ind, ['end', 'hourEnd', 'minEnd'], [(val.$H < 10 ? '0' : '') + val.$H + ':'
                + (val.$m < 10 ? '0' : '') + val.$m, val.$H, val.$m])}
            />
          </div>
          <div style={{width: '20%', display:'flex', flexDirection: 'row', paddingRight:'0.2em'}}>
            <p style={{paddingRight: '0.2em'}}>{t("schedule_page.hours_work_per_day")+":"}</p>
            <TimeField 
              disabled={!d.existDay}
              value={d.existDay && d.obligatoryHours != null ? dayjs('2023-01-01T' + d.obligatoryHours) : null}
              onChange={(val) => setValueForDataTableDays(ind, ['obligatoryHours'], [val.$H])}
              format="HH"
            />
          </div>
          <div style={{width: '13%', paddingRight:'0.2em'}}>
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={d.hasBreakTime}
                  onChange={(event) => setValueForDataTableDays(ind, ['hasBreakTime'], [event.target.checked])}
                  disabled={!d.existDay}
                />}
              label={t("schedule_page.break")} />
          </div>
          {d.hasBreakTime && 
            <div style={{width: '13%', display:'flex', flexDirection: 'row', paddingRight:'0.2em'}}>
              <p style={{paddingRight: '0.2em'}}>{t("schedule_page.start") + ":"}</p>
              <div>
                <TimeField 
                  value={d.hasBreakTime && d.startBreak != null ? dayjs('2023-01-01T' + d.startBreak) : null}
                  onChange={val => setValueForDataTableDays(ind, ['startBreak', 'hourStartBreak', 'minStartBreak'], [(val.$H < 10 ? '0' : '') + val.$H + ':'
                    + (val.$m < 10 ? '0' : '') + val.$m, val.$H, val.$m])}
                />
              </div>
            </div>
          }
          {d.hasBreakTime && 
            <div style={{width: '13%', display:'flex', flexDirection: 'row', paddingRight:'0.2em'}}>
              <p style={{paddingRight: '0.2em'}}>{t("schedule_page.duration")+":"}</p>
              <div>
                <TimeField 
                  value={d.hasBreakTime && d.startBreak != null ? dayjs('2023-01-01T' + d.breakDuration) : null}
                  onChange={val => setValueForDataTableDays(ind, ['breakDuration'], [(val.$H < 10 ? '0' : '') + val.$H + ':'
                      + (val.$m < 10 ? '0' : '') + val.$m])}
                  format="HH:mm"
                />
              </div>
            </div>
          }
        </div>
        {(ind == 0 && (selectButtonsWeek.buttonWorkWeek || selectButtonsWeek.buttonCompleteWeek)) && 
          <div style={{display:'flex', justifyContent:'flex-end', width:'98%'}}>
            <TrueffortButton
              color={"secondary"}
              variant={"contained"}
              onClick={() => configureSchedule()}
              style={{ fontSize: "11px", marginBottom:'0.5em', marginRight:'0.5em'}}>
                {t("accept")}
            </TrueffortButton>
            <TrueffortButton
              color={"error"}
              variant={"outlined"}
              onClick={() => cancelConfigureSchedule()}
              style={{ fontSize: "11px", marginBottom:'0.5em'}}>
                <Clear />
            </TrueffortButton>
          </div>
        }
      </div> : 
    null));
  }

  const handleChangeSchedulesRowsPerPage = (event) => {
    setRowsPerPageSchedules(parseInt(event.target.value, 10));
    setSchedulesPage(0);
  }
  
  const handleChangeSchedulesPage = (event, newPage) => {
    setSchedulesPage(newPage);
  }

  const getFormNameAndUser = (st, isCreating) => {
    return (<Grid container spacing={1} style={st}>
      <Grid item xs={12} sm={6}>
        <TrueffortTextField 
          id={"field-name-schedule-new"}
          name="name"
          size="small"
          required={true}
          disabled={!isCreating && user.nameScheduleDefault == formik.values.name}
          fullWidth
          value={formik.values.name}
          placeholder={t("schedule_page.name_placeholder")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.errors?.name && formik.touched?.name ? formik.errors.name : null}
          labelString={isCreating ? t("Name") : undefined}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        {isCreating ? <TrueffortInputLabel shrink label={t("project_page.users")}/> : null}
        <Select 
          name="users"
          isMulti
          placeholder={t("schedule_page.assign_users")}
          value={valuesUsersSelect}
          onChange={(option) => handleOnChangeUsers(option)}
          options={users.map((usr, k) => {
                  return ({
                      value: usr.idUser, label: getFormatNameExtendForUser(usr)
                  })
              })
          }
          styles={styleReactSelect}
        />
      </Grid>
      {isCreating && <Grid item xs={12}>
        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" >
          <TrueffortButton 
            id={"new-schedule-button"}
            variant="contained" 
            color='primary'
            onClick={formik.handleSubmit}
          >
            {t("elements.buttons.save")}
          </TrueffortButton>
          <TrueffortButton
            id={"cancel-new-schedule-button"}
            onClick={() => handleCancel(-1)}
            variant="outlined"
            color="secondary"
          >
            <Clear size="small" />
          </TrueffortButton>
        </Stack>
      </Grid>}
    </Grid>);
  }

  const getButtonsWeek = () => {
    return (<Grid container spacing={1}>
      <Grid item xs={6}>
        <TrueffortButton
          variant={"contained"}
          color={selectButtonsWeek.buttonWorkWeek ? "primary": "secondary"}
          onClick={() => {
            let daysAux = daysValues.map(d_ => d_);
            daysAux[0] = {}
            setSelectButtonsWeek({buttonWorkWeek: !selectButtonsWeek.buttonWorkWeek, buttonCompleteWeek: false});
          }}
          style={{ fontSize: "11px", marginRight:'0.5em'}}>
          {t("schedule_page.workweek")}
        </TrueffortButton>
        <TrueffortButton
          color={selectButtonsWeek.buttonCompleteWeek ? "primary": "secondary"}
          variant={"contained"}
          onClick={() => {setSelectButtonsWeek({buttonCompleteWeek: false, buttonCompleteWeek: !selectButtonsWeek.buttonCompleteWeek})}}
          style={{ fontSize: "11px"}}>
            {t("schedule_page.complete_week")}
        </TrueffortButton>
      </Grid>
    </Grid>);
  }

  const styleCellCustomHead = {
    padding: 0,
    background: '#BDBDBD'
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card sx={{ padding: '8px', background: '#F5F5F5', overflow: "unset" }}>
        <Grid container spacing={1} rowSpacing={3} style={{ marginBottom: "0.4em" }}>
          <Grid item xs={12} sm md lg={6}>
            <TrueffortSearchInput
              id="search-schedule-input"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(event) => handleChangeSearchInput(event.target.value)}
              placeholder={t("schedule_page.search")}
            />
          </Grid>
          <Grid item xs={12} sm md lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TrueffortButtonSmall
              color="secondary"
              variant={"contained"}
              onClick={() => {
                setDaysValues(constructDataForDaysValues([]));
                formik.resetForm();
                setState({ ...state, isCreating: true, showFormAdd: true, isEditing: false });
                setValuesUsersSelect([]);
              }}
              startIcon={<Add />}
              style={{ fontSize: "11px" }}
              disabled={state.isEditing}
            >{t("add_new")}
            </TrueffortButtonSmall>
          </Grid>
        </Grid>
        {state.showFormAdd &&
          <Grid item xs={12}>
            <Paper sx={{marginBottom: '0.4em'}}>
              <p style={{paddingTop: '0.5em', paddingLeft:'0.5em'}}>{t("schedule_page.create")}</p>
              {getFormNameAndUser({margin:'0.5em'}, true)}
              <Box sx={{ overflowX: 'scroll' }} >
                <div style={{display: 'flex', flexDirection: 'row', background: '#D0E7FE 45%',
                  margin:'0.5em', marginBottom:'1em', paddingBottom:'0.5em', minWidth:'1000px' }}
                  className="table-days">
                  <div style={{width:'8em', height:'3em', paddingTop: '3em', display: 'flex',
                    flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Chip size="small" 
                      variant="outlined" 
                      color='error' 
                      onClick={() => setDaysValues(constructDataForDaysValues([]))}
                      label={t("erase")} 
                      style={{width: '5em'}}
                    />  
                  </div>
                  <div style={{width: '100%', marginTop: '0.5em'}}>
                    {formik.errors.days != "" && <div style={{color:'red'}}>{formik.errors.days}</div>}
                    {getButtonsWeek()}
                    {getTableEditDays()}
                  </div>
                </div>
              </Box>
            </Paper>
          </Grid>
        }
        {showLoader && 
          <Stack alignItems={"center"} justifyContent={"center"} style={{height:'300px'}}>
            <CircularProgress />
          </Stack>
        }
        {!showLoader && schedules.length == 0 && <CardEmpty>
            <div>{t("msg.info.empty_response")}</div>
          </CardEmpty>}
        {!showLoader && schedules.length > 0 && <TrueffortCardTable>
          <Grid container spacing={1} rowSpacing={3} sx={12} style={{ margin: "0.4em 0.2em", width:'100%' }}>
            <TableContainer component={Paper}
              onSubmit={formik.handleSubmit}
              sx={{overflow: "scroll"}}
            >
              <Table responsive="true" sx={{ minWidth: '1000px' }} >
                <TableHead>
                  <StyledTableRow>
                    <TrueffortTableHeadCell background={'#DADADA'}>{t("Name")}</TrueffortTableHeadCell>
                    <TrueffortTableHeadCell background={'#DADADA'}>{t("user_page.title")}</TrueffortTableHeadCell>
                    <StyledTableCell style={{background:'#BDBDBD', width: '40em', padding: 0}}>
                      <StyledTableRow style={{ width: '100%', display: 'block' }}>
                        <StyledTableCell colSpan={4} style={{ paddingTop: 0, paddingBottom: 0, background: '#BDBDBD', width: '100%', display: 'block' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{t("schedule_page.working_hours")}</div>
                            <div>{state.openDetailDay ? 
                              <ArrowDropDown style={{ cursor: 'pointer' }} 
                                size="small" 
                                onClick={() => setState({ ...state, openDetailDay: !state.openDetailDay })} /> 
                              : <ArrowRight style={{ cursor: 'pointer' }} 
                                size="small" 
                                onClick={() => setState({ ...state, openDetailDay: !state.openDetailDay })} />}
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                      {state.openDetailDay && 
                        <StyledTableRow style={{display: 'flex', width:'100%'}}>
                          <StyledTableCell style={{...styleCellCustomHead, width: '12%', paddingLeft: '1em'}}>
                            {t("schedule_page.day")}
                          </StyledTableCell>
                          <StyledTableCell style={{...styleCellCustomHead, width:'15%'}}>
                            {t("schedule_page.total_hrs")}
                          </StyledTableCell>
                          <StyledTableCell style={{...styleCellCustomHead, width: '35%', paddingLeft: '1em'}}>
                            {t("schedule_page.working_hours")}
                          </StyledTableCell>
                          <StyledTableCell style={{...styleCellCustomHead, width: '38%' }}>
                            {t("schedule_page.break")}
                          </StyledTableCell>
                        </StyledTableRow>
                      }
                      </StyledTableCell>
                      <TrueffortTableHeadCell background={'#DADADA'}>
                          {t("actions")}
                      </TrueffortTableHeadCell>
                  </StyledTableRow>
                </TableHead>
                {schedules.slice(schedulesPage * rowsPerPageSchedules,
                  schedulesPage * rowsPerPageSchedules + rowsPerPageSchedules).map((s, index) => <>
                  <StyledTableRow>
                    {!s.isEditing && <StyledTableCell style={{ width: '200px' }}>{s.name}</StyledTableCell>}
                    {!s.isEditing && <StyledTableCell>{s.users.map(u => 
                      <Chip label={u.user.isEnabled ? u.user.name + ' ' + u.user.lastName : u.user.email} 
                        style={{
                          fontSize: '10px', padding: '0.2em',
                          marginRight: '0.3em', marginBottom: '0.1em', position: 'relative'
                        }} 
                        size="small" 
                        avatar={<CustomAvatar 
                          url={u.user?.image?.url} 
                          code={u.user?.name}
                          size={20}
                          />}
                      />
                      )}</StyledTableCell>
                    }
                    {!s.isEditing && <StyledTableCell style={{paddingLeft: 0, paddingRight: 0}}>
                      {!state.openDetailDay ? getScheduleDescription(s) : getScheduleDescriptionDetail(s)}
                    </StyledTableCell>}
                    {s.isEditing && <StyledTableCell colSpan={3}>
                      {getFormNameAndUser()}
                    </StyledTableCell>}
                    <StyledTableCell>
                      {state.isEditing && s.isEditing ? <div style={{ display: 'flex' }}> 
                        <Chip size="small" 
                          icon={<Save fontSize="small" />} 
                          variant="outlined" 
                          color='success'
                          onClick={formik.handleSubmit} 
                        /> <div style={{ marginLeft: "0.5em" }} /> 
                        <Chip size="small" 
                          icon={<Clear fontSize="small" />} 
                          onClick={() => handleCancel((schedulesPage * rowsPerPageSchedules) + index)}
                          variant="outlined"
                          color="error"
                        /></div>
                        : <div style={{ display: 'flex' }}> 
                          <Chip size="small" 
                            icon={<Edit fontSize="small" />} 
                            variant="outlined" 
                            color='primary' 
                            disabled={state.isEditing || state.isCreating} 
                            onClick={() => prepareEdit(s)} 
                          /> <div style={{ marginLeft: "0.5em" }} /> 
                          <Chip size="small" 
                            icon={<Delete fontSize="small" />} 
                            onClick={() => deleteSchedule(s)}
                            disabled={state.isEditing || state.isCreating}
                            variant="outlined"
                            color="error"
                          /> 
                        </div>
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                  {s.isEditing && <StyledTableRow>
                    <StyledTableCell colSpan={4}>
                      <div style={{display: 'flex', flexDirection: 'row', background: '#D0E7FE 45%'}}
                        className="table-days">
                        <div style={{width:'8em', height:'3em', paddingTop: '3em', display: 'flex', 
                          flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
                          <Chip size="small" 
                            variant="outlined" 
                            color='error' 
                            onClick={() => setDaysValues(constructDataForDaysValues(s.days))}
                            label={t("elements.buttons.clean")} 
                            style={{width: '5em'}}
                          />  
                        </div>
                        <div style={{width: '100%', marginTop:'0.5em'}}>
                          {formik.errors.days != "" && <div style={{color:'red'}}>{formik.errors.days}</div>}
                          {getButtonsWeek()}
                          {getTableEditDays()}
                        </div>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>}
                </>
                )}
              </Table>
            </TableContainer>
            <div style={{ width:'100%', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', justifyContent: "flex-end", 
                alignItems: "center", marginTop: '1em' }}>
                <TablePagination 
                  labelRowsPerPage={t("elements.pagination.label")}
                  rowsPerPageOptions={[5, 10, 15]}
                  component={"div"}
                  onRowsPerPageChange={handleChangeSchedulesRowsPerPage}
                  onPageChange={handleChangeSchedulesPage}
                  count={schedulesCount}
                  rowsPerPage={rowsPerPageSchedules}
                  page={schedulesPage}
                />
              </Box>
            </div>
          </Grid>
        </TrueffortCardTable>}
      </Card>
    </LocalizationProvider>
  );
}

export default SchedulesPage;