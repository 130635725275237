import React from "react";
import {useTranslation} from "react-i18next";
import NotificationService from "../services/notification.service";
import {Box, IconButton, Tooltip} from "@mui/material";
import {MarkChatRead as MarkChatReadIcon} from '@mui/icons-material';
import CustomAvatar from "./custom/CustomAvatar";

// @ts-ignore
const NotificationTRUE = ({notification, fillNotifications}) => {
  const {t} = useTranslation();
  const notificationService = React.useMemo(() => new NotificationService(), []);

  const handleMarkAsRead = (id: number) => {
      // @ts-ignore
      notificationService.markAsRead(id).then(({data: response}) => {
        fillNotifications();
      });
  }

  return (
    <Box sx={{display: "flex"}}>
      <Tooltip title={t("notification.mark_as_read")}>
        <IconButton sx={{ flexGrow: 1 }}
          onClick={() => handleMarkAsRead(notification.idNotification)}>
          <MarkChatReadIcon className="color-secondary" />
        </IconButton>
      </Tooltip>
      <div className="notification-icon">
        <CustomAvatar url={notification.user?.image?.url} />
      </div>
      <Box>
        <div className="notification-icon">
          <CustomAvatar url={notification.user?.image?.url} />
        </div>
        <div>{t(notification.message, notification.paramsJson) as string}</div>
        <div>{new Date(notification.datetime).toLocaleString()}</div>
      </Box>
    </Box>
  );
}

export default NotificationTRUE;