import {useTranslation} from "react-i18next";
import {Card, Box, Stack} from "@mui/material";
import ApexChart from 'react-apexcharts';
import Grid from '@mui/material/Unstable_Grid2';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery"; // Grid version 2


// @ts-ignore
function SummaryCard({data}) {
    const {t} = useTranslation();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    let c = {
      options: {
        labels: ['', ''],
        responsive: [{
           breakpoint: 280,
           options: {
             chart: {
               width: 200
             },
             legend: {
               enabled: false
             }
           }
        }],
        dataLabels: {
          enabled: false,
        },
        legend: {
            show: false
        },
        fill: {
          colors: ['#78B737', '#DCDCDC']
        },
        tooltip: {
            enabled: false
        }
      }
    }

    return (
        <Card sx={{padding: "6px", fontSize: "0.9rem"}}>
            <Grid container spacing={1}>
                <Grid xs={5} md={3}>
                    <Box sx={{background: "#EFEFEF", borderRadius: "0.25rem", paddingTop: "0.7rem", alignItems: "center", justifyContent: "center", color: "#07074E", textAlign: "center", height: "100%"}}>
                        {data.total} <br />{data.name}
                    </Box>
                </Grid>
                <Grid xs={7} md={6}>
                    <Stack spacing={0.4} >
                        <Box sx={{background: "#CFF1AB"}}>{t("project_tasks_page.status_finished")} {data.finished}</Box>
                        <Box sx={{background: "#D7EBF0"}}>{t("project_tasks_page.status_process")} {data.process}</Box>
                        <Box sx={{background: "#EFEFEF"}}>{t("project_tasks_page.status_created")} {data.created}</Box>
                    </Stack>
                </Grid>
                {!matches &&
                    <Grid md={3} sx={{marginTop: "-.5rem", marginLeft: "-.7rem"}}>
                        <Box>
                            {typeof data.datasets != "undefined" && data.datasets.length > 0 &&
                                <ApexChart options={c.options} series={data.datasets[0].data} type="donut" width={110} height={100} />
                            }
                        </Box>
                        {/*<div>
                            <Doughnut
                                data={data}
                                options={{
                                    plugins: {
                                        legend: {display: true},
                                        tooltip: {enabled: false}
                                    },
                                    rotation: 0,
                                    circumference: 360,
                                    cutout: "70%",
                                    maintainAspectRatio: true,
                                    responsive: true,
                                    aspectRatio: 1
                                }}
                            />
                            <div
                                style={{
                                    position: "relative",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -200%)",
                                    textAlign: "center",
                                    marginBottom: "-1.5rem"
                                }}
                            >
                                <div>{data.percent.toString() + "%"}</div>
                            </div>
                        </div>*/}
                    </Grid>
                }
            </Grid>
        </Card>
    );
}

export default SummaryCard;