import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class NotificationService {

    findLast() {
        return http?.get(baseURL.activitiesapi.notifications);
    }

    markAsRead(idNotification: number) {
        return http?.put(baseURL.activitiesapi.notifications + "/maskAsRead/" + idNotification);
    }

    markAsReadAllByIdUser() {
        return http?.put(baseURL.activitiesapi.notifications + "/markAsReadAllByIdUser");
    }
}

export default NotificationService;