import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";


class PaymentService {
    createSubscription(data: any) {
        return http?.post(baseURL.activitiesapi.payments + "/createSubscription", data);
    }

    updateSubscription(idInstance: number, data: any) {
        return http?.post(baseURL.activitiesapi.payments + `/updateSubscription/${idInstance}`, data);
    }

    cancelSubscription(idInstance: number) {
        return http?.get(baseURL.activitiesapi.payments + `/cancelSubscription/${idInstance}`);
    }

    getLastSubscriptionDate(idInstance: number) {
        return http?.get(baseURL.activitiesapi.payments + `/getLastSubscriptionDate/${idInstance}`);
    }

    saveStripePaymentMethod(data: any) {
        return http?.post(baseURL.activitiesapi.payments + "/saveStripePaymentMethod", data);
    }

    get(idInstance: number) {
        return http?.get(baseURL.activitiesapi.payments + `/get/${idInstance}`);
    }

    getAll(idInstance: number) {
        return http?.get(baseURL.activitiesapi.payments + `/getAll/${idInstance}`);
    }

    updatePaymentMethod(idPaymentMethod: number, data: any) {
        return http?.post(baseURL.activitiesapi.payments + `/updatePaymentMethod/${idPaymentMethod}`, data);
    }

    addPaymentMethod(idInstance: number, data: any) {
        return http?.post(baseURL.activitiesapi.payments + `/addPaymentMethod/${idInstance}`, data);
    }

    deletePaymentMethod(data: any) {
        return http?.post(baseURL.activitiesapi.payments + `/deletePaymentMethod`, data);
    }

    eraseIncompleteSubscription(idStripePaymentMethod: number) {
        return http?.get(baseURL.activitiesapi.payments + `/eraseIncompleteSubscription/${idStripePaymentMethod}`);
    }

    getAllInvoices(idInstance: number) {
        return http?.get(baseURL.activitiesapi.payments + `/getAllInvoices/${idInstance}`);
    }

    requestInvoice(idRequestInvoice: number) {
        return http?.get(baseURL.activitiesapi.payments + `/requestInvoice/${idRequestInvoice}`);
    }

    restoreSubscription(idStripePaymentMethod: number) {
        return http?.get(baseURL.activitiesapi.payments + `/restoreSubscription/${idStripePaymentMethod}`);
    }

    getSubscriptionsHistory() {
        return http?.get(baseURL.activitiesapi.payments + `/getAllSubscriptions`);
    }

}

export default PaymentService;
