import * as React from "react";

import axios from "axios";
import {
    ACCESS_TOKEN_KEY, DEFAULT_REQUEST_ERROR, server_address,
    USER_INFO_KEY, baseURL, PROJECT_KEY, PROJECT_ID_KEY, PROJECT_OBJ_KEY, TIME_FORMAT
} from "../util/Constants";
import {User} from "../util/Interfaces";
import {useTranslation} from "react-i18next";


let user_ = localStorage.getItem(USER_INFO_KEY)
    ? JSON.parse(localStorage.getItem(USER_INFO_KEY) as string)
    : {};

let token_ = localStorage.getItem(ACCESS_TOKEN_KEY)
    ? localStorage.getItem(ACCESS_TOKEN_KEY)
    : "";

export const initialState = {
    ...user_,
    token: token_,
    loading: false,
    errorMessage: null,
};


export const AuthReducer = (state: User, action: { type: string; payload: any; error: any; }) => {
    // console.log("Receiving action " + action.type + " -> Payload: " + JSON.stringify(action.payload))
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...state,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case "UPDATE_USER":
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case "UPDATE_PROJECTS":
            return {
                ...state,
                ...action.payload
            }
        case "LOGOUT":
            return {
                loading: false,
                errorMessage: null,
                token: ""
            };

        case "LOGIN_ERROR":
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

const AuthContext = React.createContext({
    user: {} as User, projectsInfo: [{}], dispatch: (props: any) => {
    }, logout: () => {
    }, loginUser: (loginPayload: any) => {
    }, updateUserInfo: (newInfo: any) => {
    }, updateProjectsInfo: (projectsInfo: any) => {
    }
});

export const useAuthContext = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within a AuthProvider");
    }

    return context;
}


export const AuthProvider = ({children}: any) => {

    const [user, dispatch] = React.useReducer(AuthReducer, initialState);
    const {t} = useTranslation();

    const loginUser = async (loginPayload: any) => {
        let errorMessage = t(DEFAULT_REQUEST_ERROR);
        let send = false;
        try {
            dispatch({error: null, payload: null, type: 'REQUEST_LOGIN'});
            let response = await axios.post(server_address + "/token/generate-token", loginPayload);
            if (response.data.token && response.data.email) {
                localStorage.setItem(PROJECT_ID_KEY, "-1");
                localStorage.setItem(PROJECT_KEY, "");
                localStorage.setItem(TIME_FORMAT, response.data.timeFormat);
                let projects = await axios.get(baseURL.readApi.projects + '/', {headers: {Authorization: 'Bearer '+response.data.token}});
                response.data['projectsInfo'] = projects.data.data;
                let version = await axios.get(baseURL.activitiesapi.version, {headers: {Authorization: 'Bearer '+response.data.token}});
                response.data['version'] = version.data.data.version;
                //response.data['timeFormat'] = "dec";
                //response.data['sessionDuration'] = '600000';
                dispatch({error: null, type: 'LOGIN_SUCCESS', payload: response.data});
                send = true;
                return response.data;
            } else if (response.data.internalError) {
                send = true;
                dispatch({payload: null, type: 'LOGIN_ERROR', error: response.statusText});
                return response.data;
            } else
                errorMessage = response.statusText;
        } catch (error) {
            console.log("Error #DispatchLoginRequest " + error)
        } finally {
            if (!send) {
                dispatch({payload: null, type: 'LOGIN_ERROR', error: errorMessage});
            }
        }
    };

    const updateUserInfo = async (updatedInfo: any) => {
        if (updatedInfo) {
            await dispatch({error: null, payload: {...user, ...updatedInfo}, type: 'UPDATE_USER'});
        }
    };

    const updateProjectsInfo = async (projectsInfo: any) => {
        if (projectsInfo) {
            await dispatch({error: null, payload: {...user, ...projectsInfo}, type: 'UPDATE_PROJECTS'});
        }
    };

    const logout = async () => {
        try {
            //console.log(user);
            //let user_info = JSON.stringify(localStorage.getItem(USER_INFO_KEY));
            axios.delete(baseURL.activitiesapi.users + `/closeSession/${user.idUser}`, {headers: {Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN_KEY)}});
            // localStorage.clear();
            localStorage.removeItem(USER_INFO_KEY);
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(PROJECT_KEY);
            localStorage.removeItem(PROJECT_ID_KEY);
            localStorage.removeItem(PROJECT_OBJ_KEY);
            localStorage.removeItem(TIME_FORMAT);
            localStorage.removeItem("beforeunload");
            localStorage.removeItem("load");
            localStorage.removeItem("unLoad");
            // localStorage.removeItem(ACCESS_TOKEN_KEY);
            // let refreshToken = localStorage.getItem("refreshToken");
            // localStorage.removeItem("refreshToken");
            // await api.logout(refreshToken);
            dispatch({error: null, payload: null, type: 'LOGOUT'});
        } catch (error) {
            console.log("Error #DispatchLogoutRequest " + error)
        }
    }

    return (
        <AuthContext.Provider children={children}
                              value={{user, dispatch, logout, loginUser, updateUserInfo, updateProjectsInfo}}/>
    );
}