import {createContext, useContext, useReducer} from "react";
import {UserStatus} from "../projectDetail/ProjectDetailTaskRow";

const ProjectPlanContext = createContext(null);
const ProjectPlanDispatchContext = createContext(null);

export function ProjectPlanProvider({ children }: any) {
    const [projectPlan, dispatch] = useReducer(projectPlanReducer, {});

    return (
        <ProjectPlanContext.Provider value={projectPlan} >
            <ProjectPlanDispatchContext.Provider value={dispatch} >
                {children}
            </ProjectPlanDispatchContext.Provider>
        </ProjectPlanContext.Provider>
    );
}

export function useProjectPlan() {
    return useContext(ProjectPlanContext);
}
export function useProjectPlanDispatch() {
    return useContext(ProjectPlanDispatchContext);
}

function projectPlanReducer(projectPlan: any, action: any) {
    switch (action.type) {
        case 'create': {
            return createProjectPlan(
                action.createData.estimation,
                action.createData.projectName,
                action.createData.idsLeader,
                action.createData.idSelectedMeasurement,
                action.createData.elementsForm
            );
        }

        case 'changeProjectName': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            projectPlanCopy.name = action.newProjectName;

            return projectPlanCopy;
        }

        case 'changeMeasurementStandard': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            projectPlanCopy.idMeasurementStandard = action.newMeasurementStandard;

            return  projectPlanCopy;
        }

        case 'adjustDurationEstimates':
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            adjustDurationEstimates(
                projectPlanCopy,
                action.adjustedDuration,
                action.estimation
            );

            return projectPlanCopy;

        case 'changeDuration': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            changeDuration(
                projectPlanCopy,
                action.newDurationValue,
                action.level,
                action.planIndexes,
                action.adjustedDuration
            );

            return projectPlanCopy;
        }

        case 'changeEffort': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            changeEffort(
                projectPlanCopy,
                action.newEffortValue,
                action.planIndexes
            );

            return projectPlanCopy;
        }

        case 'changeElementName': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            changeElementName(
                projectPlanCopy,
                action.newElementName,
                action.planIndexes.elementTypeIndex,
                action.planIndexes.projectElementIndex
            );

            return projectPlanCopy;
        }

        case 'changeMeasurements': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            changeMeasurements(
                projectPlanCopy,
                action.newMeasurements,
                action.planIndexes
            );

            return  projectPlanCopy;
        }

        case 'addElement': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            addNewElement(
                projectPlanCopy,
                action.newEstimatedElementType,
                action.newElementInfo,
                action.adjustedDuration
            );

            return projectPlanCopy;
        }

        case 'deleteElement': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            deleteElement(
                projectPlanCopy,
                action.planIndexes.elementTypeIndex,
                action.planIndexes.projectElementIndex,
                action.adjustedDuration
            );

            return projectPlanCopy;
        }

        case 'addPhase': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            addPhase(
                projectPlanCopy,
                action.newPhaseType,
                action.planIndexes.elementTypeIndex,
                action.planIndexes.projectElementIndex,
                action.adjustedDuration
            );

            return projectPlanCopy;
        }

        case 'deletePhase': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            deletePhase(
                projectPlanCopy,
                action.planIndexes.elementTypeIndex,
                action.planIndexes.projectElementIndex,
                action.planIndexes.phaseIndex,
                action.adjustedDuration
            );

            return projectPlanCopy;
        }

        case 'addTask': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            addTask(
                projectPlanCopy,
                action.newTaskType,
                action.planIndexes,
                action.adjustedDuration
            );

            return  projectPlanCopy;
        }

        case 'deleteTask': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            deleteTask(
                projectPlanCopy,
                action.taskEffort,
                action.planIndexes,
                action.adjustedDuration
            );

            return projectPlanCopy;
        }

        case 'assignUser': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            assignUser(
                projectPlanCopy,
                action.idUser,
                action.users,
                action.taskType,
                action.planIndexes,
                action.adjustedDuration,
                action.recalculateEstimates
            );

            return projectPlanCopy;
        }

        case 'removeUser': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            removeUser(
                projectPlanCopy,
                action.user,
                action.users,
                action.taskType,
                action.planIndexes,
                action.adjustedDuration,
                action.recalculateEstimates
            );

            return projectPlanCopy;
        }

        case 'updateTaskEstimates': {
            const projectPlanCopy = JSON.parse(JSON.stringify(projectPlan));
            updateTaskEstimates(
                projectPlanCopy,
                action.users,
                action.taskType,
                action.planIndexes,
                action.adjustedDuration
            );

            return projectPlanCopy;
        }

        case 'reset': {
            return {};
        }
    }
}

const createProjectPlan = (estimation: any, projectName: string, idsLeader: any, idSelectedMeasurement: string, elementsForm: any) => {
    const plan = { // level 0
        idProjectType: estimation.id,
        name: projectName,
        idsLeader: idsLeader.map((l:any) => l.value),
        idMeasurementStandard: idSelectedMeasurement === "" ? null : Number(idSelectedMeasurement),
        estimatedDays: 0, //estimation.duration_avg,
        estimatedTime: getTotalEstimatedTime(estimation.elementtypes, elementsForm),
        elementTypes: createPlanElements(estimation.elementtypes, elementsForm)
    };

    adjustDurationEstimates(plan, true, undefined);

    return plan;
}

function getTotalEstimatedTime(arrElementTypes: any, elementsForm: any) {
    return arrElementTypes.reduce(
        (acc: number, elemType: any) =>
            acc +
            (getElementTypeTotalEstimatedAvgBySizeAndComplexity(
                elemType,
                elementsForm.find((elemForm: any) => elemForm.idElemento == elemType.id).tamanio,
                elementsForm.find((elemForm: any) => elemForm.idElemento == elemType.id).complejidad
                )
                * elementsForm.find((elemForm: any) => elemForm.idElemento == elemType.id).numeroElementos
            ), 0
    );
}

function getElementTypeTotalEstimatedAvgBySizeAndComplexity(elemType: any, size: number, complexity: number) {
    let arrPhaseTypes = getPhaseTypesByComplexity(elemType.dificulties, size, complexity);
    return arrPhaseTypes.reduce((prev: any, current: any) => prev + current.effort_avg, 0);
}

function getElementTypeTotalEstimatedAvg(arrPhaseTypes: any) {
    return arrPhaseTypes.reduce((prev: any, current: any) => prev + current.effort_avg, 0);
}

const createPlanElements = (estimation_elements: any, elementsForm: any) => {
    return estimation_elements.map((elemType: any) => {
        const elemsForm = elementsForm.filter((elemForm: any): boolean => elemForm.idElemento == elemType.id);
        let i: number = 1;

        return { // level 1
            idElementType: elemType.id,
            projectElements: elemsForm.map((elemForm: any) =>
                [...Array(elemForm.numeroElementos)].map((elem: any) => {
                    // Obtener las fases por complejidad
                    let arrPhaseTypes = getPhaseTypesByComplexity(elemType.dificulties, elemForm.tamanio, elemForm.complejidad);
                    const elemTypeInfo = elemType.dificulties.find((d: any) => d.size_id == elemForm.tamanio && d.complexity_id == elemForm.complejidad); // temporal
                    return {
                        artifitialKey: elemType.id + "-" + i,
                        name: elemType.name + " " + (i++),
                        estimatedDays: 0, //elemTypeInfo.duration_avg,
                        estimatedTime: getElementTypeTotalEstimatedAvg(arrPhaseTypes),
                        idSize: elemForm.tamanio,
                        idComplexity: elemForm.complejidad,
                        functionalSize: 1.00,
                        duration_avg: elemTypeInfo.duration_avg, // temporal
                        duration_min: elemTypeInfo.duration_min, // temporal
                        duration_max: elemTypeInfo.duration_max, // temporal
                        effort_avg: elemTypeInfo.effort_avg, // temporal
                        effort_min: elemTypeInfo.effort_min, // temporal
                        effort_max: elemTypeInfo.effort_max, // temporal
                        projectPhases: createPlanPhases(elemType.dificulties, elemForm.tamanio, elemForm.complejidad)
                    }
                })
            ).flat()
        };
    });
}

// @ts-ignore
function getPhaseTypesByComplexity(arrDificulties, size, complexity) {
    for (let i=0; i < arrDificulties.length; i++) {
        if (arrDificulties[i].size_id == size && arrDificulties[i].complexity_id == complexity) {
            return arrDificulties[i].phasetypes;
        }
    }
}

const createPlanPhases = (arrDificulties: any, size: number, complexity: number) => {
    let arrPhaseTypes = [];
    for (let i=0; i < arrDificulties.length; i++) {
        if (arrDificulties[i].size_id == size && arrDificulties[i].complexity_id == complexity) {
            arrPhaseTypes = arrDificulties[i].phasetypes;
            break;
        }
    }
    return arrPhaseTypes.map((phaseType: any) =>
        ({ // level 2
            idPhaseType: phaseType.id,
            estimatedDays: 0, //phaseType.duration_avg,
            estimatedTime: phaseType.effort_avg,
            projectTasks: createPlanTasks(phaseType.tasktypes)
        })
    );
}

const createPlanTasks = (estimation_tasks: any) => {
    return estimation_tasks.map((taskType: any) =>
        ({ // level 3
            idTaskType: taskType.id,
            estimatedDays: taskType.duration_avg,
            estimatedTime: taskType.effort_avg,
            assignedUsers: []
        })
    );
}

const adjustDurationEstimates = (plan: any, adjustedDuration: boolean, estimation: any) => {
    if (adjustedDuration) {
        plan.estimatedDays = adjustElementsDuration(plan.elementTypes);
    } else {
        plan.estimatedDays = estimation.duration_avg;
        for (let i: number = 0; i < plan.elementTypes.length; i++) {
            for (let j: number = 0; j < plan.elementTypes[i].projectElements.length; j++) {
                plan.elementTypes[i].projectElements[j].estimatedDays =
                    plan.elementTypes[i].projectElements[j].duration_avg;
                returnPhasesDurationToEstimate(
                    plan.elementTypes[i].projectElements[j].projectPhases,
                    estimation.elementtypes[i].dificulties,
                    plan.elementTypes[i].projectElements[j].idSize,
                    plan.elementTypes[i].projectElements[j].idComplexity
                );
            }
        }
    }
}

const returnPhasesDurationToEstimate = (phases: any, arrDificulties: any, size: number, complexity: number) => {
    const phaseTypes = arrDificulties.find(
        (elemType: any) => elemType.size_id === size && elemType.complexity_id === complexity
    ).phasetypes;

    for (let i: number = 0; i < phases.length; i++) {
        const phaseType = phaseTypes.find(
            (pT: any) =>  pT.id === phases[i].idPhaseType
        );
        phases[i].estimatedDays = phaseType.duration_avg;
        returnTasksDurationToEstimate(phases[i].projectTasks, phaseType.tasktypes);
    }
}

const returnTasksDurationToEstimate = (projectTasks: any, taskTypes: any) => {
    for (let i = 0; i < projectTasks.length; i++) {
        projectTasks[i].estimatedDays = taskTypes.find(
            (taskType: any) => taskType.id === projectTasks[i].idTaskType
        ).duration_avg;
    }
}

const adjustElementsDuration = (elementTypes: any) => {
    let durationMin: number = 0;
    let durationMax: number = 0;
    for (let i: number = 0; i < elementTypes.length; i++) {
        for (let j: number = 0; j < elementTypes[i].projectElements.length; j++) {
            elementTypes[i].projectElements[j].estimatedDays = adjustPhasesDuration(elementTypes[i].projectElements[j].projectPhases);
            durationMin = durationMin < elementTypes[i].projectElements[j].estimatedDays ?
                elementTypes[i].projectElements[j].estimatedDays :
                durationMin;
            durationMax += elementTypes[i].projectElements[j].estimatedDays;
        }
    }

    return Math.round((durationMin + durationMax) / 2);
}

const adjustPhasesDuration = (phases: any) => {
    let elementDurationMin: number = 0;
    let elementDurationMax: number = 0;
    for (let i: number = 0; i < phases.length; i++) {
        let phaseDurationMax: number = phases[i].projectTasks.reduce(
            (acc: number, task: any) => acc + task.estimatedDays,
            0
        );
        let phaseDurationMin: number = phases[i].projectTasks.reduce(
            (acc: number, task: any) => acc < task.estimatedDays ? task.estimatedDays : acc,
            0
        );
        phases[i].estimatedDays = Math.round((phaseDurationMax + phaseDurationMin) / 2);
        elementDurationMin = elementDurationMin < phases[i].estimatedDays ? phases[i].estimatedDays : elementDurationMin;
        elementDurationMax += phases[i].estimatedDays;
    }

    return Math.round((elementDurationMin + elementDurationMax) / 2);
}


const changeDuration = (plan: any, timeValue: number, changeLevel: number, planIndexes: any, adjustedDuration: boolean) => {
    switch (changeLevel) {
        case 0:
            plan.estimatedDays = timeValue;

            break;
        case 1:
            if (plan.estimatedDays < timeValue) { // level 0
                plan.estimatedDays = timeValue;
            }
            plan.elementTypes[planIndexes.elementTypeIndex]
                .projectElements[planIndexes.projectElementIndex].estimatedDays = timeValue;

            if (adjustedDuration) {
                keepAdjustedDurationBelowMaxLevel1(plan);
            }

            break;
        case 2:
            if (plan.estimatedDays < timeValue) { // level 0
                plan.estimatedDays = timeValue;
            }
            if (plan.elementTypes[planIndexes.elementTypeIndex]
                .projectElements[planIndexes.projectElementIndex].estimatedDays < timeValue) { // level 1
                plan.elementTypes[planIndexes.elementTypeIndex]
                    .projectElements[planIndexes.projectElementIndex].estimatedDays = timeValue;
            }
            plan.elementTypes[planIndexes.elementTypeIndex]
                .projectElements[planIndexes.projectElementIndex]
                .projectPhases[planIndexes.phaseIndex].estimatedDays = timeValue;

            if (adjustedDuration) {
                keepAdjustedDurationBelowMaxLevel2(plan, planIndexes);
            }

            break;
        case 3:
            if (plan.estimatedDays < timeValue) { // level 0
                plan.estimatedDays = timeValue;
            }
            if (plan.elementTypes[planIndexes.elementTypeIndex]
                .projectElements[planIndexes.projectElementIndex].estimatedDays < timeValue) { // level 1
                plan.elementTypes[planIndexes.elementTypeIndex]
                    .projectElements[planIndexes.projectElementIndex].estimatedDays = timeValue;
            }
            if (
                plan.elementTypes[planIndexes.elementTypeIndex]
                    .projectElements[planIndexes.projectElementIndex]
                    .projectPhases[planIndexes.phaseIndex]
                    .estimatedDays < timeValue
            ) { // level 2
                plan.elementTypes[planIndexes.elementTypeIndex].projectElements[planIndexes.projectElementIndex]
                    .projectPhases[planIndexes.phaseIndex].estimatedDays = timeValue;
            }
            plan.elementTypes[planIndexes.elementTypeIndex]
                .projectElements[planIndexes.projectElementIndex]
                .projectPhases[planIndexes.phaseIndex]
                .projectTasks[planIndexes.taskIndex].estimatedDays = timeValue;
            
            if (adjustedDuration) {
              keepAdjustedDurationBelowMaxLevel3(plan, planIndexes);
            }

            break;
    }
}

const keepAdjustedDurationBelowMaxLevel1 = (plan: any) => {
    let adjustedDurationMax: number = getPlanAdjustedDurationMax(plan.elementTypes);
    if (plan.estimatedDays > adjustedDurationMax) {
        plan.estimatedDays = adjustedDurationMax;
    }
}

const getPlanAdjustedDurationMax = (elementTypes: any) => {
    let adjustedDurationMax: number = 0;
    for (let i: number = 0; i < elementTypes.length; i++) {
        for (let j: number = 0; j < elementTypes[i].projectElements.length; j++) {
            adjustedDurationMax += elementTypes[i].projectElements[j].estimatedDays;
        }
    }

    return adjustedDurationMax
}

const keepAdjustedDurationBelowMaxLevel2 = (plan: any, planIndexes: any) => {
    let elementAdjustedDurationMax: number = plan
        .elementTypes[planIndexes.elementTypeIndex]
        .projectElements[planIndexes.projectElementIndex]
        .projectPhases.reduce(
            (acc: number, phase: any) => acc + phase.estimatedDays,
            0
        );
    if (
        plan.elementTypes[planIndexes.elementTypeIndex]
            .projectElements[planIndexes.projectElementIndex]
            .estimatedDays > elementAdjustedDurationMax
    ) {
        plan.elementTypes[planIndexes.elementTypeIndex]
            .projectElements[planIndexes.projectElementIndex]
            .estimatedDays = elementAdjustedDurationMax
    }

    keepAdjustedDurationBelowMaxLevel1(plan);
}

const keepAdjustedDurationBelowMaxLevel3 = (plan: any, planIndexes: any) => {
    let phaseAdjustedDurationMax: number = plan
        .elementTypes[planIndexes.elementTypeIndex]
        .projectElements[planIndexes.projectElementIndex]
        .projectPhases[planIndexes.phaseIndex]
        .projectTasks.reduce(
            (acc: number, task: any) => acc + task.estimatedDays,
            0
        );
    if (
        plan.elementTypes[planIndexes.elementTypeIndex]
            .projectElements[planIndexes.projectElementIndex]
            .projectPhases[planIndexes.phaseIndex]
            .estimatedDays > phaseAdjustedDurationMax
    ) {
        plan.elementTypes[planIndexes.elementTypeIndex]
            .projectElements[planIndexes.projectElementIndex]
            .projectPhases[planIndexes.phaseIndex]
            .estimatedDays = phaseAdjustedDurationMax
    }

    keepAdjustedDurationBelowMaxLevel2(plan, planIndexes);
}

const changeEffort = (plan: any, value: number, indexes: any) => {
    /*console.log("=================");
    console.log(plan);
    console.log(indexes);
    console.log("=================");*/
    let prevValue: number = plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks[indexes.taskIndex].estimatedTime
    let effortDifference: number = value - prevValue;

    plan.estimatedTime += effortDifference;
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex].estimatedTime += effortDifference;
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex].estimatedTime += effortDifference;
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks[indexes.taskIndex].estimatedTime = value;
}

const changeElementName = (plan: any, newElementName: number, elementTypeIndex: number, projectElementIndex: number) => {
    plan.elementTypes[elementTypeIndex]
        .projectElements[projectElementIndex]
        .name = newElementName;
}

const changeMeasurements = (plan: any, newMeasurements: { newSize?: number, newComplexity?: number, newFunctionalSize?: number }, indexes: any) => {
    if (newMeasurements.newSize !== undefined) {
        plan.elementTypes[indexes.elementTypeIndex]
            .projectElements[indexes.projectElementIndex]
            .idSize = newMeasurements.newSize;
    }

    if (newMeasurements.newComplexity !== undefined) {
        plan.elementTypes[indexes.elementTypeIndex]
            .projectElements[indexes.projectElementIndex]
            .idComplexity = newMeasurements.newComplexity;
    }

    if (newMeasurements.newFunctionalSize !== undefined) {
        plan.elementTypes[indexes.elementTypeIndex]
            .projectElements[indexes.projectElementIndex]
            .functionalSize = newMeasurements.newFunctionalSize;
    }
}

const addNewElement = (plan: any, elemType: any, elemInfo: any, adjustedDuration: boolean) => {
    // Obtener las fases por complejidad
    let arrPhaseTypes = getPhaseTypesByComplexity(elemType.dificulties, elemInfo.idSize, elemInfo.idComplexity);
    const newProjectElement = {
        artifitialKey: "",
        name: elemInfo.elementName,
        estimatedDays: elemType.dificulties[0].duration_avg,
        estimatedTime: getElementTypeTotalEstimatedAvg(arrPhaseTypes),
        idSize: elemInfo.idSize,
        idComplexity: elemInfo.idComplexity,
        functionalSize: 1.00,
        duration_avg: elemType.dificulties[0].duration_avg, // temporal
        duration_min: elemType.dificulties[0].duration_min, // temporal
        duration_max: elemType.dificulties[0].duration_max, // temporal
        effort_avg: elemType.dificulties[0].effort_avg, // temporal
        effort_min: elemType.dificulties[0].effort_min, // temporal
        effort_max: elemType.dificulties[0].effort_max, // temporal
        projectPhases: createPlanPhases(elemType.dificulties, elemInfo.idSize, elemInfo.idComplexity)
    }

    if (adjustedDuration) {
        newProjectElement.estimatedDays = adjustPhasesDuration(newProjectElement.projectPhases);
    }

    let elementTypeIndex = plan.elementTypes.findIndex((elem: any) => elem.idElementType === elemType.id);

    if (elementTypeIndex === -1) {
        newProjectElement.artifitialKey = elemType.id + "-" + 1;
        plan.elementTypes.push({
            idElementType: elemType.id,
            projectElements: [newProjectElement]
        });
    } else {
        newProjectElement.artifitialKey = elemType.id + "-" + (plan.elementTypes[elementTypeIndex].projectElements.length+1);
        // ENCONTRAR EL ELEMENT TYPE CON SU TAMAÑO Y COMPLEJIDAD
        plan.elementTypes[elementTypeIndex].projectElements.push(newProjectElement);
    }

    plan.estimatedTime += newProjectElement.estimatedTime;

    if (adjustedDuration) {
        plan.estimatedDays = updatePlanAdjustedDuration(plan.elementTypes);
    } else if (elemType.duration_avg > plan.estimatedDays) {
        plan.estimatedDays = newProjectElement.estimatedDays;
    }
}

const deleteElement = (plan: any, elementTypeIndex: number, projectElementIndex: number, adjustedDuration: boolean) => {
    plan.estimatedTime -= plan.elementTypes[elementTypeIndex].projectElements[projectElementIndex].estimatedTime;
    if (plan.elementTypes[elementTypeIndex].projectElements.length === 1) {
        plan.elementTypes.splice(elementTypeIndex, 1);
    } else {
        plan.elementTypes[elementTypeIndex].projectElements.splice(projectElementIndex, 1);
    }

    if (adjustedDuration) {
        plan.estimatedDays = updatePlanAdjustedDuration(plan.elementTypes);
    }
}

const addPhase = (plan: any, phaseType: any, elementTypeIndex: number, projectElementIndex: number, adjustedDuration: boolean) => {
    let phaseDurationMax: number = phaseType.tasktypes.reduce(
        (acc: number, task: any) => acc + task.duration_avg,
        0
    );
    let phaseDurationMin: number = phaseType.tasktypes.reduce(
        (acc: number, task: any) => acc < task.duration_avg ? task.duration_avg : acc,
        0
    );

    const projectPhase = {
        idPhaseType: phaseType.id,
        estimatedDays: !adjustedDuration ? phaseType.duration_avg :
            Math.round((phaseDurationMin + phaseDurationMax) / 2),
        estimatedTime: phaseType.effort_avg,
        projectTasks: phaseType.tasktypes.map((taskType: any) =>
            ({
                idTaskType: taskType.id,
                estimatedDays: taskType.duration_avg,
                estimatedTime: taskType.effort_avg,
                assignedUsers: []
            })
        )
    }

    plan.elementTypes[elementTypeIndex]
        .projectElements[projectElementIndex]
        .projectPhases.unshift(projectPhase);

    plan.elementTypes[elementTypeIndex]
        .projectElements[projectElementIndex].estimatedTime += phaseType.effort_avg;

    plan.estimatedTime += phaseType.effort_avg;

    if (adjustedDuration) {
        plan.elementTypes[elementTypeIndex]
            .projectElements[projectElementIndex]
            .estimatedDays = updateElementAdjustedDuration(
                plan.elementTypes[elementTypeIndex]
                    .projectElements[projectElementIndex]
                    .projectPhases
        );

        plan.estimatedDays = updatePlanAdjustedDuration(plan.elementTypes);
    } else {
        if (phaseType.duration_avg >
            plan.elementTypes[elementTypeIndex].projectElements[projectElementIndex].estimatedDays) {
            plan.elementTypes[elementTypeIndex]
                .projectElements[projectElementIndex]
                .estimatedDays = phaseType.duration_avg;
        }

        if (phaseType.duration_avg > plan.estimatedDays) {
            plan.estimatedDays = phaseType.duration_avg;
        }
    }
}

const deletePhase = (plan: any, elementTypeIndex: number, projectElementIndex: number, phaseIndex: number, adjustedDuration: boolean) => {
    let phaseEffort = plan.elementTypes[elementTypeIndex]
        .projectElements[projectElementIndex]
        .projectPhases[phaseIndex].estimatedTime;
    plan.estimatedTime = plan.estimatedTime - phaseEffort;
    let elementEffort: number = plan.elementTypes[elementTypeIndex]
        .projectElements[projectElementIndex].estimatedTime;
    plan.elementTypes[elementTypeIndex]
        .projectElements[projectElementIndex].estimatedTime = elementEffort - phaseEffort;
    plan.elementTypes[elementTypeIndex]
        .projectElements[projectElementIndex]
        .projectPhases.splice(phaseIndex, 1);

    if (adjustedDuration) {
        plan.elementTypes[elementTypeIndex]
            .projectElements[projectElementIndex]
            .estimatedDays = updateElementAdjustedDuration(
                plan.elementTypes[elementTypeIndex]
                    .projectElements[projectElementIndex]
                    .projectPhases
        );

        plan.estimatedDays = updatePlanAdjustedDuration(plan.elementTypes);
    }
}

const addTask = (plan: any, taskType: any, indexes: any, adjustedDuration: boolean) => {
    const projectTask = {
        idTaskType: taskType.id,
        estimatedDays: taskType.duration_avg,
        estimatedTime: taskType.effort_avg,
        assignedUsers: []
    }

    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks.unshift(projectTask);

    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex].estimatedTime += taskType.effort_avg;
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex].estimatedTime += taskType.effort_avg;
    plan.estimatedTime += taskType.effort_avg;

    if (adjustedDuration) {
        plan.elementTypes[indexes.elementTypeIndex]
            .projectElements[indexes.projectElementIndex]
            .projectPhases[indexes.phaseIndex]
            .estimatedDays = updatePhaseAdjustedDuration(
                plan.elementTypes[indexes.elementTypeIndex]
                    .projectElements[indexes.projectElementIndex]
                    .projectPhases[indexes.phaseIndex]
                    .projectTasks
        );

        plan.elementTypes[indexes.elementTypeIndex]
            .projectElements[indexes.projectElementIndex]
            .estimatedDays = updateElementAdjustedDuration(
                plan.elementTypes[indexes.elementTypeIndex]
                    .projectElements[indexes.projectElementIndex]
                    .projectPhases
        );

        plan.estimatedDays = updatePlanAdjustedDuration(plan.elementTypes);
    } else {
        if (taskType.duration_avg >
            plan.elementTypes[indexes.elementTypeIndex]
                .projectElements[indexes.projectElementIndex]
                .projectPhases[indexes.phaseIndex].estimatedDays
        ) {
            plan.elementTypes[indexes.elementTypeIndex]
                .projectElements[indexes.projectElementIndex]
                .projectPhases[indexes.phaseIndex].estimatedDays = taskType.duration_avg;
        }

        if (taskType.duration_avg >
            plan.elementTypes[indexes.elementTypeIndex]
                .projectElements[indexes.projectElementIndex].estimatedDays
        ) {
            plan.elementTypes[indexes.elementTypeIndex]
                .projectElements[indexes.projectElementIndex].estimatedDays = taskType.duration_avg;
        }

        if (taskType.duration_avg > plan.estimatedDays) {
            plan.estimatedDays = taskType.duration_avg;
        }
    }
}

const deleteTask = (plan: any, taskEffort: number, indexes: any, adjustedDuration: boolean) => {
    plan.estimatedTime = plan.estimatedTime - taskEffort;
    let elementEffort: number = plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex].estimatedTime;
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex].estimatedTime = elementEffort - taskEffort;
    let phaseEffort: number = plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex].estimatedTime;
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex].estimatedTime = phaseEffort - taskEffort;

    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks.splice(indexes.taskIndex, 1);

    if (adjustedDuration) {
        plan.elementTypes[indexes.elementTypeIndex]
            .projectElements[indexes.projectElementIndex]
            .projectPhases[indexes.phaseIndex]
            .estimatedDays = updatePhaseAdjustedDuration(
            plan.elementTypes[indexes.elementTypeIndex]
                .projectElements[indexes.projectElementIndex]
                .projectPhases[indexes.phaseIndex]
                .projectTasks
        );

        plan.elementTypes[indexes.elementTypeIndex]
            .projectElements[indexes.projectElementIndex]
            .estimatedDays = updateElementAdjustedDuration(
            plan.elementTypes[indexes.elementTypeIndex]
                .projectElements[indexes.projectElementIndex]
                .projectPhases
        );

        plan.estimatedDays = updatePlanAdjustedDuration(plan.elementTypes);
    }
}

const updatePlanAdjustedDuration = (elementTypes: any) => {
    let adjustedDurationMin: number = 0;
    let adjustedDurationMax: number = 0;
    for (let i: number = 0; i < elementTypes.length; i++) {
        for (let j: number = 0; j < elementTypes[i].projectElements.length; j++) {
            adjustedDurationMin = adjustedDurationMin < elementTypes[i].projectElements[j].estimatedDays ?
                elementTypes[i].projectElements[j].estimatedDays :
                adjustedDurationMin;
            adjustedDurationMax += elementTypes[i].projectElements[j].estimatedDays;
        }
    }

    return Math.round((adjustedDurationMin + adjustedDurationMax) / 2);
}

const updateElementAdjustedDuration = (projectPhases: any) => {
    let elementDurationMin: number = projectPhases.reduce(
        (acc: number, phase: any) => acc < phase.estimatedDays ? phase.estimatedDays : acc,
        0
    );
    let elementDurationMax: number = projectPhases.reduce(
        (acc: number, phase: any) => acc + phase.estimatedDays,
        0
    );

    return Math.round((elementDurationMin + elementDurationMax) / 2);
}

const updatePhaseAdjustedDuration = (projectTasks: any) => {
    let phaseDurationMin: number = projectTasks.reduce(
        (acc: number, task: any) => acc < task.estimatedDays ? task.estimatedDays : acc,
        0
    );
    let phaseDurationMax: number = projectTasks.reduce(
        (acc: number, task: any) => acc + task.estimatedDays,
        0
    );

    return Math.round((phaseDurationMin + phaseDurationMax) / 2);
}

/* TODO: Considerar quitar taskType, ya que sólo se usan dos datos */
const assignUser = (plan: any, idUser: number, users: any, taskType: any, indexes: any, adjustedDuration: boolean, recalculateEstimates: boolean) => {
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks[indexes.taskIndex].assignedUsers.push(idUser);

    let num_assigned_users = plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks[indexes.taskIndex]
        .assignedUsers.length;

    if (num_assigned_users > 0 && recalculateEstimates) {
        usersEffortAverage(plan, users, num_assigned_users, indexes);
        if (taskType.duration_avg > 0) {
            let avgDuration: number = taskType.duration_avg * taskType.team_sz_avg / num_assigned_users;
            changeDuration(plan, Math.ceil(avgDuration), 3, indexes, adjustedDuration);
        }
    }
}

const usersEffortAverage = (plan: any, users: any, numUsers: number, indexes: any) => {
    let ceroEffortUsers: number = users.reduce((acc: number, user: any) =>
        acc + (user.status === UserStatus.Assigned && !user.effort_interp_avg ? 1 : 0), 0
    );
    let usersWithEffort: number = numUsers - ceroEffortUsers;
    if (usersWithEffort === 0) {
        return;
    }

    const assignedUsers = users.filter((user: any) => user.status === UserStatus.Assigned);
    let avgUsersEffort: number = assignedUsers.reduce((acc: number, user: any) => acc + user.effort_interp_avg, 0) / usersWithEffort;
    changeEffort(plan, Math.ceil(avgUsersEffort), indexes);
}

/* TODO: Considerar quitar user y taskType, del ultimo sólo se usa un dato */
const removeUser = (plan: any, user: any, users: any, taskType: any, indexes: any, adjustedDuration: boolean, recalculateEstimates: boolean) => {
    const new_users = plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks[indexes.taskIndex]
        .assignedUsers.filter((x: number) => x != user.id);
    plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks[indexes.taskIndex]
        .assignedUsers = new_users;

    if (new_users.length > 0 && recalculateEstimates) {
        usersEffortAverage(plan, users, new_users.length, indexes);
        if (taskType.duration_avg > 0) {
            let avgDuration: number = taskType.duration_avg * taskType.team_sz_avg / new_users.length;
            changeDuration(plan, Math.ceil(avgDuration), 3, indexes, adjustedDuration);
        }
    } else if (new_users.length === 0 && recalculateEstimates) {
            /*-- might delete --*/
        if (user.effort_interp_avg > 0 && taskType.effort_avg > 0) {
            changeEffort(plan, taskType.effort_avg, indexes);
        }
        changeDuration(plan, taskType.duration_avg, 3, indexes, adjustedDuration);
    }
}

const updateTaskEstimates = (plan: any, users: any, taskType: any, indexes: any, adjustedDuration: boolean) => {
    let num_assigned_users = plan.elementTypes[indexes.elementTypeIndex]
        .projectElements[indexes.projectElementIndex]
        .projectPhases[indexes.phaseIndex]
        .projectTasks[indexes.taskIndex]
        .assignedUsers.length;

    if (num_assigned_users > 0) {
        usersEffortAverage(plan, users, num_assigned_users, indexes);
        if (taskType.duration_avg > 0) {
            let avgDuration: number = taskType.duration_avg * taskType.team_sz_avg / num_assigned_users;
            changeDuration(plan, Math.ceil(avgDuration), 3, indexes, adjustedDuration);
        }
    } else if (num_assigned_users === 0) {
        if (taskType.effort_avg > 0) {
            changeEffort(plan, taskType.effort_avg, indexes);
        }
        changeDuration(plan, taskType.duration_avg, 3, indexes, adjustedDuration);
    }
}