import { Avatar } from "@mui/material";

// @ts-ignore
const CustomAvatar = ({url, code, text, size, sizeCode}:{url?:string, code?:string,
  text?:string, size?:number, sizeCode?:number}) => {
  const sizeMin = {width: size ? size : 32, height: size ? size : 32};
  return (
    <div style={{display:'flex', alignItems:'center'}}>
      {url ? <Avatar sx={sizeMin} src={url} style={{marginRight: '0.4em'}}/> :
        code ? <Avatar sx={sizeMin}><span style={{fontSize:sizeCode?sizeCode : 16}}>{code.substring(0,2)}</span></Avatar> :
        <Avatar sx={sizeMin} className="img-icon-app" />
      }
      {text && <span style={{paddingLeft:'0.2em'}}>{text}</span>}
  </div>
  )
};

export default CustomAvatar;