import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class CategorysiteService {

    findAll() {
        return http?.get(baseURL.activitiesapi.categorysite + "/");
    }

    create(data: any) {
        const options = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }, transformRequest: () => data
        }
        return http?.post(baseURL.activitiesapi.categorysite + "/", data, options);
    }

    update(data: any) {
        const options = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }, transformRequest: () => data
        }
        return http?.put(baseURL.activitiesapi.categorysite + `/`, data, options);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.categorysite + `/${id}`);
    }
}

export default CategorysiteService;