import React from "react";
import {Avatar} from "@mui/material";

const ImageUser = ({user}: any) => {
    return(
        <div>
            {typeof user.image_id != "undefined" && user.image_id != null && user.image_id != "" &&
            <Avatar src={user.image_url} style={{marginRight: ".4em"}} sx={{ width: 26, height: 26 }} />
                //<Avatar className="img-icon-app" />
            }
            {typeof user.imageDto != "undefined" && user.imageDto != null && user.imageDto != "" &&
            <Avatar src={user.imageDto.url} style={{marginRight: ".4em"}} sx={{ width: 26, height: 26 }} />
            }
            {typeof user.image != "undefined" && user.image != null && user.image != "" &&
            <Avatar src={user.image.url} style={{marginRight: ".4em"}} sx={{ width: 26, height: 26 }} />
            }
            {/*user.name + " " + (user.lastname === undefined ? user.lastName : user.lastname)*/}
        </div>
    );
}

export default ImageUser;