import {
  Grid, Tab, Tabs, Card, TablePagination, Paper, TableContainer, Table,
  TableRow, TableCell, TableHead, TableBody, Box,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatSeconds } from "../../util/GlobalFunctions";
import {
  PieChart as PieChartIcon, AccountTree as AccountTreeIcon, AccessTimeFilled as AccessTimeFilledIcon, 
  KeyboardArrowRight as KeyboardArrowRightIcon, CropOriginal as CropOriginalIcon, 
  KeyboardArrowLeft as KeyboardArrowLeftIcon, Construction as ConstructionIcon, ScatterPlot as ScatterPlotIcon, 
  ImportContacts as ImportContactsIcon
} from "@mui/icons-material";
import { StyledTableCell, StyledTableRow, TrueffortTableHeadCell } from "../../MUIstyle/MUICustom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { COLORS_RELEVANT } from "../../util/Constants";
import { useAuthContext } from "../../contexts/AuthenticationContext";
import GlobalUserViewReport from "./GlobalUserViewReport";
import GlobalUserWeeklyReport from "./GlobalUserWeeklyReport";
import CustomAvatarBadge from "../custom/CustomAvatarBadge";
import GlobalUserBenchmarkReport from "./GlobalUserBenchmarkReport";

function GlobalReportUsers({ startDate, endDate, idSelectedUser, idsSelectedUsers, idSelectedProject, 
  inputSearch, showLoader, indexTab, handleChangeIndexTab}) {
  const { user } = useAuthContext();
  const features = user?.instance?.license ? user?.instance?.license?.features : {};
  const { t } = useTranslation();
  const [dataUsersFilter, setDataUsersFilter] = React.useState([]);
  let ROWS_PER_PAGE = 4;
  const [rowsPerPageUsers, setRowsPerPageUsers] = React.useState(ROWS_PER_PAGE);
  const [usersPage, setUsersPage] = React.useState(0);
  const [usersCount, setUsersCount] = React.useState(0);
  const [showTableProductivityDetail, setShowTableProductivityDetail] = React.useState(false);
  const [showTableElementDetail, setShowTableElementDetail] = React.useState(false);
  const [showTablePhaseDetail, setShowTablePhaseDetail] = React.useState(false);
  const [showTableAppDetail, setShowTableAppDetail] = React.useState(false);

  const styleTableContent = { fontSize: '12px', padding: 0 };
  const columnDataDetail = { textAlign: "center", width: '75px' };

  const handleChangeUsersRowsPerPage = (event) => {
    setRowsPerPageUsers(parseInt(event.target.value, 10));
    setUsersPage(0);
  }

  const handleChangeUsersPage = (event, newPage) => {
    setUsersPage(newPage);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <Paper elevation={0} sx={{ padding: '12px', bgcolor: showLoader ? 'transparent' : '#F5F5F5', marginTop: '1em', width: '100%' }} >
          {!showLoader &&
            <Grid container spacing={1} xs={12}>
              <Grid id={"idTabsUsers"} item xl={10} md={10} sm xs={12}>
                <Tabs
                  variant='scrollable'
                  scrollButtons='auto'
                  value={indexTab}
                  onChange={handleChangeIndexTab}
                >
                  {features.userProductivityReportItemsPerformed && 
                    <Tab id="idTabGlobal" sx={{ minHeight: '0px' }}
                      icon={<PieChartIcon />} iconPosition="start"
                      label={t("reports.global_view")} value={0} />}
                  {false && <Tab id="idTabDetail" sx={{ minHeight: '0px' }}
                    icon={<AccountTreeIcon />} iconPosition="start"
                    label={t("reports.detail_view")} value={1} />}
                  {features.worksheetsUsers && <Tab id="idTabWeekly" sx={{ minHeight: '0px' }}
                    icon={<ImportContactsIcon />} iconPosition="start"
                    label={t("reports.time_summary.title")} value={3} />}
                  {features.reportBenchmark && <Tab id="idTabDetailGraph" sx={{ minHeight: '0px' }}
                    icon={<ScatterPlotIcon />} iconPosition="start"
                    label={t("reports.benchmark.title")} value={4} />}
                </Tabs>
              </Grid>
            </Grid>
          }
          {!showLoader && indexTab == 0 && 
            <GlobalUserViewReport startDate={startDate}
              endDate={endDate}
              idSelectedUser={idSelectedUser}
              idSelectedProject={idSelectedProject}
              inputSearch={inputSearch}
            />
          }
          {!showLoader && (indexTab == 1) &&
            <Grid container spacing={1} sx={{ marginTop: '1.2em', width: '100%' }} >
              {dataUsersFilter.length == 0 ? 
                <Card sx={{ padding: "8px", width: '100%', height: '10em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>{t("msg.info.empty_response")}</div>
                </Card> :
                <Card sx={{ padding: "8px", width: '100%' }}>
                  <Grid container spacing={1} rowSpacing={3} style={{ margin: '0.4em', width: '99%' }}>
                    <TableContainer sx={{ overflow: "scroll" }} component={Paper}>
                      <Table responsive={true}>
                        <TableHead>
                          <StyledTableRow>
                            <TrueffortTableHeadCell colSpan={8}></TrueffortTableHeadCell>
                            <TrueffortTableHeadCell colSpan={showTableProductivityDetail ? 5 : 1}
                              style={{ ...styleTableContent, textAlign: "center", width: showTableProductivityDetail ? '350px' : '40px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#D4E3FF' }}>
                                <div style={{ width: showTableProductivityDetail ? '95%' : '50%', justifyContent: 'center' }}>
                                  <AccessTimeFilledIcon fontSize="12" />
                                  {showTableProductivityDetail ? "Tiempo del periodo (hrs)" : ""}
                                </div>
                                <div style={{ width: showTableProductivityDetail ? '5%' : '50%' }}>
                                  <KeyboardArrowRightIcon fontSize="12" style={{ cursor: 'pointer' }}
                                    onClick={() => setShowTableProductivityDetail(!showTableProductivityDetail)}
                                  />
                                </div>
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell colSpan={1}></TrueffortTableHeadCell>
                          </StyledTableRow>
                          <StyledTableRow style={{ background: 'rgba(224, 224, 224, 1)' }}>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              {t("User")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              {t("reports.active_project")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <CropOriginalIcon />
                                {showTableElementDetail ? t("reports.element") : ""}
                                <KeyboardArrowLeftIcon style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTableElementDetail(!showTableElementDetail)}
                                />
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <AccountTreeIcon />
                                {showTablePhaseDetail ? t("reports.phase") : ""}
                                <KeyboardArrowLeftIcon style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTablePhaseDetail(!showTablePhaseDetail)}
                                />
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              {t("reports.now_working_on")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <ConstructionIcon />
                                {showTableAppDetail ? t("reports.application") : ""}
                                <KeyboardArrowLeftIcon style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTableAppDetail(!showTableAppDetail)}
                                />
                              </div>
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent, width: '90px' }}>
                                {t("reports.check_in_hour")}
                            </TrueffortTableHeadCell>
                            <TrueffortTableHeadCell style={{ ...styleTableContent }}>
                                {t("reports.check_out_hour")}
                            </TrueffortTableHeadCell>
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#D3E2EB', width: '75px' }}>
                                {t("reports.in_this_task")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#CFF1AB', width: '75px' }}>
                                {t("reports.productive")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#D3E2EB', width: '75px' }}>
                                {t("reports.total")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#FFCDCF', width: '75px' }}>
                                {t("reports.not_productive")}
                            </TrueffortTableHeadCell>}
                            {showTableProductivityDetail && <TrueffortTableHeadCell style={{ ...styleTableContent, textAlign: "center", background: '#D3E2EB', width: '75px' }}>
                                {t("reports.remaining")}
                            </TrueffortTableHeadCell>}
                            {!showTableProductivityDetail && <TrueffortTableHeadCell style={{ padding: 0, background: '#D4E3FF' }}></TrueffortTableHeadCell>}
                            <TrueffortTableHeadCell>{t("Productivity")}</TrueffortTableHeadCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {dataUsersFilter.slice(usersPage * rowsPerPageUsers,
                            usersPage * rowsPerPageUsers + rowsPerPageUsers).map((us, ind) => (
                              <>
                                <TableRow>
                                  <TableCell style={{ width: '230px' }}>{CustomAvatarBadge(us.name, us?.urlImage, us.isRecentlyActivity)}</TableCell>
                                  <TableCell style={{ width: '200px', backgroundColor: '#DCDCDC' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div style={{ width: '90%' }}>
                                        {us.lastTask?.projectPhase?.projectElement?.project?.name}
                                      </div>
                                      <div style={{ width: '10%' }}>
                                        <KeyboardArrowRightIcon onClick={() => {
                                          let usInd = dataUsersFilter.findIndex((u) => u.idUser == us.idUser);
                                          let usAux = dataUsersFilter.map(u => u);
                                          usAux[usInd].showListProjectDetail = !usAux[usInd].showListProjectDetail;
                                          setDataUsersFilter(usAux);
                                        }} />
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell style={{ maxWidth: '100px' }}>{showTableElementDetail ? us.lastTask?.projectPhase?.projectElement?.name : ""}</TableCell>
                                  <TableCell style={{ maxWidth: '100px' }}>{showTablePhaseDetail ? us.lastTask?.projectPhase?.phaseType?.name : ""}</TableCell>
                                  <TableCell style={{ backgroundColor: '#F2E9FF', width: '200px', color: '#553D74', fontWeight: 700 }}>{us?.lastTask?.taskType?.name}</TableCell>
                                  <TableCell>{showTableAppDetail && us.lastTask ? us.lastApplication?.name : ""}</TableCell>
                                  <TableCell>{us.timeEntryFormat}</TableCell>
                                  <TableCell>{us.timeExitFormat}</TableCell>
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                      {formatSeconds(us.totalSecondsIntoLastTask, false, false, false)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#CFF1AB' }}>
                                      {formatSeconds(us.totalEffortRelevant)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                      {formatSeconds(us.totalEffort)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#FFCDCF' }}>
                                      {formatSeconds(us.totalEffort - us.totalEffortRelevant)}
                                    </StyledTableCell>}
                                  {showTableProductivityDetail && 
                                    <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                      {formatSeconds(us.workTimeBySchedule != 0 ? us.workTimeBySchedule - us.totalEffort : 0)}
                                    </StyledTableCell>}
                                  {!showTableProductivityDetail && <StyledTableCell />}
                                  <TableCell>{((us.workTimeBySchedule == 0 ? us.totalEffortRelevant / us.totalEffort : us.totalEffortRelevant / us.workTimeBySchedule) * 100).toFixed(0) + '%'}</TableCell>
                                </TableRow>
                                {us.showListProjectDetail && us.projects.map((p, i) =>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell style={{ background: '#EFEFEF' }}>{p.name}</TableCell>
                                    <TableCell colSpan={6} style={{ minWidth: '200px', background: '#EFEFEF' }}>
                                      <div style={{ width: '100%', height: '1.5em', background: '#DCDCDC', borderRadius: '4px', alignItems: 'center' }}>
                                        <div style={{
                                          height: '1.5em', background: COLORS_RELEVANT[i],
                                          width: ((p.effort / us.totalEffort) * 100) + '%', borderRadius: '4px'
                                        }}>
                                          <span style={{ color: 'white', fontWeight: '700' }}>{formatSeconds(p.effort, false, true, true)}</span>
                                        </div>
                                      </div>
                                    </TableCell>
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                        {formatSeconds(undefined)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#CFF1AB' }}>
                                        {formatSeconds(p.effortRelevant, false, false, false)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                        {formatSeconds(p.effort, false, false, false)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#FFCDCF' }}>
                                        {formatSeconds(p.effort - p.effortRelevant)}</StyledTableCell>}
                                    {showTableProductivityDetail && 
                                      <StyledTableCell style={{ ...styleTableContent, ...columnDataDetail, background: '#D3E2EB' }}>
                                        {formatSeconds(undefined)}</StyledTableCell>}
                                    <TableCell>{((us.workTimeBySchedule == 0 ? p.effortRelevant / us.totalEffort : p.effortRelevant / us.workTimeBySchedule) * 100).toFixed(0) + '%'}</TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <Box sx={{
                        display: 'flex', justifyContent: "flex-end",
                        alignItems: "center", marginTop: '1em'
                      }}>
                        <TablePagination
                          labelRowsPerPage={t("elements.pagination.label")}
                          rowsPerPageOptions={[4, 8, 12]}
                          component={"div"}
                          onRowsPerPageChange={handleChangeUsersRowsPerPage}
                          onPageChange={handleChangeUsersPage}
                          count={usersCount}
                          rowsPerPage={rowsPerPageUsers}
                          page={usersPage}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Card>
              }
            </Grid>
          }
          {!showLoader && indexTab == 3 &&
            <GlobalUserWeeklyReport 
              startDate={startDate}
              endDate={endDate}
              idsSelectedUsers={idsSelectedUsers}
              idSelectedProject={idSelectedProject}
              inputSearch={inputSearch}
            />
          }
          {!showLoader && indexTab == 4 &&
            <GlobalUserBenchmarkReport 
              startDate={startDate}
              endDate={endDate}
              idsSelectedUsers={idsSelectedUsers}
              idSelectedProject={idSelectedProject}
              inputSearch={inputSearch}
            />
          }
        </Paper>
      </div>
    </LocalizationProvider>
  );
}

export default GlobalReportUsers;