import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class PaymentPlanService {

    getAll() {
        return http?.get(baseURL.activitiesapi.paymentPlan + "/");
    }
}

export default PaymentPlanService;