import {ApiProtectedWS as http} from "../api/axios";
import {baseURL} from "../util/Constants";

class CategoryService {

    find(idInstance: number) {
        return http?.get(baseURL.activitiesapi.category + "/findByInstance/" + idInstance);
    }

    getAll() {
        return http?.get(baseURL.activitiesapi.category + "/");
    }

    create(data: any) {
        const options = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }, transformRequest: () => data
        }
        //http?.options("/", args);
        return http?.post(baseURL.activitiesapi.category + "/", data, options);
    }

    update(data: any) {
        const options = {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }, transformRequest: () => data
        }
        //http?.options("/", args);
        return http?.put(baseURL.activitiesapi.category + `/`, data, options);
    }

    delete(id: number) {
        return http?.delete(baseURL.activitiesapi.category + `/${id}`);
    }
}

export default CategoryService;