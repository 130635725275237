import React from "react";
import {
    Card, FormControl, Menu, MenuItem, Paper, Table, TableContainer, TableHead, TableBody,
    Chip, TextField, CardContent, Button, Box, TablePagination, Stack, ListItemIcon, ListItemText, CircularProgress
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {styled, useTheme} from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import UserService from "../../services/user.service";
import '../../css/User.css';
import '../../css/App.scss';
import { useAuthContext } from "../../contexts/AuthenticationContext";
import { useFormik } from 'formik';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ProfileService from "../../services/profile.service";
// @ts-ignore
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import {
    defaultStrings, ID_ROLE_USER,
    MIN_LEVEL_ADMIN, MIN_LEVEL_SUSER, NUMBER_ITEMS_BY_PAGE
} from "../../util/Constants";
import { StyledTableCell, StyledTableRow, TrueffortButton, TrueffortCardTable, TrueffortChip, TrueffortInputLabel, TrueffortSearchInput, TrueffortTableHeadCell, TrueffortTextField } from "../../MUIstyle/MUICustom";
import { Add, ExpandMore, Clear, Save, FileUpload } from "@mui/icons-material";
import { styleReactSelect } from "../../MUIstyle/GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PendingIcon from "@mui/icons-material/Pending";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from '@mui/icons-material/Logout';
import CustomAvatar from "../custom/CustomAvatar";

function UserRow(
    {usr, currentUser, t, formik, state, roles, profilesList, roleValuesState, profileValuesState,
        closeUserSessions, prepareEdit, deleteUserFromInstance}
) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);

    const handleClickOptions = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorEl(null);
    };

    return(
        <StyledTableRow>
            {!usr.isEditing ?
                <>
                    <StyledTableCell>
                        <CustomAvatar url={usr.imageDto?.url} />
                    </StyledTableCell>
                    <StyledTableCell style={{fontWeight: "bold"}}>
                        {usr.name}
                    </StyledTableCell>
                    <StyledTableCell style={{fontWeight: "bold"}}>
                        {usr.lastName}
                    </StyledTableCell>
                    <StyledTableCell>
                        {usr.email}
                    </StyledTableCell>
                    <StyledTableCell style={{width: '250px'}}>
                        {usr.profiles.map((p, i) => {
                            return (
                                <TrueffortChip
                                    key={usr.id + "-profile-" + i}
                                    label={p.name}
                                    size="small"
                                />
                            )
                        })}
                    </StyledTableCell>
                    <StyledTableCell style={{fontWeight: "bold"}}>
                        {t("role." + usr.idRole)}
                    </StyledTableCell>
                    <StyledTableCell
                        style={{fontWeight: "bold"}}
                    >
                        {usr.isConfirmed ? t("user_page.confirmed") : t("user_page.pending")}
                    </StyledTableCell>
                    <StyledTableCell>
                        {(usr.role.accessLevel < currentUser.role.accessLevel || (currentUser.role.accessLevel == MIN_LEVEL_ADMIN && usr.role.accessLevel != MIN_LEVEL_SUSER) || currentUser.role.accessLevel == MIN_LEVEL_SUSER) &&
                            <>
                                <TrueffortButton
                                    style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
                                    size="small"
                                    color="secondary"
                                    id="btnOptionsElement"
                                    disabled={state.state.isEditing || state.state.isCreating}
                                    aria-controls={openOptions ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openOptions ? 'true' : undefined}
                                    onClick={handleClickOptions}
                                >
                                    <PendingIcon/>
                                </TrueffortButton>

                                <Menu
                                    id="basic-menu"
                                    sx={{
                                        '& .MuiPaper-root': {
                                            backgroundColor: '#050536'
                                        }
                                    }}
                                    anchorEl={anchorEl}
                                    open={openOptions}
                                    onClose={handleCloseOptions}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleCloseOptions();
                                            prepareEdit(usr);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <AddIcon
                                                size="small"
                                                //className="color-secondary"
                                                sx={{color: '#F5F5F5'}}
                                            />
                                        </ListItemIcon>
                                        <ListItemText sx={{color: '#F5F5F5'}}>
                                            {t("elements.buttons.edit")}
                                        </ListItemText>
                                    </MenuItem>
                                    {(currentUser.role.accessLevel === MIN_LEVEL_ADMIN || currentUser.role.accessLevel === MIN_LEVEL_SUSER) &&
                                        <MenuItem
                                            onClick={() => {
                                                handleCloseOptions();
                                                closeUserSessions(usr.idUser);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <LogoutIcon
                                                    size="small"
                                                    //className="color-red"
                                                    sx={{color: '#F5F5F5'}}
                                                />
                                            </ListItemIcon>
                                            <ListItemText sx={{color: '#F5F5F5'}}>
                                                {t("configuration.close_sessions")}
                                            </ListItemText>
                                        </MenuItem>
                                    }
                                    <MenuItem
                                        onClick={() => {
                                            handleCloseOptions();
                                            deleteUserFromInstance(usr);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon
                                                size="small"
                                                //className="color-red"
                                                sx={{color: '#989898'}}
                                            />
                                        </ListItemIcon>
                                        <ListItemText sx={{color: '#989898'}}>
                                            {t("elements.buttons.delete")}
                                        </ListItemText>
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    </StyledTableCell>
                </> :

                <>
                    <StyledTableCell colSpan={8}>
                        <Grid container spacing={1}>
                            <Grid sx={4}>
                                <TextField
                                    id={usr.idUser + "_name"}
                                    name={"name"}
                                    style={{background: "#F5F5F5", borderRadius: "0px", borderColor: '#BDBDBD'}}
                                    value={formik.values.name}
                                    disabled={formik.isSubmitting}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.errors?.name && formik.touched?.name ? t("formik.errors.name") : null}
                                    variant="outlined"
                                    size="small"
                                    sx={{borderRadius: "100px"}}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <TextField
                                    id={usr.idUser + "_lastName"}
                                    name={"lastName"}
                                    style={{background: "#F5F5F5", borderRadius: "0px", borderColor: '#BDBDBD'}}
                                    value={formik.values.lastName}
                                    disabled={formik.isSubmitting}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.errors?.lastName && formik.touched?.lastName ? t("formik.errors.lastName") : null}
                                    variant="outlined"
                                    size="small"
                                    sx={{borderRadius: "100px"}}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <TextField
                                    id={usr.idUser + "_email"}
                                    name={"email"}
                                    style={{background: "#F5F5F5", borderRadius: "0px", borderColor: '#BDBDBD'}}
                                    value={formik.values.email}
                                    disabled={formik.isSubmitting}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.errors?.email && formik.touched?.email ? t("formik.errors.email") : null}
                                    variant="outlined"
                                    size="small"
                                    sx={{borderRadius: "100px"}}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid xs={5}>
                                <Select
                                    isMulti
                                    value={profileValuesState.profileValues}
                                    options={Array.isArray(profilesList) &&
                                        profilesList.map((prof, k) => {
                                            return ({value: prof.idProfile, label: prof.name})
                                        })
                                    }
                                    onChange={(option) => {
                                        profileValuesState.setProfileValues(option)
                                    }}
                                    styles={styleReactSelect}
                                    menuPlacement={'auto'}
                                />
                            </Grid>
                            <Grid xs={5}>
                                <Select
                                  menuPlacement={'auto'}
                                    value={roleValuesState.roleValues}
                                    options={Array.isArray(roles) &&
                                        roles.map((r) => {
                                            return ({value: r.idRole, label: t("role." + r.idRole)})
                                        })
                                    }
                                    onChange={(option) => roleValuesState.setRoleValues(option)}
                                    styles={styleReactSelect}
                                />
                            </Grid>
                            <Grid xs={2}>
                                <div style={{display: "flex"}}>
                                    <Chip size="small"
                                          icon={<Save fontSize="small"/>}
                                          variant="outlined"
                                          color='success'
                                          onClick={formik.handleSubmit}
                                          label={t("elements.buttons.save")}
                                    />
                                    <div style={{marginLeft: "0.5em"}}/>
                                    <Chip size="small"
                                          icon={<Clear fontSize="small"/>}
                                          onClick={() => {
                                              usr["isEditing"] = false;
                                              formik.resetForm();
                                              state.setState({...state.state, isUpdating: false, isEditing: false});
                                          }}
                                          label={t("elements.buttons.cancel")}
                                          variant="outlined"
                                          color="error"
                                    >
                                        <Clear fontSize="small"/>
                                    </Chip>
                                </div>
                            </Grid>
                        </Grid>
                    </StyledTableCell>
                </>
            }
        </StyledTableRow>
    );

}

function UsersTabTRUE() {

    const { t } = useTranslation();
    const { user } = useAuthContext();
    const [showLoader, setShowLoader] = React.useState(false);
    const theme = useTheme();
    const bellowSM = useMediaQuery(theme.breakpoints.down('sm'));

    const uploadFile = React.useRef();
    const [uploadFileInfo, setUploadFileInfo] = React.useState();
    const [users, setUsers] = React.useState([]);
    const [roles, setRoles] = React.useState([]);
    const [profilesList, setProfilesList] = React.useState([]);
    const [usersFiltered, setUsersFiltered] = React.useState([])
    const [showFormAdd, setShowFormAdd] = React.useState(false);
    const [bulkUploadErrors, setBulkUploadErrors] = React.useState([]);
    const [anchorElBulk, setAnchorElBulk] = React.useState(null);
    const openBulk = Boolean(anchorElBulk);
    const [state, setState] = React.useState({ loading: false, isEditing: false, isUpdating: false, showBulkUpload: false, errorMessageFile: "" });
    const userService = React.useMemo(() => new UserService(), []);
    const profileService = React.useMemo(() => new ProfileService(), []);
    const [profileValues, setProfileValues] = React.useState([]);
    let default_role = { value: ID_ROLE_USER, label: 'Usuario' };
    const [roleValues, setRoleValues] = React.useState(default_role);
    const [rowsPerPageUsers, setRowsPerPageUsers] = React.useState(NUMBER_ITEMS_BY_PAGE);
    const [usersPage, setUsersPage] = React.useState(0);
    const [usersCount, setUsersCount] = React.useState(0);//Cantidad de categorias

    React.useEffect(() => {
        setShowLoader(true);
        fillRoles();
        fillUsers();
        fillProfilesList();
    }, []);

    const fillRoles = () => {
        // @ts-ignore
        userService.getRoles().then(({ data: response }) => {
            if (Array.isArray(response)) {
                //console.log(response);
                setRoles(response);
            }
        }).catch(({data:response}) => {
            console.error(response);
        }).finally();
    }

    const fillUsers = () => {
        // @ts-ignore
        userService.getAll().then(({ data: response }) => {
            if (Array.isArray(response)) {
                //console.log("USERS", response);
                setUsers(response.map((usr) => {
                    return {
                        ...usr, isEditing: false
                    }
                }));
                setUsersFiltered(response);
                setUsersCount(response.filter(us => us.isEnabled).length);
            }
        }).catch(({data:response}) => {
            console.error(response);
        }).finally(() => setShowLoader(false));
    }

    const fillProfilesList = () => {
        // @ts-ignore
        profileService.find().then(({ data: response }) => {
            //console.log(response);
            if (response.data != null && Array.isArray(response.data)) {
                setProfilesList(response.data);
            }
        }).catch(({data:response}) => {
            console.error(response);
        });
    }

    const handleChangeSearchInput = (searchFilter) => {
        let items = users.filter((data) => {
            if (searchFilter == "") {
                return users;
            } else {
                if (data.name?.toLowerCase().includes(searchFilter.toLowerCase())
                    || data.lastName?.toLowerCase().includes(searchFilter.toLowerCase())
                    || data.email.toLowerCase().includes(searchFilter.toLowerCase())
                    || data.role.role.toLowerCase().includes(searchFilter.toLowerCase())) {
                    return data;
                }
            }
        });
        setUsersFiltered(items);
    }

    const validate = (values) => {
        let errors = {};

        if (!values.email) {
            errors.email = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(values.email)) {
            errors.email = "forms.invalidEmailValidation";
        }

        if (values.idRole < 1) {
            errors.idRole = "Required";
        }

        //console.log(errors);
        return errors;
    };

    const handleCancelAdd = () => {
        //setErrorMessage("");
        //setInfoMessage("");
        setShowFormAdd(false);
        setState({...state, isCreating: false, isEditing: false});
        formik.resetForm();
    }

    const handleSubmit = (values) => {
        let userAux = {
            name: values.name,
            lastName: values.lastName,
            email: values.email,
            idInstance: user.instance.idInstance,
            idRole: roleValues.value,
            profiles: profileValues.map((p) => { return { idProfile: p.value } })
        }

        //return;
        // @ts-ignore
        userService.createInivitation(userAux).then(({ data: response }) => {
            //console.log(response);
            formik.setSubmitting(false);
            //console.log("finish submiting")
            if (response.internalError != null && response.internalError && response.message != null) {
                //setErrorMessage(response.message);
                toast.error(response.message);
            } else {
                //setInfoMessage(t("user_page.msg_success_add_user") + " " + userAux.email);
                toast.success(t("user_page.msg_success_add_user") + " " + userAux.email);
                formik.resetForm();
            }
            fillUsers();
        }).finally(() => {
            setShowFormAdd(false);
            setRoleValues(default_role);
            setProfileValues([])
        });
    }

    const handleEdit = (values) => {
        let aux = {
            user: {
                name: values.name,
                lastName: values.lastName,
                email: values.email,
                idInstance: user.instance.idInstance,
                idRole: roleValues.value,
                profiles: profileValues.map((p) => { return { idProfile: p.value } })
            },
            idUser: values.idUser,
            idInstance: user.instance.idInstance,
            role: { idRole: roleValues.value, role: roleValues.label }
        }
        // @ts-ignore
        userService.updateUser(values.idUser, aux).then(({ data: response }) => {
            formik.setSubmitting(false);
            if (response.internalError != null && response.internalError && response.message != null) {
                //setErrorMessage(response.message);
                toast.error(response.message);
            } else {
                //setInfoMessage(t("user_page.msg_success_update_user"));
                toast.success(t("user_page.msg_success_update_user"));
                setShowFormAdd(false);
            }
            setState({ ...state, isUpdating: false, isEditing:false });
            fillUsers();
        });
    }

    const handleUserLogout = (idUser) => {
        userService.closeActiveSessions(idUser).then(({data: response}) => {
            if (response.internalError != null && response.internalError && response.message != null) {
                toast.error(response.message);
            } else if (!response.data) {
                toast.success(t("configuration.no_active_session"));
            } else {
                toast.success(t("configuration.user_logout"));
            }
        })
    }

    const formik = useFormik({
        initialValues: {
            idUser: 0,
            idInstance: 1,
            idRole: ID_ROLE_USER,
            role: { idRole: ID_ROLE_USER, role: '' },
            active: true,
            name: '',
            email: '',
            lastName: '',
        },
        validate: validate,
        onSubmit: (values, { setSubmitting, setValues }) => {
            //console.log("submiting");
            if (!state.isUpdating) {
                handleSubmit(values);
            } else {
                handleEdit(values);
            }
        },
    });

    const deleteUserFromInstance = (userDelete) => {
        confirmAlert({
            title: t("user_page.msg_delete_confirm1"),
            message: t("user_page.msg_delete_confirm2") + "" + userDelete.name + " " + userDelete.lastName + " (" + userDelete.email + ")" + "?",
            buttons: [
                {
                    label: t("accept"),
                    onClick: () => {
                        try {
                            // @ts-ignore
                            userService.deleteFromInstance(userDelete.idUser, user.instance.idInstance).then(({ data: response }) => {
                                //console.log(response);
                                //setInfoMessage(t("user_page.msg_success_delete_user"));
                                toast.success(t("user_page.msg_success_delete_user"));
                                fillUsers();
                            });
                        } catch (error) {
                            //console.log("Error #Users handleDelete" + JSON.stringify(error));
                            //setErrorMessage("Ocurrió un error al eliminar usuario.")
                            toast.error("Ocurrió un error al eliminar usuario.");
                        }
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => { return; }
                }
            ]
        });


    }

    function onChangeUploadFile() {
        setUploadFileInfo({
            name: uploadFile.current?.files[0].name,
            size: uploadFile.current?.files[0].size,
            type: uploadFile.current?.files[0].type
        });
    }

    const uploadFileUsers = () => {
        //console.log("uploadFileUsers");
        //console.log(uploadFileInfo?.type);
        setState({ ...state, errorMessageFile: "" });
        setBulkUploadErrors([]);

        if (uploadFileInfo?.type !== "application/vnd.ms-excel") {
            setState({ ...state, errorMessageFile: t("user_page.msg_error_file_type") });
            return;
        }

        let formData = new FormData();
        formData.append("file", uploadFile.current.files[0], uploadFile.current.files[0].name);
        try {
            // @ts-ignore
            userService.bulkUploadInvitations(formData).then(({ data: response }) => {
                //console.log(response);
                if (response.internalError != null && response.internalError) {
                    //setErrorMessage(response.message);
                    toast.error(response.message);
                    setUploadFileInfo(null);
                } else {
                    if (Array.isArray(response)) {
                        setBulkUploadErrors(response);
                        if (response.length > 0) {
                            //setErrorMessage(t("user_page.msg_error_bulk_upload_errors"));
                            toast.error(t("user_page.msg_error_bulk_upload_errors"));
                        } else {
                            //setInfoMessage(t("user_page.msg_success_bulk_upload"));
                            toast.success(t("user_page.msg_success_bulk_upload"));
                            setState({ ...state, showBulkUpload: false });
                            fillUsers();
                        }
                    }
                }
            });
        } catch (err) {
            //setErrorMessage(t("user_page.msg_error_bulk_upload"));
            toast.error(t("user_page.msg_error_bulk_upload"));
        }
    }

    const handleClickBulk = (event) => {
        setAnchorElBulk(event.currentTarget);
    };
    const handleCloseBulk = () => {
        setAnchorElBulk(null);
    };

    const prepareEdit = (usr) => {
        //console.log(usr);

        usr["isEditing"] = true;
        formik.setValues(usr);
        //console.log(usr);
        setProfileValues(usr.profiles.map((p) => { return { value: p.idProfile, label: p.name } }));
        setRoleValues({ value: usr.role.idRole, label: t("role." + usr.idRole) });
        setState({ ...state, isUpdating: true, isEditing: true });
    }

    const handleChangeUsersRowsPerPage = (event) => {
        setRowsPerPageUsers(parseInt(event.target.value, 10));
        setUsersPage(0);
    }
    
    const handleChangeUsersPage = (event, newPage) => {
        setUsersPage(newPage);
    }

    return (
        <Card sx={{ padding: "8px", background: "#EFEFEF", overflow: "unset" }}>
            <Grid container spacing={1} rowSpacing={3} style={{ marginBottom: "0.4em" }} justifyContent='space-evenly'>
                <Grid xs={12} sm={6} md={6} >
                  <TrueffortSearchInput
                    id="idSearchInput"
                    fullWidth
                    placeholder={t("user_page.search")}
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleChangeSearchInput(e.target.value)}
                  />
                </Grid>
                {user.role.accessLevel >= MIN_LEVEL_ADMIN &&
                    <Grid xs={12} sm={6} md={6} >

                        <Stack direction="row" spacing={2} justifyContent="flex-end" >
                            <div>
                                <TrueffortButton
                                    id="bulk-upload-button"
                                    color="grayMedium"
                                    aria-controls={openBulk ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openBulk ? 'true' : undefined}
                                    onClick={handleClickBulk}
                                    disabled={state.isEditing || state.isCreating}
                                    variant="outlined"
                                    style={{ fontSize: "11px" }}
                                    endIcon={<ExpandMore />}
                                    maxHeight="25px"
                                >
                                    {t("user_page.bulk_load")}
                                </TrueffortButton>
                                <Menu
                                    id="bulk-upload-menu"
                                    anchorEl={anchorElBulk}
                                    open={openBulk}
                                    onClose={handleCloseBulk}
                                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                >
                                    <MenuItem onClick={() => {
                                        window.location.href = "./users_layout.xls";
                                        handleCloseBulk();
                                    }}>{t("user_page.download_layout")}</MenuItem>
                                    <MenuItem onClick={() => {
                                        setState({ ...state, showBulkUpload: true });
                                        handleCloseBulk();
                                    }}>{t("user_page.upload_layout")}</MenuItem>
                                </Menu>
                            </div>

                            <TrueffortButton
                                color="secondary"
                                variant={"contained"}
                                onClick={() => {
                                    setShowFormAdd(true);
                                    setState({ ...state, isUpdating: false, isCreating: true })
                                }}
                                disabled={state.isEditing}
                                startIcon={<Add />}
                                style={{ fontSize: "11px" }}
                                maxHeight="25px"
                            >
                                {t("user_page.invite_user")}
                            </TrueffortButton>
                        </Stack>
                    </Grid>
                }
                {user.role.accessLevel >= MIN_LEVEL_ADMIN &&
                    <Grid xs={12}>
                        <span style={{ fontSize: "11px" }} className="span-num-accounts">{t("user_page.accounts") + ": " + users.length + " / " + user.instance.totalUsers}</span>
                    </Grid>
                }
                {state.showBulkUpload &&
                    <Grid xs={12}>
                        <Card className="card-bulk-upload">
                            <Grid container spacing={3} rowSpacing={3} style={{ marginBottom: "0.4em" }} alignItems={"center"}
                                  justifyContent={bellowSM ? "center" : "flex-start"}>
                                <Grid item lg={5}>
                                    <div >
                                        <Button
                                            id="upload-file-users-button"
                                            variant="contained"
                                            component="label"
                                            fullWidth
                                        >
                                            <input
                                                type="file"
                                                ref={uploadFile}
                                                name="image"
                                                onChange={onChangeUploadFile}
                                                accept="application/vnd.ms-excel"
                                                disabled={state.loading}
                                                readOnly={state.loading}
                                            />
                                        </Button>
                                    </div>
                                    {state.errorMessageFile ?
                                        <div className="text-danger mt-2">{state.errorMessageFile} </div> : null
                                    }
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm
                                    md
                                    /*style={{
                                        display: "flex",
                                        alignItems: "flex-end", justifyContent: "center"
                                    }}*/
                                >
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent={bellowSM ? "center" : "flex-start"}
                                    >
                                        <TrueffortButton
                                            id="send-file-users-button"
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => uploadFileUsers()}
                                        >
                                            <FileUpload />
                                        </TrueffortButton>


                                        <TrueffortButton
                                            color="error"
                                            variant={"outlined"}
                                            onClick={() => {
                                                setState({ ...state, showBulkUpload: false });
                                                setUploadFileInfo(null);
                                            }}
                                            style={{ fontSize: "11px" }}
                                        >
                                            <Clear />
                                        </TrueffortButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {bulkUploadErrors.length > 0 &&
                                <TableContainer component={Paper}>
                                    < Table
                                        responsive="true"
                                        className="card-table table-vcenter text-nowrap"
                                    >
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>{""}</StyledTableCell>
                                                <StyledTableCell>{t("user_page.email")}</StyledTableCell>
                                                <StyledTableCell>{t("user_page.role")}</StyledTableCell>
                                                <StyledTableCell>{""}</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bulkUploadErrors.map((err) => (
                                                <StyledTableRow>
                                                    <StyledTableCell>{err.fila}</StyledTableCell>
                                                    <StyledTableCell><div className={(err.emailOk ? "" : "text-danger") + " mt-2"}>{err.email}</div></StyledTableCell>
                                                    <StyledTableCell><div className={(err.roleOk ? "" : "text-danger") + " mt-2"}>{err.role}</div></StyledTableCell>
                                                    <StyledTableCell>{t(err.mensajeError)}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Card>
                    </Grid>
                }
                {showFormAdd &&
                    <Grid xs={12}>
                        <Paper style={{ marginBottom: "0.4em" }}>
                            <CardContent>
                                <p style={{fontSize: '16px'}}>{t("user_page.user_create")}</p>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <TrueffortTextField 
                                            id={"field-email-profile-new"}
                                            name="email"
                                            size="small"
                                            required
                                            fullWidth
                                            value={formik.values.email}
                                            placeholder={t("user_page.form_email_placeholder")}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.errors?.email && formik.touched?.email ? t(formik.errors.email) : null} 
                                            labelString={t(defaultStrings.emailLabel)}
                                            />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TrueffortInputLabel shrink labelString={t("user_page.role")} />
                                        <div>
                                            <Select
                                                name="role"
                                                required
                                                placeholder={t("user_page.role_select")}
                                                value={roleValues}
                                                options={Array.isArray(roles) &&
                                                    roles.map((r) => {
                                                        return ({ value: r.idRole, label: t("role." + r.code) })
                                                    })
                                                }
                                                onChange={(option) => setRoleValues(option)}
                                                error={formik.errors?.idRole && formik.touched?.idRole ? t(formik.errors.idRole) : null}
                                                styles={styleReactSelect}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                    <TrueffortInputLabel shrink labelString={t("Profile")} />
                                        <Select
                                            placeholder={t("user_page.profile_select")}
                                            isMulti
                                            value={profileValues}
                                            options={Array.isArray(profilesList) &&
                                                profilesList.map((prof) => {
                                                    return ({
                                                        value: prof.idProfile,
                                                        label: prof.name
                                                    })
                                                })
                                            }
                                            onChange={(option) => setProfileValues(option)}
                                            styles={styleReactSelect}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2} lg={2} >
                                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" >
                                            <TrueffortButton
                                                id="new-user-button"
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={formik.handleSubmit}
                                            >
                                                {t("user_page.invite")}
                                            </TrueffortButton>

                                            <TrueffortButton
                                                id="cancel-profile-button"
                                                size="small"
                                                color="secondary"
                                                variant={"outlined"}
                                                onClick={() => handleCancelAdd()}
                                                style={{ fontSize: "11px" }}
                                            >
                                                <Clear size="small" />
                                            </TrueffortButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Paper>
                    </Grid>
                }
                {showLoader && 
                    <Stack alignItems={"center"} justifyContent={"center"} style={{height:'300px'}}>
                        <CircularProgress />
                    </Stack>
                }
                {!showLoader && 
                    <TrueffortCardTable>
                    <Grid container spacing={1} rowSpacing={3} style={{ margin: "0.4em 0.2em", width: '100%' }}>
                        <TableContainer component={Paper}
                            onSubmit={formik.handleSubmit}
                            sx={{ overflow: "unset" }}
                        >
                            <Table responsive="true">
                                <TableHead>
                                    <StyledTableRow sx={{backgroundColor: '#DCDCDC !important'}}>
                                        <TrueffortTableHeadCell></TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell >{t("Name")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell style={{ fontSize: '12px', padding: 0  }}>{t("Lastname")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell style={{ fontSize: '12px', padding: 0  }}>{t("user_page.email")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell style={{ fontSize: '12px', padding: 0  }}>{t("Profile")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell style={{ fontSize: '12px', padding: 0  }}>{t("user_page.role")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell style={{ fontSize: '12px', padding: 0  }}>{t("user_page.status")}</TrueffortTableHeadCell>
                                        <TrueffortTableHeadCell style={{ fontSize: '12px', padding: 0  }}>{t("actions")}</TrueffortTableHeadCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {usersFiltered
                                        .filter((us) => us.isEnabled).slice(
                                            usersPage * rowsPerPageUsers,
                                            usersPage * rowsPerPageUsers + rowsPerPageUsers)
                                        .map((usr) => (
                                            <UserRow
                                                key={usr.idUser.toString()}
                                                usr={usr}
                                                currentUser={user}
                                                t={t}
                                                formik={formik}
                                                roles={roles}
                                                profilesList={profilesList}
                                                state={{
                                                    state: state,
                                                    setState: setState
                                                }}
                                                roleValuesState={{
                                                    roleValues: roleValues,
                                                    setRoleValues: setRoleValues
                                                }}
                                                profileValuesState={{
                                                    profileValues: profileValues,
                                                    setProfileValues: setProfileValues
                                                }}
                                                closeUserSessions={handleUserLogout}
                                                prepareEdit={prepareEdit}
                                                deleteUserFromInstance={deleteUserFromInstance}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ width:'100%', justifyContent: 'flex-end' }}>
                            <Box sx={{ display: 'flex', justifyContent: "flex-end", 
                                alignItems: "center", marginTop: '1em' }}>
                                <TablePagination 
                                    labelRowsPerPage={t("elements.pagination.label")}
                                    rowsPerPageOptions={[5,10,15]}
                                    component={"div"}
                                    onRowsPerPageChange={handleChangeUsersRowsPerPage}
                                    onPageChange={handleChangeUsersPage}
                                    count={usersCount}
                                    rowsPerPage={rowsPerPageUsers}
                                    page={usersPage}
                                />
                            </Box>
                        </div>
                    </Grid>
                    </TrueffortCardTable> 
                }
            </Grid>
        </Card>
    );
};

export default UsersTabTRUE;